// @flow
import { connect } from 'react-redux'
import { surveyActions } from 'common-docdok/src/domain/survey/actions'

import StudySurveysToSend from '../components/StudySurveysToSend'

import { studiesActions } from '../actions'
import { surveysActions } from '../../Surveys/actions'

const mapStateToProps = (state: StoreType, ownProps: Object) => ({
  showSendSurveyDialog: state.surveys.showSendSurveyDialog,
  study: state.survey.studies[ownProps.params.studyId]
})

export default connect(
  mapStateToProps,
  {
    ...surveyActions,
    ...studiesActions,
    ...surveysActions
  }
)(StudySurveysToSend)
