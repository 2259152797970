import { takeEvery, put, call } from 'redux-saga/effects'
import {
  surveyActions,
  surveyActionTypes
} from 'common-docdok/src/domain/survey/actions'
import { asyncAction } from 'common-docdok/src/utils/sagaUtils'
import type {
  ActionType,
  GenType,
  ExtractReturn
} from 'common-docdok/src/types'
import { stopSubmit } from 'redux-form'
import api from 'common-docdok/src/api'

import {
  dynamicFormActionTypes,
  type SubmitFormType
} from '../../../../../common/DynamicForm/actions'
import { infoPanelActions } from '../../../../../common/InfoPanel/actions'
import { alertActions } from '../../../../../common/AlertDialog/actions'
import { surveysActions, surveysActionTypes } from '../actions'
import { downloadJSON, downloadSVC } from '../../../../../common/utils'

export function* sendSurveyToParicipant({ payload }: SubmitFormType): GenType {
  if (payload.name === 'sendSurvey') {
    const { surveys, doSchedule } = payload.data

    const participitions: Array<Object> = []

    surveys.forEach((sd) => {
      payload.data.patients.forEach((u) => {
        participitions.push({
          userRef: u.userRef,
          surveyId: sd.id,
          patientUuid: u.uuid,
          scheduling: doSchedule ? sd.defaultScheduling : undefined
        })
      })
    })
    const winner = yield asyncAction(
      surveyActions.sendSurveysRequested(participitions)
    )

    if (winner.succeeded) {
      yield put(surveysActions.setSendSurveyDialog(false))
      const surveyNames = surveys.map((s) => s.name).join(',')
      const users = payload.data.patients
        .map((u) => `${u.firstName} ${u.lastName}`)
        .join(',')
      yield put(
        infoPanelActions.showMessage('surveys.actions.sendSurvey.success', {
          surveyNames,
          users
        })
      )
      yield put(stopSubmit(payload.name))

      const schedulingNames = surveys
        .filter((s) => s.defaultScheduling)
        .map((s) => s.name)
        .join('')
      if (doSchedule && schedulingNames.length > 0) {
        yield put(
          alertActions.showAlert('surveys.actions.sendSurvey.schedulingHint', {
            surveys: schedulingNames
          })
        )
      }
    } else {
      yield put(stopSubmit(payload.name, winner.failed.payload.error))
    }
  }
}

export function* activateSuccess(
  action: ExtractReturn<typeof surveyActions.activateSurveyDefinitionsSucceeded>
): GenType {
  yield put(
    infoPanelActions.showMessage('surveys.actions.activateSurvey.success', {
      number: action.payload.length
    })
  )
}

export function* loadResponses(action: ActionType): GenType {
  if (action.payload) {
    yield put(surveyActions.getSurveyResponsesRequested(action.payload))
  }
}

export function* deleteSurveySucceeded(): GenType {
  yield put(
    infoPanelActions.showMessage('surveys.actions.deleteSurvey.success')
  )
}

export function* exportSSurveyCSVGen(action: ActionType) {
  const result = yield call(api.getSurveyCSV, action.payload.surveyId)
  const name = String(result.headers['content-disposition']).replace(
    'attachment: filename=',
    ''
  )
  const stringCSV = decodeURIComponent(encodeURIComponent(result.data))
  downloadSVC(`${name.trim('"')}.csv`, stringCSV)
}

export function* exportSSurveyJSONGen(action: ActionType) {
  const result = yield call(api.getSurveyJSON, action.payload.surveyId)
  const name = String(result.headers['content-disposition']).replace(
    'attachment: filename=',
    ''
  )
  downloadJSON(`${name.trim('"')}.json`, JSON.stringify(result.data))
}

export function* uploadSurveyThemeSucceeded(): GenType {
  yield put(
    infoPanelActions.showMessage('surveys.surveyTheme.feedback.create.success')
  )
}

export function* uploadSurveyThemeFailed(): GenType {
  yield put(
    infoPanelActions.showMessage(
      'surveys.surveyTheme.feedback.create.error',
      {},
      'error'
    )
  )
}

export function* showFileUploadError(): GenType {
  yield put(
    infoPanelActions.showMessage(
      'surveys.surveyTheme.feedback.create.fileError',
      {},
      'error'
    )
  )
}

function* saveSurveyGroup({ payload }: Object): GenType {
  if (
    payload.name === 'createSurveyGroup' ||
    payload.name === 'updateSurveyGroup'
  ) {
    const reqPayload = {
      ...payload.data,
      surveyDefinitions: payload.data.surveyDefinitionIds
        ? payload.data.surveyDefinitionIds.map((d) => ({
          id: d
        }))
        : []
    }
    const winner =
      reqPayload && reqPayload.id
        ? yield asyncAction(
          surveyActions.updateSurveyGroupRequested(reqPayload)
        )
        : yield asyncAction(
          surveyActions.createSurveyGroupRequested(reqPayload)
        )

    if (winner.succeeded) {
      yield put(stopSubmit(payload.name))
      yield put(surveysActions.toggleSurveyGroupDialog({}))
      yield put(
        infoPanelActions.showMessage(
          'surveys.surveyGroup.feedback.create.success'
        )
      )
    } else {
      yield put(stopSubmit(payload.name, winner.failed.payload.error))
    }
  }
}

export default function surveySaga(): Array<GenType> {
  return [
    takeEvery(surveyActionTypes.DELETE_SURVEY_SUCCEEDED, deleteSurveySucceeded),
    takeEvery(dynamicFormActionTypes.SUBMIT_FORM, sendSurveyToParicipant),
    takeEvery(
      surveyActionTypes.ACTIVATE_SURVEY_DEFINITIONS_SUCCEEDED,
      activateSuccess
    ),
    takeEvery(surveysActionTypes.TOGGLE_SURVEY_RESPONSES_DIALOG, loadResponses),
    takeEvery(surveysActionTypes.EXPORT_SURVEY_CSV, exportSSurveyCSVGen),
    takeEvery(
      surveysActionTypes.UPLOAD_SURVEY_THEMES_SUCCEEDED,
      uploadSurveyThemeSucceeded
    ),
    takeEvery(
      surveysActionTypes.UPLOAD_SURVEY_THEMES_FAILED,
      uploadSurveyThemeFailed
    ),
    takeEvery(surveysActionTypes.EXPORT_SURVEY_CSV, exportSSurveyCSVGen),
    takeEvery(surveysActionTypes.EXPORT_SURVEY_JSON, exportSSurveyJSONGen),
    takeEvery(
      surveysActionTypes.SHOW_SURVEY_THEME_UPLOAD_ERROR,
      showFileUploadError
    ),
    takeEvery(dynamicFormActionTypes.SUBMIT_FORM, saveSurveyGroup)
  ]
}
