// @flow

export const fieldNames = {
  answers: 'answers',
  completionDate: 'completionDate',
  creationDate: 'creationDate',
  id: 'id',
  label: 'label',
  participantName: 'participantName',
  patientUuid: 'patientUuid',
  senderRef: 'senderRef',
  studyId: 'studyId',
  studyParticipantId: 'studyParticipantId',
  submitterRef: 'submitterRef',
  surveyId: 'surveyId',
  surveyName: 'surveyName',
  userRef: 'userRef',
  writeAccess: 'writeAccess',
  score: 'score',
  differenceDays: 'differenceDays'
}

export const fields = {
  answers: { type: 'string' },
  completionDate: { type: 'Date' },
  creationDate: { type: 'Date' },
  id: {
    type: 'number',
    format: 'int64'
  },
  label: { type: 'string' },
  participantName: { type: 'string' },
  patientUuid: { type: 'string' },
  senderRef: { type: 'string' },
  studyId: {
    type: 'number',
    format: 'int64'
  },
  studyParticipantId: { type: 'string' },
  submitterRef: { type: 'string' },
  surveyId: {
    type: 'number',
    format: 'int64'
  },
  surveyName: { type: 'string', orderBy: 'survey.name' },
  userRef: { type: 'string' },
  writeAccess: {
    type: 'string',
    enum: ['PATIENT_AND_HCP', 'PATIENT_ONLY', 'HCP_ONLY']
  },
  score: { type: 'Array<string>', clientSideSort: true },
  differenceDays: {
    type: 'number',
    format: 'int64',
    orderBy: 'creationDate'
  }
}

export type SurveyParticipationDtoType = {
  answers: string,
  completionDate: Date,
  creationDate: Date,
  id: number,
  label: string,
  participantName: string,
  patientUuid: string,
  senderRef: string,
  studyId: number,
  studyParticipantId: string,
  submitterRef: string,
  surveyId: number,
  surveyName: string,
  userRef: string,
  writeAccess: string,
  score: Array<string>,
  differenceDays: number
}
