// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'
import PersonAdd from '@material-ui/icons/PersonAdd'
import { connect } from 'react-redux'

import Action from '../../Security/containers/action'
import { RaisedButton } from '../../Mui'
import { CREATE_USER } from '../../Security/actionNames'
import { Buttons } from '../../Static'
import { createUserDialogActions } from '../../CreateUserDialog/actions'

type PropsType = {
  setCreateUserDialog: typeof createUserDialogActions.setCreateUserDialog
}

function createUserButton(props: PropsType) {
  const { setCreateUserDialog } = props
  return (
    <Buttons>
      <Action name={CREATE_USER}>
        <RaisedButton
          label={<Translate value='users.createUser.title' />}
          onClick={() => setCreateUserDialog(true)}
          icon={<PersonAdd />}
        />
      </Action>
    </Buttons>
  )
}

export default connect(
  undefined,
  { ...createUserDialogActions }
)(createUserButton)
