// @flow
import React from 'react'
import { fields } from 'common-docdok/src/domain/user/types/userForCreationVm'
import { connect } from 'react-redux'

import DynamicFormDialog from '../../DynamicFormDialog'
import { createUserDialogActions } from '../actions'

type CreateUserDialogPropType = {
  open: boolean,
  setCreateUserDialog: typeof createUserDialogActions.setCreateUserDialog
}

function createUserDialog(props: CreateUserDialogPropType) {
  const { open, setCreateUserDialog } = props
  return (
    <DynamicFormDialog
      open={open}
      handleClose={() => setCreateUserDialog(false)}
      titleKey='users.createUser.title'
      form='createUser'
      fields={[
        {
          authorities: {
            type: 'multiSelect',
            options: [
              { value: 'ROLE_ADMIN', key: 'ROLE_ADMIN' },
              {
                value: 'ROLE_STUDY_COORDINATOR',
                key: 'ROLE_STUDY_COORDINATOR'
              },
              {
                value: 'ROLE_SUPPORT_ADMIN',
                key: 'ROLE_SUPPORT_ADMIN'
              }
            ],
            mandatory: true
          },
          salutation: {
            ...fields.salutation,
            mandatory: true
          }
        },
        {
          firstName: fields.firstName,
          lastName: fields.lastName
        },
        {
          email: fields.email,
          mobileNumber: fields.mobileNumber
        },
        {
          langKey: fields.langKey
        }
      ]}
    />
  )
}

createUserDialog.defaultProps = {
  initialValues: undefined
}

const mapStateToProps = (state: StoreType) => ({
  open: state.createUserDialog.showCreateUserDialog
})

export default connect(
  mapStateToProps,
  { setCreateUserDialog: createUserDialogActions.setCreateUserDialog }
)(createUserDialog)
