// @flow

export const fieldNames = {
  clientId: 'clientId',
  error: 'error',
  ipAddress: 'ipAddress',
  redirectUri: 'redirectUri',
  time: 'time',
  type: 'type',
  username: 'username'
}

export const fields = {
  clientId: { type: 'string' },
  error: { type: 'string' },
  ipAddress: { type: 'string' },
  redirectUri: { type: 'string' },
  time: { type: 'Time' },
  type: { type: 'string' },
  username: { type: 'string' }
}

export type KeycloakEventType = {
  clientId: string,
  error: string,
  ipAddress: string,
  redirectUri: string,
  time: Date,
  type: string,
  username: string
};
