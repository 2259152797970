// this is also used as order when multiple tours starts
export const tourIdentifiers = {
  messages: 'messages',
  home: 'home',
  profile: 'profile',
  patient: 'patient',
  patients: 'patients',
  patientSurvey: 'patientSurvey',
  surveys: 'surveys',
  chat: 'chat',
  timer: 'timer',
  sendSurvey: 'sendSurvey',
  attentionRequest: 'attentionRequest'
}

export const tours = [
  {
    id: tourIdentifiers.messages,
    steps: [
      {
        target: 'create-conversation-button',
        placement: 'left',
        yOffset: -10
      },
      {
        target: '.conversation-menu',
        placement: 'left',
        xOffset: 30,
        yOffset: -5
      }
    ]
  },
  {
    id: tourIdentifiers.home,
    steps: [
      {
        target: '.pageTitle',
        placement: 'bottom'
      },
      {
        target: 'home.cards.patients',
        placement: 'right'
      },
      {
        target: 'globalsearch-search-button',
        placement: 'left',
        xOffset: 20,
        yOffset: -5
      },
      {
        target: 'createPatient-header',
        placement: 'left',
        xOffset: 20,
        yOffset: -5
      },
      {
        target: 'notificationButton',
        placement: 'left',
        xOffset: 35,
        yOffset: 10
      },
      {
        target: 'start-tutorials',
        placement: 'left',
        xOffset: 25,
        yOffset: -5
      },
      {
        target: 'avatar-button',
        placement: 'left',
        xOffset: 20,
        yOffset: -5
      },
      {
        target: 'menu.billing',
        placement: 'top',
        xOffset: 70
      },
      {
        target: 'menu.help',
        placement: 'top',
        xOffset: 70
      },
      {
        target: 'menu.profile',
        placement: 'top',
        xOffset: 70
      }
    ]
  },
  {
    id: tourIdentifiers.profile,
    steps: [
      {
        target: 'profile-change-photo',
        placement: 'right'
      },
      {
        target: 'billing-requested-show-reminder',
        placement: 'right'
      },
      {
        target: 'availability-tab',
        placement: 'left'
      }
    ]
  },
  {
    id: tourIdentifiers.surveys,
    steps: [
      {
        target: '.pageTitle',
        placement: 'bottom'
      },
      {
        target: 'surveyDefintions-select-all',
        placement: 'right',
        yOffset: -15
      },
      {
        target: 'nbSent-col',
        placement: 'left',
        yOffset: -10
      }
    ]
  },
  {
    id: tourIdentifiers.patients,
    steps: [
      {
        target: '.pageTitle',
        placement: 'bottom'
      },
      {
        target: 'patient-search-bar-search-button',
        placement: 'right'
      },
      {
        target: 'activated-col',
        placement: 'left',
        yOffset: -5
      },
      {
        target: 'renewInvitation',
        placement: 'left',
        yOffset: -5,
        xOffset: 30
      },
      {
        target: 'addStudyParticipant',
        placement: 'left',
        yOffset: -5,
        xOffset: 30
      }
    ]
  },
  {
    id: tourIdentifiers.patient,
    steps: [
      {
        target: '.gender-img',
        placement: 'left',
        yOffset: -50
      },
      {
        target: 'surveys-tab',
        placement: 'left'
      },
      {
        target: 'integration-tab',
        placement: 'left'
      }
    ]
  },
  {
    id: tourIdentifiers.patientSurvey,
    steps: [
      {
        target: 'send-survey-button',
        placement: 'left',
        yOffset: -10
      },
      {
        target: 'patientSurveyParticipation',
        placement: 'top'
      },
      {
        target: 'survey-analytics-button',
        placement: 'left',
        yOffset: -10
      }
    ]
  },
  {
    id: tourIdentifiers.chat,
    steps: [
      {
        target: '.chat-content',
        placement: 'right'
      },
      {
        target: 'request-attention-button',
        placement: 'right'
      },
      {
        target: 'mute-conversation-button',
        placement: 'right'
      },
      {
        target: 'edit-conversation-button',
        placement: 'right'
      },
      {
        target: 'assign-to-me-button',
        placement: 'right'
      }
    ]
  },
  {
    id: tourIdentifiers.timer,
    steps: [
      {
        target: 'billing-code',
        placement: 'bottom'
      },
      {
        target: '.billing-start-stop-button',
        placement: 'bottom'
      }
    ]
  },
  {
    id: tourIdentifiers.sendSurvey,
    steps: []
  },
  {
    id: tourIdentifiers.attentionRequest,
    steps: []
  }
]
