// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'
import type { UserDtoType } from 'common-docdok/src/domain/user/types/userDto'
import toString from 'common-docdok/src/utils/toString'
import { healthrelationActions } from 'common-docdok/src/domain/healthrelation/actions'
import styled from 'styled-components'
import { connect } from 'react-redux'
import compose from 'ramda/src/compose'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import { introActions } from '../../../../../common/Intro/actions'
import { unreadMessagesCount } from '../../../../../common/Messaging/utils'
import palette from '../../../../../styles/palette'
import LatestConversations from './LatestConversations'
import Path from '../../../../../common/Security/containers/path'
import Card from '../components/card'
import { tourIdentifiers } from '../../../../../common/Intro/tours'
import { PageTitle } from '../../../../../common/Static'
import { DEFAULT_SPACING } from '../../../../../layouts/CoreLayout/components/CoreLayout'
import { coreLayoutActions } from '../../../../../layouts/CoreLayout/actions/index'
import { BILLING_EXPORT } from '../../../../../common/Security/actionNames'
import { billingActions } from '../../../../../common/Billing/actions'

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
`

const CardContainer = styled.div`
  padding: 2vw;
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  flex: 2 0 20vw;
  height: 100%;
`

function getMessage(conversationsCount, messagesCount) {
  let result = <Translate value='messages.noNewMessages' />
  if (conversationsCount === 1 && messagesCount === 1) {
    result = (
      <Translate value='messages.newMessage' messagesCount={messagesCount} conversationsCount={conversationsCount} />
    )
  } else if (conversationsCount === 1 && messagesCount > 1) {
    result = (
      <Translate value='messages.newMessages' messagesCount={messagesCount} conversationsCount={conversationsCount} />
    )
  } else if (conversationsCount > 1) {
    result = (
      <Translate
        value='messages.newMessagesMultiConv'
        messagesCount={messagesCount}
        conversationsCount={conversationsCount}
      />
    )
  }
  return <div data-messagescount={messagesCount}>{result}</div>
}

function getUpcomingAppointmentCount(upcomingAppointmentCount: number) {
  let result = (
    <Translate value='appointment.noUpcomingAppointments' upcomingAppointmentCount={upcomingAppointmentCount} />
  )
  if (upcomingAppointmentCount && upcomingAppointmentCount > 0) {
    result = <Translate value='appointment.upcomingAppointments' upcomingAppointmentCount={upcomingAppointmentCount} />
  }
  return <div data-appointments-count={upcomingAppointmentCount}>{result}</div>
}

type PropsType = {
  profile: UserDtoType,
  registerTour: typeof introActions.registerTour,
  unregisterTour: typeof introActions.unregisterTour,
  conversationsCount: number,
  messagesCount: number,
  fullScreen: boolean,
  setMenuVisibility: typeof coreLayoutActions.setMenuVisibility,
  downloadBillingRequested: typeof billingActions.downloadBillingRequested,
  getClinicMineRequested: typeof healthrelationActions.getClinicMineRequested,
  showActions: any,
  onlyStudyView: boolean,
  patientId: any
}

class Home extends React.Component<PropsType> {
  componentWillUnmount() {
    this.props.unregisterTour(tourIdentifiers.home)
  }

  componentDidMount() {
    const { registerTour, setMenuVisibility, getClinicMineRequested } = this.props
    getClinicMineRequested()
    setMenuVisibility(true)
    registerTour(tourIdentifiers.home)
  }

  closeMenuIfNeeded = () => {
    const { fullScreen, setMenuVisibility } = this.props
    if (!fullScreen) return () => {}
    return () => setMenuVisibility(false)
  }

  render() {
    const {
      profile,
      conversationsCount,
      messagesCount,
      downloadBillingRequested,
      upcomingAppointmentCount,
      showActions,
      onlyStudyView,
      patientId
    } = this.props

    const cards = [
      {
        id: 1,
        to: '/private/app/users',
        name: 'users',
        icon: 'people',
        color: palette.lightBlue800
      },
      {
        id: 2,
        to: onlyStudyView && patientId ? `/private/app/patients/${patientId}` : '/private/app/patients',
        name: 'patients',
        icon: 'people',
        color: palette.lightBlue700
      },
      {
        id: 3,
        to: '/private/app/messages',
        name: 'messages',
        icon: 'chat',
        color: palette.blueDark,
        message: getMessage(conversationsCount, messagesCount)
      },
      {
        id: 32,
        to: '/private/app/catalog',
        name: 'catalog',
        icon: 'library_books',
        color: palette.lightBlue900
      },
      {
        id: 4,
        to: '/private/app/surveys',
        name: 'surveys',
        icon: 'show_chart',
        color: palette.docdokBlue
      },
      {
        id: 5,
        to: '/private/app/profile',
        name: 'profile',
        icon: 'account_circle',
        color: palette.lightBlue800
      },
      {
        id: 6,
        to: '/private/app/professionals/team',
        name: 'team',
        icon: 'group_work_item',
        color: palette.lightBlue700
      },
      {
        id: 7,
        to: '/private/app/studies',
        name: 'studies',
        icon: 'assignment',
        color: palette.blueDark
      },
      {
        id: 8,
        action: {
          name: BILLING_EXPORT,
          method: downloadBillingRequested
        },
        name: 'billing',
        icon: 'cloud_download',
        color: palette.docdokBlue
      },
      {
        id: 9,
        to: '/private/app/appointment',
        name: 'appointment',
        icon: 'schedule',
        color: palette.blueDark,
        message: <div>{getUpcomingAppointmentCount(upcomingAppointmentCount)}</div>
      }
    ]

    return (
      <div style={{ height: '100%' }}>
        {profile && (
          <PageTitle className='pageTitle'>
            <Translate value='home.welcomeMessage' name={toString.person(profile)} />
          </PageTitle>
        )}

        <Row>
          <div style={{ marginRight: `-${DEFAULT_SPACING}`, padding: '2vw', flex: '1 0 100px' }}>
            <Path name='/messages/conversation/:conversationId'>
              <LatestConversations />
            </Path>
          </div>

          <CardContainer>
            {cards.map(card => {
              if (showActions && showActions.includes(card.name)) {
                return (
                  <Card
                    {...card}
                    key={card.id}
                    name={
                      onlyStudyView && patientId && card.name === 'patients'
                        ? 'overview'
                        : card.name
                    }
                    closeMenu={this.closeMenuIfNeeded()}
                  />
                )
              }
              return null
            })}
          </CardContainer>
        </Row>
      </div>
    )
  }
}
const mapStateToProps = (state: StoreType) => {
  const { profile, messaging, healthrelation, keycloak } = state
  const patients = state.healthrelation && state.healthrelation.patients
  const { conversationsCount, messagesCount } = unreadMessagesCount(messaging)
  const upcomingAppointmentCount = healthrelation.appointments.length

  const { clinicMine, customizedDashboardForClinics } = state.healthrelation
  let showActions = [
    'users',
    'patients',
    'messages',
    'surveys',
    'profile',
    'studies',
    'appointment'
  ]

  if (
    customizedDashboardForClinics &&
    !customizedDashboardForClinics.some(
      (name) => name === (clinicMine && clinicMine.name)
    )
  ) {
    showActions = [...showActions, 'catalog', 'team', 'billing']
  }

  let onlyStudyView = false
  const clinicName = clinicMine && clinicMine.name
  if (
    customizedDashboardForClinics &&
    clinicName &&
    customizedDashboardForClinics.some(
      (name) => name === clinicName
    )
  ) {
    onlyStudyView = true
  }

  if (
    profile
    && Array.isArray(profile.authorities)
    && profile.authorities.includes('ROLE_PATIENT')
    && Array.isArray(patients)
    && patients.length > 0
  ) {
    if (localStorage.getItem('redirected') !== '1' && keycloak.loggedIn && !onlyStudyView && clinicName) {
      window.location.href = `/private/app/patients/${patients[0].uuid}`
    }
  }

  const isPatient = profile && profile.authorities && profile.authorities.includes('ROLE_PATIENT')
  return {
    profile,
    conversationsCount,
    messagesCount,
    upcomingAppointmentCount,
    showActions,
    onlyStudyView,
    patientId: isPatient && patients && patients[0] && patients[0].uuid
  }
}

export default compose(
  connect(
    mapStateToProps,
    {
      ...introActions,
      setMenuVisibility: coreLayoutActions.setMenuVisibility,
      downloadBillingRequested: billingActions.downloadBillingRequested,
      getClinicMineRequested: healthrelationActions.getClinicMineRequested
    }
  ),
  withMobileDialog()
)(Home)
