// @flow
import type { ActionType, ExtractReturn } from 'common-docdok/src/types'
import { profileActionTypes, profileActions } from '../actions'
// State Definition
export type ProfilePageStateType = {
  showUploadAvatar: boolean
}

const initialState = {
  showUploadAvatar: false
}

function setCreateStudyDialog(
  state: ProfilePageStateType,
  action: ExtractReturn<typeof profileActions.setUploadUserAvatarDialog>
) {
  return { ...state, showUploadAvatar: action.payload }
}

const ACTION_HANDLERS = {
  [profileActionTypes.SET_UPLOAD_USER_AVATAR_DIALOG]: setCreateStudyDialog
}

export default function profileReducer(
  state: ProfilePageStateType = initialState,
  action: ActionType
): ProfilePageStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
