// @flow
import type { ActionType, ExtractReturn } from 'common-docdok/src/types'
import { setEventDialogHandlerActions, eventHandlerDilogActionTypes } from '../actions'

// State Definition
export type EventHandlerDialogStateType = {
  showEventHandlerDialog: boolean
}

// Default State
const initialState: EventHandlerDialogStateType = {
  showEventHandlerDialog: false
}

// Action Handlers

function setEventHandlerDialog(
  state: EventHandlerDialogStateType,
  action: ExtractReturn<typeof setEventDialogHandlerActions.setEventDialogHandler>
): EventHandlerDialogStateType {
  return {
    ...state,
    showEventHandlerDialog: action.payload
  }
}

const ACTION_HANDLERS = {
  [eventHandlerDilogActionTypes.SET_EVENT_HANDLER_DIALOG]: setEventHandlerDialog
}

export default function eventReducer(
  state: EventHandlerDialogStateType = initialState,
  action: ActionType
): EventHandlerDialogStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
