// @flow
import { put, takeEvery } from 'redux-saga/effects'
import type { GenType } from 'common-docdok/src/types'
import { userActionTypes } from 'common-docdok/src/domain/user/actions'

import { infoPanelActions } from '../../../../../common/InfoPanel/actions'

function* renewInvitationSucceeded(): GenType {
  yield put(infoPanelActions.showMessage('users.renewInvitation.success'))
}
function* disableUserSucceeeded(): GenType {
  yield put(infoPanelActions.showMessage('users.disableUser.success'))
}

export function* deleteUserSucceeded(): GenType {
  yield put(
    infoPanelActions.showMessage('patients.actions.deletePatient.success', {
      name: ''
    })
  )
}

export default function usersSaga() {
  return [
    takeEvery(userActionTypes.RENEW_INVITATION_SUCCEEDED, renewInvitationSucceeded),
    takeEvery(userActionTypes.DISABLE_USER_SUCCEEDED, disableUserSucceeeded),
    takeEvery(userActionTypes.DELETE_USER_SUCCEEDED, deleteUserSucceeded)
  ]
}
