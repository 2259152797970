// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'
import { getKeycloakClient } from 'common-docdok/src/common/Keycloak/keycloakProvider'
import CoreLayout from '../layouts/CoreLayout'
import PrivateLayout from '../layouts/PrivateLayout'

// -- APPEND IMPORTS HERE --
import catalogRoute from './private/app/Catalog'
import professionalsRoute from './private/app/Professionals'
import patientsRoute from './private/app/Patients'
import clinicsRoute from './private/app/Clinics'
import messagesRoute from './private/app/Messages'
import surveysRoute from './private/app/Surveys'
import profileRoute from './private/app/Profile'
import usersRoute from './private/app/Users'
import studiesRoute from './private/app/Studies'
import appointmentRoute from './private/app/Appointments'
import surveyparticipationRoute from './private/app/SurveyParticpation'
import Home from './private/app/Home'
import eventsRoute from './private/app/Events'
import { NotFound, AccessDenied } from './public/Static'
import PublicPatientIdResolver from './public/PublicPatientIdResolver'
import PublicSurveyParticipation from './public/surveys'

/*  Note: Instead of using JSX, we recommend using react-router
    PlainRoute objects to build route definitions.   */

const DefaultRouteContainer = ({ children }: Object) => <React.Fragment>{children}</React.Fragment>

export default (store: any) => ({
  path: '/',
  component: DefaultRouteContainer,
  onEnter: (nextState: any, replace: Function) => {
    if (nextState.location.pathname === '/') {
      replace(`private/app${nextState.location.search}`)
    }
  },
  childRoutes: [
    {
      path: '/index.html',
      onEnter: (nextState: any, replace: Function) => {
        replace('private/app')
      }
    },
    {
      path: 'private',
      component: PrivateLayout,
      hidden: true,
      childRoutes: [
        {
          path: 'app',
          component: CoreLayout,
          indexRoute: Home,
          name: (
            <span id='breadcrumb0'>
              <Translate value='menu.home' />
            </span>
          ),
          childRoutes: [
            {
              path: 'logout',
              onEnter: () => getKeycloakClient().logout()
            },
            // -- APPEND ROUTES HERE --
            catalogRoute(store),
            professionalsRoute(store),
            patientsRoute(store),
            clinicsRoute(store),
            messagesRoute(store),
            surveysRoute(store),
            profileRoute(store),
            eventsRoute(store),
            usersRoute(store),
            studiesRoute(store),
            surveyparticipationRoute(store),
            appointmentRoute(store)
          ]
        },
        {
          path: 'surveyparticipation/:participationId',
          /*  Async getComponent is only invoked when route matches   */
          getComponent(nextState: any, cb: Function) {
            /*  Webpack - use 'require.ensure' to create a split point
                and embed an async module loader (jsonp) when bundling   */
            (require: any).ensure(
              [],
              (require) => {
                /*  Webpack - use require callback to define
                    dependencies for bundling   */
                const container = require('./private/SurveyParticipation').default

                /*  Return getComponent   */
                cb(null, container)

                /* Webpack named bundle   */
              },
              'surveyparticipation'
            )
          },

          childRoutes: []
        }
      ]
    },
    {
      path: 'public',
      component: ({ children }: Object) => <React.Fragment>{children}</React.Fragment>,
      hidden: true,
      childRoutes: [
        {
          component: PrivateLayout,
          path: 'patients',
          childRoutes: [
            {
              component: PublicPatientIdResolver,
              path: ':publicPatientId'
            }
          ]
        },
        {
          path: 'notfound',
          component: NotFound
        },
        {
          path: 'accessdenied',
          component: AccessDenied
        },
        {
          path: 'surveyparticipation/:surveyId',
          component: PublicSurveyParticipation
        }
      ]
    },
    {
      path: '/*',
      onEnter: (nextState: any, replace: Function) => {
        replace('/public/notfound')
      }
    }
  ]
})

/*
    Note: childRoutes can be chunked or otherwise loaded programmatically
    using getChildRoutes with the following signature:

    getChildRoutes (location, cb) {
      require.ensure([], (require) => {
        cb(null, [
          // Remove imports!
          require('./Counter').default(store)
        ])
      })
    }

    However, this is not necessary for code-splitting! It simply provides
    an API for async route definitions. Your code splitting should occur
    inside the route `getComponent` function, since it is only invoked
    when the route exists and matches.

*/
