/* eslint-disable no-bitwise, operator-assignment */
// @flow

import React from 'react'
import styled from 'styled-components'
import { isStageOrProd } from '../../../common/utils'

function hashCode(str) {
  // java String#hashCode
  let hash = 0
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

function invertColor(hexTripletColor) {
  let color = hexTripletColor
  // remove #
  color = parseInt(color, 16) // convert to integer
  color = 0xffffff ^ color // invert three bytes
  color = color.toString(16) // convert to hex
  color = `000000${color}`.slice(-6) // pad with leading zeros
  // prepend #
  return color
}

function intToRGB(i) {
  const c = (i & 0x00ffffff).toString(16).toUpperCase()

  return '00000'.substring(0, 6 - c.length) + c
}

const RibbonContainer = styled.div`
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  border: 25px solid transparent;

  position: fixed;
  bottom: 20px;
  left: -50px;
  padding: 0 10px;
  width: 120px;
  font-family: sans-serif;
  size: 11px;
  z-index: 10000;
`

const RibbonText = styled.div`
  position: absolute;
  top: -22px;
  left: 30px;
`

export default function Ribbon() {
  let ribbon = <div />
  const { hostname } = window.location
  if (!isStageOrProd()) {
    const text = hostname.split('.')[0]
    const bgColor = intToRGB(hashCode(hostname))
    const color = invertColor(bgColor)
    ribbon = (
      <RibbonContainer style={{ borderTop: `25px solid #${bgColor}`, color: `#${color}` }}>
        <RibbonText>{text}</RibbonText>
      </RibbonContainer>
    )
  }
  return ribbon
}
