export default {
  en: {
    name: 'User',
    fields: {
      activated: 'App Activated',
      salutation: 'Salutation',
      salutationOpts: {
        Mr: 'Mr',
        Ms: 'Ms',
        Mrs: 'Mrs.',
        Prof: 'Prof',
        Dr: 'Dr',
        PractMed: 'Pract Med'
      },
      firstName: 'Firstname',
      lastName: 'Lastname',
      mobileNumber: 'Mobile',
      email: 'Email',
      langKey: 'Language',
      langKeyOpts: {
        en: 'English',
        de: 'German',
        fr: 'French',
        it: 'Italian'
      },
      authorities: 'Role',
      authoritiesOpts: {
        ROLE_SUPPORT_ADMIN: 'Support Admin',
        ROLE_ADMIN: 'Administrator',
        ROLE_PHYSICIAN: 'Physician',
        ROLE_STUDY_COORDINATOR: 'Study Coordinator',
        ROLE_MPA: "Doctor's Assistant",
        ROLE_PATIENT: 'Patient'
      },
      gender: 'Gender',
      genderOpts: {
        MALE: 'male',
        FEMALE: 'female',
        DIVERS: 'divers'
      }
    }
  },
  de: {
    name: 'Benutzer',
    fields: {
      activated: 'App Aktiviert',
      salutation: 'Anrede',
      salutationOpts: {
        Mr: 'Herr',
        Ms: 'Frau',
        Prof: 'Prof. Dr. med',
        Dr: 'Dr. med',
        PractMed: 'Prakt. med'
      },
      firstName: 'Vorname',
      lastName: 'Nachname',
      mobileNumber: 'Mobiltelefon',
      email: 'Email',
      langKey: 'Sprache',
      langKeyOpts: {
        en: 'Englisch',
        de: 'Deutsch',
        fr: 'Französisch',
        it: 'Italienisch'
      },
      authorities: 'Rolle',
      authoritiesOpts: {
        ROLE_SUPPORT_ADMIN: 'Support Admin',
        ROLE_ADMIN: 'Administrator',
        ROLE_PHYSICIAN: 'Arzt',
        ROLE_STUDY_COORDINATOR: 'Studienkoordinator',
        ROLE_MPA: 'Medizinische Praxis Assistent/in',
        ROLE_PATIENT: 'Patient'
      },
      gender: 'Geschlecht',
      genderOpts: {
        MALE: 'männlich',
        FEMALE: 'weiblich',
        DIVERS: 'divers'
      }
    }
  }
}
