// @flow
import React from 'react'
import { Translate, I18n } from 'react-redux-i18n'
import participants from './containers/Participants'
import { checkRoute } from '../../../../common/Security/utils'

export default (store: any) => ({
  path: 'surveys',
  name: (
    <span id='breadcrumb1'>
      <Translate value='menu.surveys' />
    </span>
  ),
  /*  Async getComponent is only invoked when route matches   */
  onEnter: (state: any, replace: Function) => {
    checkRoute(store.getState(), state.location.pathname, replace)
  },
  getComponent(nextState: any, cb: Function) {
    /*  Webpack - use 'require.ensure' to create a split point
        and embed an async module loader (jsonp) when bundling   */
    (require: any).ensure(
      [],
      (require) => {
        /*  Webpack - use require callback to define
          dependencies for bundling   */
        const container = require('./containers/SurveyDefinitions').default
        /*  Return getComponent   */
        cb(null, container)
        /* Webpack named bundle   */
      },
      'surveys'
    )
  },
  childRoutes: [
    {
      name: (
        <span id='breadcrumb2'>
          <Translate value='menu.surveysParticipants' />
        </span>
      ),
      path: ':surveyId/participants',
      component: participants,
      onEnter: (state: any, replace: Function) => {
        checkRoute(store.getState(), state.location.pathname, replace)
      },
      childRoutes: [
        {
          path: ':filter',
          getDisplayName() {
            const name = I18n.t('menu.surveysParticipantsCompleted')
            return <span id='breadcrumb3'>{name}</span>
          },
          component: participants,
          onEnter: (state: any, replace: Function) => {
            checkRoute(store.getState(), state.location.pathname, replace)
          }
        }
      ]
    }
  ]
})
