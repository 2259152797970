// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'
import { injectReducer } from '../../../../store/reducers'
import { checkRoute } from '../../../../common/Security/utils'

export default (store: any) => ({
  path: 'catalog',
  name: (
    <span id='breadcrumb1'>
      <Translate value='menu.catalog' />
    </span>
  ),
  onEnter: (state: any, replace: Function) => {
    checkRoute(store.getState(), state.location.pathname, replace)
  },

  getComponent(nextState: any, cb: Function) {
    (require: any).ensure(
      [],
      (require) => {
        const container = require('./containers/Catalog').default
        const reducer = require('./reducers').default

        /*  Add the reducer to the store on key 'counter'  */
        injectReducer(store, { key: 'catalog', reducer })

        cb(null, container)
      },
      'catalog'
    )
  }
})
