export default {
  en: {
    createClinic: {
      title: 'Create Clinic',
      success: 'Clinic successfully created'
    },
    updateClinic: {
      title: 'Update Clinic',
      success: 'Clinic successfully update'
    },
    actions: {
      toggleChat: {
        title: 'Enable/Disable Chat'
      }
    }
  },
  de: {
    createClinic: {
      title: 'Klinik erstellen',
      success: 'Klinik wurde erfolgreich erstellt'
    },
    updateClinic: {
      title: 'Update Clinic',
      success: 'Clinic successfully update'
    },
    actions: {
      toggleChat: {
        title: 'Chat Aktivieren/Deaktivieren '
      }
    }
  }
}
