/* eslint-disable */
import React from 'react'
import { getKeycloakClient } from 'common-docdok/src/common/Keycloak/keycloakProvider'
import { doManualLogin } from '../../../keycloak';
import SurveyParticipation from '../../private/SurveyParticipation';
import api from 'common-docdok/src/api';
import { getEnvFromHostName } from '../../../common/utils';

var called = false;
const dummyPhysicianPassword = 'aWJGVyU1cXFdVXkzdl8yKmM=';

const webUser = 'piswebserver';
const webUserPassword = {
  it: 'ZWQwYzQ1NjYtOWEwNy00ODM1LWJmY2QtYjZkZTM0YjkwZDg0',
  qa: 'ZWQwYzQ1NjYtOWEwNy00ODM1LWJmY2QtYjZkZTM0YjkwZDg0',
  stage: 'MjY0OGE2MDYtODc2Ni00NTEwLWE0YzEtNjVmNDMzMmI2M2Vj',
  production: 'MjY0OGE2MDYtODc2Ni00NTEwLWE0YzEtNjVmNDMzMmI2M2Vj',
  productionDe: 'ZWQwYzQ1NjYtOWEwNy00ODM1LWJmY2QtYjZkZTM0YjkwZDg0'
};

class PublicSurveyParticipation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newToken: null,
      loading: true,
      participationId: 0,
    }
    if (called) {return;}
    this.initDummyProcess()
    console.log('this.props ', this.props)
    called = true;
  }

  async initDummyProcess() {
    try {
      this.doPhysicianLogin()    
    } catch (error) {
      console.log({ error })
    }
  }

  async addParticipation() {
    
    // call api to add Participation
    try {
      const patients = await api.getPatients()
      if (
        patients &&
        patients.data &&
        Array.isArray(patients.data) &&
        patients.data.length > 0
      ) {
        const patient = patients.data[0]
        if (patient) {
          const reqPayload = {
            "userRef": patient.userRef,
            "surveyId": this.getSurveyIdFromName(),
            "patientUuid": patient.uuid,
            "scheduling": null
          }
          const response = await api.sendSurvey(reqPayload);
          console.log({ response })
          if (response && response.data) {
            this.setState({
              participationId: response.data.id
            })
          }
        }
      }
    } catch (error) {
      console.log({ error })
    } finally {
      this.setState({
        loading: false
      })
    }
  }

  getSurveyIdFromName() {
    return String(this.props.params.surveyId).split('_').reverse()[0];
  }
  async doPhysicianLogin() {
    const token = getKeycloakClient().getToken();
    if (token) {
      getKeycloakClient().logout();
    }

    const env = getEnvFromHostName()
    const isWebDeProd = window ? String(window.location.host).split('.').reverse()[0] === 'de' : false
    let passwordToConsider = webUserPassword[env] || webUserPassword.production
    if (isWebDeProd) {
      passwordToConsider = webUserPassword.productionDe
    }
    await doManualLogin(
      undefined,
      undefined,
      webUser,
      'client_credentials',
      passwordToConsider
    );

    // get email of physician
    const emailResponse = await api.getAnonymousSurveyPhysician(this.getSurveyIdFromName());
    console.log({ emailResponse });
    if (emailResponse && emailResponse.data) {
      getKeycloakClient().logout();
      await doManualLogin(emailResponse.data.email, dummyPhysicianPassword);
      const newToken = getKeycloakClient().getToken();
      this.setState({
        newToken
      }, () => {
        this.addParticipation();
      })
    }
  }

  render() {
    if (this.state.newToken && this.state.participationId !== 0) {
      return <SurveyParticipation {...this.props} public params={{ participationId: this.state.participationId }}/>
    }
    return <p>Loading...</p>;
  }
}

export default PublicSurveyParticipation
