// @flow
import React from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import swal from 'sweetalert'
import type { FieldComponentPropsType } from './formField'
import { UploadButton } from '../../../Mui'

export default class FileUploadField extends React.Component<FieldComponentPropsType> {
  input: ?HTMLInputElement = null

  uploadFile = (files: Array<any>) => {
    const {
      propertyDefinition: {
        changeFile
      }
    } = this.props
    const { onChange } = this.props.input

    const [file] = files
    const fr = new FileReader()
    if (file) {
      fr.onload = async (event) => {
        const enc = new TextDecoder('utf-8')
        const buffer = event.target.result
        let text
        try {
          text = enc.decode(buffer)
        } catch (e) {
          // eslint-disable-next-line
          console.log(e)
          // probably no text file
        }
        if (changeFile) {
          const response = await swal({
            title: I18n.t('forms.changeFile'),
            text: I18n.t('forms.changeFileInfo'),
            buttons: {
              cancel: I18n.t('forms.cancel'),
              catch: {
                text: I18n.t('forms.ok'),
                value: true
              }
            },
            icon: 'info'
          })
          if (!response) {
            onChange(null)
            return
          }
        }
        onChange({
          file,
          bytes: buffer,
          text
        })
      } // onload fires after reading is complete
      fr.readAsArrayBuffer(file) // begin reading
    } else {
      onChange(null)
    }
  }

  render() {
    const {
      input: { name, value },
      meta: { form, touched, error },
      propertyDefinition: {
        changeFile
      }
    } = this.props

    return (
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <div>
          <Translate value={`forms.${form}.${name}`} />:
        </div>
        <UploadButton
          type='file'
          accept='*'
          color='primary'
          labelPosition='before'
          containerElement='label'
          onChange={this.uploadFile}
          id={`${form}-${name}`}
          data-test={`${form}-${name}`}
        >
          <Translate value={changeFile ? 'forms.changeFile' : 'forms.chooseFile'} />
        </UploadButton>
        <div style={{ marginLeft: 10, color: touched && error && 'red' }}>
          {value ? value.file.name : <Translate value='forms.noFileChoosen' />}
        </div>
      </div>
    )
  }
}
