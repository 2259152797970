/**
 * @flow
 */

import type { UserDtoType } from 'common-docdok/src/domain/user/types/userDto'

export const updateUserDialogActionTypes = {
  SET_UPDATE_USER_DIALOG: 'SET_UPDATE_USER_DIALOG'
}

export type UpdateUserDialogActionType = $Keys<typeof updateUserDialogActionTypes>

export const updateUserDialogActions = {
  setUpdateUserDialog(payload: ?UserDtoType) {
    return {
      type: updateUserDialogActionTypes.SET_UPDATE_USER_DIALOG,
      payload
    }
  }
}
