import React from 'react'
import { connect } from 'react-redux'
import compose from 'ramda/src/compose'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Close from '@material-ui/icons/Close'
import moment from 'moment'
import { I18n } from 'react-redux-i18n'
import { ContentState, convertFromHTML, convertFromRaw, EditorState } from 'draft-js'
import { isArray } from 'lodash'

import { fields, PatientActivitiesDtoType } from 'common-docdok/src/domain/healthrelation/types/patientActivitiesDto'
import { healthrelationActions } from 'common-docdok/src/domain/healthrelation/actions'

import DynamicFormDialog from '../../DynamicFormDialog'
import FormField from '../../DynamicForm/components/fields/formField'
import { Spacer } from '../../Static'
import cachedEntity from '../../CachedEntity'
import { setEventDialogHandlerActions } from '../actions'
import { scheduleEventRenderer } from '../utils/renderer'
import formValidator from '../../DynamicForm/formValidator'
import { UploadButton } from '../../Mui'
import { resizeImage } from '../../Messaging/utils'

import pdfLogo from '../assets/pdfLogo.jpg'
import docLogo from '../assets/docLogo.png'

export const SUPPORTED_TYPES = 'image/png,image/jpeg,application/pdf,.doc, .docx,.ppt'

const SIZE_10_M = 10485760
const maxSizeForMimeType = (mimeType) => {
  if (mimeType.includes('video')) return 5 * SIZE_10_M
  return SIZE_10_M
}

const prepareFile = (file: File) => {
  return resizeImage(file).then(({ arrayBuffer, dataUrl, size }) => ({
    arrayBuffer,
    dataUrl,
    size
  }))
}

const patterns = {
  daily: 'daily'
}

type PropsType = {
  entity: PatientActivitiesDtoType,
  images?: any,
  setEventHandlerDialog: typeof setEventDialogHandlerActions.setEventDialogHandler,
  showEventHandlerDialog: boolean,
  handleDelete: (item: any) => null,
  handleDeleteImage: (item: any) => null,
  handleSubmit: (item: any) => null,
  addFileRequested: (item: any) => null,
  patientUuid?: string,
  physicianUuid?: string,
  isCoach?: boolean
}
class EventHandlerDialog extends React.Component<PropsType> {
  state = {
    editorState: null,
    file: null
  }

  static defaultProps = {
    patientUuid: '',
    physicianUuid: '',
    images: [],
    isCoach: false
  }


  addSelectedFile(patientActivityId, file: File) {
    const { addFileRequested } = this.props
    prepareFile(file).then(({ arrayBuffer, dataUrl, size }) => {
      if (size <= maxSizeForMimeType(file.type)) {
        addFileRequested(patientActivityId, {
          name: file.name,
          mimeType: file.type,
          arrayBuffer,
          preview: dataUrl,
          originalSize: size
        })
      }
    })
  }

  render() {
    const {
      showEventHandlerDialog,
      setEventHandlerDialog,
      entityData: patientEvent,
      handleDelete,
      handleDeleteImage,
      patientUuid,
      physicianUuid,
      images,
      isCoach
    } = this.props

    // // if the defintion not yet loaded we do not render the dialog
    // if (surveyDefinitionId !== undefined && !surveyDefinition) return null

    let initialValues = {}

    if (patientEvent) {
      initialValues = {
        status: 'SCHEDULED',
        pattern: patterns.daily,
        patientUuid,
        physicianUuid,
        cb: (res) => {
          if (res.succeeded && this.state.file) {
            const selectedFile = this.state.file
            this.setState({ editorState: null, file: null })
            return selectedFile
          }
          this.setState({ editorState: null, file: null })
          return null
        },
        ...patientEvent,
        name: patientEvent.name,
        type: patientEvent.type,
        startdate: moment(patientEvent.startTime).toDate(),
        startTime: moment(patientEvent.startTime).toDate(),
        endDate: moment(patientEvent.endTime).toDate(),
        endTime: patientEvent.endTime
          ? moment(patientEvent.endTime).toDate()
          : moment()
            .add(15, 'minutes')
            .toDate(),
        notes: patientEvent.notes,
        isCoach
      }
    }

    let content = {}
    try {
      content = initialValues.description ? JSON.parse(initialValues.description) : {}
    } catch (error) {
      content = {}
    }
    const contentData = content.blocks
      ? EditorState.createWithContent(convertFromRaw(content))
      : EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(initialValues.description || '').contentBlocks,
          convertFromHTML(initialValues.description || '').entityMap
        )
      )
    // seperated saga logic for inital upload and update
    const formName = 'editEvent'
    const imageSaved = isArray(images) && images.length ? images[0] : null
    let imageSavedPreview = imageSaved

    if (initialValues.url && String(initialValues.url).includes('.')) {
      const [extension] = String(initialValues.url).split('.').reverse()
      if (extension === 'pdf') {
        imageSavedPreview = pdfLogo
      }
      if (extension === 'doc' || extension === 'docx') {
        imageSavedPreview = docLogo
      }
    }

    return (
      <Paper>
        <DynamicFormDialog
          addionalButtons={
            initialValues.id ? (
              <Button
                variant="outlined"
                style={{
                  marginRight: 12,
                  padding: '4px 10px'
                }}
                onClick={() => handleDelete(initialValues)}
              >
                {I18n.t('actions.delete')}
              </Button>
            ) : null
          }
          fullWidth
          maxWidth="md"
          open={showEventHandlerDialog}
          handleClose={() => {
            this.setState({
              editorState: null,
              file: null
            })
            setEventHandlerDialog(false)
          }}
          titleKey={
            initialValues.id
              ? `${I18n.t('actions.update')}`
              : `${I18n.t('actions.add')}`
          }
          form={formName}
          handleDelete={null}
          initialValues={initialValues || { recurrence: 'none' }}
          fields={{
            type: {
              ...fields.type,
              type: 'select',
              enum: isCoach ? fields.type.enum : fields.type.enum.filter(e => e !== 'coaching')
            },
            name: {
              ...fields.name
            },
            startdate: fields.startTime,
            startTime: { type: 'Time', mandatory: true },
            endDate: { type: 'Date', mandatory: true },
            endTime: { type: 'Time', mandatory: true },
            location: fields.location,
            recurrence: {
              ...fields.recurrence,
              style: 'radio'
            },
            pattern: {
              ...fields.pattern,
              style: 'radio',
              enum: ['daily', 'weekly', 'monthly']
            },
            description: { ...fields.description, multiline: true },
            file: { type: 'file', mandatory: false },
            notes: {
              ...fields.notes,
              multiline: true
            },
            status: {
              ...fields.status,
              style: 'radio'
            }
          }}
          render={(def: Object, data: Object) => (
            <Grid container spacing={2}>
              {!initialValues.id ? (
                <Grid item md={12}>
                  <FormField {...def} name="type" fullWidth disabled={patientEvent.name} />
                </Grid>
              ) : null}
              <Grid item md={12}>
                <FormField {...def} name="name" fullWidth />
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <FormField {...def} name="startdate" />
                </Grid>
                <Grid container md={6}>
                  <Grid item md={6}>
                    <FormField {...def} name="startTime" />
                  </Grid>
                  <Grid item md={6}>
                    <FormField {...def} name="endTime" minDate={new Date()} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={12}>
                <FormField {...def} name="location" />
              </Grid>
              <Grid item md={12}>
                <FormField {...def} name="status" isRow />
              </Grid>
              <Spacer height={20} />
              <hr />
              <Grid item md={12}>
                <FormField {...def} name="recurrence" isRow />
              </Grid>
              <Grid item md={12}>
                {scheduleEventRenderer(def, data)}
              </Grid>
              {data.recurrence === 'schedule' ? (
                <Grid item md={12}>
                  <FormField {...def} name="endDate" minDate={new Date()} />
                </Grid>
              ) : null}
              <Grid item md={12}>
                <FormField
                  {...def}
                  name="description"
                  fullWidth
                  multiline
                  isRichTextArea
                  setEditorState={(e) => this.setState({ editorState: e })}
                  editorState={this.state.editorState || contentData}
                />
              </Grid>
              <Grid item md={2} style={{ marginTop: 12 }}>
                {imageSaved ? (
                  <div
                    style={{
                      position: 'relative',
                      height: 120,
                      width: 120,
                      padding: 4,
                      border: '1px solid gray',
                      borderRadius: 8,
                      marginBottom: 12
                    }}
                  >
                    <Close
                      style={{
                        position: 'absolute',
                        right: 0,
                        zIndex: 1000,
                        cursor: 'pointer',
                        background: 'gray'
                      }}
                      onClick={() => handleDeleteImage({
                        ...initialValues,
                        cb: null
                      })}
                    />
                    <a href={imageSaved} target="_blank" rel="noopener noreferrer">
                      <img
                        src={imageSavedPreview}
                        style={{ height: 120, width: 120 }}
                        alt="act-img"
                      />
                    </a>
                  </div>
                ) : null}
                <UploadButton
                  id="browse-button"
                  type="file"
                  accept={SUPPORTED_TYPES}
                  variant="contained"
                  color="primary"
                  labelposition="before"
                  containerelement="label"
                  onChange={(files) => {
                    const file = files[0]
                    this.setState({
                      file
                    })
                  }}
                >
                  {I18n.t('forms.chooseFile')}
                </UploadButton>
                {this.state.file ? this.state.file.name : ''}
              </Grid>
              {isCoach ? (
                <Grid item md={12}>
                  <FormField {...def} name="notes" fullWidth multiline />
                </Grid>
              ) : null}
            </Grid>
          )}
          validator={(fieldsAre, values) => {
            const errors = formValidator(fieldsAre)(values)
            if (moment(values.endTime).diff(moment(values.startTime), 'minutes') < 1) {
              errors.endTime = I18n.t('endTimeError')
            }
            if (moment(values.startdate).isSameOrAfter(values.endDate)) {
              errors.endDate = 'invalid'
            }
            try {
              if (JSON.stringify(values.description).length > 5000) {
                errors.description = I18n.t('forms.errors.maxLength', {
                  maxLength: 5000
                })
              }
            } catch (error) {
              errors.description = undefined
            }
            return errors
          }}
        />
      </Paper>
    )
  }
}

const mapStateToProps = () => ({})

export default compose(
  connect(mapStateToProps, { ...setEventDialogHandlerActions, ...healthrelationActions }),
  cachedEntity
)(EventHandlerDialog)
