// currently don't know where the flow error is
import React from 'react'
import Button from '@material-ui/core/Button'
import { Translate } from 'react-redux-i18n'
import { connect } from 'react-redux'
import DialogActions from '@material-ui/core/DialogActions'
import styled from 'styled-components'

import { dynamicFormActions } from '../../actions'
import SubmitErrors from './submitErrors'
import type { StateType } from '../../../../store/storeDefinition'

const FooterContainer = styled.div`
  display: flex;
  align-items: flex-end;
  max-width: inherit;
  justify-content: ${(props) => (props.form === 'targetForm' || props.form === 'createCatalog' ? 'center' : 'unset')};
`

type PropsType = {
  children: ?any,
  serverErrors: ?Object,
  showReset: ?boolean,
  reset: () => void,
  formSubmitting: boolean,
  saveLabelKey: ?string,
  dirty: boolean,
  form: ?string
}

function formFooter(props: PropsType) {
  const {
    children,
    serverErrors,
    reset,
    formSubmitting,
    saveLabelKey,
    showReset,
    dirty,
    form
    /* doSubmit */
  } = props
  return (
    <FooterContainer form={form}>
      <SubmitErrors serverErrors={serverErrors} form={form} />
      <DialogActions>
        {children}
        {showReset && (
          <Button
            id='reset'
            style={{ marginLeft: 10 }}
            onClick={() => {
              if (!formSubmitting) reset()
            }}
            disabled={formSubmitting}
            variant='contained'
            color='primary'
          >
            <Translate value='forms.reset' />
          </Button>
        )}
        <Button
          id='save'
          style={{ marginLeft: 10 }}
          type='submit'
          disabled={formSubmitting || !dirty}
          variant='contained'
          color='primary'
        >
          <Translate value={saveLabelKey || 'forms.save'} />
        </Button>
      </DialogActions>
    </FooterContainer>
  )
}

type FooterPropsType = {
  form: string
}

function mapStateToProps(state: StateType, ownProps: FooterPropsType) {
  const { form } = ownProps
  return {
    locale: state.i18n.locale,
    submitErrors: state.form[form] ? state.form[form].submitErrors : undefined,
    formSubmitting: state.form[form] ? state.form[form].submitting : false
  }
}

export default connect(
  mapStateToProps,
  { ...dynamicFormActions }
)(formFooter)
