export default {
  en: {
    name: 'Device',
    fields: {
      id: 'Id',
      name: 'Name'
    }
  },
  de: {
    name: 'Gerät',
    fields: {
      id: 'Id',
      name: 'Name'
    }
  }
}
