// @flow
import React from 'react'
import propOr from 'ramda/src/propOr'
import { Translate } from 'react-redux-i18n'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import styled from 'styled-components'
import type { CodeType } from '../reducers'
import { muiStyled } from '../../Mui'

const CodeItem = muiStyled(MenuItem)({
  height: '100%'
})

const CodeDescription = styled.div`
  max-width: 200px;
  padding-left: 4.5em;
  text-indent: -4.5em;
  white-space: normal;
`

type PropsType = {
  physicianTypeId: ?number,
  value: ?CodeType,
  onChange: (position: ?CodeType) => void,
  billingPositions: Array<CodeType>
}

function PhysicianTypeSelection(props: PropsType) {
  const { value, billingPositions, onChange, physicianTypeId } = props
  const code = propOr('none', 'code')(value)

  return (
    <TextField
      select
      disabled={!physicianTypeId}
      id='billing-requested-code'
      label={<Translate value='billing.requested.code' />}
      value={code}
      onChange={(event: any) => {
        const { value: selectedCode } = event.target
        const position = billingPositions.find(p => p.code === selectedCode)
        onChange(position)
      }}
    >
      {billingPositions.filter(p => p.physicianTypeId === physicianTypeId).map(t => (
        <CodeItem label={`${t.code} - ${t.description}`} key={t.id} value={t.code}>
          <CodeDescription>{`${t.code} - ${t.description}`}</CodeDescription>
        </CodeItem>
      ))}
    </TextField>
  )
}

const mapStateToProps = ({ billing: { physicianTypes, physicianTypeId, billingPositions, context } }: StoreType) => ({
  physicianTypes,
  physicianTypeId,
  billingPositions: propOr([], context)(billingPositions)
})

export default connect(mapStateToProps)(PhysicianTypeSelection)
