import '@babel/polyfill'
import { setLocalStorage } from 'common-docdok/src/utils/localStorage'
import { hot } from 'react-hot-loader'
import React from 'react'
import ReactDOM from 'react-dom'
import createStore, { storeContainer } from './store/createStore'
import AppContainer from './common/AppContainer'
import { initKeycloak } from './keycloak'
import './styles/global.scss'
import './styles/intro.scss'
import './styles/core.scss'
import 'react-image-lightbox/style.css'
import { registerSW, registerConnectivityListener } from './store/pwaUtils'
// adds pollyfill for toBlob
require('blueimp-canvas-to-blob')

// ========================================================
// Store Instantiation
// ========================================================
const initialState = window.___INITIAL_STATE__

setLocalStorage({
  getItem: (key: string) => Promise.resolve(localStorage.getItem(key)),
  setItem: (key: string, data: string) => Promise.resolve(localStorage.setItem(key, data))
})

if (__PROD__) {
  Raven.config('https://9a4de238ae314d62a30b48d894799f50@sentry.io/169446').install()
}

registerSW()

const MOUNT_NODE = document.getElementById('root')

const run = (ishot) => {
  let reduxStore
  if (ishot) {
    reduxStore = storeContainer.store
  } else {
    reduxStore = createStore(initialState)
  }

  registerConnectivityListener(reduxStore)
  // initMediaCacheStorage()

  const routes = require('./routes/index').default(reduxStore)
  const App = hot(module)(() => <AppContainer store={reduxStore} routes={routes} />)
  ReactDOM.render(<App />, MOUNT_NODE, () => {})

  // Webpack Hot Module Replacement API
}
//
initKeycloak().success(() => {
  run()
})

if (__DEV__ && module.hot) {
  module.hot.decline('./sockets/messaging.js')
  module.hot.decline('./keycloak.js')
  // Setup hot module replacement
  module.hot.accept('./routes/index', () => setImmediate(() => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE)
    run(true)
  })
  )
}
