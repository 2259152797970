export default {
  en: {
    send: 'Send',
    enterMessage: 'Enter your Message',
    selectConversation: 'Select a Conversation',
    createConversation: {
      button: 'Create Conversation',
      title: 'New Conversation',
      created: 'Conversation successfully created'
    },
    editConversation: {
      title: 'Edit Conversation',
      edited: 'Conversation successfully edited'
    },
    conversations: {
      title: 'Conversations',
      leave: 'Remove',
      edit: 'Edit',
      mute: 'Deactivate Notifications ',
      unMute: 'Activate Notifications',
      noConversations: 'No conversation found',
      assigneToMe: 'Mark as read for all coworkers'
    },
    chat: {
      numberOfParticipants: '%{participants} Participants',
      readByPatientTooltip: 'Patient viewed status',
      readOnly: 'This clinic has not enabled patient messaging',
      loadOld: 'Load older messages',
      loadingOld: 'Loading old messages'
    },
    notifications: 'Notifications',
    newMessage: '1 new message',
    newMessages: 'New messages',
    newMessagesMultiConv: 'New messages in %{conversationsCount} conversations',
    noNewMessages: 'No new messages',
    toggleMute: {
      mute:
        'You will no longer receive any email or docdok notifications for messages in this conversation',
      unMute:
        'You will now receive an email or docdok notification when someone sends a message in this conversation'
    },
    requestAttention: {
      title: 'Send Alert',
      success:
        'You successfully alerted %{profs} to respond to this conversation',
      defaultMessage: "Please respond to %{patient}'s issue"
    },
    filterMessages: {
      title: 'Filter Messages',
      otherUsersContent: 'Show other users content only'
    },
    showAppointments: {
      title: 'Show Appointments',
      content: 'Show appointments for the selected patient'
    },
    videoConference: {
      title: 'Start Video Consultation with Patient',
      sendInvitation: 'Send invitation to video consultation',
      join: 'Join Video Consultation',
      clickMe: 'Click here to start the video consultation',
      consultationTabAlreadyOpen: `It seems you have already opened a video consultation in another tab. 
        Please close any open video sessions before joining a new one`
    },
    attachments: {
      fileTab: 'Upload',
      catalogTab: 'Catalog',
      addAttachment: 'Add Attachment',
      addDescription: 'add a description',
      fileToBig: 'File is bigger than the maximum of 10 MB.'
    },
    events: {
      leftConversation: '%{user} left the conversation',
      joinedConversation: '%{user} joined the conversation',
      assignToMe:
        'INTERNAL NOTE - %{user} marked messages as read for all coworkers',
      requestForAttention:
        'INTERNAL NOTE - %{user} sent a triage notification to %{receiver}',
      conversationPatientTitleChanged: 'Conversationname changed: %{title}',
      conversationStaffTitleChanged: 'Conversationname changed: %{title}',
      appointmentReminder:
        'INTERNAL NOTE - Reminder for appointment on %{appointmentDate} at %{appointmentTime} sent',
      appointmentSchedule:
        'INTERNAL NOTE - Reminder for scheduling appointment on %{appointmentDate} sent',
      appointmentConfirmation:
        'INTERNAL NOTE - Confirmation for appointment on %{appointmentDate} at %{appointmentTime} sent',
      invitationResent: 'INTERNAL NOTE - Invitation to DocDok renewed',
      patientReadOnly: 'Conversation is read only for the patient',
      patientReadWrite: 'Conversation is read write for the patient',
      eventsUpdated: 'Event details updated',
      eventsSaved: 'Event details saved',
      eventsDeleted: 'Event details deleted',
      eventImageDeleted: 'Event image deleted'
    },
    massSending: {
      showDialog: 'Message all patients',
      title: 'Message all patients',
      messageHere: 'Enter your message here (HTML not supported)',
      checkIncludeNotActivated:
        'Send SMS for patients without mobile app (Costs %{smsCost} Fr.)',
      bttnSendMessage: 'Send message',
      sentStat: '%{pushes} push messages and %{sms} Sms sent',
      sendingStat: '%{pushes} activated und %{sms} not activated patients'
    },
    notActivated: {
      advice:
        'This patient has not used DocDok before. He can therefore not yet receive any ' +
        'messages that you send through this chat.',
      resendInvitation: 'Resend Invitation'
    },
    videoconference: {
      invite: 'Send Video consultation Invitation',
      join: 'Join Video consultation'
    },
    diga1Invitation: {
      message:
        'Hi %{name}, welcome to docdok.' +
        'As part of the treatment, I recommend that you will download the DiGA1 application.' +
        'DiGA1 offers you a 3-month program with daily tips,' +
        ' information about your condition and guidance on self-management.' +
        'By clicking on "Join DiGA1" you will receive an email with an activation link and further instructions.',
      join: 'Join DiGA1',
      joined:
        'Great! we sent you an invitation to join DiGA1, please check your email.',
      onlyPatientCanJoin: 'Only the patient can join DiGA1'
    }
  },
  de: {
    send: 'Senden',
    enterMessage: 'Geben Sie Ihre Nachricht ein',
    selectConversation: 'Selektieren Sie eine Konversation',
    conversations: {
      title: 'Konversation',
      leave: 'Entfernen',
      edit: 'Anpassen',
      mute: 'Benachrichtigungen deaktivieren',
      unMute: 'Benachrichtigungen aktivieren',
      noConversations: 'Keine Konversation gefunden',
      assigneToMe:
        'Für alle Arbeitskollegen und Kolleginnen als gelesen markieren'
    },
    createConversation: {
      button: 'Konversation erstellen',
      title: 'Neue Konversation',
      created: 'Konversation wurde erfolgreich erstellt'
    },
    editConversation: {
      title: 'Konversation anpassen',
      edited: 'Konversation wurde erfolgreich angepasst'
    },
    chat: {
      numberOfParticipants: '%{participants} Teilnehmer',
      readByPatientTooltip: 'Patient Lesestatus',
      readOnly: 'Patienten Chat nicht aktiviert',
      loadOld: 'Ältere Nachrichten laden',
      loadingOld: 'Ältere Nachrichten werden geladen'
    },
    notifications: 'Benachrichtigungen',
    newMessage: '1 neue Nachricht',
    newMessages: 'Neue Nachrichten',
    newMessagesMultiConv:
      'Neue Nachrichten in %{conversationsCount} Konversationen',
    noNewMessages: 'Keine neuen Nachrichten',
    toggleMute: {
      mute:
        'Sie werden keine Emails oder docdok Benachrichtigungen' +
        'für Nachrichten in dieser Konversation mehr erhalten.',
      unMute:
        'Sie werden Emails oder docdok Benachrichtigungen ' +
        'erhalten, wenn Ihnen jemand eine Nachricht ' +
        'in dieser Konversation schickt'
    },
    requestAttention: {
      title: 'Benachrichtigung versenden',
      success:
        '%{profs} wurde erfolgreich benachrichtigt auf die Konversation zu reagieren.',
      defaultMessage: 'Bitte reagieren Sie auf %{patient} sein Sachverhalt.'
    },
    filterMessages: {
      title: 'Nachrichten Filter',
      otherUsersContent: 'Nur Attachments anderer Teilnehmer anzeigen'
    },
    showAppointments: {
      title: 'Termine anzeigen',
      content: 'Termine für den ausgewählten Patienten anzeigen'
    },
    videoConference: {
      title: 'Video Konsultation starten',
      sendInvitation: 'Einladung verschicken',
      join: 'Video Konsultation  beitreten',
      clickMe: 'Klicken Sie hier um die Videokonsultation zu starten',
      consultationTabAlreadyOpen: `Es scheint, dass Sie bereits eine Videokonsultation in einem anderen Tab 
        geöffnet haben. Bitte schließen Sie alle offenen Videositzungen, bevor Sie an einer neuen teilnehmen.`
    },
    attachments: {
      fileTab: 'Upload',
      catalogTab: 'Katalog',
      addAttachment: 'Anhang hinzufügen',
      addDescription: 'Beschreibung hinzufügen',
      fileToBig: 'Datei ist grösser als das Maximum von 10 MB.'
    },
    events: {
      leftConversation: '%{user} hat die Konversation verlassen',
      joinedConversation: '%{user} ist der Konversation beigetreten',
      assignToMe:
        'INTERNE NOTIZ - %{user} hat die Nachricht für alle Arbeitskollegen und Kolleginen als gelesen markiert',
      requestForAttention:
        'INTERNE NOTIZ - %{user} hat eine Triage-Benachrichtigung an %{receiver} gesendet',
      conversationPatientTitleChanged:
        'Konversationsname wurde geändert: %{title}',
      conversationStaffTitleChanged:
        'Konversationsname wurde geändert: %{title}',
      appointmentReminder:
        'INTERNE NOTIZ - Erinnerung für Termin vom %{appointmentDate} um %{appointmentTime} wurde versendet',
      appointmentSchedule:
        'INTERNE NOTIZ - Erinnerung für eine Terminbuchung um den %{appointmentDate} wurde versendet',
      appointmentConfirmation:
        'INTERNE NOTIZ - Bestätigung für den vereinbarten Termin am %{appointmentDate} um %{appointmentTime}' +
        ' wurde versendet',
      invitationResent: 'Einladung zu DocDok erneuert',
      patientReadOnly: 'Schreibschutz für Patient aktiviert',
      patientReadWrite: 'Schreibschutz für Patient  aufgehoben',
      eventsUpdated: 'Ereignisdetails aktualisiert',
      eventsSaved: 'Ereignisdetails gespeichert',
      eventsDeleted: 'Ereignisdetails gelöscht',
      eventImageDeleted: 'Ereignisbild gelöscht'
    },
    massSending: {
      showDialog: 'Nachricht an alle Patienten',
      title: 'Nachricht an alle Patienten schicken',
      messageHere: 'Nachricht hier eingeben (HTML nicht unterstützt)',
      checkIncludeNotActivated:
        'SMS an Patienten ohne mobile App versenden (Kosten: %{smsCost} Fr.)',
      bttnSendMessage: 'Nachricht versenden',
      sentStat:
        '%{pushes} Nachrichten an aktivierte Patienten und %{sms} Sms verschickt',
      sendingStat:
        'Nachricht wird an %{pushes} aktivierte und %{sms} nicht aktivierte Patienten verschickt'
    },
    notActivated: {
      advice:
        'Dieser Patient hat sich bis jetzt noch nicht bei docdok angemeldet. ' +
        'Daher wird er keine der Meldungen, die Sie hier verschicken, erhalten. ' +
        'Sie können die Einladung an ihn hier erneuern.',
      resendInvitation: 'Einladung erneuern'
    },
    diga1Invitation: {
      message:
        'Hallo %{name}, willkommen bei docdok.' +
        'Als Teil der Behandlung empfehle ich Ihnen, die DiGA1-Anwendung herunterzuladen.' +
        'DiGA1 bietet Ihnen ein 3-monatiges Programm mit täglichen Tipps,' +
        'Informationen über Ihre Erkrankung und Anleitungen zum Selbstmanagement.' +
        'Wenn Sie auf "DiGA1 beitreten" klicken,' +
        'erhalten Sie eine E-Mail mit einem Aktivierungslink und weiteren Anweisungen.',
      join: 'DiGA1 beitreten',
      joined:
        'Großartig! Wir haben Ihnen eine Einladung zur Teilnahme für DiGA1 geschickt, bitte prüfen Sie Ihre E-Mail.',
      onlyPatientCanJoin: 'Only the patient can join DiGA1'
    }
  }
}
