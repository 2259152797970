// @flow
import React, { type ComponentType } from 'react'
import { connect } from 'react-redux'
import { startSubmit, reduxForm } from 'redux-form'
import compose from 'ramda/src/compose'

import { dynamicFormActions } from '../actions'
import formValidator from '../formValidator'
import ReduxFormWrapper from './ReduxFormWrapper'
import GeneratedForm from '../components/GeneratedForm'
import type { PropertyDefinitionType } from '../components/fields/formField'

export type FieldsObjectType = {
  [key: string]: PropertyDefinitionType
}
export type FieldsType = FieldsObjectType | Array<FieldsObjectType>

export type FieldsProviderType = FieldsObjectType | Array<FieldsObjectType> | ((data: any) => FieldsObjectType)

// these are the props we passing to the dynamic form directly
export type OwnFormPropsType = {
  indentForm?: boolean,
  fields: FieldsProviderType,
  form: string,
  saveLabelKey?: string,
  initialValues?: Object,
  addionalButtons?: any,
  showReset?: boolean,
  render?: ({ form: string, fields: FieldsType }, fields: Object) => any,
  validator?: (fields: FieldsType, data: Object) => any,
  onSave?: () => void,
  reduxFromConfig?: Object,
  alternateSubmit: () => void
}

// these are the props redux-form adds to it
type ReduxFormPropsType = {
  reset: () => void,
  dirty: boolean,
  submitting: boolean,
  formSubmitting: boolean,
  submitFailed: ?boolean,
  handleSubmit: (fn: Function) => void,
  pristine: boolean
}

// these are the props we adding by connecting the form
type ConnectPropsType = {
  fields: FieldsType,
  serverErrors: ?Object,
  data: Object,
  validate: (data: Object) => any,
  submitForm: typeof dynamicFormActions.submitForm, // do we rly need this
  startSubmitting: typeof startSubmit
}

export type DynamicFormPropsType = OwnFormPropsType & ConnectPropsType & ReduxFormPropsType

function dynamicForm(props: DynamicFormPropsType) {
  const { alternateSubmit } = props
  const fromWrapperProps = alternateSubmit ? { ...props, submitForm: alternateSubmit } : { ...props }

  return (
    <ReduxFormWrapper {...fromWrapperProps}>
      <GeneratedForm {...props} />
    </ReduxFormWrapper>
  )
}

function mapStateToProps(state: StoreType, ownProps: OwnFormPropsType) {
  const { fields, form, validator } = ownProps

  const data = state.form[form] ? state.form[form].values : undefined
  // rename them to server errors because redux forms overwrites the proeprty with empty
  const serverErrors = state.form[form] ? state.form[form].submitErrors : undefined
  let fieldDefs = fields
  if (typeof fields === 'function') {
    fieldDefs = fields(data)
  }

  const defaultValidator = formValidator(fieldDefs)
  let validate = defaultValidator
  if (validator) {
    validate = (values: Object) => validator(fieldDefs, values)
  }

  return {
    fields: fieldDefs,
    serverErrors,
    data,
    validate
  }
}

const createForm = compose(
  connect(
    mapStateToProps,
    {
      submitForm: dynamicFormActions.submitForm,
      startSubmitting: startSubmit // because of naming problems we rename the action
    }
  ),
  reduxForm()
)

const form: ComponentType<OwnFormPropsType> = createForm(dynamicForm)
export default form
