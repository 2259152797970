export default {
  en: {
    name: 'Survey Groups',
    fields: {
      id: 'Id',
      groupName: 'Group Name',
      surveyDefinitions: 'Surveys'
    }
  },
  de: {
    name: 'Survey Groups',
    fields: {
      id: 'Id',
      groupName: 'Group Name',
      surveyDefinitions: 'Surveys'
    }
  }
}
