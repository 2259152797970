// @flow
import type { MessageDtoType } from 'common-docdok/src/domain/messaging/types/messageDto'
import type { ProfileStateType } from 'common-docdok/src/domain/user/reducers/profile'
import IconButton from '@material-ui/core/IconButton'
import LiveHelp from '@material-ui/icons/LiveHelp'
import React from 'react'
import { Translate } from 'react-redux-i18n'
import Tooltip from '@material-ui/core/Tooltip'
import PersonAdd from '@material-ui/icons/PersonAdd'
import Menu from '@material-ui/core/Menu'
import debounce from 'lodash/debounce'
import styled from 'styled-components'
import { connect } from 'react-redux'
import type { ConversationStateType } from 'common-docdok/src/domain/messaging/reducers/messaging'
import { messagingActions } from 'common-docdok/src/domain/messaging/actions'
import { keycloakActions } from 'common-docdok/src/common/Keycloak/actions'
import type { ConversationDtoType } from 'common-docdok/src/domain/messaging/types/conversationDto'
import { userActions } from 'common-docdok/src/domain/user/actions'

import { introActions } from '../../Intro/actions'
import { headerActions } from '../actions'
import Timer from '../../Billing/containers/Timer'
import SearchBar from './SearchBar'
import HeaderAvatar from '../components/HeaderAvatar'
import HeaderNotifications from '../components/HeaderNotifications'
import AmbulanceQueue from '../../AmbulanceQueue/components/AmbulanceQueue'
import TermsDialog from '../components/TermsDialog'
import CreatePatientButton from '../../CreatePatientButton'
import CreateCaseButton from '../../CreateCaseButton'
import { isActionVisible } from '../../Security/containers/action'
import { CREATE_PATIENT } from '../../Security/actionNames'

const Grower = styled.div`
  flex-grow: 2;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 2;
`

type PropsType = {
  profile: ProfileStateType,
  conversationId: number,
  notifications: Array<MessageDtoType>,
  unseenNotifactions: number,
  showTerms: ?string,
  locale: string,
  markAsCheckedRequested: typeof messagingActions.markAsCheckedRequested,
  logout: typeof keycloakActions.logout,
  loadMessagesRequested: typeof messagingActions.loadMessagesRequested,
  startVisibleTours: typeof introActions.startVisibleTours,
  updateTermsVersionRequested: typeof userActions.updateTermsVersionRequested,
  declineTerms: typeof headerActions.declineTerms,
  hideTerms: typeof headerActions.hideTerms
}

type StateType = {
  anchorEl: ?any
}

class Header extends React.Component<PropsType, StateType> {
  state = {
    anchorEl: null
  }

  startTours = debounce(
    () => {
      const { startVisibleTours } = this.props

      startVisibleTours()
    },
    2000,
    {
      leading: true,
      trailing: false
    }
  )

  handleMoreOptionsClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleCloseMenu = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const {
      logout,
      profile,
      notifications,
      conversationId,
      loadMessagesRequested,
      markAsCheckedRequested,
      unseenNotifactions,
      updateTermsVersionRequested,
      declineTerms,
      showTerms,
      hideTerms,
      locale
    } = this.props

    const { anchorEl } = this.state

    const createPatientCaseVisible = isActionVisible(CREATE_PATIENT)
    return (
      <Grower>
        <HeaderContainer>
          <TermsDialog
            locale={locale}
            showTerms={showTerms}
            declineTerms={declineTerms}
            acceptTerms={updateTermsVersionRequested}
            acceptedTermsVersion={profile ? profile.acceptedTermsVersion : null}
            hideTerms={hideTerms}
          />

          <Grower />
          <Timer />
          <Grower />

          <AmbulanceQueue />
          <SearchBar />

          {createPatientCaseVisible && (
            <IconButton
              color='primary'
              aria-label='Create Patient'
              aria-owns={anchorEl ? 'long-menu' : null}
              aria-haspopup='true'
              onClick={this.handleMoreOptionsClick}
            >
              <PersonAdd />
            </IconButton>
          )}

          <Menu id='lock-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleCloseMenu}>
            <CreatePatientButton closeMenu={this.handleCloseMenu} />
            <CreateCaseButton closeMenu={this.handleCloseMenu} />
          </Menu>

          <HeaderNotifications
            notifications={notifications}
            conversationId={conversationId}
            loadMessagesRequested={loadMessagesRequested}
            markAsCheckedRequested={markAsCheckedRequested}
            unseenNotifactions={unseenNotifactions}
          />
          <Tooltip title={<Translate value='header.startTutorials' />}>
            <IconButton id='start-tutorials' onClick={() => this.startTours()} color='primary'>
              <LiveHelp />
            </IconButton>
          </Tooltip>
          <HeaderAvatar logout={logout} profile={profile} />
        </HeaderContainer>
      </Grower>
    )
  }
}

function getNotificationsConversation(state: StoreType): ConversationStateType & ConversationDtoType {
  const notifications = Object.keys(state.messaging.conversations)
    .map((k) => {
      const key: any = k
      return state.messaging.conversations[key]
    })
    .filter(c => c.type === 'NOTIFICATION')
  return notifications[0]
}

const mapStateToProps = (state: StoreType) => {
  let notifications = []
  let conversationId
  let unseenNotifactions = 0
  const conv = getNotificationsConversation(state)
  if (conv) {
    conversationId = conv.id
    const newestSeenNotification = state.messaging.checkedMessages[conv.id] || 0
    notifications = conv.messages.filter(m => m.sequenceNo > newestSeenNotification)

    unseenNotifactions = conv.latestMessage ? conv.latestMessage.sequenceNo - newestSeenNotification : 0
  }

  const { isLoading } = state.loadingStack

  return {
    isLoading,
    profile: state.profile,
    notifications,
    conversationId,
    unseenNotifactions,
    showTerms: state.header.showTerms,
    locale: state.i18n.locale
  }
}

export default connect(
  mapStateToProps,
  {
    markAsCheckedRequested: messagingActions.markAsCheckedRequested,
    logout: keycloakActions.logout,
    loadMessagesRequested: messagingActions.loadMessagesRequested,
    startVisibleTours: introActions.startVisibleTours,
    updateTermsVersionRequested: userActions.updateTermsVersionRequested,
    declineTerms: headerActions.declineTerms,
    hideTerms: headerActions.hideTerms
  }
)(Header)
