// @flow
import React from 'react'
import AlertDialog from '../../AlertDialog'
import InfoPanel from '../../InfoPanel/containers/infoPanel'
import EditConversationDialog from '../../ConversationEditor/containers/EditConversationDialog'
import AddToStudyDialog from '../../AddToStudyDialog'
import ExportStudyDialog from '../../ExportStudyDialog'
import UpdateUserDialog from '../../UpdateUserDialog'
import CreateUserDialog from '../../CreateUserDialog'
import CreateCaseDialog from '../../CreateCaseButton/components/CreateCaseDialog'
import CreatePatientDialog from '../../CreatePatientButton/components/CreatePatientDialog'
import CreateAmbulanceItemsDialog from '../../AmbulanceQueue/components/AmbulanceItemsDialog'
import Add2WaitingRoomQueueDialog from '../../AmbulanceQueue/components/Add2WaitingRoomQueueDialog'
import AppointmentDialog from '../../Appointments'
import EventHandlerDialog from '../../EventDialog/containers/EventHandlerDialog'

/**
 * These are Dialogs used under the private/app route
 */
const AppDialogs = () => (
  <React.Fragment>
    <InfoPanel />
    <AlertDialog />
    <EditConversationDialog />
    <AddToStudyDialog />
    <ExportStudyDialog />
    <UpdateUserDialog />
    <CreateUserDialog />
    <CreateCaseDialog />
    <CreatePatientDialog />
    <CreateAmbulanceItemsDialog />
    <Add2WaitingRoomQueueDialog />
    <AppointmentDialog />
    <EventHandlerDialog />
  </React.Fragment>
)

export default AppDialogs
