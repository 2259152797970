// @flow
import { takeEvery, put } from 'redux-saga/effects'
import { userActions } from '../../user/actions'
import { messagingActionTypes, messagingActions } from '../../messaging/actions'

import type { ExtractReturn, GenType, MessageDtoType } from '../../../types'

function* loadUsers(msgs: Array<MessageDtoType>): GenType {
  const metaUserRefs = msgs.map(m => m.meta.userRef)
  const metaSenderRefs = msgs.map(m => m.meta.senderRef)
  const userRefs = msgs.map(m => m.userRef)
  yield put(
    userActions.loadMissingUsersRequested([...metaUserRefs, ...metaSenderRefs, ...userRefs])
  )
}

function* loadMessagesSuccess(
  action: ExtractReturn<typeof messagingActions.loadMessagesSucceeded>
): GenType {
  yield loadUsers(action.payload)
}
function* appendNewMessage(
  action: ExtractReturn<typeof messagingActions.postMessageSucceeded>
): GenType {
  yield loadUsers([action.payload])
}

export default function surveyMetaDataSaga(): Array<GenType> {
  return [
    takeEvery(messagingActionTypes.LOAD_MESSAGES_SUCCEEDED, loadMessagesSuccess),
    takeEvery(messagingActionTypes.LOAD_OLDER_MESSAGES_SUCCEEDED, loadMessagesSuccess),
    takeEvery(messagingActionTypes.POST_MESSAGE_SUCCEEDED, appendNewMessage)
  ]
}
