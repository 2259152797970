// @flow
import React from 'react'
import type { UserDtoType } from 'common-docdok/src/domain/user/types/userDto'
import { userActions } from 'common-docdok/src/domain/user/actions'
import { connect } from 'react-redux'
import pathOr from 'ramda/src/pathOr'

import { getKeycloakClient } from 'common-docdok/src/common/Keycloak/keycloakProvider'
import DynamicFormDialog from '../../DynamicFormDialog'
import { conversationEditorActions } from '../actions'
import { extraxctCaseInfo } from '../../Messaging/utils'

type PropsType = {
  userCache: { [key: string]: UserDtoType },
  setEditConversation: typeof conversationEditorActions.setEditConversation,
  conversation: {
    title: string,
    id: number,
    subscriptions: Array<Object>,
    meta: {
      userRef: string
    }
  },
  showEditConversation: boolean,
  loadMissingUsersRequested: typeof userActions.loadMissingUsersRequested
}

class EditConversationDialog extends React.Component<PropsType> {
  componentWillUpdate(newProps: PropsType) {
    const conversationId = pathOr(-1, ['conversation', 'id'])(this.props)
    const newConversationId = pathOr(-1, ['conversation', 'id'])(newProps)

    if (newConversationId !== conversationId) {
      const { loadMissingUsersRequested, userCache } = newProps
      const subscriptions = pathOr([], ['conversation', 'subscriptions'])(newProps)
      const missingUserRefs = subscriptions.map(sub => sub.userRef).filter(userRef => !userCache[userRef])
      if (missingUserRefs.length > 0) {
        loadMissingUsersRequested(missingUserRefs)
      }
    }
  }

  calcUnremovableIds = () => {
    // if not case unremovable are all but myself
    // still valid fro case if not admin
    // if case admin all but myself

    const { conversation } = this.props
    const currentUser = getKeycloakClient().getUserId()
    const caseFlags = extraxctCaseInfo(currentUser, conversation.meta)

    let subFilter = sub => sub.userRef !== currentUser

    if (caseFlags.isCaseOwner) {
      subFilter = sub => sub.userRef === currentUser
    }
    const notToRemove = conversation.subscriptions.filter(subFilter).map(sub => sub.userRef)
    // add the patient
    if (conversation.meta.userRef) notToRemove.push(conversation.meta.userRef)

    return notToRemove
  }

  render() {
    const { conversation, showEditConversation, userCache, setEditConversation } = this.props
    if (!conversation) {
      return null
    }

    const { title } = conversation
    const users = conversation.subscriptions
      .map(s => s.userRef)
      .map(id => (userCache[id] ? userCache[id] : { uid: id }))
      .map((p: any) => ({
        ...p,
        userRef: p.uid
      }))
    const showDialog = showEditConversation !== false
    const unremovableIds = this.calcUnremovableIds()
    const result = (
      <DynamicFormDialog
        open={showDialog}
        handleClose={() => setEditConversation(false)}
        titleKey='messages.editConversation.title'
        form='editConversation'
        reduxFromConfig={{ enableReinitialize: true }}
        initialValues={{
          users,
          title,
          conversationId: conversation.id
        }}
        fields={{
          title: {
            minLength: 1,
            maxLength: 255,
            type: 'string',
            mandatory: true
          },
          users: {
            specName: 'professional',
            type: 'search',
            mandatory: true,
            multiSelectable: true,
            addOnly: false,
            unremovableIds
          }
        }}
      />
    )

    return result
  }
}

const mapStateToProps = ({
  conversationEditor: { editConversationId, showEditConversation },
  messaging: { conversations },
  userCache: { users }
}: StoreType) => ({
  conversation: conversations[Number(editConversationId)],
  editConversationId,
  showEditConversation,
  userCache: users
})

export default connect(
  mapStateToProps,
  {
    setEditConversation: conversationEditorActions.setEditConversation,
    loadMissingUsersRequested: userActions.loadMissingUsersRequested
  }
)(EditConversationDialog)
