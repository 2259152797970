// @flow
/* eslint import/prefer-default-export: 0 */

import type { ExtractReturn } from '../../../types'
import type { MessagingStateType } from './messaging'
import { messagingActions } from '../actions'

const placeholder =
  'data:image/png;base64,' +
  'iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAQAAADa613fAAAAaUlEQVR42u3PQREAAAgDINc/' +
  '9Izg34MGpJ0XIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIi' +
  'IiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiJyWYprx532021aAAAAAElFTkSuQmCC'

export function getMediaResource(
  state: MessagingStateType,
  action: ExtractReturn<typeof messagingActions.getMediaResourceSucceeded>
) {
  const { dataUrl, mimeType, uuid, preview } = action.payload
  const currentResource = state.loadedResources[uuid]
  const previewDataUrl = (preview && dataUrl)
    || (currentResource && currentResource.previewDataUrl)
    || dataUrl
    || placeholder
  const originalDataUrl = (!preview && dataUrl) || undefined
  return {
    ...state,
    loadedResources: {
      ...state.loadedResources,
      [uuid]: {
        originalDataUrl,
        previewDataUrl,
        mimeType
      }
    }
  }
}

export function clearMediaResourceCache(
  state: MessagingStateType,
  action: ExtractReturn<typeof messagingActions.getMediaResourceSucceeded>
) {
  const { uuid } = action.payload
  return {
    ...state,
    loadedResources: {
      ...state.loadedResources,
      [uuid]: null
    }
  }
}
