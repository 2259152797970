// @flow
import React from 'react'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import styled from 'styled-components'
import { Translate, I18n } from 'react-redux-i18n'
import sorters from 'common-docdok/src/utils/sorters'
import { connect } from 'react-redux'

import getEntitySpec from '../../EntitySpecs/getEntitySpec'
import type { FieldComponentPropsType } from '../../DynamicForm/components/fields/formField'
import EntityTable from '../../EntityTable'
import getDisplayValue from '../../EntitySpecs/utils'
import SelectionError from '../components/SelectionError'

const EntityAvatar = styled(Avatar)`
  width: 32px;
  height: 32px;
`

type PropsType = {
  rows: Array<any>
} & FieldComponentPropsType

type StateType = {
  invalidUrls: {
    [key: string]: boolean
  },
  searchTerm: ?string
}

class EntitySearcher extends React.Component<PropsType, StateType> {
  state = { invalidUrls: {}, searchTerm: undefined }

  undeletableIdsFromInitial: Array<string> = []

  undeletableIds = () => {
    const {
      propertyDefinition: { unremovableIds }
    } = this.props
    // Legacy alert: If a list of not removable ids is given use that
    // even an emppty list would be valid.
    // if not use the the one create in the constructor containing all the
    // initial values.
    if (unremovableIds) {
      return [...unremovableIds]
    }

    return this.undeletableIdsFromInitial
  }

  constructor(props: PropsType) {
    super(props)
    const {
      propertyDefinition: { specName, unremovableIds },
      input: { value }
    } = this.props

    this.selectedCount = 0
    if (!unremovableIds) {
      const { identifier } = getEntitySpec(specName)
      const undeletableIds = value || []
      this.undeletableIdsFromInitial = undeletableIds.map(v => v[identifier])
    }
  }

  static renderText(txt: string) {
    const text = txt ? txt[0] : ''
    return <EntityAvatar>{text}</EntityAvatar>
  }

  getSelectedEntites() {
    const {
      propertyDefinition: { specName },
      input: { value }
    } = this.props
    const entitySpec = getEntitySpec(specName)
    const getDispalyVal = (val: any) => getDisplayValue(val, entitySpec)
    return (value || []).sort((a, b) => sorters.sortByValue(getDispalyVal(a), getDispalyVal(b)))
  }

  selectedCount: number

  renderAvatarImage(src: string) {
    const { invalidUrls } = this.state
    return (
      <EntityAvatar
        src={src}
        imgProps={{
          onError: () => {
            this.setState({
              invalidUrls: {
                ...invalidUrls,
                [src]: true
              }
            })
          }
        }}
      />
    )
  }

  renderAvatar(entitySpec: any, entity: any) {
    const text = getDisplayValue(entity, entitySpec)
    const src = entity[entitySpec.avatarField]
    const showAvatar = this.state.invalidUrls[src] !== true && src !== undefined

    return showAvatar ? this.renderAvatarImage(src) : EntitySearcher.renderText(text)
  }

  renderSearchBar() {
    const {
      input: { name },
      meta: { form }
    } = this.props
    const id = `${form}-${name}`
    const { searchTerm } = this.state
    return (
      <div>
        <TextField
          margin='normal'
          InputLabelProps={{
            shrink: true
          }}
          id={`${id}-search-field`}
          fullWidth
          onChange={(event) => {
            this.setState({ searchTerm: event.target.value })
          }}
          value={searchTerm || ''}
          label={<Translate value={`forms.${form}.${name}`} />}
          placeholder={I18n.t('actions.search')}
        />
      </div>
    )
  }

  renderSelection() {
    const {
      propertyDefinition: { specName },
      input: { onChange }
    } = this.props
    const entitySpec = getEntitySpec(specName)
    const { identifier } = entitySpec
    const selectedEntities = this.getSelectedEntites()
    const notAllowedToDelete = this.undeletableIds()
    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap'
        }}
      >
        {selectedEntities.map((entity) => {
          const id = entity[identifier]
          const canDelete = !notAllowedToDelete.includes(id)
          let additionalAttributes = {}
          if (canDelete) {
            additionalAttributes = {
              onDelete: () => {
                const currentEntites = selectedEntities.filter(e => e[identifier] !== entity[identifier])
                onChange(currentEntites)
              }
            }
          }

          return (
            <Chip
              {...additionalAttributes}
              key={id}
              style={{ margin: 4 }}
              avatar={this.renderAvatar(entitySpec, entity)}
              label={getDisplayValue(entity, entitySpec)}
            />
          )
        })}
      </div>
    )
  }

  render() {
    const {
      propertyDefinition: { specName, noSearch, multiSelectable, params, defaultPageSize = 5 },
      input: { onChange, name },
      meta: { form },
      rows
    } = this.props
    const { searchTerm } = this.state
    const { api, fields, entity, identifier } = getEntitySpec(specName)

    const selectedEntities = this.getSelectedEntites()
    const id = `${form}-${name}`
    return (
      <div>
        {noSearch ? undefined : this.renderSearchBar()}
        {noSearch ? undefined : this.renderSelection()}
        <SelectionError {...this.props} />

        <EntityTable
          id={id}
          api={api}
          params={params}
          columns={fields}
          entity={entity}
          idField={identifier}
          searchTerm={searchTerm}
          defaultPageSize={defaultPageSize}
          multiSelectable={multiSelectable}
          selection={selectedEntities}
          showPageSizeOptions={false}
          unremovableIds={this.undeletableIds()}
          onSelectionChanged={(newSelection) => {
            if (multiSelectable) {
              const newSelectionIds = newSelection.map(e => e[identifier])
              const currentSelectionWithoutNewSelection = selectedEntities.filter(
                e => !newSelectionIds.includes(e[identifier])
              )
              const holeSelection = [...newSelection, ...currentSelectionWithoutNewSelection]
              const notSelected = rows.filter(e => !newSelectionIds.includes(e[identifier]))
              const notSelectedIds = notSelected.map(e => e[identifier])
              const currentSelection = holeSelection.filter(e => !notSelectedIds.includes(e[identifier]))
              onChange(currentSelection)
            } else {
              onChange(newSelection)
            }
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state: StoreType, ownProps: FieldComponentPropsType) => {
  const {
    input: { name },
    meta: { form }
  } = ownProps
  const id = `${form}-${name}`
  return { rows: state.entityLoader[id] ? state.entityLoader[id].result : [] }
}

export default connect(mapStateToProps)(EntitySearcher)
