// @flow
import React from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import api from 'common-docdok/src/api'
import { infoPanelActions } from '../../common/InfoPanel/actions'
import { MessageTypes } from '../../common/InfoPanel/components/infoPanel'

type PropsType = {
  showMessage: typeof infoPanelActions.showMessage,
  params: { publicPatientId: ?string }
}
class PublicPatientIdResolver extends React.Component<PropsType> {
  constructor(props: Object) {
    super(props)

    const publicId = props.params.publicPatientId
    if (publicId) {
      api
        .getPatientUuidByPublicId(publicId)
        .then((result) => {
          const uuid = result.data
          browserHistory.push(`/private/app/patients/${uuid}`)
        })
        .catch(() => {
          browserHistory.push('/private/app/patients')
          props.showMessage('patients.publicIdNotFound', { publicId }, MessageTypes.error)
        })
    } else {
      browserHistory.push('/private/app/patients')
    }
  }

  render() {
    return null
  }
}

export default connect(
  undefined,
  infoPanelActions
)(PublicPatientIdResolver)
