// @flow

import React from 'react'
import { Translate } from 'react-redux-i18n'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

import InclusionExclusion from './InclusionExclusion'

type PropsType = {
  criterias: string[],
  forward: () => void,
  studyName: string,
  criteriaType: string
}

export default class InclusionExclusionComp extends React.Component<PropsType> {
  state = { criterias: [] }

  criteriaListToProps = (criterias: string[]): Array<Object> => criterias.map(criteria => ({ verified: false, criteria })) // //

  constructor(props: PropsType) {
    super(props)
    const { criterias } = props
    this.state = {
      criterias: this.criteriaListToProps(criterias)
    }
  }

  componentWillReceiveProps(props: PropsType) {
    const { criterias } = props
    this.setState({
      criterias: this.criteriaListToProps(criterias)
    })
  }

  toggleCriteria = (i: number) => () => {
    this.setState(prevState => ({
      criterias: prevState.criterias.map((
        criteria,
        pos //
      ) => (pos === i ? { verified: !criteria.verified, criteria: criteria.criteria } : { ...criteria }))
    }))
  }

  render() {
    const { studyName, criteriaType } = this.props
    return (
      <>
        <Typography variant='subtitle1'>
          <Translate value='studies.participationCriteria.title' studyName={studyName} />
        </Typography>
        <Divider />
        <br />
        <b>
          <Translate value={`studies.participationCriteria.${criteriaType}`} />
        </b>
        <Typography>
          <Translate value={`studies.participationCriteria.${criteriaType}What`} />
        </Typography>
        <br />
        <InclusionExclusion
          forward={this.props.forward}
          toggleCriteria={this.toggleCriteria}
          criterias={this.state.criterias}
        />
      </>
    )
  }
}
