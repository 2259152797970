// @flow

export const selectedPatientActionTypes = {
  SELECT_PATIENT: 'SELECT_PATIENT'
}
export type SelectedPatientsActionType = $Keys<typeof selectedPatientActionTypes>
export const selectedPatientActions = {
  selectPatient(uuid: ?string) {
    return {
      type: selectedPatientActionTypes.SELECT_PATIENT,
      payload: uuid || null
    }
  }
}
