// @flow
import { takeEvery, put, call } from 'redux-saga/effects'
import type { ActionType, GenType } from 'common-docdok/src/types'
import api from 'common-docdok/src/api'

import { SEARCH_PATIENT } from '../actions/searchPatient'
import { searchPatientSuccess } from '../actions/searchPatientSuccess'

export function* searchPatientGen({ payload }: ActionType): GenType {
  const result = yield call(api.getPatients, 10, 0, payload)
  yield put(searchPatientSuccess(result.data))
}
export default function searchBarSaga(): Array<GenType> {
  return [takeEvery(SEARCH_PATIENT, searchPatientGen)]
}
