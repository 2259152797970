// @flow
import { mobileRgx } from '../../../utils/rgx'

export const fieldNames = {
  city: 'city',
  id: 'id',
  name: 'name',
  patientReadonlyConversations: 'patientReadonlyConversations',
  phoneNumber: 'phoneNumber',
  publicUuid: 'publicUuid',
  street: 'street',
  streetNumber: 'streetNumber',
  zipCode: 'zipCode'
}

export const fields = {
  city: {
    type: 'string',
    minLength: 0,
    maxLength: 255
  },
  id: {
    type: 'number',
    format: 'int64'
  },
  name: {
    type: 'string',
    minLength: 3,
    maxLength: 255
  },
  patientReadonlyConversations: { type: 'boolean' },
  phoneNumber: {
    type: 'string',
    minLength: 5,
    maxLength: 20,
    pattern: mobileRgx,
    patternNice: '+41795553322',
    normalize: (nbr: string) => nbr.replace(/\s/g, '')
  },
  publicUuid: {
    type: 'string',
    minLength: 0,
    maxLength: 40
  },
  street: {
    type: 'string',
    minLength: 0,
    maxLength: 255
  },
  streetNumber: {
    type: 'string',
    minLength: 0,
    maxLength: 10
  },
  zipCode: {
    type: 'string',
    minLength: 0,
    maxLength: 10
  }
}

export type ClinicDtoType = {
  city: string,
  id: number,
  name: string,
  patientReadonlyConversations: boolean,
  phoneNumber: string,
  publicUuid: string,
  street: string,
  streetNumber: string,
  zipCode: string
}
