import find from 'ramda/src/find'
import propEq from 'ramda/src/propEq'
import propOr from 'ramda/src/propOr'
import pipe from 'ramda/src/pipe'
import Maybe from 'data.maybe'

import type { SubscriptionDtoType } from '../domain/messaging/types/subscriptionDto'


/**
 * checks userRef on subscriptions
 * @param {string} userRef - the userRef which should be used for the check
 * @return a function which takes a {SubscriptinDtoType}
 */
export const eqUserRef = propEq('userRef')

/**
 * checks id on subscriptions
 * @param {number} id - the id
 * @return a function which takes a {SubscriptinDtoType}
 */
export const eqId = propEq('id')

/**
 * returns lastReadMessage or 0
 * @param {SubscriptinDtoType} subscription
 * @return {number} 0..n
 */
export const getLastReadMessage = propOr(0, 'lastReadMessage')

/**
 * find the subscription with the userREf
 * @param {string} userRef - the userRef which should be used for the check
 * @return a function which takes a {Array<SubscriptinDtoType>}
 */
export const findByUserRef = pipe(eqUserRef, find)


/**
 * Find the subscription with the id
 * @param {id} number - the userRef which should be used for the check
 * @param {sub} SubcriptionPictureType - the userRef which should be used for the check
 * @return a function which takes a {SubscriptinDtoType}
 */
export const getSubcriptionById = (
  subscriptionId: number,
  subscriptions: Array<SubscriptionDtoType>
) =>
  pipe(
    find(eqId(subscriptionId)),
    Maybe.fromNullable
  )(subscriptions)
