// @flow
export default class Worker {
  tasks = []

  interval: IntervalID

  execute: (task: any) => void

  constructor(speed: number, execute: (task: any) => void) {
    this.execute = execute
    this.interval = setInterval(() => {
      if (this.tasks.length > 0) {
        const task = this.tasks.shift()
        execute(task)
      }
    }, speed)
  }

  queue = (task: any) => this.tasks.push(task)

  stop = () => clearInterval(this.interval)
}
