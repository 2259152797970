// @flow
import type { ActionType } from 'common-docdok/src/types'
import { patientsActionTypes } from '../actions'

// State Definition

export type PatientsStateType = {
  showSendSurveyDialog: boolean,
  showSetTargetDialog: boolean,
  devices: any
}

// Default State
const initialState: PatientsStateType = {
  showSendSurveyDialog: false,
  showSetTargetDialog: false,
  devices: {}
}

// Action Handlers

function toggleSurveyDialog(state: PatientsStateType): PatientsStateType {
  return {
    ...state,
    showSendSurveyDialog: !state.showSendSurveyDialog
  }
}

function toggleSetTargetDialog(state: PatientsStateType): PatientsStateType {
  return {
    ...state,
    showSetTargetDialog: !state.showSetTargetDialog
  }
}

const ACTION_HANDLERS = {
  [patientsActionTypes.TOGGLE_SEND_SURVEY_FROM_PATIENT_DIALOG]: toggleSurveyDialog,
  [patientsActionTypes.TOGGLE_SET_TARGET_PATIENT_DIALOG]: toggleSetTargetDialog
}

export default function patientsReducer(
  state: PatientsStateType = initialState,
  action: ActionType
): PatientsStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
