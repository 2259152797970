// @flow
import { takeEvery } from 'redux-saga/effects'
import { keycloakActionTypes } from '../actions'
import { getKeycloakClient } from '../keycloakProvider'
import type { GenType } from '../../../types'

export default function keycloakSaga(): Array<GenType> {
  return [
    takeEvery(keycloakActionTypes.LOGOUT, () => {
      localStorage.removeItem('redirected')
      getKeycloakClient().logout()
    }),
    takeEvery(
      action => action.type !== keycloakActionTypes.LOGOUT,
      () => {
        if (getKeycloakClient().getUserId()) getKeycloakClient().refreshToken()
      }
    )
  ]
}
