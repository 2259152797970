// @flow
import type { SurveyScheduleSpecType } from './surveyScheduleSpec'

export const fieldNames = {
  alertAfterHours: 'alertAfterHours',
  defaultScheduling: 'defaultScheduling',
  id: 'id',
  name: 'name',
  nbCompleted: 'nbCompleted',
  nbSent: 'nbSent',
  ownerRef: 'ownerRef',
  remindAfterHours: 'remindAfterHours',
  restrictedAccess: 'restrictedAccess',
  status: 'status',
  writeAccess: 'writeAccess'
}

export const fields = {
  alertAfterHours: {
    type: 'number',
    format: 'int64'
  },
  defaultScheduling: { type: 'SurveyScheduleSpec' },
  id: {
    type: 'number',
    format: 'int64'
  },
  name: { type: 'string' },
  nbCompleted: {
    type: 'number',
    format: 'int64'
  },
  nbSent: {
    type: 'number',
    format: 'int64'
  },
  ownerRef: { type: 'string' },
  remindAfterHours: {
    type: 'number',
    format: 'int64'
  },
  restrictedAccess: { type: 'boolean' },
  status: {
    type: 'string',
    enum: ['NOT_ACTIVE', 'RUNNING']
  },
  writeAccess: {
    type: 'string',
    enum: ['PATIENT_AND_HCP', 'PATIENT_ONLY', 'HCP_ONLY']
  }
}

export type SurveyDefinitionSummaryType = {
  alertAfterHours: number,
  defaultScheduling: SurveyScheduleSpecType,
  id: number,
  name: string,
  nbCompleted: number,
  nbSent: number,
  ownerRef: string,
  remindAfterHours: number,
  restrictedAccess: boolean,
  status: string,
  writeAccess: string
}
