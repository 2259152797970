// @flow
/* eslint import/no-mutable-exports: 0 */
import invariant from 'invariant'
import omit from 'ramda/src/omit'

export type LocalStorageType = {
  setItem: (key: string, value: string) => Promise<void>,
  getItem: (key: string) => Promise<string>,
  key: (index: number) => Promise<string>,
  removeItem: (key: string) => Promise<boolean>,
  clear: () => Promise<boolean>,
  length: () => Promise<number>
}

export let memoryStore: { [string]: string } = {}
export const dummyMemoryStore: LocalStorageType = {
  setItem: (key: string, value: string) => {
    memoryStore[key] = value
    return Promise.resolve()
  },
  getItem: (key: string) => {
    if (memoryStore[key]) {
      return Promise.resolve(memoryStore[key])
    }
    return Promise.reject()
  },
  key: (index: number) => Promise.resolve(Object.keys(memoryStore)[index]),
  removeItem: (key: string) => {
    memoryStore = omit([key], memoryStore)
    return Promise.resolve(true)
  },
  clear: () => {
    memoryStore = {}
    return Promise.resolve(true)
  },
  length: () => Promise.resolve(Object.keys(memoryStore).length)
}

export type LocalStorageContainerType = { localStorage?: LocalStorageType }

const localStorageContainer: LocalStorageContainerType = {
  localStorage: undefined
}

export function getLocalStorage(): LocalStorageType {
  invariant(localStorageContainer.localStorage, 'localStorage not set')
  return localStorageContainer.localStorage
}

export function setLocalStorage(store: LocalStorageType): void {
  invariant(store, 'param store not set')
  localStorageContainer.localStorage = store
}

export async function* keys(localstorage: LocalStorageType): AsyncIterable<string> {
  let index = 0
  const length = await localstorage.length()
  /* eslint no-await-in-loop: 0 */
  while (index < length) {
    const key = await localstorage.key(index)
    yield key
    index += 1
  }
}
