// @flow
import React from 'react'
import toString from 'common-docdok/src/utils/toString'
import styled from 'styled-components'

const ImageOverlay = styled.div`
  position: absolute;
  display: flex;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  z-index: 100;
  font-size: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
`

const AttachmentDescription = styled.div`
  margin: auto;
  text-decoration: none;
  white-space: nowrap;
`

type MetaInfoOverlayPropsType = {
  meta: { originalSize: number },
  show: boolean,
  isImage: boolean,
  color: ?string
}

export default (props: MetaInfoOverlayPropsType) => {
  const {
    show,
    meta: { originalSize },
    color = 'white'
  } = props

  if (!show) return null

  return (
    <ImageOverlay>
      <AttachmentDescription style={{ color }}>{toString.bytes(originalSize)}</AttachmentDescription>
    </ImageOverlay>
  )
}
