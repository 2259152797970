// @flow
import { put, takeEvery } from 'redux-saga/effects'
import type { GenType } from 'common-docdok/src/types'
import { stopSubmit } from 'redux-form'
import { asyncAction } from 'common-docdok/src/utils/sagaUtils'

import { userActions } from 'common-docdok/src/domain/user/actions'

import { dynamicFormActionTypes } from '../../DynamicForm/actions'
import { infoPanelActions } from '../../InfoPanel/actions'
import { updateUserDialogActions } from '../actions'

function* updateUser({ payload }: Object): GenType {
  if (payload.name === 'updateUser') {
    const winner = yield asyncAction(userActions.updateUserRequested(payload.data))

    if (winner.succeeded) {
      yield put(stopSubmit(payload.name))
      yield put(updateUserDialogActions.setUpdateUserDialog(undefined))
      yield put(infoPanelActions.showMessage('users.updateUser.success'))
    } else {
      yield put(stopSubmit(payload.name, winner.failed.payload.error))
    }
  }
}

export default function usersSaga() {
  return [takeEvery(dynamicFormActionTypes.SUBMIT_FORM, updateUser)]
}
