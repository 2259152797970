// @flow
import React from 'react'
import { connect } from 'react-redux'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { browserHistory } from 'react-router'

import type { MessageDtoType } from 'common-docdok/src/domain/messaging/types/messageDto'
import UserAvatar from '../../UserAvatar'


const mapStateToProps = (state: StoreType, ownProps: { message: MessageDtoType }) => {
  const user = state.userCache.users[ownProps.message.userRef]
  const patients = state.healthrelation && state.healthrelation.patients
  return { user, patients }
}

export default connect(mapStateToProps)((props) => {
  const {
    patients,
    message: {
      text
    }
  } = props

  let user = {}
  if (Array.isArray(patients) && patients.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    user = patients[0]
  }
  return (
    <ListItem
      button
      id='activity-reminder-notification'
      onClick={() => {
        browserHistory.push(`/private/app/patients/${user.uuid}`)
      }}
    >
      <UserAvatar
        size={34}
        src={user ? user.avatarPicture : undefined}
        name={user ? user.firstName : ''}
      />
      <ListItemText
        primary={text}
      />
    </ListItem>
  )
})
