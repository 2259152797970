export default function log(level, message, ...args) {
  const error = args.length > 2 && args[2] !== undefined ? args[2] : ''

  /* eslint-disable no-console */
  if (typeof window === 'undefined') {
    console.log(`${level}: ${message}\n ${(error && error.stack) || error}`)
  } else {
    console[level](message, error)
  }
}
