// @flow
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Help from '@material-ui/icons/HelpOutline'
import Home from '@material-ui/icons/Home'
import Chat from '@material-ui/icons/Chat'
import Schedule from '@material-ui/icons/Schedule'
import Download from '@material-ui/icons/CloudDownload'
import Domain from '@material-ui/icons/Domain'
import People from '@material-ui/icons/People'
import React from 'react'
import { Translate } from 'react-redux-i18n'
import { browserHistory } from 'react-router'

import { connect } from 'react-redux'
import pathOr from 'ramda/src/pathOr'
import styled from 'styled-components'

// TODO: Unwanted dependency
import { billingActions } from '../../Billing/actions'
import Action from '../../Security/containers/action'
import Path from '../../Security/containers/path'
import { EXPORT_UOB_DATA } from '../../Security/actionNames'
import { isFirefox } from '../../utils'
import { exportStudyDialogActions } from '../../ExportStudyDialog/actions'

import Diga1Icon from '../assets/diga1-logo.svg'

function renderButton(
  { action, to, label, icon, condition, externalSite }: Object,
  index,
  onLinkActionFn: () => void
) {
  if (condition && !condition()) {
    return null
  }
  let component = <Divider key={index} />
  const labelKey = `menu.${label}`

  const listItem = (
    <ListItem
      data-test={`home-menu-${label}`}
      key={labelKey}
      id={labelKey}
      button
      onClick={() => {
        if (action) {
          action.method()
        } else {
          browserHistory.push(to)
          onLinkActionFn()
        }
      }}
    >
      <ListItemIcon
        style={{
          color: 'white' /* does notwork with styledComponents in firefox */
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={<Translate value={labelKey} style={{ color: 'white' }} />}
      />
    </ListItem>
  )

  if (action) {
    if (action.name) {
      component = (
        <Action key={index} name={action.name}>
          {listItem}
        </Action>
      )
    } else {
      component = listItem
    }
  } else if (to) {
    component = (
      <Path key={index} name={to}>
        {listItem}
      </Path>
    )
  } else if (externalSite) {
    component = (
      <a href={externalSite} target="_blank" rel="noopener noreferrer">
        {listItem}
      </a>
    )
  }

  return component
}

type PropsType = {
  toggleExportStudyDialog: typeof exportStudyDialogActions.toggleExportStudyDialog,
  downloadBillingRequested: typeof billingActions.downloadBillingRequested,
  hasStudies: boolean,
  onLinkActionFn: () => void,
  onlyStudyView: boolean,
  patientId: any,
  showDiga1Button: boolean
}

const Diga1IconContainer = styled.div`
  background: white;
  padding: 4px;
  border-radius: 21px;
`

const Diga1IconImg = styled.img`
  height: 14px;
  width: 20px;
`

const Menu = (props: PropsType) => {
  const { toggleExportStudyDialog, onLinkActionFn, showDiga1Button } = props
  const sections = [
    [
      {
        to: '/private/app',
        label: 'home',
        icon: <Home />
      }
    ],
    [
      {
        to:
          props.onlyStudyView && props.patientId
            ? `/private/app/patients/${props.patientId}`
            : '/private/app/patients',
        label: props.onlyStudyView && props.patientId ? 'overview' : 'patients',
        icon: <People />
      },
      {
        to: '/private/app/messages',
        label: 'messages',
        icon: <Chat />
      },
      {
        action: {
          name: EXPORT_UOB_DATA,
          method: toggleExportStudyDialog
        },
        label: 'exportUOBData',
        icon: <Download />
      },
      {
        to: '/private/app/users',
        label: 'users',
        icon: <People />
      },
      {
        to: '/private/app/clinics',
        label: 'clinics',
        icon: <Domain />
      },
      {
        to: '/private/app/appointment',
        label: 'appointments',
        icon: <Schedule />
      }
    ],
    showDiga1Button && props.patientId && [
      {
        externalSite: 'https://digaeins.de/',
        label: 'diga1',
        icon: (
          <Diga1IconContainer>
            <Diga1IconImg src={Diga1Icon} alt="diga1-logo" />
          </Diga1IconContainer>
        )
      }
    ],
    [
      {
        to: '/private/app/profile',
        label: 'profile',
        icon: <AccountCircle />
      },
      {
        action: {
          method: () => {
            const mailUrl =
              'mailto:supportdesk@docdok.atlassian.net?subject=docdok Kundendienstanfrage'
            if (isFirefox()) {
              const win = window.open(mailUrl, '_blank', 'width=1,height=1')
              win.blur()
              window.focus()
              if (win && win.open && !win.closed) {
                win.close()
              }
            } else {
              document.location.href = mailUrl
            }
          }
        },
        label: 'help',
        icon: <Help />
      }
    ]
  ].filter(d => Array.isArray(d))

  return (
    <div style={{ color: 'white' }}>
      {sections.map((buttons, index) => (
        <div key={index}>
          <List>
            {buttons && buttons.map((button, bIndex) => renderButton(button, bIndex, onLinkActionFn))}
          </List>
          {index !== sections.length - 1 && <Divider />}
        </div>
      ))}
    </div>
  )
}

const getAuthorities = pathOr([], ['profile', 'profile', 'authorities'])

const mapStateToProps = (state: StoreType) => {
  const {
    customizedDashboardForClinics,
    patients,
    clinicMine,
    hycareSolutionEnabledClinics,
    currentPatient
  } = state.healthrelation
  let onlyStudyView = false
  const clinicName = clinicMine && clinicMine.name
  if (
    customizedDashboardForClinics &&
    clinicName &&
    customizedDashboardForClinics.some((name) => name === clinicName)
  ) {
    onlyStudyView = true
  }
  const isHycareEnabledClinic =
    clinicMine &&
    hycareSolutionEnabledClinics &&
    hycareSolutionEnabledClinics.includes(clinicMine.name)

  const isPatient = state.profile
    && Array.isArray(state.profile.authorities)
    && state.profile.authorities.includes('ROLE_PATIENT')
  return {
    hasStudies:
      state.survey.allStudies.length > 0 ||
      getAuthorities(state).includes('ROLE_ADMIN'),
    onlyStudyView,
    patientId: isPatient && patients && patients[0] && patients[0].uuid,
    showDiga1Button:
      isHycareEnabledClinic &&
      isPatient &&
      currentPatient &&
      currentPatient.externalAppPreferencesDTO &&
      currentPatient.externalAppPreferencesDTO.status === 'patient-created' &&
      false
  }
}

export default connect(mapStateToProps, {
  toggleExportStudyDialog: exportStudyDialogActions.toggleExportStudyDialog,
  downloadBillingRequested: billingActions.downloadBillingRequested
})(Menu)
