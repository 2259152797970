// @flow
import React from 'react'
import { browserHistory } from 'react-router'
import { getKeycloakClient } from 'common-docdok/src/common/Keycloak/keycloakProvider'
import PersistStore from 'common-docdok/src/configuration/persistStore'
import initialActions from '../../../store/initialActions'
import { storeContainer } from '../../../store/createStore'
import { connect } from '../../../sockets/messaging'
import { getEnvFromHostName } from '../../../common/utils'

import LoginDialog from '../../../common/LoginDialog/components/LoginDialog'

type PropsType = {
  children: any
}
export default class Layout extends React.Component<PropsType> {
  constructor(props: Object) {
    super(props)
    if (!getKeycloakClient().getUserId()) {
      getKeycloakClient().login((authenticated) => {
        if (!authenticated) {
          browserHistory.push('private/app')
        }
      })
    } else {
      const { store } = storeContainer
      connect(store)
      initialActions(store)
      PersistStore.init({
        name: 'dcdk',
        version: '1.0.0',
        environment: getEnvFromHostName(),
        // $DisableFlow
        dev: __DEV__,
        select: (state: StoreType) => ({
          messaging: {
            cachedAttachments: state.messaging.cachedAttachments
          },
          billing: {
            physicianTypeId: state.billing.physicianTypeId
          },
          intro: {
            completedTours: state.intro.completedTours
          },
          entityTable: state.entityTable
        }),
        reduxStore: store,
        actions: {
          start: 'START_PERSIST',
          stop: 'LOGOUT'
        }
      })
    }
  }

  render() {
    if (!getKeycloakClient().getUserId()) {
      return null
    }

    return (
      <React.Fragment>
        {this.props.children}
        <LoginDialog />
      </React.Fragment>
    )
  }
}
