// @flow
import type { GenType } from 'common-docdok/src/types'

import patientsSaga from './patients'
import patientSaga from './patient'
import devicesSaga from './devices'

export default function patientsRootSaga(): Array<GenType> {
  return [...patientsSaga(), ...devicesSaga(), ...patientSaga()]
}
