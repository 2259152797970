// @flow
import React from 'react'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import toString from 'common-docdok/src/utils/toString'

type PropsType = {
  onRequestDelete?: Function,
  entity: Object,
  id?: string
}

type StateType = {
  showAvatar: boolean
}

export default class UserChip extends React.Component<PropsType, StateType> {
  state = {
    showAvatar: true
  }

  static defaultProps = {
    id: undefined,
    onRequestDelete: undefined
  }

  componentWillReceiveProps(nextProps: PropsType) {
    const { showAvatar } = this.state
    const newShowAvatar = nextProps.entity.avatarPicture !== this.props.entity.avatarPicture || showAvatar
    this.setState({ showAvatar: newShowAvatar })
  }

  renderAvatar() {
    const { entity } = this.props
    const { avatarPicture } = entity
    return (
      <Avatar
        src={avatarPicture}
        onError={() => {
          this.setState({ showAvatar: false })
        }}
      />
    )
  }

  renderText() {
    const { entity } = this.props
    const name = toString.person(entity)
    const text = name && this.state.showAvatar === false ? name[0] : ''

    const avatar = <Avatar>{text}</Avatar>

    return avatar
  }

  render() {
    const { entity, onRequestDelete, id } = this.props
    if (!entity) return null
    const { avatarPicture } = entity
    const showAvatar = this.state.showAvatar && avatarPicture !== undefined
    const avatar = showAvatar ? this.renderAvatar() : this.renderText()
    const name = toString.person(entity)
    return <Chip onDelete={onRequestDelete} id={id} label={name} avatar={avatar} />
  }
}
