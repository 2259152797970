// @flow
import type { StudySurveyDtoType } from 'common-docdok/src/domain/survey/types/studySurveyDto'

export const scheduleSurveyDialogActionTypes = {
  SET_STUDY_SURVEY_SCHEDULE: 'SET_STUDY_SURVEY_SCHEDULE'
}
export type StudiesActionType = $Keys<typeof scheduleSurveyDialogActionTypes>

export const scheduleSurveyDialogActions = {
  setStudySurveySchedule(payload?: StudySurveyDtoType) {
    return {
      type: scheduleSurveyDialogActionTypes.SET_STUDY_SURVEY_SCHEDULE,
      payload
    }
  }
}
