// @flow
import React from 'react'
import cachedEntity, { type CachedEntityPropsType } from '../../../../../common/CachedEntity'
import { PersonView } from './PersonView'
import type { PersonViewPropsType } from './PersonView'

type PropsType = PersonViewPropsType & CachedEntityPropsType // this is currently not working somehow. that's why flow was removed from the header

function studyCoordiantor(props: PropsType) {
  const { entity, id, labelKey, onRequestDelete, pageSize, actionName, addTooltip, onRequestAdd } = props
  return (
    <PersonView
      onRequestAdd={onRequestAdd}
      addTooltip={addTooltip}
      actionName={actionName}
      id={id}
      pageSize={pageSize}
      labelKey={labelKey}
      onRequestDelete={onRequestDelete}
      rows={entity ? [entity] : []}
    />
  )
}

export default cachedEntity(studyCoordiantor)
