// @flow
import type { ActionType, ExtractReturn } from 'common-docdok/src/types'
import { surveysActionTypes, surveysActions } from '../actions'

// State Definition
export type SurveysStateType = {
  showSurveyResponsesDialog: boolean,
  participationId: ?number,
  showSendSurveyDialog: boolean,
  showScoreChart: boolean,
  showScoreChartSurveyId: ?number,
  surveyGroupForForm: ?Object
}

// Default State
const initialState: SurveysStateType = {
  showSurveyResponsesDialog: false,
  participationId: undefined,
  showSendSurveyDialog: false,
  showScoreChart: false,
  showScoreChartSurveyId: undefined,
  surveyGroupForForm: {}
}

// Action Handlers

function toggleSurveyResponsesDialog(
  state: SurveysStateType,
  action: ExtractReturn<typeof surveysActions.toggleSurveyResponsesDialog>
): SurveysStateType {
  return {
    ...state,
    showSurveyResponsesDialog: !state.showSurveyResponsesDialog,
    participationId: action.payload
  }
}

function setSendSurveyDialog(
  state: SurveysStateType,
  action: ExtractReturn<typeof surveysActions.setSendSurveyDialog>
): SurveysStateType {
  return {
    ...state,
    showSurveyResponsesDialog: !state.showSurveyResponsesDialog,
    showSendSurveyDialog: action.payload
  }
}

function toggleShowScoreChart(
  state: SurveysStateType,
  action: ExtractReturn<typeof surveysActions.toggleShowScoreChart>
): SurveysStateType {
  return {
    ...state,
    showScoreChart: !state.showScoreChart,
    showScoreChartSurveyId: action.payload
  }
}


function toggleSurveyGroupDialog(
  state: SurveysStateType,
  action: ExtractReturn<typeof surveysActions.toggleSurveyGroupDialog>
): SurveysStateType {
  return {
    ...state,
    surveyGroupForForm: action.payload
  }
}

const ACTION_HANDLERS = {
  [surveysActionTypes.TOGGLE_SURVEY_RESPONSES_DIALOG]: toggleSurveyResponsesDialog,
  [surveysActionTypes.SET_SEND_SURVEY_DIALOG]: setSendSurveyDialog,
  [surveysActionTypes.TOGGLE_SHOW_SCORE_CHART]: toggleShowScoreChart,
  [surveysActionTypes.TOGGLE_SURVEY_GROUP_DIALOG]: toggleSurveyGroupDialog
}

export default function surveysReducer(state: SurveysStateType = initialState, action: ActionType): SurveysStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
