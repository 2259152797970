// @flow
import type { DeviceDefinitionDtoType } from './deviceDefinitionDto'
import type { StudyParameterType } from './studyParameters'

export const fieldNames = {
  coordinatorRef: 'coordinatorRef',
  createdDate: 'createdDate',
  devices: 'devices',
  id: 'id',
  name: 'name',
  status: 'status',
  targetParticipantNumber: 'targetParticipantNumber',
  targetPhysicianNumber: 'targetPhysicianNumber',
  key: 'key',
  studyParameters: 'studyParameters'
}

export const fields = {
  coordinatorRef: { type: 'string' },
  createdDate: { type: 'Date' },
  devices: { type: 'Array<DeviceDefinitionDTO>' },
  id: {
    type: 'number',
    format: 'int64'
  },
  name: { type: 'string' },
  status: {
    type: 'string',
    enum: ['ACTIVE', 'FINISHED', 'PAUSED']
  },
  targetParticipantNumber: {
    type: 'number',
    format: 'int64'
  },
  targetPhysicianNumber: {
    type: 'number',
    format: 'int64'
  },
  key: { type: 'string' },
  studyParameters: { type: 'StudyParameterType' }
}

export type StudyDtoType = {
  coordinatorRef: ?string,
  createdDate: Date,
  devices: Array<DeviceDefinitionDtoType>,
  id: number,
  name: string,
  status: string,
  targetParticipantNumber: number,
  targetPhysicianNumber: number,
  key: string,
  studyParameters: StudyParameterType
}
