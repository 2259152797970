import React from 'react'
import { Translate } from 'react-redux-i18n'

import { checkRoute } from '../../../../common/Security/utils'

export default store => ({
  path: 'profile',
  onEnter: (state, replace) => {
    checkRoute(store.getState(), state.location.pathname, replace)
  },
  name: (
    <span id='breadcrumb1'>
      <Translate value='menu.profile' />
    </span>
  ),
  /*  Async getComponent is only invoked when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
        and embed an async module loader (jsonp) when bundling   */
    require.ensure(
      [],
      (require) => {
        /*  Webpack - use require callback to define
          dependencies for bundling   */
        const container = require('./containers/Profile').default

        /*  Return getComponent   */
        cb(null, container)

        /* Webpack named bundle   */
      },
      'profile'
    )
  }
})
