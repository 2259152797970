// @flow
import { IDLE_LISTENERS, IDLE_NO_MORE_LISTENERS } from '../common/utils'

export const USER_IS_IDLE = 'USER_IS_IDLE'
export type UserIsIdleType = () => void
export function userIsIdle() {
  return {
    type: USER_IS_IDLE
  }
}

export const USER_IS_NO_MORE_IDLE = 'USER_IS_NO_MORE_IDLE'
export type UserIsNoMoreIdleType = () => void
export function userIsNoMoreIdle() {
  return {
    type: USER_IS_NO_MORE_IDLE
  }
}

export default function addIdleDetector(store: Object) {
  IDLE_LISTENERS.push(() => {
    store.dispatch(userIsIdle())
  })
  IDLE_NO_MORE_LISTENERS.push(() => {
    store.dispatch(userIsNoMoreIdle())
  })
}
