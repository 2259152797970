// @flow
import React from 'react'
import pathSatisfies from 'ramda/src/pathSatisfies'
import throttle from 'lodash/throttle'

import type { MessageDtoType } from 'common-docdok/src/domain/messaging/types/messageDto'
import styled from 'styled-components'
import MetaInfoOverlay from './metaInfoOverlay'
import contentLoader from '../containers/contentLoader'
import { renderAndClickHiddenDownloadLink } from '../../utils'

type PropsType = {
  message: MessageDtoType,
  originalDataUrl: ?string,
  loadResource: any
}

type StateType = {
  isDownloadRequested: boolean
}

const ChatImage = styled.img`
  object-fit: scale-down
  max-width: 50vw;
  max-height: 20vh;
  border: 0 none;

  text-decoration: none;
  cursor: pointer;
  margin: none;
`

const PreviewContainer = styled.div`
  overflow: hidden;
  position: relative;
  margin: 5px 5px 5px 5px;
  min-width: 200px;
  display: flex;
  justify-content: center;
`

class PdfMessage extends React.Component<PropsType, StateType> {
  state = { isDownloadRequested: false }

  componentDidUpdate() {
    const { message, originalDataUrl } = this.props
    const isPdf = pathSatisfies(mimeType => mimeType.includes('pdf'), ['mediaResource', 'mimeType'])(message)
    if (originalDataUrl && isPdf && this.state.isDownloadRequested) {
      window.postMessage(JSON.stringify({
        type: 'onDownloadFile',
        data: message.mediaResource
      }))
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({
          type: 'onDownloadFile',
          data: message.mediaResource
        }))
      }
    }
    if (message.mediaResource && isPdf && this.state.isDownloadRequested && originalDataUrl && !message.clearCatch) {
      // eslint-disable-next-line
      this.setState({ isDownloadRequested: false })
      renderAndClickHiddenDownloadLink(originalDataUrl, message.text)
    }
  }

  initiateDownload = () => {
    const { loadResource } = this.props
    if (!this.state.isDownloadRequested) {
      loadResource()
      this.setState({ isDownloadRequested: true })
    }
  }

  throttledDownload = throttle(() => this.initiateDownload(), 5000)

  render() {
    const { message } = this.props
    if (!message.mediaResource) return null

    const isPdf = pathSatisfies(mimeType => mimeType.includes('pdf'), ['message', 'mediaResource', 'mimeType'])(
      this.props
    )

    return (
      isPdf && (
        <a id={`pdf-${message.uuid}`} style={{ textDecoration: 'none' }} onClick={this.throttledDownload}>
          <PreviewContainer>
            <MetaInfoOverlay show isImage={false} meta={message.meta} color='black' />

            <ChatImage blur={false} src='/messaging/pdf.jpeg' alt='message.text' />
          </PreviewContainer>
        </a>
      )
    )
  }
}

export default contentLoader(PdfMessage)
