// @flow
import type { ActionType, ExtractReturn } from 'common-docdok/src/types'
import type { PatientDtoType } from 'common-docdok/src/domain/healthrelation/types/patientDto'
import type { UserDtoType } from 'common-docdok/src/domain/user/types/userDto'
import { userActionTypes, userActions } from 'common-docdok/src/domain/user/actions'

import { SEARCH_PATIENT_SUCCESS } from '../actions/searchPatientSuccess'
import { SEARCH_PATIENT } from '../actions/searchPatient'
import { headerActionsTypes } from '../actions'

export type PatientUserType = PatientDtoType & UserDtoType

// State Definition
export type HeaderStateType = {
  searchResult: ?Array<PatientUserType>,
  showTerms: ?string
}

// Default State
const initialState: HeaderStateType = {
  searchResult: undefined,
  showTerms: undefined
}

// Action Handlers

function searchPatientSucccess(state: HeaderStateType, action: ActionType): HeaderStateType {
  return {
    ...state,
    searchResult: action.payload
  }
}

function searchPatient(state: HeaderStateType): HeaderStateType {
  return {
    ...state,
    searchResult: undefined
  }
}

function showTerms(
  state: HeaderStateType,
  action: ExtractReturn<typeof userActions.termsVersionChanged>
): HeaderStateType {
  return {
    ...state,
    showTerms: action.payload
  }
}

function hideTerms(state: HeaderStateType): HeaderStateType {
  return {
    ...state,
    showTerms: undefined
  }
}

const ACTION_HANDLERS = {
  [SEARCH_PATIENT_SUCCESS]: searchPatientSucccess,
  [SEARCH_PATIENT]: searchPatient,
  [userActionTypes.TERMS_VERSION_CHANGED]: showTerms,
  [headerActionsTypes.SHOW_ACCEPTED_TERMS]: showTerms,
  [userActionTypes.UPDATE_TERMS_VERSION_SUCCEEDED]: hideTerms,
  [headerActionsTypes.HIDE_TERMS]: hideTerms
}

export default function headerReducer(
  state: HeaderStateType = initialState,
  action: ActionType
): HeaderStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
