// @flow
import moment from 'moment'

export const emergencyCodes = {
  day: {
    description: ' Notfall-Inkonvenienzpauschale, Mo-So 19-22, Sa 12-19, So 7-19',
    code: '00.2560',
    serverCode: '00.2560,00.2570' // the code we send to the server
  },
  night: {
    description: 'Notfall-Inkonvenienzpauschale, Mo-So 22-7',
    code: '00.2580',
    serverCode: '00.2580,00.2590' // the code we send to the server
  }
}

export const codePreconditions = ['00.0120', '00.0125', '00.0126']

export function getDefaultCode(time: Date): ?string {
  const now = moment(time)
  const weekDay = now.isoWeekday()
  const hour = now.hour()
  const isWorkweek = weekDay <= 5
  const isSaturday = weekDay === 6
  const isSunday = weekDay === 7
  const isNight = hour >= 22 || hour <= 6
  const isDay = hour >= 7 && hour <= 19
  const isEvening = hour >= 19 && hour <= 21

  if (isNight) {
    return 'night'
  }

  if (isWorkweek) {
    if (isEvening) {
      return 'day'
    }
  } else if (isSaturday) {
    const isAfternoon = hour >= 12 && hour <= 19
    if (isAfternoon || isEvening) {
      return 'day'
    }
  } else if (isSunday) {
    if (isDay || isEvening) {
      return 'day'
    }
  }
  return null
}
