export default {
  en: {
    previous: 'Previous',
    next: 'Next',
    loading: 'Loading...',
    noData: 'No rows found',
    page: 'Page',
    of: 'of',
    rows: 'rows'
  },
  de: {
    previous: 'Zurück',
    next: 'Nächste',
    loading: 'Wird geladen...',
    noData: 'Keine Einträge vorhanden.',
    page: 'Seite',
    of: 'von',
    rows: 'Zeilen'
  }
}
