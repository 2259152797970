// @flow
/* eslint-disable */

import React from 'react'

import { Translate } from 'react-redux-i18n'
import { Button } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Done from '@material-ui/icons/Done'
import { Buttons } from '../../Static'
import styled from 'styled-components'
import Verified from './Verified'

const Table = styled.div`
  display: table;
`

const TableRow = styled.div`
  display: table-row;
`

const TableCell = styled.div`
  display: table-cell;
  padding-top: 10px;
`
type PropsType = {
  criteria: string,
  selected: Boolean,
  toggle: () => void
}

function InclusionExclusionLine(props: PropsType) {
  const { criteria, selected, toggle } = props
  const verifyButton = (
    <Button onClick={toggle} variant="contained" style={{ height: '1.4em' }}>
      <Translate value="studies.participationCriteria.verify" />
    </Button>
  )
  const verified = <Verified toggle={toggle} />

  return (
    <TableRow>
      <TableCell style={{ paddingRight: '10px' }}>{selected ? verified : verifyButton}</TableCell>
      <TableCell style={{ width: '80%' }}>{criteria}</TableCell>
    </TableRow>
  )
}

export default class InclusionExclusion extends React.Component<PropsType> {
  allVerified = () => {
    const { criterias } = this.props
    return criterias.reduce((pre, cur) => pre && cur.verified, true)
  }

  render() {
    const { forward, criterias, toggleCriteria } = this.props
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Table>
          {criterias.map((criteria, i) => (
            <InclusionExclusionLine
              toggle={toggleCriteria(i)}
              criteria={criteria.criteria}
              key={i}
              selected={criteria.verified}
            />
          ))}
        </Table>

        <Button
          style={{ alignSelf: 'flex-end' }}
          onClick={forward}
          variant="contained"
          color="primary"
          disabled={!this.allVerified()}
        >
          <Translate value="actions.next" />
        </Button>
      </div>
    )
  }
}
