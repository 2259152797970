// @flow
import { put, takeEvery, call } from 'redux-saga/effects'
import type { GenType } from 'common-docdok/src/types'
import api from 'common-docdok/src/api'
import { userActionTypes } from 'common-docdok/src/domain/user/actions'
import { fetchProfile } from 'common-docdok/src/domain/user/sagas/profile'
import { healthrelationActionTypes } from 'common-docdok/src/domain/healthrelation/actions'

import {
  dynamicFormActionTypes,
  type SubmitFormType
} from '../../../../../common/DynamicForm/actions'
import { infoPanelActions } from '../../../../../common/InfoPanel/actions'
import { profileActions } from '../actions'

export function* updateProfile(action: SubmitFormType): GenType {
  if (action.payload.name === 'profile') {
    try {
      yield call(api.updateProfile, action.payload.data)
      yield fetchProfile()
    } catch (e) {
      yield put(infoPanelActions.showMessage(e.message))
    }
  }
}

export function* closeUploadDialog(): GenType {
  yield put(profileActions.setUploadUserAvatarDialog(false))
  yield put(infoPanelActions.showMessage('profile.photoUpdated'))
}

export function* setAvailabilitySucceeded(): GenType {
  yield put(infoPanelActions.showMessage('profile.availability.updated'))
}

export default function profileSaga(): Array<GenType> {
  return [
    takeEvery(dynamicFormActionTypes.SUBMIT_FORM, updateProfile),
    takeEvery(userActionTypes.UPLOAD_AVATAR_SUCCEEDED, closeUploadDialog),
    takeEvery(healthrelationActionTypes.SET_AVAILABILITY_SUCCEEDED, setAvailabilitySucceeded)
  ]
}
