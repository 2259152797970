// @flow
import React from 'react'
import { fields } from 'common-docdok/src/domain/user/types/userDto'
import type { UserDtoType } from 'common-docdok/src/domain/user/types/userDto'
import { connect } from 'react-redux'
import pathOr from 'ramda/src/pathOr'

import { updateUserDialogActions } from '../actions'
import DynamicFormDialog from '../../DynamicFormDialog'

type PropsType = {
  setUpdateUserDialog: typeof updateUserDialogActions.setUpdateUserDialog,
  user: UserDtoType,
  isAdmin: boolean
}

class UpdateUserDialog extends React.Component<PropsType> {
  render() {
    const { setUpdateUserDialog, user, isAdmin } = this.props

    if (!user) return null

    let formFields = [
      {
        salutation: fields.salutation
      },
      {
        firstName: fields.firstName,
        lastName: fields.lastName
      },
      {
        email: fields.email,
        mobileNumber: fields.mobileNumber
      },
      {
        langKey: fields.langKey,
        gender: fields.gender
      }
    ]


    if (!isAdmin && user.activated) {
      formFields = [
        {
          salutation: fields.salutation
        },
        {
          firstName: fields.firstName,
          lastName: fields.lastName
        },

        {
          langKey: fields.langKey,
          gender: fields.gender
        }
      ]
    }

    return (
      <DynamicFormDialog
        open={!!user}
        handleClose={() => setUpdateUserDialog(undefined)}
        titleKey='users.updateUser.title'
        form='updateUser'
        saveLabelKey='actions.update'
        initialValues={user}
        fields={formFields}
      />
    )
  }
}

const getAuthorities = pathOr([], ['profile', 'authorities'])

const isAdmin = (state) => {
  const authorities = getAuthorities(state)
  return authorities.includes('ROLE_ADMIN') || authorities.includes('ROLE_SUPPORT_ADMIN')
}

const mapStateToProps = (state: StoreType) => ({
  user: state.updateUserDialog.showUpdateUserDialog,
  isAdmin: isAdmin(state)
})

export default connect(
  mapStateToProps,
  { setUpdateUserDialog: updateUserDialogActions.setUpdateUserDialog }
)(UpdateUserDialog)
