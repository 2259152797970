import Axios from 'axios'
import { getKeycloakClient } from '../../../../../../../common/src/common/Keycloak/keycloakProvider'

export default async (surveyParticipationId: number, surveyName: string) => {
  const requestUrl = `/rest/survey/api/survey-participations/${surveyParticipationId}/pdf/`
  await Axios.get(`${requestUrl}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/pdf',
      Accept: 'application/json',
      Authorization: `Bearer ${getKeycloakClient().getToken()}`
    }
  }).then((response) => {
    if (response.data) {
      const file = new Blob([response.data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)

      const link = document.createElement('a')
      link.href = fileURL
      link.setAttribute('download', `survey_${surveyName}_results.pdf`)
      document.body.appendChild(link)

      // Start download
      link.click()

      // Clean up and remove the link
      link.parentNode.removeChild(link)
    }
  })
}
