// @flow

import invariant from 'invariant'
import type { AxiosPromise } from 'axios'
import { getAxiosInstance } from './'
import type {
  SubscriptionDtoType,
  ConversationDtoType,
  MessageDtoType
} from '../types'
import type { ContentDefinitionVmType } from '../domain/messaging/types/contentDefinitionVm'
import type { AccessRightsVmType } from '../domain/messaging/types/accessRightsVm'
import type { SubscriptionWithConversationDtoType } from '../domain/messaging/types/subscriptionWithConversationDto'
import { decrypt, encrypt } from '../utils/crypto'
import buildPaginingUrl from './util'

const base = '/rest/messaging/api/'

/**
 * Just decrypt a message
 * @param {MessageDtoType} message
 * @param {string} secret
 * @returns {MessageDtoType}
 */
function decryptMessage(
  message: MessageDtoType,
  secret: string
): MessageDtoType {
  if (message) {
    const text = decrypt(message.text, secret)
    return { ...message, text }
  }
  return message
}

function decryptSubscriptionWithConversation(
  subscription: SubscriptionWithConversationDtoType
): SubscriptionWithConversationDtoType {
  return {
    ...subscription,
    latestMessage: decryptMessage(
      subscription.latestMessage,
      subscription.conversation.secret
    )
  }
}

type SubscriptionsReturnType = AxiosPromise<Array<SubscriptionWithConversationDtoType>>
/*
 * Get subscriptions as an array.
 * @param {AxiosXHRConfig} [config=undefined] axios config
 * @return {AxiosPromise<Array<SubscriptionWithConversationDtoType>>> }
 */
export function getSubscriptions(): SubscriptionsReturnType {
  return getAxiosInstance()
    .get(`${base}subscriptions`)
    .then((result: any) => ({
      ...result,
      data: result.data.map(decryptSubscriptionWithConversation)
    }))
}
export function getAllSubsWhereUserHasASub(
  filterSilentConversations?: boolean = true,
  userRef?: string
): SubscriptionsReturnType {
  const filter = filterSilentConversations ? 'true' : 'false'
  const userRefString = userRef ? `&userRef=${userRef}` : ''
  return getAxiosInstance()
    .get(
      `${base}conversations/subscriptions?filterSilentConversations=${filter}${userRefString}`
    )
    .then((result: any) => ({
      ...result,
      data: result.data.map(decryptSubscriptionWithConversation)
    }))
}

/**
 * Get subscriptions as an array.
 * @param {AxiosXHRConfig} [config=undefined] axios config
 * @param {number} subscriptionId
 * @return {AxiosPromise<SubscriptionWithConversationDtoType>}
 */
export function getSubscription(
  subscriptionId: number
): AxiosPromise<SubscriptionWithConversationDtoType> {
  invariant(subscriptionId, 'subscriptionId undefinded')
  return getAxiosInstance()
    .get(`${base}subscriptions/${subscriptionId}`)
    .then((result: any) => {
      const latestMessage = decryptMessage(
        result.data.latestMessage,
        result.data.conversation.secret
      )
      return {
        ...result,
        data: {
          ...result.data,
          latestMessage
        }
      }
    })
}

/**
 * Get message as an array from conversion id and lastSequenceNo.
 * @param {number} conversationId
 * @param {number} [lastSequenceNo=0] axios config
 * @param {AxiosXHRConfig} [config=undefined] axios config
 * @return {AxiosPromise<Array<MessageDtoType>>}
 */
export function getMessages(
  conversationId: number,
  secret: string,
  lastSequenceNo: number = 0
): AxiosPromise<Array<MessageDtoType>> {
  return getAxiosInstance()
    .get(
      `${base}conversations/${conversationId}/messages?newerThan=${lastSequenceNo}`
    )
    .then((result: any) => {
      const data = result.data.map(message => decryptMessage(message, secret))
      return {
        ...result,
        data
      }
    })
}

/**
 * Get message as an array from conversion id, lastSequenceNo and page.
 * @param {number} conversationId
 * @param {number} page
 * @param {number} [limit=20]
 * @param {AxiosXHRConfig} [config=undefined] axios config
 * @return {AxiosPromise<Array<MessageDtoType>>}
 */
export function getMessagePage(
  conversationId: number,
  secret: string,
  page: number,
  limit: number = 20
): AxiosPromise<Array<MessageDtoType>> {
  return getAxiosInstance()
    .get(
      `${base}conversations/${conversationId}/messages?page=${page}&limit=${limit}`
    )
    .then((result: any) => ({
      ...result,
      data: result.data.map(message => decryptMessage(message, secret))
    }))
}

/**
 * Post a message.
 * @param {object} message
 * @return AxiosPromise<MessageDtoType>
 */
export function postMessage(
  message: { text: string },
  secret: string
): AxiosPromise<MessageDtoType> {
  invariant(message, 'message undefined')
  invariant(secret, 'secret undefined')
  return getAxiosInstance()
    .post(`${base}messages`, {
      ...message,
      text: encrypt(message.text, secret)
    })
    .then((result: any) => ({
      ...result,
      data: decryptMessage(result.data, secret)
    }))
}

/**
 * Create conversation with a title
 * @param {string} title
 * @return {AxiosPromise<ConversationDtoType>}
 */
export function createConversation(
  title: string,
  meta?: {} = {}
): AxiosPromise<ConversationDtoType> {
  invariant(title, 'title undefined')
  return getAxiosInstance().post(`${base}conversations`, {
    title,
    type: 'CHAT',
    meta
  })
}

/**
 * Update conversation with a id
 * @param {string} title
 * @return {AxiosPromise<ConversationDtoType>}
 */
export function updateConversation(
  id: number,
  title: string,
  meta?: {} = {}
): AxiosPromise<ConversationDtoType> {
  invariant(title, 'title undefined')
  invariant(title, 'id of conversation undefined')
  return getAxiosInstance().put(`${base}conversations/${id}`, {
    id,
    title,
    type: 'CHAT',
    meta
  })
}

/**
 * Create subscription with a title
 * @param {number} conversationId
 * @param {string} userRef
 * @return {AxiosPromise<SubscriptionDtoType>}
 */
export function createSubscription(
  title: string,
  conversationId: number,
  userRef: string
): AxiosPromise<*> {
  invariant(conversationId, 'conversationId undefined')
  invariant(title, 'title undefined')
  invariant(userRef, 'userRef undefined')
  return getAxiosInstance().post(`${base}subscriptions`, {
    title,
    conversationId,
    userRef
  })
}

/**
 * Delete subscription by subscriptionId
 * @param {number} subscriptionId
 * @return {AxiosPromise<*>}
 */
export function deleteSubscription(subscriptionId: number): AxiosPromise<*> {
  invariant(subscriptionId, 'subscriptionId undefined')
  return getAxiosInstance().delete(`${base}subscriptions/${subscriptionId}`)
}

/**
 * update subscription
 * @param {SubscriptionDtoType} subscription
 * @return {AxiosPromise<*>}
 */
export function updateSubscription(
  subscription: SubscriptionDtoType
): AxiosPromise<*> {
  invariant(subscription, 'subscription undefined')

  return getAxiosInstance().put(`${base}subscriptions`, subscription)
}

export function joinPrimaryConversation(
  userRef: string,
  healthcareSubject: string
): AxiosPromise<*> {
  return getAxiosInstance().post(`${base}conversations/join`, {
    userRef,
    metaSelector: {
      primary: 'true',
      healthcareSubject
    }
  })
}

export function joinCaseStreamConversation(
  userRef: string,
  healthcareSubject: string,
  caseStreamId: string
): AxiosPromise<*> {
  return getAxiosInstance().post(`${base}conversations/join`, {
    userRef,
    metaSelector: {
      primary: 'false',
      healthcareSubject,
      caseStreamId
    }
  })
}

/**
 * Get subscriptions by conversationId
 * @param {number} conversationId
 * @return {AxiosPromise<Array<SubscriptionDtoType>>}
 */
export function getSubscriptionsOfConversation(
  conversationId: number
): AxiosPromise<Array<SubscriptionDtoType>> {
  invariant(conversationId, 'conversationId undefined')
  return getAxiosInstance().get(
    `${base}conversations/${conversationId}/subscriptions`
  )
}

/**
 * Get conversation by conversationId
 * @param {number} conversationId
 * @return {AxiosPromise<Array<SubscriptionDtoType>>}
 */
export function getConversation(
  conversationId: number
): AxiosPromise<Array<SubscriptionDtoType>> {
  invariant(conversationId, 'conversationId undefined')
  return getAxiosInstance().get(`${base}conversations/${conversationId}`)
}

export function uploadRequest(mimeTypeString: string, conversationId: number) {
  invariant(mimeTypeString, 'mimeType undefined')
  invariant(conversationId, 'conversationId undefined')
  return getAxiosInstance().post(`${base}messages/uploadrequest`, {
    mimeTypeString,
    conversationId
  })
}

export function uploadFile(
  url: string,
  mimeType: string,
  file: ArrayBuffer
): AxiosPromise<*> {
  invariant(url, 'url undefined')
  invariant(file, 'file undefined')
  return getAxiosInstance().put(url, file, {
    headers: {
      'Content-Type': mimeType,
      'Cache-Control': 'max-age=15552000,immutable'
    }
  })
}

export function markAsChecked(
  conversationId: number,
  sequenceNo: number
): AxiosPromise<*> {
  invariant(conversationId, 'conversationId undefined')
  invariant(sequenceNo >= 0, 'sequenceNo undefined')
  return getAxiosInstance().put(
    `${base}conversations/${conversationId}/messages/${sequenceNo}/read`
  )
}

export function assignConversationToMe(
  conversationId: number
): AxiosPromise<*> {
  invariant(conversationId, 'conversationId undefined')

  return getAxiosInstance().put(
    `${base}conversations/${conversationId}/control`,
    {
      controlType: 'assignToMe'
    }
  )
}

export function getMediaUrl(
  uuid: string,
  preview: boolean,
  baseApi?: string = '',
  token?: string
) {
  const url = `${baseApi}${base}messages/media/redirect/${
    preview ? 'preview' : 'upload'
  }/${uuid}`
  return token ? `${url}?token=${token}` : url
}

export function getMediaRedirectUrl(
  uuid: string,
  baseApi?: string = ''
): AxiosPromise<string> {
  invariant(uuid, 'uuid undefined')
  const mediaUrl = `${baseApi}${base}messages/media/redirect-url/upload/${uuid}`
  return getAxiosInstance().get(mediaUrl)
}

export function getMediaBlobWithSignedUrl(
  signedUrl: string,
  mimeTypeString?: string = 'application/pdf',
  clearCache?: boolean = false
): Promise<Blob> {
  invariant(signedUrl, 'url undefined')
  const axiosInstanceToUse = getAxiosInstance()
  if (clearCache) {
    axiosInstanceToUse.defaults.headers = {
      'Cache-Control': 'no-cache'
    }
  }
  return getAxiosInstance()
    .get(signedUrl, {
      responseType: 'blob',
      headers: {
        Accept: mimeTypeString
      },
      timeout: clearCache ? 5000 : 2000
    })
    .then(response => response.data)
}

export function getMediaBlob(
  uuid: string,
  mimeTypeString?: string = 'application/pdf',
  preview?: boolean = true
): Promise<Blob> {
  invariant(uuid, 'uuid undefined')
  return getAxiosInstance()
    .get(getMediaUrl(uuid, preview), {
      responseType: 'blob',
      headers: {
        Accept: mimeTypeString
      }
    })
    .then(response => response.data)
}

/**
 * Get survey definition to import.
 * @return {AxiosPromise<*>}
 */
export function getContentDefinition(
  tags?: Array<string> = [],
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: string,
  orderBy?: string
): AxiosPromise<Array<ContentDefinitionVmType>> {
  const path = buildPaginingUrl(
    `${base}content-definition`,
    pageSize,
    page,
    searchTerm,
    orderBy
  )

  const tagsParams =
    tags.length > 0 ? `&searchTags=${tags.join('&searchTags=')}` : ''

  return getAxiosInstance().get(path + tagsParams)
}

/**
 * imports the content definition from the github
 * @return {AxiosPromise<*>}
 */
export function updateContentDefinition(revision?: string): AxiosPromise<*> {
  return getAxiosInstance().get(
    `${base}content-definition/update/${revision || 'master'}`
  )
}
/**
 * imports the content definition from the github
 * @return {AxiosPromise<*>}
 */
export function sendContentMessages(
  contentDefinitionIds: Array<string>,
  conversationIds: Array<string>
): AxiosPromise<Array<MessageDtoType>> {
  return getAxiosInstance().post(`${base}contentmessages`, {
    contentDefinitionIds,
    conversationIds
  })
}

/**
 * sets the access rights for a ContentDefintion
 * @return {AxiosPromise<*>}
 */
export function setContentDefinitionAccessRights(
  definitionId: number,
  accessRight: AccessRightsVmType
): AxiosPromise<ContentDefinitionVmType> {
  return getAxiosInstance().post(
    `${base}content-definition/${definitionId}/access-rights`,
    accessRight
  )
}
/**
 * create a message preview for a given contentDefintion-id
 * @return {AxiosPromise<MessageDtoType>}
 */
export function createContentMessagePreview(
  definitionId: string
): AxiosPromise<MessageDtoType> {
  return getAxiosInstance().get(
    `${base}content-definition/${definitionId}/preview`
  )
}

/**
 * returns all currently used tags
 * @return {AxiosPromise<Array<string>>}
 */
export function getContentDefinitionTags(): AxiosPromise<Array<string>> {
  return getAxiosInstance().get(`${base}content-definition/tags`)
}

export function invite2VideoConference(
  conversationId: string
): AxiosPromise<string> {
  return getAxiosInstance().post(
    `${base}videochat/conversation/${conversationId}/invite`
  )
}

export function physicianJoinVideoConference(
  conversationId: string
): AxiosPromise<string> {
  return getAxiosInstance().get(
    `${base}videochat/callback/redirectphysician/${conversationId}`
  )
}

export function uploadCatalog(data: any): AxiosPromise<any> {
  return getAxiosInstance().post(`${base}content-definition`, data)
}

export function updateCatalog(data: any): AxiosPromise<any> {
  return getAxiosInstance().put(`${base}content-definition`, data)
}

export function uploadCatalogGetFileURL(
  contentDefinitionId: string
): AxiosPromise<any> {
  return getAxiosInstance().get(
    `${base}content-library-create-signed-url?contentDefinitionId=${contentDefinitionId}`
  )
}

export function deleteContentDefinition(
  contentDefinitionId: string
): AxiosPromise<any> {
  return getAxiosInstance().delete(
    `${base}content-definition/${contentDefinitionId}`
  )
}
