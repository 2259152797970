// @flow
import type { ExtractReturn } from '../../../types'
import type { PatientDtoType } from '../types/patientDto'
import type { ClinicDtoType } from '../types/clinicDto'
import type { HealthcareProfessionalDtoType } from '../types/healthcareProfessionalDto'
import type { HealthrelationActionType } from '../actions'
import { healthrelationActions as actions, healthrelationActionTypes as types } from '../actions'

export type HealthrelationCacheStateType = {
    patients: {
        [key: string]: PatientDtoType
    },
    professionals: {
        [key: string]: HealthcareProfessionalDtoType
    },
    clinics: {
        [key: string]: ClinicDtoType
    }
}

// Default State
const initialState = {
  patients: {},
  professionals: {},
  clinics: {}
}

// Helpers

export function addPatient(
  state: HealthrelationCacheStateType,
  patient: PatientDtoType
): HealthrelationCacheStateType {
  let newState = { ...state }
  newState = {
    ...newState,
    patients: {
      ...newState.patients,
      [patient.uuid]: patient
    }
  }
  return newState
}

export function addProfessional(
  state: HealthrelationCacheStateType,
  prof: HealthcareProfessionalDtoType
): HealthrelationCacheStateType {
  let newState = { ...state }
  newState = {
    ...newState,
    professionals: {
      ...newState.professionals,
      [prof.uuid]: prof
    }
  }
  return newState
}

// Action Handlers

export function addPatients(
  state: HealthrelationCacheStateType,
  action: ExtractReturn<typeof actions.loadAllPatientsSucceeded>
): HealthrelationCacheStateType {
  let newState = { ...state }
  const patients = action.payload

  patients.forEach((user: Object) => {
    newState = {
      ...newState,
      ...addPatient(newState, user)
    }
  })

  return newState
}

export function addProfessionals(
  state: HealthrelationCacheStateType,
  action: ExtractReturn<typeof actions.loadTeamSucceeded> &
        ExtractReturn<typeof actions.loadProfessionalsSucceeded> &
        ExtractReturn<typeof actions.loadProfessionalsByPublicUuidSucceeded>
): HealthrelationCacheStateType {
  let newState = { ...state }
  const professioanls = action.payload

  professioanls.forEach((prof: Object) => {
    newState = {
      ...newState,
      ...addProfessional(newState, prof)
    }
  })

  return newState
}

export function addClinic(
  state: HealthrelationCacheStateType,
  action: ExtractReturn<typeof actions.getClinicSucceeded>
): HealthrelationCacheStateType {
  const clinic = action.payload

  return {
    ...state,
    clinics: {
      ...state.clinics,
      [clinic.id]: clinic
    }
  }
}

export function addClinics(
  state: HealthrelationCacheStateType,
  action: ExtractReturn<typeof actions.loadClinicsSucceeded>
): HealthrelationCacheStateType {
  const clinics = action.payload

  const map = clinics.reduce(
    (obj, clinic) => ({
      ...obj,
      [clinic.id]: clinic
    }),
    {}
  )

  return {
    ...state,
    clinics: {
      ...state.clinics,
      ...map
    }
  }
}

export function addPatientHandler(
  state: HealthrelationCacheStateType,
  action: ExtractReturn<typeof actions.getPatientDtoSucceeded>
): HealthrelationCacheStateType {
  const newState = { ...state }
  const patient = action.payload
  return addPatient(newState, patient)
}

export function updateAvatarUrl(
  state: HealthrelationCacheStateType,
  action: ExtractReturn<typeof actions.uploadPatientAvatarSucceeded>
): HealthrelationCacheStateType {
  const { patientUuid, avatarUrl } = action.payload
  const patient = state.patients[patientUuid]
  if (patient) {
    const now = new Date(Date.now())
    return {
      ...state,
      patients: {
        ...state.patients,
        [patientUuid]: {
          ...patient,
          avatarPicture: `${avatarUrl.split('?')[0]}?t=${now.getTime()}`
        }
      }
    }
  }
  return state
}

const ACTION_HANDLERS: { [key: HealthrelationActionType]: Function } = {
  [types.GET_CLINIC_SUCCEEDED]: addClinic,
  [types.LOAD_CLINICS_SUCCEEDED]: addClinics,
  [types.LOAD_ALL_PATIENTS_SUCCEEDED]: addPatients,
  [types.GET_PATIENT_DTO_SUCCEEDED]: addPatientHandler,
  [types.UPDATE_PATIENT_SUCCEEDED]: addPatientHandler,
  [types.UPLOAD_PATIENT_AVATAR_SUCCEEDED]: updateAvatarUrl,
  [types.LOAD_TEAM_SUCCEEDED]: addProfessionals,
  [types.LOAD_PROFESSIONALS_SUCCEEDED]: addProfessionals,
  [types.LOAD_PROFESSIONALS_BY_PUBLICUUID_SUCCEEDED]: addProfessionals
}
type ActionType = { type: HealthrelationActionType }
export default function userCacheReducer(
  state: HealthrelationCacheStateType = initialState,
  action: ActionType
): HealthrelationCacheStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
