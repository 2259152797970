// @flow
import React from 'react'

import invariant from 'invariant'
import Lightbox from 'react-image-lightbox'
import pathSatisfies from 'ramda/src/pathSatisfies'
import type { MessageDtoType } from 'common-docdok/src/domain/messaging/types/messageDto'
import styled from 'styled-components'

import TrackVisibility from '../../Chat/components/TrackVisibility'

type PropsType = {
  message: MessageDtoType,
}

type StateType = {
  isOpen: boolean,
  remoteUrl: string
}

const ChatImage = styled.img`
  filter: ${props => props.blur && 'blur(5px)'};
  object-fit: scale-down
  max-width: 50vw;
  max-height: 15vh;
  border: 0 none;

  text-decoration: none;
  cursor: pointer;
  margin: none;
`

const PreviewContainer = styled.div`
  overflow: hidden;
  position: relative;
  margin: 2px 2px 2px 2px;
  display: flex;
  justify-content: center;
`

class ImageMessage extends React.Component<PropsType, StateType> {
  state = { isOpen: false, remoteUrl:  '/messaging/loading.gif' }

  renderLightBox() {
    const { message } = this.props
    invariant(message.mediaResource, 'Message has no mediaResource')
    return (
      this.state.isOpen && (
        <Lightbox
          mainSrc={message.mediaResource.originalUrl}
          onCloseRequest={() => this.setState({ isOpen: false })}
          imageCaption={message.text}
        />
      )
    )
  }

  onVisible = () => {
    const { message: { mediaResource } } = this.props
    this.setState({ remoteUrl: mediaResource.originalUrl })
  }


  showPicture = () => {
    if (!this.state.isOpen) {
      this.setState({ isOpen: true })
    }
  }

  render() {
    const { message } = this.props
    const { remoteUrl } = this.state

    if (!message.mediaResource) return null
    const isImage = pathSatisfies(mimeType => mimeType.startsWith('image'), ['mediaResource', 'mimeType'])(message)

    return (
      isImage && (

      <a id={`image-${message.uuid}`} style={{ textDecoration: 'none' }} onClick={this.showPicture}>
        <PreviewContainer>
          <TrackVisibility onVisible={(this.onVisible)} intersectionRatio={0.2}>
            <ChatImage blur={false} src={remoteUrl} alt='attachment' />
          </TrackVisibility>
          {this.renderLightBox()}
        </PreviewContainer>
      </a>
      )
    )
  }
}

export default ImageMessage
