// @flow
import React from 'react'
import { connect } from 'react-redux'
import compose from 'ramda/src/compose'
import path from 'ramda/src/path'
import pathSatisfies from 'ramda/src/pathSatisfies'
import type { MessageDtoType } from 'common-docdok/src/domain/messaging/types/messageDto'
import { messagingActions } from 'common-docdok/src/domain/messaging/actions'

// @flow

type ContainerPropsType = {
  message: MessageDtoType
}

type ConnectedPropType = {
  originalDataUrl: ?string,
  loadResourceOnMount: boolean,
  getMediaResourceRequested: typeof messagingActions.getMediaResourceRequested
}

type HocPropsType = ConnectedPropType & ContainerPropsType

function contentLoader(WrappedComponent: React$ComponentType<ConnectedPropType>): React$ComponentType<HocPropsType> {
  return class HOC extends React.Component<HocPropsType> {
    loadResource = (uuid: string, mimeType: string, message?: any = {}) => () => {
      const { getMediaResourceRequested } = this.props
      return getMediaResourceRequested(uuid, false, mimeType, message)
    }

    loadIt = () => {
      const { message, originalDataUrl } = this.props

      let loadItFn = () => {}
      const shouldLoadResource = mimeType => mimeType.match(/(image|pdf)/)

      if (message.mediaResource) {
        if (pathSatisfies(shouldLoadResource, ['mediaResource', 'mimeType'])(message)) {
          const { uuid, mimeType } = message.mediaResource
          loadItFn = originalDataUrl ? loadItFn : this.loadResource(uuid, mimeType)
        }
      }
      return loadItFn
    }

    render() {
      const { message, originalDataUrl } = this.props
      return message.mediaResource ? (
        <WrappedComponent
          {...this.props}
          originalDataUrl={originalDataUrl}
          message={message}
          loadResource={() => {
            const { uuid, mimeType } = message.mediaResource
            return this.loadResource(uuid, mimeType, message)()
          }}
        />
      ) : null
    }

    timer: TimeoutID

    componentWillMount() {
      const { loadResourceOnMount, originalDataUrl } = this.props
      if (loadResourceOnMount && !originalDataUrl) {
        const loadItFn = this.loadIt()
        this.timer = setTimeout(() => {
          loadItFn()
        }, 1000)
      }
    }

    componentWillUnmount() {
      clearTimeout(this.timer)
    }
  }
}

const mapStateToProps = (state: StoreType, ownProps: ContainerPropsType) => {
  const { loadedResources } = state.messaging
  const { message } = ownProps

  let originalDataUrl = null

  if (message.mediaResource) {
    const { uuid } = message.mediaResource
    originalDataUrl = path([uuid, 'originalDataUrl'])(loadedResources)
  }

  return { originalDataUrl }
}

export default compose(
  connect(
    mapStateToProps,
    {
      getMediaResourceRequested: messagingActions.getMediaResourceRequested
    }
  ),
  contentLoader
)
