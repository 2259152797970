// @flow
import type { ActionType, ExtractReturn } from 'common-docdok/src/types'

import { conversationEditorActionTypes, conversationEditorActions } from '../actions'

export type ConversationEditorStateType = {
  editConversationId: ?number,
  showEditConversation: boolean
}

const initialState: ConversationEditorStateType = {
  editConversationId: undefined,
  showEditConversation: false
}

function setEditConversation(
  state: ConversationEditorStateType,
  action: ExtractReturn<typeof conversationEditorActions.setEditConversation>
): ConversationEditorStateType {
  let editConversationId
  let showEditConversation = true
  if (isNaN(action.payload) || action.payload === false) {
    editConversationId = undefined
    showEditConversation = false
  } else {
    editConversationId = Number(action.payload)
    showEditConversation = true
  }

  return {
    ...state,
    editConversationId,
    showEditConversation
  }
}

const ACTION_HANDLERS = {
  [conversationEditorActionTypes.SET_EDIT_CONVERSATION]: setEditConversation
}

export default function messagesReducer(
  state: ConversationEditorStateType = initialState,
  action: ActionType
): ConversationEditorStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
