import { getKeycloakClient } from 'common-docdok/src/common/Keycloak/keycloakProvider'
import type { AmbulanceQueueItemDtoType } from 'common-docdok/src/domain/healthrelation/types/ambulanceQueueItemDto'
import type { ConversationDtoType } from 'common-docdok/src/domain/messaging/types/conversationDto'
import pathOr from 'ramda/src/pathOr'

export function findAssignedItem(queueItems: Array<AmbulanceQueueItemDtoType>): AmbulanceQueueItemDtoType {
  const mySelf = getKeycloakClient().getUserId()
  const alreadyAssignedToMe = queueItems.find(row => mySelf === row.userRefPhysician)
  return alreadyAssignedToMe
}

export function findConversationIdForAssignedQueueItem(
  queueItems: AmbulanceQueueItemDtoType,
  conversations: Array<ConversationDtoType>) {
  const queueItem = findAssignedItem(queueItems)
  if (queueItem && conversations) {
    return conversations.find(con => pathOr(false, ['meta', 'healthcareSubject'])(con))
      .map(con => con.id)
  }
  return -1
}
