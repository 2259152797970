// @flow
import localApi from './api'
import lastAction from './utils/lastAction'
import profile from './domain/user/reducers/profile'
import gui from './common/GuiState/reducers/gui'
import messaging from './domain/messaging/reducers/messaging'
import userCache from './domain/user/reducers/userCache'
import survey from './domain/survey/reducers'
import healthrelation from './domain/healthrelation/reducers/healthrelation'
import healthrelationCache from './domain/healthrelation/reducers/healthrelationCache'
import entityLoader from './common/EntityLoader/reducers'
import loadingStack from './common/LoadingStack/reducers'
import keycloak from './common/Keycloak/reducers'

// eslint-disable-next-line
export const api = localApi

export const reducers = {
  keycloak,
  lastAction,
  profile,
  gui,
  messaging,
  userCache,
  survey,
  healthrelation,
  healthrelationCache,
  entityLoader,
  loadingStack
}
