// @flow

import type { OrganizationDtoType } from './organizationDto'

export const fieldNames = {
  id: 'id',
  caseStreamId: 'caseStreamId',
  caseStreamName: 'caseStreamName',
  patientPublicUuid: 'patientPublicUuid',
  participantsPublicUuidList: 'participantsPublicUuidList',
  organizations: 'organizations',
  status: 'status',
  createdDate: 'createdDate'
}

export const fields = {
  id: {
    type: 'number',
    format: 'int64'
  },
  caseStreamId: { type: 'string' },
  caseStreamName: { type: 'string' },
  patientPublicUuid: { type: 'string' },
  participantsPublicUuidList: [{ type: 'string' }],
  organizations: [{ type: 'OrganizationDtoType' }],
  status: { type: 'string' },
  createdDate: { type: 'LocalTime' }
}

export type CaseStreamDtoType = {
  caseStreamId: string,
  caseStreamName: string,
  patientPublicUuid: string,
  participantsPublicUuidList: [string],
  id: number,
  organizations: [OrganizationDtoType],
  status: string,
  createdDate: Date
}
