// @flow
import { call, takeEvery, put } from 'redux-saga/effects'

import { api } from '../../..'
import type { ExtractReturn, GenType } from '../../../types'
import { surveyActions, surveyActionTypes } from '../actions'
import { userActions } from '../../user/actions'
import type { StudyParticipantDtoType } from '../types/studyParticipantDto'

// -- APPEND GENERATORS HERE --
function* deleteStudy(action: ExtractReturn<typeof surveyActions.deleteStudyRequested>): GenType {
  try {
    yield call(api.deleteStudy, action.payload)
    yield put(surveyActions.deleteStudySucceeded())
  } catch (error) {
    yield put(surveyActions.deleteStudyFailed(error))
  }
}

function* getAllStudiesGen(): GenType {
  try {
    const result = yield call(api.getAllStudies)
    const { data } = result
    yield put(surveyActions.getAllStudiesSucceeded(data))
  } catch (error) {
    yield put(surveyActions.getAllStudiesFailed(error))
  }
}

function* addStudyParticipantGen(action: ExtractReturn<typeof surveyActions.addStudyParticipantRequested>): GenType {
  try {
    const participant: StudyParticipantDtoType = action.payload
    const result = yield call(api.createStudyParticipant, participant)
    const createdParticipation: StudyParticipantDtoType = result.data
    const studyResult = yield call(api.getStudy, createdParticipation.studyId)
    const study = studyResult.data
    const { createdDate: studyCreatedDate, ...restOfStudy } = study
    const participationWithStudy = { ...createdParticipation, ...restOfStudy, studyCreatedDate }

    yield put(surveyActions.addStudyParticipantSuceeded(participationWithStudy))
  } catch (error) {
    yield put(surveyActions.addStudyParticipantFailed(error))
  }
}

function* getStudyParticipationsForPatient(
  action: ExtractReturn<typeof surveyActions.getStudyParticipationsForPatientRequested>
): GenType {
  try {
    const patientUuid = action.payload
    const result = yield call(api.getStudyParticipationsByPatientId, patientUuid)
    const participations = result.data

    const addedByIds = participations.map(p => p.addedBy)
    yield put(userActions.loadMissingUsersRequested(addedByIds))

    const studyIds = participations.map(p => p.studyId)

    const studiesResult = yield Promise.all(studyIds.map(api.getStudy))
    const studies = studiesResult.map(r => r.data)

    const participationsWithStudy = participations.map((participation) => {
      const [study] = studies.filter(s => s.id === participation.studyId)
      const { createdDate: studyCreatedDate, ...restOfStudy } = study
      return { ...participation, ...restOfStudy, studyCreatedDate }
    })

    yield put(surveyActions.getStudyParticipationsForPatientSucceeded(patientUuid, participationsWithStudy))
  } catch (error) {
    yield put(surveyActions.getStudyParticipationsForPatientFailed(error))
  }
}

function* createStudyRequested(action: ExtractReturn<typeof surveyActions.createStudyRequested>): GenType {
  try {
    const nameKey = action.payload
    const result = yield call(api.createStudy, nameKey)
    yield put(surveyActions.createStudySucceeded(result.data))
  } catch (error) {
    yield put(surveyActions.createStudyFailed(error))
  }
}

function* getStudy(action: ExtractReturn<typeof surveyActions.getStudyRequested>) {
  try {
    const studyId = action.payload
    const result = yield call(api.getStudy, studyId)
    yield put(surveyActions.getStudySucceeded(result.data))
  } catch (error) {
    yield put(surveyActions.getStudyFailed(error))
  }
}

function* addSurveyToStudy(action: ExtractReturn<typeof surveyActions.addSurveyToStudyRequested>) {
  try {
    const studySurvey = action.payload
    const result = yield call(api.addSurveyToStudy, studySurvey)
    yield put(surveyActions.addSurveyToStudySucceeded(result.data))
  } catch (error) {
    yield put(surveyActions.addSurveyToStudyFailed(error))
  }
}

function* removeStudySurveyRequested(action: ExtractReturn<typeof surveyActions.removeStudySurveyRequested>) {
  try {
    const { studyId, surveyId } = action.payload
    yield call(api.removeStudySurvey, studyId, surveyId)
    yield put(surveyActions.removeStudySurveySucceeded(studyId, surveyId))
  } catch (error) {
    yield put(surveyActions.removeStudySurveyFailed(error))
  }
}

function* updateStudyRequested(action: ExtractReturn<typeof surveyActions.updateStudyRequested>) {
  try {
    const study = action.payload
    yield call(api.updateStudy, study)
    yield put(surveyActions.updateStudySucceeded(study))
  } catch (error) {
    yield put(surveyActions.updateStudyFailed(error))
  }
}

function* removeStudyPhysicianRequested(action: ExtractReturn<typeof surveyActions.removeStudyPhysicianRequested>) {
  try {
    const studyPhysicianDto = action.payload
    yield call(api.removeStudyPhysician, studyPhysicianDto)
    yield put(surveyActions.removeStudyPhysicianSucceeded(studyPhysicianDto))
  } catch (error) {
    yield put(surveyActions.removeStudyPhysicianFailed(error))
  }
}

function* addStudyPhysicianRequested(action: ExtractReturn<typeof surveyActions.addStudyPhysicianRequested>) {
  try {
    const { studyId, physicianId } = action.payload
    yield call(api.addStudyPhysician, studyId, physicianId)
    yield put(surveyActions.addStudyPhysicianSucceeded(studyId, physicianId))
  } catch (error) {
    yield put(surveyActions.addStudyPhysicianFailed(error))
  }
}

function* updateStudySurveyRequested(action: ExtractReturn<typeof surveyActions.updateStudySurveyRequested>) {
  try {
    const studySurvey = action.payload
    yield call(api.updateStudySurvey, studySurvey)
    yield put(surveyActions.updateStudySurveySucceeded(studySurvey))
  } catch (error) {
    yield put(surveyActions.updateStudySurveyFailed(error))
  }
}

export default function studySaga(): Array<GenType> {
  return [
    // -- APPEND TAKES HERE --
    takeEvery(surveyActionTypes.DELETE_STUDY_REQUESTED, deleteStudy),
    takeEvery(surveyActionTypes.GET_ALL_STUDIES_REQUESTED, getAllStudiesGen),
    takeEvery(surveyActionTypes.CREATE_STUDY_REQUESTED, createStudyRequested),
    takeEvery(surveyActionTypes.ADD_STUDY_PARTICIPANT_REQUESTED, addStudyParticipantGen),
    takeEvery(surveyActionTypes.GET_STUDY_PARTICIPATIONS_FOR_PATIENT_REQUESTED, getStudyParticipationsForPatient),
    takeEvery(surveyActionTypes.GET_STUDY_REQUESTED, getStudy),
    takeEvery(surveyActionTypes.ADD_SURVEY_TO_STUDY_REQUESTED, addSurveyToStudy),
    takeEvery(surveyActionTypes.REMOVE_STUDY_SURVEY_REQUESTED, removeStudySurveyRequested),
    takeEvery(surveyActionTypes.REMOVE_STUDY_PHYSICIAN_REQUESTED, removeStudyPhysicianRequested),
    takeEvery(surveyActionTypes.ADD_STUDY_PHYSICIAN_REQUESTED, addStudyPhysicianRequested),
    takeEvery(surveyActionTypes.UPDATE_STUDY_REQUESTED, updateStudyRequested),
    takeEvery(surveyActionTypes.UPDATE_STUDY_SURVEY_REQUESTED, updateStudySurveyRequested)
  ]
}
