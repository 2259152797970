// @flow
import React from 'react'

type ConditionalPropsType = {
  children: any,
  visible: boolean
}

export default function Conditional({ visible, children }: ConditionalPropsType) {
  let result: any = null
  if (visible === true && children) {
    if (children.length > 1) {
      result = <div>{children}</div>
    } else {
      result = children
    }
  }
  return result
}
