// @flow
export function registerSW() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      // $DisableFlow
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          // eslint-disable-next-line
          console.log('SW registered: ', registration)
        })
        .catch((registrationError) => {
          // eslint-disable-next-line
          console.log('SW registration failed: ', registrationError)
        })
    })
  }
}

/* add to reducers.js
online: (state: ?boolean = true, action: Object) => {
        if (action.type === 'CHANGE_CONNECTIVITY') return action.payload
        return state
      }
*/
export function registerConnectivityListener(/* reduxStore */) {
  // if ('serviceWorker' in navigator) {
  //   // dispatch redux event if there is a change in connectivity
  //   const dispatchConnectivityChange = () => {
  //     reduxStore.dispatch({
  //       type: 'CHANGE_CONNECTIVITY',
  //       payload: navigator.onLine
  //     })
  //   }
  //   window.addEventListener('online', dispatchConnectivityChange)
  //   window.addEventListener('offline', dispatchConnectivityChange)
  // }
}
