// @flow
import formValidator from '../../DynamicForm/formValidator'
import { type RecurrenceType } from '../components/CustomRecurrences'

function hasDuplicate(recs: Array<RecurrenceType>) {
  const set = new Set([])
  let has = false
  recs.forEach((r) => {
    const delay = r.delay * r.multiplier
    if (set.has(delay)) {
      has = true
    } else {
      set.add(delay)
    }
  })
  return has
}

export function isTimeMandatory(values: Object) {
  return values.startAt !== 'instant' || ['daily', 'weekly', 'customRecurrence'].includes(values.pattern)
}

export function scheduleSurveyValidator(fields: any, values: Object) {
  const scheduleProps = {
    interval: {},
    numberOfRecurrences: {}
  }

  if (['daily', 'weekly'].includes(values.pattern)) {
    scheduleProps.interval = {
      mandatory: true,
      minValue: 1
    }
    scheduleProps.numberOfRecurrences = {
      mandatory: true,
      minValue: 2
    }
  }

  const modiFiedFields = {
    ...fields,
    daysAfterAdded: {
      ...fields.daysAfterAdded,
      mandatory: values.startAt === 'daysAfterAdded'
    },
    interval: {
      ...fields.interval,
      ...scheduleProps.interval
    },
    numberOfRecurrences: {
      ...fields.numberOfRecurrences,
      ...scheduleProps.numberOfRecurrences
    },
    time: {
      ...fields.time,
      mandatory: isTimeMandatory(values)
    }
  }

  const errors = formValidator(modiFiedFields)(values)
  const { customRecurrence, pattern } = values
  if (pattern === 'customRecurrence' && customRecurrence !== undefined) {
    const lastRecurrence = customRecurrence[customRecurrence.length - 1]
    if (lastRecurrence.delay < 0) {
      errors.customRecurrence = 'toShort'
    } else if (hasDuplicate(customRecurrence)) {
      errors.customRecurrence = 'duplicate'
    }
  }

  return errors
}
