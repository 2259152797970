// @flow
import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Close from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import { Translate } from 'react-redux-i18n'
import styled from 'styled-components'

type PropsType = {
  name: string,
  children?: ?any,
  onClose: Function,
  onDelete?: Function
}

const Container = styled.div`
  display: flex;
`
const CloseContainer = styled.div`
  margin-top: -12px;
`
const DeleteContainer = styled.div`
  margin-top: -12px;
`

const Spacer = styled.div`
  flex-grow: 2;
`

const DialogHeader = (props: PropsType) => (
  <DialogTitle>
    <Container>
      {props.onDelete && (
        <DeleteContainer>
          <Tooltip title={<Translate value="actions.delete" />}>
            <IconButton id="delete" onClick={props.onDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Spacer />
        </DeleteContainer>
      )}
      <Typography id={`dialog-${props.name}-title`} variant="h6" noWrap>
        {props.children}
      </Typography>
      <Spacer />
      <CloseContainer>
        <Tooltip title={<Translate value="actions.close" />}>
          <IconButton id="close" onClick={props.onClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </CloseContainer>
    </Container>
  </DialogTitle>
)

DialogHeader.defaultProps = {
  children: null,
  onDelete: null
}

export default DialogHeader
