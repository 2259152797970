// @flow
import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import { Translate } from 'react-redux-i18n'
import path from 'ramda/src/path'

import { getDefaultCode, emergencyCodes, codePreconditions } from '../utils/emergencyCode'
import type { CodeType } from '../reducers'

type PropsType = {
  tarmedCode: ?CodeType,
  onChange: (emergencyCode: ?string) => void
}

type StateType = {
  emergencyCode: ?string
}
export default class EmergencyCodeSelection extends React.Component<PropsType, StateType> {
  state: StateType = {
    emergencyCode: getDefaultCode(new Date(Date.now()))
  }

  selectEmergencyCode = (event: any) => {
    const { value } = event.target
    const isEmpty = value === ''
    const newValue = isEmpty ? null : value
    this.setState({ emergencyCode: newValue })
    this.props.onChange(newValue)
  }

  render() {
    const tarmedCodeId = path(['tarmedCode', 'code'])(this.props)
    const showControlls = codePreconditions.includes(tarmedCodeId)

    if (!showControlls) return null

    const { emergencyCode } = this.state

    return (
      <FormControl component='fieldset'>
        <FormLabel component='legend'>
          <Translate value='billing.requested.emergencyCode' />
        </FormLabel>
        <RadioGroup
          id='emergency-code-radio-group'
          aria-label='emergencyCodes'
          name='emergencyCodes'
          value={emergencyCode || ''}
          onChange={this.selectEmergencyCode}
        >
          <FormControlLabel
            id='emergency-code-no-button'
            value=''
            control={<Radio />}
            label='Ohne Notfallcode'
          />
          <FormControlLabel
            id='emergency-code-day-button'
            value='day'
            control={<Radio />}
            label={`${emergencyCodes.day.code} - ${emergencyCodes.day.description}`}
          />
          <FormControlLabel
            id='emergency-code-night-button'
            value='night'
            control={<Radio />}
            label={`${emergencyCodes.night.code} - ${emergencyCodes.night.description}`}
          />
        </RadioGroup>
      </FormControl>
    )
  }
}
