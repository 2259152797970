import { setKeycloakImpl } from 'common-docdok/src/common/Keycloak/keycloakProvider'
import { readable } from 'common-docdok/src/utils/crypto'
import { browserHistory } from 'react-router'
import jwtDecode from 'jwt-decode'

import Keycloak from 'keycloak-js'
import { disconnect } from './sockets/messaging'
import { getEnvFromHostName, pullGetParameter } from './common/utils'

const defaultConfig = {
  'ssl-required': 'external',
  resource: 'browser',
  'public-client': true,
  clientId: 'browser',
  realm: 'docdok'
}

// Will be replace by DefinePlugin see webpack.config.js. Default is qa
declare var __DOCDOK_DEV_SERVER__: string
export const serverDevEnv = __DOCDOK_DEV_SERVER__

const localHostConifg = {
  ...defaultConfig,
  url:
    serverDevEnv !== 'stage'
      ? `https://auth-${serverDevEnv}.dev.docdok.ch/auth`
      : 'https://auth-stage.docdok.ch/auth'
}

const configMapping = {
  localhost: localHostConifg,
  '192.168.0.101': localHostConifg
}
const { hostname } = window.location
let configJson = /192\.168/.test(hostname) ? localHostConifg : configMapping[hostname]

if (!configJson) {
  configJson = {
    ...defaultConfig,
    url: `https://auth-${hostname}/auth`
  }
}

function getParam(parameter) {
  const hint = pullGetParameter(parameter)

  return hint
}

export function initKeycloak() {
  try {
    const tokenValue = getParam('token')
    if (tokenValue && tokenValue.length !== 0) {
      const parsedJwt = jwtDecode(tokenValue)
      // Still valid
      if (parsedJwt.exp * 1000 > Number(new Date().getTime())) {
        setKeycloakImpl({
          getUserId: () => parsedJwt.sub,
          logout: () => {},
          getToken: () => tokenValue,
          refreshToken: () => Promise.resolve(),
          login: (cb: (authenticated: boolean) => void) => cb(true),
          isTokenExpired: () => false
        })
        return { success: cb => cb() }
      }
    }
  } catch (e) {
    // ignore
  }

  const keycloakClient = Keycloak(configJson)


  setKeycloakImpl({
    getToken: () => keycloakClient.token,
    logout: () => {
      browserHistory.push('/private/app')
      keycloakClient.logout()
    },
    isTokenExpired: () => {
      const totalTime = keycloakClient.refreshTokenParsed.exp + keycloakClient.timeSkew
      const currentTime = new Date().getTime() / 1000
      const secondToExpire = Math.round(totalTime - currentTime)
      return secondToExpire < 0
    },
    refreshToken: () => {
      if (__TEST__) {
        return Promise.resolve(true)
      }

      return new Promise((resolve, reject) => {
        keycloakClient
          .updateToken(30)
          .success(() => {
            resolve(true)
          })
          .error((err) => {
            keycloakClient.logout()
            reject(err)
          })
      })
    },
    login: (cb: (authenticated: boolean) => void) => {
      if (__TEST__) {
        cb(true)
      } else if (keycloakClient.subject) {
        cb(true)
      } else {
        const rememberMe = readable(localStorage.getItem('rememberMe'))
        let email
        const emailParam = getParam('email')
        if (emailParam) {
          email = emailParam
        } else if (rememberMe) {
          email = rememberMe
        }

        keycloakClient
          .login({
            promt: 'none',
            loginHint: email
          })
          .success(cb)
          .error((err) => {
            Raven.captureException(err)
            cb(false)
          })
      }
    },
    getUserId: () => {
      if (__TEST__) {
        return 1234
      }
      return keycloakClient.subject
    }
  })

  keycloakClient.onAuthLogout = disconnect
  return keycloakClient.init({
    checkLoginIframe: false,
    idpHint: getParam('kc_idp_hint')
  })
}

export const callAuthorizationEndPoint = async (urlencoded) => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/x-www-form-urlencoded')
  const requestOptions = {
    method: 'POST',
    headers,
    body: urlencoded
  }
  let domain = 'ch'
  const isWebDeProd = window ? String(window.location.host).split('.').reverse()[0] === 'de' : false
  if (isWebDeProd) {
    domain = 'de'
  }
  const authURL = {
    it: `https://auth-it.dev.docdok.${domain}/auth`,
    qa: `https://auth-qa.dev.docdok.${domain}/auth`,
    stage: `https://auth-stage.docdok.${domain}/auth`,
    production: `https://auth-web.docdok.${domain}/auth`
  }
  const env = getEnvFromHostName()

  const response = await fetch(
    `${authURL[env] || authURL.production}/realms/docdok/protocol/openid-connect/token?=`,
    requestOptions
  )
  const result = await response.json()
  return result
}

let callOngoing = false
export const doManualLogin = async (
  username,
  password,
  client_id = localHostConifg.clientId,
  grant_type = 'password',
  clientSecret
) => {
  try {
    if (callOngoing) {
      return null
    }
    const urlencoded = new URLSearchParams()
    urlencoded.append('grant_type', grant_type)
    urlencoded.append('client_id', client_id)
    if (username) {
      urlencoded.append('username', username)
    }

    if (password) {
      urlencoded.append('password', atob(password))
    }
    if (clientSecret) {
      urlencoded.append('client_secret', atob(clientSecret))
    }
    callOngoing = true
    const result = await callAuthorizationEndPoint(urlencoded)
    callOngoing = false
    setKeycloakImpl({
      getToken: () => result.access_token,
      logout: () => {},
      isTokenExpired: () => { return false },
      refreshToken: async () => {
        try {
          const refreshResult = await doManualLogin(
            username,
            password,
            client_id,
            grant_type,
            clientSecret
          )
          return {
            access_token: refreshResult.access_token
          }
        } catch (error) {
          return Promise.resolve(result.access_token)
        }
      },
      login: () => {},
      getUserId: () => {}
    })
    return result
  } catch (error) {
    callOngoing = false
    return null
  }
}
