export default {
  en: {
    newAppointment: 'Appointment reminder from docdok',
    noUpcomingAppointments: 'No upcoming appointments',
    upcomingAppointments: '%{upcomingAppointmentCount} upcoming appointments',
    apptype: {
      appointment: 'Appointment',
      confirmation: 'Confirmation',
      schedule: 'Schedule'
    },
    name: 'Appointments',
    fields: {
      appointmentTime: 'Time',
      appointmentType: 'Type',
      healthSubjectUuid: 'Patient',
      physician: 'Physician',
      clinicName: 'Clinic',
      appointmentDescription: 'Description'
    }
  },
  de: {
    newAppointment: 'Neue Terminerinnerung von docdok',
    noUpcomingAppointments: 'Keine anstehenden Termine',
    upcomingAppointments: '%{upcomingAppointmentCount} anstehende Termine',
    apptype: {
      appointment: 'Termin',
      confirmation: 'Terminbestätigung',
      schedule: 'Termin vereinbaren'
    },
    name: 'Termine',
    fields: {
      appointmentTime: 'Zeit',
      appointmentType: 'Typ',
      healthSubjectUuid: 'Patient',
      physician: 'Arzt/Ärztin',
      clinicName: 'Klinik',
      appointmentDescription: 'Beschreibung'
    }
  }
}
