export default {
  en: {
    name: 'Studyreport',
    fields: {
      id: 'Id',
      name: 'Name'
    }
  },
  de: {
    name: 'Studienreport',
    fields: {
      id: 'Id',
      name: 'Name'
    }
  }
}
