// @flow

import { put, takeEvery, call } from 'redux-saga/effects'
import type { GenType, ExtractReturn } from 'common-docdok/src/types'
import { healthrelationActions } from 'common-docdok/src/domain/healthrelation/actions'
import { asyncAction } from 'common-docdok/src/utils/sagaUtils'
import { messagingActions, messagingActionTypes } from 'common-docdok/src/domain/messaging/actions'
import api from 'common-docdok/src/api'

import { stopSubmit } from 'redux-form'
import { infoPanelActions } from '../../InfoPanel/actions'
import { MessageTypes } from '../../InfoPanel/components/infoPanel'
import type { SubmitFormType } from '../../DynamicForm/actions'
import { chatActions, chatActionTypes } from '../actions'
import { dynamicFormActionTypes } from '../../DynamicForm/actions'

export function* attentionRequestGen({ payload }: SubmitFormType): GenType {
  if (payload.name === 'requestAttention') {
    const { person, patientUuid, message } = payload.data

    const uuids = person.map(pro => pro.uuid)

    const winner = yield asyncAction(healthrelationActions.requestAttentionRequested(patientUuid, uuids, message))
    if (winner.succeeded) {
      const profs = person.map(p => `${p.firstName} ${p.lastName}`).join(',')
      yield put(infoPanelActions.showMessage('messages.requestAttention.success', { profs }))
      yield put(stopSubmit(payload.name))
      yield put(chatActions.toggleRequestAttentionDialog())
    } else {
      yield put(stopSubmit(payload.name, winner.failed.payload.error))
    }
  }
}
export function* toggleMute(action: ExtractReturn<typeof messagingActions.toggleMuteSucceeded>): GenType {
  const { isMute } = action.payload

  if (isMute) {
    yield put(infoPanelActions.showMessage('messages.toggleMute.mute', undefined, MessageTypes.hint))
  } else {
    yield put(infoPanelActions.showMessage('messages.toggleMute.unMute', undefined, MessageTypes.hint))
  }
}

export function* editCaseGen({ payload }: SubmitFormType): GenType {
  if (payload.name === 'editCase') {
    // const caseOwner = getKeycloakClient().getUserId()
    const {
      data: { casedata, conversationId }
    } = payload

    const meta = { casedata }
    const winner = yield asyncAction(messagingActions.updateCaseRequested(conversationId, meta))
    if (winner.succeeded) {
      yield put(chatActions.toggleEditOrViewCaseDialog())
      yield put(infoPanelActions.showMessage('cases.newCase.edited'))
      yield put(stopSubmit(payload.name))
    } else {
      yield put(stopSubmit(payload.name, winner.failed.payload.error))
    }
  }
}

export function* invite2VideoConference(action: ExtractReturn<typeof
    chatActions.invite2VideoConferenceRequested>): GenType {
  const { conversationId } = action.payload
  try {
    const result = yield call(api.invite2VideoConference, conversationId)
    yield put(chatActions.invite2VideoConferenceSucceeded(conversationId, result.data))
  } catch (error) {
    yield put(chatActions.invite2VideoConferenceFailed(error))
  }
}

export function* joinVideoConference(action: ExtractReturn<typeof
  chatActions.joinVideoConference>): GenType {
  const { conversationId } = action.payload
  try {
    const result = yield call(api.physicianJoinVideoConference, conversationId)
    window.open(result.data)
    yield put(chatActions.joinVideoConferenceSucceeded(conversationId))
  } catch (error) {
    yield put(chatActions.invite2VideoConferenceFailed(error))
  }
}

export default function notificationSaga(): Array<GenType> {
  return [
    takeEvery(dynamicFormActionTypes.SUBMIT_FORM, attentionRequestGen),
    takeEvery(messagingActionTypes.TOGGLE_MUTE_SUCCEEDED, toggleMute),
    takeEvery(dynamicFormActionTypes.SUBMIT_FORM, editCaseGen),
    takeEvery(chatActionTypes.INVITE_AND_JOIN_VIDEOCONFERENCE_REQUESTED, invite2VideoConference),
    takeEvery(chatActionTypes.JOIN_VIDEO_CONFERENCE, joinVideoConference)
  ]
}
