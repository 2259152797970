// @flow
import React from 'react'

import FormField from '../../DynamicForm/components/fields/formField'

export function scheduleEventRenderer(def: Object, data: Object) {
  return (
    <div style={{ width: '100%', minWidth: 500 }}>
      {data.recurrence === 'schedule' && (
        <div>
          <FormField {...def} name="pattern" isRow />
        </div>
      )}
    </div>
  )
}
