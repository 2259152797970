// @flow
import { connect } from 'react-redux'
import { surveyActions } from 'common-docdok/src/domain/survey/actions'

import StudyParticipants from '../components/StudyParticipants'

import { studiesActions } from '../actions'

const mapStateToProps = (state: StoreType, ownProps: Object) => ({
  showAddStudyParticipant: state.studies.showAddStudyParticipant,
  study: state.survey.studies[ownProps.params.studyId]
})

export default connect(
  mapStateToProps,
  {
    ...surveyActions,
    ...studiesActions
  }
)(StudyParticipants)
