// @flow
import React from 'react'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import { getKeycloakClient } from 'common-docdok/src/common/Keycloak/keycloakProvider'
import dateUtils from 'common-docdok/src/utils/dateUtils'
import toString from 'common-docdok/src/utils/toString'
import styled from 'styled-components'
import DoneAllIcon from '@material-ui/icons/Done'
import Tooltip from '@material-ui/core/Tooltip'
import { I18n } from 'react-redux-i18n'

import Action from '../../Security/containers/action'
import { SHOW_MESSAGE_READ_TICK } from '../../Security/actionNames'
import UserAvatar from '../../UserAvatar'
import palette from '../../../styles/palette'
import DateRenderer from '../../DateRenderer/components/dateRenderer'
import YoutubeMessage from './youtubeMessage'
import TextMessage from './textMessage'
import ImageMessage from './ImageMessage'
import PdfMessage from './PdfMessage'
import VideoMessage from './VideoMessage'
import VideoInvitation from './VideoInvitation'
import { Bubble } from '../../Static'
import type { MessagePropsType } from '../containers/Message'
import AudioMessage from './AudioMessage'
// import Diga1InvitationMessage from './Diga1InvitationMessage'
// import Diga1InvitationResponseMessage from './Diga1InvitationResponseMessage'

const ChatBubble = styled(Bubble)`
  margin: 2px;
  max-width: 80%;
`

const ReadIcon = styled(DoneAllIcon)`
  color: ${(props) => (props.isread === 'true' ? 'green' : 'grey')};
`

export default class MessageChip extends React.Component<MessagePropsType> {
  state = {
    loading: false
  }

  renderProgress() {
    const { message } = this.props

    return message.sequenceNo || message.sequenceNo === 0 ? null : <LinearProgress mode="indeterminate" />
  }

  isMessageByMe() {
    const { message } = this.props
    return message.userRef === getKeycloakClient().getUserId()
  }

  renderAvatar() {
    const { user } = this.props
    const float = this.getFloat()
    const avatar = !this.isMessageByMe() && user && (
      <UserAvatar
        style={{ float, margin: 8 }}
        size={50}
        name={toString.person(user)}
        src={user.avatarPicture}
      />
    )
    return avatar
  }

  getFloat() {
    const isMessageByMe = this.isMessageByMe()
    const float = isMessageByMe ? 'right' : 'left'
    return float
  }

  getColor() {
    const isMessageByMe = this.isMessageByMe()
    let backgroundColor = palette.blueDark
    let color = 'white'

    if (isMessageByMe) {
      backgroundColor = palette.blueLight
      color = 'black'
    }

    return { color, backgroundColor }
  }

  renderName() {
    const { user, message, readByPrimary, messageByPatient } = this.props
    const { color } = this.getColor()
    const isMessageByMe = this.isMessageByMe()
    const displayTick = !messageByPatient
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline'
        }}
      >
        <Typography
          gutterBottom
          style={{ color, marginLeft: 10, marginRight: 10 }}
        >
          {!isMessageByMe && user ? toString.person(user) : ''}
        </Typography>
        <Typography
          gutterBottom
          style={{ color, marginLeft: 10, marginRight: 10 }}
        >
          <Action name={SHOW_MESSAGE_READ_TICK}>
            {displayTick && (
              <Tooltip title={I18n.t('messages.chat.readByPatientTooltip')}>
                <ReadIcon isread={String(readByPrimary)} />
              </Tooltip>
            )}
          </Action>
          <DateRenderer
            style={{ color }}
            value={message.postedAt}
            dateFormat="time.short"
          />
        </Typography>
      </div>
    )
  }

  render() {
    const { message } = this.props
    const postedAt = dateUtils.toDate(message.postedAt)
    postedAt.setHours(0, 0, 0, 0)
    const isMessageByMe = this.isMessageByMe()

    const { backgroundColor, color } = this.getColor()
    const flexDirection = isMessageByMe ? 'row-reverse' : 'row'
    return (
      <div style={{ display: 'flex', flexDirection, marginRight: '2px' }}>
        {this.renderAvatar()}
        <ChatBubble
          className="chat-bubble"
          style={{
            backgroundColor
          }}
        >
          {this.renderProgress()}

          {this.renderName()}

          <ImageMessage message={message} loadResourceOnMount />
          <PdfMessage message={message} />
          <YoutubeMessage message={message} />
          <VideoMessage message={message} />
          <AudioMessage message={message} directRender />
          <Typography gutterBottom component="span">
            <TextMessage
              message={message}
              color={color}
              loading={this.state.loading}
              setLoading={(v) => this.setState({ loading: v })}
            />
          </Typography>
          <VideoInvitation message={message} color={color} />
          {/* <Diga1InvitationMessage message={message} color={color} user={user} /> */}
          {/* <Diga1InvitationResponseMessage message={message} color={color} /> */}
        </ChatBubble>
      </div>
    )
  }
}
