// @flow

import { userActions } from 'common-docdok/src/domain/user/actions'

import { PersistStoreActions } from 'common-docdok/src/configuration/persistStore'
import { surveyActions } from 'common-docdok/src/domain/survey/actions'
import { securityActions } from 'common-docdok/src/common/Security/actions'

// import { locationChange } from './location'
import { healthrelationActions } from 'common-docdok/src/domain/healthrelation/actions'
import { isPathAccessable } from '../common/Security/containers/path'

type ReduxStoreType = {
  dispatch: (action: any) => void,
  getState: () => StoreType,
  subscribe: (func: Function) => void
}

let exectued = false

const executeActions = (store: ReduxStoreType) => {
  const { dispatch } = store
  dispatch(securityActions.getRightsRequested())
  store.subscribe(() => {
    if (!exectued && store.getState().security.rights) {
      exectued = true
      dispatch(healthrelationActions.loadAllPatientsRequested())
      dispatch(PersistStoreActions.startPersistStore())
      dispatch(healthrelationActions.loadAllPatientsRequested())
      if (isPathAccessable('/studies')) {
        // we have to know if the user has studies to render the study entry in the menu
        dispatch(surveyActions.getAllStudiesRequested())
      }
      dispatch(userActions.profileFetchRequested())
      if (isPathAccessable('/appointment')) {
        dispatch(healthrelationActions.upcomingAppointmentsRequested())
      }
    }
  })
}

export default executeActions
