// @flow
import React from 'react'
import { connect } from 'react-redux'
import compose from 'ramda/src/compose'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { Translate } from 'react-redux-i18n'
import { keycloakActions } from 'common-docdok/src/common/Keycloak/actions'
import { DialogHeader } from '../../Mui'
import { ambulanceQueueItemsActions } from '../actions'

const ContentDiv = styled.div`
  margin: 20px;
`
const JoinWaitingRoomDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
`
const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1
`


type PropsType = {
  showAmbulanceItemsDialog: boolean,
  getAmbulanceQueueItemsRequested: typeof ambulanceQueueItemsActions.getAmbulanceQueueItemsRequested,
  getAmbulanceWaitingPositionRequested: typeof ambulanceQueueItemsActions.getAmbulanceWaitingPositionRequested,
  closeAdd2WaitingRoomDialog: typeof ambulanceQueueItemsActions.closeAdd2WaitingRoomDialog,
  setMySelfToWaitingRequested: typeof ambulanceQueueItemsActions.setMySelfToWaitingRequested,
  logout: typeof keycloakActions.logout,
  showDialog: boolean
}

class Add2WaitingRoomQueueDialog extends React.Component<PropsType> {
  static defaultProps = {
    useIconButton: false,
    casedata: ['']
  }

  componentDidMount() {
    const { getAmbulanceWaitingPositionRequested } = this.props
    getAmbulanceWaitingPositionRequested()
  }

  add2waitingRoomAndClose = () => {
    const { closeAdd2WaitingRoomDialog, setMySelfToWaitingRequested } = this.props
    setMySelfToWaitingRequested()
    closeAdd2WaitingRoomDialog()
  }


  render() {
    const { closeAdd2WaitingRoomDialog, showDialog, logout } = this.props

    return (
      <Dialog
        id='ambulanceQueueItems'
        scroll='paper'
        maxWidth='md'
        fullWidth
        onEscapeKeyDown={closeAdd2WaitingRoomDialog}
        open={showDialog}
      >
        <DialogHeader name='ambulanceQueueItems' onClose={logout}>
          <Translate value='ambulance.add2WaitingRoom.title' />
        </DialogHeader>
        <ContentDiv>
          <JoinWaitingRoomDiv>
            <div>
              <Typography variant='body1' style={{ marginRight: '10px' }}>
                <Translate value='ambulance.add2WaitingRoom.infoTextJoin' />
              </Typography>
              <div style={{ height:'10px' }} />
              <Typography variant='body1' style={{ marginRight: '10px' }}>
                <Translate value='ambulance.add2WaitingRoom.infoTextLater' />
              </Typography>
            </div>
            <div style={{ height: '50px' }}>
              <Divider />
            </div>
            <ButtonDiv>
              <Button
                variant='contained'
                color='primary'
                onClick={this.add2waitingRoomAndClose}
              >
                <Translate value='ambulance.add2WaitingRoom.joinWaitingRoom' />
              </Button>
              <div style={{ width: '50px' }}>
                <Divider orientation='vertical' />
              </div>
              <Button
                variant='contained'
                onClick={logout}
              >
                <Translate value='ambulance.add2WaitingRoom.doSomethingElse' />
              </Button>
            </ButtonDiv>
          </JoinWaitingRoomDiv>
        </ContentDiv>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ ambulancequeueitems: { showAddToWaitingRoom,
  showAddToWaitingRoomDialog, waitingPosition } }: StoreType) => ({
  showDialog: showAddToWaitingRoomDialog && showAddToWaitingRoom && waitingPosition === -1
})

export default compose(
  connect(
    mapStateToProps,
    {
      getAmbulanceWaitingPositionRequested: ambulanceQueueItemsActions.getAmbulanceWaitingPositionRequested,
      getAmbulanceQueueItemsRequested: ambulanceQueueItemsActions.getAmbulanceQueueItemsRequested,
      closeAdd2WaitingRoomDialog: ambulanceQueueItemsActions.closeAdd2WaitingRoomDialog,
      setMySelfToWaitingRequested: ambulanceQueueItemsActions.setMySelfToWaitingRequested,
      logout: keycloakActions.logout
    }
  )
)(Add2WaitingRoomQueueDialog)
