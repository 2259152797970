// @flow
export const keycloakActionTypes = {
  LOGOUT: 'LOGOUT'
}

export type KeycloakActionType = $Keys<typeof keycloakActionTypes>

export const keycloakActions = {
  logout() {
    return {
      type: keycloakActionTypes.LOGOUT
    }
  }
}
