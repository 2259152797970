// @flow
import type { MessagingStateType } from './messaging'
import { messagingActions } from '../actions'
import type { ExtractReturn } from '../../../types'

export function addFileRequested(
  state: MessagingStateType,
  action: ExtractReturn<typeof messagingActions.addFileRequested>
): MessagingStateType {
  const {
    payload: { conversationId, file }
  } = action
  const currentFiles = state.attachments[conversationId]
  let files = [file]
  if (currentFiles) {
    files = [...currentFiles, file]
  }
  return {
    ...state,
    attachments: {
      ...state.attachments,
      [conversationId]: files
    }
  }
}

export function addFileSucceeded(
  state: MessagingStateType,
  action: ExtractReturn<typeof messagingActions.addFileRequested>
): MessagingStateType {
  const {
    payload: { conversationId, file }
  } = action
  const currentFiles = state.attachments[conversationId].filter(f => f.id !== file.id)
  const fileToUpdate = state.attachments[conversationId].filter(f => f.id === file.id)[0]
  if (fileToUpdate) {
    const files = [...currentFiles, file].sort((a, b) => a.order - b.order)

    return {
      ...state,
      attachments: {
        ...state.attachments,
        [conversationId]: files
      }
    }
  }
  return state
}

export function removeFile(
  state: MessagingStateType,
  action: ExtractReturn<typeof messagingActions.removeFile>
): MessagingStateType {
  const { conversationId, fileId } = action.payload
  const files = state.attachments[conversationId]
    .filter(f => f.id !== fileId)
    .sort((a, b) => a.order - b.order)
  return {
    ...state,
    attachments: {
      ...state.attachments,
      [conversationId]: files
    }
  }
}

export function resetAttachments(
  state: MessagingStateType,
  action: ExtractReturn<typeof messagingActions.resetAttachments>
): MessagingStateType {
  const conversationId = action.payload
  return {
    ...state,
    attachments: {
      ...state.attachments,
      [conversationId]: undefined
    }
  }
}

export function setFileDescription(
  state: MessagingStateType,
  action: ExtractReturn<typeof messagingActions.setFileDescription>
): MessagingStateType {
  const { conversationId, fileId, description } = action.payload
  const file = state.attachments[conversationId].filter(f => f.id === fileId)[0]
  const currentFiles = state.attachments[conversationId].filter(f => f.id !== fileId)

  const files = [...currentFiles, { ...file, description }].sort((a, b) => a.order - b.order)
  return {
    ...state,
    attachments: {
      ...state.attachments,
      [conversationId]: files
    }
  }
}
// TODO: change state type to MessagingStateType after migration
export function markAsCached(
  state: MessagingStateType,
  action: ExtractReturn<typeof messagingActions.markAsCached>
) {
  const url = action.payload
  let newState = state
  if (url.startsWith('http')) {
    newState = {
      ...state,
      cachedAttachments: {
        ...state.cachedAttachments,
        [url]: Date.now()
      }
    }
  }
  return newState
}

export function createContentMessagePreview(
  state: MessagingStateType,
  action: ExtractReturn<typeof messagingActions.createContentMessagePreviewSucceeded>
) {
  const { definitionId, message } = action.payload

  return {
    ...state,
    contentPreview: {
      ...state.contentPreview,
      [definitionId]: message
    }
  }
}

export function getContentDefinitionTags(
  state: MessagingStateType,
  action: ExtractReturn<typeof messagingActions.getContentDefinitionTagsSucceeded>
) {
  return {
    ...state,
    contentDefinitionTags: action.payload
  }
}
