// @flow

export const fieldNames = {
  clinics: 'clinics',
  contentDefinitionId: 'contentDefinitionId',
  definitionKey: 'definitionKey',
  language: 'language',
  license: 'license',
  mediaResourceUuid: 'mediaResourceUuid',
  mimeType: 'mimeType',
  originalSize: 'originalSize',
  studies: 'studies',
  tags: 'tags',
  text: 'text',
  title: 'title'
}

export const fields = {
  clinics: { type: 'Array<number>' },
  contentDefinitionId: {
    type: 'number',
    format: 'int64'
  },
  definitionKey: { type: 'string' },
  language: { type: 'string' },
  license: { type: 'string' },
  mediaResourceUuid: { type: 'string' },
  mimeType: { type: 'string' },
  originalSize: {
    type: 'number',
    format: 'int64'
  },
  studies: { type: 'Array<number>' },
  tags: { type: 'Array<string>' },
  text: { type: 'string' },
  title: { type: 'string' }
}

export type ContentDefinitionVmType = {
  clinics: Array<number>,
  contentDefinitionId: number,
  definitionKey: string,
  language: string,
  license: string,
  mediaResourceUuid: string,
  mimeType: string,
  originalSize: number,
  studies: Array<number>,
  tags: Array<string>,
  text: string,
  title: string
}
