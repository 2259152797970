// @flow
import { emailRgx, mobileRgx } from '../../../utils/rgx'

export const roles = {
  admin: 'ROLE_ADMIN',
  physician: 'ROLE_PHYSICIAN',
  studyCoordinator: 'ROLE_STUDY_COORDINATOR',
  mpa: 'ROLE_MPA',
  patient: 'ROLE_PATIENT',
  supportAdmin: 'ROLE_SUPPORT_ADMIN'
}

export const fieldNames = {
  activated: 'activated',
  authorities: 'authorities',
  avatarPicture: 'avatarPicture',
  email: 'email',
  firstName: 'firstName',
  langKey: 'langKey',
  lastName: 'lastName',
  mobileNumber: 'mobileNumber',
  salutation: 'salutation',
  uid: 'uid',
  acceptedTermsVersion: 'acceptedTermsVersion',
  gender: 'gender'
}

export const fields = {
  activated: { type: 'boolean' },
  authorities: { type: 'Array<string>' },
  avatarPicture: { type: 'string' },
  email: {
    type: 'string',
    mandatory: true,
    minLength: 5,
    maxLength: 100,
    pattern: emailRgx,
    patternNice: 'me@domain.com'
  },
  firstName: { type: 'string' },
  langKey: {
    type: 'select',
    enum: ['en', 'fr', 'de', 'it']
  },
  lastName: { type: 'string' },
  mobileNumber: {
    type: 'string',
    minLength: 5,
    maxLength: 20,
    pattern: mobileRgx,
    patternNice: '+41795553322',
    normalize: (nbr: string) => nbr.replace(/\s/g, '')
  },
  salutation: {
    type: 'autoComplete',
    options: [
      { value: 'Mr', key: 'Mr' },
      { value: 'Ms', key: 'Ms' },
      { value: 'Mrs', key: 'Mrs' },
      { value: 'Prof', key: 'Prof' },
      { value: 'Dr', key: 'Dr' },
      { value: 'PractMed', key: 'PractMed' }
    ],
    maxLength: 100
  },
  uid: { type: 'string' },
  acceptedTermsVersion: { type: 'string' },
  gender: {
    type: 'string',
    mandatory: true,
    enum: ['MALE', 'FEMALE', 'DIVERS']
  },
  origin: { type: 'string' }
}

export type UserDtoType = {
  activated: boolean,
  authorities: Array<string>,
  avatarPicture: string,
  email: string,
  firstName: string,
  langKey: string,
  lastName: string,
  mobileNumber: string,
  salutation: string,
  uid: string,
  acceptedTermsVersion: string,
  origin: string,
  userRef?: string,
  uuid?: string
}
