// @flow

import type { UserDtoType } from '../types/userDto'

import { userActionTypes, userActions } from '../actions'
import type { ExtractReturn } from '../../../types'
import type { UserActionType } from '../actions'
import { healthrelationActionTypes } from '../../healthrelation/actions'

export type UserCacheStateType = {
  users: {
    [key: string]: UserDtoType
  },
  idsToLoad: Array<string>
}

// Helpers
export function addUser(state: UserCacheStateType, user: UserDtoType): UserCacheStateType {
  let newState = { ...state }
  newState = {
    ...newState,
    users: {
      ...newState.users,
      [user.uid || user.userRef]: {
        ...user,
        uid: user.uid || user.userRef
      }
    }
  }
  return newState
}

// Default State
const initialState = {
  users: {},
  idsToLoad: []
}

// Action Handlers

export function addUsers(
  state: UserCacheStateType,
  action: ExtractReturn<typeof userActions.loadUsersSucceeded>
): UserCacheStateType {
  let newState = { ...state }
  const users = action.payload

  users.forEach((user: Object) => {
    newState = {
      ...newState,
      ...addUser(newState, user)
    }
  })

  return newState
}

export function addIdsToLoad(
  state: UserCacheStateType,
  action: ExtractReturn<typeof userActions.loadMissingUsersRequested>
): UserCacheStateType {
  const ids = action.payload
  const newState = {
    ...state,
    idsToLoad: [...state.idsToLoad, ...ids]
  }

  return newState
}

type FetchProfileSucceededActionType = ExtractReturn<typeof userActions.profileFetchSucceeded>
export function fetchProfileSucceeded(
  state: UserCacheStateType,
  action: FetchProfileSucceededActionType
) {
  return addUser(state, action.payload)
}

type HandlerType = { [type: UserActionType]: any } // Add more if you handle actions from other domains
const ACTION_HANDLERS: HandlerType = {
  [userActionTypes.PROFILE_FETCH_SUCCEEDED]: fetchProfileSucceeded,
  [userActionTypes.LOAD_USERS_SUCCEEDED]: addUsers,
  [healthrelationActionTypes.LOAD_ALL_PATIENTS_SUCCEEDED]: addUsers,
  [userActionTypes.LOAD_MISSING_USERS_SUCCEEDED]: addUsers,
  [userActionTypes.LOAD_MISSING_USERS_REQUESTED]: addIdsToLoad
}

type ActionType = { type: UserActionType } // Add more ActionType if you handle actions from other domains
export default function userCacheReducer(
  state: UserCacheStateType = initialState,
  action: ActionType
): UserCacheStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
