// @flow
import React from 'react'
import TextField from '@material-ui/core/TextField'
import { Translate } from 'react-redux-i18n'

import type { FieldComponentPropsType } from './formField'

export default function renderTextField(props: FieldComponentPropsType) {
  const {
    input,
    meta: { touched, error, form },
    propertyDefinition: { patternNice, fullWidth, multiline, rowsMax, variant, explicitType },
    style,
    disabled
  } = props
  const { name } = input

  return (
    <TextField
      margin='normal'
      style={style}
      id={`${form}-${name}`}
      inputProps={{
        'data-test': `${form}-${name}`
      }}
      fullWidth={fullWidth}
      helperText={(touched && (error || patternNice)) || ''}
      error={Boolean(touched && error)}
      disabled={disabled}
      label={<Translate value={`forms.${form}.${name}`} />}
      multiline={multiline || false}
      rowsMax={rowsMax || 1}
      rows={multiline ? 5 : undefined}
      variant={variant}
      {...input}
      type={explicitType}
    />
  )
}
