// @flow
import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'

type PropsType = {
  isLoading: boolean
}

const GlobalLoadingBar = ({ isLoading }: PropsType) => (
  <div style={{ height: 5, marginTop: -5 }}>
    {isLoading && <LinearProgress id='loading-bar' mode='indeterminate' />}
  </div>
)

export default GlobalLoadingBar
