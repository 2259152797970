// @flow
import { connect } from 'react-redux'

import checkedLink from '../components/checkedLink'

import { checkRoute } from './path'

type PathPropsType = {
  to: string
}

const mapStateToProps = (state: StoreType, { to }: PathPropsType) => {
  const visible = checkRoute(state, to)
  return { visible }
}

export default connect(mapStateToProps)(checkedLink)
