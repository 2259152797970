// @flow
import { takeEvery, select } from 'redux-saga/effects'
import type { ExtractReturn, GenType } from 'common-docdok/src/types'

import { surveyActions, surveyActionTypes } from 'common-docdok/src/domain/survey/actions'

import { storeContainer } from '../../../../../store/createStore'


export function* addDeviceGen({
  payload: { url, patientUuid }
}: ExtractReturn<typeof surveyActions.addIntegrationSucceeded>): GenType {
  const win = yield select((state: StoreType) => state.gui.window.newTab)
  win.location = url
  const timer = setInterval(() => {
    if (win.closed) {
      clearInterval(timer)
      storeContainer.store.dispatch(surveyActions.getDeviceStateRequested(patientUuid))
    }
  }, 1000)

  win.focus()
}

export default function deviceSaga(): Array<GenType> {
  return [takeEvery(surveyActionTypes.ADD_INTEGRATION_SUCCEEDED, addDeviceGen)]
}
