export default {
  en: {
    name: 'Professional',
    fields: {
      class: 'Type',
      classOpts: {
        PHYSICIAN: 'Physician',
        MPA: 'Assistant'
      },

      clinicId: 'Clinic',
      email: 'Email',
      firstName: 'Firstname',
      gender: 'Gender',
      genderOpts: {
        MALE: 'Male',
        FEMALE: 'Female',
        DIVERS: 'Divers'
      },
      langKey: 'Language',
      langKeyOpts: {
        en: 'English',
        de: 'German',
        fr: 'French',
        it: 'Italian'
      },
      lastName: 'Lastname',
      mobileNumber: 'Mobile',
      salutation: 'Salutation',
      salutationOpts: {
        Mr: 'Mr',
        Ms: 'Ms',
        Mrs: 'Mrs.',
        Prof: 'Prof',
        Dr: 'Dr',
        PractMed: 'Pract Med'
      },
      allowHycare: 'Allow HyCare integration'
    }
  },
  de: {
    name: 'Fachkraft',
    fields: {
      class: 'Typ',
      classOpts: {
        PHYSICIAN: 'Doktor',
        MPA: 'Assistent/in'
      },
      clinicId: 'Klinik',
      email: 'Email',
      firstName: 'Vorname',
      gender: 'Geschlecht',
      genderOpts: {
        MALE: 'männlich',
        FEMALE: 'weiblich',
        DIVERS: 'divers'
      },
      langKey: 'Sprache',
      langKeyOpts: {
        en: 'Englisch',
        de: 'Deutsch',
        fr: 'Französisch',
        it: 'Italienisch'
      },
      lastName: 'Nachname',
      mobileNumber: 'Mobiltelefon',
      salutation: 'Anrede',
      salutationOpts: {
        Mr: 'Herr',
        Ms: 'Frau',
        Prof: 'Prof. Dr. med',
        Dr: 'Dr. med',
        PractMed: 'Prakt. med'
      },
      allowHycare: 'ntegration von HyCare ermöglichen'
    }
  }
}
