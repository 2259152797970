import userTranslations from 'common-docdok/src/domain/user/translations/userTranslations'
import surveyTranslations from 'common-docdok/src/domain/survey/translations/surveyTranslations'
import surveyParticipationTranslations from 'common-docdok/src/domain/survey/translations/surveyParticipationTranslations'
import patientTranslations from 'common-docdok/src/domain/healthrelation/translations/patientTranslations'
import studyTranslations from 'common-docdok/src/domain/survey/translations/studyTranslations'
import studyParticipantTranslations from 'common-docdok/src/domain/survey/translations/studyParticipantTranslations'
import professionalTranslations from 'common-docdok/src/domain/healthrelation/translations/professionalTranslations'
import studyReportDefinitionTranslations from 'common-docdok/src/domain/survey/translations/studyReportDefinitionTranslations'
import clinicTranslations from 'common-docdok/src/domain/healthrelation/translations/clinicTranslations'
import keycloakEventTranslations from 'common-docdok/src/domain/user/translations/keycloakEventTranslations'
import docdokEventTranslation from 'common-docdok/src/domain/user/translations/docdokEventTranslations'
import serverErrors from 'common-docdok/src/common/ServerErrors'
import deviceDefinitionTranslation from 'common-docdok/src/domain/survey/translations/deviceDefinitionTranslation'
import contentDefinitionTranslation from 'common-docdok/src/domain/messaging/translations/contentDefinitionTranslation'
import surveyThemeTranslations from 'common-docdok/src/domain/survey/translations/surveyThemeTranslations'
import surveyGroupTranslations from 'common-docdok/src/domain/survey/translations/surveyGroupTranslations'

import menu from './menu'
import home from './home'
import header from './header'
import surveys from './surveys'
import users from './users'
import profile from './profile'
import messages from './messages'
import patients from './patients'
import patient from './patient'
import professionals from './professionals'
import fitbit from './fitbit'
import table from './table'
import billing from './billing'
import intro from './intro'
import studies from './studies'
import surveyAnalytic from './surveyAnalytic'
import devices from './devices'
import clinics from './clinics'
import catalog from './catalog'
import cases from './cases'
import appointment from './appointment'
import auditEventTypes from './auditEventTypes'
import ambulance from './ambulance'
import casestream from './casestream'
import organization from './organization'
import primaryConversation from './primaryConversation'
import patientActivities from './patientActivities'
import patientMeasurements from './patientMeasurements'

export default {
  en: {
    ...serverErrors.en,
    intro: intro.en,
    date: {
      long: 'MMMM Do, YYYY',
      short: 'DD.MM.YYYY',
      month: 'MM',
      day: 'DD',
      stamp: 'YYYY_MM_DD',
      withTime: 'HH:mm - DD.MM.YYYY'
    },
    time: {
      hour: 'HH',
      short: 'HH:mm',
      long: 'HH:mm:ss'
    },
    infos: {
      errorOccurred: 'An error occurred - contact the DocDok-Team',
      connectionLost: 'Connection lost, trying to reconnect!',
      reconnected: 'Reconnected to the Server',
      areYouSure: 'Are you sure?',
      sessionTimeout: 'Your session has expired. Please login again.'
    },
    actions: {
      actions: 'Actions',
      update: 'Update',
      search: 'Search...',
      save: 'Save',
      ok: 'Ok',
      cancel: 'Cancel',
      edit: 'Edit',
      close: 'Close',
      remove: 'Remove',
      send: 'Send',
      export: 'Export',
      browse: 'Browse',
      create: 'Create',
      add: 'Add',
      next: 'Next',
      previous: 'Previous',
      done: 'Done',
      skip: 'Skip',
      accept: 'Accept',
      decline: 'Decline',
      confirm: 'Confirm',
      delete: 'Delete'
    },
    charts: {
      count: 'Count: %{count}',
      percentage: '(%{percentage}%)'
    },
    entitySearcher: { noResult: 'No Results' },
    table: table.en,
    clinics: clinics.en,
    home: home.en,
    menu: menu.en,
    header: header.en,
    profile: profile.en,
    users: users.en,
    messages: messages.en,
    surveys: surveys.en,
    patients: patients.en,
    patient: patient.en,
    professionals: professionals.en,
    billing: billing.en,
    studies: studies.en,
    devices: devices.en,
    catalog: catalog.en,
    cases: cases.en,
    appointment: appointment.en,
    ambulance: ambulance.en,
    events: {
      tooltip: 'Show Keycloak/Docdok events'
    },
    domain: {
      entity: {
        user: userTranslations.en,
        survey: surveyTranslations.en,
        surveyThemes: surveyThemeTranslations.en,
        surveyGroups: surveyGroupTranslations.en,
        surveyParticipation: surveyParticipationTranslations.en,
        patient: {
          ...patientTranslations.en,
          fields: {
            ...patientTranslations.en.fields,
            activated: userTranslations.en.fields.activated
          }
        },
        professional: professionalTranslations.en,
        fitbit: fitbit.en,
        studyReportDefinition: studyReportDefinitionTranslations.en,
        contentDefinition: contentDefinitionTranslation.en,
        clinic: clinicTranslations.en,
        keycloakEvent: keycloakEventTranslations.en,
        study: studyTranslations.en,
        surveyAnalytic: surveyAnalytic.en,
        deviceDefinition: deviceDefinitionTranslation.en,
        studyParticipant: studyParticipantTranslations.en,
        studyParticipantPatient: {
          ...studyParticipantTranslations.en,
          ...patientTranslations.en,
          fields: {
            ...patientTranslations.en.fields,
            ...studyParticipantTranslations.en.fields
          }
        },
        appointment: {
          fields: {
            ...appointment.en.fields
          }
        },
        docdokEvent: docdokEventTranslation.en,
        ambulanceQueueItems: ambulance.en,
        casestream: {
          fields: casestream.en.fields
        },
        organizations: {
          fields: organization.en.fields
        },
        primaryConversation: {
          fields: primaryConversation.en.fields
        },
        patientActivities: {
          fields: patientActivities.en.fields
        },
        patientMeasurements: {
          fields: patientMeasurements.de.fields
        }
      }
    },

    forms: {
      createCatalog: {
        title: 'Title',
        text: 'Text (Description)',
        languages: 'Language',
        fileType: 'File type (MIME-type)',
        tags: 'Tags',
        license: 'License',
        studyId: 'Studies (limits access to a study or studies)',
        clinicId: 'Clinics (limit access to file to clinic[s])',
        url: 'URL',
        file: 'file'
      },
      save: 'Save',
      reset: 'Reset',
      chooseFile: 'Choose File',
      changeFile: 'Change File',
      changeFileInfo: 'This change will affect exsiting study participants',
      noFileChoosen: 'No file selected.',
      cancel: 'Cancel',
      ok: 'Ok',
      errors: {
        mandatory: 'Please provide a value.',
        minLength: 'The value has to be at least %{minLength} characters.',
        maxLength:
          'The value has reached the maximum of %{maxLength} characters',
        pattern: 'The value does not match the Pattern %{pattern}.',
        notInFuture: 'The date must not be in the future.',
        noValidDate: 'The date is not valid',
        noSelection: 'Please select',
        maxValue:
          'the value (%{value}) must be smaller or equal than %{maxValue}',
        minValue:
          'the value (%{value}) must be bigger or equal than %{minValue}',
        endTime: 'End time should be greater than start time',
        includingSpace: 'including spaces'
      },
      sendContent: {
        conversations: 'Conversation'
      },
      profile: userTranslations.en.fields,
      createUser: userTranslations.en.fields,
      updateUser: userTranslations.en.fields,
      createConversation: {
        title: 'Title',
        users: 'Attendees'
      },
      editConversation: {
        title: 'Title',
        users: 'Attendees'
      },
      createClinic: clinicTranslations.en.fields,
      createPhysician: professionalTranslations.en.fields,
      createMpa: professionalTranslations.en.fields,
      createPatient: {
        ...patientTranslations.en.fields,
        isDependent: 'Child',
        createNew: 'Create New Parent',
        pepIntegration: 'Add to Hybrid Care Program',
        disease: 'Select disease',
        diseaseOpts: {
          Diabetes: 'Diabetes',
          Hypertension: 'Hypertension',
          Obesity: 'Obesity'
        },
        proxy: 'Sibling',
        proxySalutation: patientTranslations.en.fields.salutation,
        proxySalutationOpts: patientTranslations.en.fields.salutationOpts,
        proxyFirstName: patientTranslations.en.fields.firstName,
        proxyLastName: patientTranslations.en.fields.lastName,
        proxyEmail: patientTranslations.en.fields.email,
        proxyMobileNumber: patientTranslations.en.fields.mobileNumber,
        proxyGender: patientTranslations.en.fields.gender,
        proxyGenderOpts: patientTranslations.en.fields.genderOpts
      },
      targetForm: {
        setTargetValue: 'Set Target Values',
        setTargetValueNotification:
          'You will receive a notification when the patient’s ' +
          'measured values are outside of the target value range',
        bloodPressureTargetRange: 'BLOOD PRESSURE TARGET RANGE',
        lowestSystolicBloodPressure: 'Lowest Systolic Blood Pressure',
        highestSystolicBloodPressure: 'Highest Systolic Blood Pressure',
        lowestDiastolicBloodPressure: 'Lowest Diastolic Blood Pressure',
        highestDiastolicBloodPressure: 'Highest Diastolic Blood Pressure',
        HbA1cTargetRange: 'HbA1c TARGET RANGE',
        lowestHbA1c: 'Lowest HbA1c',
        highestHbA1c: 'Highest HbA1c',
        bmiTarget: 'BMI TARGET',
        lowestBMI: 'Lowest BMI',
        highestBMI: 'Highest BMI',
        stepTargetRange: 'STEPS TARGET RANGE',
        lowestDailySteps: 'Lowest Daily Steps',
        highestDailySteps: 'Highest Daily Steps',
        save: 'SAVE'
      },
      editEvent: {
        name: 'Name',
        startdate: 'Start Date',
        startTime: 'Start Time',
        endDate: 'End Date',
        endTime: 'End Time',
        location: 'Location',
        recurrence: 'Recurrence',
        none: 'None',
        schedule: 'Schedule',
        pattern: 'Pattern',
        monthly: 'Monthly',
        daily: 'Daily',
        weekly: 'Weekly',
        file: 'File',
        notes: 'Notes',
        type: 'Type',
        status: 'Status',
        event: 'Events',
        typeOpts: {
          activity: 'To Do',
          nutrition: 'Nutrition',
          exercise: 'Exercise',
          coaching: 'Coaching'
        },
        statusOpts: {
          SCHEDULED: 'Scheduled',
          DONE: 'Done'
        },
        recurrenceOpts: {
          none: 'None',
          schedule: 'Schedule'
        },
        patternOpts: {
          monthly: 'Monthly',
          daily: 'Daily',
          weekly: 'Weekly'
        }
      },
      patientData: patientTranslations.en.fields,
      physicianData: {
        primaryPhysicianUuid: 'Name'
      },
      contactData: patientTranslations.en.fields,
      userData: userTranslations.en.fields,
      patientStudyParticipations: {
        ...studyTranslations.en.fields,
        ...studyParticipantTranslations.en.fields
      },
      addToStudy: {
        ...studyTranslations.en.fields,
        ...studyParticipantTranslations.en.fields
      },
      requestAttention: {
        message: 'Message',
        person: 'Person'
      },
      studyReport: {
        studyId: 'Study',
        studyReportDefinitionId: 'Study Report'
      },
      sendSurvey: {
        patients: 'Patients',
        doSchedule: 'Schedule the selected surveys',
        doScheduleDescription: 'The following surveys could be scheduled',
        schedulingDescription:
          'The survey "%{name}" will be sent each %{dayIncrement}. day at %{time} for %{endOccurence} days.',
        schedulingDescriptionDaily:
          'The survey "%{name}" will be sent each day at %{time} for %{endOccurence} days.'
      },
      sendSurveyFromPatient: {
        surveys: 'Umfrage',
        doSchedule: 'Schedule the selected surveys'
      },
      scheduleStudySurvey: studies.en.scheduleStudySurvey,
      uploadSurvey: surveys.en.uploadSurvey,
      updateSurvey: surveys.en.uploadSurvey,
      addStudyParticipant: {
        ...studyTranslations.de.fields,
        ...studyParticipantTranslations.de.fields,
        patients: 'Participants'
      },
      dashboard: {
        date: 'Date'
      },
      createCase: cases.en.newCaseForm,
      editCase: cases.de.editCaseForm,
      addParticipationCriterias: {
        inclusions: 'Inclusion Criteria',
        exclusions: 'Exclusion Criteria'
      },
      createSurveyGroup: {
        groupName: 'Group Name',
        surveyDefinitionIds: 'Surveys'
      },
      updateSurveyGroup: {
        groupName: 'Group Name',
        surveyDefinitionIds: 'Surveys'
      }
    },
    docdokEvents: auditEventTypes.en,
    eventTypes: {
      total: 'Total',
      event: 'Events',
      activity: 'To Do',
      activities: 'Activities',
      nutrition: 'Nutrition',
      exercise: 'Exercise',
      coaching: 'Coaching',
      daily: 'Daily',
      weekly: 'Weekly',
      threeMonths: '3 Months',
      sixMonths: '6 Months',
      year: 'Year',
      weight: 'Weight',
      whr: 'WHR',
      totalSession: '%{count} Coaching sessions completed so far.',
      totalSessionSingle: '%{count} Coaching session completed so far.'
    },
    createEvent: 'CREATE EVENT',
    createEventFor: 'Create an event for %{date} at %{time}',
    createFromNew: 'New event',
    createFromExistingOne: 'Create from existing event',
    total: 'Total',
    event: 'Events',
    activity: 'To Do',
    nutrition: 'Nutrition',
    exercise: 'Exercise',
    coaching: 'Coaching',
    daily: 'Daily',
    weekly: 'Weekly',
    threeMonths: '3 Months',
    sixMonths: '6 Months',
    year: 'Year',
    weight: 'Weight',
    whr: 'WHR',
    totalSession: '%{count} Coaching sessions completed so far.',
    totalSessionSingle: '%{count} Coaching session completed so far.',
    allDay: 'All Day',
    prev: 'Prev',
    next: 'Next',
    prevYear: 'Prev Year',
    nextYear: 'Next Year',
    today: 'Today',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    list: 'List',
    confirmation: 'Do you want to update the series of this event?',
    confirmationTitle: 'Confirmation',
    selectRecc: 'Please select Recurrence',
    yes: 'Yes',
    no: 'No',
    endTimeError: 'End time should be greater than start time',
    customFormErrors: {
      salutation: 'Please enter a salutation',
      firstName: 'Please enter a first name',
      lastName: 'Please enter a last name',
      gender: 'Please select',
      birthdate: 'Please enter a valid date of birth',
      mobileNumber: 'Please enter a cell phone number with at least 5 numbers',
      email: 'Please enter a valid e-mail address',
      langKey: 'Please select',
      role: 'Please select',
      clinic: 'Please select',
      birthdateFormat: 'The date is not valid',
      mobileNumberFormat:
        'The value does not correspond to the required format +41795553322 (oder +49...+43... etc.)',
      emailFormat:
        'The value does not correspond to the required format me@domain.com',
      proxySalutation: 'Please enter a salutation',
      proxyFirstName: 'Please enter a salutation',
      proxyLastName: 'Please enter a last name',
      proxyGender: 'Please select',
      proxyMobileNumber:
        'Please enter a cell phone number with at least 5 numbers',
      proxyEmail: 'Please enter a valid e-mail address',
      proxyMobileNumberFormat:
        'Please enter a cell phone number with at least 5 numbers',
      proxyEmailFormat: 'Please enter a valid e-mail address',
      lowestSystolicBloodPressureFormat:
        'Please use only numbers (0-9) and periods (.)',
      highestSystolicBloodPressureFormat:
        'Please use only numbers (0-9) and periods (.)',
      lowestDiastolicBloodPressureFormat:
        'Please use only numbers (0-9) and periods (.)',
      highestDiastolicBloodPressureFormat:
        'Please use only numbers (0-9) and periods (.)',
      lowestHbA1cFormat: 'Please use only numbers (0-9) and periods (.)',
      highestHbA1cFormat: 'Please use only numbers (0-9) and periods (.)',
      lowestBMIFormat: 'Please use only numbers (0-9) and periods (.)',
      highestBMIFormat: 'Please use only numbers (0-9) and periods (.)',
      lowestDailyStepsFormat: 'Please use only numbers (0-9) and periods (.)',
      highestDailyStepsFormat: 'Please use only numbers (0-9) and periods (.)'
    },
    pepIntegrationNotes:
      'Does your patient suffer from a chronic disease? It is recommended for patients ' +
      'suffering from chronic disease to be included in a 85-day program to improve their medical condition.'
  },
  de: {
    ...serverErrors.de,
    intro: intro.de,
    infos: {
      errorOccurred:
        'Es ist ein Fehler aufgetreten - kontaktieren Sie das DocDok-Team',
      connectionLost: 'Verbindung verloren - es wird versucht neu zu verbinden',
      reconnected: 'Die Verbindung zum Server wurde wieder hergestellt',
      areYouSure: 'Sind Sie sicher, dass Sie dieses Ereignis löschen möchten?',
      sessionTimeout:
        'Ihre Sitzung ist abgelaufen. Melden Sie sich bitte erneut ein.'
    },
    date: {
      long: 'D. MMMM YYYY',
      short: 'DD.MM.YYYY',
      stamp: 'YYYY_MM_DD',
      withTime: 'HH:mm - DD.MM.YYYY'
    },
    time: {
      short: 'HH:mm',
      long: 'HH:mm:ss'
    },
    actions: {
      actions: 'Aktionen',
      update: 'Aktualisieren',
      search: 'Suche...',
      save: 'Speichern',
      ok: 'Ok',
      cancel: 'Abbrechen',
      edit: 'Anpassen',
      close: 'Schliessen',
      remove: 'Entfernen',
      send: 'Senden',
      export: 'Exportieren',
      browse: 'Durchsuchen',
      create: 'Erstellen',
      add: 'Hinzufügen',
      next: 'Weiter',
      previous: 'Zurück',
      done: 'Fertig',
      skip: 'Überspringen',
      accept: 'Akzeptieren',
      decline: 'Ablehnen',
      confirm: 'Bestätigen',
      delete: 'Löschen'
    },
    charts: {
      count: 'Anzahl: %{count}',
      percentage: '(%{percentage}%)'
    },
    entitySearcher: { noResult: 'Keine Treffer' },
    table: table.de,
    clinics: clinics.de,
    home: home.de,
    menu: menu.de,
    header: header.de,
    profile: profile.de,
    users: users.de,
    messages: messages.de,
    surveys: surveys.de,
    patients: patients.de,
    patient: patient.de,
    professionals: professionals.de,
    billing: billing.de,
    studies: studies.de,
    devices: devices.de,
    catalog: catalog.de,
    cases: cases.de,
    appointment: appointment.de,
    ambulance: ambulance.de,
    events: {
      tooltip: 'Keycloak/Docdok Ereignisse anzeigen'
    },

    domain: {
      entity: {
        user: userTranslations.de,
        survey: surveyTranslations.de,
        surveyThemes: surveyThemeTranslations.de,
        surveyGroups: surveyGroupTranslations.de,
        surveyParticipation: surveyParticipationTranslations.de,
        patient: {
          ...patientTranslations.de,
          fields: {
            ...patientTranslations.de.fields,
            activated: userTranslations.de.fields.activated
          }
        },
        professional: professionalTranslations.de,
        fitbit: fitbit.de,
        studyReportDefinition: studyReportDefinitionTranslations.de,
        contentDefinition: contentDefinitionTranslation.de,
        clinic: clinicTranslations.de,
        keycloakEvent: keycloakEventTranslations.de,
        study: studyTranslations.de,
        surveyAnalytic: surveyAnalytic.de,
        deviceDefinition: deviceDefinitionTranslation.de,
        studyParticipant: studyParticipantTranslations.de,
        studyParticipantPatient: {
          ...studyParticipantTranslations.de,
          ...patientTranslations.de,
          fields: {
            ...patientTranslations.de.fields,
            ...studyParticipantTranslations.de.fields
          }
        },
        appointment: {
          fields: {
            ...appointment.de.fields
          }
        },
        docdokEvent: docdokEventTranslation.en,
        ambulanceQueueItems: ambulance.de.ambulanceRows,
        casestream: {
          fields: casestream.de.fields
        },
        organizations: {
          fields: organization.de.fields
        },
        primaryConversation: {
          fields: primaryConversation.de.fields
        },
        patientActivities: {
          fields: patientActivities.de.fields
        },
        patientMeasurements: {
          fields: patientMeasurements.de.fields
        }
      }
    },

    forms: {
      createCatalog: {
        title: 'Titel',
        text: 'Text (Beschreibung)',
        languages: 'Sprache',
        fileType: 'Datei-Typ (MIME-Typ)',
        tags: 'Markierungen',
        license: 'Lizenz',
        studyId:
          'Studien (schränkt den Zugang zu einer Studie oder mehreren Studien ein)',
        clinicId:
          'Kliniken (beschränken Sie den Zugang zur Datei auf die Klinik[en])',
        url: 'URL',
        file: 'file'
      },
      save: 'Speichern',
      reset: 'Zurücksetzen',
      chooseFile: 'Datei Auswählen',
      changeFile: 'Datei ändern',
      changeFileInfo: 'Diese Änderung wird sich auf die bisherigen Studienteilnehmer auswirken',
      cancel: 'Abbrechen',
      ok: 'Ok',
      noFileChoosen: 'keine Datei ausgewählt.',
      errors: {
        mandatory: 'Bitte geben Sie einen Werten ein.',
        minLength: 'Der Wert muss mindestens %{minLength} Zeichen lang sein.',
        maxLength:
          'Der Wert ist länger als das Maximum von %{maxLength} Zeichen.',
        pattern:
          'Der Wert entsprichten nicht dem gefordertem Muster %{pattern}.',
        notInFuture: 'Das Datum darf nicht in der Zukunft sein.',
        noValidDate: 'Das Datum ist nicht gültig',
        noSelection: 'Bitte auswählen',
        maxValue:
          'der Wert (%{value}) muss kleiner oder gleich %{maxValue} sein',
        minValue:
          'der Wert (%{value}) muss grösser oder gleich %{minValue} sein',
        endTime: 'Die Endzeit sollte größer als die Startzeit sein',
        includingSpace: 'inklusive Leerzeichen'
      },
      sendContent: {
        conversations: 'Konversationen'
      },
      profile: userTranslations.de.fields,
      createUser: userTranslations.de.fields,
      updateUser: userTranslations.de.fields,
      createConversation: {
        title: 'Titel',
        users: 'Teilnehmer'
      },
      editConversation: {
        title: 'Titel',
        users: 'Teilnehmer'
      },
      createClinic: clinicTranslations.de.fields,
      createPhysician: professionalTranslations.de.fields,
      createMpa: professionalTranslations.de.fields,
      createPatient: {
        ...patientTranslations.de.fields,
        isDependent: 'Kind',
        proxy: 'Geschwister',
        createNew: 'Elternteil neu erstellen',
        pepIntegration: 'Zum Hybrid Versorgungs-Programm hinzufügen',
        disease: 'Krankheit auswählen',
        diseaseOpts: {
          Diabetes: 'Diabetes',
          Hypertension: 'Hypertonie',
          Obesity: 'Adipositas'
        },
        proxySalutation: patientTranslations.de.fields.salutation,
        proxySalutationOpts: patientTranslations.de.fields.salutationOpts,
        proxyFirstName: patientTranslations.de.fields.firstName,
        proxyLastName: patientTranslations.de.fields.lastName,
        proxyEmail: patientTranslations.de.fields.email,
        proxyMobileNumber: patientTranslations.de.fields.mobileNumber,
        proxyGender: patientTranslations.de.fields.gender,
        proxyGenderOpts: patientTranslations.de.fields.genderOpts
      },
      targetForm: {
        setTargetValue: 'Zielwerte festlegen',
        setTargetValueNotification:
          'Sie erhalten eine Benachrichtigung, wenn die eingegebenen Messwerte des Patienten,' +
          ' ausserhalb des Zielwertbereichs liegen',
        bloodPressureTargetRange: 'BLUTDRUCK ZIELBEREICH',
        lowestSystolicBloodPressure: 'Niedrigster systolischer Blutdruck',
        highestSystolicBloodPressure: 'Höchster systolischer Blutdruck',
        lowestDiastolicBloodPressure: 'Niedrigster diastolischer Blutdruck',
        highestDiastolicBloodPressure: 'Höchster diastolischer Blutdruck',
        HbA1cTargetRange: 'HbA1c ZIELBEREICH',
        lowestHbA1c: 'Niedrigster HbA1c-Wert',
        highestHbA1c: 'Höchster HbA1c-Wert',
        bmiTarget: 'BMI ZIELWERT',
        lowestBMI: 'Niedrigster BMI',
        highestBMI: 'Höchster BMI',
        stepTargetRange: 'SCHRITTE ZIELBEREICH',
        lowestDailySteps: 'Niedrigste tägliche Schrittzahl',
        highestDailySteps: 'Höchste tägliche Schrittzahl',
        save: 'SPEICHERN'
      },
      editEvent: {
        name: 'Titel',
        startdate: 'Startdatum',
        startTime: 'Beginn',
        endDate: 'Enddatum',
        endTime: 'Ende',
        location: 'Ort',
        recurrence: 'Wiederholen',
        none: 'Niemals',
        schedule: 'Planen',
        pattern: 'Vorlage',
        monthly: 'Monatlich',
        daily: 'Täglich',
        weekly: 'Wöchentlich',
        file: 'Datei',
        notes: 'Notizen',
        type: 'Kategorie',
        status: 'Status',
        statusOpts: {
          SCHEDULED: 'Geplant',
          DONE: 'Abgeschlossen'
        },
        typeOpts: {
          activity: 'ToDo’s',
          nutrition: 'Ernährung',
          exercise: 'Bewegung',
          coaching: 'Coaching'
        },
        recurrenceOpts: {
          none: 'Niemals',
          schedule: 'Planen'
        },
        patternOpts: {
          monthly: 'Monatlich',
          daily: 'Täglich',
          weekly: 'Wöchentlich'
        },
        event: 'Ereignisse'
      },
      addToStudy: {
        ...studyTranslations.de.fields,
        ...studyParticipantTranslations.de.fields
      },
      patientData: patientTranslations.de.fields,
      physicianData: {
        primaryPhysicianUuid: 'Name'
      },
      contactData: userTranslations.de.fields,
      userData: userTranslations.de.fields,
      patientStudyParticipations: {
        ...studyTranslations.de.fields,
        ...studyParticipantTranslations.de.fields
      },
      requestAttention: {
        message: 'Nachricht',
        person: 'Person'
      },
      studyReport: {
        studyId: 'Studie',
        studyReportDefinitionId: 'Studien Report'
      },
      sendSurvey: {
        patients: 'Patienten',
        doSchedule: 'Selektierte Umfragen wiederkehrend ausführen',
        doScheduleDescription:
          'Folgende Scheduling können wiederkehrend ausgeführt werden',
        schedulingDescription:
          'Die Umfrage "%{name}" wird %{endOccurence} mal jeden %{dayIncrement}. Tag um %{time} verschickt.',
        schedulingDescriptionDaily:
          'Die Umfrage "%{name}" wird %{endOccurence} Tage lang jeden Tag um %{time} verschickt.'
      },
      sendSurveyFromPatient: {
        surveys: 'Umfrage',
        doSchedule: 'Selektierte Umfragen wiederkehrend ausführen'
      },
      scheduleStudySurvey: studies.de.scheduleStudySurvey,
      uploadSurvey: surveys.de.uploadSurvey,
      updateSurvey: surveys.de.uploadSurvey,
      addStudyParticipant: {
        ...studyTranslations.de.fields,
        ...studyParticipantTranslations.de.fields,
        patients: 'Teilnehmer'
      },
      dashboard: {
        date: 'Datum'
      },
      createCase: cases.de.newCaseForm,
      editCase: cases.de.editCaseForm,
      addParticipationCriterias: {
        inclusions: 'Einschluss Kriterien',
        exclusions: 'Ausschluss Kriterien'
      },
      createSurveyGroup: {
        groupName: 'Group Name',
        surveyDefinitionIds: 'Surveys'
      },
      updateSurveyGroup: {
        groupName: 'Group Name',
        surveyDefinitionIds: 'Surveys'
      }
    },
    docdokEvents: auditEventTypes.en,
    eventTypes: {
      total: 'Gesamt',
      event: 'Ereignisse',
      activity: 'ToDo’s',
      nutrition: 'Ernährung',
      exercise: 'Bewegung',
      coaching: 'Coaching',
      daily: 'Täglich',
      weekly: 'Wöchentlich',
      threeMonths: '3 Monate',
      sixMonths: '6 Monate',
      year: 'Jahr',
      weight: 'Gewicht',
      whr: 'WHR',
      totalSession: '%{count} Bisher abgeschlossene Coaching-Sitzungen.',
      totalSessionSingle: '%{count} Bisher abgeschlossene Coaching-Sitzung.',
      activities: 'Aktivitäten',
      count: 'Anzahl'
    },
    createEvent: 'EREIGNIS ERSTELLEN',
    createEventFor: 'Ein Ereignis erstellen für %{date} um %{time}',
    createFromNew: 'Neues Ereignis',
    createFromExistingOne: 'Aus bestehendem Ereignis erstellen',
    total: 'Total',
    event: 'Ereignisse',
    threeMonths: '3 Monate',
    sixMonths: '6 Monate',
    year: 'Jahr',
    weight: 'Gewicht',
    whr: 'WHR',
    totalSession: '%{count} Bisher abgeschlossene Coaching-Sitzung',
    totalSessionSingle: '%{count} Bisher abgeschlossene Coaching-Sitzung',
    activities: 'Aktivitäten',
    count: 'Anzahl',
    activity: 'ToDo’s',
    nutrition: 'Ernährung',
    exercise: 'Bewegung',
    coaching: 'Coaching',
    daily: 'Täglich',
    weekly: 'Wöchentlich',
    allDay: 'Ganz - tägig',
    prev: 'Prev',
    next: 'Next',
    prevYear: 'Prev Year',
    nextYear: 'Next Year',
    today: 'Heute',
    month: 'Monat',
    week: 'Woche',
    day: 'Tag',
    list: 'List',
    confirmation: 'Möchten Sie die Serie dieses Ereignis aktualisieren?',
    confirmationTitle: 'Bestätigung',
    selectRecc: 'Bitte wählen Sie Wiederholung',
    yes: 'ja',
    no: 'Nein',
    endTimeError: 'Die Endzeit sollte größer als die Startzeit sein',
    customFormErrors: {
      salutation: 'Bitte geben Sie eine Anrede ein',
      firstName: 'Bitte geben Sie einen Vornamen ein',
      lastName: 'Bitte geben Sie einen Nachnamen ein',
      gender: 'Bitte auswählen',
      birthdate: 'Bitte geben Sie ein gültiges Geburtsdatum ein',
      mobileNumber:
        'Bitte geben Sie eine Mobiltelefonnummer ein mit mind. 5 Nummern',
      email: 'Bitte geben Sie eine gültige E-Mail Adresse ein',
      langKey: 'Bitte auswählen',
      role: 'Bitte auswählen',
      clinic: 'Bitte auswählen',
      birthdateFormat: 'Das Datum ist nicht gültig',
      mobileNumberFormat:
        'Der Wert entspricht nicht dem geforderten Format +41795553322 (oder +49...+43... etc.)',
      emailFormat:
        'Der Wert entspricht nicht dem geforderten Format me@domain.com',
      proxySalutation: 'Bitte geben Sie eine Anrede ein',
      proxyFirstName: 'Bitte geben Sie einen Vornamen ein',
      proxyLastName: 'Bitte geben Sie einen Nachnamen ein',
      proxyGender: 'Bitte auswählen',
      proxyMobileNumber:
        'Bitte geben Sie eine Mobiltelefonnummer ein mit mind. 5 Nummern',
      proxyEmail: 'Bitte geben Sie eine gültige E-Mail Adresse ein',
      proxyMobileNumberFormat:
        'Der Wert entspricht nicht dem geforderten Format +41795553322 (oder +49...+43... etc.)',
      proxyEmailFormat:
        'Der Wert entspricht nicht dem geforderten Format me@domain.com',
      lowestSystolicBloodPressureFormat:
        'Bitte verwenden Sie nur Zahlen (0-9) und Punkte (.)',
      highestSystolicBloodPressureFormat:
        'Bitte verwenden Sie nur Zahlen (0-9) und Punkte (.)',
      lowestDiastolicBloodPressureFormat:
        'Bitte verwenden Sie nur Zahlen (0-9) und Punkte (.)',
      highestDiastolicBloodPressureFormat:
        'Bitte verwenden Sie nur Zahlen (0-9) und Punkte (.)',
      lowestHbA1cFormat: 'Bitte verwenden Sie nur Zahlen (0-9) und Punkte (.)',
      highestHbA1cFormat: 'Bitte verwenden Sie nur Zahlen (0-9) und Punkte (.)',
      lowestBMIFormat: 'Bitte verwenden Sie nur Zahlen (0-9) und Punkte (.)',
      highestBMIFormat: 'Bitte verwenden Sie nur Zahlen (0-9) und Punkte (.)',
      lowestDailyStepsFormat:
        'Bitte verwenden Sie nur Zahlen (0-9) und Punkte (.)',
      highestDailyStepsFormat:
        'Bitte verwenden Sie nur Zahlen (0-9) und Punkte (.)'
    },
    pepIntegrationNotes:
      'Leidet Ihr Patient an einer chronischen Krankheit? Es wird empfohlen, Patienten mit chronischen Krankheiten' +
      ' in ein 85-Tage-Programm zur Verbesserung ihres Gesundheitszustands einzubeziehen.'
  }
}
