/* eslint-disable max-len, no-useless-escape */
// =========================
// DONT DO LINE BREAKS!
// =========================
export const emailRgx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const mobileRgx = /^(\s*|\+[1-9]\d{1,14})$/

export const onlyNumberRgx = /^\d*\.?\d*$/

export const onlyTimeString = /^(?=.*[hmd]$)\d+(?:d\s*)?\d*(?:h\s*)?\d*(?:m\s*)?$/

export const nonEmptyString = /^(?!\s*$).+/

export default { emailRgx, mobileRgx, onlyNumberRgx }
