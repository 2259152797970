// @flow

import { put, takeEvery, select } from 'redux-saga/effects'
import type { ExtractReturn, GenType } from 'common-docdok/src/types'
import { getKeycloakClient } from 'common-docdok/src/common/Keycloak/keycloakProvider'
import { messagingActions, messagingActionTypes } from 'common-docdok/src/domain/messaging/actions'
import { userActions } from 'common-docdok/src/domain/user/actions'
import { asyncAction } from 'common-docdok/src/utils/sagaUtils'

import { showNotification } from '../../../../../common/Notifications/actions/showNotification'

import { unreadMessagesCount } from '../../../../../common/Messaging/utils'

type PostMessageType = ExtractReturn<typeof messagingActions.postMessageSucceeded>
export function* showBrowserNotificationForMessage({ payload }: PostMessageType): GenType {
  const {
    userRef,
    conversationId,
    sequenceNo,
    meta: { notificationtype }
  } = payload

  yield asyncAction(userActions.loadMissingUsersRequested([userRef]))

  const users = yield select((state: StoreType) => state.userCache.users)
  const user = users[userRef]
  const checkedMessages = yield select((state: StoreType) => state.messaging.checkedMessages[conversationId])
  const selectedConv = yield select((state: StoreType) => state.messaging.selectedConversation)
  const subs = yield select((state: StoreType) => state.messaging.conversations[conversationId].subscriptions)
  const isChatMessage = notificationtype === 'CHAT'
  if (isChatMessage && subs && user) {
    const [{ mute }] = subs.filter(sub => sub.userRef === getKeycloakClient().getUserId())
    const isMessageChecked = checkedMessages >= sequenceNo
    const isCurrentConv = conversationId === selectedConv
    const isMessageByMe = userRef === getKeycloakClient().getUserId()

    if (!isMessageByMe && !isMessageChecked && !mute && !isCurrentConv) {
      const titel = `${user.firstName} ${user.lastName}`
      const options = { body: payload.text, icon: user.avatarPicture }
      const url = `${window.location.origin}/private/app/messages/conversation/${conversationId}`
      yield put(showNotification(titel, options, url))
    }
  }
}

function* updateTitle(): GenType {
  const { messagesCount } = yield select((state: StoreType) => unreadMessagesCount(state.messaging))
  if (messagesCount > 0) {
    document.title = `(${messagesCount}) ${document.title.replace(/\(.*\)/, '')}`
  } else {
    document.title = document.title.replace(/\(.*\)/, '')
  }
}

export default function notificationSaga(): Array<GenType> {
  return [
    takeEvery(messagingActionTypes.POST_MESSAGE_SUCCEEDED, showBrowserNotificationForMessage),
    takeEvery(messagingActionTypes.POST_MESSAGE_SUCCEEDED, updateTitle),
    takeEvery(messagingActionTypes.GET_SUBSCRIPTIONS_SUCCEEDED, updateTitle),
    takeEvery(messagingActionTypes.MARK_AS_CHECKED_REQUESTED, updateTitle),
    takeEvery(messagingActionTypes.SELECT_CONVERSATION, updateTitle)
  ]
}
