// @flow

export const professionalsActionTypes = {
  TOGGLE_CREATE_MPA_DIALOG: 'TOGGLE_CREATE_MPA_DIALOG',
  TOGGLE_CREATE_PHYSICIAN_DIALOG: 'TOGGLE_CREATE_PHYSICIAN_DIALOG'
}
export type ProfessionalsActionType = $Keys<typeof professionalsActionTypes>
export const professionalsActions = {
  toggleCreateMpaDialog() {
    return {
      type: professionalsActionTypes.TOGGLE_CREATE_MPA_DIALOG
    }
  },
  toggleCreatePhysicianDialog() {
    return {
      type: professionalsActionTypes.TOGGLE_CREATE_PHYSICIAN_DIALOG
    }
  }
}
