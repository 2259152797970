// @flow
import participations from './participations'

import surveySaga from './surveys'
import devicesSaga from './devices'
import studySaga from './study'
import type { GenType } from '../../../types'

export default function surveyRootSaga(): Array<GenType> {
  return [...surveySaga(), ...participations(), ...devicesSaga(), ...studySaga()]
}
