// @flow
import { put, call, takeEvery, all } from 'redux-saga/effects'
import type { GenType } from '../../../types'
import api from '../../../api'
import { securityActionTypes, securityActions } from '../actions/index'

export function* getRightsGen(): GenType {
  try {
    const result = yield call(api.getRights)
    yield put(securityActions.getRightsSucceeded(result.data))
  } catch (e) {
    yield put(securityActions.getRightsFailed(e))
  }
}

export default function* clinicSaga(): GenType {
  yield all([takeEvery(securityActionTypes.GET_RIGHTS_REQUESTED, getRightsGen)])
}
