export default {
  en: {
    addRecurrence: 'Add Recurrence',
    removeRecurrence: 'Remove Recurrence',
    delay: 'Delay',
    unit: 'Unit',
    unitOpts: {
      day: 'Day',
      week: 'Week'
    },
    customRecurrenceHint: 'Each recurrence is calculated from the start date.',
    error: {
      toShort: 'smallest value is 0',
      duplicate: 'Recurrence exists already'
    }
  },
  de: {
    addRecurrence: 'Wiederholung hinzufügen',
    removeRecurrence: 'Wiederholung entfernen',
    delay: 'Verzögerung',
    unit: 'Einheit',
    unitOpts: {
      day: 'Tag',
      week: 'Woche'
    },
    customRecurrenceHint: 'Jede Wiederholung ist vom Start Datum ausgerechnet.',
    error: {
      toShort: 'kleinster Wert ist 0',
      duplicate: 'Wiederholung existiert bereits'
    }
  }
}
