// @flow
import { put, takeEvery } from 'redux-saga/effects'
import type { GenType } from 'common-docdok/src/types'
import { stopSubmit } from 'redux-form'
import { asyncAction } from 'common-docdok/src/utils/sagaUtils'

import { userActions } from 'common-docdok/src/domain/user/actions'

import { dynamicFormActionTypes } from '../../DynamicForm/actions'
import { infoPanelActions } from '../../InfoPanel/actions'
import { createUserDialogActions } from '../actions'

function* createUser({ payload }: Object): GenType {
  if (payload.name === 'createUser') {
    const winner = yield asyncAction(userActions.createUsersRequested(payload.data))

    if (winner.succeeded) {
      yield put(stopSubmit(payload.name))
      yield put(createUserDialogActions.setCreateUserDialog(false))
      yield put(infoPanelActions.showMessage('users.createUser.userCreated'))
    } else {
      yield put(stopSubmit(payload.name, winner.failed.payload.error))
    }
  }
}

export default function usersSaga() {
  return [takeEvery(dynamicFormActionTypes.SUBMIT_FORM, createUser)]
}
