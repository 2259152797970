// @flow
import React from 'react'
import Paper from '@material-ui/core/Paper'
import type { StudySummaryDtoType } from 'common-docdok/src/domain/survey/types/studySummaryDto'
import type { DeviceDefinitionDtoType } from 'common-docdok/src/domain/survey/types/deviceDefinitionDto'
import { Translate } from 'react-redux-i18n'
import { fields } from 'common-docdok/src/domain/survey/types/deviceDefinitionDto'

import Devices from '@material-ui/icons/Devices'
import Remove from '@material-ui/icons/RemoveCircleOutline'
import { surveyActions } from 'common-docdok/src/domain/survey/actions'

import DynamicFormDialog from '../../../../../common/DynamicFormDialog'
import { ADD_STUDY_DEVICE, REMOVE_STUDY_DEVICE } from '../../../../../common/Security/actionNames'
import Action from '../../../../../common/Security/containers/action'
import EntityTable from '../../../../../common/EntityTable/components/EntityTable'
import { studiesActions } from '../actions'

import { RaisedButton, TypographyLight } from '../../../../../common/Mui'
import { GreyBar, Toolbar, ToolbarGroup, Buttons } from '../../../../../common/Static'

type PropsType = {
  study: StudySummaryDtoType,
  setAddStudyDeviceDialog: typeof studiesActions.setAddStudyDeviceDialog,
  showAddStudyDevice: boolean,
  updateStudyRequested: typeof surveyActions.updateStudyRequested
}

export default class StudyDevices extends React.Component<PropsType> {
  render() {
    const { study, setAddStudyDeviceDialog, showAddStudyDevice, updateStudyRequested } = this.props
    const { devices, id } = study
    const rows = devices || []
    return (
      <Paper>
        <GreyBar>
          <Toolbar>
            <ToolbarGroup>
              <TypographyLight variant='h6'>
                <Translate value='studies.devices.title' />
              </TypographyLight>
            </ToolbarGroup>
            <Buttons>
              <Action name={ADD_STUDY_DEVICE}>
                <RaisedButton
                  id='addStudyDevice-button'
                  label={<Translate value='studies.actions.addStudyDevice.title' />}
                  onClick={() => {
                    setAddStudyDeviceDialog(true)
                  }}
                  icon={<Devices />}
                />
              </Action>
            </Buttons>
          </Toolbar>
        </GreyBar>
        <EntityTable
          id='studyDevices'
          defaultPageSize={5}
          hideRowCheckbox
          entity='deviceDefinition'
          rows={rows}
          columns={{
            name: fields.name
          }}
          actions={[
            {
              name: REMOVE_STUDY_DEVICE,
              icon: <Remove color='primary' />,
              tooltipKey: 'studies.actions.removeSurvey.tooltip',
              enabled: () => true,
              action: (row: DeviceDefinitionDtoType) => {
                updateStudyRequested({
                  ...study,
                  devices: rows.filter(r => r.id !== row.id)
                })
              }
            }
          ]}
        />
        <DynamicFormDialog
          open={showAddStudyDevice}
          handleClose={() => {
            setAddStudyDeviceDialog(false)
          }}
          titleKey='studies.actions.addStudyDevice.title'
          form='addStudyDevice'
          saveLabelKey='actions.add'
          initialValues={{ study, devices: rows }}
          fields={{
            devices: {
              specName: 'availableStudyDevices',
              type: 'search',
              noSearch: true,
              mandatory: true,
              multiSelectable: true,
              params: [id]
            }
          }}
        />
      </Paper>
    )
  }
}
