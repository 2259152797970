export default {
  en: {
    name: 'Clinic',
    fields: {
      city: 'City',
      id: 'Id',
      name: 'Name',
      phoneNumber: 'Phone Number',
      publicUuid: 'PublicUuid',
      street: 'Street',
      streetNumber: 'Number',
      zipCode: 'Zip Code',
      patientReadonlyConversations: 'Patient Chat'
    }
  },
  de: {
    name: 'Klinik',
    fields: {
      city: 'Stadt',
      id: 'Id',
      name: 'Name',
      phoneNumber: 'Telefonnummer',
      publicUuid: 'PublicUuid',
      street: 'Strasse',
      streetNumber: 'Nummer',
      zipCode: 'PLZ',
      patientReadonlyConversations: 'Patienten Messaging'

    }
  }
}
