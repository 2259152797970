// @flow
import type { ActionType, ExtractReturn } from 'common-docdok/src/types'
import { addToStudyDialogActionTypes, addToStudyDialogActions } from '../actions'

// State Definition
export type AddToStudyDialogStateType = {
  showAddToStudyDialog: boolean,
  userRef: ?string,
  patientUuid: ?string
}

// Default State
const initialState: AddToStudyDialogStateType = {
  showAddToStudyDialog: false,
  userRef: undefined,
  patientUuid: undefined
}

// Action Handlers

function toggleAddToStudyDialog(
  state: AddToStudyDialogStateType,
  action: ExtractReturn<typeof addToStudyDialogActions.toggleAddToStudyDialog>
): AddToStudyDialogStateType {
  const { patientUuid, userRef } = action.payload
  return {
    ...state,
    showAddToStudyDialog: !state.showAddToStudyDialog,
    userRef,
    patientUuid
  }
}

const ACTION_HANDLERS = {
  [addToStudyDialogActionTypes.TOGGLE_ADD_TO_STUDY_DIALOG]: toggleAddToStudyDialog
}

export default function surveysReducer(
  state: AddToStudyDialogStateType = initialState,
  action: ActionType
): AddToStudyDialogStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
