// @flow
import React from 'react'
import dateUtils from 'common-docdok/src/utils/dateUtils'
import { Localize } from 'react-redux-i18n'

type DateRenderPropsType = {
  value: any,
  dateFormat?: string,
  style?: Object,
  id?: string,
  className?: string
}

export default function dateRenderer(props: DateRenderPropsType) {
  const { dateFormat, style, value, id, className } = props
  const date = dateUtils.toDate(value)
  return !date || isNaN(date.getTime()) ? (
    <span id={id} className={className} />
  ) : (
    <span id={id} className={className}>
      <Localize style={style} value={date} dateFormat={dateFormat} />
    </span>
  )
}

dateRenderer.defaultProps = {
  dateFormat: 'date.short',
  style: {},
  id: undefined,
  className: 'date'
}
