import * as colors from '@material-ui/core/colors'

const palette = {
  docdokBlue: '#2774a3',
  docdokBlueDark: '#1d5d83',
  blueDark: '#1e5e84',
  blue: '#2773a4',
  blueLight: '#2fb3e5',
  blueVeryLight: '#daefff',
  grey: 'rgb(224, 224, 224)',
  greyDark: 'rgb(95, 95, 95)',
  greyLight: 'rgb(232, 232, 232)',
  pink: 'rgb(255, 64, 129)',
  greenLight: 'green',

  green100: colors.green[100],
  green200: colors.green[200],
  green300: colors.green[300],
  green400: colors.green[400],
  green500: colors.green[500],
  green600: colors.green[600],
  green700: colors.green[700],
  green800: colors.green[800],
  green900: colors.green[900],
  blue100: colors.blue[100],
  blue200: colors.blue[200],
  blue300: colors.blue[300],
  blue400: colors.blue[400],
  blue500: colors.blue[500],
  blue600: colors.blue[600],
  blue700: colors.blue[700],
  blue800: colors.blue[800],
  blue900: colors.blue[900],
  yellow100: colors.yellow[100],
  yellow200: colors.yellow[200],
  yellow300: colors.yellow[300],
  yellow400: colors.yellow[400],
  yellow500: colors.yellow[500],
  yellow600: colors.yellow[600],
  yellow700: colors.yellow[700],
  yellow800: colors.yellow[800],
  yellow900: colors.yellow[900],
  red100: colors.red[100],
  red200: colors.red[200],
  red300: colors.red[300],
  red400: colors.red[400],
  red500: colors.red[500],
  red600: colors.red[600],
  red700: colors.red[700],
  red800: colors.red[800],
  red900: colors.red[900],

  indigo100: colors.indigo[100],
  indigo200: colors.indigo[200],
  indigo300: colors.indigo[300],
  indigo400: colors.indigo[400],
  indigo500: colors.indigo[500],
  indigo600: colors.indigo[600],
  indigo700: colors.indigo[700],
  indigo800: colors.indigo[800],
  indigo900: colors.indigo[900],

  lightBlue100: colors.lightBlue[100],
  lightBlue200: colors.lightBlue[200],
  lightBlue300: colors.lightBlue[300],
  lightBlue400: colors.lightBlue[400],
  lightBlue500: colors.lightBlue[500],
  lightBlue600: colors.lightBlue[600],
  lightBlue700: colors.lightBlue[700],
  lightBlue800: colors.lightBlue[800],
  lightBlue900: colors.lightBlue[900]
}

export default palette
