// @flow
import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Translate } from 'react-redux-i18n'
import { browserHistory } from 'react-router'

import type { MessagePropsType } from './Message'
import { launchSurveyActions } from '../../LaunchSurvey/actions'

type PropsType = { launchSurvey: typeof launchSurveyActions.launchSurvey } & MessagePropsType

export default function appointmentNotification(props: PropsType) {
  let result = null
  const { closeRequest } = props

  result = (
    <ListItem
      button
      id='survey-invitation-notification'
      onClick={() => {
        browserHistory.push('/private/app/appointment')
        if (closeRequest) closeRequest()
      }}
    >
      <ListItemText primary={<Translate value='appointment.newAppointment' />} />
    </ListItem>
  )

  return result
}
