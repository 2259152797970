/* eslint-disable */
import noUiSlider from 'nouislider'
import './docdokslider.css'
import wNumb from 'wnumb'

function init(Survey) {
  const widget = {
    name: 'ddnouislider',
    title: 'noUiSlider',
    iconName: 'icon-nouislider',
    widgetIsLoaded() {
      return typeof noUiSlider !== 'undefined'
    },
    isFit(question) {
      return question.getType() === 'ddnouislider'
    },
    htmlTemplate: `
    <div id="docdokQuestion" data-test="testdata">
      <div id="sliderValue"></div>
      
      <div id="sliderItem">
        <h6 class="sv_q_title" id="sliderTextLeft">
          <span id="textLeft"></span>
        </h6>
        <div id="slider"></div>
        <h6 class="sv_q_title" id="sliderTextRight">
          <span id="textRight"></span>
        </h6>
        </div>
    </div>`,

    setClassFromQuestion(el, elementId, question) {
      const elementById = document.getElementById(elementId)
      const questionValue = question[`${elementId}Class`]
      if (questionValue && elementById) {
        elementById.classList.add(questionValue)
      }
    },

    setTextFromQuestion(el, question) {
      const textLeft = document.getElementById('textLeft')
      if (textLeft && question.textLeft) {
        textLeft.innerHTML = question.textLeft
      }

      const textRight = document.getElementById('textRight')
      if (textRight && question.textRight) {
        textRight.innerHTML = question.textRight
      }
    },

    activatedByChanged(activatedBy) {
      Survey.JsonObject.metaData.addClass('ddnouislider', [], null, 'empty')
      Survey.JsonObject.metaData.addProperties('ddnouislider', [
        {
          name: 'step:number',
          default: 1
        },
        {
          name: 'rangeMin:number',
          default: 0
        },
        {
          name: 'rangeMax:number',
          default: 100
        },
        {
          name: 'pipsMode',
          default: 'positions'
        },
        {
          name: 'pipsValues:itemvalues',
          default: [0, 25, 50, 75, 100]
        },
        {
          name: 'pipsDensity:number',
          default: 5
        },
        {
          name: 'orientation',
          default: 'horizontal'
        },
        {
          name: 'direction',
          default: 'ltr'
        },
        {
          name: 'tooltips',
          default: true
        },
        {
          name: 'sliderClass:string'
        },
        {
          name: 'docdokQuestionClass:string'
        },
        {
          name: 'sliderTextLeftClass:string'
        },
        {
          name: 'sliderTextRightClass:string'
        },
        {
          name: 'sliderItemClass:string'
        },
        { name: 'sliderValueClass:string' },
        { name: 'textLeft:string' },
        { name: 'textRight:string' },
        { name: 'sliderValueText:string' }
      ])
    },
    afterRender(question, elSurveyjs) {
      const el = document.getElementById('slider')

      if (el.noUiSlider) {
        el.noUiSlider.destroy()
        delete el.noUiSlider
      }

      const wNumbFormater = wNumb({ decimals: 0 })

      const slider = noUiSlider.create(el, {
        start: question.value || (question.rangeMin + question.rangeMax) / 2,
        connect: [false, false],
        step: question.step,
        tooltips: question.tooltips ? wNumbFormater : false,
        pips: {
          mode: question.pipsMode || 'positions',
          values: question.pipsValues.map(pVal => parseInt((pVal.value !== undefined && pVal.value) || pVal)),
          density: question.pipsDensity || 5,
          stepped: true
        },
        format: wNumbFormater,
        range: {
          min: question.rangeMin,
          max: question.rangeMax
        },
        orientation: question.orientation,
        direction: question.direction,
        behaviour: 'drag-tap-snap'
      })

      const sliderValue = document.getElementById('sliderValue')
      const sliderValueText = question.sliderValueText ? question.sliderValueText : ''
      const textValueSetter = value => {
        sliderValue.innerHTML = `${sliderValueText} ${slider.get()}`
      }

      slider.on('change', () => {
        question.value = slider.get()
        textValueSetter(slider.get())
      })

      slider.on('slide', () => {
        question.value = slider.get()
        textValueSetter(slider.get())
      })

      const updateValueHandler = function() {
        slider.set(question.value)
        textValueSetter(question.value)
      }
      if (question.isReadOnly) {
        el.setAttribute('disabled', true)
      }

      this.setTextFromQuestion(el, question)

      this.setClassFromQuestion(el, 'docdokQuestion', question)
      this.setClassFromQuestion(el, 'slider', question)
      this.setClassFromQuestion(el, 'sliderItem', question)
      this.setClassFromQuestion(el, 'sliderTextLeft', question)
      this.setClassFromQuestion(el, 'sliderTextRight', question)
      this.setClassFromQuestion(el, 'sliderValue', question)

      updateValueHandler()
      question.noUiSlider = slider
      question.valueChangedCallback = updateValueHandler
      question.readOnlyChangedCallback = function() {
        if (question.isReadOnly) {
          el.setAttribute('disabled', true)
        } else {
          el.removeAttribute('disabled')
        }
      }
    },
    willUnmount(question, el) {
      question.noUiSlider.destroy()
      question.noUiSlider = null
      question.readOnlyChangedCallback = null
    }
  }

  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, 'customtype')
}

if (typeof Survey !== 'undefined') {
  init(Survey)
}

export default init
