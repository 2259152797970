export default {
  en: {
    logout: 'Logout',
    profile: 'Profile',
    notifications: 'Notifications',
    dismissAll: 'Dismiss All',
    patients: 'patients',
    noResult: 'No Result',
    menu: 'Menu',
    startTutorials: 'Start Tutorials',
    terms: {
      title: 'Terms and Conditions',
      update: 'pleace accept the updated terms and conditions',
      consent:
        'With my consent, I expressly agree to the storage of '
        + 'my health data in the EU (Germany). ',
      consentLink: 'More information can be found in the privacy policy section.',
      checkboxLabel: 'I have read the terms of use and the privacy policy and I agree to them.'
    }
  },
  de: {
    patients: 'Patienten',
    noResult: 'Kein Ergebnis',
    logout: 'Abmelden',
    profile: 'Profil',
    notifications: 'Benachrichtigungen',
    dismissAll: 'Alle als gesehen markieren',
    menu: 'Menu',
    startTutorials: 'Start Tutorials',
    terms: {
      title: 'Nutzungsbedingungen und Datenschutzerklärung',
      update: 'Bitte akzeptieren Sie die aktualisierten Nutzungsbedingungen',
      consent:
        'Mit meiner Zustimmung willige ich ausdrücklich in die Speicherung meiner '
        + 'Gesundheitsdaten in der EU (Deutschland) ein. ',
      consentLink: 'Mehr Informationen dazu finden sich in der Datenschutzerklärung',
      checkboxLabel: 'Ich habe die Nutzungsbedingungen und die Datenschutzerklärung gelesen und stimme ihnen zu.'
    }
  }
}
