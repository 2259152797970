// @flow
import { put, takeEvery, all } from 'redux-saga/effects'
import type { GenType } from 'common-docdok/src/types'
import { stopSubmit } from 'redux-form'
import { asyncAction } from 'common-docdok/src/utils/sagaUtils'
import { surveyActions, surveyActionTypes } from 'common-docdok/src/domain/survey/actions'

import { infoPanelActions } from '../../../../../common/InfoPanel/actions'
import { studiesActions } from '../actions'
import { dynamicFormActionTypes } from '../../../../../common/DynamicForm/actions'

function* addSurveyToStudy({ payload }: Object): GenType {
  if (payload.name === 'addSurveyToStudy') {
    const {
      data: { studyId }
    } = payload

    const studySurveyDtos = payload.data.surveys.map(survey => ({
      surveyId: survey.id,
      studyId,
      remindAfterHours: survey.remindAfterHours,
      alertAfterHours: survey.alertAfterHours
    }))

    const winners = yield all(studySurveyDtos.map(dto => asyncAction(surveyActions.addSurveyToStudyRequested(dto))))

    const addedCount = winners.filter(winner => winner.succeeded).length
    const failes = winners.filter(winner => winner.failed)
    if (addedCount > 0) {
      yield put(stopSubmit(payload.name))
      yield put(studiesActions.toggleAddSurveyDialog())

      yield put(
        infoPanelActions.showMessage('studies.actions.addSurvey.success', {
          addedCount
        })
      )
    } else if (failes.length > 0) {
      const [{ failed }] = failes
      yield put(stopSubmit(payload.name, failed.payload.error))
    } else {
      yield put(stopSubmit(payload.name))
    }
  }
}

function* removeStudySurveySucceeded(): GenType {
  yield put(infoPanelActions.showMessage('studies.actions.removeSurvey.success'))
}

export default function surveySaga(): Array<GenType> {
  return [
    takeEvery(dynamicFormActionTypes.SUBMIT_FORM, addSurveyToStudy),
    takeEvery(surveyActionTypes.REMOVE_STUDY_SURVEY_SUCCEEDED, removeStudySurveySucceeded)
  ]
}
