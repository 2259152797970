// @flow
import type { ActionType, ExtractReturn } from 'common-docdok/src/types'

import { clinicsActionTypes, clinicsActions } from '../actions'
// State Definition
export type ClinicsStateType = {
  showCreateClinic: boolean
}

const initialState = {
  showCreateClinic: false,
  showUpdateClinic: false
}

function setCreateClinicDialog(
  state: ClinicsStateType,
  action: ExtractReturn<typeof clinicsActions.setCreateClinicDialog>
) {
  return { ...state, showCreateClinic: action.payload }
}

function setUpdateClinicDialog(
  state: ClinicsStateType,
  action: ExtractReturn<typeof clinicsActions.setCreateClinicDialog>
) {
  return { ...state, showUpdateClinic: action.payload }
}

const ACTION_HANDLERS = {
  [clinicsActionTypes.SET_CREATE_CLINIC_DIALOG]: setCreateClinicDialog,
  [clinicsActionTypes.SET_UPDATE_CLINIC_DIALOG]: setUpdateClinicDialog
}

export default function studiesReducer(
  state: ClinicsStateType = initialState,
  action: ActionType
): ClinicsStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
