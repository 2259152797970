export default {
  en: {
    name: 'Keycloak Event',
    fields: {
      clientId: 'Client-Id',
      error: 'Error',
      ipAddress: 'Ip-Address',
      redirectUri: 'Redirect-Uri',
      time: 'Time',
      type: 'Type',
      username: 'Username'
    }
  },
  de: {
    name: 'Keycloak Event',
    fields: {
      clientId: 'Client-Id',
      error: 'Fehler',
      ipAddress: 'Ip-Adresse',
      redirectUri: 'Redirect-Uri',
      time: 'Zeit',
      type: 'Typ',
      username: 'Benutzername'
    }
  }
}
