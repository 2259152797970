// @flow
import React from 'react'
import { connect } from 'react-redux'
import { keycloakActions } from 'common-docdok/src/common/Keycloak/actions'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import { Translate } from 'react-redux-i18n'
import type { AmbulanceQueueItemDtoType } from 'common-docdok/src/domain/healthrelation/types/ambulanceQueueItemDto'
import { ambulanceQueueItemsActions } from '../actions'
import type { AmbulanceQueueSummaryType } from '../reducers'
import { findAssignedItem } from '../util'
import Action from '../../Security/containers/action'
import { SHOW_AMBULANCE } from '../../Security/actionNames'

const AmbulanceDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
`

const Bubble = styled.div`
  border-radius: 50%;
  height: 30px;
  width: 30px;
  color: white;
  font-weight: bold;
  margin-right: 10px 
  text-align: center;
  line-height: 30px;
`

type PropsType = {
  profile: ?{ firstName: string, avatarPicture: string },
  logout: typeof keycloakActions.logout,
  toggleShowAmbulanceItemsDialog: typeof ambulanceQueueItemsActions.toggleShowAmbulanceItemsDialog,
  getAmbulanceQueueItemsRequested: typeof ambulanceQueueItemsActions.getAmbulanceQueueItemsRequested,
  setMySelfToWaitingRequested: typeof ambulanceQueueItemsActions.setMySelfToWaitingRequested,
  setMySelfToNotWaitingRequested: typeof ambulanceQueueItemsActions.setMySelfToNotWaitingRequested,
  navigateToConversationOfItem: typeof ambulanceQueueItemsActions.navigateToConversationOfItem,
  summary: AmbulanceQueueSummaryType,
  assigend2Me: AmbulanceQueueItemDtoType,
  isPatient: boolean,
  isVinzenz: boolean,
  waitingPosition: number
}

class AmbulanceQueue extends React.Component<PropsType> {
  componentDidMount() {
    const { getAmbulanceQueueItemsRequested } = this.props
    getAmbulanceQueueItemsRequested()
  }

  renderNotPatient = () => {
    const { summary, toggleShowAmbulanceItemsDialog } = this.props

    return (
      <Action name={SHOW_AMBULANCE}>
        <AmbulanceDiv>
          <Typography variant='body1' style={{ marginRight: '10px' }}>
            <Translate value='ambulance.header.title' />
          </Typography>

          <Tooltip
            title={<Translate value='ambulance.header.tooltip.waiting' />}
            onClick={toggleShowAmbulanceItemsDialog}
          >
            <Bubble style={{ backgroundColor: 'red', cursor: 'pointer' }}>{summary.waiting}</Bubble>
          </Tooltip>

          <Tooltip
            title={<Translate value='ambulance.header.tooltip.inprogress' />}
            onClick={toggleShowAmbulanceItemsDialog}
          >
            <Bubble style={{ backgroundColor: 'lightgreen', cursor: 'pointer' }}>{summary.progress}</Bubble>
          </Tooltip>

          <Tooltip
            title={<Translate value='ambulance.header.tooltip.assigend2me' />}
            onClick={toggleShowAmbulanceItemsDialog}
          >
            <Bubble style={{ backgroundColor: 'green', cursor: 'pointer' }}>{summary.assigned2me}</Bubble>
          </Tooltip>
        </AmbulanceDiv>
      </Action>
    )
  }

renderPatient = () => {
  const { waitingPosition, isVinzenz, setMySelfToNotWaitingRequested, setMySelfToWaitingRequested } = this.props

  if (!isVinzenz) {
    return null
  }

  if (waitingPosition < 0) {
    return (
      <Tooltip title={<Translate value='ambulance.header.tooltip.joinButton' />}>
        <Button
          variant='contained'
          color='primary'
          onClick={setMySelfToWaitingRequested}
        >
          <Translate value='ambulance.header.joinButton' />
        </Button>
      </Tooltip>
    )
  }
  const position = waitingPosition + 1
  return (
    <AmbulanceDiv>
      <Typography variant='body1' style={{ marginRight: '10px' }}>
        <Translate value='ambulance.header.tooltip.actualPosition' position={position} />
      </Typography>
      <Tooltip
        title={<Translate value='ambulance.header.tooltip.actualPosition' position={position} />}
        onClick={() => {}}
      >
        <Bubble style={{ backgroundColor: 'green' }}>{position}</Bubble>
      </Tooltip>

      <Tooltip
        title={<Translate value='ambulance.header.tooltip.leave' />}
        onClick={setMySelfToNotWaitingRequested}
      >
        <Bubble style={{ backgroundColor: 'red', cursor: 'pointer' }}>X</Bubble>
      </Tooltip>
    </AmbulanceDiv>
  )
}

render() {
  const { isPatient } = this.props
  return isPatient ? this.renderPatient() : this.renderNotPatient()
}
}

const mapStateToProps = (state: StoreType) => {
  const { queueItems, summary, isPatient, isVinzenz, waitingPosition } = state.ambulancequeueitems
  const assigend2Me = findAssignedItem(queueItems)

  return ({
    queueItems,
    summary,
    assigend2Me,
    isPatient,
    isVinzenz,
    waitingPosition
  })
}

export default connect(
  mapStateToProps,
  { getAmbulanceQueueItemsRequested: ambulanceQueueItemsActions.getAmbulanceQueueItemsRequested,
    toggleShowAmbulanceItemsDialog: ambulanceQueueItemsActions.toggleShowAmbulanceItemsDialog,
    setMySelfToNotWaitingRequested: ambulanceQueueItemsActions.setMySelfToNotWaitingRequested,
    setMySelfToWaitingRequested: ambulanceQueueItemsActions.setMySelfToWaitingRequested
  }
)(AmbulanceQueue)
