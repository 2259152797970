// @flow
import type { ExtractReturn } from 'common-docdok/src/types'

export const dynamicFormActionTypes = {
  SUBMIT_FORM: 'SUBMIT_FORM',
  CHANGE_INPUT_FIELD: '@@redux-form/CHANGE',
  REGISTER_FORM_FIELD: '@@redux-form/REGISTER_FIELD'
}
export type DynamicFormActionType = $Keys<typeof dynamicFormActionTypes>
export const dynamicFormActions = {
  submitForm(name: string, data: Object) {
    return {
      type: dynamicFormActionTypes.SUBMIT_FORM,
      payload: { name, data }
    }
  }
}

export type SubmitFormType = ExtractReturn<typeof dynamicFormActions.submitForm>
