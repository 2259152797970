// @flow
import React from 'react'
import ReactDOM from 'react-dom'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import Popper from '@material-ui/core/Popper'
import { Translate } from 'react-redux-i18n'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { connect } from 'react-redux'

import { searchPatient } from '../actions/searchPatient'
import SearchField from '../../SearchField/components/SearchField'
import palette from '../../../styles/palette'
import type { PatientUserType } from '../reducers'
import PatientItem from '../components/patientItem'

type PropsType = {
  results: Array<PatientUserType>,
  searchPatient: typeof searchPatient
}

type StateType = {
  open: boolean
}

class SearchBar extends React.Component<PropsType, StateType> {
  state = { open: false }

  searchField: any

  searchFieldDom: any

  componentWillReceiveProps(nextProps: PropsType) {
    this.setState({ open: nextProps.results !== undefined })
  }

  close = () => this.setState({ open: false })

  getResult() {
    const { results } = this.props
    if (results) {
      const subheader = results.length > 0 ? (
        <ListSubheader style={{ background: 'white' }}>
          <Translate value='header.patients' />
        </ListSubheader>
      ) : (
        <ListSubheader color='error' style={{ background: 'white' }}>
          <Translate value='header.noResult' />
        </ListSubheader>
      )
      return (
        <List
          subheader={subheader}
          id='globalsearch-result'
          style={{
            maxHeight: 400,
            overflowY: 'scroll'
          }}
        >
          {results.map(p => (
            <PatientItem
              key={p.uuid}
              patient={p}
              onRequestClose={() => {
                this.close()
                this.searchField.toggleShow()
              }}
            />
          ))}
        </List>
      )
    }
    return <div />
  }

  render() {
    let errorText

    return (
      <div style={{ width: '30vw' }}>
        <SearchField
          id='globalsearch'
          hoverColor={palette.blueLight}
          color={palette.blue}
          search={(searchTerm) => {
            this.props.searchPatient(searchTerm)
          }}
          onClose={this.close}
          errorText={errorText}
          ref={(comp) => {
            if (comp) {
              this.searchField = comp
              this.searchFieldDom = ReactDOM.findDOMNode(this.searchField)
            }
          }}
        />
        <Popper
          placement='bottom-start'
          id='id'
          open={this.state.open}
          anchorEl={this.searchFieldDom}
          style={{ zIndex: 1000, maxHeight: 400, background: 'white' }}
        >
          <ClickAwayListener onClickAway={this.close}>
            <Paper>{this.getResult()}</Paper>
          </ClickAwayListener>
        </Popper>
      </div>
    )
  }
}

const mapStateToProps = (state: StoreType) => ({
  results: state.header.searchResult
})

export default connect(
  mapStateToProps,
  {
    searchPatient
  }
)(SearchBar)
