import { nonEmptyString } from '../../../utils/rgx'

export const fieldNames = {
  id: 'id',
  patientRef: 'patientRef',
  coachRef: 'coachRef',
  activityName: 'activity Name',
  eventType: 'eventType',
  startTime: 'start Time',
  Duration: 'Duration',
  location: 'location',
  description: 'description',
  notes: 'notes',
  seriesId: 'seriesId',
  pattern: 'pattern',
  endDate: 'endDate'
}

export const fields = {
  id: {
    type: 'number',
    format: 'int64'
  },
  patientRef: {
    type: 'string'
  },
  coachRef: {
    type: 'string'
  },
  name: {
    type: 'string',
    mandatory: true,
    pattern: nonEmptyString,
    patternNice: '(Not empty)'
  },
  type: {
    type: 'string',
    mandatory: true,
    disabled: true,
    enum: ['coaching', 'activity', 'exercise', 'nutrition']
  },
  startTime: {
    type: 'Date',
    mandatory: true
  },
  Duration: {
    type: 'duration',
    mandatory: true
  },
  location: {
    type: 'string'
  },
  description: {
    type: 'string',
    rows: 5
  },
  notes: {
    type: 'string',
    maxLength: 2000
  },
  seriesId: {
    type: 'number',
    format: 'int64'
  },
  pattern: {
    type: 'string',
    enum: ['daily', 'weekly', 'monthly']
  },
  endDate: {
    type: 'Date'
  },
  recurrence: {
    type: 'string',
    enum: ['none', 'schedule']
  },
  status: {
    type: 'string',
    enum: ['SCHEDULED', 'DONE']
  }
}

export type PatientActivitiesCalendarType = {
  patientRef: string,
  coachRef: string,
  distinct: boolean
}

export type PatientActivitiesDtoType = {
  id: number,
  patientRef: string,
  coachRef: string,
  activityName: string,
  eventType: string,
  startTime: string,
  Duration: string,
  location: string,
  description: string,
  notes: string,
  seriesId: number,
  pattern: string,
  endDate: string
}

export type CreatePatientActivitiesDtoType = {
  id?: number,
  patientUuid: string,
  physicianUuid: string,
  name: string,
  description: string,
  startTime: string,
  endTime: string,
  seriesEndDate?: string | null,
  type: string,
  location: string,
  pattern: string | null,
  status: string,
  url: string,
  seriesId?: number | null
}
