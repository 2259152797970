// @flow

export default function (cachedAttachments: Object, url: string): boolean {
  let isCached = false
  const lastLoaded = cachedAttachments[url]
  if (lastLoaded !== undefined) {
    const now = Date.now()
    const expireDate = new Date(lastLoaded)
    const halfAYear = 15552000
    expireDate.setSeconds(expireDate.getSeconds() + halfAYear)
    isCached = expireDate.getTime() > now
  }
  return isCached
}
