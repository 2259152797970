// @flow
import type { ActionType } from 'common-docdok/src/types'
import { alertActionTypes } from '../actions'

// State Definition
export type AlertStateType = {
  open: boolean,
  messageKey: ?string,
  params: Object
}

const initialState: AlertStateType = {
  open: false,
  messageKey: undefined,
  params: {}
}

// ------------------------------------
// Action Handlers (Reducers)
// ------------------------------------
const ACTION_HANDLERS = {
  [alertActionTypes.ALERT]: (state, action) => action.payload
}

// ------------------------------------
// Reducer
// ------------------------------------

export default function alertReducer(state: AlertStateType = initialState, action: ActionType) {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
