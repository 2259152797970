// @flow
import patientSaga from './patient'
import professionalsSaga from './professionals'
import clinicSaga from './clinic'

import type { GenType } from '../../../types'

export default function healthrelationSaga(): Array<GenType> {
  return [...patientSaga(), ...professionalsSaga(), ...clinicSaga()]
}
