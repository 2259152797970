// @flow

import type { AmbulanceQueueItemDtoType } from 'common-docdok/src/domain/healthrelation/types/ambulanceQueueItemDto'

export const ambulanceQueueItemsActionTypes = {

  GET_AMBULANCE_QUEUE_ITEMS_REQUESTED: 'GET_AMBULANCE_QUEUE_ITEMS_REQUESTED',
  GET_AMBULANCE_QUEUE_ITEMS_SUCCEEDED: 'GET_AMBULANCE_QUEUE_ITEMS_SUCCEEDED',
  GET_AMBULANCE_QUEUE_ITEMS_FAILED: 'GET_AMBULANCE_QUEUE_ITEMS_FAILED',

  SET_IN_PROGRESS_AMBULANCE_QUEUE_ITEM_REQUESTED: 'SET_IN_PROGRESS_AMBULANCE_QUEUE_ITEM_REQUESTED',
  SET_IN_PROGRESS_AMBULANCE_QUEUE_ITEM_SUCCEEDED: 'SET_IN_PROGRESS_AMBULANCE_QUEUE_ITEM_SUCCEEDED',
  SET_IN_PROGRESS_AMBULANCE_QUEUE_ITEM_FAILED: 'SET_IN_PROGRESS_AMBULANCE_QUEUE_ITEM_FAILED',

  SET_TO_FINISHED_AMBULANCE_QUEUE_ITEM_REQUESTED: 'SET_TO_FINISHED_AMBULANCE_QUEUE_ITEM_REQUESTED',
  SET_TO_FINISHED_AMBULANCE_QUEUE_ITEM_SUCCEEDED: 'SET_TO_FINISHED_AMBULANCE_QUEUE_ITEM_SUCCEEDED',
  SET_TO_FINISHED_AMBULANCE_QUEUE_ITEM_FAILED: 'SET_TO_FINISHED_AMBULANCE_QUEUE_ITEM_FAILED',

  SET_TO_ERROR_AMBULANCE_QUEUE_ITEM_REQUESTED: 'SET_TO_ERROR_AMBULANCE_QUEUE_ITEM_REQUESTED',
  SET_TO_ERROR_AMBULANCE_QUEUE_ITEM_SUCCEEDED: 'SET_TO_ERROR_AMBULANCE_QUEUE_ITEM_SUCCEEDED',
  SET_TO_ERROR_AMBULANCE_QUEUE_ITEM_FAILED: 'SET_TO_ERROR_AMBULANCE_QUEUE_ITEM_FAILED',

  QUEUE_UPDATED: 'QUEUE_UPDATED',

  TOGGLE_SHOW_AMBULANCE_ITMES_QUEUE_DIALOG: 'TOGGLE_SHOW_AMBULANCE_ITMES_QUEUE_DIALOG',

  NAVIGATE_TO_CONVERSATION_OF_ITEM: 'NAVIGATE_TO_CONVERSATION_OF_ITEM',

  GET_AMBULANCE_WAITING_POSITION_REQUESTED: 'GET_AMBULANCE_WAITING_POSITION_REQUESTED',
  GET_AMBULANCE_WAITING_POSITION_SUCCEEDED: 'GET_AMBULANCE_WAITING_POSITION_SUCCEEDED',
  GET_AMBULANCE_WAITING_POSITION_FAILED: 'GET_AMBULANCE_WAITING_POSITION_FAILED',

  SET_MYSELF_2_WAITING_REQUESTED: 'SET_MYSELF_2_WAITING_REQUESTED',
  SET_MYSELF_2_WAITING_SUCCEEDED: 'SET_MYSELF_2_WAITING_SUCCEEDED',
  SET_MYSELF_2_WAITING_FAILED: 'SET_MYSELF_2_WAITING_FAILED',

  SET_MYSELF_2_NOT_WAITING_REQUESTED: 'SET_MYSELF_2_NOT_WAITING_REQUESTED',
  SET_MYSELF_2_NOT_WAITING_SUCCEEDED: 'SET_MYSELF_2_NOT_WAITING_SUCCEEDED',
  SET_MYSELF_2_NOT_WAITING_FAILED: 'SET_MYSELF_2_NOT_WAITING_FAILED',

  CLOSE_ADD_2_WAITINGROOM_DIALOG: 'CLOSE_ADD_2_WAITINGROOM_DIALOG'
}

export type AmbulanceQueueItemsActionType = $Keys<typeof ambulanceQueueItemsActionTypes>

export const ambulanceQueueItemsActions = {
  //      -- APPEND ACTIONS HERE --
  getAmbulanceWaitingPositionRequested() {
    return {
      type: ambulanceQueueItemsActionTypes.GET_AMBULANCE_WAITING_POSITION_REQUESTED
    }
  },
  getAmbulanceWaitingPositionSucceeded(position: number) {
    return {
      type: ambulanceQueueItemsActionTypes.GET_AMBULANCE_WAITING_POSITION_SUCCEEDED,
      payload: { position }
    }
  },
  getAmbulanceWaitingPositionFailed(error: Error) {
    return {
      type: ambulanceQueueItemsActionTypes.GET_AMBULANCE_WAITING_POSITION_FAILED,
      payload: { error }
    }
  },
  setMySelfToWaitingRequested() {
    return {
      type: ambulanceQueueItemsActionTypes.SET_MYSELF_2_WAITING_REQUESTED
    }
  },
  setMySelfToWaitingSucceeded() {
    return {
      type: ambulanceQueueItemsActionTypes.SET_MYSELF_2_WAITING_SUCCEEDED
    }
  },
  setMySelfToWaitingFailed(error: Error) {
    return {
      type: ambulanceQueueItemsActionTypes.SET_MYSELF_2_WAITING_FAILED,
      payload: { error }
    }
  },
  setMySelfToNotWaitingRequested() {
    return {
      type: ambulanceQueueItemsActionTypes.SET_MYSELF_2_NOT_WAITING_REQUESTED
    }
  },
  setMySelfToNotWaitingSucceeded() {
    return {
      type: ambulanceQueueItemsActionTypes.SET_MYSELF_2_NOT_WAITING_SUCCEEDED
    }
  },
  setMySelfToNotWaitingFailed(error: Error) {
    return {
      type: ambulanceQueueItemsActionTypes.SET_MYSELF_2_NOT_WAITING_FAILED,
      payload: { error }
    }
  },
  closeAdd2WaitingRoomDialog() {
    return {
      type: ambulanceQueueItemsActionTypes.CLOSE_ADD_2_WAITINGROOM_DIALOG
    }
  },
  getAmbulanceQueueItemsRequested() {
    return {
      type: ambulanceQueueItemsActionTypes.GET_AMBULANCE_QUEUE_ITEMS_REQUESTED,
      payload: {}
    }
  },
  getAmbulanceQueueItemsSucceeded(payload: Array<AmbulanceQueueItemDtoType>) {
    return {
      type: ambulanceQueueItemsActionTypes.GET_AMBULANCE_QUEUE_ITEMS_SUCCEEDED,
      payload
    }
  },
  getAmbulanceQueueItemsFailed(error: Error) {
    return {
      type: ambulanceQueueItemsActionTypes.GET_AMBULANCE_QUEUE_ITEMS_FAILED,
      payload: { error }
    }
  },

  setItemIntoProgressRequested(patientUserRef: string) {
    return {
      type: ambulanceQueueItemsActionTypes.SET_IN_PROGRESS_AMBULANCE_QUEUE_ITEM_REQUESTED,
      payload: { patientUserRef }
    }
  },
  setItemIntoProgressSucceeded(patientUserRef: string) {
    return {
      type: ambulanceQueueItemsActionTypes.SET_IN_PROGRESS_AMBULANCE_QUEUE_ITEM_SUCCEEDED,
      payload: { patientUserRef }
    }
  },
  setItemIntoProgressFailed(error: Error) {
    return {
      type: ambulanceQueueItemsActionTypes.SET_IN_PROGRESS_AMBULANCE_QUEUE_ITEM_FAILED,
      payload: { error }
    }
  },

  setItemToFinishedRequested(patientUserRef: string) {
    return {
      type: ambulanceQueueItemsActionTypes.SET_TO_FINISHED_AMBULANCE_QUEUE_ITEM_REQUESTED,
      payload: { patientUserRef }
    }
  },
  setItemToFinishedSucceeded(patientUserRef: string) {
    return {
      type: ambulanceQueueItemsActionTypes.SET_TO_FINISHED_AMBULANCE_QUEUE_ITEM_SUCCEEDED,
      payload: { patientUserRef }
    }
  },
  setItemToFinishedFailed(error: Error) {
    return {
      type: ambulanceQueueItemsActionTypes.SET_TO_FINISHED_AMBULANCE_QUEUE_ITEM_FAILED,
      payload: { error }
    }
  },
  setItemToErrorRequested(patientUserRef: string) {
    return {
      type: ambulanceQueueItemsActionTypes.SET_TO_ERROR_AMBULANCE_QUEUE_ITEM_REQUESTED,
      payload: { patientUserRef }
    }
  },
  setItemToErrorSucceeded(patientUserRef: string) {
    return {
      type: ambulanceQueueItemsActionTypes.SET_TO_ERROR_AMBULANCE_QUEUE_ITEM_SUCCEEDED,
      payload: { patientUserRef }
    }
  },
  setItemToErrorFailed(error: Error) {
    return {
      type: ambulanceQueueItemsActionTypes.SET_TO_ERROR_AMBULANCE_QUEUE_ITEM_FAILED,
      payload: { error }
    }
  },
  toggleShowAmbulanceItemsDialog() {
    return {
      type: ambulanceQueueItemsActionTypes.TOGGLE_SHOW_AMBULANCE_ITMES_QUEUE_DIALOG
    }
  },
  navigateToConversationOfItem(item: AmbulanceQueueItemDtoType, invite: boolean = true) {
    return {
      type: ambulanceQueueItemsActionTypes.NAVIGATE_TO_CONVERSATION_OF_ITEM,
      payload: { item, invite }
    }
  }

}
