// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'
import { checkRoute } from '../../../../common/Security/utils'

export default (store: any) => ({
  path: 'appointment',
  name: (
    <span id='breadcrumb1'>
      <Translate value='menu.appointments' />
    </span>
  ),
  onEnter: (state: any, replace: Function) => {
    checkRoute(store.getState(), state.location.pathname, replace)
  },

  getComponent(nextState: any, cb: Function) {
    (require: any).ensure(
      [],
      (require) => {
        const container = require('./containers/Appointments').default
        cb(null, container)
      },
      'appointment'
    )
  }
})
