// @flow
import { connect } from 'react-redux'
import { surveyActions } from 'common-docdok/src/domain/survey/actions'
import pathOr from 'ramda/src/pathOr'
import { studiesActions } from '../actions'
import Study from '../components/Study'

import { scheduleSurveyDialogActions } from '../../../../../common/ScheduleSurveyDialog/actions'

export const selectStudyPhysicians = pathOr([], ['entityLoader', 'studyPhysicians', 'result'])

const mapStateToProps = (state: StoreType, ownProps: Object) => ({
  study: state.survey.studies[ownProps.params.studyId],
  ...state.studies,
  physicians: selectStudyPhysicians(state)
})

export default connect(
  mapStateToProps,
  {
    ...surveyActions,
    ...studiesActions,
    setStudySurveySchedule: scheduleSurveyDialogActions.setStudySurveySchedule
  }
)(Study)
