// @flow
import React from 'react'
import classnames from 'classnames'
import { Translate } from 'react-redux-i18n'

import IconButton from '@material-ui/core/IconButton'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MenuIcon from '@material-ui/icons/Menu'

import { isFunction } from 'common-docdok/src/utils/typeCheck'
import { isActionVisible } from '../../Security/containers/action'

import './style.scss'

export type TableActionType = {
  key?: string,
  icon: any,
  name: string,
  tooltipKey: string,
  enabled?: (row: any) => boolean,
  hidden?: (row: any) => boolean,
  action: (row: any) => void
}

type PropsType = {
  children: any,
  className: string,
  background?: string,
  row?: Object,
  actions?: Array<TableActionType>,
  onRowClick?: (row: any) => void
}

type StateType = { opacity: number, anchorEl: any }

export default class TableRow extends React.Component<PropsType, StateType> {
  state = { opacity: 1, anchorEl: null }

  timeout: TimeoutID

  static defaultProps = {
    row: undefined,
    actions: undefined,
    background: 'white',
    onRowClick: () => {}
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({ opacity: undefined })
    }, 1000)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  isHidden = (action: TableActionType, row?: Object): boolean => Boolean(action.hidden && action.hidden(row))

  renderAction = (actionList: Array<TableActionType>, row?: Object) => {
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)

    const allowedAction = actionList.filter(a => isActionVisible(a.name))

    if (allowedAction.length === 0) {
      return null
    }

    return (
      <div>
        <IconButton
          aria-label='More'
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup='true'
          onClick={this.handleClick}
          style={{ paddingTop: '7px' }}
          data-test='rowMenuButton'
        >
          <MenuIcon />
        </IconButton>

        <Menu id='long-menu' anchorEl={anchorEl} open={open} onClose={this.handleClose}>
          {allowedAction
            .filter(a => !this.isHidden(a, row))
            .map((a, index) => {
              const actionKey = a.key ? a.key : a.name
              const key = `${actionKey}-${index}`
              const disabled = a.enabled && !a.enabled(row)
              const onClick = disabled ? () => {} : () => a.action(row)
              const icon = isFunction(a.icon) ? a.icon(row) : a.icon

              return (
                <MenuItem key={key} onClick={onClick} disabled={disabled} data-test={`${actionKey}-action`}>
                  {icon}
                  <Translate value={a.tooltipKey} style={{ paddingLeft: '5px' }} />
                </MenuItem>
              )
            })}
        </Menu>
      </div>
    )
  }

  render() {
    const { children, className, background, actions, row, onRowClick } = this.props
    const showOverlay = row !== undefined
    const actionList: Array<TableActionType> = actions || []
    const newChildren = React.Children.map(children, c => React.cloneElement(c, {
      onDoubleClick: () => {
        if (onRowClick) onRowClick(row)
      }
    })
    )

    return (
      <div className={classnames('rt-tr', className)} style={{ background }}>
        {newChildren}
        {showOverlay && (
          <div
            style={{ opacity: this.state.opacity, display: 'flex' }}
            className={classnames('action-overlay', className)}
          >
            {this.renderAction(actionList, row)}
          </div>
        )}
      </div>
    )
  }
}
