// @flow
import pathEq from 'ramda/src/pathEq'
import type { ConversationStateType } from '../reducers/messaging'

export default function (state: any, patientRef: string): ?number {
  if (!patientRef) return undefined

  let conversationId

  const conversations = Object.values(state.messaging.conversations)
  if (conversations) {
    const primaryConvs: any = conversations.filter((c: any) => {
      const conversation: ConversationStateType = c
      const isPrimary = pathEq(['meta', 'primary'], 'true', conversation)
      const subjectMatches = conversation.meta.healthcareSubject === patientRef
      return subjectMatches && isPrimary
    })
    if (primaryConvs.length > 0) {
      conversationId = primaryConvs[0].id
    }
  }
  return conversationId
}
