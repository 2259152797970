// @flow

export type KeycloakClientType = {
  getUserId: () => string,
  logout: () => void,
  getToken: () => string,
  refreshToken: () => Promise<*>,
  login: (cb: (authenticated: boolean) => void) => void,
  isTokenExpired: () => boolean
}

const nullObject = {
  getUserId: () => 'unkown',
  logout: () => {},
  getToken: () => 'notLoggedIn - set a keycloak implementation',
  refreshToken: () => Promise.resolve(),
  login: (cb: (authenticated: boolean) => void) => cb(true),
  isTokenExpired: () => false
}

const client = {}
export function setKeycloakImpl(impl: KeycloakClientType) {
  client.impl = impl
}

export function getKeycloakClient(): KeycloakClientType {
  if (client.impl) {
    return client.impl
  }
  return nullObject
}
