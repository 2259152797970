// @flow

export const eventHandlerDilogActionTypes = {
  SET_EVENT_HANDLER_DIALOG: 'SET_EVENT_HANDLER_DIALOG'
}
export type EventHandlerDiaglogActionsType = $Keys<typeof eventHandlerDilogActionTypes>
export const setEventDialogHandlerActions = {
  setEventDialogHandler(show: boolean) {
    return {
      type: eventHandlerDilogActionTypes.SET_EVENT_HANDLER_DIALOG,
      payload: show
    }
  }
}
