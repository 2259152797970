export default {
  en: {
    joinConversation: 'Join Conversation',
    directMessages: 'Direct Messages',
    tabs: {
      progress: 'Progress',
      calendar: 'Calendar',
      integration: 'Integration',
      surveys: 'Surveys',
      messaging: 'MESSAGING',
      casestreams: 'Case Streams',
      general: 'General Conversation',
      hycare: 'HyCare'
    },
    integration: {
      added: 'Added at',
      lastSync: 'Last time synchronized',
      lastCollelcted: 'Last time collected',
      addIntegration: 'Add Integration',
      devices: {
        fitbit: 'Fit-Bit',
        nuvoair: 'NuvoAir',
        biomotion: 'Biomotion'
      }
    },
    fitbit: {
      dailySteps: 'Daily Steps',
      weeklyAverage: 'Weekly Average',
      table: 'Table',
      chart: 'Chart'
    },
    patientData: {
      studies: 'Studies',
      personalData: 'Personal Data',
      contactDetails: 'Contact Details'
    },
    dataSharing:
      'Please note that the information entered here may be viewed by the health professional(s) linked to you.',
    dataSharingCoach:
      'Please note that the information entered here can be viewed by the patient/user',
    hycare: {
      noTargetSet: 'No Targets Set!',
      noTargetSetDesc:
        'In order to track your patient’s adherence to his/her disease management program,' +
        ' click the button below to set the appropriate health targets (BMI, Blood Pressure etc.).',
      targetNote:
        '**Note that only doctors can set the targets for the patient',
      setTarget: 'SET TARGETS',
      card: {
        medicalImpact: 'MEDICAL IMPACT',
        engagement: 'ENGAGEMENT',
        startDate: 'Start date',
        appUsed: 'App used for %{days} days (since %{date})',
        expectedMeasurement: 'Expected measurement',
        measurementNotReported: 'Measurement not reported',
        updated: 'Updated',
        target: 'Target',
        details: 'DETAILS',
        accordingTo: 'According to SCORE2-OP risk calculator',
        mediumRisk: 'MEDIUM RISK',
        lowRisk: 'LOW RISK',
        highRisk: 'HIGH RISK',
        riskNotSpecified: 'RISK NOT SPECIFIED',
        notSpecified: 'Not specified',
        na: 'N/A',
        notSet: 'Not set',
        bloodPressure: {
          title: 'Blood Pressure'
        },
        bmi: {
          title: 'BMI'
        },
        steps: {
          title: 'Steps'
        },
        hba1c: {
          title: 'HbA1c'
        },
        cardiovascularRiskScore: {
          title: 'Cardiovascular Risk Score'
        },
        potentialRiskFactors: {
          title: 'Potential risk factors',
          button: 'REVIEW ALL FACTORS',
          desc: 'Potential risk factors based on patient answers collected as of %{date}'
        },
        logins: {
          title: 'Logins / Surveys',
          logins: 'Logins',
          surveyCompleted: 'Surveys Completed'
        }
      },
      notification: 'Please set health targets for %{name}'
    }
  },
  de: {
    joinConversation: 'Konversation beitreten',
    directMessages: 'Direktnachrichten',
    tabs: {
      progress: 'Fortschritt',
      calendar: 'Kalender',
      integration: 'Integration',
      surveys: 'Umfragen',
      messaging: 'Chat',
      casestreams: 'Case Streams',
      general: 'Allgemeine Konversation',
      hycare: 'HyCare'
    },
    integration: {
      added: 'Hinzugefügt am',
      lastSync: 'Letzte Synchronisation',
      lastCollelcted: 'Letzte Datensammlung',
      addIntegration: 'Integration Hinzufügen',
      devices: {
        fitbit: 'Fit-Bit',
        cair: 'CAir'
      }
    },
    fitbit: {
      weeklyAverage: 'Wöchentlicher Durschnitt',
      dailySteps: 'Tägliche Schritte',
      table: 'Tabelle',
      chart: 'Diagramm'
    },
    patientData: {
      studies: 'Studien',
      personalData: 'Personalien',
      contactDetails: 'Kontaktinformationen'
    },
    dataSharing: `Bitte nehmen Sie zur Kenntnis, dass die hier eingegebenen Angaben von der mit 
Ihnen verknüpften Gesundheitsfachperson/en eingesehen werden können.`,
    dataSharingCoach:
      'Bitte nehmen Sie zur Kenntnis, dass die hier eingegebenen Angaben vom Patienten/User eingesehen werden können',
    hycare: {
      noTargetSet: 'Noch keine Zielwerte festgelegt!',
      noTargetSetDesc:
        'Um einen besseren Überblick über die Fortschritte vom Patient/' +
        'in innerhalb des Programms zu erhalten,' +
        ' klicken Sie auf die Schaltfläche unten, um die entsprechenden Zielwerte (BMI, Blutdruck usw.) festzulegen.',
      targetNote:
        '**Hinweis: Nur Ärzte können die Zielwerte für den Patient/in festlegen',
      setTarget: 'ZIELWERTE FESTLEGEN',
      card: {
        medicalImpact: 'MEDIZINISCHER IMPACT',
        engagement: 'ENGAGEMENT',
        startDate: 'Startdatum',
        appUsed: 'App used for %{days} days (since %{date})',
        expectedMeasurement: 'Erwarteter Messwert',
        measurementNotReported: 'Messwert nicht dokumentiert',
        updated: 'Aktualisiert',
        target: 'Zielwert',
        details: 'DETAILS',
        accordingTo: 'Gemäss SCORE2-OP Risikorechner',
        mediumRisk: 'MITTLERES RISIKO',
        lowRisk: 'GERINGES RISIKO',
        highRisk: 'HOHES RISIKO',
        riskNotSpecified: 'RISIKO NICHT ANGEGEBEN',
        notSpecified: 'Nicht angegeben',
        na: 'N/A',
        notSet: 'Not set',
        bloodPressure: {
          title: 'Blutdruck'
        },
        bmi: {
          title: 'BMI'
        },
        steps: {
          title: 'Schritte'
        },
        hba1c: {
          title: 'HbA1c'
        },
        cardiovascularRiskScore: {
          title: 'Kardiovaskulärer Risiko Score'
        },
        potentialRiskFactors: {
          title: 'Mögliche Risikofaktoren',
          button: 'ÜBERSICHT ALLER EINFLUSSFAKTOREN'
        },
        logins: {
          title: 'Logins / Umfragen',
          logins: 'Logins',
          surveyCompleted: 'Abgeschlossene Umfragen'
        }
      },
      notification: 'Bitte legen Sie die Zielwerte für %{name} fest'
    }
  }
}
