// @flow
import type { ExtractReturn } from '../../../types'
import { entityLoaderActionTypes as types, entityLoaderActions as actions } from '../actions'
import type { EntityLoaderActionType } from '../actions'

export type EntityLoaderType = {
  id: string,
  loading: boolean,
  result: Array<Object>,
  api: Function,
  orderBy?: ?string,
  page: number,
  pageSize: number,
  total: number,
  refreshActions?: ?Array<string>,
  searchTerm?: ?string,
  params: Array<*>
}

export type EntityLoaderStateType = {
  [id: string]: EntityLoaderType
}

// Default State
const initialState = {}

// Action Handlers

function getEntitiesRequested(
  state: EntityLoaderStateType,
  action: ExtractReturn<typeof actions.getEntitiesRequested>
): EntityLoaderStateType {
  const { payload, payload: { id } } = action
  const newState = {
    ...state,
    [id]: {
      ...state[id],
      ...payload,
      result: [],
      loading: true
    }
  }

  return newState
}

function getEntitiesSuccess(
  state: EntityLoaderStateType,
  action: ExtractReturn<typeof actions.getEntitiesSucceeded>
): EntityLoaderStateType {
  const { id, result, total } = action.payload
  const newState = {
    ...state,
    [id]: {
      ...state[id],
      loading: false,
      result,
      total
    }
  }
  return newState
}

function getEntitiesFailed(
  state: EntityLoaderStateType,
  action: ExtractReturn<typeof actions.getEntitiesSucceeded>
): EntityLoaderStateType {
  const { id } = action.payload
  const newState = {
    ...state,
    [id]: {
      ...state[id],
      loading: false,
      result: [],
      total: undefined
    }
  }
  return newState
}

const ACTION_HANDLERS: { [key: EntityLoaderActionType]: any } = {
  [types.GET_ENTITIES_SUCCEEDED]: getEntitiesSuccess,
  [types.GET_ENTITIES_REQUESTED]: getEntitiesRequested,
  [types.GET_ENTITIES_FAILED]: getEntitiesFailed
}

type ActionType = { type: EntityLoaderActionType }

export default function entityLoaderReducer(
  state: EntityLoaderStateType = initialState,
  action: ActionType
): EntityLoaderStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
