export default {
  de: {
    name: 'Analyse %{name}',
    fields: {
      text: 'Antwort',
      count: 'Anzahl',
      percentage: 'Prozent'
    }
  },
  en: {
    name: 'Analytics %{name}',
    fields: {
      text: 'Response',
      count: 'Anzahl',
      percentage: 'Prozent'
    }
  }
}
