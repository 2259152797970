// @flow
import type { ActionType } from 'common-docdok/src/types'
import { createPatientButtonActionTypes } from '../actions'

// State Definition

export type CreatePatientButtonStateType = {
  showCreatePatientDialog: boolean,
  showEventHandlerForm: boolean,
}

// Default State
const initialState: CreatePatientButtonStateType = {
  showCreatePatientDialog: false,
  showEventHandlerForm: false
}

// Action Handlers

function togglePatientDialog(state: CreatePatientButtonStateType): CreatePatientButtonStateType {
  return {
    ...state,
    showCreatePatientDialog: !state.showCreatePatientDialog
  }
}


function toggleEventFormDialog(state: PatientsStateType): PatientsStateType {
  return {
    ...state,
    showEventHandlerForm: !state.showEventHandlerForm
  }
}

const ACTION_HANDLERS = {
  [createPatientButtonActionTypes.TOGGLE_CREATE_PATIENT_DIALOG]: togglePatientDialog,
  [createPatientButtonActionTypes.TOGGLE_CREATE_EVENT_DIALOG]: toggleEventFormDialog
}

export default function patientsReducer(
  state: CreatePatientButtonStateType = initialState,
  action: ActionType
): CreatePatientButtonStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
