// @flow
import React, { Component } from 'react'
import { browserHistory, Router } from 'react-router'
import { Provider } from 'react-redux'
import { ThemeProvider } from '../styles/muiTheme'

type PropsType = {
  routes: any,
  store: any
}

class AppContainer extends Component<PropsType> {
  shouldComponentUpdate() {
    return false
  }

  render() {
    const { routes, store } = this.props

    return (
      <ThemeProvider>
        <Provider id='provider' store={store}>
          <div style={{ height: '100%' }}>
            <Router history={browserHistory}>{routes}</Router>
          </div>
        </Provider>
      </ThemeProvider>
    )
  }
}

export default AppContainer
