// @flow

function values<T>(obj: { [key: any]: T }): Array<T> {
  return Object.keys(obj).map(k => obj[k])
}

const textFilter = (currentValue: string, filter: string) => {
  let result = true
  if (filter !== '') {
    result = currentValue.toLowerCase().indexOf(filter.toLowerCase()) > -1
  }
  return result
}

const has = (name: string) => (obj: Object) => obj[name] !== undefined && obj[name] !== null
const hasNot = (name: string) => (obj: Object) => obj[name] === undefined || obj[name] === null

export default {
  values,
  textFilter,
  has,
  hasNot
}
