// @flow

import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { fields } from 'common-docdok/src/domain/survey/types/studyDto'
import type { UserDtoType } from 'common-docdok/src/domain/user/types/userDto'
import { Translate } from 'react-redux-i18n'
import type { StudySummaryDtoType } from 'common-docdok/src/domain/survey/types/studySummaryDto'
import { roles } from 'common-docdok/src/domain/user/types/userDto'
import api from 'common-docdok/src/api'
import type { StudyDoctorUserType } from 'common-docdok/src/api/survey'
import IconButton from '@material-ui/core/IconButton'
import { browserHistory } from 'react-router'
import Assignment from '@material-ui/icons/Assignment'
import ViewList from '@material-ui/icons/ViewList'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'

import { surveyActions, surveyActionTypes } from 'common-docdok/src/domain/survey/actions'
import StudySurveys from './studySurveys'
import StudyDevices from './StudyDevices'
import Range from './Range'
import { studiesActions } from '../actions'
import DateRenderer from '../../../../../common/DateRenderer/components/dateRenderer'
import PersonViewContainer from './PersonView'
import StudyCoordinator from './StudyCoordinator'
import DynamicFormDialog from '../../../../../common/DynamicFormDialog'
import type { StudiesStateType } from '../reducers'
import {
  ADD_STUDY_PHYSICIAN,
  ADD_STUDY_COORDINATOR,
  SET_STUDY_STATUS,
  EXPORT_STUDY_DATA,
  DELETE_STUDY
} from '../../../../../common/Security/actionNames'
import AddPhysicianDialog from './addPhysicianDialog'
import Path from '../../../../../common/Security/containers/path'
import Action, { isActionVisible } from '../../../../../common/Security/containers/action'
import { RaisedButton, TypographyLight, muiStyled } from '../../../../../common/Mui'
import { DEFAULT_SPACING } from '../../../../../layouts/CoreLayout/components/CoreLayout'
import { Container, Spacer, GreyBar, Toolbar, ToolbarGroup } from '../../../../../common/Static'
import ConfirmationDialog from '../../../../../common/Confirmation/components/ConfirmationDialog'
import CreateUserButton from '../../../../../common/CreateUserButton'
import { scheduleSurveyDialogActions } from '../../../../../common/ScheduleSurveyDialog/actions'
import AddStudyParticipantCriteriaButton from './AddStudyParticipationCriteriaButton'
import { renderAndClickHiddenDownloadLink } from '../../../../../common/utils'

const StudyAvatar = muiStyled(Avatar)({
  height: 70,
  width: 70,
  fontSize: 50
})

const ActionsWrapper = styled.div`
  margin-bottom: 5px;
`

type PropsType = {
  study: StudySummaryDtoType,
  params: {
    studyId: number
  },
  getStudyRequested: typeof surveyActions.getStudyRequested,
  toggleAddSurveyDialog: typeof studiesActions.toggleAddSurveyDialog,
  toggleAddStudyCoordinatorDialog: typeof studiesActions.toggleAddStudyCoordinatorDialog,
  children: any,
  removeStudySurveyRequested: typeof surveyActions.removeStudySurveyRequested,
  updateStudyRequested: typeof surveyActions.updateStudyRequested,
  removeStudyPhysicianRequested: typeof surveyActions.removeStudyPhysicianRequested,
  physicians: Array<UserDtoType>,
  toggleAddPhysicianDialog: typeof studiesActions.toggleAddPhysicianDialog,
  setAddStudyDeviceDialog: typeof studiesActions.setAddStudyDeviceDialog,
  downloadStudyDataRequested: typeof studiesActions.downloadStudyDataRequested,
  deleteStudyRequested: typeof surveyActions.deleteStudyRequested,
  setStudySurveySchedule: typeof scheduleSurveyDialogActions.setStudySurveySchedule
} & StudiesStateType

type StateType = {
  confirmDeleteStudy: boolean
}

export default class Study extends React.Component<PropsType, StateType> {
  state = {
    confirmDeleteStudy: false
  }

  componentDidMount() {
    const {
      params: { studyId },
      getStudyRequested
    } = this.props
    getStudyRequested(studyId)
  }

  render() {
    const {
      study,
      toggleAddSurveyDialog,
      showAddSurveyDialog,
      children,
      removeStudySurveyRequested,
      updateStudyRequested,
      toggleAddPhysicianDialog,
      toggleAddStudyCoordinatorDialog,
      showAddPhysicianDialog,
      showAddStudyCoordinatorDialog,
      removeStudyPhysicianRequested,
      physicians,
      setAddStudyDeviceDialog,
      showAddStudyDevice,
      setAddParticipationCriteriaDialog,
      showAddParticipationCriteriaDialog,
      downloadStudyDataRequested,
      deleteStudyRequested,
      setStudySurveySchedule
    } = this.props

    const { confirmDeleteStudy } = this.state

    if (children) return children
    if (!study) return null
    return (
      <div style={{ flexGrow: 1 }}>
        <AddPhysicianDialog
          studyId={study.id}
          physicians={physicians}
          toggleAddPhysicianDialog={toggleAddPhysicianDialog}
          showAddPhysicianDialog={showAddPhysicianDialog}
        />
        <DynamicFormDialog
          open={showAddStudyCoordinatorDialog}
          handleClose={() => {
            toggleAddStudyCoordinatorDialog()
          }}
          titleKey='studies.actions.addStudyCoordinator.title'
          form='addStudyCoordinator'
          saveLabelKey='actions.add'
          addionalButtons={<CreateUserButton />}
          initialValues={{ study }}
          fields={{
            coordinators: {
              specName: 'userByRole',
              type: 'search',
              mandatory: true,
              multiSelectable: false,
              params: [roles.studyCoordinator]
            }
          }}
        />
        <Card style={{ marginBottom: DEFAULT_SPACING }}>
          <CardHeader
            avatar={(
              <StudyAvatar>
                <Assignment fontSize='inherit' />
              </StudyAvatar>
)}
            action={(
              <ActionsWrapper>
                <div style={{ marginBottom: 2, display: 'flex' }}>
                  <TextField
                    select
                    label={<Translate value='domain.entity.study.fields.status' />}
                    value={study.status}
                    onChange={(event) => {
                      const status = event.target.value
                      updateStudyRequested({ ...study, status })
                    }}
                    inputProps={{ 'data-test': 'study-status' }}
                    disabled={!isActionVisible(SET_STUDY_STATUS)}
                  >
                    {Object.values(fields.status.enum).map((e: any) => (
                      <MenuItem key={e} value={e}>
                        <Translate value={`domain.entity.study.fields.statusOpts.${e}`} />
                      </MenuItem>
                    ))}
                  </TextField>
                  <Action name={DELETE_STUDY}>
                    <RaisedButton
                      style={{ marginTop: 10, marginRight: 10 }}
                      disabled={study.status !== 'FINISHED'}
                      onClick={() => {
                        this.setState({ confirmDeleteStudy: true })
                      }}
                      data-test='delete-study-button'
                      label={<Translate value='studies.actions.deleteStudy.title' />}
                    />

                    <ConfirmationDialog
                      open={confirmDeleteStudy}
                      message={<Translate value='infos.areYouSure' />}
                      onConfirm={(doConfirm) => {
                        this.setState({ confirmDeleteStudy: false })
                        if (doConfirm) {
                          deleteStudyRequested(study.id)
                        }
                      }}
                    />
                  </Action>
                </div>
                <div style={{ float: 'right', marginRight: 10 }}>
                  <Action name={EXPORT_STUDY_DATA}>
                    <RaisedButton
                      onClick={() => {
                        // This is ugly but they want it for OKL
                        if (study.key && study.key === 'OKLARCHIVE') {
                          renderAndClickHiddenDownloadLink(
                            '/okl/OKL_QGPVXIRYLB.zip',
                            'OKL Archived Export Files.zip'
                          )
                        } else {
                          downloadStudyDataRequested(study)
                        }
                      }}
                      id='change-study-status-button'
                      label={<Translate value='studies.actions.exportStudyData.title' />}
                    />
                  </Action>
                </div>
              </ActionsWrapper>
)}
            title={(
              <Typography variant='h4' id='study-name'>
                {study ? study.name : ''}
              </Typography>
)}
            subheader={(
              <div>
                <Translate value='domain.entity.study.fields.createdDate' />
                :&nbsp;
                <DateRenderer id='created-date' value={study.createdDate} />
              </div>
)}
          />
        </Card>
        <Container style={{ flexGrow: 1 }}>
          <div style={{ flexGrow: 1 }}>
            <StudySurveys
              setStudySurveySchedule={setStudySurveySchedule}
              studyId={study.id}
              showAddSurveyDialog={showAddSurveyDialog}
              toggleAddSurveyDialog={toggleAddSurveyDialog}
              removeStudySurveyRequested={removeStudySurveyRequested}
            />
            <Spacer height={5} />
            <StudyDevices
              study={study}
              setAddStudyDeviceDialog={setAddStudyDeviceDialog}
              showAddStudyDevice={showAddStudyDevice}
              updateStudyRequested={updateStudyRequested}
            />
          </div>

          <Card>
            <GreyBar>
              <Toolbar>
                <ToolbarGroup>
                  <TypographyLight variant='h6'>
                    <Translate value='StudyParticipants' />
                  </TypographyLight>
                </ToolbarGroup>
                <div style={{ float: 'right' }}>
                  <AddStudyParticipantCriteriaButton
                    setAddParticipationCriteriaDialog={setAddParticipationCriteriaDialog}
                    showAddParticipationCriteriaDialog={showAddParticipationCriteriaDialog}
                    study={study}
                  />
                </div>
              </Toolbar>
            </GreyBar>
            <div style={{ paddingLeft: 25, paddingTop: 5, paddingBottom: 25 }}>
              <PersonViewContainer
                api={() => api.getStudyPhysicians(study.id)}
                id='studyPhysicians'
                onRequestDelete={(user: StudyDoctorUserType) => removeStudyPhysicianRequested(user)}
                onRequestAdd={toggleAddPhysicianDialog}
                multi
                labelKey='studies.physicians.title'
                allUrl={`/private/app/studies/${study.id}/physicians`}
                defaultPageSize={3}
                refreshActions={[
                  surveyActionTypes.ADD_STUDY_PHYSICIAN_SUCCEEDED,
                  surveyActionTypes.REMOVE_STUDY_PHYSICIAN_SUCCEEDED
                ]}
                actionName={ADD_STUDY_PHYSICIAN}
                addTooltip='studies.actions.addStudyPhysicians.title'
              />
              <StudyCoordinator
                id='studyCoordinator'
                userRef={study.coordinatorRef}
                onRequestDelete={() => updateStudyRequested({ ...study, coordinatorRef: null })}
                onRequestAdd={toggleAddStudyCoordinatorDialog}
                labelKey='studies.studyCoordiantors.title'
                multi={false}
                total={study.coordinatorRef ? 1 : 0}
                allUrl={`/private/app/studies/${study.id}/studycoordinators`}
                actionName={ADD_STUDY_COORDINATOR}
                addTooltip='studies.actions.addStudyCoordinator.title'
              />
              <div style={{ display: 'flex' }}>
                <Range
                  id='target-participant-number'
                  floatingLabelText='domain.entity.study.fields.targetParticipantNumber'
                  width={300}
                  hintText='studies.targetParticipantNumberHint'
                  min={0}
                  max={study.targetParticipantNumber}
                  value={study.currentParticipantNumber}
                  onChange={(targetParticipantNumber: number) => updateStudyRequested({
                    ...study,
                    targetParticipantNumber
                  })
                  }
                />
                <Path name={`/studies/${study.id}/participants`}>
                  <IconButton
                    id='show-all-study-patients-button'
                    style={{ marginTop: 10 }}
                    onClick={() => {
                      browserHistory.push(`/private/app/studies/${study.id}/participants`)
                    }}
                    tooltip={<Translate value='studies.participants.title' />}
                  >
                    <ViewList />
                  </IconButton>
                </Path>
              </div>
              <Range
                id='target-physician-number'
                floatingLabelText='domain.entity.study.fields.targetPhysicianNumber'
                width={300}
                hintText='studies.targetPhysicianNumberHint'
                min={0}
                max={study.targetPhysicianNumber}
                value={study.currentPhysicianNumber}
                onChange={(targetPhysicianNumber: number) => updateStudyRequested({
                  ...study,
                  targetPhysicianNumber
                })
                }
              />
            </div>
          </Card>
        </Container>
      </div>
    )
  }
}
