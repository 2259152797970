// @flow

import type { UserDtoType, UserForCreationVmType } from '../../../types'
import type { PreferencesType } from '../types/preferences'
import type { MeDtoType } from '../types/meDto'

export const userActionTypes = {
  //      -- APPEND ACTION TYPES HERE --
  RESEND_INIT_SMS_REQUESTED: 'RESEND_INIT_SMS_REQUESTED',
  RESEND_INIT_SMS_SUCCEEDED: 'RESEND_INIT_SMS_SUCCEEDED',
  RESEND_INIT_SMS_FAILED: 'RESEND_INIT_SMS_FAILED',
  DISABLE_USER_REQUESTED: 'DISABLE_USER_REQUESTED',
  DISABLE_USER_SUCCEEDED: 'DISABLE_USER_SUCCEEDED',
  DISABLE_USER_FAILED: 'DISABLE_USER_FAILED',
  PROFILE_FETCH_REQUESTED: 'PROFILE_FETCH_REQUESTED',
  PROFILE_FETCH_SUCCEEDED: 'PROFILE_FETCH_SUCCEEDED',
  PROFILE_FETCH_FAILED: 'PROFILE_FETCH_FAILED',
  LOAD_USERS_REQUESTED: 'LOAD_USERS_REQUESTED',
  LOAD_USERS_SUCCEEDED: 'LOAD_USERS_SUCCEEDED',
  LOAD_USERS_FAILED: 'LOAD_USERS_FAILED',
  LOAD_MISSING_USERS_REQUESTED: 'LOAD_MISSING_USERS_REQUESTED',
  LOAD_MISSING_USERS_SUCCEEDED: 'LOAD_MISSING_USERS_SUCCEEDED',
  LOAD_MISSING_USERS_FAILED: 'LOAD_MISSING_USERS_FAILED',
  UPLOAD_AVATAR_REQUESTED: 'UPLOAD_AVATAR_REQUESTED',
  UPLOAD_AVATAR_SUCCEEDED: 'UPLOAD_AVATAR_SUCCEEDED',
  UPLOAD_AVATAR_FAILED: 'UPLOAD_AVATAR_FAILED',
  CREATE_USER_REQUESTED: 'CREATE_USER_REQUESTED',
  CREATE_USER_SUCCEEDED: 'CREATE_USER_SUCCEEDED',
  CREATE_USER_FAILED: 'CREATE_USER_FAILED',
  RENEW_INVITATION_REQUESTED: 'RENEW_INVITATION_REQUESTED',
  RENEW_INVITATION_SUCCEEDED: 'RENEW_INVITATION_SUCCEEDED',
  RENEW_INVITATION_FAILED: 'RENEW_INVITATION_FAILED',
  CHECK_TERMS_VERSION: 'CHECK_TERMS_VERSION',
  TERMS_VERSION_CHANGED: 'TERMS_VERSION_CHANGED',
  TERMS_VERSION_EQUALS: 'TERMS_VERSION_EQUALS',
  CHECK_TERMS_VERSION_ERR: 'CHECK_TERMS_VERSION_ERR',
  UPDATE_TERMS_VERSION_REQUESTED: 'UPDATE_TERMS_VERSION_REQUESTED',
  UPDATE_TERMS_VERSION_SUCCEEDED: 'UPDATE_TERMS_VERSION_SUCCEEDED',
  UPDATE_TERMS_VERSION_FAILED: 'UPDATE_TERMS_VERSION_FAILED',
  UPDATE_USER_PREFERENCES_REQUESTED: 'UPDATE_USER_PREFERENCES_REQUESTED',
  UPDATE_USER_PREFERENCES_SUCCEEDED: 'UPDATE_USER_PREFERENCES_SUCCEEDED',
  UPDATE_USER_PREFERENCES_FAILED: 'UPDATE_USER_PREFERENCES_FAILED',
  UPDATE_USER_REQUESTED: 'UPDATE_USER_REQUESTED',
  UPDATE_USER_SUCCEEDED: 'UPDATE_USER_SUCCEEDED',
  UPDATE_USER_FAILED: 'UPDATE_USER_FAILED',
  DELETE_USER_REQUESTED: 'DELETE_USER_REQUESTED',
  DELETE_USER_SUCCEEDED: 'DELETE_USER_SUCCEEDED',
  DELETE_USER_FAILED: 'DELETE_USER_FAILED'
}

export type UserActionType = $Keys<typeof userActionTypes>

export const userActions = {
  //  -- APPEND ACTIONS HERE --
  resendInitialPasswordSmsRequested(userRef: string) {
    return { type: userActionTypes.RESEND_INIT_SMS_REQUESTED, payload: userRef }
  },
  resendInitialPasswordSmsSucceeded() {
    return {
      type: userActionTypes.RESEND_INIT_SMS_SUCCEEDED
    }
  },
  resendInitialPasswordSmsFailed(error: Error) {
    return {
      type: userActionTypes.RESEND_INIT_SMS_FAILED,
      payload: { error }
    }
  },

  disableUserRequested(userRef: string) {
    return { type: userActionTypes.DISABLE_USER_REQUESTED, payload: userRef }
  },
  disableUserSucceeded() {
    return {
      type: userActionTypes.DISABLE_USER_SUCCEEDED
    }
  },
  disableUserFailed(error: Error) {
    return {
      type: userActionTypes.DISABLE_USER_FAILED,
      payload: { error }
    }
  },

  deleteUserRequested(userRef: string) {
    return { type: userActionTypes.DELETE_USER_REQUESTED, payload: userRef }
  },
  deleteUserSucceeded(payload?: any) {
    return {
      type: userActionTypes.DELETE_USER_SUCCEEDED,
      payload
    }
  },
  deleteUserFailed(error: Error) {
    return {
      type: userActionTypes.DELETE_USER_FAILED,
      payload: { error }
    }
  },

  profileFetchRequested() {
    return {
      type: userActionTypes.PROFILE_FETCH_REQUESTED
    }
  },

  profileFetchSucceeded(user: MeDtoType) {
    return {
      type: userActionTypes.PROFILE_FETCH_SUCCEEDED,
      payload: user
    }
  },

  profileFetchFailed(error: Error) {
    return {
      type: userActionTypes.PROFILE_FETCH_FAILED,
      payload: { error }
    }
  },

  loadUsersRequested(userIds: Array<string>) {
    return {
      type: userActionTypes.LOAD_USERS_REQUESTED,
      payload: userIds || []
    }
  },

  loadUsersSucceeded(users: Array<UserDtoType>) {
    return {
      type: userActionTypes.LOAD_USERS_SUCCEEDED,
      payload: users
    }
  },

  loadUsersFailed(error: Error) {
    return {
      type: userActionTypes.LOAD_USERS_FAILED,
      payload: { error }
    }
  },

  loadMissingUsersRequested(userIds: Array<string>) {
    return {
      type: userActionTypes.LOAD_MISSING_USERS_REQUESTED,
      payload: userIds || []
    }
  },

  loadMissingUsersSucceeded(users: Array<UserDtoType>) {
    return {
      type: userActionTypes.LOAD_MISSING_USERS_SUCCEEDED,
      payload: users
    }
  },

  loadMissingUsersFailed(error: Error) {
    return {
      type: userActionTypes.LOAD_MISSING_USERS_FAILED,
      payload: { error }
    }
  },

  uploadAvatarRequested(image: string) {
    return {
      type: userActionTypes.UPLOAD_AVATAR_REQUESTED,
      payload: image
    }
  },

  uploadAvatarSucceeded() {
    return {
      type: userActionTypes.UPLOAD_AVATAR_SUCCEEDED
    }
  },

  uploadAvatarFailed(error: Error) {
    return {
      type: userActionTypes.UPLOAD_AVATAR_FAILED,
      payload: { error }
    }
  },

  createUsersRequested(user: UserForCreationVmType) {
    return {
      type: userActionTypes.CREATE_USER_REQUESTED,
      payload: user
    }
  },

  createUserSucceeded(user: UserForCreationVmType) {
    return {
      type: userActionTypes.CREATE_USER_SUCCEEDED,
      payload: user
    }
  },

  createUserFailed(error: Error) {
    return {
      type: userActionTypes.CREATE_USER_FAILED,
      payload: { error }
    }
  },

  renewInvitationRequested(uid: string) {
    return {
      type: userActionTypes.RENEW_INVITATION_REQUESTED,
      payload: uid
    }
  },

  renewInvitationSucceeded(uid: string) {
    return {
      type: userActionTypes.RENEW_INVITATION_SUCCEEDED,
      payload: uid
    }
  },

  renewInvitationFailed(error: Error) {
    return {
      type: userActionTypes.RENEW_INVITATION_FAILED,
      payload: { error }
    }
  },

  checkTermsVersion() {
    return {
      type: userActionTypes.CHECK_TERMS_VERSION
    }
  },

  termsVersionChanged(termsVersion: string) {
    return {
      type: userActionTypes.TERMS_VERSION_CHANGED,
      payload: termsVersion
    }
  },

  checkTermsVersionError(error: Error) {
    return {
      type: userActionTypes.CHECK_TERMS_VERSION_ERR,
      payload: { error }
    }
  },

  termsVersionEquals() {
    return {
      type: userActionTypes.TERMS_VERSION_EQUALS
    }
  },

  updateTermsVersionRequested(termsVersion: string) {
    return {
      type: userActionTypes.UPDATE_TERMS_VERSION_REQUESTED,
      payload: termsVersion
    }
  },

  updateTermsVersionSucceeded(termsVersion: string) {
    return {
      type: userActionTypes.UPDATE_TERMS_VERSION_SUCCEEDED,
      payload: termsVersion
    }
  },

  updateTermsVersionFailed(error: Error) {
    return {
      type: userActionTypes.UPDATE_TERMS_VERSION_FAILED,
      payload: { error }
    }
  },

  updateUserPreferencesRequested(payload: PreferencesType) {
    return {
      type: userActionTypes.UPDATE_USER_PREFERENCES_REQUESTED,
      payload
    }
  },

  updateUserPreferencesSucceeded(payload: PreferencesType) {
    return {
      type: userActionTypes.UPDATE_USER_PREFERENCES_SUCCEEDED,
      payload
    }
  },

  updateUserPreferencesFailed(error: Error) {
    return {
      type: userActionTypes.UPDATE_USER_PREFERENCES_FAILED,
      payload: { error }
    }
  },

  updateUserRequested(payload: UserDtoType) {
    return {
      type: userActionTypes.UPDATE_USER_REQUESTED,
      payload
    }
  },

  updateUserSucceeded(payload: UserDtoType) {
    return {
      type: userActionTypes.UPDATE_USER_SUCCEEDED,
      payload
    }
  },

  updateUserFailed(error: Error) {
    return {
      type: userActionTypes.UPDATE_USER_FAILED,
      payload: { error }
    }
  }
}
