/**
 * @flow
 */

export const entityLoaderActionTypes = {
  GET_ENTITIES_REQUESTED: 'GET_ENTITIES_REQUESTED',
  GET_ENTITIES_SUCCEEDED: 'GET_ENTITIES_SUCCEEDED',
  GET_ENTITIES_FAILED: 'GET_ENTITIES_FAILED'
}

export type EntityLoaderActionType = $Keys<typeof entityLoaderActionTypes>

export const entityLoaderActions = {
  getEntitiesRequested(
    id: string,
    api: Function,
    pageSize: number,
    page: number,
    searchTerm?: ?string,
    orderBy?: ?string,
    refreshActions?: ?Array<string>,
    params: Array<*> = []
  ) {
    return {
      type: entityLoaderActionTypes.GET_ENTITIES_REQUESTED,
      payload: { id, api, pageSize, page, searchTerm, orderBy, refreshActions, params }
    }
  },

  getEntitiesSucceeded(id: string, result: Array<Object>, total: number) {
    return {
      type: entityLoaderActionTypes.GET_ENTITIES_SUCCEEDED,
      payload: {
        id,
        result,
        total
      }
    }
  },

  getEntitiesFailed(id: string, error: Error) {
    return {
      type: entityLoaderActionTypes.GET_ENTITIES_FAILED,
      payload: { error, id }
    }
  }
}
