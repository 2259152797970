// @flow
import React from 'react'
import { connect } from 'react-redux'
import compose from 'ramda/src/compose'
import { userActions } from 'common-docdok/src/domain/user/actions'
import { healthrelationActions } from 'common-docdok/src/domain/healthrelation/actions'
import type { ActionType } from 'common-docdok/src/types'
import { surveyActions } from 'common-docdok/src/domain/survey/actions'

type ReferencePropsType = {
  profRef?: string,
  userRef?: string,
  patientUuid?: string,
  clinicId?: string,
  surveyDefinitionId?: number
}

export type CachedEntityPropsType = {
  entity: ?Object,
  dispatch: (action: ActionType) => void,
  isLoading: boolean
} & ReferencePropsType

const mapStateToProps = (state: StoreType, ownProps: ReferencePropsType) => {
  const { userRef, profRef, patientUuid, clinicId, surveyDefinitionId } = ownProps
  let entity
  const { isLoading } = state.loadingStack
  if (userRef) {
    entity = state.userCache.users[userRef]
  } else if (profRef) {
    entity = state.healthrelationCache.professionals[profRef]
  } else if (patientUuid) {
    entity = state.healthrelationCache.patients[patientUuid]
  } else if (clinicId) {
    entity = state.healthrelationCache.clinics[clinicId]
  } else if (surveyDefinitionId) {
    entity = state.survey.surveyDefinitionsById[surveyDefinitionId]
  }
  return { entity, isLoading }
}

function cachedEntity(
  WrappedComponent: React$ComponentType<ReferencePropsType>
): React$ComponentType<ReferencePropsType & CachedEntityPropsType> {
  return class HOC extends React.Component<ReferencePropsType & CachedEntityPropsType> {
    static loadEntity(props: ReferencePropsType & CachedEntityPropsType) {
      const { dispatch, userRef, profRef, entity, patientUuid, isLoading, clinicId, surveyDefinitionId } = props
      if (!isLoading) {
        if (userRef && !entity) dispatch(userActions.loadMissingUsersRequested([userRef]))
        if (profRef && !entity) {
          dispatch(healthrelationActions.loadMissingProfessionals([profRef]))
        }
        if (patientUuid && !entity) {
          dispatch(healthrelationActions.getPatientDtoRequested(patientUuid))
        }
        if (clinicId && !entity) {
          dispatch(healthrelationActions.getClinicRequested(clinicId))
        }
        if (surveyDefinitionId && !entity) {
          dispatch(surveyActions.getSurveyDefinitionsByIdRequested([surveyDefinitionId]))
        }
      }
    }

    componentDidMount() {
      HOC.loadEntity(this.props)
    }

    componentWillReceiveProps(newProsp: ReferencePropsType & CachedEntityPropsType) {
      if (
        this.props.isLoading !== newProsp.isLoading
        || this.props.userRef !== newProsp.userRef
        || this.props.profRef !== newProsp.profRef
        || this.props.patientUuid !== newProsp.patientUuid
        || this.props.surveyDefinitionId !== newProsp.surveyDefinitionId
        || this.props.clinicId !== newProsp.clinicId
      ) {
        HOC.loadEntity(newProsp)
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}

export default compose(
  connect(mapStateToProps),
  cachedEntity
)
