// @flow
import type { ExtractReturn } from 'common-docdok/src/types'
import type { AmbulanceQueueItemDtoType } from 'common-docdok/src/domain/healthrelation/types/ambulanceQueueItemDto'
import { getKeycloakClient } from 'common-docdok/src/common/Keycloak/keycloakProvider'
import { userActionTypes, userActions } from 'common-docdok/src/domain/user/actions'
import type { UserActionType } from 'common-docdok/src/domain/user/actions'
import { roles } from 'common-docdok/src/domain/user/types/userDto'
import { ambulanceQueueItemsActionTypes as types, ambulanceQueueItemsActions as actions } from '../actions'
import type { AmbulanceQueueItemsActionType } from '../actions'

export type AmbulanceQueueSummaryType = {
  waiting: number,
  progress: number,
  assigned2me: number
}

export type AmbulanceQueueStateType = {
  queueItems: Array<AmbulanceQueueItemDtoType>,
  waitingPosition: number,
  showAmbulanceItemsDialog: boolean,
  summary: AmbulanceQueueSummaryType,
  showAddToWaitingRoom: boolean,
  showAddToWaitingRoomDialog: boolean,
  isVinzenz: boolean,
  isPatient: boolean
}

const initialState: AmbulanceQueueStateType = {
  queueItems: [],
  waitingPosition: -1,
  summary:  { waiting:0, progress:0, assigned2me:0 },
  showAmbulanceItemsDialog: false,
  showAddToWaitingRoom: false,
  showAddToWaitingRoomDialog: true,
  isVinzenz: false,
  isPatient: false

}

const reduceFn = (prevSummary, item: AmbulanceQueueItemDtoType): AmbulanceQueueSummaryType => {
  const userRef = getKeycloakClient().getUserId()

  const summary = { ...prevSummary }
  if (item.state === 'WA') {
    summary.waiting += 1
  } else if (item.state === 'IN' && userRef !== item.userRefPhysician) {
    summary.progress += 1
  } else if (item.state === 'IN' && userRef === item.userRefPhysician) {
    summary.assigned2me += 1
  }

  return summary
}


// Action Handlers
function getAmbulanceQueueItemsSucceeded(
  state: AmbulanceQueueStateType,
  action: ExtractReturn<typeof actions.getAmbulanceQueueItemsSucceeded>
): AmbulanceQueueStateType {
  const intialSummary = { waiting: 0, progress: 0, assigned2me: 0 }
  const queueItems = action.payload


  const summary = queueItems.reduce(reduceFn, intialSummary)

  return {
    ...state,
    queueItems: action.payload,
    summary
  }
}

function toggleCaseDialog(state: AmbulanceQueueStateType): AmbulanceQueueStateType {
  return {
    ...state,
    showAmbulanceItemsDialog: !state.showAmbulanceItemsDialog
  }
}

type ProfileFetchActionType = ExtractReturn<typeof userActions.profileFetchSucceeded>
function enableAdd2WaitingRoomDialog(state: AmbulanceQueueStateType,
  action: ProfileFetchActionType): AmbulanceQueueStateType {
  const { origin, authorities } = action.payload
  const isPatient = authorities.indexOf(roles.patient) > -1
  const isVinzenz = origin === 'VINZENZGRUPPE'

  const showAddToWaitingRoom = isPatient && isVinzenz
  return { ...state, showAddToWaitingRoom, isPatient, isVinzenz }
}

function closeAdd2WaitingRoomDialog(state: AmbulanceQueueStateType) {
  return { ...state, showAddToWaitingRoomDialog: false }
}

function getAmbulanceWaitingPositionSucceeded(state: AmbulanceQueueStateType, { payload: { position } }) {
  return { ...state, waitingPosition: position }
}


const ACTION_HANDLERS: { [key: AmbulanceQueueItemsActionType | UserActionType]: Function } = {
  [types.GET_AMBULANCE_QUEUE_ITEMS_SUCCEEDED]: getAmbulanceQueueItemsSucceeded,
  [types.TOGGLE_SHOW_AMBULANCE_ITMES_QUEUE_DIALOG]: toggleCaseDialog,
  [types.CLOSE_ADD_2_WAITINGROOM_DIALOG]: closeAdd2WaitingRoomDialog,
  [types.GET_AMBULANCE_WAITING_POSITION_SUCCEEDED]: getAmbulanceWaitingPositionSucceeded,
  [userActionTypes.PROFILE_FETCH_SUCCEEDED]: enableAdd2WaitingRoomDialog
}

type ActionType = { type: AmbulanceQueueItemsActionType }
export default function ambulanceQueueItemsReducer(
  state: AmbulanceQueueStateType = initialState,
  action: ActionType
): AmbulanceQueueStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
