export default {
  en: {
    tab: 'Devices',
    daily: 'Daily [d]',
    dailyX: 'daytime [h]',
    monthly: 'Monthly',
    monthlyX: 'day',
    deviceUsage: 'Device Usage',
    nuvoair: {
      name: 'NuvoAir',
      usageUnit: 'Spirometry\nSessions [-]',
      lungFunction: 'Lung Function',
      exhalationCurve: 'Exhalation Curve',
      session: 'Session %{session}: ',
      flow: 'Flow [L/s]',
      volume: 'Volume [L]',
      relative: 'Relative [%]',
      fev1: 'FEV1',
      fevL: 'FEV [L]',
      fvc: 'FVC',
      fvcL: 'FVC [L]',
      fev1Fvc: 'FEV1/FVC'
    },
    biovotion: {
      name: 'Biovotion',
      usageUnit: 'Time worn [h]',
      vitalSigns: 'Vital Signs',
      activity: 'Activity',
      stepIntensity: 'Step Intensity\n[1000/h]',
      steps: 'Steps [1000]',
      eeIntensitiy: 'EE Intensity [kCal/h]',
      pal: 'PAL [-]',
      hr: 'HR [bpm]',
      rr: 'RR [bpm]',
      spO2: 'SpO2 [%]'
    },
    fitbit: {
      name: 'Fit-Bit',
      steps: 'Steps',
      avgWeek: 'Weekly Average'
    },
    contour: {
      title: 'Contour',
      subtitle: 'Glucose Report',
      columns: {
        date: 'Date',
        time: 'Time',
        value: 'Measurement',
        unit: 'Unit'
      }
    }
  },
  de: {
    tab: 'Geräte',
    daily: 'Täglich',
    dailyX: 'Tageszeit [h]',
    monthly: 'Monatlich',
    monthlyX: 'Tag [d]',
    deviceUsage: 'Gerätenutzung',
    nuvoair: {
      name: 'NuvoAir',
      usageUnit: 'Spirometrie\nAnzahl [-]',
      lungFunction: 'Lungenfunktion',
      exhalationCurve: 'Lungenfunktionskurve',
      session: 'Durchgang %{session}: ',
      flow: 'Strom [L/s]',
      volume: 'Volumen [L]',
      relative: 'Prozentual [%]',
      fev1: 'FEV1',
      fevL: 'FEV [L]',
      fvc: 'FVC',
      fvcL: 'FVC [L]',
      fev1Fvc: 'FEV1/FVC'
    },
    biovotion: {
      name: 'Biovotion',
      usageUnit: 'Tragdauer [h]',
      vitalSigns: 'Vital-Parameter',
      activity: 'Aktivität',
      stepIntensity: 'Schrittintensität\n[1000/h]',
      steps: 'Schritte\n[1000]',
      eeIntensitiy: 'EV Intensität\n[kCal/h]',
      pal: 'Leistungsumsatz\n[-]',
      hr: 'HF [1/min]',
      rr: 'AF [1/min]',
      spO2: 'SpO2 [%]'
    },
    fitbit: {
      name: 'Fit-Bit',
      steps: 'Schritte',
      avgWeek: 'Wöchentlicher Durschnitt'
    },
    contour: {
      title: 'Contour',
      subtitle: 'Blutzuckerbericht',
      columns: {
        date: 'Datum',
        time: 'Uhrzeit',
        value: 'Messwert',
        unit: 'Einheit'
      }
    }
  }
}
