/**
 * @flow
 */
export const chatActionTypes = {
  CHAT_CONTNENT_CLICK: 'CHAT_CONTNENT_CLICK',
  TOGGLE_REQUEST_ATTENTION_DIALOG: 'TOGGLE_REQUEST_ATTENTION_DIALOG',
  TOGGLE_EDIT_CASE_DIALOG: 'TOGGLE_EDIT_CASE_DIALOG',
  TOGGLE_FILTER_MESSAGES_DIALOG: 'TOGGLE_FILTER_MESSAGES_DIALOG',
  TOGGLE_APPOINTMENT_DIALOG: 'TOGGLE_APPOINTMENT_DIALOG',
  INVITE_AND_JOIN_VIDEOCONFERENCE_REQUESTED: 'INVITE_AND_JOIN_VIDEOCONFERENCE_REQUESTED',
  INVITE_AND_JOIN_VIDEOCONFERENCE_SUCCEEDED: 'INVITE_AND_JOIN_VIDEOCONFERENCE_SUCCEEDED',
  INVITE_AND_JOIN_VIDEOCONFERENCE_FAILED: 'INVITE_AND_JOIN_VIDEOCONFERENCE_FAILED',
  VIDEOCHAT_INVITATION_CLICKED: 'VIDEOCHAT_INVITATION_CLICKED',
  JOIN_VIDEO_CONFERENCE: 'JOIN_VIDEO_CONFERENCE',
  JOIN_VIDEO_CONFERENCE_SUCCEEDED: 'JOIN_VIDEO_CONFERENCE_SUCCEEDED'
}

export type ChatActionType = $Keys<typeof chatActionTypes>

export const chatActions = {
  chatContentClick() {
    return {
      type: chatActionTypes.CHAT_CONTNENT_CLICK
    }
  },
  toggleRequestAttentionDialog() {
    return {
      type: chatActionTypes.TOGGLE_REQUEST_ATTENTION_DIALOG
    }
  },
  toggleEditOrViewCaseDialog() {
    return {
      type: chatActionTypes.TOGGLE_EDIT_CASE_DIALOG
    }
  },
  toggleFilterMessagesDialog() {
    return {
      type: chatActionTypes.TOGGLE_FILTER_MESSAGES_DIALOG
    }
  },
  toggleAppointmentDialog() {
    return {
      type: chatActionTypes.TOGGLE_APPOINTMENT_DIALOG
    }
  },
  invite2VideoConferenceRequested(conversationId: number) {
    return {
      type: chatActionTypes.INVITE_AND_JOIN_VIDEOCONFERENCE_REQUESTED,
      payload: {
        conversationId
      }
    }
  },
  invite2VideoConferenceSucceeded(conversationId: number, chatRedirectUrl: string) {
    return {
      type: chatActionTypes.INVITE_AND_JOIN_VIDEOCONFERENCE_SUCCEEDED,
      payload: { conversationId, chatRedirectUrl }
    }
  },
  invite2VideoConferenceFailed(error: Error) {
    return {
      type: chatActionTypes.INVITE_AND_JOIN_VIDEOCONFERENCE_FAILED,
      payload: { error }
    }
  },
  joinVideoConference(conversationId: number) {
    return {
      type: chatActionTypes.JOIN_VIDEO_CONFERENCE,
      payload: { conversationId }
    }
  },
  joinVideoConferenceSucceeded(conversationId: number) {
    return {
      type: chatActionTypes.JOIN_VIDEO_CONFERENCE_SUCCEEDED,
      payload: { conversationId }
    }
  }
}
