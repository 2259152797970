// @flow
import React from 'react'
import classNames from 'classnames'
import invariant from 'invariant'
import { Translate } from 'react-redux-i18n'
import Snackbar from '@material-ui/core/Snackbar'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'
import IconButton from '@material-ui/core/IconButton'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import { withStyles } from '@material-ui/core/styles'

import type { InfoPanelStateType } from '../reducers'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  hint: InfoIcon
}

const styles1 = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  hint: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
})

type ContentPropsType = {
  classes: Object,
  className: string,
  message: any,
  onClose: Function,
  variant: string
}

function MySnackbarContent(props: ContentPropsType) {
  const { classes, className, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]
  invariant(Icon, `no icon for ${variant} found`)
  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby='client-snackbar'
      data-test={`info-panel-${variant}`}
      message={(
        <span id='client-snackbar' className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
)}
      action={[
        <IconButton
          data-test='close-info-panel'
          key='close'
          aria-label='Close'
          color='inherit'
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  )
}

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent)

export const MessageTypes = {
  success: 'success',
  error: 'error',
  hint: 'hint'
}

type PropsType = InfoPanelStateType

type StateType = {
  open: boolean,
  messageInfo: Object,
  openTimeStamp: number
}
export default class InfoPanel extends React.Component<PropsType, StateType> {
  queue = []

  state = {
    open: false,
    messageInfo: {},
    openTimeStamp: 0
  }

  componentWillReceiveProps(newProps: PropsType) {
    const { message, count } = newProps
    if (this.props.count < count) {
      this.queue.push({
        message,
        timestamp: new Date().getTime()
      })

      const { open, openTimeStamp } = this.state
      const timeElapsed = new Date().getTime() - openTimeStamp
      if (open && timeElapsed > 1000) {
        // immediately begin dismissing current message if she is there for
        // to start showing new one
        this.setState({ open: false })
      } else if (!open) {
        this.processQueue()
      }
    }
  }

  processQueue = () => {
    if (this.queue.length > 0) {
      this.setState({
        messageInfo: this.queue.shift(),
        open: true,
        openTimeStamp: new Date().getTime()
      })
    }
  }

  handleClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ open: false })
  }

  handleExited = () => {
    this.processQueue()
  }

  render() {
    const { message, timestamp } = this.state.messageInfo

    if (!message) return null

    const { key, params, type } = message

    let messageText
    if (key instanceof Error) {
      messageText = <Translate value='infos.errorOccurred' />
    } else {
      messageText = key ? <Translate value={key} {...params} /> : ''
    }

    return (
      <Snackbar
        key={timestamp}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        onExited={this.handleExited}
        ContentProps={{
          'aria-describedby': 'infopanel'
        }}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose}
          variant={type}
          message={<span id='infopanel'>{messageText}</span>}
        />
      </Snackbar>
    )
  }
}
