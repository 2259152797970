// @flow
import { keycloakActionTypes } from '../actions'
import type { KeycloakActionType } from '../actions'

export type KeycloakStateType = {
  loggedIn: boolean
}

const initialState: KeycloakStateType = {
  loggedIn: true
}

function logout(state: KeycloakStateType): KeycloakStateType {
  return {
    ...state,
    loggedIn: false
  }
}

const ACTION_HANDLERS: { [key: KeycloakActionType]: Function } = {
  [keycloakActionTypes.LOGOUT]: logout
}
type ActionType = { type: KeycloakActionType }
export default function healthrelationReducer(
  state: KeycloakStateType = initialState,
  action: ActionType
): KeycloakStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
