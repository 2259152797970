export default {
  en: {
    user: {
      onboarding: 'Redirect Url sent',
      'intial-password': 'Initial password sent',
      'email-confirmed': 'Email Link clicked',
      'email-sent': 'Email sent',
      'invitation-email': 'Invitation Email sent',
      activation: 'User activated',
      termsAndConditions: 'Terms and Conditions accepted',
      'appointment-sms': 'Appointment Reminder sms',
      'prevented-email-bounce': 'Bouncing Email - not sent '
    },
    tokenUpdated: 'Mobile Login',
    pushMessage: 'Push Message sent'
  }
}
