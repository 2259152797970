// @flow

import { SET_GUI_VALUE } from '../actions/setGuiValue'
import type { ActionType } from '../../../types'

export type GuiSubStateType = {
  [key: string]: any
};

export type GuiStateType = {
  [key: string]: GuiSubStateType
};

const initialState: GuiStateType = {}

// Action Handlers

function setGuiValue(state, action) {
  return {
    ...state,
    [action.payload.component]: {
      ...state[action.payload.component],
      [action.payload.key]: action.payload.value
    }
  }
}

const ACTION_HANDLERS = {
  [SET_GUI_VALUE]: setGuiValue
}

export default function swaggerReducer(
  state: GuiStateType = initialState,
  action: ActionType
) {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
