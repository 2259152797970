// @flow
import React from 'react'
import { fields as studyParticipantFields } from 'common-docdok/src/domain/survey/types/studyParticipantDto'
import { connect } from 'react-redux'
import { stopSubmit as stopSubmitting } from 'redux-form'
import pathOr from 'ramda/src/pathOr'
import find from 'ramda/src/find'
import propEq from 'ramda/src/propEq'
import compose from 'ramda/src/compose'

import withMobileDialog from '@material-ui/core/withMobileDialog'
import Dialog from '@material-ui/core/Dialog'
import { Translate } from 'react-redux-i18n'
import DialogContent from '@material-ui/core/DialogContent'

import type { StudyDtoType } from 'common-docdok/src/domain/survey/types/studyDto'
import { addToStudyDialogActions } from '../actions'
import { DialogHeader } from '../../Mui'
import { Container } from '../../Static'
import DynamicForm from '../../DynamicForm'
import { dynamicFormActions } from '../../DynamicForm/actions'
import InclusionExclusionComp from '../../InclusionExclusion'

type AddToStudyDialogPropsType = {
  open: boolean,
  toggleAddToStudyDialog: typeof addToStudyDialogActions.toggleAddToStudyDialog,
  stopSubmit: string => void,
  submitForm: typeof dynamicFormActions.submitForm,
  selectedStudy: StudyDtoType,
  fullScreen: boolean
}

type StateType = {
  wizardPage: number
}

class addToStudyDialog extends React.Component<AddToStudyDialogPropsType, StateType> {
  state = { wizardPage: 0 }

  nextPage = () => {
    const { stopSubmit } = this.props
    this.setState(prevState => ({ wizardPage: prevState.wizardPage + 1 }))
    stopSubmit('addToStudy')
  }

  renderSelectStudy = () => (
    <DynamicForm
      form='addToStudy'
      alternateSubmit={this.nextPage}
      destroyOnUnmount={false}
      saveLabelKey='actions.next'
      fields={{
        studyId: {
          ...studyParticipantFields.studyId,
          type: 'select',
          specName: 'study',
          format: 'int64',
          mandatory: true
        }
      }}
    />
  )

  renderParticipationIds = () => {
    const { submitForm } = this.props
    const alternateSubmit = (form, data) => {
      this.setState({ wizardPage: 0 })
      submitForm(form, data)
    }

    return (
      <DynamicForm
        form='addToStudy'
        alternateSubmit={alternateSubmit}
        destroyOnUnmount={false}
        fields={{
          participantId: {
            ...studyParticipantFields.participantId,
            mandatory: true
          },
          participantSubId: studyParticipantFields.participantSubId
        }}
      />
    )
  }

  renderInclusions = () => {
    const criterias = pathOr([], ['studyParameters', 'inclusionCriterias'])(this.props.selectedStudy)
    return (
      <InclusionExclusionComp
        criterias={criterias}
        forward={this.nextPage}
        criteriaType='inclusion'
        studyName={this.props.selectedStudy.name}
      />
    )
  }

  renderExclusions = () => {
    const criterias = pathOr([], ['studyParameters', 'exclusionCriterias'])(this.props.selectedStudy)
    return (
      <InclusionExclusionComp
        criterias={criterias}
        forward={this.nextPage}
        criteriaType='exclusion'
        studyName={this.props.selectedStudy.name}
      />
    )
  }

  renderConsent = () => (
    <InclusionExclusionComp
      criterias={[<Translate value='studies.participationCriteria.informedConsentVerifyText' />]}
      forward={this.nextPage}
      criteriaType='informedConsent'
      studyName={this.props.selectedStudy.name}
    />
  )

  renderContent() {
    const { wizardPage } = this.state
    switch (wizardPage) {
      case 0:
        return this.renderSelectStudy()
      case 1:
        return this.renderInclusions()
      case 2:
        return this.renderExclusions()
      case 3:
        return this.renderConsent()
      case 4:
        return this.renderParticipationIds()
      default:
        return null
    }
  }

  myToggleAddToStudyDialog = () => {
    const { toggleAddToStudyDialog, stopSubmit } = this.props
    this.setState({ wizardPage: 0 })
    toggleAddToStudyDialog()
    stopSubmit('addToStudy')
  }

  render() {
    const { open, fullScreen } = this.props
    return (
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth='sm'
        scroll='paper'
        open={open}
        onEscapeKeyDown={() => this.myToggleAddToStudyDialog()}
      >
        <DialogHeader name='survey-respones' onClose={() => this.myToggleAddToStudyDialog()}>
          <Container noWrap>
            <Translate value='surveys.addToStudy.title' />
          </Container>
        </DialogHeader>
        <DialogContent>{this.renderContent()}</DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = (state: StoreType) => {
  const studyId = pathOr(-1, ['form', 'addToStudy', 'values', 'studyId'])(state)
  let selectedStudy = {}
  if (studyId !== -1) {
    selectedStudy = compose(
      find(propEq('id', studyId)),
      pathOr({}, ['survey', 'allStudies'])
    )(state)
  }
  return {
    selectedStudy,
    open: state.addToStudyDialog.showAddToStudyDialog
  }
}

export default compose(
  connect(
    mapStateToProps,
    {
      toggleAddToStudyDialog: addToStudyDialogActions.toggleAddToStudyDialog,
      submitForm: dynamicFormActions.submitForm,
      stopSubmit: stopSubmitting
    }
  ),
  withMobileDialog()
)(addToStudyDialog)
