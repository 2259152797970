// @flow
import React from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import compose from 'ramda/src/compose'
import { MenuItem } from '@material-ui/core'
import Action from '../../Security/containers/action'

import { CREATE_PATIENT } from '../../Security/actionNames'
import { createCaseButtonActions } from '../actions'

type PropsType = {
  toggleCreateCaseDialog: typeof createCaseButtonActions.toggleCreateCaseDialog,
  closeMenu: () => void
}

class CreateCaseButton extends React.Component<PropsType> {
  menuItemClicked = () => {
    const { closeMenu, toggleCreateCaseDialog } = this.props
    closeMenu()
    toggleCreateCaseDialog()
  }

  render() {
    const textMenuEntry = (
      <MenuItem onClick={this.menuItemClicked}>
        <Translate value='cases.newCase.title' />
      </MenuItem>
    )
    return <Action name={CREATE_PATIENT}>{textMenuEntry}</Action>
  }
}

export default compose(
  connect(
    null,
    {
      toggleCreateCaseDialog: createCaseButtonActions.toggleCreateCaseDialog
    }
  ),
  withMobileDialog()
)(CreateCaseButton)
