// @flow

export const createPatientButtonActionTypes = {
  TOGGLE_CREATE_PATIENT_DIALOG: 'TOGGLE_CREATE_PATIENT_DIALOG',
  TOGGLE_CREATE_EVENT_DIALOG: 'TOGGLE_CREATE_EVENT_DIALOG'
}
export type CreatePatientButtonActionType =$Keys<typeof createPatientButtonActionTypes>
export const createPatientButtonActions = {
  toggleCreatePatientDialog() {
    return {
      type: createPatientButtonActionTypes.TOGGLE_CREATE_PATIENT_DIALOG
    }
  },
  toggleCreateEventDialog() {
    return {
      type: createPatientButtonActionTypes.TOGGLE_CREATE_EVENT_DIALOG
    }
  }
}
