// @flow
import type { ActionType, ExtractReturn } from 'common-docdok/src/types'
import { uploadSurveyDialogActionTypes, uploadSurveyDialogActions } from '../actions'

// State Definition
export type UploadSurveyDialogStateType = {
  showUploadSurveyDialog: boolean
}

// Default State
const initialState: UploadSurveyDialogStateType = {
  showUploadSurveyDialog: false
}

// Action Handlers

function setUploadSurveyDialog(
  state: UploadSurveyDialogStateType,
  action: ExtractReturn<typeof uploadSurveyDialogActions.setUploadSurveyDialog>
): UploadSurveyDialogStateType {
  return {
    ...state,
    showUploadSurveyDialog: action.payload
  }
}

const ACTION_HANDLERS = {
  [uploadSurveyDialogActionTypes.SET_UPLOAD_SURVEY_DIALOG]: setUploadSurveyDialog
}

export default function surveysReducer(
  state: UploadSurveyDialogStateType = initialState,
  action: ActionType
): UploadSurveyDialogStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
