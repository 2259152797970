/**
 * @providesModule UserActions
 * @flow
 */

export const alertActionTypes = {
  // -- APPEND ACTION-TYPES HERE --

  ALERT: 'ALERT'
}

export type AlertActionType = $Keys<typeof alertActionTypes>

export const alertActions = {
  // -- APPEND ACTIONS HERE --

  showAlert(messageKey: string, params: Object = {}) {
    return {
      type: alertActionTypes.ALERT,
      payload: {
        open: true,
        messageKey,
        params
      }
    }
  },

  hideAlert() {
    return {
      type: alertActionTypes.ALERT,
      payload: {
        open: false,
        messageKey: undefined,
        params: {}
      }
    }
  }
}
