// @flow
import type { ConversationType } from 'common-docdok/src/domain/messaging/reducers/messaging'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Avatar from '@material-ui/core/Avatar'
import NotificationIcon from '@material-ui/icons/NotificationsActive'
import IconButton from '@material-ui/core/IconButton'

import ListItemText from '@material-ui/core/ListItemText'
import Badge from '@material-ui/core/Badge'
import Typography from '@material-ui/core/Typography'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { connect } from 'react-redux'
import compose from 'ramda/src/compose'
import withMobileDialog from '@material-ui/core/withMobileDialog'

import React from 'react'
import { I18n } from 'react-redux-i18n'
import { browserHistory } from 'react-router'
import UserAvatar from '../../UserAvatar'
import palette from '../../../styles/palette'
import LatestMessage from '../../LatestMessage'
import { Clickable, Container } from '../../Static'
import { Menu } from '../../Mui'
import { coreLayoutActions } from '../../../layouts/CoreLayout/actions'

const AutoHideBadge = ({ badgeContent, ...props }: Object) => (badgeContent === 0 ? (
  <div>{props.children}</div>
) : (
  <Badge style={{ zIndex: 0 }} {...props} badgeContent={<span data-test='unread-messages-count'>{badgeContent}</span>}>
    {props.children}
  </Badge>
))

type ConversationItemPropsType = {
  conversation: ConversationType,
  checkedMessageNo: number,
  selectedConversation: ?number,
  children?: React$Node,
  onClick?: () => void,
  setMenuVisibility: typeof coreLayoutActions.setMenuVisibility,
  fullScreen: boolean
}

type StateType = {
  anchorEl: ?any
}

class ConversationItem extends React.Component<ConversationItemPropsType, StateType> {
  static defaultProps = {
    children: undefined,
    onClick: undefined
  }

  state = {
    anchorEl: null
  }

  handleMenuClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget })
    event.preventDefault()
  }

  handleItemClick = () => {
    if (!this.state.anchorEl) {
      const { conversation, onClick, setMenuVisibility, fullScreen } = this.props
      if (onClick) onClick()
      if (fullScreen) setMenuVisibility(false)
      browserHistory.push(`/private/app/messages/conversation/${conversation.id}`)
    }
  }

  handleClose = (onClick: Function = () => {}) => () => {
    onClick()
    this.setState({ anchorEl: null })
  }

  render() {
    const { conversation, checkedMessageNo, selectedConversation } = this.props
    const { anchorEl } = this.state

    const { latestMessage, id: conversationId } = conversation
    const newestMessageNo = latestMessage ? latestMessage.sequenceNo : 0
    const uncheckedMessages = Math.max(newestMessageNo - checkedMessageNo, 0)

    const isNotificationChannel = conversation.type === 'NOTIFICATION'
    let conversationTitle = conversation.title ? conversation.title : ''
    let listItemStyle = {}

    if (conversationId === selectedConversation) {
      listItemStyle = { backgroundColor: palette.blueVeryLight }
    }

    let titleStyle = {}
    if (isNotificationChannel) {
      titleStyle = { color: 'black' }
      conversationTitle = I18n.t('messages.notifications')
    }
    const clone = child => React.cloneElement(child, { onClick: this.handleClose(child.onClick) })
    const newChildren = React.Children.map(this.props.children, clone)

    return (
      <ListItem
        className={isNotificationChannel ? 'conversation-item-notification' : 'conversation-item'}
        style={listItemStyle}
      >
        <Clickable onClick={this.handleItemClick}>
          <Container noWrap>
            <AutoHideBadge badgeContent={uncheckedMessages} color='secondary'>
              {isNotificationChannel ? (
                <Avatar>
                  <NotificationIcon />
                </Avatar>
              ) : (
                <UserAvatar name={conversationTitle} src={conversation.conversationPicture} showAvatar />
              )}
            </AutoHideBadge>
            <ListItemText
              primary={(
                <Typography className='conversation-item-title' style={titleStyle}>
                  {conversationTitle}
                </Typography>
)}
              secondary={<LatestMessage message={latestMessage} />}
            />
          </Container>
        </Clickable>

        {newChildren && (
          <ListItemSecondaryAction>
            <IconButton aria-label='Menu' onClick={this.handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        )}

        <Menu id='conversation-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose()}>
          <div>{newChildren}</div>
        </Menu>
      </ListItem>
    )
  }
}

export default compose(
  connect(
    null,
    { setMenuVisibility: coreLayoutActions.setMenuVisibility }
  ),
  withMobileDialog()
)(ConversationItem)
