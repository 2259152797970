// @flow
import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import Done from '@material-ui/icons/Done'
import styled from 'styled-components'

const GreenBall = styled.div`
  background-color: green;
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;
`

type PropsType = {
  toggle: () => void
}

export default function verified(props: PropsType) {
  const toggle = props.toggle ? props.toggle : () => {}
  return (
    <IconButton onClick={toggle}>
      <GreenBall>
        <Done style={{ color: 'white' }} />
      </GreenBall>
    </IconButton>
  )
}
