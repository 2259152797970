// @flow
import type { ActionType } from 'common-docdok/src/types'
import { professionalsActionTypes } from '../actions'

// State Definition
export type ProfessionalsStateType = {
  showCreatePhysicianDialog: boolean,
  showCreateMpaDialog: boolean
}

// Default State
const initialState: ProfessionalsStateType = {
  showCreatePhysicianDialog: false,
  showCreateMpaDialog: false
}

// Action Handlers

function toggleCreatePhysicianDialog(state: ProfessionalsStateType): ProfessionalsStateType {
  return {
    ...state,
    showCreatePhysicianDialog: !state.showCreatePhysicianDialog
  }
}

function toggleCreateMpaDialog(state: ProfessionalsStateType): ProfessionalsStateType {
  return {
    ...state,
    showCreateMpaDialog: !state.showCreateMpaDialog
  }
}

const ACTION_HANDLERS = {
  [professionalsActionTypes.TOGGLE_CREATE_PHYSICIAN_DIALOG]: toggleCreatePhysicianDialog,
  [professionalsActionTypes.TOGGLE_CREATE_MPA_DIALOG]: toggleCreateMpaDialog
}

export default function professionalsReducer(
  state: ProfessionalsStateType = initialState,
  action: ActionType
): ProfessionalsStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
