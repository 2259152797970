// @flow
import { put, takeEvery } from 'redux-saga/effects'
import type { GenType } from 'common-docdok/src/types'
import { stopSubmit } from 'redux-form'
import { asyncAction } from 'common-docdok/src/utils/sagaUtils'
import { surveyActions } from 'common-docdok/src/domain/survey/actions'

import { infoPanelActions } from '../../InfoPanel/actions'
import { scheduleSurveyDialogActions } from '../actions'
import { dynamicFormActionTypes } from '../../DynamicForm/actions'
import { formToScheduleModel } from '../utils/converter'

function* scheduleStudySurvey({ payload }: Object): GenType {
  if (payload.name === 'scheduleStudySurvey') {
    const formData = payload.data
    const { scheduleSurvey } = formData
    const schedule = formToScheduleModel(formData)

    const winner = yield asyncAction(
      surveyActions.updateStudySurveyRequested({
        ...scheduleSurvey,
        schedule
      })
    )

    if (winner.succeeded) {
      yield put(infoPanelActions.showMessage('studies.actions.scheduleSurvey.success'))
      yield put(scheduleSurveyDialogActions.setStudySurveySchedule())
      yield put(stopSubmit(payload.name))
    } else {
      yield put(stopSubmit(payload.name, winner.failed.payload.error))
    }
  }
}

export default function surveySaga(): Array<GenType> {
  return [takeEvery(dynamicFormActionTypes.SUBMIT_FORM, scheduleStudySurvey)]
}
