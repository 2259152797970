// @flow
import type { ActionType, ExtractReturn } from 'common-docdok/src/types'
import { coreLayoutActionTypes, coreLayoutActions } from '../actions'

// State Definition

export type CoreLayoutStateType = { menuVisible: boolean }

// Default State
const initialState: CoreLayoutStateType = { menuVisible: true }

// Action Handlers

function setMenuVisibility(
  state: CoreLayoutStateType,
  action: ExtractReturn<typeof coreLayoutActions.setMenuVisibility>
): CoreLayoutStateType {
  return { menuVisible: action.payload }
}

const ACTION_HANDLERS = {
  [coreLayoutActionTypes.SET_MENU_VISIBILITY]: setMenuVisibility
}

export default function selectPatientReducer(
  state: SelectedPatientStateType = initialState,
  action: ActionType
): SelectedPatientStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
