// @flow
import { connect } from 'react-redux'
import { surveyActions } from 'common-docdok/src/domain/survey/actions'

import StudyPhysicians from '../components/StudyPhysicians'

import { studiesActions } from '../actions'
import { selectStudyPhysicians } from './study'

const mapStateToProps = (state: StoreType) => ({
  ...state.studies,
  physicians: selectStudyPhysicians(state)
})

export default connect(
  mapStateToProps,
  {
    ...surveyActions,
    ...studiesActions
  }
)(StudyPhysicians)
