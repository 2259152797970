// @flow
import pathOr from 'ramda/src/pathOr'

import dateUtils from '../../../utils/dateUtils'
import type { ActionType, ExtractReturn } from '../../../types'
import type { MessagingStateType } from './messaging'
import type { MessageDtoType } from '../types/messageDto'
import { messagingActions } from '../actions'

export function markMessageAsChecked(
  state: MessagingStateType,
  action: ExtractReturn<typeof messagingActions.markAsCheckedLocal>
): MessagingStateType {
  const messages = action.payload || []

  const newState = { ...state }

  messages.forEach((m) => {
    const { conversationId } = m
    const newestMsg = Math.max(
      ...messages.filter(msg => msg.conversationId === conversationId).map(msg => msg.sequenceNo)
    )
    const currentylNewestMsg = state.checkedMessages[conversationId]
      ? state.checkedMessages[conversationId]
      : -1
    if (m.sequenceNo > currentylNewestMsg) {
      newState.checkedMessages = {
        ...newState.checkedMessages,
        [conversationId]: newestMsg
      }
    }
  })
  return newState
}

export function markAsCheckedLocal(
  state: MessagingStateType,
  action: ExtractReturn<typeof messagingActions.markAsCheckedLocal>
): MessagingStateType {
  const messages = action.payload || []

  let newState = markMessageAsChecked(state, action)

  messages.forEach((m) => {
    const { conversationId } = m
    const newestMsg = Math.max(
      ...messages.filter(msg => msg.conversationId === conversationId).map(msg => msg.sequenceNo)
    )
    const currentylNewestMsg = pathOr(
      -1,
      ['conversations', conversationId, 'lastReadMessage'],
      state
    )

    if (m.sequenceNo > currentylNewestMsg) {
      newState = {
        ...newState,
        conversations: {
          ...newState.conversations,
          [conversationId]: {
            ...newState.conversations[conversationId],
            lastReadMessage: newestMsg
          }
        }
      }
    }
  })
  return newState
}

export function sortMessages(m1: MessageDtoType, m2: MessageDtoType) {
  const t1 = dateUtils.toDate(m1.postedAt)
  const t2 = dateUtils.toDate(m2.postedAt)
  let result = t1.getTime() - t2.getTime()
  if (result === 0) result = m1.sequenceNo - m2.sequenceNo
  return result
}

export function appendOrReplaceMessage(state: MessagingStateType, message: MessageDtoType) {
  const { conversationId } = message
  const currentMessages = (state.conversations[conversationId]
    ? state.conversations[conversationId].messages
    : []
  ).filter(m => m.uuid !== message.uuid)

  let latestMessage = state.conversations[conversationId]
    ? state.conversations[conversationId].latestMessage
    : undefined

  if (
    (!latestMessage || message.sequenceNo > latestMessage.sequenceNo) &&
    message.meta.subtype !== 'conversationevent'
  ) {
    latestMessage = message
  }

  const messages = [...currentMessages, message].sort(sortMessages)
  const newState: MessagingStateType = {
    ...state,
    conversations: {
      ...state.conversations,
      [conversationId]: {
        ...state.conversations[conversationId],
        messages,
        latestMessage
      }
    }
  }

  return newState
}

export function loadMessagesSuccess(
  state: MessagingStateType,
  action: ExtractReturn<typeof messagingActions.loadMessagesSucceeded>
): MessagingStateType {
  let newState = { ...state }
  const messages = action.payload

  messages.forEach((message) => {
    newState = appendOrReplaceMessage(newState, message)
  })
  return newState
}

export function postMessageSucceeded(
  state: MessagingStateType,
  action: ExtractReturn<typeof messagingActions.postMessageSucceeded>
): MessagingStateType {
  return appendOrReplaceMessage(state, action.payload)
}

export function selectConversation(state: MessagingStateType, action: ActionType) {
  const selectedConversation = action.payload

  return {
    ...state,
    selectedConversation
  }
}
export function selectCaseStream(state: MessagingStateType, action: ActionType) {
  const selectedCaseStream = action.payload

  return {
    ...state,
    selectedCaseStream
  }
}
