import { I18n } from 'react-redux-i18n'
import moment from 'moment'

export const getCustomErrorMessage = (key, defaultMessage) => {
  if (
    [
      'salutation',
      'firstName',
      'lastName',
      'gender',
      'birthdate',
      'mobileNumber',
      'email',
      'langKey',
      'role',
      'clinic',
      'birthdateFormat',
      'mobileNumberFormat',
      'emailFormat',
      'proxySalutation',
      'proxyFirstName',
      'proxyLastName',
      'proxyGender',
      'proxyEmail',
      'proxyMobileNumberFormat',
      'proxyEmailFormat',
      'proxyMobileNumber',
      'lowestSystolicBloodPressureFormat',
      'highestSystolicBloodPressureFormat',
      'lowestDiastolicBloodPressureFormat',
      'highestDiastolicBloodPressureFormat',
      'lowestHbA1cFormat',
      'highestHbA1cFormat',
      'lowestBMIFormat',
      'highestBMIFormat',
      'lowestDailyStepsFormat',
      'highestDailyStepsFormat'
    ].some(txKey => txKey === key)
  ) {
    return I18n.t(`customFormErrors.${key}`)
  }
  return defaultMessage
}

export default function formValidator(fields) {
  let properties = fields
  if (fields instanceof Array) {
    properties = fields.reduce((fs, all) => ({ ...all, ...fs }), {})
  }
  const validate = (values) => {
    const errors = {}

    Object.keys(properties).forEach((key) => {
      const config = properties[key]
      const {
        mandatory,
        readonly,
        minLength,
        maxLength,
        type,
        patternNice,
        pattern,
        notInFuture
      } = config
      const value = values[key]

      const isSelection = type
        && (type.includes('Array')
          || type === 'select'
          || type === 'multiSelect'
          || type === 'search'
          || config.enum)

      if (!isSelection && mandatory && !value) {
        errors[key] = getCustomErrorMessage(key, I18n.t('forms.errors.mandatory'))
      } else if (minLength > 0 && (!value || value.length < minLength)) {
        errors[key] = getCustomErrorMessage(key, I18n.t('forms.errors.minLength', {
          minLength
        }))
      } else if (maxLength > 0 && (value && value.length > maxLength)) {
        errors[key] = getCustomErrorMessage(key, I18n.t('forms.errors.maxLength', {
          maxLength
        }))
      } else if (pattern) {
        if (!mandatory) {
          if (value && !pattern.test(value)) {
            errors[key] = getCustomErrorMessage(`${key}Format`, I18n.t('forms.errors.pattern', {
              pattern: patternNice
            }))
          }
        } else if (!pattern.test(value)) {
          errors[key] = getCustomErrorMessage(`${key}Format`, I18n.t('forms.errors.pattern', {
            pattern: patternNice
          }))
        }
      } else if (type === 'Date') {
        if (value) {
          const dateString = value

          const date = moment(dateString, I18n.t('date.short'))
          if (!date.isValid()) {
            if (!moment(dateString).isValid()) {
              errors[key] = getCustomErrorMessage(`${key}Format`, I18n.t('forms.errors.noValidDate'))
            }
          }
          if (notInFuture) {
            if (date > Date.now()) {
              errors[key] = getCustomErrorMessage(`${key}Format`, I18n.t('forms.errors.notInFuture'))
            }
          }
        }
      } else if (type === 'number') {
        if (!isNaN(value)) {
          const { minValue, maxValue } = config
          if (minValue !== undefined) {
            if (value < minValue) {
              errors[key] = getCustomErrorMessage(`${key}Format`, I18n.t('forms.errors.minValue', { minValue, value }))
            }
          }
          if (maxValue !== undefined) {
            if (value > maxValue) {
              errors[key] = getCustomErrorMessage(`${key}Format`, I18n.t('forms.errors.maxValue', { maxValue, value }))
            }
          }
        }
      } else if (isSelection) {
        const isEmptyArray = Array.isArray(value) && value.length === 0
        if (mandatory && (!value || isEmptyArray)) {
          errors[key] = getCustomErrorMessage(key, I18n.t('forms.errors.noSelection'))
        }
      }
      if (readonly) {
        // no validation on readonly
        errors[key] = undefined
      }
    })

    return errors
  }
  return validate
}
