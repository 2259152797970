// @flow

import React from 'react'
import Paper from '@material-ui/core/Paper'
import Remove from '@material-ui/icons/RemoveCircleOutline'
import AvTimer from '@material-ui/icons/AvTimer'
import Done from '@material-ui/icons/Done'
import ShowChart from '@material-ui/icons/ShowChart'
import api from 'common-docdok/src/api'
import { Translate } from 'react-redux-i18n'
import { fields } from 'common-docdok/src/domain/survey/types/studySurveyDto'
import type { StudySurveyDtoType } from 'common-docdok/src/domain/survey/types/studySurveyDto'

import { surveyActionTypes, surveyActions } from 'common-docdok/src/domain/survey/actions'
import Action from '../../../../../common/Security/containers/action'
import EntityTable from '../../../../../common/EntityTable'
import DynamicFormDialog from '../../../../../common/DynamicFormDialog'
import { studiesActions } from '../actions'
import {
  REMOVE_STUDY_SURVEY,
  SCHEDULE_STUDY_SURVEY,
  ADD_STUDY_SURVEY
} from '../../../../../common/Security/actionNames'
import ScheduleSurveyDialog from '../../../../../common/ScheduleSurveyDialog'
import { RaisedButton, TypographyLight } from '../../../../../common/Mui'
import { GreyBar, Toolbar, ToolbarGroup, Buttons } from '../../../../../common/Static'
import { scheduleSurveyDialogActions } from '../../../../../common/ScheduleSurveyDialog/actions'

type PropsType = {
  studyId: number,
  showAddSurveyDialog: boolean,
  toggleAddSurveyDialog: typeof studiesActions.toggleAddSurveyDialog,
  removeStudySurveyRequested: typeof surveyActions.removeStudySurveyRequested,
  setStudySurveySchedule: typeof scheduleSurveyDialogActions.setStudySurveySchedule
}
type StateType = {
  searchTerm: ?string
}
export default class StudySurveys extends React.Component<PropsType, StateType> {
  state = {
    searchTerm: undefined
  }

  render() {
    const {
      studyId,
      showAddSurveyDialog,
      toggleAddSurveyDialog,
      removeStudySurveyRequested,
      setStudySurveySchedule
    } = this.props

    return (
      <Paper>
        <GreyBar>
          <Toolbar>
            <ToolbarGroup>
              <TypographyLight variant='h6'>
                <Translate value='surveys.table.title' />
              </TypographyLight>
            </ToolbarGroup>
            <Buttons>
              <Action name={ADD_STUDY_SURVEY}>
                <RaisedButton
                  id='add-survey-button'
                  label={<Translate value='studies.actions.addSurvey.title' />}
                  onClick={toggleAddSurveyDialog}
                  icon={<ShowChart />}
                />
              </Action>
            </Buttons>
          </Toolbar>
        </GreyBar>
        <EntityTable
          searchTerm={this.state.searchTerm}
          params={[studyId]}
          api={api.getStudySurveys}
          id='studySurveys'
          hideRowCheckbox
          defaultPageSize={5}
          refreshActions={[
            surveyActionTypes.REMOVE_STUDY_SURVEY_SUCCEEDED,
            surveyActionTypes.ADD_SURVEY_TO_STUDY_SUCCEEDED,
            surveyActionTypes.UPDATE_STUDY_SURVEY_SUCCEEDED
          ]}
          entity='survey'
          columns={{
            surveyName: {
              ...fields.surveyName
            },
            schedule: {
              ...fields.schedule,
              renderer: (val: any) => (val ? <Done /> : '')
            },
            writeAccess: {
              ...fields.writeAccess
            }
          }}
          actions={[
            {
              name: REMOVE_STUDY_SURVEY,
              icon: <Remove color='primary' />,
              tooltipKey: 'studies.actions.removeSurvey.tooltip',
              enabled: () => true,
              action: (row: StudySurveyDtoType) => {
                removeStudySurveyRequested(studyId, row.id)
              }
            },
            {
              name: SCHEDULE_STUDY_SURVEY, // CHANGE ACTION
              icon: <AvTimer color='primary' />,
              tooltipKey: 'studies.actions.scheduleSurvey.tooltip',
              enabled: () => true,
              action: (row: StudySurveyDtoType) => {
                setStudySurveySchedule(row)
              }
            }
          ]}
        />
        <DynamicFormDialog
          open={showAddSurveyDialog}
          handleClose={toggleAddSurveyDialog}
          titleKey='studies.actions.addSurvey.title'
          form='addSurveyToStudy'
          initialValues={{ studyId }}
          fields={{
            surveys: {
              specName: 'availableStudySurveys',
              type: 'search',
              mandatory: true,
              multiSelectable: true,
              params: [studyId]
            }
          }}
        />

        <ScheduleSurveyDialog />
      </Paper>
    )
  }
}
