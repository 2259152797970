// @flow
export const securityActionTypes = {
  GET_RIGHTS_REQUESTED: 'GET_RIGHTS_REQUESTED',
  GET_RIGHTS_SUCCEEDED: 'GET_RIGHTS_SUCCEEDED',
  GET_RIGHTS_FAILED: 'GET_RIGHTS_FAILED'
}

export type SecurityActionType = $Keys<typeof securityActionTypes>

export const securityActions = {
  getRightsRequested() {
    return {
      type: securityActionTypes.GET_RIGHTS_REQUESTED
    }
  },
  getRightsSucceeded(payload: Array<Object>) {
    return {
      type: securityActionTypes.GET_RIGHTS_SUCCEEDED,
      payload
    }
  },
  getRightsFailed(error: Error) {
    return {
      type: securityActionTypes.GET_RIGHTS_FAILED,
      payload: { error }
    }
  }
}
