// @flow
import React from 'react'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { browserHistory } from 'react-router'
import { I18n } from 'react-redux-i18n'

import type { MessagePropsType } from '../containers/Message'
import UserAvatar from '../../UserAvatar'

export default function hycareSetTarget(props: MessagePropsType) {
  const {
    user,
    message: {
      meta: { patientUuid }
    },
    closeRequest
  } = props

  let result = null
  if (user) {
    result = (
      <ListItem
        button
        id='survey-completed-notification'
        onClick={() => {
          browserHistory.push(
            `/private/app/patients/${patientUuid}?tab=hycare-set-target`
          )
          if (closeRequest) closeRequest()
        }}
      >
        <UserAvatar
          size={34}
          src={user ? user.avatarPicture : undefined}
          name={user ? user.firstName : ''}
        />
        <ListItemText
          primary={I18n.t('patient.hycare.notification', { name: user.firstName })}
        />
      </ListItem>
    )
  }
  return result
}
