// @flow
import React from 'react'
import classnames from 'classnames'

import styled from 'styled-components'
import './style.scss'

const HeaderDiv = styled.div`
  flex: 1 1;
`

const ValueDiv = styled.div`
  flex: 2 0;
`

export type TableActionType = {
  key?: string,
  icon: any,
  name: string,
  tooltipKey: string,
  enabled?: (row: any) => boolean,
  hidden?: (row: any) => boolean,
  action: (row: any) => void
}

type PropsType = {
  children: any,
  className: string,
  row?: Object,
  actions?: Array<TableActionType>,
  column: any,
  onRowClick?: (row: any) => void,
  displayRowCheckbox: boolean
}

type StateType = { opacity: number }

export default class TableCell extends React.Component<PropsType, StateType> {
  static defaultProps = {
    row: undefined,
    actions: undefined,
    onRowClick: () => {}
  }

  render() {
    const { children, className, row, column } = this.props

    const isSelectColumn = column && column.id === 'select'
    let headerValue = row && column.header
    let cellValue = children
    if (isSelectColumn) {
      headerValue = children
      cellValue = null
    }

    return (
      <div className={classnames('rt-td', className)}>
        <HeaderDiv>{headerValue}</HeaderDiv>
        <ValueDiv>{cellValue}</ValueDiv>
      </div>
    )
  }
}
