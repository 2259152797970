// @flow
import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { Translate } from 'react-redux-i18n'
import Slider from '@material-ui/lab/Slider'
import Typography from '@material-ui/core/Typography'
import OpenInBrowser from '@material-ui/icons/OpenInBrowser'
import { resizeImage } from '../../Messaging/utils'

import { RaisedButton, UploadButton, DialogHeader } from '../../Mui'
import AvatarEditor from './avatarEditor'

export const AVATAR_SIZE = {
  width: 200,
  height: 200
}

type PropsType = {
  avatar: ?string,
  handleClose: (image: ?any) => void,
  open: boolean
}

type StateType = {
  saveDisabled: boolean,
  avatarScale: number
}

export class UploadAvatarDialog extends React.Component<PropsType, StateType> {
  setEditorRef(editor: AvatarEditor) {
    if (editor) this.editor = editor
  }

  editor: AvatarEditor

  input: Object

  state = {
    saveDisabled: true,
    avatarScale: 1
  }

  saveAvatar() {
    // If you want the image resized to the canvas size (also a HTMLCanvasElement)
    const canvasScaled = this.editor.getImageScaledToCanvas()
    const { handleClose } = this.props
    const close = () => {
      this.closeDialog()
    }
    canvasScaled.toBlob((blob) => {
      let arrayBuffer
      const fileReader = new FileReader()
      fileReader.onload = function onLoad() {
        arrayBuffer = this.result
        handleClose(arrayBuffer)
        close()
      }
      fileReader.readAsArrayBuffer(blob)
    })
  }

  uploadImage(file: any) {
    resizeImage(file, { maxWidth: 300, maxHeight: 300 }).then(({ dataUrl }) => {
      const img = new Image()
      img.onload = () => {
        this.editor.handleImageReady(img)
      }
      img.src = dataUrl
    })
  }

  closeDialog = () => {
    this.setState({ saveDisabled: true })
    this.props.handleClose()
  }

  render() {
    const { open } = this.props
    const maxScale = 2
    const minScale = 0.2
    return (
      <Dialog open={open} onEscapeKeyDown={this.closeDialog}>
        <DialogHeader name='profile-change-photo-title' onClose={this.closeDialog}>
          <Translate value='profile.changePhoto' />
        </DialogHeader>
        <DialogContent>
          <Typography variant='caption' gutterBottom align='center'>
            <Translate value='profile.uploadHint' />
          </Typography>
          <div
            style={{
              width: '100%'
            }}
          >
            <div
              style={{
                margin: 'auto',
                width: AVATAR_SIZE.width,
                marginBottom: -(AVATAR_SIZE.height + 10)
              }}
            >
              <OpenInBrowser
                color='primary'
                style={{
                  width: AVATAR_SIZE.width,
                  height: AVATAR_SIZE.height
                }}
              />
            </div>
          </div>

          <div>
            <AvatarEditor
              ref={editor => this.setEditorRef(editor)}
              style={{ margin: 'auto' }}
              color={[30, 94, 132, 0.4]}
              width={AVATAR_SIZE.width}
              height={AVATAR_SIZE.height}
              border={10}
              min={minScale}
              max={maxScale}
              scale={this.state.avatarScale}
              onLoadSuccess={() => this.setState({ saveDisabled: false })}
            />
          </div>

          <div style={{ paddingRight: 20 }}>
            <Slider
              min={minScale}
              max={maxScale}
              value={this.state.avatarScale}
              onChange={(e, avatarScale) => {
                this.setState({ avatarScale })
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <UploadButton
            id='browse-button'
            type='file'
            accept='image/png,image/jpeg'
            variant='contained'
            color='primary'
            labelPosition='before'
            containerElement='label'
            onChange={(files) => {
              const file = files[0]
              this.uploadImage(file)
            }}
          >
            <Translate value='profile.chooseImage' />
          </UploadButton>

          <RaisedButton
            disabled={this.state.saveDisabled}
            onClick={() => {
              this.saveAvatar()
            }}
            style={{ float: 'right' }}
            label={<Translate value='actions.save' />}
            primary
          />
        </DialogActions>
      </Dialog>
    )
  }
}
