// @flow
import type { ActionType, ExtractReturn } from 'common-docdok/src/types'
import { billingActionTypes as billingATypes, billingActions } from '../actions'

// State Definition

export type CodeType = { code: string, description: string, id: number, physicianTypeId: number }
export type AdditionalCodeType = { code: string, description: string, serverCode: string }
export type SelectedCodeType = { position: CodeType, additionalPos: ?AdditionalCodeType }

export type BillingStateType = {
  context: ?string,
  active: boolean,
  requested: boolean,
  showReminder: boolean,
  paused: boolean,
  physicianTypes: Array<Object>,
  physicianTypeId: ?number,
  billingPositions: {
    [context: string]: Array<CodeType>
  },
  billingPosition: ?SelectedCodeType
}

// Default State
const initialState: BillingStateType = {
  context: undefined,
  active: false,
  requested: false,
  showReminder: true,
  paused: false,
  billingPositions: {},
  billingPosition: undefined,
  physicianTypeId: undefined,
  physicianTypes: []
}

// Action Handlers

function startBilling(
  state: BillingStateType,
  action: ExtractReturn<typeof billingActions.startBillingAccepted>
): BillingStateType {
  return {
    ...state,
    active: true,
    requested: false,
    billingPosition: action.payload
  }
}

function startBillingProposed(state: BillingStateType): BillingStateType {
  return {
    ...state,
    requested: true
  }
}

function setBillingContext(
  state: BillingStateType,
  action: ExtractReturn<typeof billingActions.setBillingContext>
): BillingStateType {
  return {
    ...state,
    context: action.payload
  }
}

function stopBilling(state: BillingStateType): BillingStateType {
  return {
    ...state,
    active: false,
    requested: false
  }
}

function pauseBilling(state: BillingStateType): BillingStateType {
  return {
    ...state,
    paused: true
  }
}

function resumeBilling(state: BillingStateType): BillingStateType {
  return {
    ...state,
    paused: false
  }
}

function setBillingShowReminder(
  state: BillingStateType,
  action: ExtractReturn<typeof billingActions.setBillingReminder>
): BillingStateType {
  return {
    ...state,
    showReminder: action.payload
  }
}

function getBillingPositionsSucceeded(
  state: BillingStateType,
  action: ExtractReturn<typeof billingActions.getBillingPoisitionsSucceeded>
): BillingStateType {
  const {
    payload: { context, positions }
  } = action
  return {
    ...state,
    billingPositions: {
      ...state.billingPositions,
      [context]: positions
    }
  }
}

function getPhysicianTypesSucceeded(
  state: BillingStateType,
  action: ExtractReturn<typeof billingActions.getPhysicianTypesSucceeded>
): BillingStateType {
  const physicianTypes = action.payload
  return {
    ...state,
    physicianTypes
  }
}

function selectPhysicianType(
  state: BillingStateType,
  action: ExtractReturn<typeof billingActions.selectPhysicianType>
): BillingStateType {
  const physicianTypeId = action.payload
  return {
    ...state,
    physicianTypeId
  }
}

const ACTION_HANDLERS = {
  [billingATypes.START_BILLING_PROPOSED]: startBillingProposed,
  [billingATypes.START_BILLING_ACCEPTED]: startBilling,
  [billingATypes.STOP_BILLING]: stopBilling,
  [billingATypes.START_BILLING_DECLINED]: stopBilling,
  [billingATypes.SET_BILLING_CONTEXT]: setBillingContext,
  [billingATypes.SET_BILLING_REMINDER]: setBillingShowReminder,
  [billingATypes.PAUSE_BILLING]: pauseBilling,
  [billingATypes.RESUME_BILLING]: resumeBilling,
  [billingATypes.GET_BILLING_POSITIONS_SUCCEEDED]: getBillingPositionsSucceeded,
  [billingATypes.GET_PHYSICIAN_TYPES_SUCCEEDED]: getPhysicianTypesSucceeded,
  [billingATypes.SELECT_PHYSICIAN_TYPE]: selectPhysicianType
}

export default function billingReducer(state: BillingStateType = initialState, action: ActionType): BillingStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
