export default {
  en: {
    newCaseForm: {
      caseName: 'Case Name',
      clinicalData: 'Clinical Data',
      clinicalDataHelp: 'Type a free text term for the data and value (e.g., Weight, 75 kg)',
      save: 'Save',
      addDataLine: 'Add clinical data line'
    },
    editCaseForm: {},
    newCase: {
      tooltip: 'Create new case',
      title: 'Create new case',
      titleClinicalData: 'Clinical Data',
      created: 'New Case created',
      edited: 'Case edited'
    },
    showClinicalData: 'Show clinical data',
    showCaseInformation: 'Show case information'
  },
  de: {
    newCaseForm: {
      caseName: 'Case Name',
      clinicalData: 'Klinische Daten',
      clinicalDataHelp: 'Geben sie einen beliebigen Text ein (z.B. Gewicht 75kg)',
      save: 'Speichern',
      addDataLine: 'Zeile hinzufügen'
    },
    editCaseForm: {},
    newCase: {
      tooltip: 'Neuen Case erstellen',
      title: 'Neuen Case erstellen',
      titleClinicalData: 'Klinische Daten',
      nameHelp: 'Type a name for the case, e.g., "Patient with low blood pressure"',
      dataTitle: 'Clinical Data',
      created: 'Neuer Case wurde erstellt',
      edited: 'Case geändert'
    },
    showClinicalData: 'Klinische Daten anzeigen',
    showCaseInformation: 'Case Informationen anzeigen'
  }
}
