// @flow
import type { ActionType, ExtractReturn } from 'common-docdok/src/types'
import { chatActionTypes, chatActions } from '../actions'

const VideoConferenceState = { initial: 0, invited: 1, clicked: 2 }

export type InvitationStateType = {chatRedirectUrl: String, invitationState: number}

export type ChatStateType = {
  showRequestAttentionDialog: boolean,
  showEditCaseDialog: boolean,
  showFilterMessagesDialog: boolean,
  showAppointmentDialog: boolean,
  videoCallbacks: Object
}

const initialState: ChatStateType = {
  showRequestAttentionDialog: false,
  showEditCaseDialog: false,
  showFilterMessagesDialog: false,
  showAppointmentDialog: false,
  videoCallbacks: {}
}

function toggleRequestAttentionDialog(state: ChatStateType): ChatStateType {
  return {
    ...state,
    showRequestAttentionDialog: !state.showRequestAttentionDialog
  }
}

function toggleEditCaseDialog(state: ChatStateType): ChatStateType {
  return {
    ...state,
    showEditCaseDialog: !state.showEditCaseDialog
  }
}

function toggleFilterMessagesDialog(state: ChatStateType): ChatStateType {
  return {
    ...state,
    showFilterMessagesDialog: !state.showFilterMessagesDialog
  }
}

function toggleAppointmentDialog(state: ChatStateType): ChatStateType {
  return {
    ...state,
    showAppointmentDialog: !state.showAppointmentDialog
  }
}

function inviteToVideoConferenceSucceeded(state: ChatStateType,
  action: ExtractReturn<typeof chatActions.invite2VideoConferenceSucceeded>): ChatStateType {
  const { conversationId, chatRedirectUrl } = action.payload
  const newState = { ...state }

  const invitationState = { chatRedirectUrl, invitationState: VideoConferenceState.invited }

  return {
    ...newState,
    videoCallbacks: { ...newState.videoCallbacks, [conversationId]: invitationState }
  }
}

function inviteToVideoConferenceLinkClicked(state: ChatStateType, action: any) {
  const newState = { ...state }
  const { conversationId } = action.payload

  const invitationState = { invitationState: VideoConferenceState.clicked }

  return {
    ...newState,
    videoCallbacks: { ...newState.videoCallbacks, [conversationId]: invitationState }
  }
}

function joinVideoConferenceSucceeded(
  state: ChatStateType, action: ExtractReturn<typeof chatActions.joinVideoConferenceSucceeded>): ChatStateType {
  const newState = { ...state }
  const { conversationId } = action.payload

  const invitationState = { invitationState: VideoConferenceState.initial }

  return {
    ...newState,
    videoCallbacks: { ...newState.videoCallbacks, [conversationId]: invitationState }
  }
}

const ACTION_HANDLERS = {
  [chatActionTypes.TOGGLE_REQUEST_ATTENTION_DIALOG]: toggleRequestAttentionDialog,
  [chatActionTypes.TOGGLE_EDIT_CASE_DIALOG]: toggleEditCaseDialog,
  [chatActionTypes.TOGGLE_FILTER_MESSAGES_DIALOG]: toggleFilterMessagesDialog,
  [chatActionTypes.TOGGLE_APPOINTMENT_DIALOG]: toggleAppointmentDialog,
  [chatActionTypes.INVITE_AND_JOIN_VIDEOCONFERENCE_SUCCEEDED]: inviteToVideoConferenceSucceeded,
  [chatActionTypes.VIDEOCHAT_INVITATION_CLICKED]: inviteToVideoConferenceLinkClicked,
  [chatActionTypes.JOIN_VIDEO_CONFERENCE_SUCCEEDED]: joinVideoConferenceSucceeded
}

export default function messagesReducer(state: ChatStateType = initialState, action: ActionType): ChatStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
