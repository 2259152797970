// @flow
import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { Translate } from 'react-redux-i18n'
import withMobileDialog from '@material-ui/core/withMobileDialog'

import DynamicForm from '../../DynamicForm'
import { type OwnFormPropsType } from '../../DynamicForm/containers/DynamicForm'

import { DialogHeader } from '../../Mui'

type PropsType = {
  open: boolean,
  handleClose: () => any,
  handleDelete?: () => any,
  titleKey: string,
  width?: number,
  fullScreen: boolean,
  wizard: boolean,
  fullWidth: Boolean,
  maxWidth: string,
  deleteAtFooter: Boolean,
  dontAutoFocus?: Boolean
} & OwnFormPropsType

function dynamicFormDialog(props: PropsType) {
  const {
    open,
    titleKey,
    handleClose,
    form,
    fields,
    showReset,
    initialValues,
    saveLabelKey,
    render,
    validator,
    fullScreen,
    addionalButtons,
    fullWidth,
    maxWidth,
    reduxFromConfig,
    handleDelete,
    dontAutoFocus
  } = props

  return (
    <Dialog
      classes={{
        paper: `modal-for-form-${form}`
      }}
      id={`dialog-${form}`}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      scroll="paper"
      onEscapeKeyDown={handleClose}
      open={open}
    >
      <DialogHeader name={form} onClose={handleClose} onDelete={handleDelete}>
        <Translate value={titleKey} />
      </DialogHeader>
      <DynamicForm
        indentForm
        addionalButtons={addionalButtons}
        form={form}
        fields={fields}
        showReset={showReset}
        initialValues={initialValues}
        saveLabelKey={saveLabelKey}
        render={render}
        validator={validator}
        dontAutoFocus={dontAutoFocus}
        {...reduxFromConfig}
      />
    </Dialog>
  )
}

dynamicFormDialog.defaultProps = {
  showReset: false,
  initialValues: undefined,
  render: undefined,
  addionalButtons: undefined,
  autoScroll: true,
  wizard: undefined,
  fullWidth: false,
  maxWidth: undefined,
  handleDelete: null,
  dontAutoFocus: false
}

export default withMobileDialog()(dynamicFormDialog)
