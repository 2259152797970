// @flow
import React from 'react'
import { fields } from 'common-docdok/src/domain/survey/types/surveyParticipationDto'
import { surveyActions } from 'common-docdok/src/domain/survey/actions'
import type { SurveyParticipationDtoType } from 'common-docdok/src/domain/survey/types/surveyParticipationDto'
import api from 'common-docdok/src/api'
import Paper from '@material-ui/core/Paper'
import { Translate } from 'react-redux-i18n'
import Launch from '@material-ui/icons/Launch'
import ViewList from '@material-ui/icons/ViewList'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import { connect } from 'react-redux'

import EntityTable from '../../../../../common/EntityTable'
import { UserName } from '../../../../../common/UserName'
import palette from '../../../../../styles/palette'
import { LAUNCH_SURVEY, VIEW_SURVEY_RESPONSES } from '../../../../../common/Security/actionNames'
import SurveyResponsesDialog from './surveyResponsesDialog'
import { surveysActions } from '../actions'
import { TextLine, PageTitle, GreyBar, Toolbar, ToolbarGroup } from '../../../../../common/Static'
import { launchSurveyActions, launchSurveyActionTypes } from '../../../../../common/LaunchSurvey/actions'
import { canLaunchSurvey } from '../../../../../common/LaunchSurvey/utils'
import SurveyScore from '../../../../../common/Survey/containers/SurveyScore'
import Timepoint from '../../../../../common/Survey/containers/Timepoint'
import generatePDF from '../utils/PdfUtils'

type PropsType = {
  surveyName: string,
  params: {
    surveyId: string,
    filter: string
  },
  launchSurvey: typeof launchSurveyActions.launchSurvey,
  toggleSurveyResponsesDialog: typeof surveysActions.toggleSurveyResponsesDialog,
  getSurveyDefinitionsByIdRequested: typeof surveyActions.getSurveyDefinitionsByIdRequested,
  userAuthorities: Array<string>
}

class Participants extends React.Component<PropsType> {
  static statusRenderer(val: any, col: string, entity: string, row: Object) {
    let style = {
      color: palette.blueDark
    }
    let result = 'incomplete'
    if (row.completionDate != null) {
      result = 'complete'
      style = {
        color: palette.blueLight
      }
    }
    return (
      <TextLine>
        <Translate style={style} value={`domain.entity.surveyParticipation.fields.statusOpts.${result}`} />
      </TextLine>
    )
  }

  loadSurvey() {
    const {
      params: { surveyId },
      surveyName,
      getSurveyDefinitionsByIdRequested
    } = this.props

    if (!surveyName) {
      getSurveyDefinitionsByIdRequested([Number(surveyId)])
    }
  }

  componentDidMount() {
    this.loadSurvey()
  }

  apiCall = (pageSize?: number = 1000, page?: number = 0, search?: string, orderBy?: string) => {
    const {
      params: { surveyId, filter },
      location
    } = this.props
    const queryObj = new URLSearchParams(location.search)

    let query = orderBy || ''
    if (filter === 'completed') {
      query += `&patient_uuid=${queryObj.get('patient_uuid')}&status=completed`
    }
    const promis = api.getSurveyParticipations(surveyId, pageSize, page, search, query).then(result => ({
      ...result,
      data: result.data
    }))
    return promis
  }

  render() {
    const {
      params: { surveyId, filter },
      surveyName,
      toggleSurveyResponsesDialog,
      launchSurvey,
      userAuthorities
    } = this.props

    return (
      <div>
        <PageTitle>{surveyName}</PageTitle>
        <Paper>
          <GreyBar>
            <Toolbar>
              <ToolbarGroup />
            </Toolbar>
          </GreyBar>
          <EntityTable
            entity='surveyParticipation'
            id={`surveyParticipation${surveyId}${filter || ''}`}
            api={this.apiCall}
            defaultSort='creationDate'
            refreshActions={[launchSurveyActionTypes.LAUNCH_SURVEY_CLOSED]}
            hideRowCheckbox
            columns={{
              patientUuid: {
                ...fields.patientUuid,
                renderer: (val: any) => <UserName patientUuid={val} />
              },
              creationDate: {
                type: 'Date'
              },
              completionDate: {
                ...fields.completionDate
              },
              senderRef: {
                ...fields.senderRef,
                renderer: (val: any) => <UserName userRef={val} />
              },
              submitterRef: {
                ...fields.submitterRef,
                renderer: (val: any) => <UserName userRef={val} />
              },
              status: {
                // calculated filed
                renderer: Participants.statusRenderer,
                orderBy: 'completionDate'
              },
              differenceDays: {
                ...fields.differenceDays,
                renderer: (val: any) => <Timepoint differenceDays={val} />
              },
              score: {
                ...fields.score,
                sortable: false,
                renderer: (val: any) => <SurveyScore scores={val} />
              }
            }}
            actions={[
              {
                icon: <ViewList color='primary' />,
                name: VIEW_SURVEY_RESPONSES,
                tooltipKey: 'surveys.actions.viewResponses',
                enabled: (row: SurveyParticipationDtoType) => row.completionDate != null,
                action: (row) => {
                  toggleSurveyResponsesDialog(row.id)
                }
              },
              {
                icon: <PictureAsPdfIcon />,
                name: VIEW_SURVEY_RESPONSES,
                tooltipKey: 'surveys.actions.downloadResults',
                enabled: (row: SurveyParticipationDtoType) => row.completionDate != null,
                action: (row) => {
                  generatePDF(row.id, row.surveyName)
                }
              },
              {
                icon: <Launch color='primary' />,
                name: LAUNCH_SURVEY,
                tooltipKey: 'surveys.actions.launchSurvey',
                enabled: (row: SurveyParticipationDtoType) => row.completionDate == null,
                action: (row: SurveyParticipationDtoType) => {
                  launchSurvey(row.id)
                },
                hidden: (row: SurveyParticipationDtoType) => !canLaunchSurvey(userAuthorities)(row.writeAccess)
              }
            ]}
          />
          <SurveyResponsesDialog />
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state: StoreType, ownProps) => ({
  surveyName:
    state.survey.surveyDefinitionsById[ownProps.params.surveyId]
    && state.survey.surveyDefinitionsById[ownProps.params.surveyId].name,
  userAuthorities: state.profile ? state.profile.authorities : []
})

export default connect(
  mapStateToProps,
  {
    launchSurvey: launchSurveyActions.launchSurvey,
    toggleSurveyResponsesDialog: surveysActions.toggleSurveyResponsesDialog,
    getSurveyDefinitionsByIdRequested: surveyActions.getSurveyDefinitionsByIdRequested
  }
)(Participants)
