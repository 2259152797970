// @flow
import { put, takeEvery } from 'redux-saga/effects'
import { stopSubmit } from 'redux-form'
import { messagingActions } from 'common-docdok/src/domain/messaging/actions'
import type { GenType } from 'common-docdok/src/types'
import { asyncAction } from 'common-docdok/src/utils/sagaUtils'

import { infoPanelActions } from '../../InfoPanel/actions'
import { dynamicFormActionTypes, type SubmitFormType } from '../../DynamicForm/actions'

import { conversationEditorActions } from '../actions'

export function* editConversationGen(action: SubmitFormType): GenType {
  const {
    payload: { name, data }
  } = action
  if (name === 'editConversation') {
    const { title, users, conversationId } = data
    const userRefs = users.map(u => u.userRef)
    const winner = yield asyncAction(messagingActions.editConversationRequested(conversationId, title, userRefs))

    if (winner.succeeded) {
      yield put(conversationEditorActions.setEditConversation(false))
      yield put(infoPanelActions.showMessage('messages.editConversation.edited'))
    } else {
      yield put(stopSubmit(name, winner.failed.payload.error))
    }
  }
}

export default function subscriptionsSaga(): Array<GenType> {
  return [takeEvery(dynamicFormActionTypes.SUBMIT_FORM, editConversationGen)]
}
