// @flow
import type { ActionType } from 'common-docdok/src/types'
import { infoPanelActionTypes } from '../actions'

// State Definition
export type InfoPanelStateType = {
  count: number,
  message: ?Object
}

const initialState: InfoPanelStateType = {
  count: 0,
  message: undefined
}

// ------------------------------------
// Action Handlers (Reducers)
// ------------------------------------
const ACTION_HANDLERS = {
  [infoPanelActionTypes.SHOW_MESSAGE]: (state, action) => ({
    count: state.count + 1,
    message: action.payload
  })
}

// ------------------------------------
// Reducer
// ------------------------------------

export default function infoPanelReducer(state: InfoPanelStateType = initialState, action: ActionType) {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
