// @flow
import React from 'react'
import { connect } from 'react-redux'

import DynamicFormDialog from '../../DynamicFormDialog'
import { exportStudyDialogActions } from '../actions'

type ExportStudyDialogPropsType = {
  open: boolean,
  toggleExportStudyDialog: typeof exportStudyDialogActions.toggleExportStudyDialog
}

function ExportStudyDialog(props: ExportStudyDialogPropsType) {
  return (
    <DynamicFormDialog
      open={props.open}
      handleClose={() => props.toggleExportStudyDialog()}
      titleKey='surveys.studyReport.title'
      form='studyReport'
      saveLabelKey='actions.export'
      fields={{
        studyId: {
          type: 'select',
          specName: 'study',
          mandatory: true
        },
        studyReportDefinitionId: {
          type: 'select',
          specName: 'studyReportDefinition',
          mandatory: true
        }
      }}
    />
  )
}

const mapStateToProps = (state: StoreType) => ({
  open: state.exportStudyDialog.showExportStudyDialog
})

export default connect(
  mapStateToProps,
  { toggleExportStudyDialog: exportStudyDialogActions.toggleExportStudyDialog }
)(ExportStudyDialog)
