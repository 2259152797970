// @flow
import { takeEvery, put } from 'redux-saga/effects'
import { keycloakActions } from 'common-docdok/src/common/Keycloak/actions'
import type { GenType } from 'common-docdok/src/types'

import { headerActionsTypes } from '../actions'

export function* declineTerms(): GenType {
  yield put(keycloakActions.logout())
}
export default function termsDialogSaga(): Array<GenType> {
  return [takeEvery(headerActionsTypes.DECLINE_TERMS, declineTerms)]
}
