// @flow
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import type { CommonStateType, ExtractReturn, GenType } from '../../../types'
import api from '../../../api'
import { healthrelationActions, healthrelationActionTypes } from '../actions'
import { userActions } from '../../user/actions'
import { takeEverySync } from '../../../utils/sagaUtils'

// -- APPEND GENERATORS HERE --
function* loadProfessionals(
  action: ExtractReturn<typeof healthrelationActions.loadProfessionalsRequested>
): GenType {
  const ids = action.payload
  const results = yield all(ids.map(id => call(api.getHealthcareProfessional, id)))

  const professionals = results.map(r => r.data)
  yield put(userActions.loadUsersRequested(professionals.map(p => p.userRef)))
  yield put(healthrelationActions.loadProfessionalsSucceeded(professionals))
}

function* loadProfessionalsByPublicUuid(
  action: ExtractReturn<typeof healthrelationActions.loadProfessionalsByPublicUuidRequested>
): GenType {
  const ids = action.payload
  try {
    const results = yield all(ids.map(id => call(api.getHealthcareProfessionalByPublicUuid, id)))
    const professionals = results.map(r => r.data)
    yield put(userActions.loadUsersRequested(professionals.map(p => p.userRef)))
    yield put(healthrelationActions.loadProfessionalsByPublicUuidSucceeded(professionals))
  } catch
  (error) {
    yield put(healthrelationActions.loadProfessionalsByPublicUuidFailed(error))
  }
}

function* loadMissingProfessionals(
  action: ExtractReturn<typeof healthrelationActions.loadMissingProfessionals>
): GenType {
  const professionalIds = action.payload
  const currentProfessionalsIds = yield select((state: CommonStateType) =>
    Object.keys(state.healthrelationCache.professionals)
  )
  const idsToLoad = professionalIds.filter(id => id && !currentProfessionalsIds.includes(id))
  if (idsToLoad.length > 0) {
    yield put(healthrelationActions.loadProfessionalsRequested(idsToLoad))
  }
}

function* createPhysicianRequested(
  action: ExtractReturn<typeof healthrelationActions.createPhysicianRequested>
): GenType {
  try {
    const { payload } = action
    const createdPhysician = yield call(api.createProfessional, payload)
    yield put(healthrelationActions.createPhysicianSucceeded(createdPhysician))
  } catch (error) {
    yield put(healthrelationActions.createPhysicianFailed(error))
  }
}

function* createMpaRequested(
  action: ExtractReturn<typeof healthrelationActions.createMpaRequested>
): GenType {
  try {
    const { payload } = action
    const createdMpa = yield call(api.createProfessional, payload)
    yield put(healthrelationActions.createMpaSucceeded(createdMpa))
  } catch (error) {
    yield put(healthrelationActions.createMpaFailed(error))
  }
}

function* requestAttention(
  action: ExtractReturn<typeof healthrelationActions.requestAttentionRequested>
): GenType {
  const { patientUuid, receiverIds, message } = action.payload
  try {
    yield all(receiverIds.map(toUuid => call(api.requestAttention, patientUuid, toUuid, message)))
    yield put(healthrelationActions.requestAttentionSucceeded())
  } catch (error) {
    yield put(healthrelationActions.requestAttentionFailed(error))
  }
}

export default function professionalsSaga(): Array<GenType> {
  return [
    // -- APPEND TAKES HERE --
    takeEvery(healthrelationActionTypes.CREATE_PHYSICIAN_REQUESTED, createPhysicianRequested),
    takeEvery(healthrelationActionTypes.CREATE_MPA_REQUESTED, createMpaRequested),
    takeEvery(healthrelationActionTypes.LOAD_PROFESSIONALS_REQUESTED, loadProfessionals),
    takeEvery(healthrelationActionTypes.LOAD_PROFESSIONALS_BY_PUBLICUUID_REQUESTED, loadProfessionalsByPublicUuid),
    takeEvery(healthrelationActionTypes.REQUEST_ATTENTION_REQUESTED, requestAttention),
    takeEverySync(healthrelationActionTypes.LOAD_MISSING_PROFESSIONALS, loadMissingProfessionals)
  ]
}
