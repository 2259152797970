export default {
  en: {
    name: 'Participation',
    fields: {
      completionDate: 'Completion date',
      creationDate: 'Sentdate',
      id: 'Id',
      lsToken: 'Token',
      participantName: 'Participant',
      senderRef: 'Sender',
      surveyId: 'Survey-Id',
      surveyName: 'SurveyName',
      url: 'Url',
      userRef: 'Participant',
      submitterRef: 'Completed by',
      patientUuid: 'Participant',
      status: 'Status',
      statusOpts: {
        complete: 'Complete',
        incomplete: 'Incomplete'
      },
      writeAccess: 'Respondent',
      writeAccessOpts: {
        PATIENT_AND_HCP: 'Patient or Professional',
        PATIENT_ONLY: 'Patient',
        HCP_ONLY: 'Professional'
      },
      score: 'Score',
      differenceDays: 'Timepoint'
    }
  },
  de: {
    name: 'Teilnahme',
    fields: {
      completionDate: 'Abschlussdatum',
      creationDate: 'Versanddatum',
      id: 'Id',
      lsToken: 'Token',
      participantName: 'Teilnehmer',
      senderRef: 'Absender',
      surveyId: 'Survey-Id',
      surveyName: 'Umfrage',
      url: 'Url',
      userRef: 'Teilnehmer',
      patientUuid: 'Teilnehmer',
      submitterRef: 'Abgeschlossen von',
      status: 'Status',
      statusOpts: {
        complete: 'Abgeschlossen',
        incomplete: 'Offen'
      },
      writeAccess: 'Befragter',
      writeAccessOpts: {
        PATIENT_AND_HCP: 'Patient or Fachkraft',
        PATIENT_ONLY: 'Patient',
        HCP_ONLY: 'Fachkraft'
      },
      score: 'Score',
      differenceDays: 'Zeitpunkt'
    }
  }
}
