// @flow
import { connect } from 'react-redux'
import pathOr from 'ramda/src/pathOr'

import surveyResponsesDialog from '../components/surveyResponsesDialog'
import { surveysActions } from '../actions'

const mapStateToProps = (state: StoreType) => {
  const {
    surveys: { participationId, showSurveyResponsesDialog }
  } = state

  const responses = pathOr({}, ['survey', 'surveyResponses', participationId])(state)

  return {
    showSurveyResponsesDialog,
    participationId,
    responses
  }
}

export default connect(
  mapStateToProps,
  { toggleSurveyResponsesDialog: surveysActions.toggleSurveyResponsesDialog }
)(surveyResponsesDialog)
