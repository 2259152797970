import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { browserHistory } from 'react-router'
import createSagaMiddleware from 'redux-saga'
import { loadTranslations, syncTranslationWithStore } from 'react-redux-i18n'
import { createLogger } from 'redux-logger'

import addIdleDetector from './idleDetector'
import translations from '../i18n'
import rootSaga from './rootSaga'
import makeRootReducer from './reducers'
import { updateLocation } from './location'

import type { StateType } from './storeDefinition'
import { isStageOrProd } from '../common/utils'

export const storeContainer = {
  store: undefined
}

export default (initialState = {}) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  // add your swager url
  // const middleware = [thunk, swaggerClient({ url: 'http://petstore.swagger.io/v2/swagger.json' })]
  const sagaMiddleware = createSagaMiddleware()
  const middleware = [thunk, sagaMiddleware]

  if (__DEV__ && !__TEST__) {
    middleware.push(createLogger())
  }

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = []

  let composeEnhancers = compose

  if (__DEV__) {
    const composeWithDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    if (typeof composeWithDevToolsExtension === 'function') {
      composeEnhancers = composeWithDevToolsExtension
    }
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================

  const store = createStore(
    makeRootReducer(),
    initialState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers)
  )
  store.asyncReducers = {}

  // To unsubscribe, invoke `store.unsubscribeHistory()` anytime
  store.unsubscribeHistory = browserHistory.listen(updateLocation(store))

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default
      store.replaceReducer(reducers(store.asyncReducers))
    })
  }

  // setLanguage
  syncTranslationWithStore(store)
  store.dispatch(loadTranslations(translations))

  if (!__TEST__) {
    let sagaTask = sagaMiddleware.run(rootSaga)

    if (module.hot) {
      // Enable Webpack hot module replacement for sagas
      module.hot.accept('./rootSaga', () => {
        sagaTask.cancel()
        sagaTask.done.then(() => {
          const getNewSagas = require('./rootSaga').default
          sagaTask = sagaMiddleware.run(getNewSagas)
        })
      })
    }
  }

  if (!isStageOrProd()) {
    // expose the store if it is not on prod
    window.redux_store = store
  }

  addIdleDetector(store)

  storeContainer.store = store

  return store
}

export function subscribe(actionType: string, func: (state: StateType) => void) {
  const { store } = storeContainer
  return store.subscribe(() => {
    const state = store.getState()
    const { lastAction } = state
    if (lastAction === actionType) {
      func(state)
    }
  })
}
