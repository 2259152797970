// @flow
import type { SurveyScheduleSpecType } from 'common-docdok/src/domain/survey/types/surveyScheduleSpec'
import { timeStringToDate, dateToTimeString } from '../../utils'

function toString(value: any) {
  let result
  if (value !== undefined) {
    result = `${value}`
  }
  return result
}

export function scheduleModelToForm(schedule: SurveyScheduleSpecType) {
  if (!schedule) {
    return {}
  }

  const { startDelay, dayIncrement, endOccurence, time, fixedDaysFromStart, immediately } = schedule

  let startAt = startDelay === 0 ? 'whenAdded' : 'daysAfterAdded'
  const daysAfterAdded = startDelay
  if (immediately) {
    startAt = 'instant'
  }

  let pattern = 'noRecurrence'
  let interval = dayIncrement
  if (dayIncrement > 0) {
    if (dayIncrement % 7 === 0) {
      pattern = 'weekly'
      interval = dayIncrement / 7
    } else {
      pattern = 'daily'
      interval = dayIncrement
    }
  }

  const numberOfRecurrences = endOccurence
  let customRecurrence
  if (fixedDaysFromStart && fixedDaysFromStart.length > 0) {
    pattern = 'customRecurrence'
    customRecurrence = fixedDaysFromStart.map((days) => {
      let multiplier = 1
      let delay = days
      if (days > 0 && days % 7 === 0) {
        multiplier = 7
        delay = `${days / 7}`
      }
      return { multiplier, delay }
    })
  }

  return {
    doSchedule: true,
    startAt: toString(startAt),
    startDelay: toString(startDelay),
    pattern,
    interval: toString(interval),
    numberOfRecurrences: toString(numberOfRecurrences),
    customRecurrence,
    daysAfterAdded: toString(daysAfterAdded),
    time: timeStringToDate(time)
  }
}

export function formToScheduleModel(form: Object) {
  const { doSchedule, time, startAt, daysAfterAdded, pattern, interval, numberOfRecurrences, customRecurrence } = form

  if (!doSchedule) {
    return undefined
  }

  const startDelayVal = ['whenAdded', 'instant'].includes(startAt) ? 0 : Number(daysAfterAdded)
  const immediately = startAt === 'instant'
  const recurrence = {}

  if (pattern === 'weekly') {
    recurrence.dayIncrement = Number(interval) * 7
    recurrence.endOccurence = Number(numberOfRecurrences)
  } else if (pattern === 'daily') {
    recurrence.dayIncrement = Number(interval)
    recurrence.endOccurence = Number(numberOfRecurrences)
  } else if (pattern === 'customRecurrence') {
    recurrence.fixedDaysFromStart = customRecurrence.map(r => Number(r.delay) * Number(r.multiplier))
  } else if (pattern === 'noRecurrence') {
    recurrence.endOccurence = 1
  }

  return {
    immediately,
    startDelay: startDelayVal,
    time: dateToTimeString(time),
    ...recurrence
  }
}
