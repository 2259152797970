// @flow
import * as React from 'react'
import { Link } from 'react-router'

type PropsType = {
  className?: string,
  id?: string,
  children: React.Node,
  visible: boolean,
  to: string
}

export default function checkedLink(props: PropsType) {
  const { children, to, visible, className, id } = props
  return visible ? (
    <Link className={className} id={id} to={to} style={{ display: 'inline-block', width: '100%' }}>
      {children}
    </Link>
  ) : (
    <span className={className} id={id}>
      {children}
    </span>
  )
}

checkedLink.defaultProps = {
  className: undefined,
  id: undefined
}
