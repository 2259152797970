// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'
import type { StudyDtoType } from 'common-docdok/src/domain/survey/types/studyDto'
import { type SurveyDefinitionSummaryType, fields } from 'common-docdok/src/domain/survey/types/surveyDefinitionSummary'
import api from 'common-docdok/src/api'
import Send from '@material-ui/icons/Send'
import Donwload from '@material-ui/icons/CloudDownload'
import { surveyActionTypes } from 'common-docdok/src/domain/survey/actions'
import Paper from '@material-ui/core/Paper'

import { SEND_SURVEY, DOWNLOAD_STUDY_ANSWERS } from '../../../../../common/Security/actionNames'
import EntityTable from '../../../../../common/EntityTable'
import Action from '../../../../../common/Security/containers/action'

import palette from '../../../../../styles/palette'
import DynamicFormDialog from '../../../../../common/DynamicFormDialog'
import FormField from '../../../../../common/DynamicForm/components/fields/formField'
import { surveysActions } from '../../Surveys/actions'
import CheckedLink from '../../../../../common/Security/containers/checkedLink'
import { renderers } from '../../../../../common/EntityTable/components/EntityTable'
import studyLoader from './studyLoader'
import { TextLine, PageTitle, GreyBar, Toolbar, ToolbarGroup, Buttons } from '../../../../../common/Static'
import { RaisedButton } from '../../../../../common/Mui'
import { studiesActions } from '../actions'

type PropsType = {
  params: { studyId: number },
  setSendSurveyDialog: typeof surveysActions.setSendSurveyDialog,
  showSendSurveyDialog: boolean,
  study: StudyDtoType,
  children: ?any,
  downloadSurveyAnswersRequested: typeof studiesActions.downloadSurveyAnswersRequested
}

type StateType = {
  selection: Array<SurveyDefinitionSummaryType>
}

class StudySurveysToSend extends React.Component<PropsType, StateType> {
  state = {
    selection: []
  }

  static renderSelectionCount(selection: Array<*>, isDisabled: boolean) {
    return <span className='selection-count'>{selection.length > 0 && !isDisabled && ` (${selection.length})`}</span>
  }

  static canSendSurvey(survey) {
    return survey.status === 'RUNNING' && survey.defaultScheduling === null
  }

  static canExportSurvey(survey) {
    return survey.nbCompleted > 0
  }

  render() {
    const { setSendSurveyDialog, study, showSendSurveyDialog, downloadSurveyAnswersRequested, children } = this.props
    if (children) return children
    if (!study) return null

    const { selection } = this.state
    const hasNoSelection = selection.length === 0
    const canNotSendAll = selection.filter(StudySurveysToSend.canSendSurvey).length !== selection.length
    const buttonDisabled = hasNoSelection || canNotSendAll
    return (
      <div>
        <PageTitle>
          <Translate value='studies.surveys.title' />
        </PageTitle>
        <Paper>
          <GreyBar>
            <Toolbar>
              <ToolbarGroup />
              <Buttons>
                <Action name={SEND_SURVEY}>
                  <RaisedButton
                    id='send-survey-button'
                    style={{ width: 250 }}
                    icon={<Send />}
                    label={(
                      <span>
                        <Translate value='surveys.actions.sendSurvey.title' />
                        {StudySurveysToSend.renderSelectionCount(selection, buttonDisabled)}
                      </span>
)}
                    onClick={() => {
                      setSendSurveyDialog(true)
                    }}
                    disabled={buttonDisabled}
                  />
                </Action>
              </Buttons>
            </Toolbar>
          </GreyBar>

          <EntityTable
            entity='survey'
            id='surveyDefintions'
            params={[study.id]}
            api={api.getStudySurveysDefinitions}
            defaultSort='name'
            selection={selection}
            refreshActions={[surveyActionTypes.SEND_SURVEYS_SUCCEEDED]}
            columns={{
              name: {
                ...fields.name,
                orderBy: 'survey.name'
              },
              writeAccess: {
                ...fields.writeAccess
              },
              nbSent: {
                width: 90,
                ...fields.nbSent,
                renderer: (val: any, col: string, entity: string, row: Object) => (
                  <TextLine>
                    <CheckedLink to={`/private/app/studies/${study.id}/surveys/${row.id}/participants`}>
                      {renderers.number(val, col)}
                    </CheckedLink>
                  </TextLine>
                ),
                sortable: false
              },
              nbCompleted: {
                width: 120,
                ...fields.nbCompleted,
                renderer: (val: any, col: string, entity: string, row: Object) => (
                  <TextLine>
                    <CheckedLink to={`/private/app/studies/${study.id}/surveys/${row.id}/participants/completed`}>
                      {renderers.number(val, col)}
                    </CheckedLink>
                  </TextLine>
                ),
                sortable: false
              },
              status: {
                width: 200,
                ...fields.status,
                sortable: false
              }
            }}
            onSelectionChanged={newSelection => this.setState({ selection: newSelection })}
            actions={[
              {
                icon: <Send color={palette.blue} />,
                name: SEND_SURVEY,
                tooltipKey: 'surveys.actions.sendSurvey.title',
                enabled: StudySurveysToSend.canSendSurvey,
                action: (row) => {
                  this.setState({ selection: [row] })
                  setSendSurveyDialog(true)
                }
              },
              {
                icon: <Donwload color={palette.blue} />,
                name: DOWNLOAD_STUDY_ANSWERS,
                tooltipKey: 'surveys.actions.export.title',
                enabled: StudySurveysToSend.canExportSurvey,
                action: (row) => {
                  downloadSurveyAnswersRequested(study.id, row.id)
                }
              }
            ]}
          />
          <DynamicFormDialog
            open={showSendSurveyDialog}
            form='sendSurvey'
            saveLabelKey='surveys.sendSurvey.title'
            fields={{
              patients: {
                specName: 'studyParticipants',
                type: 'search',
                noSearch: true,
                mandatory: true,
                multiSelectable: true,
                params: [study.id]
              }
            }}
            handleClose={() => setSendSurveyDialog(false)}
            titleKey='surveys.sendSurvey.title'
            initialValues={{ surveys: this.state.selection, doSchedule: false }}
            render={props => (
              <div>
                <Translate value='surveys.sendSurvey.help' />
                <FormField {...props} name='patients' />
              </div>
            )}
          />
        </Paper>
      </div>
    )
  }
}

export default studyLoader(StudySurveysToSend)
