/**
 * @flow
 */
export const exportStudyDialogActionTypes = {
  TOGGLE_EXPORT_STUDY_DIALOG: 'TOGGLE_EXPORT_STUDY_DIALOG'
}

export type ExportStudyDialogActionType = $Keys<typeof exportStudyDialogActionTypes>

export const exportStudyDialogActions = {
  toggleExportStudyDialog() {
    return {
      type: exportStudyDialogActionTypes.TOGGLE_EXPORT_STUDY_DIALOG
    }
  }
}
