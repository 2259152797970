// @flow

import { fields } from 'common-docdok/src/domain/healthrelation/types/healthcareProfessionalDto'
import { Translate } from 'react-redux-i18n'
import api from 'common-docdok/src/api'
import React from 'react'
import Remove from '@material-ui/icons/RemoveCircleOutline'
import PersonAdd from '@material-ui/icons/PersonAdd'
import { surveyActions, surveyActionTypes } from 'common-docdok/src/domain/survey/actions'
import Paper from '@material-ui/core/Paper'
import type { UserDtoType } from 'common-docdok/src/domain/user/types/userDto'
import type { StudyDoctorUserType } from 'common-docdok/src/api/survey'

import EntityTable from '../../../../../common/EntityTable'
import {
  REMOVE_STUDY_PHYSICIAN,
  ADD_STUDY_PHYSICIAN
} from '../../../../../common/Security/actionNames'
import AddPhysicianDialog from './addPhysicianDialog'
import { studiesActions } from '../actions'
import Action from '../../../../../common/Security/containers/action'
import studyLoader from './studyLoader'
import { RaisedButton } from '../../../../../common/Mui'
import {
  GreyBar,
  Toolbar,
  ToolbarGroup,
  Buttons,
  PageTitle
} from '../../../../../common/Static'

type PropsType = {
  children?: any,
  params: { studyId: number },
  removeStudyPhysicianRequested: typeof surveyActions.removeStudyPhysicianRequested,
  physicians: Array<UserDtoType>,
  toggleAddPhysicianDialog: typeof studiesActions.toggleAddPhysicianDialog,
  showAddPhysicianDialog: boolean
}

class StudyPhysicians extends React.Component<PropsType> {
  static defaultProps = {
    children: undefined
  }

  render() {
    const {
      children,
      params: { studyId },
      removeStudyPhysicianRequested,
      physicians,
      toggleAddPhysicianDialog,
      showAddPhysicianDialog
    } = this.props
    if (children) return children
    return (
      <div>
        <PageTitle>
          <Translate value='studies.physicians.title' />
        </PageTitle>
        <Paper>
          <GreyBar>
            <Toolbar>
              <ToolbarGroup />
              <Buttons>
                <Action name={ADD_STUDY_PHYSICIAN}>
                  <RaisedButton
                    id='addStudyDevice-button'
                    label={<Translate value='studies.actions.addStudyPhysicians.title' />}
                    onClick={() => {
                      toggleAddPhysicianDialog()
                    }}
                    icon={<PersonAdd />}
                  />
                </Action>
              </Buttons>
            </Toolbar>
          </GreyBar>

          <EntityTable
            api={() => api.getStudyPhysicians(studyId)}
            id='studyPhysicians'
            hideRowCheckbox
            entity='user'
            refreshActions={[
              surveyActionTypes.ADD_STUDY_PHYSICIAN_SUCCEEDED,
              surveyActionTypes.REMOVE_STUDY_PHYSICIAN_SUCCEEDED
            ]}
            columns={{
              salutation: fields.salutation,
              firstName: fields.firstName,
              lastName: fields.lastName,
              gender: fields.gender,
              email: fields.email,
              mobileNumber: fields.mobileNumber
            }}
            actions={[
              {
                name: REMOVE_STUDY_PHYSICIAN,
                icon: <Remove color='primary' />,
                tooltipKey: 'studies.actions.removeStudyPhysician.tooltip',
                enabled: () => true,
                action: (row: StudyDoctorUserType) => {
                  removeStudyPhysicianRequested(row)
                }
              }
            ]}
          />
        </Paper>
        <AddPhysicianDialog
          studyId={studyId}
          physicians={physicians}
          toggleAddPhysicianDialog={toggleAddPhysicianDialog}
          showAddPhysicianDialog={showAddPhysicianDialog}
        />
      </div>
    )
  }
}

export default studyLoader(StudyPhysicians)
