// @flow
import dateUtils from './dateUtils'

function sortByValue(valA: any, valB: any, reverse?: boolean) {
  const useA = valA < valB
  const useB = valA > valB
  let result = 0
  if (useA) {
    result = -1
  } else if (useB) {
    result = 1
  }
  return reverse ? result * -1 : result
}

function sortByPostedAt(conv1: mixed, conv2: mixed) {
  const c1: any = conv1
  const c2: any = conv2
  const latestPostedAt1 = c1.latestMessage
    ? c1.latestMessage.postedAt
    : '1900-01-01T00:00:00+00:00'
  const latestPostedAt2 = c2.latestMessage
    ? c2.latestMessage.postedAt
    : '1900-01-01T00:00:00+00:00'

  const d1 = dateUtils.toDate(latestPostedAt1)
  const d2 = dateUtils.toDate(latestPostedAt2)

  let result = sortByValue(d1, d2, true)

  if (result === 0) {
    result = sortByValue(c1.title, c2.title)
  }
  return result
}

export default {
  sortByValue,
  sortByPostedAt
}
