// @flow
import React from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import MuiMenu from '@material-ui/core/Menu'
import MuiDialogContent from '@material-ui/core/DialogContent'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

// TODO: why? import { Typography } from '../../../node_modules/@material-ui/core'
import { Typography } from '@material-ui/core'
import palette from '../../styles/palette'

export { default as ExpandButton } from './ExpandButton'
export { default as MediaTitle } from './mediaTitle'
export { default as DialogHeader } from './dialogHeader'
// A function you can extract and put into its own module.
// Yes, 15 lines of code, it's all you need.

type PropsType = {
  classes: Object,
  className: ?string
}
/*
function getCurrentStyle(element: any) {
  return element.currentStyle
}

 function cssToObject(string) {
  const getComputedStyle = window.getComputedStyle || getCurrentStyle

  // creating an empty dummy object to compare with
  const dummy = document.createElement(`dummy-${new Date().getTime()}`)
  const element = document.createElement(`element-${new Date().getTime()}`)
  if (document.body) {
    document.body.appendChild(dummy)
  }
  if (document.body) {
    document.body.appendChild(element)
  }
  element.style.cssText = string

  // getting computed styles for both elements
  const defaultStyles = getComputedStyle(dummy)
  const elementStyles = getComputedStyle(element)

  // calculating the difference
  const styles = {}
  Object.keys(elementStyles).forEach((key) => {
    if (defaultStyles[key] !== elementStyles[key]) {
      styles[key] = elementStyles[key]
    }
  })

  // clear dom
  dummy.remove()
  element.remove()

  // console.log(string)
  // console.log(styles)
  return styles
} */

export function muiStyled(Component: any) {
  return (style: any, options?: Object) => {
    function StyledComponent(props: PropsType) {
      const { classes, className, ...other } = props
      return <Component className={classNames(classes.root, className)} {...other} />
    }
    const styles = typeof style === 'function' ? theme => ({ root: style(theme) }) : { root: style }
    return withStyles(styles, options)(StyledComponent)
  }
}

/**
 * this function has the same interface as styled from styled-component
 * to have a streamlined experience to set styles for components.
 * It converts the css back to the jsobject and uses
 * the withStyles functionality of material-ui
 * if you have complex styles you have to use muiStyled directly
 * @param {} Component
 */
/* export function styled(Component: any) {
  return (strings: Array<string>, ...expressionValues: Array<string>) => {
    const css = strings.reduce((fullCss, str, i) => {
      let newCss = fullCss
      if (i > 0) {
        newCss += expressionValues[i - 1]
      }
      newCss += str
      return newCss
    })

    const style = cssToObject(css)
    return muiStyled(Component)(style)
  }
} */

export const WhiteButton = muiStyled(Button)({
  background: 'white',
  color: 'black'
})

export class UploadButton extends React.Component<Object> {
  input = undefined

  render() {
    const { id, onChange, children, style, ...restProps } = this.props

    return (
      <div>
        <input
          id={id}
          ref={input => (this.input = input)}
          style={{
            display: 'none'
          }}
          {...restProps}
          onChange={() => {
            const { input } = this
            if (input) {
              onChange(input.files || [])
              input.value = null
            }
          }}
        />
        {/* eslint-disable-next-line */}
        <label htmlFor={id}>
          <Button id={`${id}-button`} component='span' style={style} {...restProps}>
            {children}
          </Button>
        </label>
      </div>
    )
  }
}

export const RoundButton = muiStyled(Button)({
  border: `solid 1px ${palette.greyDark}`,
  margin: 6,
  fontSize: '0.8em',
  color: palette.greyDark,
  borderRadius: 10,
  minWidth: 0,
  boxShadow: 'none',
  height: 20,
  lineHeight: 1,
  padding: 3,
  minHeight: 0
})

const raisedButtonStyles = theme => ({
  space: {
    marginRight: theme.spacing.unit
  }
})

const LabelIconButton = ({ classes, label, icon, ...rest }: Object) => (
  <Button variant='contained' color='primary' {...rest}>
    {icon && <icon.type {...icon.props} className={label && classes.space} />}
    {label}
  </Button>
)

export const Menu = ({ onClose, children, ...rest }: Object) => (
  <MuiMenu {...rest} onClose={onClose}>
    <ClickAwayListener onClickAway={onClose}>{children}</ClickAwayListener>
  </MuiMenu>
)

export const RaisedButton = withStyles(raisedButtonStyles)(LabelIconButton)

export const BorderlessCheckbox = muiStyled(Checkbox)({
  width: 20,
  height: 20
})

export const TypographyLight = muiStyled(Typography)({
  color: 'rgba(95, 95, 95, 0.4)'
})

export const DialogContent = muiStyled(MuiDialogContent)({
  '&:first-child': {
    paddingTop: 0
  }
})

export const TypographyBlue = muiStyled(Typography)({
  color: palette.blue
})

export const SublinedTypography = muiStyled(TypographyBlue)({
  borderBottom: '1px solid rgb(235, 235, 235)',
  marginBottom: 10
})
