// @flow
import { put, call, takeEvery, select, all } from 'redux-saga/effects'
import { entityLoaderActionTypes, entityLoaderActions } from '../actions'
import type { ActionType, ExtractReturn, GenType, CommonStateType } from '../../../types'
import type { EntityLoaderStateType, EntityLoaderType } from '../reducers'

export function* getEntitiesGen(
  action: ExtractReturn<typeof entityLoaderActions.getEntitiesRequested>
): GenType {
  const { id, api, page, pageSize, searchTerm, orderBy, params } = action.payload
  try {
    const result = yield call(api, ...params, pageSize, page, searchTerm, orderBy)
    yield put(
      entityLoaderActions.getEntitiesSucceeded(
        id,
        result.data,
        Number(result.headers['x-total-count'])
      )
    )
  } catch (e) {
    yield put(entityLoaderActions.getEntitiesFailed(id, e))
  }
}

export function* refreshActionsGen({ type }: ActionType): GenType {
  const entityLoaders: EntityLoaderStateType = yield select(
    (state: CommonStateType) => state.entityLoader
  )

  const relevantLoaders = Object.values(entityLoaders).filter(
    (l: any) => l.refreshActions !== undefined && l.refreshActions.includes(type)
  )
  yield all(
    relevantLoaders.map((l: any) => {
      const {
        id,
        api,
        pageSize,
        page,
        searchTerm,
        orderBy,
        refreshActions,
        params
      }: EntityLoaderType = l
      return put(
        entityLoaderActions.getEntitiesRequested(
          id,
          api,
          pageSize,
          page,
          searchTerm,
          orderBy,
          refreshActions,
          params
        )
      )
    })
  )
}

export default function entityLoaderSaga(): Array<GenType> {
  return [
    takeEvery(entityLoaderActionTypes.GET_ENTITIES_REQUESTED, getEntitiesGen),
    takeEvery('*', refreshActionsGen)
  ]
}
