// @flow
import type { ActionType, ExtractReturn } from 'common-docdok/src/types'
import type { UserDtoType } from 'common-docdok/src/domain/user/types/userDto'
import { updateUserDialogActionTypes, updateUserDialogActions } from '../actions'

export type UpdateUserDialogStateType = {
  showCreateUserDialog: boolean,
  showUpdateUserDialog: ?UserDtoType
}

const initialState: UpdateUserDialogStateType = {
  showCreateUserDialog: false,
  showUpdateUserDialog: undefined
}

function setUpdateUserDialog(
  state: UpdateUserDialogStateType,
  action: ExtractReturn<typeof updateUserDialogActions.setUpdateUserDialog>
): UpdateUserDialogStateType {
  return {
    ...state,
    showUpdateUserDialog: action.payload
  }
}

const ACTION_HANDLERS = {
  [updateUserDialogActionTypes.SET_UPDATE_USER_DIALOG]: setUpdateUserDialog
}

export default function usersReducer(
  state: UpdateUserDialogStateType = initialState,
  action: ActionType
): UpdateUserDialogStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
