// @flow
import React from 'react'
import { connect } from 'react-redux'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Translate } from 'react-redux-i18n'
import { browserHistory } from 'react-router'
import type { MessageDtoType } from 'common-docdok/src/domain/messaging/types/messageDto'
import UserAvatar from '../../UserAvatar'


export const mapStateToProps = (state: StoreType, ownProps: { message: MessageDtoType }) => {
  const user = state.userCache.users[ownProps.message.userRef]
  const survey = state.survey.surveyDefinitionsById[ownProps.message.meta.surveyId]
  return { user, survey }
}

export default connect(mapStateToProps)((props) => {
  let result = null
  const {
    survey,
    user,
    message: {
      meta: { patientuuid }
    },
    closeRequest
  } = props
  if (survey && user) {
    result = (
      <ListItem
        button
        id='last-protocol-survey-sent-notification'
        onClick={() => {
          browserHistory.push(`/private/app/patients/${patientuuid}`)
          if (closeRequest) closeRequest()
        }}
      >
        <UserAvatar
          size={34}
          src={user ? user.avatarPicture : undefined}
          name={user ? user.firstName : ''}
        />
        <ListItemText
          primary={(
            <Translate
              value='surveys.notifications.lastProtocolSurveySent'
              user={user ? `${user.firstName} ${user.lastName}` : ''}
              survey={survey ? survey.name : ''}
            />
)}
        />
      </ListItem>
    )
  }
  return result
})
