// @flow
import type { GenType } from 'common-docdok/src/types'
import { put, call, all } from 'redux-saga/effects'
import keycloakSaga from 'common-docdok/src/common/Keycloak/sagas'
import messagingSagas from 'common-docdok/src/domain/messaging/sagas'
import usersRootSaga from 'common-docdok/src/domain/user/sagas'
import surveyCommonSaga from 'common-docdok/src/domain/survey/sagas'
import healthrelationSaga from 'common-docdok/src/domain/healthrelation/sagas'
import rightsSaga from 'common-docdok/src/common/Security/sagas/rights'
import entityLoaderSaga from 'common-docdok/src/common/EntityLoader/sagas'
import PersistStore from 'common-docdok/src/configuration/persistStore'
// -- APPEND IMPORTS HERE --
import catalogSaga from '../routes/private/app/Catalog/sagas'
import usersSaga from '../routes/private/app/Users/sagas'
import patientsSage from '../routes/private/app/Patients/sagas'
import professionalsSaga from '../routes/private/app/Professionals/sagas'
import profileSaga from '../routes/private/app/Profile/sagas'
import surveysSaga from '../routes/private/app/Surveys/sagas'
import studiesSaga from '../routes/private/app/Studies/sagas'
import messagesSaga from '../routes/private/app/Messages/sagas'
import clinicsSaga from '../routes/private/app/Clinics/sagas'

import selectedPatientSaga from '../common/SelectedPatient/sagas'
import notificationsSaga from '../common/Notifications/sagas'
import headerSaga from '../common/Header/sagas'
import billingRootSaga from '../common/Billing/sagas'
import { infoPanelActions } from '../common/InfoPanel/actions'
import sentriSaga from './sentriSaga'
import langSaga from './langSaga'
import introSaga from '../common/Intro/sagas'
import conversationEditorSaga from '../common/ConversationEditor/sagas'
import chatSaga from '../common/Chat/sagas'
import createPatientButtonSaga from '../common/CreatePatientButton/sagas'
import createCaseButtonSaga from '../common/CreateCaseButton/sagas'
import addToStudyDialogSaga from '../common/AddToStudyDialog/sagas'
import exportStudyDialogSaga from '../common/ExportStudyDialog/sagas'
import launchSurveySaga from '../common/LaunchSurvey/sagas'
import createUserDialogSaga from '../common/CreateUserDialog/sagas'
import updateUserDialogSaga from '../common/UpdateUserDialog/sagas'
import uploadSurveyDialogSaga from '../common/UploadSurveyDialog/sagas'
import eventHandlerDialogSaga from '../common/EventDialog/sagas'
import scheduleSurveyDialogSaga from '../common/ScheduleSurveyDialog/sagas'
import ambulanceQueueSaga from '../common/AmbulanceQueue/sagas'
import socketSaga from '../sockets/sagas'

declare var __DEV__: any
declare var Raven: any
declare var __TEST__: any

function autoRestart(generator) {
  if (__DEV__) {
    return generator
  }

  return function* autoRestarting(...args: any): GenType {
    while (true) {
      try {
        yield call(generator, ...args)
      } catch (e) {
        Raven.captureException(e)
        yield put(infoPanelActions.showMessage(e))
      }
    }
  }
}

// register all sagas
function* rootSaga(): GenType {
  const sagas = [
    ...PersistStore.persistSagas(),
    // --- APPEND SAGAS HERE --
    ...eventHandlerDialogSaga(),
    ...uploadSurveyDialogSaga(),
    ...scheduleSurveyDialogSaga(),
    ...updateUserDialogSaga(),
    ...createUserDialogSaga(),
    ...addToStudyDialogSaga(),
    ...exportStudyDialogSaga(),
    ...launchSurveySaga(),
    ...chatSaga(),
    ...createPatientButtonSaga(),
    ...createCaseButtonSaga(),
    ...selectedPatientSaga(),
    ...conversationEditorSaga(),
    ...catalogSaga(),
    ...billingRootSaga(),
    ...clinicsSaga(),
    ...sentriSaga(),
    ...rightsSaga(),
    ...entityLoaderSaga(),
    ...professionalsSaga(),
    ...usersRootSaga(),
    ...usersSaga(),
    ...profileSaga(),
    ...messagingSagas(),
    ...messagesSaga(),
    ...notificationsSaga(),
    ...surveysSaga(),
    ...surveyCommonSaga(),
    ...studiesSaga(),
    ...healthrelationSaga(),
    ...patientsSage(),
    ...headerSaga(),
    ...langSaga(),
    ...introSaga(),
    ...socketSaga(),
    ...ambulanceQueueSaga()
  ]

  if (!__TEST__) {
    sagas.push(...keycloakSaga())
  }

  yield all(sagas)
}

export default autoRestart(rootSaga)
