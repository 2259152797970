export default {
  en: {
    actions: {
      addEvent: {
        title: 'Create new event',
        success: 'New event has been successfully created'
      },
      editEvent: {
        title: 'Edit event',
        success: 'The event successfully updated'
      },
      deleteEvent: {
        warning:
          'The event is part of a series do you want to delete only this event ' +
          'or the entire series ?',
        seriesSuccess: 'The series successfully deleted',
        eventSuccess: 'The event successfully deleted'
      }
    },
    fields: {
      coachRef: 'Coach',
      activityName: 'Activity Name',
      eventType: 'Event Type',
      startTime: 'Start Time',
      Duration: 'Duration',
      location: 'location',
      description: 'Description',
      notes: 'Notes'
    }
  },
  de: {
    actions: {
      addEvent: {
        title: 'Create new event',
        success: 'New event has been successfully created'
      },
      editEvent: {
        title: 'Edit event',
        success: 'The event successfully updated'
      },
      deleteEvent: {
        warning:
          'The event is part of a series do you want to delete only this event ' +
          'or the entire series ?',
        seriesSuccess: 'The series successfully deleted',
        eventSuccess: 'The event successfully deleted'
      }
    },
    fields: {
      coachRef: 'Coach',
      activityName: 'Aktivitätsname',
      eventType: 'Ereignistyp',
      startTime: 'Startzeit',
      Duration: 'Dauer',
      location: 'Lage',
      description: 'Beschreibung',
      notes: 'Anmerkungen'
    }
  }
}
