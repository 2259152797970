// @flow
import type { ActionType, ExtractReturn } from 'common-docdok/src/types'

import { entityTableActionTypes, entityTableActions } from '../actions'

// State Definition

export type EntityTableSubStateType = {
  pageSize: number
}

export type EntityTableStateType = {
  [id: string]: EntityTableSubStateType
}

// Default State
const initialState: EntityTableStateType = {}

// Action Handlers

function setPageSize(
  state: EntityTableStateType,
  action: ExtractReturn<typeof entityTableActions.setPageSize>
): EntityTableStateType {
  const { id, pageSize } = action.payload
  const newState = {
    ...state,
    [id]: {
      pageSize
    }
  }

  return newState
}

const ACTION_HANDLERS = {
  [entityTableActionTypes.SET_PAGE_SIZE]: setPageSize
}

export default function entitySearcherReducer(
  state: EntityTableStateType = initialState,
  action: ActionType
): EntityTableStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
