// @flow
import { call, takeEvery, put } from 'redux-saga/effects'
import moment from 'moment'

import { surveyActions as actions, surveyActionTypes as types } from '../actions'
import { api } from '../../..'
import type { ExtractReturn, GenType } from '../../../types'

function* addIntegrationRequested(
  action: ExtractReturn<typeof actions.addIntegrationRequested>
): GenType {
  try {
    const { payload: { deviceName, patientUuid } } = action
    const result = yield call(api.getIntegrationAuthorization, patientUuid, deviceName)
    const autorizationUrl = result.data
    yield put(actions.addIntegrationSucceeded(patientUuid, autorizationUrl))
  } catch (error) {
    yield put(actions.addIntegrationFailed(error))
  }
}

function* getDeviceStateRequested(
  action: ExtractReturn<typeof actions.getDeviceStateRequested>
): GenType {
  try {
    const { payload: { patientUuid } } = action
    const result = yield call(api.getDeviceState, patientUuid)
    const state = result.data
    yield put(actions.getDeviceStateSucceeded(patientUuid, state))
  } catch (error) {
    yield put(actions.getDeviceStateFailed(error))
  }
}

function* getDeviceDataRequested(
  action: ExtractReturn<typeof actions.getDeviceDataRequested>
): GenType {
  try {
    const { payload: { deviceName, patientUuid, startDate, range } } = action

    const endDate = moment(startDate)
      .add(1, range)
      .toDate()

    const result = yield call(api.getDeviceData, patientUuid, deviceName, startDate, endDate)
    const { data } = result
    yield put(actions.getDeviceDataSucceeded(patientUuid, deviceName, data))
  } catch (error) {
    yield put(actions.getDeviceDataFailed(error))
  }
}

function* getDeviceDefinitionsRequested(): GenType {
  try {
    const result = yield call(api.getDeviceDefinitions)
    const { data } = result
    yield put(actions.getDeviceDefinitionsSucceeded(data))
  } catch (error) {
    yield put(actions.getDeviceDefinitionsFailed(error))
  }
}

export default function devicesSaga(): Array<GenType> {
  return [
    takeEvery(types.ADD_INTEGRATION_REQUESTED, addIntegrationRequested),
    takeEvery(types.GET_DEVICE_STATE_REQUESTED, getDeviceStateRequested),
    takeEvery(types.GET_DEVICE_DATA_REQUESTED, getDeviceDataRequested),
    takeEvery(types.GET_DEVICE_DEFINITIONS_REQUESTED, getDeviceDefinitionsRequested)
  ]
}
