// @flow
import { all, call, put, takeEvery, select } from 'redux-saga/effects'

import { surveyActions, surveyActionTypes } from '../actions'
import api from '../../../api'
import type { ExtractReturn, GenType, CommonStateType } from '../../../types'
import filters from '../../../utils/filters'

// -- APPEND GENERATORS HERE --
function* getSurveySpec(
  action: ExtractReturn<typeof surveyActions.getSurveySpecRequested>
): GenType {
  try {
    const participationId = action.payload
    const result = yield call(api.getSurveySpec, participationId)
    const spec = result.data

    yield put(surveyActions.getSurveySpecSucceeded(participationId, spec))
  } catch (error) {
    yield put(surveyActions.getSurveySpecFailed(error))
  }
}

function* getSurveyDefintionsRequested(): GenType {
  try {
    const result = yield call(api.getSurveyDefinitions)
    const sureyDefintions = result.data
    yield put(surveyActions.getSurveyDefinitionsSucceeded(sureyDefintions))
  } catch (error) {
    yield put(surveyActions.getSurveyDefinitionsFailed(error))
  }
}

function* sendSurveys(action: ExtractReturn<typeof surveyActions.sendSurveysRequested>): GenType {
  try {
    const participations = action.payload.filter(filters.hasNot('scheduling'))
    const schedulings = action.payload.filter(filters.has('scheduling'))
    yield all(participations.map(p => call(api.sendSurvey, p)))
    yield all(schedulings.map(p => call(api.scheduleSurvey, p)))
    yield put(surveyActions.getSurveyDefinitionsRequested())
    yield put(surveyActions.sendSurveysSucceeded())
  } catch (error) {
    yield put(surveyActions.sendSurveysFailed(error))
  }
}

function* activateDefinitions(
  action: ExtractReturn<typeof surveyActions.activateSurveyDefinitionsRequested>
): GenType {
  try {
    const surveyIds = action.payload
    yield all(surveyIds.map(id => call(api.activateSurveyDefinition, id)))
    yield put(surveyActions.activateSurveyDefinitionsSucceeded(surveyIds))
  } catch (error) {
    yield put(surveyActions.activateSurveyDefinitionsFailed(error))
  }
}

function* getSurveyResponses(
  action: ExtractReturn<typeof surveyActions.getSurveyResponsesRequested>
): GenType {
  try {
    const participationId = action.payload
    const result = yield call(api.getSurveyResponses, participationId)
    const responses = result.data
    yield put(surveyActions.getSurveyResponsesSucceeded(participationId, responses))
  } catch (error) {
    yield put(surveyActions.getSurveyResponsesFailed(error))
  }
}

function* getMySurveys(): GenType {
  try {
    const result = yield call(api.getMySurveysAsync)
    const responses = result.data
    yield put(surveyActions.getMySurveysSucceeded(responses))
  } catch (error) {
    yield put(surveyActions.getMySurveysFailed(error))
  }
}

function* loadSurveysById(
  action: ExtractReturn<typeof surveyActions.getSurveyDefinitionsByIdRequested>
) {
  try {
    const surveyIds = action.payload
    const loadedSurveyIds: Array<number> = yield select((state: CommonStateType) =>
      Object.keys(state.survey.surveyDefinitionsById)
    )

    const surveysToLoad = surveyIds
      .filter(id => id !== undefined)
      .map(id => Number(id))
      .filter(id => !loadedSurveyIds.includes(id))

    if (surveysToLoad.length > 0) {
      const result = yield all(surveysToLoad.map(id => call(api.getSurveyDefinition, id)))
      const surveyDefinitions = result.map(r => r.data)
      yield put(surveyActions.getSurveyDefinitionsByIdSucceeded(surveyDefinitions))
    } else {
      yield put(surveyActions.getSurveyDefinitionsByIdSucceeded([]))
    }
  } catch (error) {
    yield put(surveyActions.getSurveyDefinitionsByIdFailed(error))
  }
}

function* getSurveyStatistic(
  action: ExtractReturn<typeof surveyActions.getSurveyStatisticRequested>
) {
  try {
    const { surveyId, patientUuid } = action.payload
    const result = yield call(api.getSurveyStatistic, surveyId, patientUuid)
    yield put(surveyActions.getSurveyStatisticSucceeded(surveyId, patientUuid, result.data))
  } catch (error) {
    yield put(surveyActions.getSurveyStatisticFailed(error))
  }
}

function* getSurveyDefinitionsByPatient(
  action: ExtractReturn<typeof surveyActions.getSurveyDefinitionsByPatientRequested>
) {
  try {
    const { patientUuid } = action.payload
    const result = yield call(api.getSurveyDefintionsByPatientId, patientUuid)
    yield put(surveyActions.getSurveyDefinitionsByPatientSucceeded(patientUuid, result.data))
  } catch (error) {
    yield put(surveyActions.getSurveyDefinitionsByPatientFailed(error))
  }
}
function* uploadLSSFile(action: ExtractReturn<typeof surveyActions.uploadLSSFileRequested>) {
  try {
    const { name, content } = action.payload
    const result = yield call(api.uploadLSSFile, name, content)
    const surveyDefinition = result.data
    yield put(surveyActions.uploadLSSFileSucceeded(name, surveyDefinition))
  } catch (error) {
    yield put(surveyActions.uploadLSSFileFailed(error))
  }
}

function* updatSurveyDefinition(
  action: ExtractReturn<typeof surveyActions.updateSurveyDefinitionRequested>
) {
  try {
    const surveyDefinition = action.payload
    const result = yield call(api.updateSurveyDefinition, surveyDefinition)
    const updateSurveyDefinition = result.data
    yield put(surveyActions.updateSurveyDefinitionSucceeded(updateSurveyDefinition))
  } catch (error) {
    yield put(surveyActions.updateSurveyDefinitionFailed(error))
  }
}

function* deleteSurvey(action: ExtractReturn<typeof surveyActions.deleteSurveyRequested>) {
  try {
    const surveyId = action.payload
    yield call(api.deleteSurvey, surveyId)
    yield put(surveyActions.deleteSurveySucceeded(surveyId))
  } catch (error) {
    yield put(surveyActions.deleteSurveyFailed(error))
  }
}

function* uploadSurveyThemeFile(action) {
  try {
    const { name, content, id } = action.payload
    if (id) {
      const result = yield call(api.uploadEditSurveyThemeFile, id, name, content)
      const surveyDefinition = result.data
      yield put(surveyActions.uploadSurveyThemeFileSucceeded(name, surveyDefinition))
    } else {
      const result = yield call(api.uploadSurveyThemeFile, name, content)
      const surveyDefinition = result.data
      yield put(surveyActions.uploadSurveyThemeFileSucceeded(name, surveyDefinition))
    }
  } catch (error) {
    yield put(surveyActions.uploadSurveyThemeFileFailed(error))
  }
}

function* deleteSurveyThemeFile(action) {
  try {
    const { name, id } = action.payload
    yield call(api.deleteSurveyThemes, id)
    yield put(surveyActions.deleteSurveyThemeFileSucceeded(name))
  } catch (error) {
    yield put(surveyActions.deleteSurveyThemeFileFailed(error))
  }
}

function* createSurveyGroup(action: any) {
  try {
    if (action.payload.id) {
      const result = yield call(api.updateSurveyGroup, action.payload)
      const resultData = result.data
      yield put(surveyActions.updateSurveyGroupSucceeded(action.payload.groupName, resultData))
    } else {
      const result = yield call(api.createSurveyGroup, action.payload)
      const resultData = result.data
      yield put(surveyActions.createSurveyGroupSucceeded(action.payload.groupName, resultData))
    }
  } catch (error) {
    yield put(surveyActions.createSurveyGroupFailed(error))
  }
}

function* deleteSurveyGroup(action) {
  try {
    yield call(api.deleteSurveyGroups, action.payload)
    yield put(surveyActions.deleteSurveyGroupSucceeded(action.payload))
  } catch (error) {
    yield put(surveyActions.deleteSurveyGroupFailed(error))
  }
}

export default function surveySaga(): Array<GenType> {
  return [
    // -- APPEND TAKES HERE --
    takeEvery(surveyActionTypes.GET_SURVEY_SPEC_REQUESTED, getSurveySpec),
    takeEvery(surveyActionTypes.GET_SURVEY_DEFINITIONS_REQUESTED, getSurveyDefintionsRequested),
    takeEvery(surveyActionTypes.SEND_SURVEYS_REQUESTED, sendSurveys),
    takeEvery(surveyActionTypes.ACTIVATE_SURVEY_DEFINITIONS_REQUESTED, activateDefinitions),
    takeEvery(surveyActionTypes.GET_SURVEY_RESPONSES_REQUESTED, getSurveyResponses),
    takeEvery(surveyActionTypes.GET_MY_SURVEYS_REQUESTED, getMySurveys),
    takeEvery(surveyActionTypes.GET_SURVEY_DEFINITIONS_BY_ID_REQUESTED, loadSurveysById),
    takeEvery(surveyActionTypes.GET_SURVEY_STATISTIC_REQUESTED, getSurveyStatistic),
    takeEvery(
      surveyActionTypes.GET_SURVEY_DEFINITIONS_BY_PATIENT_REQUESTED,
      getSurveyDefinitionsByPatient
    ),
    takeEvery(surveyActionTypes.UPLOAD_LSS_FILE_REQUESTED, uploadLSSFile),
    takeEvery(surveyActionTypes.UPDATE_SURVEY_DEFINITION_REQUESTED, updatSurveyDefinition),
    takeEvery(surveyActionTypes.DELETE_SURVEY_REQUESTED, deleteSurvey),
    takeEvery(surveyActionTypes.UPLOAD_SURVEY_THEMES_REQUESTED, uploadSurveyThemeFile),
    takeEvery(surveyActionTypes.DELETE_SURVEY_THEMES_REQUESTED, deleteSurveyThemeFile),
    takeEvery(surveyActionTypes.CREATE_SURVEY_GROUP_REQUESTED, createSurveyGroup),
    takeEvery(surveyActionTypes.UPDATE_SURVEY_GROUP_REQUESTED, createSurveyGroup),
    takeEvery(surveyActionTypes.DELETE_SURVEY_GROUP_REQUESTED, deleteSurveyGroup)
  ]
}
