// @flow

import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import compose from 'ramda/src/compose'

import Header from '../../../common/Header'
import Menu from '../../../common/Menu'
import Breadcrumbs from '../container/breadcrumbs'
import docDokLogo from '../../../common/Menu/assets/logo_docdok.png'
import palette from '../../../styles/palette'
import Ribbon from './Ribbon'
import GlobalLoadingBar from '../container/globalLoadingBar'
import AppDialogs from '../../../common/AppDialogs'
import { coreLayoutActions } from '../actions/index'

export const DEFAULT_SPACING = '1.0%'

const drawerWidthOpen = 240

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    minHeight: '100%'
  },
  appBar: {
    backgroundColor: palette.grey,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidthOpen + 1,
      width: `calc(100% - ${drawerWidthOpen + 1}px)`
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 0,
    marginRight: 0
  },
  menuClose: {
    color: 'white'
  },
  hide: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  drawerPaper: {
    position: 'fixed',
    whiteSpace: 'nowrap',
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: drawerWidthOpen
    },
    backgroundColor: palette.blue,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing.unit * 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    backgroundColor: palette.blueDark,
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), '
      + '0px 4px 5px 0px rgba(0, 0, 0, 0.14), '
      + '0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
    ...theme.mixins.toolbar
  },
  toolbarOuter: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px'
    }
  },
  content: {
    padding: DEFAULT_SPACING,
    paddingBottom: 0,
    paddingTop: 0,
    height: '100%',
    margin: 0
  },
  container: {
    flexGrow: 2,
    width: '100%',
    marginLeft: '2px',
    marginTop: 50,
    [theme.breakpoints.up('sm')]: {
      width: `calc(98vw - ${theme.spacing.unit * 7}px)`,
      marginTop: 64
    }
  },
  openMargin: {
    marginLeft: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 30
    },

    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  closeMargin: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 9
    },
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  breadcrumbsContainer: {
    color: '#1e5e84',
    borderBottom: 'rgb(224, 224, 224) 1px solid',
    width: '100%',
    a: {
      textDecoration: 'none'
    },
    paddingLeft: DEFAULT_SPACING,
    paddingTop: 2,
    paddingBottom: 2,
    marginBottom: 0,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 7,
      paddingBottom: 5,
      marginBottom: DEFAULT_SPACING
    }
  }
})

type PropsType = {
  classes: Object,
  theme: Object,
  routes: Object,
  children: any,
  params: Object,
  fullScreen: boolean,
  menuVisible: boolean,
  setMenuVisibility: typeof coreLayoutActions.setMenuVisibility
}

class CoreLayout extends React.Component<PropsType> {
  handleDrawerOpenClose = () => {
    const { menuVisible, setMenuVisibility } = this.props
    setMenuVisibility(!menuVisible)
  }

  render() {
    const { classes, theme, menuVisible } = this.props
    const { children, routes, params, fullScreen } = this.props
    const linkActionFn = fullScreen ? this.handleDrawerOpenClose : () => {}

    return (
      <div className={classes.root}>
        <Ribbon />
        <AppBar className={classNames(classes.appBar, menuVisible && classes.appBarShift)}>
          <Toolbar className={classNames(classes.toolbarOuter)}>
            <IconButton
              id='menu-open-button'
              color='primary'
              aria-label='Open drawer'
              onClick={this.handleDrawerOpenClose}
              className={classNames(classes.menuButton, menuVisible && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Header />
          </Toolbar>
          <GlobalLoadingBar />
        </AppBar>
        <Drawer
          position='fixed'
          variant='permanent'
          classes={{
            paper: classNames(classes.drawerPaper, !menuVisible && classes.drawerPaperClose)
          }}
          open={menuVisible}
        >
          <div className={classes.toolbar}>
            <div style={{ flexGrow: 2, marginLeft: 20 }}>
              <img src={docDokLogo} alt='docDokLogo' style={{ height: 20, float: 'left' }} />
            </div>
            <IconButton id='menu-close-button' className={classes.menuClose} onClick={this.handleDrawerOpenClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider />
          <Menu onLinkActionFn={linkActionFn} />
        </Drawer>
        <div className={classNames(classes.container, menuVisible ? classes.openMargin : classes.closeMargin)}>
          <div className={classes.breadcrumbsContainer}>
            <Typography noWrap variant='subtitle1'>
              <Breadcrumbs
                routes={routes}
                params={params}
                excludes={['DefaultRouteContainer', 'PrivateLayout', 'Home']}
                displayMissing={false}
              />
            </Typography>
          </div>

          <div className={classes.content}>{children}</div>
        </div>
        <AppDialogs />
      </div>
    )
  }
}

const mapStateToProps = (state: StoreType) => {
  const { menuVisible } = state.coreLayout
  return { menuVisible }
}

export default compose(
  connect(
    mapStateToProps,
    { setMenuVisibility: coreLayoutActions.setMenuVisibility }
  ),
  withMobileDialog(),
  withStyles(styles, { withTheme: true })
)(CoreLayout)
