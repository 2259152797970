// @flow
import { put, call, takeEvery } from 'redux-saga/effects'

import api from '../../../api'
import { surveyActions, surveyActionTypes } from '../actions'
import { userActions } from '../../user/actions'
import type { ExtractReturn, GenType } from '../../../types'
import type { SurveyParticipationDtoType } from '../types/surveyParticipationDto'

// -- APPEND GENERATORS HERE --
function* saveSurveyAnswers(action: ExtractReturn<typeof surveyActions.saveSurveyAnswersRequested>): GenType {
  try {
    const { surveyParticipationResult, complete } = action.payload
    yield call(api.saveSurveyAnswers, surveyParticipationResult, complete)
    yield put(surveyActions.saveSurveyAnswersSucceeded())
  } catch (error) {
    yield put(surveyActions.saveSurveyAnswersFailed(error))
  }
}

function* getSurveyParticipation(action: ExtractReturn<typeof surveyActions.getSurveyParticipationRequested>): GenType {
  try {
    const result = yield call(api.getSurveyParticipation, action.payload)
    yield put(surveyActions.getSurveyParticipationSucceeded(result.data))
  } catch (error) {
    yield put(surveyActions.getSurveyParticipationFailed(error))
  }
}

function* getSurveyParticipantionsRequested(
  action: ExtractReturn<typeof surveyActions.getSurveyParticipationsRequested>
): GenType {
  try {
    const result = yield call(api.getSurveyParticipations, action.payload)
    const surveyParticipations: Array<SurveyParticipationDtoType> = result.data
    const senders = surveyParticipations.map(sd => sd.senderRef)
    const users = surveyParticipations.map(sd => sd.userRef)
    yield put(userActions.loadMissingUsersRequested([...senders, ...users]))
    yield put(surveyActions.getSurveyParticipationsSucceeded(surveyParticipations))
  } catch (error) {
    yield put(surveyActions.getSurveyParticipationsFailed(error))
  }
}

function* getSurveyParticipationsByPatient(
  action: ExtractReturn<typeof surveyActions.getSurveyParticipationsByPatientRequested>
): GenType {
  try {
    const result = yield call(api.getSurveyParticipationsByPatientId, action.payload)
    yield put(surveyActions.getSurveyParticipationsByPatientSucceeded(result.data))
  } catch (error) {
    yield put(surveyActions.getSurveyParticipationsByPatientFailed(error))
  }
}

export default function participationSaga(): Array<GenType> {
  return [
    // -- APPEND TAKES HERE --
    takeEvery(surveyActionTypes.SAVE_SURVEY_ANSWERS_REQUESTED, saveSurveyAnswers),
    takeEvery(surveyActionTypes.GET_SURVEY_PARTICIPATION_REQUESTED, getSurveyParticipation),
    takeEvery(surveyActionTypes.GET_SURVEY_PARTICIPATIONS_REQUESTED, getSurveyParticipantionsRequested),
    takeEvery(surveyActionTypes.GET_SURVEY_PARTICIPATIONS_BY_PATIENT_REQUESTED, getSurveyParticipationsByPatient)
  ]
}
