// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'
import { fields as patientFields } from 'common-docdok/src/domain/healthrelation/types/patientDto'
import { Link, browserHistory } from 'react-router'
import Button from '@material-ui/core/Button'
import LibraryAdd from '@material-ui/icons/LibraryAdd'
import Done from '@material-ui/icons/Done'
import DeleteForever from '@material-ui/icons/DeleteForever'
import PersonAdd from '@material-ui/icons/PersonAdd'
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined'
import Email from '@material-ui/icons/Email'
import Paper from '@material-ui/core/Paper'
import { healthrelationActions, healthrelationActionTypes } from 'common-docdok/src/domain/healthrelation/actions'
import { userActions, userActionTypes } from 'common-docdok/src/domain/user/actions'
import { connect } from 'react-redux'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import compose from 'ramda/src/compose'
import { pathOr } from 'ramda'

import femaleIcon from '../assets/gender-female.png'
import maleIcon from '../assets/gender-male.png'
import { introActions } from '../../../../../common/Intro/actions'
import EntityTable from '../../../../../common/EntityTable'
import Action from '../../../../../common/Security/containers/action'
import ConfirmationDialog from '../../../../../common/Confirmation/components/ConfirmationDialog'
import {
  ADD_STUDY_PARTICIPANT,
  RENEW_INVITATION,
  DELETE_PATIENT,
  CREATE_PATIENT
} from '../../../../../common/Security/actionNames'
import { UserNameComp as UserName } from '../../../../../common/UserName'
import palette from '../../../../../styles/palette'
import { showEventAction } from '../../Events/components/Events'
import SearchField from '../../../../../common/SearchField/components/SearchField'
import { tourIdentifiers } from '../../../../../common/Intro/tours'
import { TextLine, PageTitle, Buttons, Toolbar, ToolbarGroup, GreyBar } from '../../../../../common/Static'
import { addToStudyDialogActions } from '../../../../../common/AddToStudyDialog/actions'
import { createPatientButtonActions } from '../../../../../common/CreatePatientButton/actions'

export const patientTableColumns = {
  fullScreen: {
    name: {
      orderBy: 'lastName',
      renderer: (val: any, col: string, entity: string, row: Object) => (
        <TextLine>
          <Link to={`/private/app/patients/${row.uuid}`}>
            <UserName entity={row} />
          </Link>
        </TextLine>
      )
    },
    birthdate: {
      type: 'Date',
      width: 100
    },
    gender: {
      width: 70,
      renderer: (val: any) => {
        if (val === 'DIVERS') {
          return <div>X&nbsp;</div>
        }
        return <img className='gender-img' src={val === 'MALE' ? maleIcon : femaleIcon} alt={`gender-${val}`} />
      }
    }
  },
  defaultColumns: {
    name: {
      orderBy: 'lastName',
      renderer: (val: any, col: string, entity: string, row: Object) => (
        <TextLine>
          <Link to={`/private/app/patients/${row.uuid}`}>
            <UserName entity={row} />
          </Link>
        </TextLine>
      )
    },
    gender: patientFields.gender,
    birthdate: patientFields.birthdate,
    mobileNumber: patientFields.mobileNumber,
    email: patientFields.email,
    activated: {
      renderer: (val: any) => {
        if (val) {
          return <Done />
        }
        return null
      }
    }
  }
}
type PropsType = {
  toggleAddToStudyDialog: typeof addToStudyDialogActions.toggleAddToStudyDialog,
  renewInvitationRequested: typeof userActions.renewInvitationRequested,
  deleteUserRequested: typeof userActions.deleteUserRequested,
  registerTour: typeof introActions.registerTour,
  unregisterTour: typeof introActions.unregisterTour,
  deletePatientRequested: typeof healthrelationActions.deletePatientRequested,
  id: string,
  api: Function,
  fullScreen: boolean,
  toggleCreatePatientDialog: typeof createPatientButtonActions.toggleCreatePatientDialog,
  isAdmin: boolean
}

type StateType = {
  searchTerm: ?string,
  userRefToDelete: ?string,
}

class PatientsTable extends React.Component<PropsType, StateType> {
  state = {
    searchTerm: undefined,
    userRefToDelete : undefined
  }

  componentDidMount() {
    const { registerTour } = this.props
    registerTour(tourIdentifiers.patients)
  }

  componentWillUnmount() {
    const { unregisterTour } = this.props
    unregisterTour(tourIdentifiers.patients)
  }

  renderCreatePatientButton() {
    const { toggleCreatePatientDialog } = this.props
    return (
      <Action name={CREATE_PATIENT}>
        <Button variant='contained' color='primary' id='createPatient-table' onClick={toggleCreatePatientDialog}>
          <PersonAdd />
          <Translate value='patients.actions.createPatient.title' />
        </Button>
      </Action>
    )
  }

  render() {
    const {
      toggleAddToStudyDialog,
      renewInvitationRequested,
      id,
      api,
      deletePatientRequested,
      deleteUserRequested,
      isAdmin
    } = this.props
    const { searchTerm, userRefToDelete } = this.state
    const columns = /* fullScreen ? patientTableColumns.fullScreen : */ patientTableColumns.defaultColumns

    const actions = [
      {
        name: ADD_STUDY_PARTICIPANT,
        icon: <LibraryAdd />,
        tooltipKey: 'patients.actions.addToStudy.title',
        enabled: () => true,
        action: (patient) => {
          toggleAddToStudyDialog(patient.userRef, patient.uuid)
        }
      },
      showEventAction,
      {
        name: RENEW_INVITATION,
        icon: <Email />,
        tooltipKey: 'users.renewInvitation.title',
        enabled: ({ activated }) => !activated,
        action: (patient) => {
          renewInvitationRequested(patient.userRef)
        }
      }
    ]

    if (isAdmin) {
      actions.push({
        name: DELETE_PATIENT,
        icon: <DeleteForeverOutlined />,
        tooltipKey: 'patients.actions.deletePatient.title',
        action: (patient) => {
          this.setState({ userRefToDelete: patient.userRef })
        }
      })
    } else {
      actions.push({
        name: DELETE_PATIENT,
        icon: <DeleteForever />,
        tooltipKey: 'patients.actions.deletePatient.title',
        enabled: ({ activated }) => !activated,
        action: (patient) => {
          deletePatientRequested(patient)
        }
      })
    }

    return (
      <div>
        <PageTitle>
          <Translate value='patients.title' />
        </PageTitle>
        <Paper>
          <GreyBar>
            <Toolbar>
              <ToolbarGroup>
                <div>
                  <SearchField
                    expandToRight
                    id='patient-search-bar'
                    hoverColor={palette.blueLight}
                    color={palette.blue}
                    search={(value) => {
                      this.setState({ searchTerm: value })
                    }}
                  />
                </div>
              </ToolbarGroup>

              <Buttons>{this.renderCreatePatientButton()}</Buttons>
            </Toolbar>
          </GreyBar>
          <EntityTable
            api={api}
            id={id}
            hideRowCheckbox
            searchTerm={searchTerm}
            entity='patient'
            defaultSort='name'
            refreshActions={[healthrelationActionTypes.DELETE_PATIENT_SUCCEEDED, userActionTypes.DELETE_USER_SUCCEEDED]}
            columns={columns}
            actions={actions}
            onRowClick={(row) => {
              browserHistory.push(`/private/app/patients/${row.uuid}`)
            }}
          />
        </Paper>
        <ConfirmationDialog
          open={Boolean(userRefToDelete)}
          message={<Translate value='patients.actions.confirmation.title' />}
          onConfirm={(doConfirm) => {
            this.setState({ userRefToDelete: undefined })
            if (userRefToDelete && doConfirm) {
              deleteUserRequested(userRefToDelete)
            }
          }}
        />
      </div>
    )
  }
}

const getAuthorities = pathOr([], ['profile', 'authorities'])
const isAdmin = (state) => {
  const authorities = getAuthorities(state)
  return authorities.includes('ROLE_ADMIN') || authorities.includes('ROLE_SUPPORT_ADMIN')
}

const mapStateToProps = (state: StoreType) => {
  return { isAdmin: isAdmin(state) }
}

export default compose(
  connect(
    mapStateToProps,
    {
      renewInvitationRequested: userActions.renewInvitationRequested,
      registerTour: introActions.registerTour,
      unregisterTour: introActions.unregisterTour,
      deletePatientRequested: healthrelationActions.deletePatientRequested,
      toggleAddToStudyDialog: addToStudyDialogActions.toggleAddToStudyDialog,
      toggleCreatePatientDialog: createPatientButtonActions.toggleCreatePatientDialog,
      deleteUserRequested: userActions.deleteUserRequested
    }
  ),
  withMobileDialog()
)(PatientsTable)
