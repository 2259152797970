// @flow
import React from 'react'
import { connect } from 'react-redux'
import api from 'common-docdok/src/api'
import type { PatientDtoType } from 'common-docdok/src/domain/healthrelation/types/patientDto'
import type { CaseStreamDtoType } from 'common-docdok/domain/healthrelation/types/caseStreamDto'
import type { ConversationDtoType } from 'common-docdok/domain/messaging/types/conversationDto'

import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import { Translate } from 'react-redux-i18n'
import moment from 'moment'
import EntityTable from '../../EntityTable'
import { TextLine } from '../../Static'
import { chatActions } from '../../Chat/actions'
import { DialogContent, DialogHeader } from '../../Mui'

type PropsType = {
    patient: PatientDtoType,
    caseStream: CaseStreamDtoType,
    conversation: ConversationDtoType,
    showAppointmentDialog: boolean,
    toggleAppointmentDialog: typeof chatActions.toggleAppointmentDialog
}

const FullWidthContainer = styled.div`
  width: 100%;
`

class AppointmentDialog extends React.Component<PropsType> {
  render() {
    const { conversation, patient, showAppointmentDialog, toggleAppointmentDialog } = this.props

    if (!showAppointmentDialog) return null

    function getApi() {
      if (conversation !== undefined) {
        if (conversation.meta.caseStreamId !== undefined) {
          return api.getCaseStreamAppointments
        }
        return api.getAllAppointmentsByUserRef
      }
      return null
    }

    function getParams() {
      if (conversation !== undefined) {
        if (conversation.meta.caseStreamId !== undefined) {
          return [conversation.meta.caseStreamId]
        }
        return [patient.userRef]
      }
      return []
    }

    return (
      <div>
        <Dialog open={showAppointmentDialog} onEscapeKeyDown={toggleAppointmentDialog} maxWidth='md' fullWidth>
          <DialogHeader name='show-appointments' onClose={toggleAppointmentDialog}>
            <Translate value='appointment.name' />
          </DialogHeader>
          <DialogContent>
            <FullWidthContainer>
              <EntityTable
                api={getApi()}
                params={getParams()}
                id='apppintments'
                entity='appointment'
                // filterPredicate={appointment => moment(appointment.appointmentTime).isAfter(moment())} // we do want to show past appointments for the moment
                comparator={(a, b) => new Date(a.appointmentTime).getTime() - new Date(b.appointmentTime).getTime()}
                columns={
                    {
                      appointmentTime: {
                        renderer: (val: any, col: string, entity: string, row: Object) => (
                          <TextLine>{moment(row.appointmentTime).format('DD.MM.YYYY HH:mm')}</TextLine>
                        ),
                        sortable: false
                      },
                      physician: {
                        renderer: (val: any, col: string, entity: string, row: Object) => (
                          <TextLine>{row.physician}</TextLine>
                        ),
                        sortable: false
                      },
                      clinicName: {
                        renderer: (val: any, col: string, entity: string, row: Object) => (
                          <TextLine>{row.clinicName}</TextLine>
                        ),
                        sortable: false
                      },
                      appointmentDescription: {
                        renderer: (val: any, col: string, entity: string, row: Object) => (
                          <TextLine>{row.appointmentDescription}</TextLine>
                        ),
                        sortable: false
                      }
                    }
                }
                hidePagnitation
                hideRowCheckbox
              />
            </FullWidthContainer>
          </DialogContent>
        </Dialog>
      </div>

    )
  }
}

export const mapStateToProps = (state: StoreType) => {
  const uuid = state.selectedPatient
  if (!uuid) {
    return {}
  }

  const patient = state.healthrelationCache.patients[uuid]

  const {
    messaging: { conversations, selectedConversation }
  } = state

  const conversation = conversations[Number(selectedConversation)]

  const { showAppointmentDialog } = state.chat

  return {
    patient,
    conversation,
    showAppointmentDialog
  }
}

export default connect(
  mapStateToProps,
  {
    toggleAppointmentDialog: chatActions.toggleAppointmentDialog
  }
)(AppointmentDialog)
