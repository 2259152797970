// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import palette from '../../../styles/palette'
import { Bubble, Container } from '../../Static'

const EventBubble = styled(Bubble)`
  max-width: 80%;
  margin: auto;
  margin-bottom: 2px;
  background-color: ${props => (props.internal ? palette.pink : palette.blueVeryLight)};
  user-select: text;
  padding: 1px;
  padding-left: 5px;
  padding-right: 5px;
`

type PropsType = {
  message: Object
}

const internalNotes = [
  'assignToMe',
  'requestForAttention',
  'appointmentReminder',
  'appointmentSchedule',
  'appointmentConfirmation',
  'invitationResent'
]

export default class ConversationEvent extends React.Component<PropsType> {
  render() {
    const {
      message: { meta }
    } = this.props
    const { eventKey } = meta
    const isInternal = internalNotes.includes(eventKey)

    return (
      <Container>
        <EventBubble internal={isInternal} labelStyle={{}}>
          <Typography variant='caption' align='center'>
            <Translate
              value={`messages.events.${eventKey}`}
              {...meta}
              style={{
                color: isInternal ? 'white' : undefined
              }}
            />
          </Typography>
        </EventBubble>
      </Container>
    )
  }
}
