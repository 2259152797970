// @flow
import React from 'react'
import { Field } from 'redux-form'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { Translate, I18n } from 'react-redux-i18n'
import DeleteIcon from '@material-ui/icons/Delete'
import AddCircleOutlined from '@material-ui/icons/AddCircleOutlined'
import styled from 'styled-components'

import type { ReduxFormCompontenType } from '../../DynamicForm/components/fields/formField'

const ClinicalData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

type PropsType = ReduxFormCompontenType

class DataLinesComp extends React.Component<PropsType> {
  addDataEntry = () => {
    const {
      input: { value, onChange }
    } = this.props
    const casedata = [...value, '']
    onChange(casedata)
  }

  updateClinicalDateEntry = index => (event) => {
    const newValue = event.target.value
    const {
      input: { value, onChange }
    } = this.props
    const casedata = [...value]
    casedata[index] = newValue
    onChange(casedata)
  }

  removeClinicalDataEntry = index => () => {
    const {
      input: { value, onChange }
    } = this.props
    const casedata = value.filter((v, pos) => pos !== index)
    onChange(casedata)
  }

  renderClinicalData = () => {
    const {
      input: { value }
    } = this.props

    const caseData = value || []
    return caseData.map((data, i) => (
      <ClinicalData key={i} data-test={`case-line-${i}`}>
        <IconButton
          data-test={`delete-row-${i}`}
          aria-label='Delete'
          onClick={this.removeClinicalDataEntry(i)}
          style={{ padding: 0 }}
        >
          <DeleteIcon />
        </IconButton>
        <TextField
          margin='normal'
          autoFocus
          value={data}
          helperText={data.length === 0 && <Translate value='cases.newCaseForm.clinicalDataHelp' />}
          error={data.length === 0}
          onChange={this.updateClinicalDateEntry(i)}
          multiline
          rowsMax={13}
          label={I18n.t('cases.newCaseForm.clinicalData')}
          fullWidth
          id={`clinical-data-${i}`}
        />
      </ClinicalData>
    ))
  }

  render() {
    const {
      input: { name },
      meta: { error, form }
    } = this.props

    const id = `${form}-${name}`

    return (
      <div id={id}>
        {error}
        {this.renderClinicalData()}
        <IconButton onClick={this.addDataEntry} data-test='addClinicalDataLine-button'>
          <AddCircleOutlined />
          <Typography>
            <Translate value='cases.newCaseForm.addDataLine' />
          </Typography>
        </IconButton>
      </div>
    )
  }
}

export default function DataLines(props: { name: string }) {
  return <Field name={props.name} component={DataLinesComp} />
}
