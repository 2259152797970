// @flow
import React from 'react'
import pathOr from 'ramda/src/pathOr'

export default (store: any) => ({
  path: 'surveyparticipation/:participationId',
  getDisplayName(param: Object) {
    const surveyTitle = pathOr(
      '',
      ['survey', 'surveyParticipations', param.participationId, 'surveyName'],
      store.getState()
    )
    return (
      <span style={{ whiteSpace: 'normal' }} id='breadcrumb2'>
        {surveyTitle}
      </span>
    )
  },
  /*  Async getComponent is only invoked when route matches   */
  onEnter: () => {
    // Fixem need to added
  },
  getComponent(nextState: any, cb: Function) {
    /*  Webpack - use 'require.ensure' to create a split point
        and embed an async module loader (jsonp) when bundling   */
    (require: any).ensure(
      [],
      (require) => {
        /*  Webpack - use require callback to define
          dependencies for bundling   */
        const container = require('../../SurveyParticipation').default
        /*  Return getComponent   */
        cb(null, container)
        /* Webpack named bundle   */
      },
      'surveyparticipation'
    )
  }
})
