// @flow

import { I18n } from 'react-redux-i18n'

export const sortOptionsByTranslation = (form: string, field: string) => (
  optA: { key: string },
  optB: { key: string }
) => {
  const textA = I18n.t(`forms.${form}.${field}Opts.${optA.key}`)
  const textB = I18n.t(`forms.${form}.${field}Opts.${optB.key}`)

  const useA = textA < textB
  const useB = textA > textB
  let result = 0
  if (useA) {
    result = -1
  } else if (useB) {
    result = 1
  }
  return result
}

export function flatFields(fields: Object | Array<Object>): Object {
  if (fields instanceof Array) {
    return fields.reduce((fs, all) => ({ ...all, ...fs }), {})
  } if (fields instanceof Object) {
    return fields
  }
  throw new Error('Input has to be an object or an array')
}
