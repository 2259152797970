// @flow
import { userActionTypes, userActions } from '../actions'
import type { UserActionType } from '../actions'
import type { ExtractReturn } from '../../../types'
import type { MeDtoType } from '../types/meDto'

export type ProfileStateType = ?MeDtoType

// Default State
const initialState = null

// Action Handlers
type ProfileFetchActionType = ExtractReturn<typeof userActions.profileFetchSucceeded>
function profileFetchSucceeded(
  state: ProfileStateType,
  action: ProfileFetchActionType
): ProfileStateType {
  const newProfile = action.payload

  if (newProfile) {
    return {
      ...state,
      ...newProfile
    }
  }
  return state
}

type UpdateTermsVersionType = ExtractReturn<typeof userActions.updateTermsVersionSucceeded>
function updateTermsVersionSucceeded(
  state: ProfileStateType,
  action: UpdateTermsVersionType
): ProfileStateType {
  const acceptedTermsVersion: string = action.payload
  return acceptedTermsVersion ? { ...state, acceptedTermsVersion } : state
}

function updateUserPreferencesSucceeded(
  state: ProfileStateType,
  action: ExtractReturn<typeof userActions.updateUserPreferencesSucceeded>
): ProfileStateType {
  const preferences = action.payload
  return { ...state, preferences }
}

type HandlerType = { [type: UserActionType]: any } // Add more if you handle actions from other domains
const ACTION_HANDLERS: HandlerType = {
  [userActionTypes.PROFILE_FETCH_SUCCEEDED]: profileFetchSucceeded,
  [userActionTypes.UPDATE_TERMS_VERSION_SUCCEEDED]: updateTermsVersionSucceeded,
  [userActionTypes.UPDATE_USER_PREFERENCES_SUCCEEDED]: updateUserPreferencesSucceeded
}

type ActionType = { type: UserActionType } // Add more if you handle actions from other domains

export default function profileReducer(
  state: ProfileStateType = initialState,
  action: ActionType
): ProfileStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
