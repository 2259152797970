// @flow
import React from 'react'
import { Localize } from 'react-redux-i18n'
import dateUtils from 'common-docdok/src/utils/dateUtils'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'

import surveyCompletedLatestMessage from '../components/surveyCompletedLatestMessage'
import surveyInvitationLatestMessage from '../components/surveyInvitationLatestMessage'
import { connectMessage, type MessagePropsType } from '../../Message'

const renderRegistry = {
  SURVEY_COMPLETED: surveyCompletedLatestMessage,
  NEW_SURVEY: surveyInvitationLatestMessage
}

const BreakingText = styled(Typography)`
  word-break: break-word;
  hyphens: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 80px;
`

function isToday(date: Date) {
  const today = new Date()
  return new Date(date).setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)
}

const space = ' '

function latestMessage(props: MessagePropsType) {
  let result = null
  const { message } = props

  if (message) {
    const postedAt = dateUtils.toDate(message.postedAt)
    let text
    if (renderRegistry[message.meta.subtype]) {
      const Component = renderRegistry[message.meta.subtype]
      text = <Component {...props} />
    } else {
      text = (
        <span style={{ wordBreak: 'break-word' }} className='latest-message'>
          {message.text}
        </span>
      )
    }
    result = (
      <BreakingText component='span'>
        <span className='latest-message-date' style={{ color: 'black' }}>
          {isToday(postedAt) ? (
            <Localize value={postedAt} dateFormat='time.short' />
          ) : (
            <Localize value={postedAt} dateFormat='date.short' />
          )}
        </span>
        {space}
        {text}
      </BreakingText>
    )
  }

  return result
}

export default connectMessage(latestMessage)
