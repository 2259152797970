// @flow

export const createCaseButtonActionTypes = {
  TOGGLE_CREATE_CASE_DIALOG: 'TOGGLE_CREATE_CASE_DIALOG',
  TOGGLE_EDIT_CASE_DIALOG: 'TOGGLE_EDIT_CASE_DIALOG'
}
export type CreateCaseButtonActionType = $Keys<typeof createCaseButtonActionTypes>
export const createCaseButtonActions = {
  toggleCreateCaseDialog() {
    return {
      type: createCaseButtonActionTypes.TOGGLE_CREATE_CASE_DIALOG
    }
  },
  toggleEditOrViewCaseDialog() {
    return {
      type: createCaseButtonActionTypes.TOGGLE_EDIT_CASE_DIALOG
    }
  }
}
