// @flow
import React from 'react'

import { connect } from 'react-redux'
import { stopSubmit as stopSubmitting } from 'redux-form'
import pathOr from 'ramda/src/pathOr'
import find from 'ramda/src/find'
import propEq from 'ramda/src/propEq'
import compose from 'ramda/src/compose'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import Dialog from '@material-ui/core/Dialog'

import PersonAdd from '@material-ui/icons/PersonAdd'
import { Translate } from 'react-redux-i18n'
import DialogContent from '@material-ui/core/DialogContent'
import { fields as studyParticipantFields } from 'common-docdok/src/domain/survey/types/studyParticipantDto'
import type { StudyDtoType } from 'common-docdok/src/domain/survey/types/studyDto'
import { DialogHeader, RaisedButton } from '../../../../../common/Mui'
import { Container } from '../../../../../common/Static'
import { studiesActions } from '../actions'
import { ADD_STUDY_PARTICIPANT } from '../../../../../common/Security/actionNames'
import Action from '../../../../../common/Security/containers/action'

import DynamicForm from '../../../../../common/DynamicForm'
import { dynamicFormActions } from '../../../../../common/DynamicForm/actions'
import InclusionExclusionComp from '../../../../../common/InclusionExclusion'

type PropsType = {
  studyId: number,
  setAddStudyParticipantDialog: typeof studiesActions.setAddStudyParticipantDialog,
  showAddStudyParticipant: boolean,
  disabled: boolean,
  stopSubmit: string => void,
  selectedStudy: StudyDtoType
}

class AddStudyParticipantButton extends React.Component<PropsType> {
  state = { wizardPage: 0 }

  nextPage = () => {
    const { stopSubmit } = this.props
    this.setState(prevState => ({ wizardPage: prevState.wizardPage + 1 }))
    stopSubmit('addStudyParticipant')
  }

  renderSelectParticipant = () => {
    const { studyId } = this.props

    return (
      <DynamicForm
        form='addStudyParticipant'
        alternateSubmit={this.nextPage}
        destroyOnUnmount={false}
        initialValues={{ studyId }}
        saveLabelKey='actions.next'
        fields={{
          patients: {
            specName: 'patient',
            type: 'search',
            mandatory: true,
            multiSelectable: false
          }
        }}
      />
    )
  }

  renderParticipationIds = () => {
    const { studyId } = this.props

    return (
      <DynamicForm
        form='addStudyParticipant'
        destroyOnUnmount={false}
        initialValues={{ studyId }}
        fields={{
          participantId: {
            ...studyParticipantFields.participantId,
            mandatory: true
          },
          participantSubId: studyParticipantFields.participantSubId
        }}
      />
    )
  }

  renderInclusions = () => {
    const criterias = pathOr([], ['studyParameters', 'inclusionCriterias'])(this.props.selectedStudy)
    return (
      <InclusionExclusionComp
        criterias={criterias}
        forward={this.nextPage}
        criteriaType='inclusion'
        studyName={this.props.selectedStudy.name}
      />
    )
  }

  renderExclusions = () => {
    const criterias = pathOr([], ['studyParameters', 'exclusionCriterias'])(this.props.selectedStudy)

    return (
      <InclusionExclusionComp
        criterias={criterias}
        forward={this.nextPage}
        criteriaType='exclusion'
        studyName={this.props.selectedStudy.name}
      />
    )
  }

  renderConsent = () => (
    <InclusionExclusionComp
      criterias={[<Translate value='studies.participationCriteria.informedConsentVerifyText' />]}
      forward={this.nextPage}
      criteriaType='informedConsent'
      studyName={this.props.selectedStudy.name}
    />
  )

  renderContent() {
    const { wizardPage } = this.state
    switch (wizardPage) {
      case 0:
        return this.renderSelectParticipant()
      case 1:
        return this.renderInclusions()
      case 2:
        return this.renderExclusions()
      case 3:
        return this.renderConsent()
      case 4:
        return this.renderParticipationIds()
      default:
        return null
    }
  }

  myToggleAddToStudyDialog = () => {
    const { setAddStudyParticipantDialog, stopSubmit } = this.props
    this.setState({ wizardPage: 0 })
    setAddStudyParticipantDialog(false)
    stopSubmit('addStudyParticipant')
  }

  render() {
    const { setAddStudyParticipantDialog, showAddStudyParticipant, disabled } = this.props

    return (
      <Action name={ADD_STUDY_PARTICIPANT}>
        <RaisedButton
          id='addStudyParticipant-button'
          disabled={disabled}
          label={<Translate value='studies.actions.addStudyParticipant.title' />}
          onClick={() => {
            setAddStudyParticipantDialog(true)
          }}
          icon={<PersonAdd />}
        />
        <Dialog
          fullScreen={false}
          fullWidth
          maxWidth='sm'
          scroll='paper'
          open={showAddStudyParticipant}
          onEscapeKeyDown={() => this.myToggleAddToStudyDialog()}
        >
          <DialogHeader name='survey-respones' onClose={() => this.myToggleAddToStudyDialog()}>
            <Container noWrap>
              <Translate value='surveys.addToStudy.title' />
            </Container>
          </DialogHeader>
          <DialogContent>{this.renderContent()}</DialogContent>
        </Dialog>
      </Action>
    )
  }
}

const mapStateToProps = (state: StoreType, props: PropsType) => {
  const { studyId } = props

  const studyIdInt = parseInt(studyId, 10)
  const selectedStudy = compose(
    find(propEq('id', studyIdInt)),
    pathOr({}, ['survey', 'allStudies'])
  )(state)

  return {
    selectedStudy
  }
}

export default compose(
  connect(
    mapStateToProps,
    {
      submitForm: dynamicFormActions.submitForm,
      stopSubmit: stopSubmitting
    }
  ),
  withMobileDialog()
)(AddStudyParticipantButton)
