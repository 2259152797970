// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'
import { checkRoute } from '../../../../common/Security/utils'

export default (store: any) => ({
  path: 'events/:userRef',
  getDisplayName() {
    return (
      <span id='breadcrumb1'>
        <Translate value='menu.events' />
      </span>
    )
  },
  onEnter: (state: any, replace: Function) => {
    checkRoute(store.getState(), state.location.pathname, replace)
  },
  /*  Async getComponent is only invoked when route matches   */
  getComponent(nextState: any, cb: Function) {
    /*  Webpack - use 'require.ensure' to create a split point
        and embed an async module loader (jsonp) when bundling   */
    (require: any).ensure(
      [],
      (require) => {
        /*  Webpack - use require callback to define
          dependencies for bundling   */
        const container = require('./components/Events').default

        /*  Return getComponent   */
        cb(null, container)

        /* Webpack named bundle   */
      },
      'events'
    )
  }
})
