export default {
  en: {
    name: 'Docdok Event',
    fields: {
      timestamp: 'Time',
      type: 'Type',
      principal: 'Username',
      data: 'Data'
    }
  },
  de: {
    name: 'Docdok Event',
    fields: {
      timestamp: 'Zeitpunkt',
      type: 'Type',
      principal: 'Username',
      data: 'Daten'
    }
  }
}
