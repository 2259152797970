// @flow
import SockJS from 'sockjs-client'
import { Stomp } from 'stompjs/lib/stomp'
import { setGuiValue } from 'common-docdok/src/common/GuiState/actions/setGuiValue'
import { getKeycloakClient } from 'common-docdok/src/common/Keycloak/keycloakProvider'
import { decrypt } from 'common-docdok/src/utils/crypto'
import { messagingActionTypes } from 'common-docdok/src/domain/messaging/actions'
import { socketActions } from './actions'
import { infoPanelActions } from '../common/InfoPanel/actions'
import executeActions from '../store/initialActions'
import Worker from './worker'

let stompClient
let reconnecting = false

export function decryptAction(action: any, secret: string) {
  return {
    ...action,
    payload: {
      ...action.payload,
      text: decrypt(action.payload.text, secret)
    },
    type: messagingActionTypes.POST_MESSAGE_SUCCEEDED
  }
}

function dispatchChatAction(store, action) {
  const { conversationId } = action.payload
  const conversation = store.getState().messaging.conversations[conversationId]
  if (conversation) {
    const decryptedAction = decryptAction(action, conversation.secret)
    store.dispatch(decryptedAction)
  } else {
    store.dispatch(socketActions.loadSubAndNotifyFromMsg(action.payload))
  }
}

function dispatchAction(store: Object, action: Object) {
  if (action.type === 'chat') {
    dispatchChatAction(store, action)
  } else {
    store.dispatch(action)
  }
}
// make sure only dispatch one action
let socketWorker

export function connect(store: Object) {
  const uid = getKeycloakClient().getUserId()
  const idToken = getKeycloakClient().getToken()
  const socket = new SockJS(`/rest/messaging/api/wsnotifications?token=${idToken}`)

  store.dispatch(setGuiValue('sockets', 'messaging', false))
  stompClient = Stomp.over(socket)
  socketWorker = new Worker(100, (message: Object) => {
    const action = JSON.parse(message.body)
    dispatchAction(store, action)
  })

  stompClient.connect(
    {},
    () => {
      if (reconnecting) {
        store.dispatch(infoPanelActions.showMessage('infos.reconnected'))
        executeActions(store)
        reconnecting = false
      }
      store.dispatch(setGuiValue('sockets', 'messaging', true))
      stompClient.subscribe(`/topic/${uid}`, socketWorker.queue)
    },
    () => {
      reconnecting = true
      setTimeout(() => connect(store), 10000)
    }
  )
}

export function disconnect() {
  if (stompClient != null) {
    stompClient.disconnect()
  }
  if (socketWorker != null) {
    socketWorker.stop()
  }
}
