// @flow

import AES from 'crypto-js/aes'
import MD5 from 'crypto-js/md5'
import Base64 from 'crypto-js/enc-base64'
import Utf8 from 'crypto-js/enc-utf8'
import WordArray from 'crypto-js/lib-typedarrays'

export const encrypt = (plainText: string, secretStr: string) => {
  // eslint-disable-next-line
  const iv = MD5(String(Date.now()))

  const parsedSecret = Base64.parse(secretStr)
  const encrypted = AES.encrypt(plainText, parsedSecret, {
    iv
  })
  const concated = encrypted.iv.concat(encrypted.ciphertext)
  const result = concated.toString(Base64)
  return result
}

export const decrypt = (ivAndCypher: string, secretStr: string) => {
  // base64 to WordArray (iv + cypher text)
  const parsedIvAndCypher = Base64.parse(ivAndCypher)
  // 16 bytes = 4 words
  const iv = WordArray.create(parsedIvAndCypher.words.slice(0, 4))
  // the rest
  const parsedCypherArray = WordArray.create(
    parsedIvAndCypher.words.slice(4, parsedIvAndCypher.words.length)
  )
  // WordArray of base64 encoded key
  const parsedSecret = Base64.parse(secretStr)
  const params = { ciphertext: parsedCypherArray, salt: '' }

  const message = AES.decrypt(params, parsedSecret, {
    iv
  })
  const plainText = Utf8.stringify(message)
  return plainText
}

export const UnreadableCryptoConfig = {
  secret: 'secret'
}

export const unreadable = (text: ?string, secret?: string) => {
  if (text && text !== '') {
    return AES.encrypt(text, secret || UnreadableCryptoConfig.secret).toString()
  }
  return text || ''
}

export const readable = (ciphertext: ?string, secret?: string) => {
  if (ciphertext && ciphertext !== '') {
    return AES.decrypt(ciphertext, secret || UnreadableCryptoConfig.secret).toString(Utf8)
  }
  return ciphertext || ''
}
