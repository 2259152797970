import * as React from 'react'
import { Field } from 'redux-form'
import { Translate, I18n } from 'react-redux-i18n'
import styled from 'styled-components'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import { Editor } from 'react-draft-wysiwyg'
import {
  ContentState,
  convertFromRaw,
  convertFromHTML,
  convertToRaw,
  EditorState
} from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import SelectField from './selectField'

import MultiSelectField from './MultiSelectField'

import EntitySearcher from '../../../EntitySearcher/containers/EntitySearcher'
import DatePickerField from './DatePickerField'
import EntitySelector from '../../../EntitySelector/containers/EntitySelector'
import AutoCompleteField from './autoCompleteField'
import fileUploadField from './fileUploadField'
import TextField from './textField'
import CheckboxField from './CheckoxField'
import TimePickerField from './TimePickerField'


export type PropertyDefinitionType = {
  type: string,
  specName?: string,
  patternNice?: string,
  options?: Array<{ key: string, value: string }>,
  fullWidth?: boolean,
  enum?: Array<any>,
  multiSelectable?: boolean,
  addOnly?: boolean,
  title?: string,
  normalize?: Function,
  readonly?: boolean,
  mandatory?: boolean,
  minLength?: number,
  maxLength?: number,
  pattern?: string,
  noSearch?: boolean,
  focusFunc?: () => void,
  selectFirst?: boolean,
  params?: Array<any>,
  style?: string,
  format?: (val: any) => any,
  width?: number,
  noLabel?: boolean,
  multiline: boolean,
  rowsMax: number,
  minDate?: any,
  isRow?: any
}

export type ReduxFormCompontenType = {
  meta: {
    touched: boolean,
    error: ?string,
    form: string,
    dirty: boolean,
    active: boolean
  },
  input: {
    value: any,
    name: string,
    onChange: Function,
    onBlur: Function,
    onFocus: Function
  }
}

export type FieldComponentPropsType = {
  disabled?: boolean,
  propertyDefinition: PropertyDefinitionType,
  style?: Object
} & ReduxFormCompontenType

const EntitySearcherWrapper = (
  props: FieldComponentPropsType & { fullScreen: boolean }
) => (
  <div
    style={{
      minWidth: !props.fullScreen
        ? props.propertyDefinition.width || 400
        : undefined
    }}
  >
    <EntitySearcher {...props} />
  </div>
)
const Searcher = withMobileDialog()(EntitySearcherWrapper)

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

class FieldComponent extends React.Component<FieldComponentPropsType> {
  static defaultProps = {
    isLast: false,
    style: {},
    children: undefined,
    disabled: false
  }

  render() {
    const { propertyDefinition, disabled, input, meta } = this.props

    let RenderFunc = TextField
    if (propertyDefinition.type === 'file') {
      RenderFunc = fileUploadField
    } else if (propertyDefinition.type === 'boolean') {
      RenderFunc = CheckboxField
    } else if (
      propertyDefinition.type === 'multiSelect' &&
      propertyDefinition.options
    ) {
      RenderFunc = MultiSelectField
    } else if (propertyDefinition.enum) {
      RenderFunc = SelectField
      propertyDefinition.options = propertyDefinition.enum.map((e) => ({
        value: e,
        key: e
      }))
    } else if (propertyDefinition.type === 'select') {
      if (propertyDefinition.specName) {
        RenderFunc = EntitySelector
      } else {
        RenderFunc = SelectField
      }
    } else if (
      propertyDefinition.type === 'autoComplete' &&
      propertyDefinition.options
    ) {
      RenderFunc = AutoCompleteField
    } else if (propertyDefinition.type === 'Date') {
      return (
        <DatePickerField
          disabled={disabled}
          input={input}
          meta={meta}
          minDate={propertyDefinition.minDate}
        />
      )
    } else if (propertyDefinition.type === 'Time') {
      RenderFunc = TimePickerField
    } else if (propertyDefinition.type === 'search') {
      RenderFunc = Searcher
    }
    return <RenderFunc {...this.props} />
  }
}

export type FormFieldPropsType = {
  name: string,
  fields: { [field: string]: PropertyDefinitionType },
  isLast?: boolean,
  style?: Object,
  children?: React.Node,
  readonly?: boolean,
  isRow: any,
  fullWidth: any,
  multiline: any,
  options: any,
  disabled: any,
  minDate: any,
  isRichTextArea: any,
  setEditorState: any,
  editorState: any
}

export default function formField(props: FormFieldPropsType) {
  const {
    isLast,
    name,
    fields,
    style,
    children,
    readonly,
    isRow,
    fullWidth,
    multiline,
    options,
    disabled,
    minDate,
    isRichTextArea,
    setEditorState,
    editorState
  } = props
  const propertyDefinition = {
    ...fields[name],
    readonly: readonly !== undefined ? readonly : fields[name].readonly,
    mandatory:
      readonly || fields[name].readonly ? false : fields[name].mandatory,
    minLength:
      readonly || fields[name].readonly ? undefined : fields[name].minLength,
    maxLength:
      readonly || fields[name].readonly ? undefined : fields[name].maxLength,
    pattern:
      readonly || fields[name].readonly ? undefined : fields[name].pattern,
    isRow,
    fullWidth,
    multiline,
    options: options || fields[name].options,
    disabled,
    minDate
  }
  // add values as last param to get form values

  let focusFunc
  if (isLast) {
    focusFunc = () => {
      this.setFocus()
    }
  }

  let element
  if (propertyDefinition.title) {
    element = (
      <div key={propertyDefinition.title} style={{ marginTop: 50 }}>
        {<Translate value={propertyDefinition.title} />}
        <hr />
      </div>
    )
  } else {
    element = (
      <FieldWrapper>
        {isRichTextArea ? (
          <Field
            key={name}
            style={style}
            normalize={propertyDefinition.normalize}
            disabled={propertyDefinition.readonly || disabled}
            name={name}
            component={(prop) => {
              return (
                <RichTextEditorField
                  {...prop}
                  editorState={editorState}
                  setEditorState={setEditorState}
                />
              )
            }}
            propertyDefinition={propertyDefinition}
            format={
              typeof propertyDefinition.format === 'function'
                ? propertyDefinition.format
                : undefined
            }
          >
            {children}
          </Field>
        ) : (
          <Field
            key={name}
            style={style}
            normalize={propertyDefinition.normalize}
            disabled={propertyDefinition.readonly || disabled}
            name={name}
            component={FieldComponent}
            focusFunc={focusFunc}
            propertyDefinition={propertyDefinition}
            format={
              typeof propertyDefinition.format === 'function'
                ? propertyDefinition.format
                : undefined
            }
          >
            {children}
          </Field>
        )}
      </FieldWrapper>
    )
  }
  return element
}

export class RichTextEditorField extends React.PureComponent {
  render() {
    const { input, editorState, setEditorState, meta } = this.props
    let content = {}
    try {
      content = meta.initial ? JSON.parse(meta.initial) : {}
    } catch (error) {
      content = {}
    }
    const contentData = content.blocks
      ? EditorState.createWithContent(convertFromRaw(content))
      : EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(meta.initial || '').contentBlocks,
          convertFromHTML(meta.initial || '').entityMap
        )
      )
    return (
      <>
        <Editor
          toolbar={{
            options: ['inline', 'fontSize', 'list', 'textAlign', 'history'],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            history: { inDropdown: true }
          }}
          editorState={editorState || contentData}
          toolbarClassName="rich-text-area-toolbar"
          wrapperClassName="rich-text-area-wrapper"
          editorClassName="rich-text-area"
          onEditorStateChange={(editorNewState) => {
            setEditorState(editorNewState)
            input.onChange(JSON.stringify(convertToRaw(editorNewState.getCurrentContent())))
          }}
        />
        {meta.error ? <p className='text-danger'>{meta.error} {I18n.t('forms.errors.includingSpace')}</p> : null}
      </>
    )
  }
}
