// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { MenuItem } from '@material-ui/core'

import { CREATE_PATIENT } from '../../Security/actionNames'
import Action from '../../Security/containers/action'
import { createPatientButtonActions } from '../actions'

type PropsType = {
  toggleCreatePatientDialog: typeof createPatientButtonActions.toggleCreatePatientDialog,
  closeMenu: () => void
}

class CreatePatientButton extends React.Component<PropsType> {
  menuItemClicked = () => {
    const { closeMenu, toggleCreatePatientDialog } = this.props
    closeMenu()
    toggleCreatePatientDialog()
  }

  render() {
    return (
      <div>
        <Action name={CREATE_PATIENT}>
          <MenuItem onClick={this.menuItemClicked} data-test='create-patient-header-btn'>
            <Translate value='patients.createPatient.title' />
          </MenuItem>
        </Action>
      </div>
    )
  }
}

export default connect(
  null,
  {
    toggleCreatePatientDialog: createPatientButtonActions.toggleCreatePatientDialog
  }
)(CreatePatientButton)
