export default {
  en: {
    tags: 'Tags filter...',
    preview: 'Preview',
    table: {
      title: 'Catalog'
    },
    updateContentDefinition: {
      title: 'Update Catalog',
      success: 'Catalog successfully updated'
    },
    uploadContentDefinition: {
      title: 'Upload Content',
      titleUpdate: 'Update Content',
      delete: 'Delete',
      edit: 'Edit',
      success: 'Content successfully uploaded to catalog',
      inProgress: 'Please wait until file is uploaded to proceed',
      description:
        'For multiple tags, separate each tag with a semicolon',
      deleteConfirmation: 'Do you really want to delete the file %{file}?',
      addFiles: 'Add File',
      replaceFiles: 'Replace File',
      orDropFiles: '... or drop files here'
    },
    sendContent: {
      title: 'Send Content',
      success: 'Content successfully sent'
    },
    clinicSelection: '%{count} Clinics%{clinics}'
  },
  de: {
    tags: 'Tags filter...',
    preview: 'Vorschau',
    table: {
      title: 'Katalog'
    },
    updateContentDefinition: {
      title: 'Katalog aktualisieren',
      success: 'Katalog wurde erfolgreich aktualisiert'
    },
    uploadContentDefinition: {
      title: 'Inhalt hochladen',
      titleUpdate: 'Inhalt aktualisieren',
      success: 'Inhalt erfolgreich zum Katalog hinzugefügt',
      inProgress: 'Please wait until file is uploaded to proceed',
      description:
        'Bei mehreren Markierungen, trennen Sie jede Markierung mit einer Semikolone',
      delete: 'Löschen',
      edit: 'Anpassen',
      deleteConfirmation: 'Wollen Sie die Datei %{file} wirklich löschen?',
      addFiles: 'Datei hinzufügen',
      replaceFiles: 'Datei ersetzen',
      orDropFiles: '...oder Dateien hier ablegen'
    },
    sendContent: {
      title: 'Inhalt versenden',
      success: 'Inhalt wurde erfolgreich versendet'
    },
    clinicSelection: '%{count} Kliniken%{clinics}'
  }
}
