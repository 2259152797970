// @flow

import invariant from 'invariant'
import type { AxiosPromise } from 'axios'
import moment from 'moment'

import { getAxiosInstance } from './'
import { getPatient } from './healthrelation'
import { getUser } from './user'
import buildPaginingUrl from './util'

import type { SurveyDefinitionDtoType, SurveyParticipantDtoType, SurveyParticipationDtoType } from '../types'
import type { ConnectedDeviceDtoType } from '../domain/survey/types/connectedDeviceDto'
import type { DeviceDatapointDtoType } from '../domain/survey/types/deviceDatapointDto'
import type { StudyParticipantDtoType } from '../domain/survey/types/studyParticipantDto'
import type { StudySummaryDtoType } from '../domain/survey/types/studySummaryDto'
import type { UserDtoType } from '../domain/user/types/userDto'

import type { StudyDtoType } from '../domain/survey/types/studyDto'
import type { StudyReportDefinitionDtoType } from '../domain/survey/types/studyReportDefinitionDto'
import type { StudySurveyDtoType } from '../domain/survey/types/studySurveyDto'
import type { StudyDoctorDtoType } from '../domain/survey/types/studyDoctorDto'
import type { SurveyQuestionStatisticType } from '../domain/survey/types/surveyQuestionStatistic'
import type { SurveyDefinitionSummaryType } from '../domain/survey/types/surveyDefinitionSummary'
import type { DeviceDefinitionDtoType } from '../domain/survey/types/deviceDefinitionDto'
import type { SurveyParticipationResultDtoType } from '../domain/survey/types/surveyParticipationResultDto'

type SurveyDefinitionsListType = AxiosPromise<Array<SurveyDefinitionDtoType>>

const base = '/rest/survey/api/'

/**
 * Get survey answers for study.
 * @return {AxiosPromise<*>}
 */
export function getSurveyAnswers(studyId: number, surveyId: number): AxiosPromise<*> {
  invariant(studyId, 'studyId undefined')
  invariant(surveyId, 'surveyId undefined')
  return getAxiosInstance().get(`${base}studies/${studyId}/survey-definitions/${surveyId}/answers`)
}

/**
 * Get surveys definition.
 * @return {AxiosPromise<*>}
 */
export function getSurveyDefinitions(
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: string,
  orderBy?: string
): SurveyDefinitionsListType {
  const path = buildPaginingUrl(`${base}survey-definitions`, pageSize, page, searchTerm, orderBy)
  return getAxiosInstance().get(path)
}

/**
 * Get survey definition by id.
 * @return {AxiosPromise<*>}
 */
export function getSurveyDefinition(id: number): AxiosPromise<SurveyDefinitionDtoType> {
  invariant(id, 'id undefined')
  return getAxiosInstance().get(`${base}survey-definitions/${id}`)
}

/**
 * update the surveyDefintion
 * @return {AxiosPromise<*>}
 */
export function updateSurveyDefinition(survey: SurveyDefinitionDtoType): AxiosPromise<SurveyDefinitionDtoType> {
  invariant(survey, 'survey undefined')
  return getAxiosInstance().put(`${base}survey-definitions`, survey)
}

/**
 * Active survey definition by id.
 * @return {AxiosPromise<*>}
 */
export function activateSurveyDefinition(id: number): AxiosPromise<*> {
  invariant(id, 'id undefined')
  return getAxiosInstance().put(`${base}survey-definitions/${id}/activate`)
}

/**
 * Send survey.
 * @return {AxiosPromise<*>}
 */
export function sendSurvey(surveyParticipant: SurveyParticipantDtoType): AxiosPromise<*> {
  invariant(surveyParticipant, 'surveyParticipant undefined')
  return getAxiosInstance().post(`${base}survey-participations`, surveyParticipant)
}

/**
 * Send survey.
 * @return {AxiosPromise<*>}
 */
export function scheduleSurvey(surveyScheduling: SurveyParticipantDtoType): AxiosPromise<*> {
  invariant(surveyScheduling, 'surveyScheduling undefined')
  return getAxiosInstance().post(`${base}patient-survey-plans`, surveyScheduling)
}
/**
 * create Studyparticipant survey.
 * @return {AxiosPromise<*>}
 */
export function createStudyParticipant(studyParticipant: StudyParticipantDtoType): AxiosPromise<*> {
  invariant(studyParticipant, 'surveyParticipant undefined')
  return getAxiosInstance().post(`${base}studies/${studyParticipant.studyId}/study-participants`, studyParticipant)
}

/**
 * Get all studies.
 * @return {AxiosPromise<*>}
 */

export function getAllStudies(
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: string,
  orderBy?: string
): AxiosPromise<Array<StudySummaryDtoType>> {
  const path = buildPaginingUrl(`${base}studies`, pageSize, page, searchTerm, orderBy)
  return getAxiosInstance().get(path)
}

/**
 * Get survey participations.
 * @return {AxiosPromise<*>}
 */
export function getSurveyParticipations(
  surveyId: string,
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: string,
  orderBy?: string
): AxiosPromise<Array<SurveyParticipationDtoType>> {
  invariant(surveyId, 'surveyId undefined')
  const path = buildPaginingUrl(
    `${base}survey-definitions/${surveyId}/survey-participations`,
    pageSize,
    page,
    searchTerm,
    orderBy
  )
  return getAxiosInstance().get(path)
}

/**
 * Get survey participation.
 * @return {AxiosPromise<*>}
 */
export function getSurveyParticipation(participationId: number): AxiosPromise<SurveyParticipationDtoType> {
  invariant(participationId, 'participationId undefined')
  return getAxiosInstance().get(`${base}survey-participations/${participationId}`)
}

/**
 * Get authorization url for a device.
 * @return {AxiosPromise<string>}
 */
export function getIntegrationAuthorization(patientId: string, hubName: string): AxiosPromise<string> {
  invariant(patientId, 'userRef undefined')
  invariant(hubName, 'deviceName undefined')
  return getAxiosInstance().get(`${base}integration/${hubName}/authorization/redirect/${patientId}`)
}

/**
 * Get the state for a device
 * @return {AxiosPromise<Object>}
 */
export function getDeviceState(patientId: string): AxiosPromise<ConnectedDeviceDtoType> {
  invariant(patientId, 'userRef undefined')
  return getAxiosInstance().get(`${base}connecteddevices/${patientId}`)
}

export function getDeviceDefinitions(
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: string,
  orderBy?: string
): AxiosPromise<Array<DeviceDefinitionDtoType>> {
  const path = buildPaginingUrl(`${base}device-definitions`, pageSize, page, searchTerm, orderBy)
  return getAxiosInstance().get(path)
}

/**
 * Get the data of a device
 * @return { AxiosPromise<Array<DeviceDatapointDtoType>>}
 */
export function getDeviceData(
  patientId: string,
  deviceName: string,
  startDate: Date,
  endDate: Date
): AxiosPromise<Array<DeviceDatapointDtoType>> {
  invariant(patientId, 'userRef undefined')
  invariant(deviceName, 'deviceName undefined')

  const from = moment(startDate).format('YYYY-MM-DD')
  const to = moment(endDate).format('YYYY-MM-DD')

  return getAxiosInstance().get(`${base}devicedatapoints/${deviceName}/${patientId}?from=${from}&to=${to}`)
}

/**
 * Fetch user's surveys.
 * @return {AxiosPromise<Array<SurveyParticipationDtoType>>}
 */
export function getMySurveysAsync(): AxiosPromise<Array<SurveyParticipationDtoType>> {
  return getAxiosInstance().get(`${base}survey-participations/mysurveys`)
}

/**
 * Fetch user's surveys by userRef.
 * @return {AxiosPromise<Array<SurveyParticipationDtoType>>}
 */
export function getSurveyParticipationsByPatientId(
  patientUuid: string,
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: string,
  orderBy?: string
): AxiosPromise<Array<SurveyParticipationDtoType>> {
  invariant(patientUuid, 'patientUuid undefined')
  const path = buildPaginingUrl(
    `${base}patients/${patientUuid}/survey-participations`,
    pageSize,
    page,
    searchTerm,
    orderBy
  )
  return getAxiosInstance().get(path)
}

/**
 * Fetch user's studys by userRef.
 * @return {AxiosPromise<Array<SurveyParticipationDtoType>>}
 */
export function getStudyParticipationsByPatientId(uuid: string): AxiosPromise<Array<StudyParticipantDtoType>> {
  invariant(uuid, 'uuid undefined')
  return getAxiosInstance().get(`${base}patients/${uuid}/study-participants/`)
}

export function getSurveyDefintionsByPatientId(uuid: string): AxiosPromise<Array<SurveyDefinitionSummaryType>> {
  invariant(uuid, 'uuid undefined')
  return getAxiosInstance().get(`${base}patients/${uuid}/survey-definitions/`)
}

export function getAvailableStudySurveys(
  studyId: string,
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: string,
  orderBy?: string
): AxiosPromise<Array<SurveyDefinitionSummaryType>> {
  invariant(studyId, 'studyId undefined')
  const path = buildPaginingUrl(`${base}studies/${studyId}/available-surveys`, pageSize, page, searchTerm, orderBy)
  return getAxiosInstance().get(path)
}

export function getAvailableStudyDevices(
  studyId: string,
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: string,
  orderBy?: string
): AxiosPromise<Array<SurveyDefinitionSummaryType>> {
  invariant(studyId, 'studyId undefined')
  const path = buildPaginingUrl(`${base}studies/${studyId}/available-devices`, pageSize, page, searchTerm, orderBy)
  return getAxiosInstance().get(path)
}

/**
 * Fetch study by id<.
 * @return {AxiosPromise<Array<StudySummaryDtoType>>}
 */
export function getStudy(studyId: number): AxiosPromise<StudySummaryDtoType> {
  invariant(studyId, 'studyId undefined')
  return getAxiosInstance().get(`${base}studies/${studyId}`)
}

/**
 * Fetch user's studys by userRef.
 * @return {AxiosPromise<Object>}
 */
export function getSurveyResponses(participationId: number): AxiosPromise<Object> {
  invariant(participationId, 'participationId undefined')
  return getAxiosInstance().get(`${base}survey-participations/${participationId}/responses/`)
}

type ReportDefType = AxiosPromise<StudyReportDefinitionDtoType>
export function getReportDefintions(): ReportDefType {
  return getAxiosInstance().get(`${base}studies/report-definitions/`)
}

export function getStudyReport(studyId: number, reportDefId: number): AxiosPromise<any> {
  invariant(studyId, 'studyId undefined')
  invariant(reportDefId, 'reportDefId undefined')
  return getAxiosInstance().get(`${base}studies/${studyId}/report-definitions/${reportDefId}/generate`)
}

export function getSurveyCSV(surveyId: number): AxiosPromise<any> {
  invariant(surveyId, 'surveyId undefined')
  return getAxiosInstance().get(`${base}survey-participations/generate-survey-csv?surveyId=${surveyId}`)
}

export function getSurveyJSON(surveyId: number): AxiosPromise<any> {
  invariant(surveyId, 'surveyId undefined')
  return getAxiosInstance().get(`${base}survey-participations/generate-survey-json?surveyId=${surveyId}`)
}

export function createStudy(study: StudyDtoType): AxiosPromise<StudyDtoType> {
  invariant(study, 'study undefined')
  return getAxiosInstance().post(`${base}studies`, study)
}

export function getStudySurveys(
  studyId: number,
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: ?string,
  orderBy?: string
): AxiosPromise<StudySurveyDtoType> {
  invariant(studyId, 'studyId undefined')
  const path = buildPaginingUrl(`${base}studies/${studyId}/study-surveys`, pageSize, page, searchTerm, orderBy)
  return getAxiosInstance().get(path)
}

export function getStudySurveysDefinitions(
  studyId: number,
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: ?string,
  orderBy?: string
): AxiosPromise<SurveyDefinitionSummaryType> {
  invariant(studyId, 'studyId undefined')
  const path = buildPaginingUrl(`${base}studies/${studyId}/survey-definitions`, pageSize, page, searchTerm, orderBy)
  return getAxiosInstance().get(path)
}

export function addSurveyToStudy(studySurvey: StudySurveyDtoType): AxiosPromise<StudySurveyDtoType> {
  invariant(studySurvey, 'studySurvey undefined')
  return getAxiosInstance().post(`${base}studies/${studySurvey.studyId}/study-surveys`, studySurvey)
}

export function getStudyDevices(
  studyId: number,
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: ?string,
  orderBy?: string
): AxiosPromise<Object> {
  invariant(studyId, 'studyId undefined')
  const path = buildPaginingUrl(`${base}studies/${studyId}/study-surveys`, pageSize, page, searchTerm, orderBy)
  return getAxiosInstance().get(path)
}
export type StudyDoctorUserType = StudyDoctorDtoType & UserDtoType
export function getStudyPhysicians(
  studyId: number
  /*   pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: ?string,
  orderBy?: string */
): AxiosPromise<StudyDoctorUserType> {
  invariant(studyId, 'studyId undefined')

  return getAxiosInstance()
    .get(`${base}studies/${studyId}/study-doctors`)
    .then((studyDoctorsResult: any) =>
      Promise.all(
        studyDoctorsResult.data.map((row: StudyDoctorDtoType) =>
          getUser(row.userRef).then(userResult => ({
            ...userResult.data,
            ...row // this will override the id attribute of the user
          }))
        )
      ).then((data: any) => ({ ...studyDoctorsResult, data }))
    )
}

export function deleteStudyPhysician(studyId: string, physicianId: string): AxiosPromise<*> {
  invariant(studyId, 'studyId undefined')
  invariant(physicianId, 'physicianId undefined')
  return getAxiosInstance().delete(`${base}studies/${studyId}/study-doctors/${physicianId}`)
}

export function addStudyPhysician(studyId: string, userRef: string): AxiosPromise<StudyDoctorDtoType> {
  invariant(studyId, 'studyId undefined')
  invariant(userRef, 'physicianId/userRef undefined')
  return getAxiosInstance().post(`${base}studies/${studyId}/study-doctors/`, { studyId, userRef })
}

export function removeStudyPhysician(studyDoctorDto: StudyDoctorDtoType): AxiosPromise<StudyDoctorDtoType> {
  invariant(studyDoctorDto, 'studyDoctorDto undefined')
  const { id } = studyDoctorDto
  invariant(id, 'studyDoctorDto.id undefined')

  return getAxiosInstance().delete(`${base}studies/${studyDoctorDto.studyId}/study-doctors/${id}`)
}

export function getStudyParticipants(
  studyId: number
  /*  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: ?string,
  orderBy?: string */
): AxiosPromise<Object> {
  invariant(studyId, 'studyId undefined')
  return getAxiosInstance()
    .get(`${base}studies/${studyId}/study-participants`)
    .then((studyParticipantsResult: any) =>
      Promise.all(
        studyParticipantsResult.data.map((studyParticipant: StudyParticipantDtoType) =>
          getPatient(studyParticipant.patientUuid).then(patientResult => ({
            ...studyParticipant,
            ...patientResult.data
          }))
        )
      ).then((data: any) => ({ ...studyParticipantsResult, data }))
    )
}

export function removeStudySurvey(studyId: number, surveyId: number): AxiosPromise<void> {
  invariant(studyId, 'studyId undefined')
  invariant(surveyId, 'surveyId undefined')
  return getAxiosInstance().delete(`${base}studies/${studyId}/study-surveys/${surveyId}`)
}

/**
 * Delete Survey delete call.
 * @param {number} surveyId The surveyId choosed to be deleted in the UI
 * @return {AxiosPromise}
 */
export function deleteSurvey(surveyId: number): AxiosPromise<void> {
  invariant(surveyId, 'surveyId undefined')
  return getAxiosInstance().delete(`${base}survey-definitions/${surveyId}`)
}

export function updateStudy(study: StudyDtoType): AxiosPromise<StudyDtoType> {
  invariant(study, 'study undefined')
  return getAxiosInstance().put(`${base}studies`, study)
}

export function updateStudySurvey(studySurvey: StudySurveyDtoType): AxiosPromise<StudySurveyDtoType> {
  invariant(studySurvey, 'studySurvey undefined')
  const { studyId } = studySurvey
  return getAxiosInstance().put(`${base}studies/${studyId}/study-surveys`, studySurvey)
}

export function uploadLSSFile(name: string, content: any): AxiosPromise<SurveyDefinitionDtoType> {
  invariant(content, 'content of survey file is undefiend')
  invariant(name, 'name of survey file is undefiend')
  return getAxiosInstance().post(`${base}survey-definitions/${name}`, content)
}

export function uploadSurveyThemeFile(name: string, content: any): AxiosPromise<SurveyDefinitionDtoType> {
  invariant(content, 'content of survey theme file is undefiend')
  invariant(name, 'name of survey theme file is undefiend')
  return getAxiosInstance().post(`${base}survey-themes`, {
    name,
    description: name,
    specification: content
  })
}

export function uploadEditSurveyThemeFile(id: any, name: string, content: any): AxiosPromise<SurveyDefinitionDtoType> {
  invariant(content, 'content of survey theme file is undefiend')
  invariant(name, 'name of survey theme file is undefiend')
  return getAxiosInstance().put(`${base}survey-themes`, {
    id,
    name,
    description: name,
    specification: content
  })
}

export function getSurveyStatistic(
  surveyId: number,
  patientUuid: string
): AxiosPromise<Array<SurveyQuestionStatisticType>> {
  invariant(surveyId, 'surveyId undefined')
  invariant(patientUuid, 'patientUuid undefined')
  return getAxiosInstance().get(`${base}survey-definitions/${surveyId}/patients/${patientUuid}/statistics`)
}

export function downloadSurveyAnswers(studyId: number): AxiosPromise<*> {
  invariant(studyId, 'studyId undefined')
  return getAxiosInstance().get(`${base}studies/${studyId}/all-survey-answers`)
}

export function saveSurveyAnswers(
  participationResult: SurveyParticipationResultDtoType,
  complete: boolean
): AxiosPromise<*> {
  invariant(participationResult, 'answers undefined')
  const { id: surveyParticipationId } = participationResult
  const apiSuffix = complete ? 'complete' : 'persist'
  return getAxiosInstance().post(
    `${base}survey-participations/${surveyParticipationId}/${apiSuffix}`,
    participationResult
  )
}
export function getSurveySpec(participationId: number): AxiosPromise<Object> {
  invariant(participationId, 'participationId undefined')
  const surveyParticipationId = participationId
  return getAxiosInstance().get(`${base}survey-participations/${surveyParticipationId}/spec`)
}

export function deleteStudy(studyId: number): AxiosPromise<void> {
  invariant(studyId, 'studyId undefined')
  return getAxiosInstance().delete(`${base}studies/${studyId}`)
}

/**
 * Get Anonymous Survey Physician.
 * @return {AxiosPromise<*>}
 */
export function getAnonymousSurveyPhysician(id: number | string): AxiosPromise<*> {
  return getAxiosInstance().get(`${base}anonymous_survey_physician/${id}`)
}

/**
 * Get surveys themes.
 * @return {AxiosPromise<*>}
 */
export function getSurveyThemes(
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: string,
  orderBy?: string
) {
  const path = buildPaginingUrl(`${base}survey-themes`, pageSize, page, searchTerm, orderBy)
  return getAxiosInstance().get(path)
}

/**
 * Get surveys themes.
 * @return {AxiosPromise<*>}
 */
export function deleteSurveyThemes(
  id: any
) {
  const path = buildPaginingUrl(`${base}survey-themes/${id}`)
  return getAxiosInstance().delete(path)
}

/**
 * Get surveys groups.
 * @return {AxiosPromise<*>}
 */
export function getSurveyGroups(
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: string,
  orderBy?: string
) {
  const path = buildPaginingUrl(`${base}survey-groups`, pageSize, page, searchTerm, orderBy)
  return getAxiosInstance().get(path)
}

/**
 * Get surveys groups.
 * @return {AxiosPromise<*>}
 */
export function deleteSurveyGroups(
  id: any
) {
  const path = buildPaginingUrl(`${base}survey-groups/${id}`)
  return getAxiosInstance().delete(path)
}

export function createSurveyGroup(
  payload: any
): AxiosPromise<*> {
  return getAxiosInstance().post(
    `${base}survey-groups`,
    payload
  )
}

export function updateSurveyGroup(
  payload: any
): AxiosPromise<*> {
  return getAxiosInstance().put(
    `${base}survey-groups`,
    payload
  )
}
