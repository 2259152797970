// @flow
import pathOr from 'ramda/src/pathOr'
import pipe from 'ramda/src/pipe'
import prop from 'ramda/src/prop'
import propEq from 'ramda/src/propEq'
import map from 'ramda/src/map'
import reject from 'ramda/src/reject'

import { type CommonStateType } from '../../../types'

const getCoworkerSubscriber = (conversationId: number) => (state: CommonStateType) => {
  const patientUserRef = pathOr(
    null,
    ['messaging', 'conversations', conversationId, 'meta', 'userRef'],
    state
  )
  return pipe(
    pathOr([], ['messaging', 'conversations', conversationId, 'subscriptions']),
    reject(propEq('userRef', patientUserRef)),
    map(prop('userRef'))
  )(state)
}

export default getCoworkerSubscriber
