// @flow

import React from 'react'
import { Translate, I18n } from 'react-redux-i18n'
import LinearProgress from '@material-ui/core/LinearProgress'
import TextField from '@material-ui/core/TextField'
import throttle from 'lodash/throttle'

type PropsType = {
  id: string,
  width?: number,
  min: number,
  max: number,
  value: number,
  floatingLabelText: string,
  hintText: string,
  onChange: (val: number) => any
}

type StateType = {
  value: number,
  max: string
}

export default class Range extends React.Component<PropsType, StateType> {
  static defaultProps = { width: 300 }

  state = { value: 0, max: '0' }

  onChange = throttle(
    (val: number) => {
      const { onChange } = this.props

      onChange(val)
    },
    2000,
    { leading: false }
  )

  copyPropsToState(props: PropsType) {
    const { value, max } = props
    let newState = {}
    if (value !== null && !isNaN(value)) {
      newState = { ...newState, value }
    }
    if (max != null && !isNaN(max)) {
      newState = { ...newState, max: `${max}` }
    }

    this.setState(newState)
  }

  componentWillMount() {
    this.copyPropsToState(this.props)
  }

  componentWillReceiveProps(newProps: PropsType) {
    this.copyPropsToState(newProps)
  }

  render() {
    const { width, min, floatingLabelText, hintText, id } = this.props
    const { value, max } = this.state

    const steps = Number(max) - min
    const stepSize = 100 / steps
    const currentValue = value * stepSize

    return (
      <div style={{ width }}>
        <TextField
          id={id}
          value={max}
          fullWidth
          placeholder={I18n.t(hintText)}
          label={<Translate value={floatingLabelText} />}
          onChange={(event) => {
            const val = event.target.value
            this.setState({ max: val })
            if (val !== '' && !isNaN(val)) this.onChange(Number(val))
          }}
        />
        <LinearProgress variant='determinate' value={currentValue} />
      </div>
    )
  }
}
