// @flow
import subscriptionsSaga from './subscriptions'
import messagesSaga from './messages'
import metaData from './metaData'
import attachmentsSaga from './attachments'
import catalogSaga from './catalog'
import type { GenType } from '../../../types'

export default function messagesRootSaga(): Array<GenType> {
  return [
    ...messagesSaga(),
    ...subscriptionsSaga(),
    ...attachmentsSaga(),
    ...catalogSaga(),
    ...metaData()
  ]
}
