// @flow
import React from 'react'
import { connect } from 'react-redux'
import path from 'ramda/src/path'
import pipe from 'ramda/src/pipe'
import type { MessageDtoType } from 'common-docdok/src/domain/messaging/types/messageDto'
import type { UserDtoType } from 'common-docdok/src/domain/user/types/userDto'
import { messagingActions } from 'common-docdok/src/domain/messaging/actions'
import isUrlCached from 'common-docdok/src/domain/messaging/selectors/isUrlCached'
import { getSubjectSub } from 'common-docdok/src/selectors/conversation'
import { isMessageByPatient, isMessageRead } from 'common-docdok/src/selectors/message'
import { getLastReadMessage } from 'common-docdok/src/selectors/subscription'

import SurveyCompleted from '../components/surveyCompleted'
import messageChip from '../components/MessageChip'
import SurveyInvitation from './surveyInvitation'
import AttentionRequest from './attentionRequest'
import ConversationEvent from '../components/ConversationEvent'
import SurveyNotCompleted from '../components/surveyNotCompleted'
import LastProtocolSurveySent from './lastProtocolSurveySent'
import SurveyReminder from '../components/surveyReminder'
import ActivityReminder from '../components/activityReminder'
import AppointmentNotification from './appointmentNotification'
import hycareSetTarget from '../components/hycareSetTarget'

type OwnPropsType = { message: MessageDtoType, closeRequest?: ?() => void }

export type MessagePropsType = {
  user: ?UserDtoType,
  showOriginalUrl: boolean,
  messageByPatient: boolean,
  readByPrimary: boolean,
  markAsCached: typeof messagingActions.markAsCached
} & OwnPropsType

const renderRegistry = {
  SURVEY_COMPLETED: SurveyCompleted,
  NEW_SURVEY: SurveyInvitation,
  requestattention: AttentionRequest,
  conversationevent: ConversationEvent,
  SURVEY_NOTCOMPLETED: SurveyNotCompleted,
  LAST_PROTOCOL_SURVEY_SENT: LastProtocolSurveySent,
  SURVEY_REMINDER: SurveyReminder,
  APPOINTMENT: AppointmentNotification,
  ACTIVITY: ActivityReminder,
  MEDICAL_HEALTH_TARGET: hycareSetTarget
}

function MessageComp(props: MessagePropsType) {
  let Component
  if (!props.message) return null
  if (renderRegistry[props.message.meta.subtype]) {
    Component = renderRegistry[props.message.meta.subtype]
  } else if (renderRegistry[props.message.meta.notificationtype]) {
    Component = renderRegistry[props.message.meta.notificationtype]
  } else {
    Component = messageChip
  }

  return Component && <Component {...props} />
}

const mapStateToProps = (state: StoreType, props: OwnPropsType) => {
  const { message } = props
  if (!message) {
    return {
      user: undefined,
      showOriginalUrl: false,
      messageByPatient: false,
      readByPrimary: false
    }
  }
  const conversation = path(['messaging', 'conversations', message.conversationId])(state)

  const messageByPatient = isMessageByPatient(conversation)(message)
  const readByPrimary = pipe(
    getSubjectSub,
    getLastReadMessage,
    isMessageRead
  )(conversation)(message)

  let user = state.userCache.users[message.userRef] ? state.userCache.users[message.userRef] : {}
  if (!user.uuid && message.meta) {
    user = state.userCache.users[message.meta.userRef] ? state.userCache.users[message.meta.userRef] : {}
  }
  const showOriginalUrl = message.mediaResource
    ? isUrlCached(state.messaging.cachedAttachments, message.mediaResource.originalUrl)
    : false

  return { user, showOriginalUrl, messageByPatient, readByPrimary }
}

export const connectMessage = connect(
  mapStateToProps,
  { markAsCached: messagingActions.markAsCached }
)

export default connectMessage(MessageComp)
