// @flow
import React from 'react'
import compose from 'ramda/src/compose'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import { Translate } from 'react-redux-i18n'
import Button from '@material-ui/core/Button'

import { alertActions } from '../actions'

type PropsType = {
  messageKey: ?string,
  open: boolean,
  hideAlert: typeof alertActions.hideAlert,
  params: Object
}

function alertDialog(props: PropsType) {
  const { messageKey, open, hideAlert, params } = props
  if (!open) return null
  return (
    <Dialog modal={false} open={open} onRequestClose={hideAlert}>
      <DialogTitle>
        <Translate value={messageKey} {...params} />
      </DialogTitle>
      <DialogActions>
        <Button variant='contained' color='primary' onClick={hideAlert}>
          <Translate value='action.ok' />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state: StoreType) => ({
  ...state.alert
})

export default compose(
  connect(
    mapStateToProps,
    alertActions
  ),
  withMobileDialog()
)(alertDialog)
