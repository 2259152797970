// @flow
/* eslint-disable */
import React from 'react'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Button,Row, Col, Modal,  ModalHeader, ModalBody, ModalFooter  } from 'reactstrap';
import * as Survey from 'survey-react'
import * as SurveyReact from "survey-react-ui";
import styled from 'styled-components'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import InfoRounded from '@material-ui/icons/InfoRounded'
import { Translate, I18n } from 'react-redux-i18n'
import { surveyActions } from 'common-docdok/src/domain/survey/actions'
import type { SurveyParticipantDtoType } from 'common-docdok/src/types'
import docdokNouislider from './docdokNouislider'
import { launchSurveyActions } from '../../../common/LaunchSurvey/actions'
import TwoLineRating from './ExtendedRating'
import { getKeycloakClient } from 'common-docdok/src/common/Keycloak/keycloakProvider'
import pathOr from 'ramda/src/pathOr'
import 'bootstrap/dist/css/bootstrap.css'
import 'nouislider/distribute/nouislider.css'
import "survey-core/defaultV2.css";
import './styles/docdokStylev2.css';
import ExtendedContentRender from './ExtendedContentRender';

const StyledTypography = styled(Typography)`
  && {
    margin-top: 30px;
  }
`

type PropsType = {
  params: {
    participationId: number
  },
  spec: ?Object,
  saveSurveyAnswersRequested: typeof surveyActions.saveSurveyAnswersRequested,
  getSurveySpecRequested: typeof surveyActions.getSurveySpecRequested,
  getSurveyParticipationRequested: typeof surveyActions.getSurveyParticipationRequested,
  participation: SurveyParticipantDtoType,
  classes: Object,
  launchSurveyClosed: typeof launchSurveyActions.launchSurveyClosed,
  public?: Boolean,
  getSurveyParticipationsReset: typeof surveyActions.getSurveyParticipationsReset,
}

type StateType = {
  selectedOption: {},
  useSpec: {},
  modal: boolean
}


docdokNouislider(Survey)
Survey.JsonObject.metaData.addProperty('rating', {
  name: 'renderAs',
  default: 'normal'
})
Survey.StylesManager.ThemeColors['default']['$main-color'] = '#2774a3';
Survey.StylesManager.applyTheme("defaultV2")
Survey.ChoicesRestfull.getCachedItemsResult = (obj) => {
  return false
}

Survey.ChoicesRestfull.onBeforeSendRequest = (sender, options) => {
  const token = getKeycloakClient().getToken()
  options.request.setRequestHeader('Authorization', `Bearer ${token}`)
}

Survey.Serializer.addProperty("question", "popupDescription:text");
Survey.Serializer.addProperty("question", "popupCloseButton:text");
Survey.Serializer.addProperty("question", "popupTitle:text");
Survey.Serializer.addProperty("question", "moreInfo:text");

Survey.Serializer.addProperty("page", "popupDescription:text");
Survey.Serializer.addProperty("page", "popupCloseButton:text");
Survey.Serializer.addProperty("page", "popupTitle:text");
Survey.Serializer.addProperty("page", "moreInfo:text");

class SurveyParticipation extends React.Component<PropsType> {
  constructor(props: PropsType) {
    super(props)

    const {
      spec,
      participation,
      params: { participationId },
      getSurveySpecRequested,
      getSurveyParticipationRequested,
    } = props
    if (!spec) {
      getSurveySpecRequested(participationId)
    }
    getSurveyParticipationRequested(participationId)

    const answers = participation ? JSON.parse(participation.answers) : undefined
    
    this.state ={
      useSpec: spec,
      selectedOption: answers && answers.locale ? {value: answers.locale 
        , label: answers.locale} : {value: 'de', label: 'de'},
      modal: false,
      selectedMoreInfo: {}
    }

    Survey.CustomWidgetCollection.Instance.addCustomWidget({
      name: 'visual_analog_scale',
      isFit: (question) =>
        question.renderAs && question.renderAs.includes('twolines'),
      render: (question) => (
        <TwoLineRating
          question={question}
          onRatingChange={(rating) => {
            question.value = rating
          }}
        />
      )
    })

    Survey.CustomWidgetCollection.Instance.addCustomWidget({
      name: 'catalog-content-question',
      isFit: (question) =>
        question.renderAs &&
        question.renderAs.includes('catalog-content-renderer'),
      render: (question) => <ExtendedContentRender question={question} />
    })
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log(error, info)
  }

  componentDidMount() {
    Survey.ChoicesRestfull.itemsResult = {}
  }

  componentDidUpdate(prevProps: PropsType) {
    const surveyId = pathOr(0, ['participation', 'surveyId'])(this.props)
    const token = getKeycloakClient().getToken()

    if(!prevProps.spec && this.props.spec){
        this.setState({
          useSpec: this.props.spec
        })
    }
    if(!prevProps.participation && this.props.participation && this.props.participation.answers){
      const answers = JSON.parse(this.props.participation.answers);
      if(answers && answers.locale){
        this.setState({
          selectedOption: {value: answers.locale , label: answers.locale }
        })
      }

    }
  }

  componentWillUnmount() {
    this.props.getSurveyParticipationsReset(this.props.params.participationId)
  }

  json = {
    pages: []
  }
  dropdownSelectedOption = {}

  toggle = () => this.setState({
    modal: !this.state.modal
  });

  
  handleChange = selectedOption => {
    this.toggle();
    this.dropdownSelectedOption = selectedOption;
  };

  handleUpdateSurvey = () => {
    
    this.setState({ selectedOption: this.dropdownSelectedOption });
    let specs = this.state.useSpec
    specs.locale = this.dropdownSelectedOption.value
    this.setState({
      useSpec: specs
    })
    this.toggle()
    
  }

  showDescription(element) {
    this.setState({
      selectedMoreInfo: element || {}
    })
  }

  render() {
    const {
      saveSurveyAnswersRequested,
      spec,
      params: { participationId },
      participation,
      launchSurveyClosed,
      location
    } = this.props

    const query = new URLSearchParams(location.search)
    const mode = query.get('mode') || 'edit'
    const isPreview = mode === 'display'
    // const useSpec = this.json.pages.length > 0 ? this.json : spec
    if (!this.state.useSpec || !participation) return null

    if (mode === 'edit' && participation && participation.completionDate) {
      return (
        <StyledTypography variant="h5" align="center">
          <Translate value="surveys.surveyParticipation.completed" />
        </StyledTypography>
      )
    }

    let options = [
    ];
    if(spec && spec.allLocales){
      options = spec.allLocales.map(locale =>{
        return {
          value: locale , label:locale
        }
      })
    }

    let answers
    let currentPageNo = 0
    try {
      const { currentPage, ...rest } = JSON.parse(participation.answers)
      currentPageNo = currentPage + 1
      answers = rest
    } catch (e) {
      // ignore
    }
    const specsToConsider = this.state.useSpec;
    if (specsToConsider && specsToConsider.firstPageIsStarted && Array.isArray(specsToConsider.pages) && specsToConsider.pages.length > 0) {
      if (!specsToConsider.title) {
        specsToConsider.title = specsToConsider.pages[0].title || specsToConsider.pages[0].name
      }
      specsToConsider.description = specsToConsider.pages[0].description || specsToConsider.pages[0].title
      if (specsToConsider.description && specsToConsider.description === specsToConsider.title) {
        specsToConsider.description = ''
      }
    }
    if (!this.model && participation && participation.id) {
      this.model = new Survey.Model(specsToConsider)
      this.model.onLoadChoicesFromServer.add((event) => {
        console.log(event, null, 2)
      })
      if (mode) {
        this.model.mode = mode
      }
      if (isPreview) {
        this.model.calcIsCompleteButtonVisible = () => {
          return isPreview && (this.model.currentPageNo + 1) === this.model.pageCount
        }
      }
      this.model.onGetQuestionTitleActions.add((_, opt) => {
        if (opt && opt.question && opt.question.moreInfo === 'true') {
          opt.titleActions = [{
            title: I18n.t('surveys.surveyParticipation.moreInfo'),
            innerCss: 'btn-more-info',
            action: () => {
              this.showDescription(opt.question);
            }
          }];
        }
      });
      this.model.onGetPageTitleActions.add((_, opt) => {
        if (opt && opt.page && opt.page.moreInfo === 'true') {
          opt.titleActions = [{
            title: I18n.t('surveys.surveyParticipation.moreInfo'),
            innerCss: 'btn-more-info',
            action: () => {
              this.showDescription(opt.page);
            }
          }];
        }
      });
      if (answers) {
        this.model.data = answers
      }
      if (currentPageNo) {
        this.model.currentPageNo = currentPageNo
      }
    }

    return (
      <div style={{ paddingLeft: '2px' }} className="survey-rendering">
        <style jsx global>{
          `
            ${spec && spec.customTheme && spec.customTheme['font-family'] ? `
              @font-face {
                font-family: "${spec.customTheme['font-family']}";
                src: url("/fonts/${spec.customTheme['font-family']}.ttf") format("truetype");
              }
              .survey-rendering * {
                font-family: ${spec.customTheme['font-family']}, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
              }
            `: ``}
            .survey-rendering {
              ${Object.keys(spec ? spec.customTheme || {} : {})
                .filter((_k) => _k !== 'theme-name')
                .map((_key) => {
                  return `${_key}: ${spec.customTheme[_key]}`
                })
                .join(';')}
            }
          `
        }
        </style>
        <Row>
        {options.length > 0 && (
            <Col md ={1} xs={3}>
              <Dropdown
                value={this.state.selectedOption}
                onChange={this.handleChange}
                options={options}
              />
            </Col>
          )}
          <Col md={12}>
            <SurveyReact.Survey
              currentPageNo={currentPageNo}
              data={answers}
              model={this.model}
              sendResultOnPageNext
              onComplete={(survey) => {
                if(!isPreview) {
                  saveSurveyAnswersRequested(
                    Number(participationId),
                    survey.data,
                    true
                  )
                }
                // This message is send so the expojs app can  to finish of the survey
                window.postMessage('surveyCompleted', window.location)
                // New RN postMessage
                if (window.ReactNativeWebView) {
                  window.ReactNativeWebView.postMessage('surveyCompleted')
                }
                launchSurveyClosed(participationId)
                if(this.props.public) {
                  getKeycloakClient().logout();
                }
              }}
              onPartialSend={(survey) => {
                survey.data = {
                  ...survey.data,
                  locale: this.state.selectedOption.value
                }
                console.log(JSON.stringify(survey.data, null, 2))
                saveSurveyAnswersRequested(
                  Number(participationId),
                  { ...survey.data, currentPage: survey.currentPageNo },
                  false
                )
              }}
              onUpdateQuestionCssClasses={(survey, options) => {
                const { cssClasses, question } = options
                cssClasses.root = 'sq-root'
                console.log(question.getType())
                if (
                  question.getType() === 'radiogroup' ||
                  question.getType() === 'checkbox'
                ) {
                  cssClasses.controlLabel = 'ddcontrolLabel'
                }
                if (
                  question.getType() === 'matrixdropdown'
                ) {
                  cssClasses.root = `${cssClasses.root} docdok-matrixdropdown`
                }
                if (
                  question.getType() === 'imagepicker'
                ) {
                  cssClasses.root = `${cssClasses.root} docdok-imagepicker`
                }

                if (
                  question.getType() === 'image'
                ) {
                  cssClasses.root = `${cssClasses.root} docdok-image-que`
                }
              }}
            />
          </Col>
        </Row>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            <Translate value="surveys.actions.changeLanguage.title"/>
          </ModalHeader>
          <ModalBody>
            <Translate value="surveys.actions.changeLanguage.body"/>
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={this.handleUpdateSurvey}>
              Ok
            </Button>{' '}
            <Button color="success" onClick={this.toggle}>
              Close
            </Button>{' '}
          </ModalFooter>
        </Modal>
        <Modal
          centered
          id="more-info-modal"
          isOpen={this.state.selectedMoreInfo.moreInfo === 'true'}
          toggle={() => this.setState({ selectedMoreInfo: {} })}
        >
          <ModalHeader toggle={() => this.setState({ selectedMoreInfo: {} })}>
            <InfoRounded color='primary' />
            {this.state.selectedMoreInfo.popupTitle}
          </ModalHeader>
          <ModalBody>
            <p className='more-info-body'>
              {this.state.selectedMoreInfo.popupDescription}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              outline
              onClick={() => this.setState({ selectedMoreInfo: {} })}
            >
              {this.state.selectedMoreInfo.popupCloseButton}
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    )
  }

  playWithData = (survey) => {
    const { data } = survey
    survey.data = { ...data, question1: 'Eastern' }
  }
}

const mapStateToProps = (state: StoreType, ownProps: Object) => ({
  spec:
    state.survey.surveySpecsByParticipation[ownProps.params.participationId],
  participation:
    state.survey.surveyParticipations[ownProps.params.participationId]
})

export default connect(mapStateToProps, {
  ...surveyActions,
  launchSurveyClosed: launchSurveyActions.launchSurveyClosed
})(SurveyParticipation)
