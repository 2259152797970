// @flow
import type { ActionType } from 'common-docdok/src/types'
import { selectedPatientActionTypes } from '../actions'

// State Definition

export type SelectedPatientStateType = ?string

// Default State
const initialState: SelectedPatientStateType = null

// Action Handlers

function selectPatient(
  state: SelectedPatientStateType,
  action: ActionType
): SelectedPatientStateType {
  return action.payload
}

const ACTION_HANDLERS = {
  [selectedPatientActionTypes.SELECT_PATIENT]: selectPatient
}

export default function selectPatientReducer(
  state: SelectedPatientStateType = initialState,
  action: ActionType
): SelectedPatientStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
