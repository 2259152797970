// @flow
import { connect } from 'react-redux'

import InfoPanel from '../components/infoPanel'

const mapStateToProps = (state: StoreType) => ({
  ...state.infoPanel
})

export default connect(mapStateToProps)(InfoPanel)
