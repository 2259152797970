// @flow
import React from 'react'
import ReactTable from 'react-table'
import ReactDOM from 'react-dom'
import Done from '@material-ui/icons/Done'
import { Translate } from 'react-redux-i18n'

import DateRenderer from '../../DateRenderer/components/dateRenderer'
import './table.scss'
import './style.scss'
import type { TableActionType } from './TableRow'
import TableRow from './TableRow'
import Pagination from './Pagination'
import TableCell from './TableCell'
import { entityTableActions } from '../actions'
import { TextLine } from '../../Static'
import { BorderlessCheckbox } from '../../Mui'
import palette from '../../../styles/palette'

export type RenderFuncType = (val: any, col: string, entity: string, row: Object, colDef: Object, index: number) => any

const CssMobileClass = '-mobile'

type ColoumnsType = {
    [key: string]: {
        type?: string,
        renderer?: RenderFuncType,
        width?: number,
        sortMethod?: Function,
        sortable?: boolean,
        visible?: boolean
    }
}

export const renderers = {
  boolean(val: any, fieldName: string) {
    const result = val ? <Done /> : ''
    return <span className={`${fieldName}-cell`}>{result}</span>
  },
  string(val: any, fieldName: string) {
    let result = ''
    if (val !== undefined && val !== null) {
      result = val
    }
    return <span className={`${fieldName}-cell`}>{result}</span>
  },
  percentage(val: any, fieldName: string) {
    let result = ''
    if (val !== undefined && val !== null) {
      result = `${val.toFixed(2)}%`
    }
    return (
      <div style={{ width: '100%' }}>
        <div style={{ float: 'right' }} className={`${fieldName}-cell`}>
          {result}
        </div>
      </div>
    )
  },
  autoComplete(val: any, fieldName: string) {
    return (
      <span className={`${fieldName}-cell`}>
        {' '}
        {val}
      </span>
    )
  },
  number(val: any, fieldName: string) {
    return (
      <div style={{ width: '100%' }}>
        <div style={{ float: 'right' }} className={`${fieldName}-cell`}>
          {val}
        </div>
      </div>
    )
  },
  enum(val: any, fieldName: string, entity: string) {
    let result = <span className={`${fieldName}-cell`} />
    if (val) {
      result = (
        <Translate
          className={`${fieldName}-cell`}
          value={`domain.entity.${entity}.fields.${fieldName}Opts.${val}`}
        />
      )
    }
    return result
  },
  Date(val: any, fieldName: string) {
    return <DateRenderer className={`${fieldName}-cell`} value={val} />
  },
  Time(val: any, fieldName: string) {
    return <DateRenderer className={`${fieldName}-cell`} value={val} dateFormat='date.withTime' />
  },
  'Array<number>': (val: any, fieldName: string) => <div className={`${fieldName}-cell`}>{val.join(', ')}</div>,
  'Array<string>': (val: any, fieldName: string) => <div className={`${fieldName}-cell`}>{val.join(', ')}</div>
}

function getRender(col: string, def: Object): RenderFuncType {
  let renderFunc

  if (def.renderer) {
    renderFunc = def.renderer
  } else if (def.enum) {
    renderFunc = renderers.enum
  } else {
    renderFunc = renderers[def.type]
  }
  if (!renderFunc) {
    throw new Error(`no render found for ${col}`)
  }

  return renderFunc
}

function renderCell(definitions: ColoumnsType, col: string, row: Object, entity: string, index: number) {
  const fieldDef = definitions[col]
  const val = row[col]

  let result
  if (fieldDef.renderer) {
    result = fieldDef.renderer(val, col, entity, row, fieldDef, index)
  } else {
    result = <TextLine>{getRender(col, fieldDef)(val, col, entity, row, fieldDef, index)}</TextLine>
  }
  return result
}

export type EntityTableDefaultPropsType = {
    onSelectionChanged: (Array<Object>) => void,
    selection: Array<Object>,
    hidePagnitation: boolean,
    multiSelectable: boolean,
    pageSize: number,
    unremovableIds: Array<string>,
    id: string,
    selectable?: boolean
}

type OptionalPropsType = {
    pageIndex?: number,
    hideRowCheckbox?: boolean,
    height?: number,
    actions?: Array<TableActionType>,
    onRowClick?: (row: any) => void,
    idField?: string,
    onPageChange?: (page: number) => void,
    onPageSizeChange?: (pageSize: number, pageIndex: number) => void,
    onOrderByChange?: (orderBy: ?string) => void,
    hasPagingSupport?: boolean,
    orderBy?: string,
    totalPages?: number,
    defaultPageSize?: number,
    loading?: boolean,
    total?: number,
    searchTerm?: string,
    defaultSort?: string,
    storedPageSize?: number,
    setPageSize?: typeof entityTableActions.setPageSize,
    showPageSizeOptions?: boolean,
    filterPredicate?: any => boolean,
    comparator?: any => boolean,
    selectedEntityId?: number,
    inputData?: Array<Object>,
    defaultSortArray?: any
}

export type EntityTablePropsType = {
    columns: ColoumnsType,
    entity: string,
    rows: Array<Object>,
    fullScreen: boolean
} & OptionalPropsType &
    EntityTableDefaultPropsType

type StateType = {
    selectedEntityId: number
}

export default class EntityTable extends React.Component<EntityTablePropsType, StateType> {
    /*
     this is needed because react-table works with object references.
     if the object is generated new every render cycle the sorting resets
    */
    defaultSort = undefined

    constructor(props: EntityTablePropsType) {
      super(props)
      const { defaultSort, defaultSortArray } = props
      if (defaultSortArray) {
        this.defaultSort = defaultSortArray
      } else {
        this.defaultSort = defaultSort && [
          {
            id: defaultSort,
            desc: false
          }
        ]
      }
      this.state = { selectedEntityId: -1 }
    }

    static defaultProps = {
      onSelectionChanged: () => {
      },
      selection: [],
      unremovableIds: [],
      hidePagnitation: false,
      multiSelectable: true,
      pageSize: 10,
      id: 'noIdProvided'
    }

    componentWillReceiveProps(newProps: EntityTablePropsType) {
      if (newProps.selectedEntityId !== undefined) {
        this.setState({ selectedEntityId: newProps.selectedEntityId })
      }
    }

    getIdField() {
      let { idField } = this.props
      if (idField) {
        return idField
      }

      const rows = this.props.rows ? this.props.rows : []
      const { entity } = this.props
      const typicalIds = ['id', 'uid', 'uuid', `${entity}Id`]

      if (!idField && rows.length > 0) {
        const row = rows[0]
        typicalIds.forEach((id) => {
          if (row[id]) {
            idField = id
          }
        })
        if (idField) {
          return idField
        }
      } else {
        return 'unkown'
      }

      throw Error(
        `no id (${typicalIds.join(',')}) found for current rows. provide an identifier via the idField property`
      )
    }

    setHeight(ref: any) {
      const { height } = this.props
      if (ref && height) {
        const node: any = ReactDOM.findDOMNode(ref)
        if (node) {
          const bodies = node.getElementsByClassName('rt-tbody')
          if (bodies) {
            const tableBody = bodies[0]

            tableBody.style.height = `${height}px`
          }
        }
      }
    }

    getTbodyProps = (state: any) => {
      const classNames = [CssMobileClass]
      if (state.data.length === 0) {
        classNames.push('empty')
      }
      return { className: [...classNames] }
    }

    getTrProps = (state: any, rowInfo: any) => {
      const { actions, onRowClick, fullScreen } = this.props
      const { selectedEntityId } = this.state
      const row = rowInfo && rowInfo.row
      const classNames = []
      if (fullScreen) {
        classNames.push(CssMobileClass)
        if (!row) classNames.push('-hidden')
      }
      if (rowInfo !== undefined && rowInfo && rowInfo.row) {
        const background = (rowInfo.row.id !== undefined && rowInfo.row.id === selectedEntityId)
          ? palette.blueVeryLight : 'white'

        return {
          row,
          actions,
          onRowClick,
          background,
          className: [...classNames]
        }
      }
      return { row, actions, onRowClick, className: [...classNames] }
    }

    getTdProps = (state: any, rowInfo: any, column: any) => {
      const row = rowInfo && rowInfo.row
      const displayRowCheckbox = this.displayRowCheckbox()
      return { row, column, className: [CssMobileClass], displayRowCheckbox }
    }

    displayRowCheckbox = () => {
      const { hideRowCheckbox } = this.props
      return hideRowCheckbox === undefined ? true : !hideRowCheckbox
    }

    render() {
      const {
        entity,
        columns,
        selection,
        rows,
        hidePagnitation,
        onSelectionChanged,
        unremovableIds,
        loading,
        hasPagingSupport,
        multiSelectable,
        total,
        totalPages,
        pageIndex,
        onPageChange,
        onPageSizeChange,
        onOrderByChange,
        id,
        setPageSize,
        showPageSizeOptions,
        fullScreen,
        inputData,
        selectable = true
      } = this.props

      const showPagination = !hidePagnitation
      let { pageSize } = this.props
      const totalRows = isNaN(total) ? rows.length : total
      if (hidePagnitation) {
        pageSize = 10
      }

      const tableCols = Object.keys(columns).filter(k => columns[k].visible !== false)
      let checkColumn = []

      if (this.displayRowCheckbox()) {
        const idField = this.getIdField()
        const selectionIds = selection ? selection.map(s => s[idField]) : []
        let allRowsChecked = rows.length > 0
        rows
          .map(s => s[idField])
          .forEach((rowId) => {
            if (!selectionIds.includes(rowId)) {
              allRowsChecked = false
            }
          })

        checkColumn = selectable ? [
          {
            width: 40,
            header: (
              <BorderlessCheckbox
                id={`${id}-select-all`}
                disabled={!multiSelectable}
                checked={allRowsChecked}
                onChange={(ev) => {
                  const { checked } = ev.target
                  if (checked && multiSelectable) {
                    onSelectionChanged(rows)
                  } else if (multiSelectable && unremovableIds.length > 0) {
                    const unremoveableIds = unremovableIds
                    const minimumSelection = rows.filter(row => unremoveableIds.includes(row[idField]))
                    onSelectionChanged(minimumSelection)
                  } else {
                    onSelectionChanged([])
                  }
                }}
              />
            ),
            id: 'select',
            sortable: false,
            accessor: row => (
              <BorderlessCheckbox
                className='select-checkbox'
                data-test={`select-${rows.indexOf(row)}`}
                key={`select-${row[idField]}`}
                checked={selectionIds.includes(row[idField])}
                disabled={unremovableIds.includes(row[idField])}
                onChange={(ev) => {
                  const { checked } = ev.target
                  let currentSelection = selection.filter(s => s[idField] !== row[idField])
                  if (checked && (multiSelectable || currentSelection.length === 0)) {
                    currentSelection.push(row)
                  } else if (checked && !multiSelectable) {
                    currentSelection = [row]
                  }
                  onSelectionChanged(currentSelection)
                }}
              />
            )
          }
        ] : []
      }

      const tableColumns = [
        ...checkColumn,
        ...tableCols.map(col => ({
          sortMethod: columns[col].sortMethod,
          sortable: columns[col].sortable !== undefined ? columns[col].sortable : true,
          header: <Translate value={`domain.entity.${entity}.fields.${col}`} />,
          accessor: row => renderCell(columns, col, row, entity, rows.indexOf(row)),
          id: col,
          width: columns[col].width,
          getHeaderProps: () => ({ id: `${col}-col` })
        }))
      ]

      let additionalProps = {}
      if (onPageChange && onPageSizeChange && onOrderByChange) {
        additionalProps = {
          manual: hasPagingSupport,
          page: pageIndex,
          pageSize,
          pages: totalPages,
          loading,
          onPageChange(pi: number) {
            onPageChange(pi)
            window.scrollTo(0, 0)
          },
          onPageSizeChange(ps: number, pi: number) {
            onPageSizeChange(ps, pi)
            if (setPageSize) setPageSize(id, ps)
          },
          onChange: (state) => {
            // triggered when sorting
            const sortings = state.sorting.map((sorting) => {
              const fieldName = sorting.id
              const clientSideSort =
                columns[fieldName] && columns[fieldName].clientSideSort
                  ? columns[fieldName].clientSideSort
                  : false
              if (clientSideSort) {
                return ''
              }

              const orderByAttribute =
                columns[fieldName] && columns[fieldName].orderBy
                  ? columns[fieldName].orderBy
                  : fieldName
              return `sort=${orderByAttribute},${sorting.desc ? 'desc' : 'asc'}`
            })

            const orderBy = sortings.join('&')
            if (onOrderByChange) {
              onOrderByChange(orderBy)
            }
          }
        }
      }

      let mobileProps = {}
      if (fullScreen) {
        mobileProps = {
          ThComponent: () => null,
          TdComponent: TableCell,
          getTbodyProps: this.getTbodyProps,
          getTdProps: this.getTdProps
        }
      }

      let rowData = rows
      if (rowData.length === 0 && inputData !== undefined) {
        rowData = inputData
      }

      let filteredRows = rowData
      if (this.props.filterPredicate) {
        filteredRows = rows.filter(this.props.filterPredicate)
      }

      let filteredAndSortedRows = filteredRows
      if (this.props.comparator) {
        filteredAndSortedRows = filteredRows.sort(this.props.comparator)
      }

      return (
        <div id={id}>
          <ReactTable
            showPagination={showPagination}
            defaultPageSize={pageSize}
            ref={(ref) => {
              this.setHeight(ref)
            }}
            {...additionalProps}
            defaultSorting={this.defaultSort}
            className='-striped -highlight'
            data={filteredAndSortedRows}
            columns={tableColumns}
            showPageSizeOptions={showPageSizeOptions}
            previousText={<Translate value='table.previous' />}
            nextText={<Translate value='table.next' />}
            loadingText={<Translate value='table.loading' />}
            noDataText={<Translate value='table.noData' />}
            pageText={<Translate value='table.page' />}
            ofText={<Translate value='table.of' />}
            rowsText={<Translate value='table.rows' />}
            TrComponent={TableRow}
            getTrGroupProps={(state, rowInfo) => ({
              'data-test': `entityTableRow-${rowInfo && rowInfo.index}`
            })}
            getPaginationProps={() => ({
              total: totalRows
            })}
            getTrProps={this.getTrProps}
            PaginationComponent={Pagination}
            {...mobileProps}
          />
          <div style={{ clear: 'both' }} />
        </div>
      )
    }
}
