// @flow
import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Switch from '@material-ui/core/Switch'
import { Translate } from 'react-redux-i18n'

import type { FieldComponentPropsType } from './formField'

export default function CheckboxField(props: FieldComponentPropsType) {
  const {
    style,
    input,
    meta: { form },
    propertyDefinition,
    disabled
  } = props

  const { name, onChange, value } = input

  const checked = !!value
  const label = `forms.${form}.${name}`
  const id = `${form}-${name}`
  let controll
  if (propertyDefinition.style === 'toggle') {
    controll = (
      <Switch
        style={style}
        id={id}
        inputProps={{
          'data-test': `${form}-${name}`
        }}
        checked={checked}
        onChange={event => onChange(event.target.checked)}
        disabled={disabled}
      />
    )
  } else {
    controll = (
      <Checkbox
        style={style}
        id={id}
        inputProps={{
          'data-test': `${form}-${name}`
        }}
        checked={checked}
        onChange={event => onChange(event.target.checked)}
        disabled={disabled}
      />
    )
  }
  return <FormControlLabel control={controll} label={<Translate value={label} />} />
}
