// @flow
import React from 'react'
import { connect } from 'react-redux'
import compose from 'ramda/src/compose'
import { entityLoaderActions } from 'common-docdok/src/common/EntityLoader/actions'
import isEqual from 'lodash/isEqual'

type ContainerPropsType = {
  id: string,
  api: Function,
  refreshActions?: Array<string>,
  searchTerm?: string,
  defaultPageSize?: number,
  defaultSort?: string,
  params?: Array<*>
}

const mapStateToProps = (state: StoreType, ownProps: ContainerPropsType) => {
  const loader = state.entityLoader[ownProps.id]

  return {
    loading: loader ? loader.loading : false,
    total: loader ? loader.total : undefined,
    rows: loader ? loader.result : []
  }
}

type ConnectedPropType = {
  getEntitiesRequested: typeof entityLoaderActions.getEntitiesRequested,
  loading: boolean,
  total: number,
  rows: Array<Object>
}

type HocPropsType = ConnectedPropType & ContainerPropsType

type StateType = {
  pageSize: number,
  pageIndex: number,
  orderBy: ?string
}

export type EntitySearcherPropsType = {
  onPageChange: (page: number) => void,
  onPageSizeChange: (pageSize: number, pageIndex: number) => void,
  onOrderByChange: (orderBy: string) => void,
  pageSize: number,
  pageIndex: number,
  orderBy: ?string,
  totalPages: number,
  defaultPageSize: number,
  hasPagingSupport: boolean
} & HocPropsType

function entityLoader(
  WrappedComponent: React$ComponentType<EntitySearcherPropsType>
): React$ComponentType<HocPropsType> {
  return class HOC extends React.Component<HocPropsType, StateType> {
    static defaultProps = {
      defaultPageSize: 10,
      params: []
    }

    componentWillMount() {
      this.setState({
        pageSize: this.props.defaultPageSize,
        pageIndex: 0,
        orderBy: undefined
      })
    }

    componentDidMount() {
      const { getEntitiesRequested, id, api, refreshActions, searchTerm, defaultSort, params } = this.props
      const { pageSize, pageIndex, orderBy } = this.state
      if (!defaultSort) {
        getEntitiesRequested(id, api, pageSize, pageIndex, searchTerm, orderBy, refreshActions, params)
      }
    }

    componentWillReceiveProps(nextProps: HocPropsType) {
      if (
        this.props.searchTerm !== nextProps.searchTerm
        || this.props.id !== nextProps.id
        || !isEqual(this.props.params, nextProps.params)
      ) {
        const { getEntitiesRequested, id, api, refreshActions, searchTerm, params } = nextProps
        const { pageSize, pageIndex, orderBy } = this.state
        getEntitiesRequested(id, api, pageSize, pageIndex, searchTerm, orderBy, refreshActions, params)
      }
    }

    onPageChange(pageIndex: number) {
      const { getEntitiesRequested, id, refreshActions, searchTerm, api, params } = this.props
      const { pageSize, orderBy } = this.state
      getEntitiesRequested(id, api, pageSize, pageIndex, searchTerm, orderBy, refreshActions, params)
      this.setState({ pageIndex })
    }

    onPageSizeChange(pageSize: number, pageIndex: number) {
      const { getEntitiesRequested, id, refreshActions, searchTerm, api, params } = this.props
      const { orderBy } = this.state
      getEntitiesRequested(id, api, pageSize, pageIndex, searchTerm, orderBy, refreshActions, params)

      this.setState({ pageSize, pageIndex })
    }

    onOrderByChange(orderBy: string) {
      const { getEntitiesRequested, id, refreshActions, searchTerm, api, params } = this.props
      const { pageSize, pageIndex } = this.state
      getEntitiesRequested(id, api, pageSize, pageIndex, searchTerm, orderBy, refreshActions, params)
      this.setState({ orderBy })
    }

    render() {
      const { pageSize, pageIndex, orderBy } = this.state
      const { id, searchTerm, rows, total, defaultSort } = this.props

      const hasPagingSupport = !isNaN(total)
      const totalRows = hasPagingSupport ? Number(total) : rows.length
      const totalPages = Math.ceil(totalRows / this.state.pageSize)

      return (
        <WrappedComponent
          {...this.props}
          id={id}
          searchTerm={searchTerm}
          onPageChange={(i: number) => this.onPageChange(i)}
          onPageSizeChange={(s: number, i: number) => this.onPageSizeChange(s, i)}
          onOrderByChange={(o: string) => this.onOrderByChange(o)}
          pageSize={pageSize}
          pageIndex={pageIndex}
          orderBy={orderBy}
          totalPages={totalPages}
          hasPagingSupport={hasPagingSupport}
          defaultSort={defaultSort}
        />
      )
    }
  }
}

export default compose(
  connect(
    mapStateToProps,
    {
      ...entityLoaderActions
    }
  ),
  entityLoader
)
