// @flow

import { takeEvery, put, select } from 'redux-saga/effects'
import type { ExtractReturn, GenType } from 'common-docdok/src/types'
import primaryConversationSelector from 'common-docdok/src/domain/messaging/selectors/primaryConSelector'
import { messagingActions } from 'common-docdok/src/domain/messaging/actions'
import { healthrelationActions } from 'common-docdok/src/domain/healthrelation/actions'
import { asyncAction } from 'common-docdok/src/utils/sagaUtils'
import { selectedPatientActions, selectedPatientActionTypes } from '../actions'


export function* selectPatient(action: ExtractReturn<typeof selectedPatientActions.selectPatient>): GenType {
  const patientId = action.payload
  if (patientId) {
    const winner = yield asyncAction(healthrelationActions.loadPatientRequested(patientId))

    if (winner.succeeded) {
      const conversationId = yield select((state: StoreType) => primaryConversationSelector(state, patientId))
      yield put(messagingActions.selectConversation(Number(conversationId)))
    }
  }
}

export default function patientsSaga(): Array<GenType> {
  return [takeEvery(selectedPatientActionTypes.SELECT_PATIENT, selectPatient)]
}
