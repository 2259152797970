// @flow

import type { SubscriptionDtoType } from '../types/subscriptionDto'
import type { MessageDtoType } from '../types/messageDto'

import { messagingActionTypes } from '../actions'
import type { MessagingActionType } from '../actions'

import {
  createConversationSucceeded,
  getSubscriptions,
  removeSubscription,
  setLastReadMessageOnSub,
  setSubscriptions,
  setSubscriptionsForConversations,
  toggleMute,
  updateCaseSucceeded
} from './subscriptions'
import {
  loadMessagesSuccess,
  markAsCheckedLocal,
  postMessageSucceeded,
  selectCaseStream,
  selectConversation
} from './message'
import {
  addFileRequested,
  addFileSucceeded,
  createContentMessagePreview,
  getContentDefinitionTags,
  markAsCached,
  removeFile,
  resetAttachments,
  setFileDescription
} from './attachments'
import { clearMediaResourceCache, getMediaResource } from './resources'
import type { ConversationDtoType } from '../types/conversationDto'
import type { CaseStreamDtoType } from '../../healthrelation/types/caseStreamDto'

// State Definition
export type ConversationStateType = {
  messages: Array<MessageDtoType>,
  latestMessage: MessageDtoType,
  subscriptions: Array<SubscriptionDtoType>,
  subscriptionId: number,
  meta: Object,
  id: number,
  lastReadMessage: number
}

export type FileToSendType = {
  order: number,
  id: string,
  name: string,
  mediaResourceId: string,
  uploaded: boolean,
  mimeType: string,
  arrayBuffer: ArrayBuffer,
  preview: string,
  url: ?string,
  description: string,
  originalSize: number
}

export type ConversationType = ConversationStateType & ConversationDtoType

export type LoadedResourcesType = {
  [uuid: string]: {
    mimeType: string,
    previewDataUrl: string,
    originalDataUrl?: string
  }
}

export type MessagingStateType = {
  conversations: {
    [conversationId: number]: ConversationType
  },
  checkedMessages: { [key: number]: number },
  cachedAttachments: { [key: string]: Date },
  selectedConversation: ?number,
  selectedCaseStream: ?CaseStreamDtoType,
  attachments: {
    [conversationId: number]: Array<FileToSendType>
  },
  loadedResources: LoadedResourcesType,
  contentPreview: { [key: number]: MessageDtoType },
  contentDefinitionTags: Array<string>
}

export const defaultState = {
  checkedMessages: {},
  conversations: {},
  selectedConversation: undefined,
  selectedCaseStream: undefined,
  attachments: {},
  cachedAttachments: {},
  loadedResources: {},
  contentPreview: {},
  contentDefinitionTags: []
}

export const ACTION_HANDLERS: { [key: MessagingActionType]: any } = {
  [messagingActionTypes.MARK_AS_CHECKED_LOCAL]: markAsCheckedLocal,
  [messagingActionTypes.REMOVE_FILE]: removeFile,
  [messagingActionTypes.RESET_ATTACHMENTS]: resetAttachments,
  [messagingActionTypes.SET_FILE_DESCRIPTION]: setFileDescription,
  [messagingActionTypes.MARK_AS_CACHED]: markAsCached,
  [messagingActionTypes.LOAD_MESSAGES_SUCCEEDED]: loadMessagesSuccess,
  [messagingActionTypes.LOAD_OLDER_MESSAGES_SUCCEEDED]: loadMessagesSuccess,
  [messagingActionTypes.SELECT_CONVERSATION]: selectConversation,
  [messagingActionTypes.SELECT_CASE_STREAM]: selectCaseStream,
  [messagingActionTypes.TOGGLE_MUTE_SUCCEEDED]: toggleMute,
  [messagingActionTypes.GET_SUBS_BY_CONV_SUCCEEDED]: setSubscriptionsForConversations,
  [messagingActionTypes.POST_MESSAGE_REQUESTED]: postMessageSucceeded,
  [messagingActionTypes.POST_MESSAGE_SUCCEEDED]: postMessageSucceeded,
  [messagingActionTypes.POST_MESSAGE_FAILED]: postMessageSucceeded,
  [messagingActionTypes.GET_SUBSCRIPTIONS_REQUESTED]: getSubscriptions,
  [messagingActionTypes.GET_SUBSCRIPTIONS_SUCCEEDED]: setSubscriptions,
  [messagingActionTypes.ADD_FILE_REQUESTED]: addFileRequested,
  [messagingActionTypes.ADD_FILE_SUCCEEDED]: addFileSucceeded,
  [messagingActionTypes.REMOVE_SUBSCRIPTION_SUCCEEDED]: removeSubscription,
  [messagingActionTypes.CREATE_CONVERSATION_SUCCEEDED]: createConversationSucceeded,
  [messagingActionTypes.GET_MEDIA_RESOURCE_SUCCEEDED]: getMediaResource,
  [messagingActionTypes.CREATE_CONTENT_MESSAGE_PREVIEW_SUCCEEDED]: createContentMessagePreview,
  [messagingActionTypes.GET_CONTENT_DEFINITION_TAGS_SUCCEEDED]: getContentDefinitionTags,
  [messagingActionTypes.SET_LAST_READ_MESSAGE_ON_SUB]: setLastReadMessageOnSub,
  [messagingActionTypes.UPDATE_CASE_SUCCEEDED]: updateCaseSucceeded,
  [messagingActionTypes.CLEAR_MEDIA_RESOURCE]: clearMediaResourceCache

}
type ActionType = { type: MessagingActionType }
export default function messagingReducer(
  state: MessagingStateType = defaultState,
  action: ActionType
): MessagingStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
