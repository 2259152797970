// @flow
import { put, call, takeEvery } from 'redux-saga/effects'
import type { ExtractReturn, GenType } from '../../../types'

import api from '../../../api/'

import { messagingActions, messagingActionTypes } from '../actions'

// -- APPEND GENERATORS HERE --
function* getContentDefinitionTags(): GenType {
  try {
    const result = yield call(api.getContentDefinitionTags)
    yield put(messagingActions.getContentDefinitionTagsSucceeded(result.data))
  } catch (error) {
    yield put(messagingActions.getContentDefinitionTagsFailed(error))
  }
}

function* createContentMessagePreview(
  action: ExtractReturn<typeof messagingActions.createContentMessagePreviewRequested>
): GenType {
  try {
    const definitionId = action.payload
    const result = yield call(api.createContentMessagePreview, definitionId)

    if (result && result.data && result.data.mediaResource) {
      yield put(
        messagingActions.clearMediaResource(result.data.mediaResource.uuid)
      )
    }
    yield put(
      messagingActions.createContentMessagePreviewSucceeded(
        definitionId,
        result.data
      )
    )
  } catch (error) {
    yield put(messagingActions.createContentMessagePreviewFailed(error))
  }
}

function* setContentDefinitionAccessRights(
  action: ExtractReturn<typeof messagingActions.setContentDefinitionAccessRightsRequested>
): GenType {
  try {
    const { definitionId, accessRights } = action.payload
    const result = yield call(
      api.setContentDefinitionAccessRights,
      definitionId,
      accessRights
    )
    yield put(
      messagingActions.setContentDefinitionAccessRightsSucceeded(result.data)
    )
  } catch (error) {
    yield put(messagingActions.setContentDefinitionAccessRightsFailed(error))
  }
}

function* sendContentMessages(
  action: ExtractReturn<typeof messagingActions.sendContentMessagesRequested>
): GenType {
  try {
    const { contentDefinitionIds, conversationIds } = action.payload
    const result = yield call(
      api.sendContentMessages,
      contentDefinitionIds,
      conversationIds
    )
    yield put(messagingActions.sendContentMessagesSucceeded(result.data))
  } catch (error) {
    yield put(messagingActions.sendContentMessagesFailed(error))
  }
}

function* updateContentDefinition(
  action: ExtractReturn<typeof messagingActions.updateContentDefinitionRequested>
): GenType {
  try {
    const result = yield call(api.updateContentDefinition, action.payload)
    yield put(messagingActions.updateContentDefinitionSucceeded(result.data))
  } catch (error) {
    yield put(messagingActions.updateContentDefinitionFailed(error))
  }
}

function* uploadContentDefinitionFile(
  action: ExtractReturn<typeof messagingActions.uploadContentDefinitionFileRequested>
): GenType {
  try {
    if (action.payload.contentDefinitionId) {
      const result = yield call(api.updateCatalog, action.payload)
      yield put(
        messagingActions.updateContentDefinitionFileSucceeded(result.data)
      )
    } else {
      const result = yield call(api.uploadCatalog, action.payload)
      yield put(
        messagingActions.uploadContentDefinitionFileSucceeded(result.data)
      )
    }
  } catch (error) {
    yield put(messagingActions.uploadContentDefinitionFileFailed(error))
  }
}

function* uploadContentDefinitionGetFileURL(action: any): GenType {
  try {
    const {
      contentDefinitionId,
      file: { mimeType, arrayBuffer }
    } = action.payload
    const result = yield call(api.uploadCatalogGetFileURL, contentDefinitionId)
    if (result.data.url) {
      const response = yield call(api.uploadFile, result.data.url, mimeType, arrayBuffer)
      yield put(
        messagingActions.uploadContentDefinitionGetFileURLSucceeded(response.data)
      )
    }
  } catch (error) {
    yield put(messagingActions.uploadContentDefinitionGetFileURLFailed(error))
  }
}

function* deleteContentDefinition(action: any): GenType {
  try {
    const { contentDefinitionId } = action.payload
    const result = yield call(api.deleteContentDefinition, contentDefinitionId)
    yield put(messagingActions.deleteContentDefinitionSucceeded(result.data))
  } catch (error) {
    yield put(messagingActions.deleteContentDefinitionFailed(error))
  }
}

export default function clinicSaga(): Array<GenType> {
  return [
    // -- APPEND TAKES HERE --
    takeEvery(
      messagingActionTypes.GET_CONTENT_DEFINITION_TAGS_REQUESTED,
      getContentDefinitionTags
    ),
    takeEvery(
      messagingActionTypes.CREATE_CONTENT_MESSAGE_PREVIEW_REQUESTED,
      createContentMessagePreview
    ),
    takeEvery(
      messagingActionTypes.SET_CONTENT_DEFINITION_ACCESS_RIGHTS_REQUESTED,
      setContentDefinitionAccessRights
    ),
    takeEvery(
      messagingActionTypes.SEND_CONTENT_MESSAGES_REQUESTED,
      sendContentMessages
    ),
    takeEvery(
      messagingActionTypes.UPDATE_CONTENT_DEFINITION_REQUESTED,
      updateContentDefinition
    ),
    takeEvery(
      messagingActionTypes.UPLOAD_CONTENT_DEFINITION_FILES_REQUESTED,
      uploadContentDefinitionFile
    ),
    takeEvery(
      messagingActionTypes.UPLOAD_CONTENT_DEFINITION_GET_FILE_URL_REQUESTED,
      uploadContentDefinitionGetFileURL
    ),
    takeEvery(
      messagingActionTypes.DELETE_CONTENT_DEFINITION_REQUESTED,
      deleteContentDefinition
    )
  ]
}
