// @flow
import React from 'react'
import { connect } from 'react-redux'
import compose from 'ramda/src/compose'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import { fields } from 'common-docdok/src/domain/healthrelation/types/ambulanceQueueItemDto'

import Tooltip from '@material-ui/core/Tooltip'
import { Translate } from 'react-redux-i18n'

import type { AmbulanceQueueItemDtoType } from 'common-docdok/src/domain/healthrelation/types/ambulanceQueueItemDto'
import { getKeycloakClient } from 'common-docdok/src/common/Keycloak/keycloakProvider'
import { DialogHeader } from '../../Mui'
import EntityTable from '../../EntityTable/components/EntityTable'
import { ambulanceQueueItemsActions } from '../actions'
import { UserName } from '../../UserName'
import { Buttons, Link } from '../../Static'

type PropsType = {
  showAmbulanceItemsDialog: boolean,
  toggleShowAmbulanceItemsDialog: typeof ambulanceQueueItemsActions.toggleShowAmbulanceItemsDialog,
  setItemIntoProgressRequested: typeof ambulanceQueueItemsActions.setItemIntoProgressRequested,
  setItemToFinishedRequested: typeof ambulanceQueueItemsActions.setItemToFinishedRequested,
  setItemToErrorRequested: typeof ambulanceQueueItemsActions.setItemToErrorRequested,
  navigateToConversationOfItem: ambulanceQueueItemsActions.navigateToConversationOfItem,
  queueItems: Array<AmbulanceQueueItemDtoType>
}

class CreateAmbulanceItemsDialog extends React.Component<PropsType> {
  static defaultProps = {
    useIconButton: false,
    casedata: ['']
  }

  assignToMe = (row) => {
    const { setItemIntoProgressRequested } = this.props
    const alreadyAssignedToMe = this.findAssignedItem()
    if (!alreadyAssignedToMe) {
      setItemIntoProgressRequested(row.userRef)
      this.navigateToConversation(row)
    }
  }

  navigateToConversation = (row, invite = true) => {
    const { toggleShowAmbulanceItemsDialog, navigateToConversationOfItem } = this.props
    toggleShowAmbulanceItemsDialog()
    navigateToConversationOfItem(row, invite)
  }


  finishItem = (row) => {
    const mySelf = getKeycloakClient().getUserId()
    const { setItemToFinishedRequested } = this.props
    if (mySelf === row.userRefPhysician) {
      setItemToFinishedRequested(row.userRef)
    }
  }

  finishItemError = (row) => {
    const mySelf = getKeycloakClient().getUserId()
    const { setItemToErrorRequested } = this.props
    if (mySelf === row.userRefPhysician) {
      setItemToErrorRequested(row.userRef)
    }
  }

  findAssignedItem() {
    const mySelf = getKeycloakClient().getUserId()
    const { queueItems } = this.props
    const alreadyAssignedToMe = queueItems.find(row => mySelf === row.userRefPhysician)
    return alreadyAssignedToMe
  }

  render() {
    const { toggleShowAmbulanceItemsDialog, showAmbulanceItemsDialog, queueItems } = this.props
    const alreadyAssignedToMe = this.findAssignedItem()
    const mySelf = getKeycloakClient().getUserId()

    const cols = {
      state: {
        width: 150,
        ...fields.state,
        renderer: (val: any) => (
          <Translate value={`ambulance.dialog.states.${val}`} />
        )
      },
      userRef: {
        width: 350,
        renderer: (val: any, col: string, entity: string, row: Object) => {
          let res = <UserName userRef={val} />
          if (mySelf === row.userRefPhysician) {
            res = (
              <Link onClick={() => this.navigateToConversation(row, false)}>
                <UserName style={{ cursor: 'pointer' }} userRef={val} />
              </Link>
            )
          }
          return res
        }
      },
      id: { dth: 450,
        renderer: (val: any, col: string, entity: string, row: Object) => {
          if (row.state === 'WA' && !alreadyAssignedToMe) {
            return (
              <Buttons style={{ justifyContent: 'space-between' }}>
                <Tooltip
                  title={<Translate value='ambulance.dialog.tooltip.select' />}
                >
                  <Button variant='contained' onClick={() => this.assignToMe(row)}>
                    <Translate value='ambulance.dialog.actions.assign2me' />
                  </Button>
                </Tooltip>
              </Buttons>
            )
          }
          if (row.state === 'IN' && mySelf === row.userRefPhysician) {
            return (
              <Buttons style={{ justifyContent: 'space-around' }}>
                <Tooltip
                  title={<Translate value='ambulance.dialog.tooltip.finishSuccess' />}
                >
                  <Button variant='contained' onClick={() => this.finishItem(row)}>
                    <Translate value='ambulance.dialog.actions.finish' />
                  </Button>
                </Tooltip>
                <Tooltip
                  title={<Translate value='ambulance.dialog.tooltip.finishError' />}
                >
                  <Button variant='contained' color='secondary' onClick={() => this.finishItemError(row)}>
                    <Translate value='ambulance.dialog.actions.error' />
                  </Button>
                </Tooltip>
              </Buttons>)
          }
          return (<div />)
        }
      }
    }

    return (
      <Dialog
        id='ambulanceQueueItems'
        scroll='paper'
        maxWidth='md'
        fullWidth
        onEscapeKeyDown={toggleShowAmbulanceItemsDialog}
        open={showAmbulanceItemsDialog}
      >
        <DialogHeader name='ambulanceQueueItems' onClose={toggleShowAmbulanceItemsDialog}>
          <Translate value='ambulance.dialog.title' />
        </DialogHeader>
        <EntityTable
          hideRowCheckbox
          rows={queueItems}
          entity='ambulanceQueueItems'
          columns={cols}
          hidePagnitation
          hasPagingSupport={false}
        />
      </Dialog>
    )
  }
}

const mapStateToProps = ({ ambulancequeueitems: { showAmbulanceItemsDialog, queueItems } }: StoreType) => ({
  showAmbulanceItemsDialog,
  queueItems
})

export default compose(
  connect(
    mapStateToProps,
    {
      toggleShowAmbulanceItemsDialog: ambulanceQueueItemsActions.toggleShowAmbulanceItemsDialog,
      setItemIntoProgressRequested: ambulanceQueueItemsActions.setItemIntoProgressRequested,
      setItemToFinishedRequested: ambulanceQueueItemsActions.setItemToFinishedRequested,
      setItemToErrorRequested: ambulanceQueueItemsActions.setItemToErrorRequested,
      navigateToConversationOfItem: ambulanceQueueItemsActions.navigateToConversationOfItem
    }
  )
)(CreateAmbulanceItemsDialog)
