// @flow
import React, { type Node } from 'react'
import type { DynamicFormPropsType } from './DynamicForm'

type PropsType = { children: Node } & DynamicFormPropsType

export default class ReduxFormWrapper extends React.Component<PropsType> {
  el: ?Object

  disableButton = false

  submitting = false

  static defaultProps = {
    render: undefined,
    addionalButtons: undefined,
    onSave: () => {},
    dontAutoFocus: false
  }

  componentDidMount() {
    this.setFocus()
  }

  componentWillReceiveProps(props: PropsType) {
    const { submitting, submitFailed, onSave } = props
    if (submitting) {
      this.submitting = true
    }
    if (!submitting && this.submitting && !submitFailed) {
      // submitted has ended
      this.submitting = false
      if (onSave) onSave()
    }
  }

  setFocus() {
    const { props } = this
    if (props.dontAutoFocus) {
      return
    }
    if (this.el) {
      const input = this.el.querySelector('input')
      if (input) input.focus()
    }
  }

  render() {
    const { handleSubmit, pristine, submitting, form, children, submitForm, startSubmitting } = this.props
    const wrapper = this

    const formSubmitting = pristine || submitting

    const doSubmit = handleSubmit((data) => {
      if (!wrapper.disableButton && !formSubmitting) {
        wrapper.disableButton = true
        startSubmitting(form)
        submitForm(form, data)

        setTimeout(() => {
          wrapper.disableButton = false
        }, 1000)
        /*
          this a small hack for redux form.
          redux-form calls this function with the data.
          you can use promises to do async stuff and then stop the submit through
          resolving the promise. But Since we are using sagas
          we return a promise and never resolve it.
          this Keeps the form in the submittign state.
          In the saga we dispatch stopSubmit to finish submitting
        */
        return new Promise(() => {})
      }
      return null
    })

    return (
      <form
        onSubmit={doSubmit}
        ref={(el) => {
          if (el) this.el = el
        }}
      >
        {children}
      </form>
    )
  }
}
