// @flow
import type { ActionType, ExtractReturn } from 'common-docdok/src/types'
import type { StudySurveyDtoType } from 'common-docdok/src/domain/survey/types/studySurveyDto'

import { scheduleSurveyDialogActionTypes, scheduleSurveyDialogActions } from '../actions'
// State Definition
export type ScheduleSurveyDialogStateType = {
  scheduleSurvey: ?StudySurveyDtoType
}

const initialState = {
  scheduleSurvey: undefined
}

function setStudySurveySchedule(
  state: ScheduleSurveyDialogStateType,
  action: ExtractReturn<typeof scheduleSurveyDialogActions.setStudySurveySchedule>
) {
  return { ...state, scheduleSurvey: action.payload }
}

const ACTION_HANDLERS = {
  [scheduleSurveyDialogActionTypes.SET_STUDY_SURVEY_SCHEDULE]: setStudySurveySchedule
}

export default function studiesReducer(
  state: ScheduleSurveyDialogStateType = initialState,
  action: ActionType
): ScheduleSurveyDialogStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
