// @flow
import { connect } from 'react-redux'
import { roles } from 'common-docdok/src/domain/user/types/meDto'
import { isRouteMatch } from '../utils'

import conditional from '../components/conditional'

import { storeContainer } from '../../../store/createStore'
import { originBasedFilterNotPatient, originBasedFilterPatient } from './originbasedFilter'

type PathPropsType = {
  name: string,
  children: React$Element<*>
}


type RouteFilterType = Object => boolean
const defaultRouteFilter: RouteFilterType = () => true

export function checkRoute(state: StoreType, name: string) {
  let visible = false
  const {
    security: { rights }
  } = state
  if (rights) {
    let originFilter = defaultRouteFilter

    // HACK FOR vinzengruppe
    const origin = state.profile ? state.profile.origin : ''
    if (state.profile && state.profile.authorities.indexOf(roles.patient) !== -1) {
      originFilter = originBasedFilterPatient[origin]
        ? originBasedFilterPatient[origin].routeFilter : defaultRouteFilter
    } else {
      originFilter = originBasedFilterNotPatient[origin]
        ? originBasedFilterNotPatient[origin].routeFilter : defaultRouteFilter
    }

    const originFiltered = rights.filter(r => originFilter(r))

    const rightsForRoute = originFiltered.filter(r => isRouteMatch(r.route, name, true))[0]
    visible = rightsForRoute !== undefined
  }
  return visible
}
export function isPathAccessable(path: string) {
  if (storeContainer.store) {
    return checkRoute(storeContainer.store.getState(), path)
  }
  return false
}
const mapStateToProps = (state: StoreType, { name, children }: PathPropsType) => {
  const visible = checkRoute(state, name)
  return { visible, children }
}

export default connect(mapStateToProps)(conditional)
