// @flow

export const fieldNames = {
  id: 'id',
  name: 'name'
}

export const fields = {
  id: {
    type: 'number',
    format: 'int64'
  },
  name: { type: 'string' }
}

export type DeviceDefinitionDtoType = {
  id: number,
  name: string
}
