// @flow
import React from 'react'
import api from 'common-docdok/src/api'
import PatientTable from '../../Patients/containers/PatientsTable'

type PropsType = {
  params: {
    clinicId: number,
    uuid: string
  }
}

export default class PhysicianPatients extends React.Component<PropsType> {
  render() {
    const { uuid } = this.props.params

    return (
      <PatientTable
        id='physicianPatients'
        api={(...args) => api.getPhysicianPatients(uuid, ...args)}
      />
    )
  }
}
