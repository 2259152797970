// @flow
import { put, call, takeEvery, select } from 'redux-saga/effects'
import invariant from 'invariant'

import api from '../../../api'
import { userActions, userActionTypes } from '../actions'
import type { ExtractReturn, GenType } from '../../../types'
import { getLocalStorage } from '../../../utils/localStorage'
import { unreadable } from '../../../utils/crypto'
import { findCurrentUser, getAcceptedTermsVersion, isPatient } from '../selectors/findCurrentUser'

export const REMEMBER_ME_KEY: string = 'rememberMe'

export function* fetchProfile(): GenType {
  try {
    const result = yield call(api.getProfile)
    const profile = result.data
    getLocalStorage().setItem(REMEMBER_ME_KEY, unreadable(profile.email))
    const { data } = yield call(api.getUserPreferences)
    const preferences = data
    yield put(
      userActions.profileFetchSucceeded({
        ...profile,
        preferences
      })
    )
  } catch (error) {
    yield put(userActions.profileFetchFailed(error))
  }
}

type UploadAvatarActionType = ExtractReturn<typeof userActions.uploadAvatarRequested>
export function* uploadAvatar(action: UploadAvatarActionType): GenType {
  try {
    const uploadUrl = yield call(api.getUploadAvatarUrl)
    const bytes = action.payload
    yield call(api.uploadAvatar, uploadUrl.data.url, bytes)
    yield put(userActions.profileFetchRequested())
    yield put(userActions.uploadAvatarSucceeded())
  } catch (error) {
    yield put(userActions.uploadAvatarFailed(error))
  }
}

export function* checkTermsVersion(): GenType {
  try {
    const currentUser = yield select(findCurrentUser)
    const origin = currentUser.isJust ? currentUser.get().origin : ''
    const termsVersion = yield call(api.getTermsVersion, origin)
    const acceptedTerms = getAcceptedTermsVersion(currentUser.isJust && currentUser.get())
    if (
      currentUser.isNothing ||
      !isPatient(currentUser.get()) ||
      (acceptedTerms.isJust && acceptedTerms.get() === termsVersion)
    ) {
      yield put(userActions.termsVersionEquals())
    } else {
      yield put(userActions.termsVersionChanged(termsVersion))
    }
  } catch (error) {
    yield put(userActions.checkTermsVersionError(error))
  }
}

type UpdateTermsVersionType = ExtractReturn<typeof userActions.updateTermsVersionRequested>
export function* updateTermsVersion(action: UpdateTermsVersionType): GenType {
  try {
    invariant(action.payload, 'payload undefined')
    invariant(typeof action.payload === 'string', 'unexpected payload type')
    const termsVersion = action.payload
    yield call(api.updateAcceptedTermsVersion, termsVersion)
    yield put(userActions.updateTermsVersionSucceeded(termsVersion))
  } catch (error) {
    yield put(userActions.updateTermsVersionFailed(error))
  }
}

export function* updateUserPreferences(
  action: ExtractReturn<typeof userActions.updateUserPreferencesRequested>
): GenType {
  try {
    const preferences = action.payload
    yield call(api.updateUserPreferences, preferences)
    yield put(userActions.updateUserPreferencesSucceeded(preferences))
  } catch (error) {
    yield put(userActions.updateUserPreferencesFailed(error))
  }
}

export default function profileSaga(): Array<GenType> {
  return [
    takeEvery(userActionTypes.PROFILE_FETCH_REQUESTED, fetchProfile),
    takeEvery(userActionTypes.UPLOAD_AVATAR_REQUESTED, uploadAvatar),
    takeEvery(
      [userActionTypes.CHECK_TERMS_VERSION, userActionTypes.PROFILE_FETCH_SUCCEEDED],
      checkTermsVersion
    ),
    takeEvery(userActionTypes.UPDATE_TERMS_VERSION_REQUESTED, updateTermsVersion),
    takeEvery(userActionTypes.UPDATE_USER_PREFERENCES_REQUESTED, updateUserPreferences)
  ]
}
