// @flow
export default function buildPaginingUrl(
  basePath: string,
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: ?string,
  orderBy?: ?string
) {
  let path = `${basePath}?size=${pageSize}&page=${page}`
  if (searchTerm) {
    path += `&search=${searchTerm}`
  }
  if (orderBy) {
    path += `&${orderBy}`
  }
  return path
}

const addVersionToUrl = (url: string) => `${url}?v=${Date.now()}`

export const setAvatarPictureVersions = (result: any) => ({
  ...result,
  data: result.data.map(p => ({
    ...p,
    avatarPicture: addVersionToUrl(p.avatarPicture)
  }))
})

export const setAvatarPictureVersion = (result: any) => ({
  ...result,
  data: {
    ...result.data,
    avatarPicture: addVersionToUrl(result.data.avatarPicture)
  }
})
