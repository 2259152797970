import { put, call, takeEvery, select } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import type { ExtractReturn, GenType, CommonStateType } from '../../../types'

import api from '../../../api'
import type { PatientDtoType } from '../types/patientDto'
import primaryConSelector from '../../messaging/selectors/primaryConSelector'
import { messagingActions } from '../../messaging/actions'
import { healthrelationActions, healthrelationActionTypes } from '../actions'
import { asyncAction } from '../../../utils/sagaUtils'
// -- APPEND GENERATORS HERE --

function* loadPatientProgressRequested(
  action: ExtractReturn<any>
): GenType {
  try {
    const result = yield call(api.getPatientProgress, action.payload)
    yield put(
      healthrelationActions.loadPatientProgressSucceeded(result.data)
    )
    action.payload.cb(result.data)
  } catch (error) {
    yield put(healthrelationActions.loadPatientProgressFailed(error))
  }
}

function* loadPatientProgressCountsRequested(
  action: ExtractReturn<any>
): GenType {
  try {
    const result = yield call(api.getPatientProgressCounts, action.payload)
    yield put(
      healthrelationActions.loadPatientCountSucceeded(result.data)
    )
    action.payload.cb(result.data)
  } catch (error) {
    yield put(healthrelationActions.loadPatientCountFailed(error))
  }
}

function* loadPatientMeasurementsRequested(
  action: ExtractReturn<any>
): GenType {
  try {
    const { patientRef } = action.payload
    yield patientRef
  } catch (error) {
    yield error
  }
}

function* loadPatientActivitiesRequested(
  action: ExtractReturn<any>
): GenType {
  try {
    const { patientUuid, physicianUuid, distinct } = action.payload
    const result = yield call(api.getPatientActivities, {
      patientUuid,
      physicianUuid,
      distinct
    })

    if (!distinct) {
      yield put(
        healthrelationActions.loadPatientActivitiesSucceeded(result.data)
      )
    } else {
      yield put(
        healthrelationActions.loadPatientUniqueActivitiesSucceeded(result.data)
      )
    }
  } catch (error) {
    yield put(healthrelationActions.loadPatientActivitiesFailed(error))
  }
}

function* upcomingAppointments(): GenType {
  try {
    const result = yield call(api.getAllAppointments)
    yield put(healthrelationActions.upcomingAppointmentsSucceeded(result.data))
  } catch (error) {
    yield put(healthrelationActions.upcomingAppointmentsFailed(error))
  }
}

function* massSending(
  action: ExtractReturn<typeof healthrelationActions.massSendingRequested>
): GenType {
  try {
    const { message, activated, simulation, countCall } = action.payload
    const result = yield call(api.massSending, message, activated, simulation)
    const extended = { ...result.data, simulation }
    if (countCall) {
      yield put(healthrelationActions.massSendingCountSucceeded(extended))
    } else {
      yield put(healthrelationActions.massSendingSucceeded(extended))
    }
  } catch (error) {
    yield put(healthrelationActions.massSendingFailed(error))
  }
}

function* loadAllPatientsRequested(): GenType {
  try {
    const result = yield call(api.getPatients)
    yield put(healthrelationActions.loadAllPatientsSucceeded(result.data))
  } catch (error) {
    yield error
  }
}

function* loadPatientRequested(
  action: ExtractReturn<typeof healthrelationActions.loadPatientRequested>
): GenType {
  try {
    const patientId = action.payload
    const patients: { [id: string]: PatientDtoType } = yield select(
      (state: CommonStateType) => state.healthrelationCache.patients
    )
    let patient: PatientDtoType = patients[patientId]

    /*   let userId */
    if (patient) {
      /*  userId = patient.userRef */
      /*   yield put(userActions.loadMissingUsersRequested([userId])) */
    } else {
      const winner = yield asyncAction(
        healthrelationActions.getPatientDtoRequested(patientId)
      )
      if (winner.succeeded) {
        patient = winner.succeeded.payload
        /*   userId = patient.userRef */
        /*   yield put(userActions.loadMissingUsersRequested([userId])) */
      } else {
        throw winner.failed.error
      }
    }
    /*  yield put(
      healthrelationActions.loadMissingProfessionalsRequested([patient.primaryPhysicianUuid])
    ) */

    const conversationId = yield select(primaryConSelector, patientId)
    if (!conversationId) {
      yield asyncAction(
        messagingActions.getSubscriptionsRequested(false, patient.userRef)
      )
    }

    yield put(healthrelationActions.loadPatientSucceeded(patient))
  } catch (error) {
    yield put(healthrelationActions.loadPatientFailed(error))
  }
}

function* createPatientRequested(
  action: ExtractReturn<typeof healthrelationActions.createPatientRequested>
): GenType {
  try {
    const { payload } = action
    const result = yield call(api.createPatient, payload)
    const patient = result.data

    yield put(healthrelationActions.createPatientSucceeded(patient))
  } catch (error) {
    yield put(healthrelationActions.createPatientFailed(error))
  }
}

function* createPatientSucceeeded(
  action: ExtractReturn<typeof healthrelationActions.createPatientSucceeded>
): GenType {
  const patient = action.payload
  let primarConv
  while (primarConv === undefined) {
    // retry until we get the primary conversation
    const winner = yield asyncAction(
      messagingActions.getSubscriptionsRequested()
    )
    if (winner.succeeded) {
      primarConv = yield select((state: CommonStateType) =>
        primaryConSelector(state, patient.uuid)
      )
    } else {
      throw winner.failed.error
    }
    if (primarConv === undefined) {
      yield call(delay, 100)
    }
  }
  yield put(healthrelationActions.loadAllPatientsRequested())
}

function* uploadPatientAvatarRequested(
  action: ExtractReturn<typeof healthrelationActions.uploadPatientAvatarRequested>
): GenType {
  try {
    const { patientUuid, bytes } = action.payload
    const result = yield call(api.getPatientUploadAvatarUrl, patientUuid)
    const {
      data: { url }
    } = result
    yield call(api.uploadAvatar, url, bytes)
    yield put(
      healthrelationActions.uploadPatientAvatarSucceeded(patientUuid, url)
    )
  } catch (error) {
    yield put(healthrelationActions.uploadPatientAvatarFailed(error))
  }
}

function* getPatientDto(
  action: ExtractReturn<typeof healthrelationActions.getPatientDtoRequested>
): GenType {
  try {
    const patientUuid = action.payload
    const result = yield call(api.getPatient, patientUuid)
    const patient = result.data
    yield put(healthrelationActions.getPatientDtoSucceeded(patient))
  } catch (error) {
    yield put(healthrelationActions.getPatientDtoFailed(error))
  }
}
function* deletePatient(
  action: ExtractReturn<typeof healthrelationActions.deletePatientRequested>
): GenType {
  try {
    const { uuid } = action.payload
    yield call(api.deletePatient, uuid)
    yield put(healthrelationActions.deletePatientSucceeded(action.payload))
  } catch (error) {
    yield put(healthrelationActions.deletePatientFailed(error))
  }
}

function* updatePatient(
  action: ExtractReturn<typeof healthrelationActions.deletePatientRequested>
): GenType {
  try {
    const patient = action.payload
    const result = yield call(api.updatePatient, patient)
    yield put(healthrelationActions.updatePatientSucceeded(result.data))
  } catch (error) {
    yield put(healthrelationActions.updatePatientFailed(error))
  }
}

function* updatePatientActivity(action: any): GenType {
  try {
    const activity = action.payload
    const result = yield call(api.updatePatientActivities, activity)
    yield put(healthrelationActions.updatePatientActivitySucceeded(result.data))
  } catch (error) {
    yield put(healthrelationActions.updatePatientActivityFailed(error))
  }
}

export function* addFileGen(action: any): GenType {
  const {
    patientActivityId,
    file: { mimeType, arrayBuffer, name }
  } = action.payload
  const {
    data: { url }
  } = yield call(api.uploadRequestAttachment, name || mimeType, patientActivityId)

  yield call(api.uploadFile, url, mimeType, arrayBuffer)
}

function* createDiga1PatientRequested(
  action: ExtractReturn<typeof healthrelationActions.createDiga1PatientRequested>
): GenType {
  const { payload } = action
  try {
    const result = yield call(api.createDiga1Patient, payload)
    const patient = result.data

    yield put(healthrelationActions.createDiga1PatientSucceeded(patient))
    yield put(healthrelationActions.getCurrentPatientRequested(payload.uuid))
    if (payload.cb) {
      payload.cb()
    }
  } catch (error) {
    yield put(healthrelationActions.createDiga1PatientFailed(error))
    if (payload.cb) {
      payload.cb()
    }
  }
}

function* getCurrentPatient(
  action
) {
  try {
    const patientUuid = action.payload
    const result = yield call(api.getPatient, patientUuid)
    const patient = result.data
    yield put(healthrelationActions.getCurrentPatientSucceeded(patient))
  } catch (error) {
    yield put(healthrelationActions.getCurrentPatientFailed(error))
  }
}

function* loadPatientTargetRequested(
  action
) {
  try {
    const { id, cb } = action.payload
    const result = yield call(api.getPatientTarget, id)
    yield put(healthrelationActions.loadPatientTargetSucceeded(result.data))
    if (cb) {
      cb(result.data)
    }
  } catch (error) {
    yield put(healthrelationActions.loadPatientTargetFailed(error))
    const { cb } = action.payload
    if (cb) {
      cb()
    }
  }
}

function* updatePatientTargetRequested(
  action
) {
  const { payload } = action
  try {
    if (!payload.id) {
      yield call(api.setPatientTarget, payload)
      yield put(healthrelationActions.updatePatientTargetSucceeded())
      yield put(healthrelationActions.loadPatientTargetRequested(payload.patientUuid))
    }
    if (payload.cb) {
      payload.cb()
    }
  } catch (error) {
    yield put(healthrelationActions.updatePatientTargetFailed(error))
    if (payload.cb) {
      payload.cb()
    }
  }
}

function* getCurrentPatientDigaDetails(
  action
) {
  try {
    const { payload } = action
    yield put(healthrelationActions.loadPatientDigaDetailsSucceeded({}))
    const result = yield call(api.getPatientTargetDigaDetails, payload)
    const patientMeasurments = result.data
    yield put(healthrelationActions.loadPatientDigaDetailsSucceeded(patientMeasurments))
  } catch (error) {
    yield put(healthrelationActions.loadPatientDigaDetailsFailed(error))
  }
}


export default function patientSaga(): Array<GenType> {
  return [
    // -- APPEND TAKES HERE --
    takeEvery(healthrelationActionTypes.MASS_SENDING_REQUESTED, massSending),
    takeEvery(
      healthrelationActionTypes.CREATE_PATIENT_REQUESTED,
      createPatientRequested
    ),
    takeEvery(
      healthrelationActionTypes.CREATE_PATIENT_SUCCEEDED,
      createPatientSucceeeded
    ),
    takeEvery(
      healthrelationActionTypes.LOAD_ALL_PATIENTS_REQUESTED,
      loadAllPatientsRequested
    ),
    takeEvery(
      healthrelationActionTypes.LOAD_PATIENT_REQUESTED,
      loadPatientRequested
    ),
    takeEvery(
      healthrelationActionTypes.UPLOAD_PATIENT_AVATAR_REQUESTED,
      uploadPatientAvatarRequested
    ),
    takeEvery(
      healthrelationActionTypes.GET_PATIENT_DTO_REQUESTED,
      getPatientDto
    ),
    takeEvery(
      healthrelationActionTypes.DELETE_PATIENT_REQUESTED,
      deletePatient
    ),
    takeEvery(
      healthrelationActionTypes.UPDATE_PATIENT_REQUESTED,
      updatePatient
    ),
    takeEvery(
      healthrelationActionTypes.UPCOMING_APPOINTMENTS_REQUESTED,
      upcomingAppointments
    ),
    takeEvery(
      healthrelationActionTypes.LOAD_PATIENT_ACTIVITIES_REQUESTED,
      loadPatientActivitiesRequested
    ),
    takeEvery(
      healthrelationActionTypes.LOAD_PATIENT_MEASUREMENTS_REQUESTED,
      loadPatientMeasurementsRequested
    ),
    takeEvery(
      healthrelationActionTypes.UPDATE_PATIENT_ACTIVITY_REQUESTED,
      updatePatientActivity
    ),
    takeEvery(
      healthrelationActionTypes.PATIENT_ACTIVITY_ADD_FILE_REQUESTED,
      addFileGen
    ),
    takeEvery(
      healthrelationActionTypes.LOAD_PATIENT_PROGRESS_REQUESTED,
      loadPatientProgressRequested
    ),
    takeEvery(
      healthrelationActionTypes.LOAD_PATIENT_COUNT_REQUESTED,
      loadPatientProgressCountsRequested
    ),
    takeEvery(
      healthrelationActionTypes.CREATE_DIGA_1_PATIENT_REQUESTED,
      createDiga1PatientRequested
    ),
    takeEvery(
      healthrelationActionTypes.GET_PATIENT_DETAILS_REQUESTED,
      getCurrentPatient
    ),
    takeEvery(
      healthrelationActionTypes.LOAD_PATIENT_TARGET_REQUESTED,
      loadPatientTargetRequested
    ),
    takeEvery(
      healthrelationActionTypes.UPDATE_PATIENT_TARGET_REQUESTED,
      updatePatientTargetRequested
    ),
    takeEvery(
      healthrelationActionTypes.GET_PATIENT_DATA_FROM_DIGA_REQUESTED,
      getCurrentPatientDigaDetails
    )
  ]
}
