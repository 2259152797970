// @flow
import React from 'react'

type PropsType = {
  scores: Array<string>
}

export default function surveyScore(props: PropsType) {
  const { scores } = props
  return <div>{scores.join(' | ')}</div>
}
