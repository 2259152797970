// @flow
import type { ActionType, ExtractReturn } from 'common-docdok/src/types'

import { messagesActionTypes, messagesActions } from '../actions'

export type MessagesStateType = {
  showSubscriptions: boolean,
  showCreateNewConversation: boolean,
  showMassSendingDialog: boolean
}

const initialState: MessagesStateType = {
  showSubscriptions: true,
  showCreateNewConversation: false,
  showMassSendingDialog: false
}

function setShowSubscriptions(
  state: MessagesStateType,
  action: ExtractReturn<typeof messagesActions.setShowSubscriptions>
): MessagesStateType {
  return {
    ...state,
    showSubscriptions: action.payload
  }
}

function setShowCreateConversation(
  state: MessagesStateType,
  action: ExtractReturn<typeof messagesActions.setShowCreateConversation>
): MessagesStateType {
  return {
    ...state,
    showCreateNewConversation: action.payload
  }
}

function setShowMassSendingDialog(
  state: MessagesStateType,
  action: ExtractReturn<typeof messagesActions.setShowMassSendingDialog>
): MessagesStateType {
  return {
    ...state,
    showMassSendingDialog: action.payload
  }
}

const ACTION_HANDLERS = {
  [messagesActionTypes.SET_SHOW_SUBSCRIPTIONS]: setShowSubscriptions,
  [messagesActionTypes.SET_SHOW_CREATE_CONVERSATION]: setShowCreateConversation,
  [messagesActionTypes.SET_SHOW_MASS_DIALOG]: setShowMassSendingDialog
}

export default function messagesReducer(
  state: MessagesStateType = initialState,
  action: ActionType
): MessagesStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
