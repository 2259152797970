// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'
import Paper from '@material-ui/core/Paper'

import {
  fields,
  fieldNames
} from 'common-docdok/src/domain/healthrelation/types/healthcareProfessionalDto'
import api from 'common-docdok/src/api'
import { Link, browserHistory } from 'react-router'

import type { HealthcareProfessionalDtoType } from 'common-docdok/src/domain/healthrelation/types/healthcareProfessionalDto'

import EntityTable from '../../../../../common/EntityTable'
import { UserNameComp as UserName } from '../../../../../common/UserName'
import { showEventAction } from '../../Events/components/Events'
import { TextLine, PageTitle, GreyBar, Toolbar } from '../../../../../common/Static'

type PropsType = {
  params: {
    clinicId: number
  },
  children?: any
}

type StateType = {
  selection: Array<HealthcareProfessionalDtoType>
}

export default class ClinicTeam extends React.Component<PropsType, StateType> {
  static defaultProps = {
    children: undefined
  }

  state = {
    selection: []
  }

  render() {
    if (this.props.children) return <div>{this.props.children}</div>
    const { clinicId } = this.props.params

    const cols = {
      class: {
        ...fields.class,
        sortable: false
      },
      name: {
        orderBy: fieldNames.lastName,
        renderer: (val: any, col: string, entity: string, row: Object) => (
          <TextLine>
            <Link to={`/private/app/clinics/${clinicId}/team/${row.uuid}/patients`}>
              <UserName entity={row} />
            </Link>
          </TextLine>
        )
      },
      gender: fields.gender,
      email: fields.email,
      mobileNumber: fields.mobileNumber
    }

    return (
      <div>
        <PageTitle>
          <Translate value='menu.team' />
        </PageTitle>
        <Paper>
          <GreyBar>
            <Toolbar />
          </GreyBar>

          <EntityTable
            api={(...args) => api.getClinicTeam(clinicId, ...args)}
            id='clinicTeam'
            entity='professional'
            defaultSort='name'
            hideRowCheckbox
            selection={this.state.selection}
            columns={cols}
            onRowClick={(row) => {
              browserHistory.push(`/private/app/clinics/${clinicId}/team/${row.uuid}/patients`)
            }}
            actions={[showEventAction]}
            onSelectionChanged={newSelection => this.setState({ selection: newSelection })}
          />
        </Paper>
      </div>
    )
  }
}
