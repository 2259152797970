// @flow
import React from 'react'
import pathSatisfies from 'ramda/src/pathSatisfies'
import path from 'ramda/src/path'
import styled from 'styled-components'
import type { MessageDtoType } from 'common-docdok/src/domain/messaging/types/messageDto'

const AudioView = styled.div`
  background-color: lightgrey;
  overflow: hidden;
  position: relative;
  margin: 5px 5px 5px 5px;
  border-radius: 10px;
  padding: 8px;
`

type PropsType = {
  message: MessageDtoType,
  directRender?: boolean
}

type StateType = {}
class AudioMessage extends React.Component<PropsType, StateType> {
  render() {
    const { message, directRender } = this.props
    if (!message.mediaResource) return null

    const isVideo = pathSatisfies((mimeType) => mimeType.includes('audio'), [
      'message',
      'mediaResource',
      'mimeType'
    ])(this.props)

    const audioUrl = path(['message', 'mediaResource', 'originalUrl'])(
      this.props
    )

    return (
      <div>
        {directRender && audioUrl && isVideo ? (
          <AudioView>
            <audio controls>
              <track src="" kind="captions" srcLang="en" label="english_captions" />
              <source src={audioUrl} type="audio/ogg" />
              <source src={audioUrl} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </AudioView>
        ) : null}
      </div>
    )
  }
}
AudioMessage.defaultProps = {
  directRender: false
}
export default AudioMessage
