export default {
  en: {
    name: 'Content',
    fields: {
      clinics: 'Clinics',
      contentDefinitionId: 'Id',
      definitionKey: 'definitionKey',
      language: 'Language',
      license: 'License',
      mediaResourceUuid: 'mediaResourceUuid',
      mimeType: 'File-Type',
      studies: 'Studies',
      text: 'Text',
      title: 'Title',
      tags: 'Tags'
    }
  },
  de: {
    name: 'Inhalt',
    fields: {
      clinics: 'Kliniken',
      contentDefinitionId: 'Id',
      definitionKey: 'definitionKey',
      language: 'Sprache',
      license: 'Lizenz',
      mediaResourceUuid: 'mediaResourceUuid',
      mimeType: 'Dateityp',
      studies: 'Studien',
      text: 'Text',
      title: 'Titel',
      tags: 'Tags'
    }
  }
}
