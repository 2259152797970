import React from 'react'
import { I18n } from 'react-redux-i18n'

type PropsType = {
  differenceDays: number
}

export function timepointString(differenceDays) {
  let result = ''
  if (differenceDays >= 0) {
    if (differenceDays === 0) {
      result = `${differenceDays}${I18n.t('surveys.timepoint.day')}`
    } else {
      const years = Math.trunc(differenceDays / 365)
      let remainder = differenceDays - years * 365
      const months = Math.trunc(remainder / 30)
      remainder -= months * 30
      const weeks = Math.trunc(remainder / 7)
      remainder -= weeks * 7
      const days = remainder

      if (days !== 0) {
        result = `${days}${I18n.t('surveys.timepoint.day')}`
      }
      if (weeks !== 0) {
        result = `${weeks}${I18n.t('surveys.timepoint.week')} ${result}`
      }
      if (months !== 0) {
        result = `${months}${I18n.t('surveys.timepoint.month')} ${result}`
      }

      if (years !== 0) {
        result = `${years}${I18n.t('surveys.timepoint.year')} ${result}`
      }
    }
  }
  return result.trim()
}

export default function timepoint(props: PropsType) {
  const { differenceDays } = props

  let result = ''
  if (differenceDays >= 0) {
    if (differenceDays === 0) {
      result = `${differenceDays}${I18n.t('surveys.timepoint.day')}`
    } else {
      const years = Math.trunc(differenceDays / 365)
      let remainder = differenceDays - years * 365
      const months = Math.trunc(remainder / 30)
      remainder -= months * 30
      const weeks = Math.trunc(remainder / 7)
      remainder -= weeks * 7
      const days = remainder

      if (days !== 0) {
        result = `${days}${I18n.t('surveys.timepoint.day')}`
      }
      if (weeks !== 0) {
        result = `${weeks}${I18n.t('surveys.timepoint.week')} ${result}`
      }
      if (months !== 0) {
        result = `${months}${I18n.t('surveys.timepoint.month')} ${result}`
      }

      if (years !== 0) {
        result = `${years}${I18n.t('surveys.timepoint.year')} ${result}`
      }
    }
  }

  return <div>{result.trim()}</div>
}
