// @flow
import React from 'react'
import { messagingActions } from 'common-docdok/src/domain/messaging/actions'
import { connect } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import Card from '@material-ui/core/Card'
import type { ConversationType } from 'common-docdok/src/domain/messaging/reducers/messaging'
import ConversationItem from '../../../../../common/ConversationItem'
import { sortConversations } from '../../../../../common/Messaging/utils'


type PropsType = {
  getSubscriptionsRequested: typeof messagingActions.getSubscriptionsRequested,
  conversations: {
    [conversationId: number]: ConversationType
  },
  checkedMessages: { [key: number]: number },
  selectedConversation: ?number
}

class LatestConvesations extends React.Component<PropsType> {
  componentDidMount() {
    const { conversations, getSubscriptionsRequested } = this.props
    if (Object.keys(conversations).length === 0) getSubscriptionsRequested()
  }

  renderConversation(conversation: ConversationType, index: number) {
    const { checkedMessages, selectedConversation } = this.props
    const { id: conversationId } = conversation
    const newestCheckedMessageNo = checkedMessages[conversationId] ? checkedMessages[conversationId] : 0

    return (
      <ConversationItem
        key={index}
        conversation={conversation}
        checkedMessageNo={newestCheckedMessageNo}
        selectedConversation={selectedConversation}
      />
    )
  }

  render() {
    const { conversations, checkedMessages } = this.props

    const convs: any = Object.values(conversations)
    const conversationsList = sortConversations(convs, checkedMessages, 5)

    const hasNewMessages = conversationsList.length > 0
    return (
      <Card id='recent-conversations' className='latestConversations'>
        <List
          component='nav'
          subheader={(
            <ListSubheader component='div'>
              <Translate value='home.recentActivity' />
            </ListSubheader>
)}
        >
          {hasNewMessages ? (
            conversationsList.map((conv, index) => this.renderConversation(conv, index))
          ) : (
            <Translate style={{ marginLeft: 17 }} value='home.noActiveConversation' />
          )}
        </List>
      </Card>
    )
  }
}

const mapStateToProps = ({ messaging: { conversations, checkedMessages, selectedConversation } }: StoreType) => ({
  conversations,
  checkedMessages,
  selectedConversation
})

export default connect(
  mapStateToProps,
  {
    getSubscriptionsRequested: messagingActions.getSubscriptionsRequested
  }
)(LatestConvesations)
