// @flow
import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { Translate } from 'react-redux-i18n'

type PropsType = {
  onConfirm: (result: boolean) => void,
  message: any,
  messageOk: any,
  messageCancel: any,
  open: boolean
}

export default class ConfirmationDialog extends React.Component<PropsType> {
  handleClose(result: boolean) {
    this.props.onConfirm(result)
  }

  render() {
    const { open, message, messageOk = 'actions.ok', messageCancel = 'actions.cancel' } = this.props
    const actions = []

    return (
      <Dialog actions={actions} open={open} onEscapeKeyDown={() => this.handleClose(false)}>
        <DialogTitle>
          {' '}
          {message}
        </DialogTitle>
        <DialogActions>
          <Button
            id='actions.cancel'
            data-test='confirm-cancel'
            onClick={() => this.handleClose(false)}
          >
            <Translate value={messageCancel} />
          </Button>
          ,
          <Button
            data-test='confirm-ok'
            id='actions.ok'
            color='primary'
            variant='contained'
            onClick={() => this.handleClose(true)}
          >
            <Translate value={messageOk} />
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
