// @flow
import React, { Component } from 'react'

type PropsType = {
  intersectionRatio?: number,
  onVisible?: Function,
  children: Array<React$Element<any>>
}


class TrackVisibility extends Component<PropsType> {
  ref = React.createRef()

  static defaultProps = { intersectionRatio: 0.8, onVisible: () => {} }

  async componentDidMount() {
    const { intersectionRatio, onVisible } = this.props
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > intersectionRatio) {
          // full visible
            onVisible()
          }
        })
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: intersectionRatio
      }
    )
    if (this.ref.current) {
      observer.observe(this.ref.current)
    }
  }

  render() {
    return <div ref={this.ref}>{this.props.children}</div>
  }
}


export default TrackVisibility
