// @flow
import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Translate } from 'react-redux-i18n'
import { browserHistory } from 'react-router'

import type { PatientUserType } from '../reducers'
import UserAvatar from '../../UserAvatar'
import DateRenderer from '../../DateRenderer/components/dateRenderer'
import { UserNameComp as UserName } from '../../UserName'

type PatientItemType = {
  patient: PatientUserType,
  onRequestClose: () => void
}

export default function patientItem({ patient, onRequestClose }: PatientItemType) {
  return (
    <ListItem
      button
      className='global-search-result-entry'
      onClick={() => {
        onRequestClose()
        browserHistory.push(`/private/app/patients/${patient.uuid}`)
      }}
    >
      <UserAvatar src={patient.avatarPicture} name={patient.firstName} />
      <ListItemText
        primary={<UserName className='patient-name' entity={patient} />}
        secondary={(
          <span>
            <Translate value='domain.entity.patient.fields.birthdate' />
            :&nbsp;
            <DateRenderer className='patient-birthdate' value={patient.birthdate} />
          </span>
)}
      />
    </ListItem>
  )
}
