// @flow
import React from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import MenuItem from '@material-ui/core/MenuItem'
import debounce from 'lodash/debounce'
import TextField from '@material-ui/core/TextField'

import { Menu } from '../../../Mui'
import { sortOptionsByTranslation } from '../utils'
import type { FieldComponentPropsType } from './formField'

type StateType = {
  open: boolean
}

export default class AutoComplete extends React.Component<FieldComponentPropsType, StateType> {
  state = {
    open: false
  }

  anchorEl = undefined

  selection = 0

  shouldOpen = (value: string) => {
    const {
      input,
      meta: { form },
      propertyDefinition: { options }
    } = this.props
    const { name } = input

    const dataSource = (options || [])
      .sort(sortOptionsByTranslation(form, name))
      .map((option) => {
        const translation = I18n.t(`forms.${form}.${name}Opts.${option.key}`)
        if (translation === 'noTranslation') {
          return option.key
        }
        return translation
      })
      .filter(option => !value || option.toLowerCase().indexOf(value.toLowerCase()) > -1)
    const optionIsValue = dataSource.length > 0 && dataSource[0] === value
    const open = value && dataSource.length > 0 && !optionIsValue
    return open
  }

  setMenuDelayed = debounce(
    (open) => {
      this.setState({ open })
    },
    500,
    {
      leading: false
    }
  )

  handleChange = (event: Object) => {
    this.anchorEl = event.currentTarget
    this.setMenuDelayed(this.shouldOpen(event.target.value))
    this.props.input.onChange(event.target.value)
    if (this.anchorEl) this.anchorEl.focus()
  }

  handleClose = (option: string) => () => {
    this.props.input.onChange(option)
    this.setMenuDelayed(false)
  }

  render() {
    const {
      input,
      meta: { touched, error, form },
      propertyDefinition: { options }
    } = this.props
    const { name, value } = input
    const dataSource = (options || []).sort(sortOptionsByTranslation(form, name)).map((option) => {
      const translation = I18n.t(`forms.${form}.${name}Opts.${option.key}`)
      if (translation === 'noTranslation') {
        return option.key
      }
      return translation
    })
    const filteredDataSource = dataSource.filter(
      option => !value || option.toLowerCase().indexOf(value.toLowerCase()) > -1
    )

    const displayedDataSource = filteredDataSource.length > 0 ? filteredDataSource : dataSource

    const HasError = Boolean(touched && error)
    return (
      <React.Fragment>
        <TextField
          id={`${form}-${name}`}
          inputProps={{
            'data-test': `${form}-${name}`
          }}
          value={value}
          onChange={this.handleChange}
          onKeyDown={(event) => {
            if (event.keyCode === 40) {
              this.anchorEl = event.currentTarget
              this.setState({ open: true })
            }
          }}
          label={<Translate value={`forms.${form}.${name}`} />}
          error={HasError}
          helperText={(touched && error) || ''}
          margin='normal'
        />
        <Menu
          onKeyDown={(event) => {
            if (event.keyCode === 40 && this.selection < filteredDataSource.length - 1) {
              this.selection += 1
            } else if (event.keyCode === 38) {
              if (this.selection === 0) {
                this.handleClose(value)()
              } else {
                this.selection -= 1
              }
            } else if (event.keyCode !== 13) {
              this.handleClose(value)()
            }
          }}
          open={this.state.open}
          anchorEl={this.anchorEl}
          PaperProps={{ style: { marginTop: 50 } }}
          onClose={this.handleClose(value)}
        >
          <span>
            {displayedDataSource.map((option, index) => (
              <MenuItem
                key={option}
                onClick={this.handleClose(option)}
                selected={index === this.selection}
              >
                {option}
              </MenuItem>
            ))}
          </span>
        </Menu>
      </React.Fragment>
    )
  }
}
