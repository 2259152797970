// @flow
import React from 'react'
import { connect } from 'react-redux'
import compose from 'ramda/src/compose'
import { createCaseButtonActions } from '../actions'
import ClinicalData from './ClinicalData'
import DataLines from './DataLines'
import FormField from '../../DynamicForm/components/fields/formField'
import DynamicFormDialog from '../../DynamicFormDialog'

type PropsType = {
  fullScreen: boolean,
  showCreateCaseDialog: boolean,
  toggleCreateCaseDialog: typeof createCaseButtonActions.toggleCreateCaseDialog,
  useIconButton?: boolean,
  classes: Object,
  casedata?: Array<string>
}

class CreateCaseDialog extends React.Component<PropsType> {
  static defaultProps = {
    useIconButton: false,
    casedata: ['']
  }

  render() {
    const { toggleCreateCaseDialog, showCreateCaseDialog, casedata } = this.props

    return (
      <DynamicFormDialog
        open={showCreateCaseDialog}
        handleClose={toggleCreateCaseDialog}
        titleKey='cases.newCase.title'
        form='createCase'
        fullScreen
        initialValues={{ casedata }}
        fields={() => ({
          caseName: { type: 'string', mandatory: true },
          casedata: { type: 'Array', mandatory: true }
        })}
        render={(def: Object) => (
          <div>
            <FormField {...def} name='caseName' />
            <ClinicalData>
              <DataLines name='casedata' />
            </ClinicalData>
          </div>
        )}
      />
    )
  }
}

const mapStateToProps = ({ createCaseButton: { showCreateCaseDialog } }: StoreType) => ({
  showCreateCaseDialog
})

export default compose(
  connect(
    mapStateToProps,
    {
      toggleCreateCaseDialog: createCaseButtonActions.toggleCreateCaseDialog
    }
  )
)(CreateCaseDialog)
