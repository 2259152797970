// @flow
import React from 'react'
import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import NotificationIcon from '@material-ui/icons/Notifications'
import { Translate } from 'react-redux-i18n'
import Tooltip from '@material-ui/core/Tooltip'
import { messagingActions } from 'common-docdok/src/domain/messaging/actions'
import type { MessageDtoType } from 'common-docdok/src/domain/messaging/types/messageDto'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'

import Message from '../../Message'
import { muiStyled } from '../../Mui'

const NotificationList = muiStyled(List)({
  minWidth: 400,
  maxWidth: 400,
  backgroundColor: 'white'
})
const DissmissButton = muiStyled(Button)({
  float: 'right',
  width: 300
})

type PropsType = {
  markAsCheckedRequested: typeof messagingActions.markAsCheckedRequested,
  conversationId: number,
  notifications: Array<MessageDtoType>,
  unseenNotifactions: number,
  loadMessagesRequested: typeof messagingActions.loadMessagesRequested
}

type StateType = {
  anchorEl: ?EventTarget
}

export default class HeaderNotifications extends React.Component<PropsType, StateType> {
  state = { anchorEl: undefined }

  render() {
    const {
      unseenNotifactions,
      loadMessagesRequested,
      conversationId,
      notifications,
      markAsCheckedRequested
    } = this.props

    const notificationsCount = unseenNotifactions > 0 ? ` (${unseenNotifactions})` : ''

    return (
      <div>
        <Tooltip title={<Translate value='header.notifications' />}>
          <IconButton
            color='primary'
            id='notificationButton'
            onClick={(event) => {
              loadMessagesRequested(conversationId)
              this.setState({
                anchorEl: event.currentTarget
              })
            }}
          >
            {unseenNotifactions > 0 ? (
              <Badge badgeContent={unseenNotifactions} color='secondary'>
                <NotificationIcon />
              </Badge>
            ) : (
              <NotificationIcon />
            )}
          </IconButton>
        </Tooltip>
        <Popover
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          onClose={() => {
            this.setState({
              anchorEl: undefined
            })
          }}
        >
          <NotificationList
            id='notification-list'
            component='nav'
            subheader={(
              <ListSubheader component='div'>
                <Translate value='header.notifications' />
                {notificationsCount}
              </ListSubheader>
)}
          >
            <div style={{ maxWidth: '90vw' }}>
              {notifications.reverse().map(message => (
                <Message
                  key={message.uuid}
                  message={message}
                  closeRequest={() => {
                    this.setState({
                      anchorEl: undefined
                    })
                  }}
                />
              ))}
            </div>
          </NotificationList>

          {notifications.length > 0 && (
            <DissmissButton
              id='dismissAllButton'
              onClick={() => {
                markAsCheckedRequested(notifications, true)
                this.setState({
                  anchorEl: undefined
                })
              }}
              color='secondary'
            >
              <Translate value='header.dismissAll' />
            </DissmissButton>
          )}
        </Popover>
      </div>
    )
  }
}
