// @flow
import { userActionTypes, userActions } from 'common-docdok/src/domain/user/actions'
import type { ExtractReturn } from 'common-docdok/src/types'
import { takeEvery } from 'redux-saga/effects'

declare var Raven: any
export function setupSentry(action: ExtractReturn<typeof userActions.profileFetchSucceeded>) {
  const user = action.payload
  Raven.setUserContext({
    email: user.email,
    ui: user.uid
  })
}

export default function sentriSaga(): Array<Generator<*, *, *>> {
  return [takeEvery(userActionTypes.PROFILE_FETCH_SUCCEEDED, setupSentry)]
}
