// @flow
/* eslint-disable no-param-reassign */
import sorters from 'common-docdok/src/utils/sorters'
import filters from 'common-docdok/src/utils/filters'
import loadImage from 'blueimp-load-image'
import type { MessagingStateType, ConversationType } from 'common-docdok/src/domain/messaging/reducers/messaging'
import { getKeycloakClient } from 'common-docdok/src/common/Keycloak/keycloakProvider'

export default function sortByPostedAt(conv1: mixed, conv2: mixed) {
  const c1: any = conv1
  const c2: any = conv2
  const latestPostedAt1 = c1.latestMessage ? c1.latestMessage.postedAt : '1900-01-01T00:00:00+00:00'
  const latestPostedAt2 = c2.latestMessage ? c2.latestMessage.postedAt : '1900-01-01T00:00:00+00:00'

  let result = sorters.sortByValue(latestPostedAt1, latestPostedAt2, true)

  if (result === 0) {
    result = sorters.sortByValue(c1.title, c2.title)
  }
  return result
}

export function toArrayBuffer(file: File): Promise<any> {
  return new Promise((resolve) => {
    const fileReader = new FileReader()
    fileReader.onload = function onload() {
      const arrayBuffer = this.result
      resolve(arrayBuffer)
    }
    fileReader.readAsArrayBuffer(file)
  })
}

export function resizeImage(file: File, settings: Object = { maxWidth: 1600, maxHeight: 1600 }): Promise<any> {
  return new Promise((resolve) => {
    loadImage(
      file,
      (canvas) => {
        canvas.toBlob(
          (blob) => {
            toArrayBuffer(blob).then((arrayBuffer) => {
              const size = arrayBuffer.byteLength
              const dataUrl = canvas.toDataURL()
              resolve({ arrayBuffer, dataUrl, size })
            })
          },
          'image/jpeg',
          0.8
        )
      },
      { ...settings, orientation: true, canvas: true }
    )
  })
}

export function unreadMessagesCount(messaging: MessagingStateType) {
  let conversationsCount = 0
  let messagesCount = 0
  Object.values(messaging.conversations)
    .filter((c: any) => c.type !== 'NOTIFICATION')
    .forEach((conv: any) => {
      const { latestMessage } = conv
      if (latestMessage) {
        const checkedMessages = messaging.checkedMessages[conv.id] ? messaging.checkedMessages[conv.id] : 0

        const maxSeenNr = Math.max(checkedMessages)
        const unseenMessages = latestMessage.sequenceNo - maxSeenNr
        if (unseenMessages > 0) {
          conversationsCount += 1
          messagesCount += unseenMessages
        }
      }
    })
  return { conversationsCount, messagesCount }
}

function getUncheckedMessages(conversation: ConversationType, checkedMessages: Object) {
  const { id, latestMessage } = conversation

  const newestMessageNo = latestMessage ? latestMessage.sequenceNo : 0
  const newestCheckedMessageNo = checkedMessages[id] ? checkedMessages[id] : 0
  const uncheckedMessages = newestMessageNo - newestCheckedMessageNo
  return uncheckedMessages
}

export function sortConversations(
  conversations: Array<ConversationType>,
  checkedMessages: { [key: number]: number },
  maxCount: number,
  showNotifications?: boolean,
  showEmpty?: boolean,
  filterText?: ?string
): Array<ConversationType> {
  return conversations
    .filter(conv => conv.latestMessage || showEmpty)
    .filter(conv => conv.type !== 'NOTIFICATION' || showNotifications)
    .filter(conv => filters.textFilter(conv.title, filterText || ''))
    .sort((c1, c2) => {
      const un1 = getUncheckedMessages(c1, checkedMessages)
      const un2 = getUncheckedMessages(c2, checkedMessages)

      const bothWithUnreadMessages = un1 * un2 !== 0
      const bothWithoutUnreadMessages = un1 + un2 === 0
      if (bothWithUnreadMessages || bothWithoutUnreadMessages) {
        return sortByPostedAt(c1, c2)
      }
      return un2 - un1
    })
    .slice(0, maxCount)
}

export function isCaseConversation(meta: Object) {
  return Boolean(meta.case)
}

export function isCaseOwner(currentUserRef: string, meta: Object) {
  return isCaseConversation(meta) && currentUserRef !== meta.caseOwner
}

export function extraxctCaseInfo(currentUserRef: string, meta: Object = {}) {
  return { isCase: isCaseConversation(meta), isCaseOwner: currentUserRef === meta.caseOwner }
}

const mayRemoveNotificationSubscription = () => false
const mayRemoveConversationSubscription = (subscription: any) => {
  const currentUser = getKeycloakClient().getUserId()
  const subscriptionUserIsCurrentUser = currentUser === subscription.userRef
  return subscriptionUserIsCurrentUser
}

const mayRemoveCaseSubscription = (subscription: any, conversation: Object) => {
  const currentUser = getKeycloakClient().getUserId()
  const subscriptionUserIsCurrentUser = currentUser === subscription.userRef
  const caseFlags = extraxctCaseInfo(currentUser, conversation.meta)
  return caseFlags.isCaseOwner ? !subscriptionUserIsCurrentUser : subscriptionUserIsCurrentUser
}

export function mayRemoveSubscription(subscription: any, conversation: Object) {
  if (conversation.type === 'NOTIFICATION') return mayRemoveNotificationSubscription()
  if (extraxctCaseInfo(subscription.userRef, conversation.meta).isCase) {
    return mayRemoveCaseSubscription(subscription, conversation)
  }

  return mayRemoveConversationSubscription(subscription)
}
