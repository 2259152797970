// @flow
import { emailRgx, mobileRgx } from '../../../utils/rgx'

export const fieldNames = {
  authorities: 'authorities',
  email: 'email',
  firstName: 'firstName',
  langKey: 'langKey',
  lastName: 'lastName',
  mobileNumber: 'mobileNumber',
  salutation: 'salutation'
}

export const fields = {
  authorities: {
    type: 'multiSelect',
    options: [
      { value: 'ROLE_ADMIN', key: 'ROLE_ADMIN' },
      { value: 'ROLE_STUDY_COORDINATOR', key: 'ROLE_STUDY_COORDINATOR' },
      { value: 'ROLE_PHYSICIAN', key: 'ROLE_PHYSICIAN' },
      { value: 'ROLE_MPA', key: 'ROLE_MPA' },
      { value: 'ROLE_PATIENT', key: 'ROLE_PATIENT' }
    ],
    mandatory: true
  },
  salutation: {
    type: 'autoComplete',
    options: [
      { value: 'Mr', key: 'Mr' },
      { value: 'Ms', key: 'Ms' },
      { value: 'Mrs', key: 'Mrs' },
      { value: 'Prof', key: 'Prof' },
      { value: 'Dr', key: 'Dr' },
      { value: 'PractMed', key: 'PractMed' }
    ],
    maxLength: 100
  },
  firstName: {
    type: 'string',
    maxLength: 50,
    mandatory: true
  },
  lastName: {
    type: 'string',
    maxLength: 50,
    mandatory: true
  },
  email: {
    type: 'string',
    mandatory: true,
    minLength: 5,
    maxLength: 100,
    pattern: emailRgx,
    patternNice: 'me@domain.com'
  },
  mobileNumber: {
    type: 'string',
    minLength: 5,
    maxLength: 20,
    pattern: mobileRgx,
    patternNice: '+41795553322',
    normalize: (nbr: string) => nbr.replace(/\s/g, '')
  },
  langKey: {
    type: 'select',
    enum: ['en', 'fr', 'de', 'it'],
    mandatory: true
  }
}

export type UserForCreationVmType = {
  authorities: Array<string>,
  email: string,
  firstName: string,
  langKey: string,
  lastName: string,
  mobileNumber: string,
  salutation: string
};
