// @flow
import type { ActionType } from 'common-docdok/src/types'
import { createCaseButtonActionTypes } from '../actions'

// State Definition

export type CreateCaseButtonStateType = {
  showCreateCaseDialog: boolean
}

// Default State
const initialState: CreateCaseButtonStateType = {
  showCreateCaseDialog: false
}

// Action Handlers

function toggleCaseDialog(state: CreateCaseButtonStateType): CreateCaseButtonStateType {
  return {
    ...state,
    showCreateCaseDialog: !state.showCreateCaseDialog
  }
}

const ACTION_HANDLERS = {
  [createCaseButtonActionTypes.TOGGLE_CREATE_CASE_DIALOG]: toggleCaseDialog
}

export default function caseReducer(
  state: CreateCaseButtonStateType = initialState,
  action: ActionType
): CreateCaseButtonStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
