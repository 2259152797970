// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'

import { checkRoute } from '../../../../common/Security/utils'

export default (store: any) => ({
  path: 'messages',
  name: (
    <span id='breadcrumb1'>
      <Translate value='menu.messages' />
    </span>
  ),
  onEnter: (state: any, replace: Function) => {
    checkRoute(store.getState(), state.location.pathname, replace)
  },
  /*  Async getComponent is only invoked when route matches   */
  getComponent(nextState: any, cb: Function) {
    /*  Webpack - use 'require.ensure' to create a split point
        and embed an async module loader (jsonp) when bundling   */
    (require: any).ensure(
      [],
      (require) => {
        /*  Webpack - use require callback to define
          dependencies for bundling   */
        const container = require('./containers/MessagesOverview').default

        /*  Return getComponent   */
        cb(null, container)

        /* Webpack named bundle   */
      },
      'messages'
    )
  },
  childRoutes: [
    {
      path: 'conversation/:conversationId',
      getComponent(nextState: any, cb: Function) {
        /*  Webpack - use 'require.ensure' to create a split point
            and embed an async module loader (jsonp) when bundling   */
        (require: any).ensure(
          [],
          (require) => {
            /*  Webpack - use require callback to define
              dependencies for bundling   */
            const container = require('./components/Conversation').default

            /*  Return getComponent   */
            cb(null, container)

            /* Webpack named bundle   */
          },
          'conversation'
        )
      },
      getDisplayName(param: Object) {
        let conversationTitle = '...'
        const { conversations } = store.getState().messaging
        if (conversations && conversations[param.conversationId]) {
          const conv = conversations[param.conversationId]
          conversationTitle = conv.title
          if (conv.type === 'NOTIFICATION') {
            conversationTitle = <Translate value='menu.notifications' />
          }
        }
        return (
          <span id='breadcrumb2'>
            {' '}
            {conversationTitle}
            {' '}
          </span>
        )
      },
      onEnter(state: any, replace: Function) {
        checkRoute(store.getState(), state.location.pathname, replace)
      }
    }
  ]
})
