// @flow
import { connect } from 'react-redux'
import { isRouteMatch } from '../utils'

import conditional from '../components/conditional'

import { storeContainer } from '../../../store/createStore'

type ActionPropsType = {
  name?: string,
  names?: Array<string>
}

const mapStateToProps = (state: StoreType, { name, names }: ActionPropsType) => {
  let visible = false
  const { rights } = state.security
  if (state.location && rights) {
    const matchingRoutes = rights.filter(r => isRouteMatch(r.route, state.location))
    matchingRoutes.forEach((rightsForRoute) => {
      if (name) {
        if (rightsForRoute.actions.indexOf(name) > -1) {
          visible = true
        }
      } else if (names) {
        names.forEach((n) => {
          if (rightsForRoute.actions.indexOf(n) > -1) {
            visible = true
          }
        })
      } else {
        throw new Error('provide property "name" or "names" to the Action component')
      }
    })
  }
  return { visible }
}

export default connect(mapStateToProps)(conditional)

export function isActionVisible(name: string) {
  if (storeContainer.store) {
    return mapStateToProps(storeContainer.store.getState(), { name }).visible
  }
  return false
}
