export default {
  en: {
    header: {
      title: 'Ambulance online',
      tooltip: {
        waiting: 'Number of waiting patients',
        inprogress: 'Patients in progress',
        assigend2me: 'My patients',
        showlist: 'Show Virtual Waiting Room',
        joinButton:  `
        Ready for video consultation. Click here to show your doctor 
        that you are present and ready.`,
        actualPosition: 'Your actual waiting position %{position}',
        leave: 'Leave waiting room'
      },
      joinButton: 'Ready for video consulation'
    },
    dialog: {
      title: 'Virtual Waiting Room',
      states: {
        WA: 'Waiting',
        IN: 'In Progress'
      },
      actions: {
        assign2me: 'Assing to me',
        finish: 'Success Finish',
        error: 'Error'
      },
      tooltip: {
        select: 'Invite the patient to a video consultation in the waiting room.',
        finishSuccess: 'Close the video consultation and release the patient from the waiting room',
        finishError: `There were problems with the video consultation. 
        Close the video consultation and release the patient from the waiting room.`
      }
    },
    ambulanceRows: {
      fields: {
        userRef: 'Patient',
        id: 'Actions',
        state: 'State'
      }
    },
    add2WaitingRoom: {
      title: 'Welcome at Ambulance online',
      infoTextJoin: `
      Your arranged appointment starts in less than 15 minutes? Please click on the 
      Button "Enter waiting room".
      `,
      infoTextLater: `
      You have registered in advance and your appointment will take place at a different time? 
      Please click on the button "Leave registration and come to the agreed appointment 
      reconnect."
      `,
      joinWaitingRoom: 'Enter waiting room',
      doSomethingElse: `Leave registration and come to the agreed appointment 
      reconnect`
    },
    messageSuccess: `You have successfully entered the waiting room. Please be patient, 
    until your doctor calls you.`,
    messageRemoved: `You have exited the waiting room. Please click the 
      button “Ready for video consultation” to rejoin the waiting room.`
  },
  de: {
    header: {
      title: ' Wilkommen zur Ambulanz online',
      tooltip: {
        waiting: 'Anzahl Patienten',
        inprogress: 'Patienten in Bearbeitung',
        assigend2me: 'Meine Patienten',
        showlist: 'Virtuelles Wartezimmer anzeigen',
        joinButton:  `
        Bereit für die Videokonsultation. Klicken Sie hier, damit Ihre Ärztin 
        bzw. Ihr Arzt sehen kann, dass Sie anwesend und bereit sind.`,
        actualPosition: 'Sie sind die/der %{position}. Patient/in im Warteraum',
        leave: 'Den Warteraum verlassen'
      },
      joinButton: 'Bereit für die Videokonsultation'
    },
    dialog: {
      title: 'Wartezimmer',
      states: {
        WA: 'Wartend',
        IN: 'In Bearbeitung'
      },
      actions: {
        assign2me: 'Bearbeiten',
        finish: 'Abschliessen',
        error: 'Fehler'
      },
      tooltip: {
        select: 'Patient/in im Wartezimmer zur Videokonsultation auffordern.',
        finishSuccess: 'Videokonsultation abschliessen und die/den Patient/in aus dem Wartezimmer entlassen',
        finishError: `Es traten Probleme mit der Videokonsultation auf. 
        Videokonsultation abschliessen und die/den Patient/in aus dem Wartezimmer entlassen.`
      }
    },
    ambulanceRows: {
      fields: {
        userRef: 'Patient',
        state: 'Status',
        id: 'Aktionen'
      }
    },
    add2WaitingRoom: {
      title: ' Willkommen in der Ambulanz online',
      infoTextJoin: `
      Ihr vereinbarter Termin startet in weniger als 15 Minuten? Klicken Sie bitte auf die 
      Schaltfläche "Warteraum betreten".
      `,
      infoTextLater: `
      Sie haben sich vorab registriert und Ihr Termin findet zu einem anderem Zeitpunkt statt? 
      Klicken Sie bitte auf die Schaltfläche "Anmeldung verlassen und zum vereinbarten Termin 
      wieder einloggen".
      `,
      joinWaitingRoom: 'Warteraum betreten',
      doSomethingElse: 'Anmeldung verlassen und zum vereinbarten Termin wieder einloggen'
    },
    messageSuccess: `Sie haben erfolgreich den Warteraum betreten. Bitte haben Sie noch etwas Geduld, 
      bis Ihre Ärztin/Ihr Arzt Sie aufruft.`,
    messageRemoved: `Sie haben den Warteraum verlassen. Bitte klicken Sie auf die 
    Schaltfläche "Bereit für die Videokonsultation", um wieder in den Warteraum zu gelangen.`
  }
}
