// @flow

import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import styled from 'styled-components'

const ExtensibleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ expandToRight }) => (!expandToRight ? 'flex-end' : 'flex-start')};
`
const InputWrapper = styled.div`
  opacity: ${({ show }) => (show ? 1.0 : 0.0)};
  width: ${({ show }) => (show ? 'calc(100% - 48px)' : 0)};
  transition: opsacity 250ms 250ms, width 250ms 250ms;
`

type PropsType = {
  id: string,
  inputComponent: any,
  icon: any,
  tooltip: string,
  expandToRight?: boolean,
  onToggle?: boolean => void
}

type StateType = {
  show: boolean
}

export default class Extensible extends React.Component<PropsType, StateType> {
  static defaultProps = {
    expandToRight: false,
    onToggle: () => {}
  }

  state = { show: false }

  toggleShow() {
    const { onToggle } = this.props
    const { show } = this.state

    if (onToggle) {
      onToggle(!show)
    }
    this.setState({ show: !show })
  }

  render() {
    const { icon, inputComponent, expandToRight, id, tooltip } = this.props
    const { show } = this.state

    const searchButton = (
      <Tooltip title={tooltip} placement={expandToRight ? 'top' : 'bottom'}>
        <IconButton
          id={`${id}-search-button`}
          onClick={() => {
            this.toggleShow()
          }}
        >
          {icon}
        </IconButton>
      </Tooltip>
    )

    return (
      <ExtensibleContainer expandToRight={expandToRight}>
        {expandToRight ? searchButton : null}
        <InputWrapper show={show}>{show && inputComponent}</InputWrapper>
        {!expandToRight ? searchButton : null}
      </ExtensibleContainer>
    )
  }
}
