export default {
  en: {
    fileName: 'billing',
    active: 'Billing is active',
    activeToolTip: 'click to stop',
    paused: 'Billing is paused',
    deactivated: 'Start Billing',
    deactivatedToolTip: 'click to start',
    requested: {
      emergencyCode: 'Emergency-Code',
      title: 'Do you want to bill this consultation?',
      text:
        'Do you want to send the time you spent in this '
        + 'consulation to your EMR system in order to bill the consulation? '
        + 'A costshare will be charged to your docdok account '
        + 'per your contract agreement.',
      start: 'Activate Billing',
      stop: 'No Thanks',
      noReminder: 'Do not show billing dialog automatically',
      reminderLabel: 'Show billing dialog automatically',
      type: 'Type',
      code: 'Code'
    }
  },
  de: {
    fileName: 'abrechnung',
    active: 'Verrechnung ist aktiv',
    activeToolTip: 'klicken um zu stoppen',
    paused: 'Verrechnung ist pausiert',
    deactivated: 'Verrechnung aktivieren',
    deactivatedToolTip: 'klicken um zu starten',
    requested: {
      emergencyCode: 'Notfall-Code',
      title: 'Möchten Sie diese Beratung verrechnen?',
      text:
        'Möchten Sie die Zeit, welche für diese Beratung aufgebracht wurde '
        + 'an Ihr EMR System schicken um sie zu verrechnen? '
        + 'Ein Kostenanteil wird dadurch Ihrem docdok Account verrechnet'
        + 'wie im Vertrag vereinbart.',
      start: 'Verrechnung aktivieren',
      stop: 'Nein Danke',
      noReminder: 'Verrechnungsdialog nicht automatisch öffnen',
      reminderLabel: 'Verrechnungsdialog automatisch anzeigen',
      type: 'Typ',
      code: 'Code'
    }
  }
}
