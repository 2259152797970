// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'
import toString from 'common-docdok/src/utils/toString'

import { checkRoute } from '../../../../common/Security/utils'

export default (store: any) => ({
  path: 'patients',
  name: (
    <span id='breadcrumb1'>
      <Translate value='menu.patients' />
    </span>
  ),
  onEnter: (state: any, replace: Function) => {
    checkRoute(store.getState(), state.location.pathname, replace)
  },
  getComponent(nextState: any, cb: Function) {
    (require: any).ensure(
      [],
      (require) => {
        const container = require('./components/patients').default
        cb(null, container)
      },
      'patients'
    )
  },
  childRoutes: [
    {
      onEnter: (state: any, replace: Function) => {
        checkRoute(store.getState(), state.location.pathname, replace)
      },
      path: ':patientId',
      getDisplayName(param: Object) {
        const pat = store.getState().healthrelationCache.patients[param.patientId]
        const name = toString.person(pat, '...')
        return <span id='breadcrumb2'>{name}</span>
      },
      getComponent(nextState: any, cb: Function) {
        (require: any).ensure(
          [],
          (require) => {
            const container = require('./containers/Patient').default
            cb(null, container)
          },
          'patient'
        )
      }
    }
  ]
})
