import React from 'react'
import compose from 'ramda/src/compose'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import withMobileDialog from '@material-ui/core/withMobileDialog'
// import { withStyles } from '@material-ui/core/styles'
import { DialogHeader } from '../../Mui'

// const DarkThemedDialog = withStyles({ paper: { backgroundColor: 'black' } })(Dialog)

const Videodiv = styled.div`
  background-color: black;
  overflow: hidden;
  position: relative;
  margin: 5px 5px 5px 5px;
  border-radius: 10px;
`

type PropsType = {
  fullScreen: boolean,
  showVideoDialog: boolean,
  closeDialogFn: () => {},
  videoUrl: string,
  videoTitle?: string
}

class PlayVideoDialog extends React.Component<PropsType> {
  static defaultProps = {
    videoTitle: 'No Title available'
  }

  render() {
    const { fullScreen, showVideoDialog, videoUrl, closeDialogFn, videoTitle } = this.props
    return (
      <Dialog
        fullScreen={fullScreen}
        maxWidth='md'
        scroll='paper'
        open={showVideoDialog}
        onEscapeKeyDown={closeDialogFn}
      >
        <DialogHeader name='show-video' onClose={closeDialogFn} />
        <div>
          <Typography variant='h6' align='center'>
            {videoTitle}
          </Typography>
          {videoUrl && (
            <Videodiv>
              <video
                style={{ objectFit: 'cover', width: '100%', height: '100%', maxHeight: '540px' }}
                muted
                preloaded='metadata'
                src={videoUrl}
                controls
                autoPlay
              />
            </Videodiv>
          )}
        </div>
      </Dialog>
    )
  }
}

export default compose(withMobileDialog())(PlayVideoDialog)
