// @flow
import type { ActionType } from '../../../types'
import { isRequested, shouldTrack, getPrefix } from '../utils'

export type LoadingStackStateType = {
  pendingActions: {
    [action: string]: number
  },
  isLoading: boolean
}

const defaultState: LoadingStackStateType = {
  pendingActions: {},
  isLoading: false
}

function increasePendingAction(state: LoadingStackStateType, action: string) {
  const currentValue = state.pendingActions[action] !== undefined ? state.pendingActions[action] : 0
  return {
    ...state,
    pendingActions: {
      ...state.pendingActions,
      [action]: currentValue + 1
    }
  }
}

function decreasePendingAction(state: LoadingStackStateType, action: string) {
  const currentValue = state.pendingActions[action] !== undefined ? state.pendingActions[action] : 0
  return {
    ...state,
    pendingActions: {
      ...state.pendingActions,
      [action]: currentValue - 1
    }
  }
}

function sumRequests(state: LoadingStackStateType): number {
  return Object.values(state.pendingActions).reduce((sum: any, val: any) => sum + val, 0)
}

export default function loadingStackReducer(
  state: LoadingStackStateType = defaultState,
  action: ActionType
): LoadingStackStateType {
  if (!action) return state
  let newState = { ...state }
  const { type } = action
  if (shouldTrack(type)) {
    const prefix = getPrefix(type)
    if (isRequested(type)) {
      newState = increasePendingAction(newState, prefix)
    } else {
      newState = decreasePendingAction(newState, prefix)
    }
  }

  const isLoading = sumRequests(newState) > 0
  newState = {
    ...newState,
    isLoading
  }

  return newState
}
