// @flow
import { put, takeEvery } from 'redux-saga/effects'
import { stopSubmit } from 'redux-form'
import { healthrelationActions } from 'common-docdok/src/domain/healthrelation/actions'
import type { GenType } from 'common-docdok/src/types'
import { asyncAction } from 'common-docdok/src/utils/sagaUtils'
import {
  dynamicFormActionTypes,
  type SubmitFormType
} from '../../../../../common/DynamicForm/actions'
import { infoPanelActions } from '../../../../../common/InfoPanel/actions'
import { clinicsActions } from '../actions'

function* submitFormGen({ payload }: SubmitFormType): GenType {
  if (payload.name === 'createClinic') {
    const clinic = payload.data
    if (clinic && clinic.id) {
      const winner = yield asyncAction(healthrelationActions.updateClinicRequested(clinic))
      if (winner.succeeded) {
        yield put(stopSubmit(payload.name))
        yield put(clinicsActions.setUpdateClinicDialog(false))
        yield put(infoPanelActions.showMessage('clinics.updateClinic.success'))
      } else {
        yield put(stopSubmit(payload.name, winner.failed.error))
      }
      return
    }
    const winner = yield asyncAction(healthrelationActions.createClinicRequested(clinic))
    if (winner.succeeded) {
      yield put(stopSubmit(payload.name))
      yield put(clinicsActions.setCreateClinicDialog(false))
      yield put(infoPanelActions.showMessage('clinics.createClinic.success'))
    } else {
      yield put(stopSubmit(payload.name, winner.failed.error))
    }
  }
}

export default function patientSaga(): Array<GenType> {
  return [takeEvery(dynamicFormActionTypes.SUBMIT_FORM, submitFormGen)]
}
