// @flow
import React from 'react'
import get from 'lodash/get'
import { Translate } from 'react-redux-i18n'
import styled from 'styled-components'

const SubmitErrorsContainer = styled.div`
  color: red;
  padding: 24px;
  padding-bottom: 8px;
  flex-grow: 2;
`
const TextContainer = styled.div`
  max-width: 400px;
`

const NoErrors = styled.div`
  flex-grow: ${(props) => (props.form === 'targetForm' || props.form === 'createCatalog' ? 'unset' : '2')};
`

type PropsType = {
  serverErrors: ?Object,
  form: ?string
}

export default function (props: PropsType) {
  const { serverErrors, form } = props
  const messageKey = get(serverErrors, 'response.data.message')
  const params = get(serverErrors, 'response.data.params')

  if (messageKey) {
    return (
      <SubmitErrorsContainer className='submit-errors'>
        <TextContainer>
          <Translate value={messageKey} {...params} />
        </TextContainer>
      </SubmitErrorsContainer>
    )
  }

  return <NoErrors form={form} />
}
