// @flow
import type { StudySummaryDtoType } from 'common-docdok/src/domain/survey/types/studySummaryDto'

export const studiesActionTypes = {
  TOGGLE_CREATE_STUDY_DIALOG: 'TOGGLE_CREATE_STUDY_DIALOG',
  TOGGLE_ADD_SURVEY_DIALOG: 'TOGGLE_ADD_SURVEY_DIALOG',
  SET_ADD_STUDY_PARTICIPANT_DIALOG: 'SET_ADD_STUDY_PARTICIPANT_DIALOG',
  TOGGLE_ADD_PHYSICIAN_DIALOG: 'TOGGLE_ADD_PHYSICIAN_DIALOG',
  TOGGLE_ADD_STUDY_COORDINATOR_DIALOG: 'TOGGLE_ADD_STUDY_COORDINATOR_DIALOG',
  SET_ADD_STUDY_DEVICE_DIALOG: 'SET_ADD_STUDY_DEVICE_DIALOG',
  DOWNLOAD_STUDY_DATA_REQUESTED: 'DOWNLOAD_STUDY_DATA_REQUESTED',
  DOWNLOAD_STUDY_DATA_SUCCEEDED: 'DOWNLOAD_STUDY_DATA_SUCCEEDED',
  DOWNLOAD_STUDY_DATA_FAILED: 'DOWNLOAD_STUDY_DATA_FAILED',
  DOWNLOAD_SURVEYS_ANSWERS_REQUESTED: 'DOWNLOAD_SURVEYS_ANSWERS_REQUESTED',
  DOWNLOAD_SURVEYS_ANSWERS_SUCCEEDED: 'DOWNLOAD_SURVEYS_ANSWERS_SUCCEEDED',
  DOWNLOAD_SURVEYS_ANSWERS_FAILED: 'DOWNLOAD_SURVEYS_ANSWERS_FAILED',
  SET_ADD_PARTICIPATION_CRITERIA: 'SET_ADD_PARTICIPATION_CRITERIA'
}
export type StudiesActionType = $Keys<typeof studiesActionTypes>

export const studiesActions = {
  toggleCreateStudyDialog() {
    return {
      type: studiesActionTypes.TOGGLE_CREATE_STUDY_DIALOG
    }
  },
  toggleAddSurveyDialog() {
    return {
      type: studiesActionTypes.TOGGLE_ADD_SURVEY_DIALOG
    }
  },
  toggleAddPhysicianDialog() {
    return {
      type: studiesActionTypes.TOGGLE_ADD_PHYSICIAN_DIALOG
    }
  },
  toggleAddStudyCoordinatorDialog() {
    return {
      type: studiesActionTypes.TOGGLE_ADD_STUDY_COORDINATOR_DIALOG
    }
  },
  setAddStudyParticipantDialog(show: boolean) {
    return {
      type: studiesActionTypes.SET_ADD_STUDY_PARTICIPANT_DIALOG,
      payload: show
    }
  },
  setAddStudyDeviceDialog(show: boolean) {
    return {
      type: studiesActionTypes.SET_ADD_STUDY_DEVICE_DIALOG,
      payload: show
    }
  },
  downloadStudyDataRequested(payload: StudySummaryDtoType) {
    return {
      type: studiesActionTypes.DOWNLOAD_STUDY_DATA_REQUESTED,
      payload
    }
  },
  downloadStudyDataSucceeded(payload: StudySummaryDtoType) {
    return {
      type: studiesActionTypes.DOWNLOAD_STUDY_DATA_SUCCEEDED,
      payload
    }
  },
  downloadStudyDataFailed(error: Error) {
    return {
      type: studiesActionTypes.DOWNLOAD_STUDY_DATA_FAILED,
      payload: { error }
    }
  },
  downloadSurveyAnswersRequested(studyId: number, surveyId: number) {
    return {
      type: studiesActionTypes.DOWNLOAD_SURVEYS_ANSWERS_REQUESTED,
      payload: { studyId, surveyId }
    }
  },
  downloadSurveyAnswersSucceeded() {
    return {
      type: studiesActionTypes.DOWNLOAD_SURVEYS_ANSWERS_SUCCEEDED
    }
  },
  downloadSurveyAnswersFailed(error: Error) {
    return {
      type: studiesActionTypes.DOWNLOAD_SURVEYS_ANSWERS_FAILED,
      payload: { error }
    }
  },
  setAddParticipationCriteriaDialog(show: boolean) {
    return {
      type: studiesActionTypes.SET_ADD_PARTICIPATION_CRITERIA,
      payload: show
    }
  }
}
