// @flow
import { type EntitySpecType } from './getEntitySpec'

export default function getDisplayValue(
  entity: ?Object,
  entitySpec: EntitySpecType,
  seperator: string = ' '
) {
  let result = ''
  if (entity) {
    result = Object.keys(entitySpec.fields)
      .map(f => entity && entity[f])
      .join(seperator)
  }

  return result
}
