// @flow
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { getKeycloakClient } from 'common-docdok/src/common/Keycloak/keycloakProvider'
import { messagingActions as ma } from 'common-docdok/src/domain/messaging/actions'
import ImageMessage from '../../../common/Message/components/ImageMessage'
import PdfMessage from '../../../common/Message/components/PdfMessage'
import VideoMessage from '../../../common/Message/components/VideoMessage'
import YoutubeMessage from '../../../common/Message/components/youtubeMessage'
import AudioMessage from '../../../common/Message/components/AudioMessage'

const SurroundedDiv = styled.div`
  display: flex;
  justify-content: center;
`

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  line-height: normal;
`
const TextLineDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
`

const QuestionLineDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const RateDescriptionDiv = styled.div`
  max-width: 30vw;
  margin-bottom: 5px;

  @media (min-width: 768px) {
    max-width: 20vw;
  }
`
type OwnPropsType = {
  question: Object
}

type PropsType = {
  question: Object,
  message: Object,
  createContentMessagePreviewRequested: any
}

class ExtendedContentRender extends React.Component<PropsType> {
  componentDidMount() {
    this.getContentURL()
  }

  getContentURL = () => {
    const {
      question: {
        moreInfo: { contentDefinitionId }
      },
      createContentMessagePreviewRequested
    } = this.props
    createContentMessagePreviewRequested(contentDefinitionId)
  }

  render() {
    const { question, message } = this.props
    const messageToConsider = message && {
      ...message,
      clearCatch: true,
      postedAt: new Date(),
      userRef: getKeycloakClient().getUserId(),
      sequenceNo: 123
    }
    return (
      <div>
        <SurroundedDiv>
          <ContainerDiv>
            <TextLineDiv>
              <RateDescriptionDiv>
                {question.minRateDescription}
              </RateDescriptionDiv>
              <RateDescriptionDiv style={{ textAlign: 'right' }}>
                {question.maxRateDescription}
              </RateDescriptionDiv>
            </TextLineDiv>
            {messageToConsider ? (
              <QuestionLineDiv>
                <ImageMessage message={messageToConsider} loadResourceOnMount />
                <PdfMessage message={messageToConsider} />
                <YoutubeMessage message={messageToConsider} />
                <VideoMessage message={messageToConsider} directRender />
                <AudioMessage message={messageToConsider} directRender />
              </QuestionLineDiv>
            ) : null}
          </ContainerDiv>
        </SurroundedDiv>
      </div>
    )
  }
}

const mapStateToProps = (state: StoreType, ownProps: OwnPropsType) => ({
  message:
    state.messaging.contentPreview[
      ownProps.question.moreInfo.contentDefinitionId
    ]
})

export default connect(mapStateToProps, {
  createContentMessagePreviewRequested: ma.createContentMessagePreviewRequested
})(ExtendedContentRender)

// {
//   type: 'html',
//   name: 'catalog-content-question',
//   renderAs: 'catalog-content-renderer',
//   moreInfo: {
//     contentDefinitionId: 69,
//   },
//   title: 'Pr',
//   html: {
//     default:
//       '',
//     de:
//       ''
//   }
// }
