import React from 'react'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import Button from '@material-ui/core/Button'
import pathOr from 'ramda/src/pathOr'
import linkifyHtml from 'linkifyjs/html'
import styled from 'styled-components'
import { messagingActions } from 'common-docdok/src/domain/messaging/actions'
import { healthrelationActions } from 'common-docdok/src/domain/healthrelation/actions'

type TextMessagePropsType = {
  message: Object,
  color: string,
  postMessageRequested: messagingActions.postMessageRequested,
  createDiga1PatientRequested: healthrelationActions.createDiga1PatientRequested,
  isPatient?: Boolean,
  loading?: Boolean,
  joinedDiga?: Boolean,
  patient?: Object,
  setLoading?: Function
}

const ButtonContainer = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  cursor: ${(props) => props.cursor || 'pointer'};

`
const TextContainer = styled.div`
  font-weight: bold;
  text-align: left;
  word-break: break-word;
  hyphens: auto;
  white-space: normal;

  a {
    color: #daefff;
  }
  padding-left: 12px;
  padding-right: 12px;

  color: ${(props) => props.color};
`

const TextContainerQuickReply = styled.div`
  font-weight: bold;
  text-align: center;
  word-break: break-word;
  hyphens: auto;
  white-space: normal;

  a {
    color: #daefff;
  }
  padding-left: 12px;
  padding-right: 12px;

  color: ${(props) => props.color};
`

const RoundedBox = styled.div`
  border-radius: 25px;
  border: 2px solid #ffffff;
  padding: 20px;
  min-width 200px;
  margin-right: 10px;
  margin-bottom: 10px;
`

function textMessage(props: TextMessagePropsType) {
  const { message, color, postMessageRequested, createDiga1PatientRequested } = props
  const textWithLineBreaks = message.text
    ? message.text.replace(/\n/g, '<br />')
    : ''

  const postQuickReplyAnswer = (choice) => {
    const choiceResponse = choice.value ? choice.value : choice.text
    const interaction = { result: { value: [choiceResponse] } }
    const meta = {
      subtype: 'quickreply',
      answers: message.uuid,
      context: message.meta.context,
      interaction
    }
    const answer = choice.text
    // $DisableFlow
    return () => postMessageRequested(message.conversationId, answer, null, meta)
  }

  const renderQuickReply = (isLatestQuickReply: boolean) => {
    const { meta } = message
    if (
      isLatestQuickReply &&
      meta.subtype &&
      meta.subtype === 'quickreply' &&
      meta.interaction.choices
    ) {
      return (
        <ButtonContainer>
          {meta.interaction.choices.map((choice) => (
            <RoundedBox
              variant="contained"
              key={choice.value}
              onClick={postQuickReplyAnswer(choice)}
            >
              <TextContainerQuickReply color={color}>
                {choice.text}
              </TextContainerQuickReply>
            </RoundedBox>
          ))}
        </ButtonContainer>
      )
    }
    return <div />
  }

  let htmlMessage = linkifyHtml(textWithLineBreaks, {
    defaultProtocol: 'https',
    callback(text, href) {
      return href
        ? `<a href="${href}" title="${href}" target="_blank">${text}</a>`
        : text
    }
  })

  const {
    message: {
      meta: { subtype }
    },
    patient,
    isPatient,
    joinedDiga,
    loading,
    setLoading
  } = props

  if (subtype === 'videoInvitation') {
    const relevantText = message.text.split('https')[0]
    htmlMessage = linkifyHtml(relevantText, {
      defaultProtocol: 'https',
      callback(text, href) {
        return href
          ? `<a href="${href}" title="${href}" target="_blank">${text}</a>`
          : text
      }
    })
  }

  const quickReply = renderQuickReply(pathOr(false, ['isLast'])(message))

  const joinDiga = I18n.t('messages.diga1Invitation.join')
  const onlyPatientCanJoin = I18n.t('messages.diga1Invitation.onlyPatientCanJoin')

  const handleJoinDiga1 = () => {
    if (joinedDiga) {
      return
    }
    if (!patient) {
      return
    }

    if (!isPatient) {
      return
    }

    if (loading) {
      return
    }

    setLoading(true)
    const data = {
      class: patient.class,
      uuid: patient.uuid,
      salutation: patient.salutation,
      firstName: patient.firstName,
      lastName: patient.lastName,
      email: patient.email,
      langKey: patient.langKey,
      gender: patient.gender,
      origin: patient.origin,
      activated: patient.activated,
      disabled: patient.disabled,
      type: 'PATIENTPROXIED',
      mobileNumber: patient.mobileNumber,
      birthdate: patient.birthdate,
      lifelongId: patient.lifelongId,
      firstname: patient.firstname,
      lastname: patient.lastname,
      cb: () => {
        setLoading(false)
      }
    }
    createDiga1PatientRequested(data)
  }

  return (
    <>
      <TextContainer
        color={color}
        dangerouslySetInnerHTML={{ __html: htmlMessage }}
      />
      {quickReply}
      {subtype === 'externalAppInvitationMessage' ? (
        <ButtonContainer cursor="default">
          <Button
            title={!isPatient ? onlyPatientCanJoin : ''}
            className={joinedDiga || !isPatient || loading ? ' disabled ' : ''}
            variant="contained"
            color={joinedDiga || !isPatient || loading ? 'default' : 'primary'}
            onClick={() => handleJoinDiga1()}
          >
            {joinDiga}
          </Button>
        </ButtonContainer>
      ) : null}
    </>
  )
}

export const mapStateToProps = (state) => {
  const uuid = state.selectedPatient
  const isPatient = state.profile
    && Array.isArray(state.profile.authorities)
    && state.profile.authorities.includes('ROLE_PATIENT')
  if (!uuid) {
    return {}
  }

  const patient = state.healthrelation.currentPatient || {}
  return {
    patient,
    isPatient,
    joinedDiga: patient.externalAppPreferencesDTO && patient.externalAppPreferencesDTO.status === 'patient-created'
  }
}
export default connect(mapStateToProps, {
  postMessageRequested: messagingActions.postMessageRequested,
  createDiga1PatientRequested: healthrelationActions.createDiga1PatientRequested
})(textMessage)
