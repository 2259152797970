// @flow
import React from 'react'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Translate } from 'react-redux-i18n'
import { browserHistory } from 'react-router'
import UserAvatar from '../../UserAvatar'

import type { MessagePropsType } from '../containers/Message'

export default function surveyCompleted(props: MessagePropsType) {
  const {
    user,
    message: {
      meta: { surveyId, studyId, surveyName }
    },
    closeRequest
  } = props

  let result = null
  if (user) {
    result = (
      <ListItem
        button
        id='survey-completed-notification'
        onClick={() => {
          if (studyId !== undefined) {
            browserHistory.push(
              `/private/app/studies/${studyId}/surveys/${surveyId}/participants/completed`
            )
          } else {
            const uuid = String(user.uuid)
            browserHistory.push(
              `/private/app/surveys/${surveyId}/participants/completed?patient_uuid=${uuid}`
            )
          }
          if (closeRequest) closeRequest()
        }}
      >
        <UserAvatar
          size={34}
          src={user ? user.avatarPicture : undefined}
          name={user ? user.firstName : ''}
        />
        <ListItemText
          primary={(
            <Translate
              value='surveys.notifications.surveyCompleted'
              user={user ? `${user.firstName} ${user.lastName}` : ''}
              survey={surveyName}
            />
)}
        />
      </ListItem>
    )
  }
  return result
}
