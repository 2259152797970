// @flow
import React from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

// A function you can extract and put into its own module.
// Yes, 15 lines of code, it's all you need.

const expandStyle = theme => ({
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    }),
    marginLeft: 'auto'
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
})

type ExpandButtonStateType = {
  expanded: boolean
}

type ExpandButtonPropsType = {
  classes: Object,
  onClick: Function
}

class ExpandButtonComp extends React.Component<ExpandButtonPropsType, ExpandButtonStateType> {
  state = {
    expanded: false
  }

  handleExpandClick = () => {
    const { expanded } = this.state
    this.setState({ expanded: !expanded })
  }

  render() {
    const { classes, onClick } = this.props
    return (
      <IconButton
        className={classNames(classes.expand, {
          [classes.expandOpen]: this.state.expanded
        })}
        onClick={(event: any) => {
          this.handleExpandClick()
          onClick(event)
        }}
        aria-expanded={this.state.expanded}
        aria-label='Show more'
      >
        <ExpandMoreIcon />
      </IconButton>
    )
  }
}

export default withStyles(expandStyle)(ExpandButtonComp)
