// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'
import Typography from '@material-ui/core/Typography'
import pathOr from 'ramda/src/pathOr'
import type { StudySummaryDtoType } from 'common-docdok/src/domain/survey/types/studySummaryDto'
import { studiesActions } from '../actions'
import DynamicFormDialog from '../../../../../common/DynamicFormDialog'
import { ADD_STUDY_DEVICE } from '../../../../../common/Security/actionNames'
import Action from '../../../../../common/Security/containers/action'
import { RaisedButton } from '../../../../../common/Mui'
import FormField from '../../../../../common/DynamicForm/components/fields/formField'

type PropsType = {
  study: StudySummaryDtoType,
  setAddParticipationCriteriaDialog: typeof studiesActions.setAddParticipationCriteriaDialog,
  showAddParticipationCriteriaDialog: boolean,
  disabled: boolean
}

export default class AddStudyParticipantCriteriaButton extends React.Component<PropsType> {
  renderDialog = (def: Object) => (
    <>
      <Typography>
        <Translate value='studies.actions.addParticipationCriterias.howto' />
      </Typography>
      <FormField {...def} name='inclusions' />
      <FormField {...def} name='exclusions' />
    </>
  )

  render() {
    const { setAddParticipationCriteriaDialog, showAddParticipationCriteriaDialog, study, disabled } = this.props

    const inclusions = pathOr([], ['studyParameters', 'inclusionCriterias'])(study).join(';\n\n')
    const exclusions = pathOr([], ['studyParameters', 'exclusionCriterias'])(study).join(';\n\n')

    return (
      <Action name={ADD_STUDY_DEVICE}>
        <RaisedButton
          id='addStudyDevice-button'
          disabled={disabled}
          label={<Translate value='studies.actions.addParticipationCriterias.title' />}
          onClick={() => {
            setAddParticipationCriteriaDialog(true)
          }}
        />

        <DynamicFormDialog
          open={showAddParticipationCriteriaDialog}
          handleClose={() => {
            setAddParticipationCriteriaDialog(false)
          }}
          titleKey='studies.actions.addParticipationCriterias.title'
          form='addParticipationCriterias'
          render={this.renderDialog}
          saveLabelKey='studies.actions.addParticipationCriterias.save'
          fullWidth
          maxWidth='sm'
          initialValues={{
            study,
            inclusions,
            exclusions
          }}
          fields={{
            inclusions: {
              type: 'string',
              multiline: true,
              rowsMax: 13,
              fullWidth: true
            },
            exclusions: {
              type: 'string',
              multiline: true,
              rowsMax: 13,
              fullWidth: true
            }
          }}
        />
      </Action>
    )
  }
}
