// @flow
import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Translate } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { userActions } from 'common-docdok/src/domain/user/actions'
import type { PreferencesType } from 'common-docdok/src/domain/user/types/preferences'
import { billingActions as actions, billingActions } from '../actions'

type PropsType = {
  showReminder: boolean,
  setBillingReminder: typeof actions.setBillingReminder,
  updateUserPreferencesRequested: typeof userActions.updateUserPreferencesRequested,
  preferences: PreferencesType
}

function BillingReminderCheckbox(props: PropsType) {
  const { showReminder, setBillingReminder, updateUserPreferencesRequested, preferences } = props

  const onDisableBillingPreferenceChanged = (event: React.FormEvent<Checkbox>) => {
    const showBillingReminder = !event.target.checked
    setBillingReminder(showBillingReminder)
    updateUserPreferencesRequested({
      ...preferences,
      values: {
        ...preferences.values,
        showBillingReminder
      }
    })
  }

  return (
    <FormControlLabel
      control={(
        <Checkbox
          id='billing-requested-no-reminder'
          checked={!showReminder}
          onChange={onDisableBillingPreferenceChanged}
          value='checkedA'
        />
)}
      label={<Translate value='billing.requested.noReminder' />}
    />
  )
}

const mapStateToProps = (state: StoreType) => ({
  preferences: state.profile.preferences,
  showReminder: state.billing.showReminder
})

export default connect(
  mapStateToProps,
  {
    setBillingReminder: billingActions.setBillingReminder,
    updateUserPreferencesRequested: userActions.updateUserPreferencesRequested
  }
)(BillingReminderCheckbox)
