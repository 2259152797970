// @flow
import React from 'react'
import { connect } from 'react-redux'
import DatePicker from 'material-ui-pickers/DatePicker'
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider'
import 'moment/locale/de' // this is the important bit, you have to import the locale your'e trying to use.
import moment from 'moment'
import MomentUtils from 'material-ui-pickers/utils/moment-utils'
import { I18n, Translate } from 'react-redux-i18n'

import type { ReduxFormCompontenType } from './formField'

type PropsType = {
  locale: string,

  disabled?: boolean
} & ReduxFormCompontenType

class DatePickerField extends React.Component<PropsType> {
  static defaultProps = {
    disabled: false
  }

  datepicker: any

  openDatePicker() {
    if (this.datepicker) this.datepicker.openDialog()
  }

  render() {
    const {
      locale,
      disabled,
      input: { name, value, onChange, onBlur, onFocus },
      meta: { touched, error, form },
      minDate
    } = this.props
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale={locale}>
        <DatePicker
          onBlur={onBlur}
          onFocus={onFocus}
          id={`${form}-${name}`}
          data-test={`${form}-${name}`}
          keyboard
          label={<Translate value={`forms.${form}.${name}`} />}
          format={I18n.t('date.short')}
          mask={txt => (txt ? [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/] : [])}
          margin='normal'
          disabled={disabled}
          value={value || null}
          onChange={(momentDate) => {
            if (momentDate.isValid()) {
              onChange(momentDate.toDate())
            }
          }}
          disableOpenOnEnter
          animateYearScrolling={false}
          minDate={minDate || new Date(1900, 0, 1)}
          helperText={touched && error}
          error={Boolean(touched && error)}
        />
      </MuiPickersUtilsProvider>
    )
  }
}

export default connect((state: StoreType) => ({
  locale: state.i18n.locale
}))(DatePickerField)
