export default {
  en: {
    name: 'Studyparticipant',
    fields: {
      id: 'Id',
      participantId: 'Participant-Id',
      participantSubId: 'Participant-Sub-Id',
      studyId: 'Study',
      userRef: 'User',
      addedBy: 'Added by',
      Name: 'Name',
      gender: 'Gender',
      birthdate: 'Birthdate',
      createdDate: 'Date added',
      daysInStudy: 'Current Day of Study'
    }
  },
  de: {
    name: 'Studienteilnehmer',
    fields: {
      id: 'Id',
      participantId: 'Teilnehmer-Id',
      participantSubId: 'Teilnehmer-Unter-Id',
      studyId: 'Studie',
      userRef: 'Benutzer',
      addedBy: 'Hinzugefügt von',
      Name: 'Name',
      gender: 'Geschlecht',
      birthdate: 'Geburtsdatum',
      createdDate: 'Hinzugefügt am',
      daysInStudy: 'Tag der Studie'
    }
  }
}
