// @flow
import React from 'react'

import Paper from '@material-ui/core/Paper'
import { fields } from 'common-docdok/src/domain/user/types/keycloakEvent'
import { Translate } from 'react-redux-i18n'

import api from 'common-docdok/src/api'
import List from '@material-ui/icons/List'
import { browserHistory } from 'react-router'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import palette from '../../../../../styles/palette'

import EntityTable from '../../../../../common/EntityTable'
import { UserName } from '../../../../../common/UserName'

import { SHOW_EVENTS } from '../../../../../common/Security/actionNames'
import { GreyBar, Toolbar, PageTitle } from '../../../../../common/Static'

function mapObject(object, callback): Array<*> {
  return Object.keys(object)
    .filter(key => key !== 'userRef')
    .map((key, i) => callback(i, key, object[key]))
}

const SurveyRow = styled.div`
  display: flex;
  background-color: ${props => (props.lineNo % 2 === 0 ? palette.greyLight : 'white')};
`

const QuestionCell = styled.div`
  width: 50%;
  padding: 0.5rem;
`
const AnswerCell = styled.div`
  width: 50%;
  padding: 0.5rem;
`

export const showEventAction = {
  name: SHOW_EVENTS,
  icon: <List />,
  tooltipKey: 'events.tooltip',
  enabled: () => true,
  action: (row: Object) => {
    browserHistory.push(`/private/app/events/${row.userRef || row.uid}`)
  }
}

type PropsType = {
  params: {
    userRef: string
  }
}

type StateType = {
  selection: Array<any>
}

export default class Events extends React.Component<PropsType, StateType> {
  state = {
    selection: []
  }

  render() {
    const { userRef } = this.props.params

    return (
      <>
        <PageTitle>
          <UserName userRef={userRef} />
        </PageTitle>
        <Paper>
          <GreyBar>
            <Toolbar />
          </GreyBar>
          <EntityTable
            api={(...args) => api.getKeycloakEvents(userRef, ...args)}
            id='events'
            idField='time'
            entity='keycloakEvent'
            defaultSort='time'
            hideRowCheckbox
            selection={this.state.selection}
            columns={fields}
            onSelectionChanged={newSelection => this.setState({ selection: newSelection })}
          />
          <div style={{ paddingTop: '20px' }} />
          <Typography variant='h5' align='center'>
            Docdok Events
          </Typography>
          <EntityTable
            api={() => api.getDocdokEvents(userRef)}
            id='docdokEvents'
            idField='timestamp'
            entity='docdokEvent'
            defaultSort='timestamp'
            hasPagingSupport={false}
            showPageSizeOptions={false}
            defaultPageSize={100}
            hideRowCheckbox
            columns={{
              type: {
                renderer: (val: any, col: string, entity: string, row: Object) => {
                  let renderMe = val
                  if (val === 'noteworthyAction') {
                    renderMe = row.data.description
                  }
                  return <Translate value={`docdokEvents.${renderMe}`} />
                }
              },
              timestamp: {
                type: 'Time',
                clientSideSort: true
              },
              principal: {
                renderer: (val: any, col: string, entity: string, row: Object) => {
                  let renderMe = val
                  if (val !== 'noteworthyAction') {
                    renderMe = row.data.userRef
                  }
                  return <UserName userRef={renderMe} />
                }
              },

              data: {
                renderer: val => (
                  <>
                    {mapObject(val, (i, k, v) => (
                      <SurveyRow id={i}>
                        <QuestionCell>{k}</QuestionCell>
                        <AnswerCell>{v}</AnswerCell>
                      </SurveyRow>
                    ))}
                  </>
                )
              }
            }}
          />
        </Paper>
      </>
    )
  }
}
