// @flow
import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import Typography from '@material-ui/core/Typography'
import { Translate } from 'react-redux-i18n'
import { connect } from 'react-redux'
import compose from 'ramda/src/compose'
import { DialogHeader } from '../../Mui'
import { billingActions } from '../actions'
import { getDefaultCode, emergencyCodes, codePreconditions } from '../utils/emergencyCode'
import { type CodeType } from '../reducers'
import { Container } from '../../Static'
import EmergencyCodeSelection from '../components/EmergencyCodeSelection'
import BillingReminderCheckbox from './BillingReminderCheckbox'
import PhysicianTypeSelection from './PhysicianTypeSelection'
import TarmedCodeSelection from './TarmedCodeSelection'

type PropsType = {
  startBillingAccepted: typeof billingActions.startBillingAccepted,
  startBillingDeclined: typeof billingActions.startBillingDeclined,
  selectPhysicianType: typeof billingActions.selectPhysicianType,
  fullScreen: boolean,
  requested: boolean
}

type StateType = {
  tarmedCode: ?CodeType,
  emergencyCode: ?string
}

const getDefaultState = () => ({
  tarmedCode: undefined,
  emergencyCode: getDefaultCode(new Date(Date.now()))
})

class BillingDialog extends React.Component<PropsType, StateType> {
  state: StateType = getDefaultState()

  selectPhysicianType = (selectedPhysicianType: number) => {
    this.props.selectPhysicianType(selectedPhysicianType)
    this.setState(getDefaultState())
  }

  startBilling = () => {
    const { startBillingAccepted } = this.props
    const { tarmedCode, emergencyCode } = this.state
    if (tarmedCode) {
      const additionalCode = codePreconditions.includes(tarmedCode.code) && emergencyCode
        ? emergencyCodes[emergencyCode]
        : undefined
      startBillingAccepted(tarmedCode, additionalCode)
    }
  }

  render() {
    const { startBillingDeclined, fullScreen, requested } = this.props
    if (!requested) return null
    const { tarmedCode } = this.state

    return (
      <Dialog
        fullScreen={fullScreen}
        maxWidth='md'
        scroll='paper'
        open
        onEscapeKeyDown={startBillingDeclined}
      >
        <DialogHeader name='billing' onClose={startBillingDeclined}>
          <Translate value='billing.requested.title' />
        </DialogHeader>
        <DialogContent>
          <Typography gutterBottom>
            <Translate value='billing.requested.text' />
          </Typography>
          <BillingReminderCheckbox />

          <Container>
            <PhysicianTypeSelection onChange={this.selectPhysicianType} />
            <TarmedCodeSelection
              value={tarmedCode}
              onChange={newBillingPosition => this.setState({ tarmedCode: newBillingPosition })}
            />
            <EmergencyCodeSelection
              tarmedCode={tarmedCode}
              onChange={newValue => this.setState({ emergencyCode: newValue })}
            />
          </Container>
        </DialogContent>
        <DialogActions>
          <Button id='billing-requested-stop' onClick={startBillingDeclined}>
            <Translate value='billing.requested.stop' />
          </Button>
          <Button
            id='billing-requested-start'
            variant='contained'
            disabled={!this.state.tarmedCode}
            color='primary'
            onClick={this.startBilling}
          >
            <Translate value='billing.requested.start' />
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ billing: { requested } }: StoreType) => ({
  requested
})

export default compose(
  withMobileDialog(),
  connect(
    mapStateToProps,
    {
      selectPhysicianType: billingActions.selectPhysicianType,
      startBillingAccepted: billingActions.startBillingAccepted,
      startBillingDeclined: billingActions.startBillingDeclined
    }
  )
)(BillingDialog)
