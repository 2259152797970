// @flow
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import PauseIcon from '@material-ui/icons/PauseCircleFilled'
import PlayIcon from '@material-ui/icons/PlayCircleFilled'
import TimerIcon from '@material-ui/icons/Timer'
import React from 'react'
import { Translate } from 'react-redux-i18n'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { introActions } from '../../Intro/actions'
import palette from '../../../styles/palette'
import { billingActions } from '../actions'
import Action from '../../Security/containers/action'
import { BILLING } from '../../Security/actionNames'
import { tourIdentifiers } from '../../Intro/tours'
import { type SelectedCodeType, type CodeType } from '../reducers'
import BillingDialog from './BillingDialog'
import './style.scss'

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    max-width: 20vw;
  }
`
const Pink = styled.div`
  color: ${palette.pink};
`
/*
const ellipsis = keyframes`
  to {
    width: 20px;
  }
`

const Loading = styled.div`
  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ${ellipsis} steps(4, end) 900ms infinite;
    content: '\2026';
    width: 0px;
    color: rgb(255, 64, 129);
  }
`
*/

const TextContainer = styled.div`
  width: 250px;
  font-size: 12px;
`

const style = {
  dialogContent: { width: 800 },
  pink: { color: palette.pink },

  billingCode: { width: 450, maxWidth: 450 },
  billingCodeItem: {
    whiteSpace: 'normal'
  },
  stopBillingTooltip: {
    overflowWrap: 'break-word',
    whiteSpace: 'normal'
  }
}

type PropsType = {
  active: boolean,
  show: boolean,
  paused: boolean,
  billingPosition: SelectedCodeType,

  startBillingProposed: typeof billingActions.startBillingProposed,
  stopBilling: typeof billingActions.stopBilling,
  registerTour: typeof introActions.registerTour,
  unregisterTour: typeof introActions.unregisterTour
}

type TimerStateType = {
  position: ?CodeType
}

const getInitalState = () => ({
  position: undefined
})

class Timer extends React.Component<PropsType, TimerStateType> {
  state: TimerStateType = getInitalState()

  componentWillUnmount() {
    const { unregisterTour } = this.props
    unregisterTour(tourIdentifiers.timer)
  }

  componentDidMount() {
    const { registerTour } = this.props
    registerTour(tourIdentifiers.timer)
  }

  render() {
    const { active, startBillingProposed, stopBilling, show, paused, billingPosition } = this.props
    if (!show) return null

    let button = (
      <ButtonContainer>
        <Tooltip title={<Translate value='billing.deactivated' />} placement='bottom'>
          <IconButton
            color='primary'
            className='billing-start-stop-button'
            id='billing-start'
            onClick={startBillingProposed}
          >
            <PlayIcon />
          </IconButton>
        </Tooltip>
        <TextContainer>
          <Translate style={{ color: palette.blueDark }} value='billing.deactivated' />
        </TextContainer>
      </ButtonContainer>
    )

    if (paused) {
      button = (
        <ButtonContainer>
          <Tooltip title={<Translate value='billing.paused' />} placement='bottom'>
            <IconButton className='billing-start-stop-button' id='billing-paused'>
              <PauseIcon />
            </IconButton>
          </Tooltip>
          <TextContainer>
            <Translate value='billing.paused' style={{ color: palette.greyDark }} />
          </TextContainer>
        </ButtonContainer>
      )
    } else if (active) {
      button = (
        <ButtonContainer>
          <Tooltip
            placement='bottom'
            title={(
              <div style={style.stopBillingTooltip}>
                <Translate value='billing.activeToolTip' />
                <br />
                <div>{billingPosition.position.description}</div>
                <div>{billingPosition.additionalPos ? billingPosition.additionalPos.description : ''}</div>
              </div>
)}
          >
            <IconButton className='billing-start-stop-button' id='billing-stop' onClick={stopBilling} color='secondary'>
              <TimerIcon />
            </IconButton>
          </Tooltip>
          <TextContainer>
            <div className='loading'>
              <Translate style={style.pink} value='billing.active' />
            </div>
            <Pink id='billing-code'>
              {billingPosition.position.code}
              {billingPosition.additionalPos ? `, ${billingPosition.additionalPos.code}` : ''}
            </Pink>
          </TextContainer>
        </ButtonContainer>
      )
    }

    return (
      <Action name={BILLING}>
        <BillingDialog />
        {button}
      </Action>
    )
  }
}

const mapStateToProps = ({ billing: { active, paused, billingPosition, context } }: StoreType) => ({
  active,
  paused,
  billingPosition,
  show: context !== undefined
})

export default connect(
  mapStateToProps,
  {
    startBillingProposed: billingActions.startBillingProposed,
    stopBilling: billingActions.stopBilling,
    registerTour: introActions.registerTour,
    unregisterTour: introActions.unregisterTour
  }
)(Timer)
