// @flow
import React from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { entityLoaderActions } from 'common-docdok/src/common/EntityLoader/actions'
import { Translate } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { change } from 'redux-form'

import getEntitySpec from '../../EntitySpecs/getEntitySpec'
import getDisplayValue from '../../EntitySpecs/utils'
import type { FieldComponentPropsType } from '../../DynamicForm/components/fields/formField'

type PropsType = {
  result?: ?Array<Object>,

  getEntitiesRequested: typeof entityLoaderActions.getEntitiesRequested
} & FieldComponentPropsType

class EntitySelector extends React.Component<PropsType> {
  componentDidMount() {
    const {
      input: { name },
      meta: { form },
      getEntitiesRequested,
      propertyDefinition: { specName },
      language
    } = this.props

    const id = `${form}-${name}`
    const entitySpec = getEntitySpec(specName)
    if (specName === 'icdCodes') {
      getEntitiesRequested(id, entitySpec.api, 1000, 0, undefined, undefined, undefined, [language])
    } else {
      getEntitiesRequested(id, entitySpec.api, 1000, 0)
    }
  }

  setIntialValue(nextProps: PropsType) {
    const {
      result,
      input,
      propertyDefinition: { specName, selectFirst }
    } = nextProps
    if (!this.props.input.value && result && selectFirst) {
      if (result[0]) {
        const entity = result[0]
        const entitySpec = getEntitySpec(specName)
        const { identifier } = entitySpec
        input.onChange(entity[identifier])
      }
    }
  }

  componentWillReceiveProps(nextProps: PropsType) {
    this.setIntialValue(nextProps)
  }

  render() {
    const {
      result,
      input,
      meta: { touched, error, form },
      propertyDefinition: { specName },
      changeForm
    } = this.props
    const { name } = input
    const entitySpec = getEntitySpec(specName)
    const { identifier } = entitySpec

    let currentValue = input.value
    const values = result || []

    if (specName === 'themes' && !currentValue) {
      // select default theme
      const defaultTheme = values ? values.find((v) => v && v.name === 'Default') : {}
      currentValue = defaultTheme ? defaultTheme.id : ''
      if (currentValue) {
        changeForm('uploadSurvey', 'themeId', currentValue)
        changeForm('updateSurvey', 'themeId', currentValue)
      }
    }

    return (
      <TextField
        margin='normal'
        select
        id={`${form}-${name}`}
        label={<Translate value={`forms.${form}.${name}`} />}
        error={touched && error}
        helperText={touched && error}
        {...input}
        value={currentValue || ''}
        onChange={event => input.onChange(event.target.value)}
      >
        {values.map(entity => (
          <MenuItem key={entity[identifier]} value={entity[identifier]}>
            {getDisplayValue(entity, entitySpec)}
          </MenuItem>
        ))}
      </TextField>
    )
  }
}

const mapStateToProps = (state: StoreType, ownProps: FieldComponentPropsType) => {
  const {
    input: { name },
    meta: { form }
  } = ownProps
  const id = `${form}-${name}`
  return {
    ...state.entityLoader[id],
    language: state.i18n.locale
  }
}

export default connect(
  mapStateToProps,
  {
    ...entityLoaderActions,
    changeForm: change
  }
)(EntitySelector)
