// @flow
import React from 'react'
import pathSatisfies from 'ramda/src/pathSatisfies'
import path from 'ramda/src/path'
import styled from 'styled-components'
import type { MessageDtoType } from 'common-docdok/src/domain/messaging/types/messageDto'
import MetaInfoOverlay from './metaInfoOverlay'

import VideoDialog from '../../PlayVideoDialog'

const Videodiv = styled.div`
  overflow: hidden;
  background-color: white;
  overflow: hidden;
  position: relative;
  margin: 5px 5px 5px 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
`
const ChatImage = styled.img`
  object-fit: scale-down
  max-width: 50vw;
  max-height: 20vh;
  border: 0 none;

  text-decoration: none;
  cursor: pointer;
  margin: none;
`
const VideoView = styled.div`
  background-color: black;
  overflow: hidden;
  position: relative;
  margin: 5px 5px 5px 5px;
  border-radius: 10px;
`

type PropsType = {
  message: MessageDtoType,
  directRender?: boolean
}

type StateType = {
  showVideoDialog: boolean
}

class VideoMessage extends React.Component<PropsType, StateType> {
  state = { showVideoDialog: false }

  toggleShowVideoDialog = () => {
    this.setState((prevState) => ({
      showVideoDialog: !prevState.showVideoDialog
    }))
  }

  render() {
    const { message, directRender } = this.props
    if (!message.mediaResource) return null

    const isVideo = pathSatisfies((mimeType) => mimeType.includes('video'), [
      'message',
      'mediaResource',
      'mimeType'
    ])(this.props)

    const videoUrl = path(['message', 'mediaResource', 'originalUrl'])(
      this.props
    )

    return (
      <div>
        {isVideo && !directRender && (
          <Videodiv
            id={`video-${message.uuid}`}
            style={{ textDecoration: 'none' }}
            onClick={this.toggleShowVideoDialog}
          >
            <MetaInfoOverlay
              show
              isImage={false}
              meta={message.meta}
              color="black"
            />
            <ChatImage src="/messaging/playVideo.jpeg" alt="message.text" />
          </Videodiv>
        )}
        <VideoDialog
          showVideoDialog={this.state.showVideoDialog}
          videoUrl={videoUrl}
          closeDialogFn={this.toggleShowVideoDialog}
          videoTitle={message.text}
        />
        {directRender && videoUrl && isVideo ? (
          <VideoView>
            <video
              height="200"
              width="250"
              src={videoUrl}
              controls
            >
              <track src="" kind="captions" srcLang="en" label="english_captions" />
            </video>
          </VideoView>
        ) : null}
      </div>
    )
  }
}
VideoMessage.defaultProps = {
  directRender: false
}
export default VideoMessage
