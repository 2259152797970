// @flow
import { put, call, takeEvery } from 'redux-saga/effects'
import type { ExtractReturn, GenType } from '../../../types'

import api from '../../../api/'

import { healthrelationActions, healthrelationActionTypes } from '../actions'
import { userActionTypes } from '../../user/actions'

// -- APPEND GENERATORS HERE --
function* deleteClinic(action: ExtractReturn<typeof healthrelationActions.deleteClinicRequested>): GenType {
  try {
    const {
      payload: { clinicId }
    } = action
    yield call(api.deleteClinic, clinicId)
    yield put(healthrelationActions.deleteClinicSucceeded(clinicId))
  } catch (error) {
    yield put(healthrelationActions.deleteClinicFailed(error))
  }
}

function* updateClinic(action: ExtractReturn<typeof healthrelationActions.updateClinicRequested>): GenType {
  try {
    const result = yield call(api.updateClinic, action.payload)
    yield put(healthrelationActions.updateClinicSucceeded(result.data))
  } catch (error) {
    yield put(healthrelationActions.updateClinicFailed(error))
  }
}

function* getClinicMine(): GenType {
  try {
    const result = yield call(api.getClinicMine)
    yield put(healthrelationActions.getClinicMineSucceeded(result.data))
  } catch (error) {
    yield put(healthrelationActions.getClinicMineFailed(error))
  }
}

function* loadClinics(): GenType {
  try {
    const result = yield call(api.getClinics)
    yield put(healthrelationActions.loadClinicsSucceeded(result.data))
  } catch (error) {
    yield put(healthrelationActions.loadClinicsFailed(error))
  }
}

function* getClinic(action: ExtractReturn<typeof healthrelationActions.setAvailabilityRequested>): GenType {
  try {
    const result = yield call(api.getClinic, action.payload)
    yield put(healthrelationActions.getClinicSucceeded(result.data))
  } catch (error) {
    yield put(healthrelationActions.getClinicFailed(error))
  }
}

function* getAvailabilities(): GenType {
  try {
    const result = yield call(api.getAvailabilities)
    yield put(healthrelationActions.getAvailabilitiesSucceeded(result.data))
  } catch (error) {
    yield put(healthrelationActions.getAvailabilitiesFailed(error))
  }
}

function* setAvailability(action: ExtractReturn<typeof healthrelationActions.setAvailabilityRequested>): GenType {
  try {
    const { type, message } = action.payload
    const result = yield call(api.setAvailability, type, message)
    yield put(healthrelationActions.setAvailabilitySucceeded(result.data))
  } catch (error) {
    yield put(healthrelationActions.setAvailabilityFailed(error))
  }
}

function* createClinic(action: ExtractReturn<typeof healthrelationActions.createClinicRequested>): GenType {
  try {
    const clinic = action.payload
    const result = yield call(api.createClinic, clinic)
    yield put(healthrelationActions.createClinicSucceeded(result.data))
  } catch (error) {
    yield put(healthrelationActions.createClinicFailed(error))
  }
}

function* getClinicOfPatient(user): GenType {
  try {
    const result = yield call(api.getClinicMine, {
      userRef: user.payload.uid
    })
    yield put(healthrelationActions.getClinicOfPatientSucceeded(result.data))
  } catch (error) {
    yield put(healthrelationActions.getClinicOfPatientFailed(error))
  }
}

export default function clinicSaga(): Array<GenType> {
  return [
    // -- APPEND TAKES HERE --
    takeEvery(healthrelationActionTypes.UPDATE_CLINIC_REQUESTED, updateClinic),
    takeEvery(healthrelationActionTypes.GET_CLINIC_MINE_REQUESTED, getClinicMine),
    takeEvery(healthrelationActionTypes.LOAD_CLINICS_REQUESTED, loadClinics),
    takeEvery(healthrelationActionTypes.GET_CLINIC_REQUESTED, getClinic),
    takeEvery(healthrelationActionTypes.GET_AVAILABILITIES_REQUESTED, getAvailabilities),
    takeEvery(healthrelationActionTypes.SET_AVAILABILITY_REQUESTED, setAvailability),
    takeEvery(healthrelationActionTypes.CREATE_CLINIC_REQUESTED, createClinic),
    takeEvery(healthrelationActionTypes.DELETE_CLINIC_REQUESTED, deleteClinic),
    takeEvery(userActionTypes.PROFILE_FETCH_SUCCEEDED, getClinicOfPatient)
  ]
}
