// @flow
import React from 'react'
import { connect } from 'react-redux'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import { browserHistory } from 'react-router'

import type { MessageDtoType } from 'common-docdok/src/domain/messaging/types/messageDto'
import UserAvatar from '../../UserAvatar'


const mapStateToProps = (state: StoreType, ownProps: { message: MessageDtoType }) => {
  const user = state.userCache.users[ownProps.message.userRef]
  const { patientuuid } = ownProps.message.meta
  return { patientuuid, user }
}

export default connect(mapStateToProps)((props) => {
  let result = null
  if (props.patientuuid && props.user) {
    result = (
      <ListItem
        button
        onClick={() => {
          browserHistory.push(`/private/app/patients/${props.patientuuid}`)
          if (props.closeRequest) props.closeRequest()
        }}
      >
        <UserAvatar
          size={34}
          src={props.user ? props.user.avatarPicture : undefined}
          name={props.user ? props.user.firstName : ''}
        />
        <ListItemText
          primary={props.message.text}
          secondary={`${props.user.firstName} ${props.user.lastName}`}
        />
      </ListItem>
    )
  }
  return result
})
