// @flow
import { fields as participantFields } from 'common-docdok/src/domain/survey/types/studyParticipantDto'
import { fields as patientFields } from 'common-docdok/src/domain/healthrelation/types/patientDto'
import { Link } from 'react-router'
import { surveyActionTypes } from 'common-docdok/src/domain/survey/actions'
import { Translate } from 'react-redux-i18n'
import api from 'common-docdok/src/api'
import React from 'react'
import type { StudyDtoType } from 'common-docdok/src/domain/survey/types/studyDto'
import Paper from '@material-ui/core/Paper'
import type { StudyParticipantDtoType } from 'common-docdok/src/domain/survey/types/studyParticipantDto'
import type { PatientDtoType } from 'common-docdok/src/domain/healthrelation/types/patientDto'
import dateUtils from 'common-docdok/src/utils/dateUtils'
import { ADD_STUDY_PARTICIPANT } from '../../../../../common/Security/actionNames'
import { studiesActions } from '../actions'
import Action from '../../../../../common/Security/containers/action'
import AddStudyParticipantButton from './AddStudyParticipantButton'
import EntityTable from '../../../../../common/EntityTable'
import studyLoader from './studyLoader'
import { UserNameComp as UserName } from '../../../../../common/UserName'
import { TextLine, PageTitle, GreyBar, Toolbar, ToolbarGroup, Buttons } from '../../../../../common/Static'

type PropsType = {
  params: { studyId: number },
  setAddStudyParticipantDialog: typeof studiesActions.setAddStudyParticipantDialog,
  showAddStudyParticipant: boolean,
  study: StudyDtoType
}

class StudyParticipants extends React.Component<PropsType> {
  render() {
    const {
      params: { studyId },
      setAddStudyParticipantDialog,
      showAddStudyParticipant,
      study
    } = this.props
    if (!study) return null

    return (
      <div>
        <PageTitle>
          <Translate value='studies.participants.title' />
        </PageTitle>
        <Paper>
          <GreyBar>
            <Toolbar>
              <ToolbarGroup />
              <Buttons>
                <Action name={ADD_STUDY_PARTICIPANT}>
                  <AddStudyParticipantButton
                    disabled={study.status !== 'ACTIVE'}
                    studyId={studyId}
                    setAddStudyParticipantDialog={setAddStudyParticipantDialog}
                    showAddStudyParticipant={showAddStudyParticipant}
                  />
                </Action>
              </Buttons>
            </Toolbar>
          </GreyBar>

          <EntityTable
            api={() => api.getStudyParticipants(studyId)}
            id='studyParticipants'
            entity='studyParticipant'
            hideRowCheckbox
            refreshActions={[surveyActionTypes.ADD_STUDY_PARTICIPANT_SUCCEEDED]}
            columns={{
              name: {
                orderBy: 'lastName',
                renderer: (val: any, col: string, entity: string, row: StudyParticipantDtoType & PatientDtoType) => (
                  <TextLine>
                    <Link to={`/private/app/patients/${row.uuid}`}>
                      <UserName entity={row} />
                    </Link>
                  </TextLine>
                )
              },

              gender: patientFields.gender,
              birthdate: patientFields.birthdate,
              participantId: participantFields.participantId,
              participantSubId: participantFields.participantSubId,
              createdDate: participantFields.createdDate,
              daysInStudy: {
                renderer: (val: any, col: string, entity: string, row: StudyParticipantDtoType) => {
                  const diff = dateUtils.getDiffDays(row.createdDate, new Date())
                  return diff + 1
                }
              }
            }}
          />
        </Paper>
      </div>
    )
  }
}

export default studyLoader(StudyParticipants)
