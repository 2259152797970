// @flow
import { put, takeEvery, select } from 'redux-saga/effects'
import { stopSubmit } from 'redux-form'
import sorters from 'common-docdok/src/utils/sorters'
import {
  healthrelationActionTypes,
  healthrelationActions
} from 'common-docdok/src/domain/healthrelation/actions'
import { messagingActionTypes, messagingActions } from 'common-docdok/src/domain/messaging/actions'
import type { GenType, ExtractReturn } from 'common-docdok/src/types'
import { browserHistory } from 'react-router'
import { asyncAction } from 'common-docdok/src/utils/sagaUtils'

import { infoPanelActions } from '../../../../../common/InfoPanel/actions'
import {
  dynamicFormActionTypes,
  type SubmitFormType
} from '../../../../../common/DynamicForm/actions'


import { messagesActions } from '../actions'

export function* createConversationGen(action: SubmitFormType): GenType {
  const {
    payload: { name, data }
  } = action
  if (name === 'createConversation') {
    const { title, users } = data
    const userRefs = users.map(u => u.userRef)
    const winner = yield asyncAction(messagingActions.createConversationRequested(title, userRefs))

    if (winner.succeeded) {
      yield put(messagesActions.setShowCreateConversation(false))
      yield put(infoPanelActions.showMessage('messages.createConversation.created'))
      browserHistory.push(
        `/private/app/messages/conversation/${winner.succeeded.payload.conversation.id}`
      )
    } else {
      yield put(stopSubmit(name, winner.failed.payload.error))
    }
  }
}

function* openFirstConv(conversationDtos: Array<*>): GenType {
  if (conversationDtos.length > 0) {
    const dto: any = conversationDtos[0]
    const location = yield select((state: StoreType) => state.location)
    if (location.match(/\/messages/)) {
      browserHistory.push(`/private/app/messages/conversation/${dto.id}`)
    }
  }
}

export function* openLatestConversation({ payload }: Object): GenType {
  const removedSubscription = payload
  const conversations = yield select((state: StoreType) => state.messaging.conversations)
  const selectdConversation = yield select(
    (state: StoreType) => state.messaging.selectedConversation
  )
  if (!selectdConversation || !conversations[selectdConversation]) {
    const conversationDtos = Object.values(conversations)
      .filter((conv: any) => conv.subscriptionId !== removedSubscription)
      .sort((conv1, conv2) => {
        const c1: any = conv1
        const c2: any = conv2
        return sorters.sortByValue(new Date(c1.latestPostedAt), new Date(c2.latestPostedAt), true)
      })
    yield openFirstConv(conversationDtos)
  }
}

export function* closeMassSendingOnSuccess({
  payload
}: ExtractReturn<typeof healthrelationActions.massSendingSucceeded>): GenType {
  if (!payload.simulation) {
    yield put(messagesActions.setShowMassSendingDialog(false))
    yield put(infoPanelActions.showMessage('messages.massSending.sentStat', payload))
  }
}

export function* profileFetched(): GenType {
  yield put(messagingActions.getSubscriptionsRequested(true))
}

export default function subscriptionsSaga(): Array<GenType> {
  return [
    takeEvery(dynamicFormActionTypes.SUBMIT_FORM, createConversationGen),
    takeEvery(messagingActionTypes.REMOVE_SUBSCRIPTION_SUCCEEDED, openLatestConversation),
    takeEvery(healthrelationActionTypes.MASS_SENDING_SUCCEEDED, closeMassSendingOnSuccess)
  ]
}
