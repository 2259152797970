export default {
  en: {
    fields: {
      patientRef: 'Patient',
      waist: 'Waist',
      hip: 'Hip',
      weight: 'Weight',
      height: 'Height',
      whr: 'WHR',
      date: 'Creation Date'
    }
  },
  de: {
    fields: {
      patientRef: 'Patient',
      waist: 'Taille',
      hip: 'Hüfte',
      weight: 'Gewicht',
      height: 'Höhe',
      whr: 'WHR',
      date: 'Erstellungsdatum'
    }
  }
}
