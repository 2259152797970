// @flow

export const uploadSurveyDialogActionTypes = {
  SET_UPLOAD_SURVEY_DIALOG: 'SET_UPLOAD_SURVEY_DIALOG'
}
export type UploadSurveyDialogActionType = $Keys<typeof uploadSurveyDialogActionTypes>
export const uploadSurveyDialogActions = {
  setUploadSurveyDialog(show: boolean) {
    return {
      type: uploadSurveyDialogActionTypes.SET_UPLOAD_SURVEY_DIALOG,
      payload: show
    }
  }
}
