/**
 * @flow
 */
export const createUserDialogActionTypes = {
  SET_CREATE_USER_DIALOG: 'SET_CREATE_USER_DIALOG'
}

export type CreateUserDialogActionType = $Keys<typeof createUserDialogActionTypes>

export const createUserDialogActions = {
  setCreateUserDialog(show: boolean) {
    return {
      type: createUserDialogActionTypes.SET_CREATE_USER_DIALOG,
      payload: show
    }
  }
}
