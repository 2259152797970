// @flow

import propOr from 'ramda/src/propOr'
import pipe from 'ramda/src/pipe'
import prop from 'ramda/src/prop'
import isNil from 'ramda/src/isNil'
import not from 'ramda/src/not'
import gte from 'ramda/src/gte'
import { eqUserRef as eqUserRefOnObject } from './subscription'
import { getSubjectUserRef } from './conversation'
import type { MessageDtoType } from '../domain/messaging/types/messageDto'
/**
 * checks userRef on message
 * @param {string} userRef - the userRef which should be used for the check
 * @return a function which takes a {MessageDtoType}
 */
export const eqUserRef = eqUserRefOnObject

/**
 * checks userRef on message is equal the conversationSubject
 * @param {ConversationDto} conversation - the conversation with
 *        meta.userRef which should be used for the check
 * @return a function which takes a {MessageDtoType}
 */
export const isMessageByPatient = pipe(getSubjectUserRef, eqUserRef)

/**
 * checks if message has an id which means it's saved
 * @param {MessageDtoType} message
 * @return true or false
 */
export const isMessageSaved = pipe(prop('id'), isNil, not)

/**
 * returns the sequenceNo or -1 if not set
 * @param {MessageDtoType} message
 * @return {number} the number or -1
 */
export const getSequenceNo = propOr(-1, 'sequenceNo')

/**
 * returns true if the sequenceNo of the message is smaller or equal the given number
 * @param {MessageDtoType} message
 * @return {boolean} result
 */
export const isSequenceNoLtE = (nbr: number) => pipe(getSequenceNo, gte(nbr))

/**
 * returns true if the sequenceNo of the message is smaller or equal the given number
 * and the message is saved (has id)
 * @param sequenceNo the minimum sequeneceNo requirement
 * @return a function which takes a {MessageDtoType} and tells if the message is read
 */
export const isMessageRead = (sequenceNo: number) => (message: MessageDtoType) =>
  isMessageSaved(message) && isSequenceNoLtE(sequenceNo)(message)
