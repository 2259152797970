export const fieldNames = {
  id: 'id',
  patientRef: 'patientRef',
  waist: 'waist',
  hip: 'hip',
  weight: 'weight',
  height: 'height',
  whr: 'whr',
  date: 'date'
}

export const fields = {
  id: {
    type: 'number',
    format: 'int64'
  },
  patientRef: { type: 'string' },
  waist: { type: 'float' },
  hip: { type: 'float' },
  weight: [{ type: 'float' }],
  height: [{ type: 'float' }],
  whr: { type: 'float' },
  date: { type: 'ZonedDateTime' }
}

export type PatientMeassurmentsDtoType = {
    id: number,
    patientRef: string,
    waist: number,
    hip: number,
    weight: number,
    height: number,
    whr: number,
    date: string
}
