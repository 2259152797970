// @flow
import React from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { Translate } from 'react-redux-i18n'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import Delete from '@material-ui/icons/Delete'
import AddAlarm from '@material-ui/icons/AddAlarm'
import { Field } from 'redux-form'
import type { ReduxFormCompontenType } from '../../DynamicForm/components/fields/formField'

export type RecurrenceType = {
  multiplier: number,
  delay: number
}

type RecurrencePropsType = {
  recurrence: RecurrenceType,
  onChange: (rec: RecurrenceType) => void,
  onRemove: (rec: RecurrenceType) => void,
  error: ?string,
  disableDelete: boolean,
  form: string,
  name: string,
  index: number
}

function Recurrence(props: RecurrencePropsType) {
  const { onChange, onRemove, recurrence, error, disableDelete, form, name, index } = props
  const { multiplier, delay } = recurrence
  return (
    <div style={{ display: 'flex' }} id={`${form}-${name}-${index}`}>
      <Tooltip title={<Translate value={`forms.${form}.${name}.removeRecurrence`} />}>
        <div>
          <IconButton
            color='primary'
            id={`${form}-${name}-${index}-remove`}
            disabled={disableDelete}
            onClick={() => {
              onRemove(recurrence)
            }}
          >
            <Delete />
          </IconButton>
        </div>
      </Tooltip>
      <TextField
        id={`${form}-${name}-${index}-delay`}
        label={<Translate value={`forms.${form}.${name}.delay`} />}
        style={{ width: 100 }}
        value={delay}
        onChange={(event) => {
          onChange({ delay: event.target.value, multiplier })
        }}
        error={error}
        helperText={error && <Translate value={`forms.${form}.${name}.error.${error}`} />}
      />
      <TextField
        id={`${form}-${name}-${index}-unit`}
        label={<Translate value={`forms.${form}.${name}.unit`} />}
        onChange={(event) => {
          onChange({ delay, multiplier: event.target.value })
        }}
        value={multiplier}
        select
      >
        <MenuItem id={`${form}-${name}-${index}-unit-day`} value={1}>
          <Translate value={`forms.${form}.${name}.unitOpts.day`} />
        </MenuItem>
        <MenuItem id={`${form}-${name}-${index}-unit-week`} value={7}>
          <Translate value={`forms.${form}.${name}.unitOpts.week`} />
        </MenuItem>
      </TextField>
    </div>
  )
}

class CustomRecurrencesComponent extends React.Component<ReduxFormCompontenType> {
  componentWillMount() {
    const {
      input: { onChange, value }
    } = this.props
    if (!value) {
      onChange([
        {
          multiplier: 1,
          delay: 1
        }
      ])
    }
  }

  static sortRecurrences(r1: RecurrenceType, r2: RecurrenceType) {
    const re1Delay = r1.delay * r1.multiplier
    const re2Delay = r2.delay * r2.multiplier
    return re1Delay - re2Delay
  }

  getError(rec: RecurrenceType, index: number) {
    const {
      input: { value },
      meta: { error, touched }
    } = this.props
    const recurrences = value || []
    const hasValue = !isNaN(rec.delay) && rec.delay !== ''
    const isLastItem = index === recurrences.length - 1
    const errorVal = (touched || hasValue) && isLastItem ? error : undefined
    return errorVal
  }

  render() {
    const {
      input: { onChange, value, name },
      meta: { error, form }
    } = this.props

    const recurrences = value || []
    const recurrenceSelection = recurrences.map((rec: RecurrenceType, index: number) => (
      <Recurrence
        key={index}
        form={form}
        name={name}
        index={index}
        disableDelete={value.length < 2}
        error={this.getError(rec, index)}
        recurrence={rec}
        onChange={(newRec: RecurrenceType) => {
          const newRecurrences = recurrences.slice()
          newRecurrences[index] = newRec
          onChange(newRecurrences)
        }}
        onRemove={(toRemove: RecurrenceType) => {
          const newRecurrences = recurrences.filter(r => r !== toRemove)

          onChange(newRecurrences)
        }}
      />
    ))

    return (
      <div style={{ minHeight: 200, width: 400 }}>
        <Translate value={`forms.${form}.${name}.customRecurrenceHint`} />
        {recurrenceSelection}
        <Button
          color='primary'
          style={{ float: 'right' }}
          disabled={error}
          onClick={() => {
            const currentReccurrecs = value.sort(CustomRecurrencesComponent.sortRecurrences)
            const latestRecurrence = currentReccurrecs[value.length - 1]

            onChange([
              ...currentReccurrecs,
              {
                multiplier: latestRecurrence.multiplier,
                delay: Number(latestRecurrence.delay) + 1
              }
            ])
          }}
        >
          <AddAlarm />
          <Translate value={`forms.${form}.${name}.addRecurrence`} />
        </Button>
      </div>
    )
  }
}

type PropsType = {
  name: string
}

export default function CustomRecurrences({ name }: PropsType) {
  return <Field name={name} component={CustomRecurrencesComponent} />
}
