// @flow

import React from 'react'

import TextField from '@material-ui/core/TextField'
import Search from '@material-ui/icons/Search'

import debounce from 'lodash/debounce'
import { I18n } from 'react-redux-i18n'
import Extensible from './Extensible'

type PropsType = {
  id: string,
  search: (searchTerm: string) => void,
  label?: string,
  errorText?: string,
  expandToRight?: boolean,
  onClose?: Function
}

type StateType = {
  searchTerm: string
}

export default class SearchField extends React.Component<PropsType, StateType> {
  static defaultProps = {
    label: undefined,
    errorText: undefined,
    expandToRight: false,
    onClose: () => {}
  }

  extensible: any = undefined

  state = { searchTerm: '' }

  debouncedSearch = debounce(this.triggerSearch, 1000)

  selectedCount: number

  triggerSearch() {
    this.props.search(this.state.searchTerm)
  }

  resetSearchTerm() {
    this.setState({ searchTerm: '' })
  }

  // called by searchbar
  toggleShow() {
    this.setState({ searchTerm: '' })
    this.extensible.toggleShow()
  }

  render() {
    const { id, errorText, expandToRight, onClose } = this.props

    return (
      <Extensible
        ref={(ref) => {
          if (ref) this.extensible = ref
        }}
        id={id}
        tooltip={I18n.t('actions.search')}
        expandToRight={expandToRight}
        icon={<Search color='primary' />}
        onToggle={(toggle: boolean) => {
          if (!toggle) {
            this.resetSearchTerm()
            if (onClose) {
              onClose()
            }
          }
        }}
        inputComponent={(
          <TextField
            id={`${id}-search-field`}
            fullWidth
            onChange={(event) => {
              this.setState({ searchTerm: event.target.value })
              this.debouncedSearch()
            }}
            error={Boolean(errorText)}
            helperText={errorText}
            value={this.state.searchTerm}
            /*          floatingLabelText={label}
            floatingLabelFixed */
            placeholder={I18n.t('actions.search')}
            autoFocus
          />
)}
      />
    )
  }
}
