export default {
  en: {
    name: 'Patient',
    fields: {
      name: 'Name',
      birthdate: 'Birthdate',
      email: 'Email',
      firstName: 'Firstname',
      gender: 'Gender',
      genderOpts: {
        MALE: 'Male',
        FEMALE: 'Female',
        DIVERS: 'Divers'
      },
      lastName: 'Lastname',
      langKey: 'Language',
      langKeyOpts: {
        en: 'English',
        de: 'German',
        fr: 'French',
        it: 'Italian'
      },
      mobileNumber: 'Mobile',
      primaryPhysicianUuid: 'Primary Physician',
      salutation: 'Salutation',
      salutationOpts: {
        Mr: 'Mr',
        Ms: 'Ms',
        Mrs: 'Mrs.',
        Prof: 'Prof',
        Dr: 'Dr',
        PractMed: 'Pract Med'
      },
      insuranceNumber: 'Social Securiy Number',
      lifelongId: 'Lifelong patient number'
    }
  },
  de: {
    name: 'Patient',
    fields: {
      name: 'Name',
      birthdate: 'Geburtsdatum',
      email: 'Email',
      firstName: 'Vorname',
      gender: 'Geschlecht',
      genderOpts: {
        MALE: 'männlich',
        FEMALE: 'weiblich',
        DIVERS: 'divers'
      },
      lastName: 'Nachname',
      mobileNumber: 'Mobiltelefon',
      langKey: 'Sprache',
      langKeyOpts: {
        en: 'Englisch',
        de: 'Deutsch',
        fr: 'Französisch',
        it: 'Italienisch'
      },
      primaryPhysicianUuid: 'Primärer Arzt',
      salutation: 'Anrede',
      salutationOpts: {
        Mr: 'Herr',
        Ms: 'Frau',
        Prof: 'Prof. Dr. med',
        Dr: 'Dr. med',
        PractMed: 'Prakt. med'
      },
      insuranceNumber: 'Sozialversicherungsnummer',
      lifelongId: 'Lebenslange Patientennummer'
    }
  }
}
