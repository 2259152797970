import { onlyTimeString } from 'common-docdok/src/utils/rgx'

export const scheduleSurveyFields = {
  doSchedule: {
    type: 'boolean',
    style: 'toggle'
  },
  startAtTitle: {
    title: 'forms.scheduleStudySurvey.startAtTitle'
  },
  patternTitle: {
    title: 'forms.scheduleStudySurvey.patternTitle'
  },
  timeTitle: {
    title: 'forms.scheduleStudySurvey.timeTitle'
  },
  startAt: {
    type: 'select',
    style: 'radio',
    noLabel: true
  },
  daysAfterAdded: {
    type: 'number'
  },
  pattern: {
    type: 'select',
    enum: ['daily', 'weekly', 'customRecurrence', 'noRecurrence']
  },
  interval: {
    type: 'number'
  },
  time: {
    type: 'Time'
  },
  numberOfRecurrences: {
    type: 'number'
  },
  canExpire: {
    type: 'boolean',
    style: 'toggle'
  },
  expireAfter: {
    type: 'string',
    pattern: onlyTimeString,
    patternNice: '1h/1d/1m'
  }
}
