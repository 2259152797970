// @flow
import React from 'react'
import type { ReduxFormCompontenType } from '../../DynamicForm/components/fields/formField'

export default function selectionError(props: ReduxFormCompontenType) {
  const { input: { name }, meta: { error, touched, form } } = props
  const id = `${form}-${name}`
  return touched ? (
    <div id={`${id}-error`} style={{ color: 'red' }}>
      {error}
    </div>
  ) : null
}
