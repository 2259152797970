/**
 * @flow
 */

import { type ContentDefinitionVmType } from 'common-docdok/src/domain/messaging/types/contentDefinitionVm'

export const catalogActionTypes = {
  // -- APPEND ACTION TYPES HERE --
  SET_SEND_CONTENT_DIALOG: 'SET_SEND_CONTENT_DIALOG',
  TOGGLE_CREATE_CATALOG_DIALOG: 'TOGGLE_CREATE_CATALOG_DIALOG'
}

export type CatalogActionType = $Keys<typeof catalogActionTypes>

export const catalogActions = {
  //  -- APPEND ACTIONS HERE --
  setSendContentDialog(payload: Array<ContentDefinitionVmType>) {
    return { type: catalogActionTypes.SET_SEND_CONTENT_DIALOG, payload }
  },

  toggleCreateCatalogDialog(payload: any) {
    return {
      type: catalogActionTypes.TOGGLE_CREATE_CATALOG_DIALOG,
      payload
    }
  }
}
