// @flow
import React from 'react'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import styled from 'styled-components'
import { messagingActions } from 'common-docdok/src/domain/messaging/actions'
import { getKeycloakClient } from 'common-docdok/src/common/Keycloak/keycloakProvider'

type TextMessagePropsType = {
  message: Object,
  color: string,
  postMessageRequested: messagingActions.postMessageRequested
}


const TextContainer = styled.div`
  font-weight: bold;
  text-align: left;
  word-break: break-word;
  hyphens: auto;
  white-space: normal;

  a {
    color: #daefff;
  }
  padding-left: 12px;
  padding-right: 12px;

  color: ${props => props.color};
`

function textMessage(props: TextMessagePropsType) {
  const { color } = props
  const {
    message: {
      meta: { invitationUrl, activateFor, /* useAuthorization, */ subtype }
    }
  } = props
  if (subtype !== 'videoInvitation') {
    return null
  }

  const userRef = getKeycloakClient().getUserId()
  if (userRef !== activateFor) {
    return null
  }

  const message = I18n.t('messages.videoConference.clickMe')
  const htmlMessage = `<a href=${invitationUrl} target='_self'
        rel='noopener noreferrer'>${message}</a>`


  return (
    <>
      <TextContainer
        color={color}
        dangerouslySetInnerHTML={{ __html: htmlMessage }}
      />
    </>
  )
}

export default connect(null, {
  postMessageRequested: messagingActions.postMessageRequested
})(textMessage)
