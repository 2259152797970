// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

type PropsType = {
  physicianTypeId: ?number,
  physicianTypes: Array<Object>,
  onChange: Function
}

function PhysicianTypeSelection(props: PropsType) {
  const { physicianTypeId, physicianTypes, onChange } = props

  return (
    <TextField
      select
      id='billing-requested-type'
      label={<Translate value='billing.requested.type' />}
      value={physicianTypeId || ''}
      onChange={(event: any) => {
        const { value: newValue } = event.target
        const typeId = Number(newValue)
        onChange(typeId)
      }}
    >
      {physicianTypes.map(t => (
        <MenuItem key={t.id} value={t.id}>
          <span>{t.label}</span>
        </MenuItem>
      ))}
    </TextField>
  )
}

const mapStateToProps = ({ billing: { physicianTypes, physicianTypeId } }: StoreType) => ({
  physicianTypes,
  physicianTypeId
})

export default connect(mapStateToProps)(PhysicianTypeSelection)
