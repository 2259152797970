// @flow
import type { ActionType, ExtractReturn } from 'common-docdok/src/types'

import { studiesActionTypes, studiesActions } from '../actions'
// State Definition
export type StudiesStateType = {
  showCreateStudy: boolean,
  showAddSurveyDialog: boolean,
  showAddStudyParticipant: boolean,
  showAddStudyDevice: boolean,
  showAddPhysicianDialog: boolean,
  showAddStudyCoordinatorDialog: boolean
}

const initialState = {
  showCreateStudy: false,
  showAddSurveyDialog: false,
  showAddStudyParticipant: false,
  showAddStudyDevice: false,
  showAddPhysicianDialog: false,
  showAddStudyCoordinatorDialog: false,
  showAddParticipationCriteriaDialog: false
}

function toggleCreateStudyDialog(state: StudiesStateType) {
  return { ...state, showCreateStudy: !state.showCreateStudy }
}

function toggleAddSurveyDialog(state: StudiesStateType) {
  return { ...state, showAddSurveyDialog: !state.showAddSurveyDialog }
}

function toggleAddPhysicianDialog(state: StudiesStateType) {
  return { ...state, showAddPhysicianDialog: !state.showAddPhysicianDialog }
}

function toggleAddStudyCoordiantorDialog(state: StudiesStateType) {
  return { ...state, showAddStudyCoordinatorDialog: !state.showAddStudyCoordinatorDialog }
}

function setAddStudyParicipantDialog(
  state: StudiesStateType,
  action: ExtractReturn<typeof studiesActions.setAddStudyParticipantDialog>
) {
  return { ...state, showAddStudyParticipant: action.payload }
}
function setAddStudyDeviceDialog(
  state: StudiesStateType,
  action: ExtractReturn<typeof studiesActions.setAddStudyDeviceDialog>
) {
  return { ...state, showAddStudyDevice: action.payload }
}

function setAddParticipationCriteriaDialog(
  state: StudiesStateType,
  action: ExtractReturn<typeof studiesActions.setAddStudyDeviceDialog>
) {
  return { ...state, showAddParticipationCriteriaDialog: action.payload }
}

const ACTION_HANDLERS = {
  [studiesActionTypes.TOGGLE_CREATE_STUDY_DIALOG]: toggleCreateStudyDialog,
  [studiesActionTypes.TOGGLE_ADD_SURVEY_DIALOG]: toggleAddSurveyDialog,
  [studiesActionTypes.TOGGLE_ADD_PHYSICIAN_DIALOG]: toggleAddPhysicianDialog,
  [studiesActionTypes.TOGGLE_ADD_STUDY_COORDINATOR_DIALOG]: toggleAddStudyCoordiantorDialog,
  [studiesActionTypes.SET_ADD_STUDY_PARTICIPANT_DIALOG]: setAddStudyParicipantDialog,
  [studiesActionTypes.SET_ADD_STUDY_DEVICE_DIALOG]: setAddStudyDeviceDialog,
  [studiesActionTypes.SET_ADD_PARTICIPATION_CRITERIA]: setAddParticipationCriteriaDialog
}

export default function studiesReducer(state: StudiesStateType = initialState, action: ActionType): StudiesStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
