/**
 * @flow
 */

export const headerActionsTypes = {
  // -- APPEND ACTION-TYPES HERE --
  DECLINE_TERMS: 'DECLINE_TERMS',
  SHOW_ACCEPTED_TERMS: 'SHOW_ACCEPTED_TERMS',
  HIDE_TERMS: 'HIDE_TERMS'
}

export type HeaderActionsType = $Keys<typeof headerActionsTypes>

export const headerActions = {
  // -- APPEND ACTIONS HERE --
  declineTerms() {
    return {
      type: headerActionsTypes.DECLINE_TERMS
    }
  },
  showAcceptedTerms(termsVersion: string) {
    return {
      type: headerActionsTypes.SHOW_ACCEPTED_TERMS,
      payload: termsVersion
    }
  },
  hideTerms() {
    return {
      type: headerActionsTypes.HIDE_TERMS
    }
  }
}
