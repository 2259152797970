/**
 * @flow
 */
export const conversationEditorActionTypes = {
  SET_EDIT_CONVERSATION: 'SET_EDIT_CONVERSATION'
}

export type ConversationEditorActionType = $Keys<typeof conversationEditorActionTypes>

export const conversationEditorActions = {
  setEditConversation(conversationId: number | boolean) {
    return {
      type: conversationEditorActionTypes.SET_EDIT_CONVERSATION,
      payload: conversationId
    }
  }
}
