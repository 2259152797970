// @flow
import React from 'react'
import { connect } from 'react-redux'
import compose from 'ramda/src/compose'

import { type StudyDtoType } from 'common-docdok/src/domain/survey/types/studyDto'
import { surveyActions } from 'common-docdok/src/domain/survey/actions'


type ReferencePropsType = {
  params: { studyId: number }
}
type StudyLoaderPropsType = {
  study: ?StudyDtoType,
  getStudyRequested: typeof surveyActions.getStudyRequested
} & ReferencePropsType
type ReturnCompType = React$ComponentType<ReferencePropsType & StudyLoaderPropsType>
type InputCompType = React$ComponentType<ReferencePropsType>

function studyLoader(WrappedComponent: InputCompType): ReturnCompType {
  return class HOC extends React.Component<ReferencePropsType & StudyLoaderPropsType> {
    static loadEntity(props: ReferencePropsType & StudyLoaderPropsType) {
      const {
        params: { studyId },
        study,
        getStudyRequested
      } = props
      if (studyId && !study) getStudyRequested(studyId)
    }

    componentDidMount() {
      HOC.loadEntity(this.props)
    }

    componentWillReceiveProps(newProsp: ReferencePropsType & StudyLoaderPropsType) {
      if (newProsp.params && this.props.params.studyId !== newProsp.params.studyId) {
        HOC.loadEntity(newProsp)
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}

const mapStateToProps = (state: StoreType, ownProps: ReferencePropsType) => {
  const {
    params: { studyId }
  } = ownProps
  const study = state.survey.studies[studyId]
  return { study }
}

export default compose(
  connect(
    mapStateToProps,
    { ...surveyActions }
  ),
  studyLoader
)
