// @flow
import React from 'react'
import type { MessageDtoType } from 'common-docdok/src/domain/messaging/types/messageDto'

type TextMessagePropsType = {
  message: MessageDtoType
}

function getYoutubeId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)
  if (match && match[2].length === 11) {
    return match[2]
  }
  return null
}

export default function textMessage(props: TextMessagePropsType) {
  const { message } = props
  const videoId = getYoutubeId(message.text.replace(/\n/g, ''))
  if (videoId) {
    return (
      <div style={{ marginTop: 15 }}>
        <iframe
          title={videoId}
          style={{}}
          width='100%'
          height='320'
          src={`https://www.youtube.com/embed/${videoId}`}
          frameBorder='0'
          allowFullScreen
        />
      </div>
    )
  }
  return null
}
