// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'
import type { MessagePropsType } from '../../Message'

export default function surveyInvitationlatestMessage(props: MessagePropsType) {
  let result = null
  const {
    user,
    message: {
      meta: { surveyName }
    }
  } = props
  if (user) {
    result = (
      <Translate
        value='surveys.notifications.surveyInvitation'
        user={user ? `${user.firstName} ${user.lastName}` : ''}
        survey={surveyName}
      />
    )
  }
  return result
}
