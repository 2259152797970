// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'
import { fields as patientFields } from 'common-docdok/src/domain/healthrelation/types/patientDto'
import pathOr from 'ramda/src/pathOr'
import styled from 'styled-components'
import { connect } from 'react-redux'
import DynamicFormDialog from '../../DynamicFormDialog'

import FormField from '../../DynamicForm/components/fields/formField'
import { Container, Spacer } from '../../Static'
import { createPatientButtonActions } from '../actions'

type PropsType = {
  showCreatePatientDialog: boolean,
  toggleCreatePatientDialog: typeof createPatientButtonActions.toggleCreatePatientDialog,
  hycareSolutionEnabledClinics: any,
  clinicMine: any,
}

const FullWidthContainer = styled.div`
  width: 100%;
`
const TwoRows = styled.div`
  width: 518px;
  @media (max-width: 768px) {
    width: 100vw;
  }
`

export function createPatientRenderer(def: Object, data: Object, isHycareEnabledClinic: Boolean) {
  return (
    <TwoRows>
      <Container vertical>
        <FullWidthContainer>
          <FormField {...def} name='isDependent' />

          <FormField {...def} name='salutation' />

          <Container>
            <FormField {...def} name='firstName' />

            <FormField {...def} name='lastName' />
          </Container>
          <Container>
            <FormField {...def} name='gender' />

            <FormField {...def} name='birthdate' />
          </Container>
          <Container>
            <FormField {...def} name='mobileNumber' />

            <FormField {...def} name='email' />
          </Container>
          {!data.isDependent && (
            <Container>
              <FormField {...def} name='langKey' />
              <FormField {...def} name='lifelongId' />
            </Container>
          )}
          {isHycareEnabledClinic && <FormField {...def} name='pepIntegration' />}
          {!data.pepIntegration && isHycareEnabledClinic && (
            <div>
              <Translate value='pepIntegrationNotes' />
            </div>
          )}
          {data.pepIntegration && isHycareEnabledClinic && (
            <Container>
              <FormField {...def} name='disease' />
            </Container>
          )}
        </FullWidthContainer>
        {data.isDependent && (
          <FullWidthContainer>
            <Spacer height={20} />
            <div>
              <hr />
              <Translate value='patients.createPatient.parentInfos' />
            </div>
            <Spacer height={20} />
            <FormField {...def} name='createNew' />
            <Spacer height={5} />
            {data.createNew ? (
              <FullWidthContainer>
                <FormField {...def} name='proxySalutation' />

                <Container>
                  <FormField {...def} name='proxyFirstName' />

                  <FormField {...def} name='proxyLastName' />
                </Container>
                <Container>
                  <FormField {...def} name='proxyMobileNumber' />

                  <FormField {...def} name='proxyEmail' />
                </Container>
                <Container>
                  <FormField {...def} name='proxyGender' />

                  <FormField {...def} name='langKey' />
                </Container>
              </FullWidthContainer>
            ) : (
              <FullWidthContainer>
                <Translate value='patients.createPatient.siblingInfo' />
                <FormField {...def} name='proxy' />
              </FullWidthContainer>
            )}
          </FullWidthContainer>
        )}
      </Container>
    </TwoRows>
  )
}

class CreatePatientDialog extends React.Component<PropsType> {
  render() {
    const {
      toggleCreatePatientDialog,
      showCreatePatientDialog,
      hycareSolutionEnabledClinics,
      clinicMine
    } = this.props

    const isHycareEnabledClinic =
      clinicMine &&
      hycareSolutionEnabledClinics &&
      hycareSolutionEnabledClinics.includes(clinicMine.name)

    return (
      <div>
        <DynamicFormDialog
          open={showCreatePatientDialog}
          handleClose={toggleCreatePatientDialog}
          titleKey='patients.createPatient.title'
          form='createPatient'
          initialValues={{ createNew: true }}
          render={(def, data) => createPatientRenderer(def, data, isHycareEnabledClinic)}
          fields={data => ({
            isDependent: {
              type: 'boolean'
            },
            salutation: patientFields.salutation,
            firstName: patientFields.firstName,
            lastName: patientFields.lastName,
            gender: patientFields.gender,
            birthdate: patientFields.birthdate,
            lifelongId: patientFields.lifelongId,
            mobileNumber: {
              ...patientFields.mobileNumber,
              format: value => pathOr(value, ['proxy', 0, 'mobileNumber'], data),
              readonly: data && data.isDependent
            },
            email: {
              ...patientFields.email,
              format: value => pathOr(value, ['proxy', 0, 'email'], data),
              readonly: data && data.isDependent
            },
            langKey: patientFields.langKey,
            pepIntegration: {
              type: 'boolean',
              style: 'toggle'
            },
            disease: {
              specName: 'icdCodes',
              type: 'select',
              single: true,
              mandatory: data && data.pepIntegration && isHycareEnabledClinic
            },
            createNew: {
              type: 'boolean',
              style: 'toggle'
            },
            proxy: {
              specName: 'patient',
              type: 'search',
              mandatory: true,
              multiSelectable: false
            },
            proxySalutation: {
              ...patientFields.salutation,
              mandatory: true
            },
            proxyFirstName: patientFields.firstName,
            proxyLastName: patientFields.lastName,
            proxyGender: patientFields.gender,
            proxyEmail: {
              ...patientFields.email,
              mandatory: data && data.isDependent
            },
            proxyMobileNumber: {
              ...patientFields.mobileNumber,
              mandatory: data && data.isDependent
            }
          })}
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  createPatientButton: { showCreatePatientDialog },
  healthrelation: { hycareSolutionEnabledClinics, clinicMine }
}: StoreType) => ({
  showCreatePatientDialog,
  hycareSolutionEnabledClinics,
  clinicMine
})

export default connect(
  mapStateToProps,
  {
    toggleCreatePatientDialog: createPatientButtonActions.toggleCreatePatientDialog
  }
)(CreatePatientDialog)
