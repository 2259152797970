export default {
  en: {
    cards: {
      dashboard: 'Dashboard',
      users: 'Users',
      alerts: 'Alerts',
      messages: 'Messages',
      surveys: 'Surveys',
      settings: 'Settings',
      patients: 'Patients',
      profile: 'Profile',
      studies: 'Studies',
      team: 'Team',
      catalog: 'Catalog',
      billing: 'Billing',
      appointment: 'Appointments',
      overview: 'Overview'
    },
    welcomeMessage: 'Welcome %{name}',
    recentActivity: 'Recent Conversations',
    noActiveConversation: 'You have no active conversations'
  },
  de: {
    cards: {
      dashboard: 'Dashboard',
      users: 'Benutzer',
      alerts: 'Benachrichtigungen',
      messages: 'Nachrichten',
      surveys: 'Umfragen',
      settings: 'Settings',
      patients: 'Patienten',
      profile: 'Profil',
      studies: 'Studien',
      team: 'Team',
      catalog: 'Katalog',
      billing: 'Abrechnung',
      appointment: 'Termine',
      overview: 'Überblick'
    },
    welcomeMessage: 'Willkommen %{name}',
    recentActivity: 'Neuste Konversationen',
    noActiveConversation: 'Sie haben keine aktiven Konversationen'
  }
}
