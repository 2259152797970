// @flow
import { put, race, take, actionChannel, call, flush, all } from 'redux-saga/effects'
import { buffers } from 'redux-saga'
import flatten from 'lodash/flatten'

export function* asyncAction(action: { type: string }): Generator<*, *, *> {
  const { type } = action
  const succeeded = type.replace('_REQUESTED', '_SUCCEEDED')
  const failed = type.replace('_REQUESTED', '_FAILED')
  yield put(action)
  return yield race({
    succeeded: take(succeeded),
    failed: take(failed)
  })
}

export function* takeEverySync(pattern: string, saga: any): Generator<*, *, *> {
  // 1- Create a channel for request actions
  const requestChan = yield actionChannel(pattern, buffers.expanding())
  while (true) {
    // 2- take from the channel
    const action = yield take(requestChan)
    // 3- Note that we're using a blocking call
    yield call(saga, action)
  }
}

export function* takeAggregated(pattern: string, saga: any): Generator<*, *, *> {
  const requestChan = yield actionChannel(pattern, buffers.expanding())
  while (true) {
    const actions = yield flush(requestChan) // take all cached actions
    if (actions.length > 0) {
      const payloadArray = actions.map(a => a.payload)
      const flattedPayload = flatten(payloadArray)
      const payload = [...new Set(flattedPayload)]
      // yield all actions as succeeded with no result
      yield all(
        actions.filter(a => a.type.endsWith('_REQUESTED')).map(a =>
          put({
            type: a.type.replace('_REQUESTED', '_SUCCEEDED'),
            payload: []
          })
        )
      )
      // call the saga with a new action with all the request data of all actions
      const action = {
        type: actions[0].type,
        payload
      }

      yield call(saga, action)
    }
  }
}
