export const SUFFIX = {
  requested: '_REQUESTED',
  succeeded: '_SUCCEEDED',
  failed: '_FAILED',
  cancelled: '_CANCELLED'
}

export function isRequested(type: string) {
  return type.endsWith(SUFFIX.requested)
}

export function isSucceeded(type: string) {
  return type.endsWith(SUFFIX.succeeded)
}

export function isFailed(type: string) {
  return type.endsWith(SUFFIX.failed)
}

export function isCancelled(type: string) {
  return type.endsWith(SUFFIX.cancelled)
}

export function shouldTrack(type: string) {
  return isRequested(type) || isSucceeded(type) || isFailed(type) || isCancelled(type)
}
export function getPrefix(type: string) {
  return type
    .replace(SUFFIX.succeeded, '')
    .replace(SUFFIX.failed, '')
    .replace(SUFFIX.requested, '')
    .replace(SUFFIX.cancelled, '')
}
