// @flow
import * as React from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Translate } from 'react-redux-i18n'
import FormLabel from '@material-ui/core/FormLabel'
import { sortOptionsByTranslation } from '../utils'
import type { FieldComponentPropsType } from './formField'

type PropsType = { children?: React.Node } & FieldComponentPropsType

export default (props: PropsType) => {
  const {
    input,
    meta: { touched, error, form },
    propertyDefinition: { options, style, noLabel, isRow },
    children,
    disabled
  } = props

  const { name } = input
  /*   const index = 0 // TODO: pass index */
  let result
  if (style === 'radio') {
    let radioOptions
    if (children) {
      radioOptions = children
    } else if (options) {
      radioOptions = options
        // .sort(sortOptionsByTranslation(form, name))
        .map(option => (
          <FormControlLabel
            id={option.key}
            key={option.key}
            value={option.value}
            control={<Radio />}
            label={<Translate value={`forms.${form}.${name}Opts.${option.key}`} />}
          />
        ))
    }
    result = (
      <div>
        {!noLabel && (
          <FormLabel component='legend'>
            <Translate value={`forms.${form}.${name}`} />
          </FormLabel>
        )}
        <RadioGroup aria-label={name} name={name} {...input} row={isRow}>
          {radioOptions}
        </RadioGroup>
      </div>
    )
  } else {
    const menuItems = (options || []).sort(sortOptionsByTranslation(form, name)).map(option => (
      <MenuItem id={option.key} key={option.key} value={option.value}>
        <Translate value={`forms.${form}.${name}Opts.${option.key}`} />
      </MenuItem>
    ))

    result = (
      <TextField
        disabled={disabled}
        id={`${form}-${name}`}
        inputProps={{
          'data-test': `${form}-${name}`
        }}
        select
        label={<Translate value={`forms.${form}.${name}`} />}
        error={Boolean(touched && error)}
        helperText={(touched && error) || ''}
        margin='normal'
        {...input}
      >
        {menuItems}
      </TextField>
    )
  }
  return result
}
