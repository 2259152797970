// @flow
import hopscotch from 'hopscotch/dist/js/hopscotch'
import { takeEvery, select, put, call, all } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import type { ExtractReturn, GenType } from 'common-docdok/src/types'
import { I18n } from 'react-redux-i18n'
import { takeEverySync } from 'common-docdok/src/utils/sagaUtils'
import { userActionTypes } from 'common-docdok/src/domain/user/actions'
import { introActions, introActionTypes } from '../actions'
import { storeContainer } from '../../../store/createStore'
import { LOCATION_CHANGE } from '../../../store/location'
import { infoPanelActions } from '../../InfoPanel/actions'
import { tours, tourIdentifiers } from '../tours'
import { pullGetParameter } from '../../utils'

function getTour(tourId: string) {
  const [tour] = tours.filter(t => t.id === tourId)
  return tour
}

function isTourCompleted(tourId: string) {
  return (state: StoreType) => state.intro.completedTours[tourId] === true
}

function isBillingDialogVisible() {
  return (state: StoreType) => state.billing.requested
}

function* startTour(action: ExtractReturn<typeof introActions.startTour>): GenType {
  const { tourId, force } = action.payload

  let isLoading = yield select((state: StoreType) => state.loadingStack.isLoading)
  let isReady = yield select((state: StoreType) => state.intro.ready)
  let billingDialogVisible = yield select(isBillingDialogVisible())
  let currentTour = hopscotch.getCurrTour()
  while (isLoading || !isReady || billingDialogVisible || currentTour) {
    yield call(delay, 100)
    isLoading = yield select((state: StoreType) => state.loadingStack.isLoading)
    isReady = yield select((state: StoreType) => state.intro.ready)
    billingDialogVisible = yield select(isBillingDialogVisible())
    currentTour = hopscotch.getCurrTour()
  }

  const completed = yield select(isTourCompleted(tourId))
  if (!completed || force) {
    const tour = getTour(tourId)
    hopscotch.startTour({
      ...tour,
      steps: tour.steps.map((step, i) => ({
        ...step,
        title: I18n.t(`intro.tours.${tourId}.${i}.title`),
        content: I18n.t(`intro.tours.${tourId}.${i}.content`)
      })),
      // Add callback
      onEnd() {
        storeContainer.store.dispatch(introActions.tourFinsihed(tourId))
      },
      onClose() {
        storeContainer.store.dispatch(introActions.tourFinsihed(tourId))
      },
      onError() {
        storeContainer.store.dispatch(introActions.tourFinsihed(tourId))
      },
      i18n: {
        nextBtn: I18n.t('actions.ok'),
        prevBtn: I18n.t('actions.previous'),
        doneBtn: I18n.t('actions.ok'),
        skipBtn: I18n.t('actions.skip'),
        closeTooltip: I18n.t('actions.close')
      }
    })
  }
}

function stopTour() {
  if (hopscotch.getCurrTour()) hopscotch.endTour()
}

function* resetTours(): GenType {
  yield put(infoPanelActions.showMessage('intro.reseted'))
}

function* delayedPut(action: Object, milis: number): GenType {
  yield put(action)
  yield call(delay, milis)
}

const NO_TUTORIAL_ORGINS = ['VINZENZGRUPPE']

function* startVisibleTours(): GenType {
  const visibleTours = yield select((state: StoreType) => state.intro.visibleTours)
  const toursInOrder = Object.keys(tourIdentifiers).filter(t => visibleTours.has(t))
  yield all(toursInOrder.map(tour => delayedPut(introActions.startTour(tour, true), 100)))
}

function* startHomeTour(): GenType {
  const param = pullGetParameter('noTour')
  const profile = yield select((state: StoreType) => state.profile)
  // No tours for patients for specific origins
  if (profile && profile.origin && NO_TUTORIAL_ORGINS.indexOf(profile.origin) === -1) {
    if (param !== 'true') {
      yield put(introActions.startTour(tourIdentifiers.home))
    }
  }
}

export default function introSaga(): Array<GenType> {
  return [
    takeEverySync(introActionTypes.START_TOUR, startTour),
    takeEvery(LOCATION_CHANGE, stopTour),
    takeEvery(introActionTypes.RESET_TOURS, resetTours),
    takeEvery(introActionTypes.START_VISIBLE_TOURS, startVisibleTours),
    takeEvery(userActionTypes.UPDATE_TERMS_VERSION_SUCCEEDED, startHomeTour),
    takeEvery(userActionTypes.TERMS_VERSION_EQUALS, startHomeTour)
  ]
}
