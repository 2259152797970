// @flow
import { Translate } from 'react-redux-i18n'
import React from 'react'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import styled from 'styled-components'

import CustomRecurrences from '../components/CustomRecurrences'
import FormField from '../../DynamicForm/components/fields/formField'
import { isTimeMandatory } from './validator'

const RadioInput = styled.div`
  margin-top: -40px;
  margin-left: 10px;
`
const RadioWithInput = styled.div`
  display: flex;
  margin-top: 10px;
`

export function scheduleSurveyRenderer(def: Object, data: Object) {
  return (
    <div style={{ width: '100%', minWidth: 500 }}>
      <FormField {...def} name='doSchedule' />
      {data.doSchedule && (
        <div>
          <FormField {...def} name='startAtTitle' />
          <FormField {...def} name='startAt'>
            <FormControlLabel
              value='whenAdded'
              control={<Radio />}
              label={<Translate value='forms.scheduleStudySurvey.startAtOpts.whenAdded' />}
            />
            <FormControlLabel
              value='instant'
              control={<Radio />}
              label={<Translate value='forms.scheduleStudySurvey.startAtOpts.instant' />}
            />
            <FormControlLabel
              value='daysAfterAdded'
              control={<Radio />}
              label={(
                <RadioWithInput>
                  <Translate value='forms.scheduleStudySurvey.startAtOpts.daysAfterAdded' />
                  <RadioInput>
                    <FormField {...def} name='daysAfterAdded' />
                  </RadioInput>
                </RadioWithInput>
)}
            />
          </FormField>
          {isTimeMandatory(data) && (
            <div>
              <FormField {...def} name='timeTitle' />
              <FormField {...def} name='time' />
            </div>
          )}
          <FormField {...def} name='patternTitle' />

          <FormField {...def} name='pattern' />
          {(data.pattern === 'daily' || data.pattern === 'weekly') && (
            <div>
              <div style={{ display: 'flex' }}>
                <Translate value='forms.scheduleStudySurvey.every' style={{ marginTop: 38, marginRight: 10 }} />
                <FormField {...def} name='interval' style={{ width: 80 }} />
                {'.'}
                <Translate
                  value={`forms.scheduleStudySurvey.intervalOpts.${data.pattern}`}
                  style={{ marginTop: 38, marginLeft: 10, marginRight: 10 }}
                />
              </div>
              <FormField {...def} name='numberOfRecurrences' />
            </div>
          )}
          {data.pattern === 'customRecurrence' && <CustomRecurrences name='customRecurrence' />}
        </div>
      )}
    </div>
  )
}
