// @flow
import type { CodeType, AdditionalCodeType } from '../reducers'

export const billingActionTypes = {
  DOWNLOAD_BILLING_REQUESTED: 'DOWNLOAD_BILLING_REQUESTED',
  DOWNLOAD_BILLING_SUCCEEDED: 'DOWNLOAD_BILLING_SUCCEEDED',
  DOWNLOAD_BILLING_FAILED: 'DOWNLOAD_BILLING_FAILED',

  PAUSE_BILLING: 'PAUSE_BILLING',
  RESUME_BILLING: 'RESUME_BILLING',

  SET_BILLING_CONTEXT: 'SET_BILLING_CONTEXT',

  GET_BILLING_POSITIONS_REQUESTED: 'GET_BILLING_POSITIONS_REQUESTED',
  GET_BILLING_POSITIONS_SUCCEEDED: 'GET_BILLING_POSITIONS_SUCCEEDED',
  GET_BILLING_POSITIONS_FAILED: 'GET_BILLING_POSITIONS_FAILED',

  SET_BILLING_REMINDER: 'SET_BILLING_REMINDER',

  GET_PHYSICIAN_TYPES_REQUESTED: 'GET_PHYSICIAN_TYPES_REQUESTED',
  GET_PHYSICIAN_TYPES_SUCCEEDED: 'GET_PHYSICIAN_TYPES_SUCCEEDED',
  GET_PHYSICIAN_TYPES_FAILED: 'GET_PHYSICIAN_TYPES_FAILED',

  START_BILLING_PROPOSED: 'START_BILLING_PROPOSED',
  START_BILLING_ACCEPTED: 'START_BILLING_ACCEPTED',
  START_BILLING_DECLINED: 'START_BILLING_DECLINED',

  STOP_BILLING: 'STOP_BILLING',

  TICK_BILLING_REQUESTED: 'TICK_BILLING_REQUESTED',
  TICK_BILLING_SUCCEEDED: 'TICK_BILLING_SUCCEEDED',
  TICK_BILLING_FAILED: 'TICK_BILLING_FAILED',

  SELECT_PHYSICIAN_TYPE: 'SELECT_PHYSICIAN_TYPE'
}
export type BillingActionType = $Keys<typeof billingActionTypes>
export const billingActions = {
  downloadBillingRequested() {
    return {
      type: billingActionTypes.DOWNLOAD_BILLING_REQUESTED
    }
  },
  downloadBillingSucceeded() {
    return {
      type: billingActionTypes.DOWNLOAD_BILLING_SUCCEEDED
    }
  },
  downloadBillingFailed(error: Error) {
    return {
      type: billingActionTypes.DOWNLOAD_BILLING_FAILED,
      payload: { error }
    }
  },

  pauseBilling() {
    return {
      type: billingActionTypes.PAUSE_BILLING
    }
  },
  resumeBilling() {
    return {
      type: billingActionTypes.RESUME_BILLING
    }
  },

  setBillingContext(identifier: ?string) {
    return {
      type: billingActionTypes.SET_BILLING_CONTEXT,
      payload: identifier
    }
  },

  getBillingPoisitionsRequested(context: string) {
    return {
      type: billingActionTypes.GET_BILLING_POSITIONS_REQUESTED,
      payload: context
    }
  },
  getBillingPoisitionsSucceeded(context: string, positions: Array<Object>) {
    return {
      type: billingActionTypes.GET_BILLING_POSITIONS_SUCCEEDED,
      payload: { context, positions }
    }
  },

  getBillingPoisitionsFailed(error: Error) {
    return {
      type: billingActionTypes.GET_BILLING_POSITIONS_FAILED,
      payload: { error }
    }
  },

  setBillingReminder(activated: boolean) {
    return {
      type: billingActionTypes.SET_BILLING_REMINDER,
      payload: activated
    }
  },

  getPhysicianTypesRequested() {
    return {
      type: billingActionTypes.GET_PHYSICIAN_TYPES_REQUESTED
    }
  },
  getPhysicianTypesSucceeded(types: Array<Object>) {
    return {
      type: billingActionTypes.GET_PHYSICIAN_TYPES_SUCCEEDED,
      payload: types
    }
  },
  getPhysicianTypesFailed(error: Error) {
    return {
      type: billingActionTypes.GET_PHYSICIAN_TYPES_FAILED,
      payload: { error }
    }
  },

  startBillingProposed() {
    return {
      type: billingActionTypes.START_BILLING_PROPOSED
    }
  },
  startBillingAccepted(position: CodeType, additionalPos: ?AdditionalCodeType) {
    return {
      type: billingActionTypes.START_BILLING_ACCEPTED,
      payload: { position, additionalPos }
    }
  },
  startBillingDeclined() {
    return {
      type: billingActionTypes.START_BILLING_DECLINED
    }
  },

  stopBilling() {
    return {
      type: billingActionTypes.STOP_BILLING
    }
  },

  tickBillingRequested(position: string) {
    return {
      type: billingActionTypes.TICK_BILLING_REQUESTED,
      payload: position
    }
  },

  tickBillingSucceded() {
    return {
      type: billingActionTypes.TICK_BILLING_SUCCEEDED
    }
  },

  tickBillingFailed(error: Error) {
    return {
      type: billingActionTypes.TICK_BILLING_FAILED,
      payload: { error }
    }
  },

  selectPhysicianType(id: number) {
    return {
      type: billingActionTypes.SELECT_PHYSICIAN_TYPE,
      payload: id
    }
  }
}
