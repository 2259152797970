// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'
import { browserHistory } from 'react-router'
import invariant from 'invariant'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import PersonAdd from '@material-ui/icons/PersonAdd'
import ViewList from '@material-ui/icons/ViewList'

import { RoundButton } from '../../../../../common/Mui'
import UserChip from '../../../../../common/UserChip'
import entityLoader from '../../../../../common/EntityLoader'
import Action from '../../../../../common/Security/containers/action'

const style = {
  container: { display: 'flex' },
  labelContainer: {
    marginTop: 10,
    color: 'rgb(95, 95, 95)',
    fontSize: 12
  },
  elementContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 450,
    marginTop: 10
  },
  actionContainer: { marginLeft: 10, display: 'flex', flexDirection: 'column' },
  chipContainer: { paddingRight: 3, paddingBottom: 3 },
  line: {
    borderTop: 'none rgb(224, 224, 224)',
    borderLeft: 'none rgb(224, 224, 224)',
    borderright: 'none rgb(224, 224, 224)',
    borderBottom: '1px solid rgb(224, 224, 224)',
    bottom: 8,
    boxSizing: 'content-box',
    margin: 0,
    width: '100%'
  },
  showAll: { alignSelf: 'end', marginTop: 'auto' }
}

export type PersonViewPropsType = {
  id: string,
  labelKey: string,
  rows: Array<Object>,
  onRequestDelete: Function,
  onRequestAdd?: Function,
  total?: number,
  allUrl?: string,
  multi?: boolean,
  pageSize: number,
  actionName: string,
  addTooltip?: string
}

export class PersonView extends React.Component<PersonViewPropsType> {
  static defaultProps = {
    multi: true,
    total: undefined,
    allUrl: undefined,
    onRequestAdd: undefined,
    addTooltip: undefined
  }

  render() {
    const {
      labelKey,
      rows,
      total,
      allUrl,
      onRequestDelete,
      multi,
      onRequestAdd,
      pageSize,
      actionName,
      addTooltip,
      id
    } = this.props

    const totalRows = !isNaN(total) ? Number(total) : rows.length

    const more = totalRows - pageSize

    const moreLink = more > 0 && multi ? (
      <RoundButton onClick={() => browserHistory.push(allUrl)} id={`${id}-show-more-button`}>
        <Translate value='studies.more' more={more} />
      </RoundButton>
    ) : null

    const showAddButton = (!multi && total === 0) || multi
    if (showAddButton) {
      invariant(addTooltip, 'provide the "addTooltip" property for the button-tooltip')
    }
    return (
      <div>
        <div style={style.labelContainer}>
          <Translate value={labelKey} />
        </div>
        <div style={style.container}>
          <div style={style.elementContainer}>
            {rows.slice(0, pageSize).map((p, i) => (
              <span key={i} style={style.chipContainer}>
                <UserChip
                  id={`${id}-person-${i}`}
                  entity={p}
                  onRequestDelete={() => {
                    onRequestDelete(p)
                  }}
                />
              </span>
            ))}
            <span>{moreLink}</span>
            <hr style={style.line} />
          </div>
          <div style={style.actionContainer}>
            <div>
              {showAddButton && (
                <Tooltip title={<Translate value={addTooltip} />}>
                  <IconButton onClick={onRequestAdd} id={`${id}-add-button`}>
                    <PersonAdd />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <div style={style.showAll}>
              {multi && (
                <Action name={actionName}>
                  <Tooltip title={<Translate value='studies.showAll' />}>
                    <IconButton
                      id={`${id}-show-all-button`}
                      onClick={() => {
                        browserHistory.push(allUrl)
                      }}
                    >
                      <ViewList />
                    </IconButton>
                  </Tooltip>
                </Action>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default entityLoader(PersonView)
