// @flow
import type { ActionType } from 'common-docdok/src/types'

// ------------------------------------
// Constants
// ------------------------------------
export const LOCATION_CHANGE = 'LOCATION_CHANGE'

export function locationChange(location: string = '/') {
  return {
    type: LOCATION_CHANGE,
    payload: location
  }
}

// ------------------------------------
// Specialized Action Creator
// ------------------------------------
export const updateLocation = ({ dispatch }: Object) => (nextLocation: any) => {
  dispatch(locationChange(nextLocation.pathname))
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = null
export default function locationReducer(state: ?string = initialState, action: ActionType) {
  return action.type === LOCATION_CHANGE ? action.payload : state
}
