// @flow
import { takeEvery, put } from 'redux-saga/effects'
import { setLocale } from 'react-redux-i18n'
import { userActionTypes } from 'common-docdok/src/domain/user/actions'
import { pullGetParameter } from '../common/utils'

function getProbablePreferredLang() {
  const userLangs = navigator.languages.map(it => it.replace(/-.*/, '')) // something like en-US -> en
  return userLangs.indexOf('de') !== -1 ? 'de' : 'en'
}

export function* setLang(action: any): Generator<*, *, *> {
  const lang = pullGetParameter('lang')
  const preferences = action.payload.preferences.values
  const storedLang = preferences ? preferences.locale : undefined

  const locale = lang || storedLang || getProbablePreferredLang()
  yield put(setLocale(locale))
}

export default function langSaga(): Array<Generator<*, *, *>> {
  return [takeEvery(userActionTypes.PROFILE_FETCH_SUCCEEDED, setLang)]
}
