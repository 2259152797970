// @flow
import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import AddAPhoto from '@material-ui/icons/AddAPhoto'
import { UploadAvatarDialog } from '../../UploadAvatarDialog/components/UploadAvatarDialog'

type PropsType = {
  name: string,
  changeAvatar?: Function,
  src: ?string,
  size?: number,
  style?: Object,
  showAvatar?: boolean
}

type StateType = {
  showAvatar: boolean,
  showChangeAvatar: boolean,
  showDialog: boolean
}

export default class UserAvatar extends React.Component<PropsType, StateType> {
  static defaultProps = {
    changeAvatar: undefined,
    size: undefined,
    style: undefined,
    showAvatar: true
  }

  state = {
    showAvatar: this.props.showAvatar || true,
    showChangeAvatar: false,
    showDialog: false
  }

  componentWillReceiveProps(nextProps: PropsType) {
    const { src } = this.props
    const { showAvatar } = this.state
    this.setState({ showAvatar: nextProps.src !== src || showAvatar })
  }

  renderAvatar() {
    const { src, style, size, changeAvatar } = this.props
    return (
      <Avatar
        style={style}
        src={src}
        size={size}
        onError={() => {
          this.setState({ showAvatar: false })
        }}
        onMouseEnter={() => {
          if (changeAvatar) this.setState({ showChangeAvatar: true })
        }}
      />
    )
  }

  renderText() {
    const { name, style, size, changeAvatar } = this.props
    const text = (name && name[0]) || ''

    const avatar = (
      <span>
        <Avatar
          style={style}
          size={size}
          onMouseEnter={() => {
            if (changeAvatar) this.setState({ showChangeAvatar: true })
          }}
        >
          <span style={size && { fontSize: size / 2 }}>{text.toUpperCase()}</span>
        </Avatar>
      </span>
    )

    return avatar
  }

  render() {
    const { src, style, size, changeAvatar } = this.props
    const showAvatar = this.state.showAvatar && Boolean(src)
    let avatar = showAvatar ? this.renderAvatar() : this.renderText()

    if (this.state.showChangeAvatar) {
      avatar = (
        <span key={new Date().getTime()}>
          <Avatar
            onClick={() => {
              if (changeAvatar) this.setState({ showDialog: true })
            }}
            style={style}
            size={size}
            onMouseLeave={() => {
              this.setState({ showChangeAvatar: false })
            }}
          >
            <AddAPhoto />
          </Avatar>
          {this.state.showDialog && (
            <UploadAvatarDialog
              avatar={src}
              open={this.state.showDialog}
              handleClose={(arrayBuffer) => {
                if (arrayBuffer && changeAvatar) {
                  changeAvatar(arrayBuffer)
                }
                this.setState({ showDialog: false, showChangeAvatar: false })
              }}
            />
          )}
        </span>
      )
    }
    return avatar
  }
}
