// @flow

export const infoPanelActionTypes = {
  SHOW_MESSAGE: 'SHOW_MESSAGE'
}
export type InfoPanelActionType = $Keys<typeof infoPanelActionTypes>
export const infoPanelActions = {
  showMessage(key: string, params?: Object, type?: string = 'success') {
    return {
      type: infoPanelActionTypes.SHOW_MESSAGE,
      payload: { key, params, type }
    }
  }
}
