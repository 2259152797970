// @flow

export const patientsActionTypes = {
  TOGGLE_SEND_SURVEY_FROM_PATIENT_DIALOG: 'TOGGLE_SEND_SURVEY_FROM_PATIENT_DIALOG',
  TOGGLE_SET_TARGET_PATIENT_DIALOG: 'TOGGLE_SET_TARGET_PATIENT_DIALOG'
}
export type PatientsActionType = $Keys<typeof patientsActionTypes>
export const patientsActions = {
  toggleSendSurveyFromPatientDialog() {
    return {
      type: patientsActionTypes.TOGGLE_SEND_SURVEY_FROM_PATIENT_DIALOG
    }
  },
  toggleSetTargetPatientDialog() {
    return {
      type: patientsActionTypes.TOGGLE_SET_TARGET_PATIENT_DIALOG
    }
  }
}
