// @flow
import { put, takeEvery } from 'redux-saga/effects'
import type { ExtractReturn, GenType } from 'common-docdok/src/types'
import { infoPanelActions } from '../../InfoPanel/actions'
import { SHOW_NOTIFICATION, showNotification } from '../actions/showNotification'

// Global variables
declare var Notification: any

function showBrowserNotification(title, options, url) {
  const notification = new Notification(title, options)
  if (url) {
    notification.onclick = () => {
      window.open(url)
    }
  }
}

export function* newNotification(action: ExtractReturn<typeof showNotification>): GenType {
  const hasNotifactions = 'Notification' in window
  if (hasNotifactions) {
    try {
      if (Notification.permission === 'granted') {
        showBrowserNotification(action.payload.title, action.payload.options, action.payload.url)
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission((permission) => {
          if (permission === 'granted') {
            showBrowserNotification(action.payload.title, action.payload.options, action.payload.url)
          }
        })
      }
    } catch (e) {
      yield put(infoPanelActions.showMessage(e.message))
    }
  }
}

export default function notificationsSaga(): Array<GenType> {
  return [takeEvery(SHOW_NOTIFICATION, newNotification)]
}
