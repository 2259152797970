// @flow

export const fieldNames = {
  id: 'id',
  createdAt: 'createdAt',
  userRef: 'userRef',
  patientUuid: 'patientUuid',
  clinicId: 'clinicId',
  state: 'state',
  userRefPhysician: 'userRefPhysician'
}

export const fields = {
  id: {
    type: 'number',
    format: 'int64'
  },
  createdAt: { type: 'Date' },
  userRef: {
    type: 'string',
    minLength: 0,
    maxLength: 36
  },
  patientUuid: { type: 'string' },
  clinicId: {
    type: 'number',
    format: 'int64'
  },
  state: { type: 'string',
    enum: ['WA', 'IN', 'ER', 'FI', 'TO'] },
  userRefPhysician: {
    type: 'string',
    minLength: 0,
    maxLength: 36
  }
}

export type AmbulanceQueueItemDtoType = {
  id: number,
  userRefPhysician: string,
  userRef: string,
  createdAt: Date,
  patientUuid: string,
  clinicId: number,
  state: string
}
