// @flow

import { takeEvery, put, select } from 'redux-saga/effects'
import type { ActionType, ExtractReturn, GenType } from 'common-docdok/src/types'
import toString from 'common-docdok/src/utils/toString'
import primaryConversationSelector from 'common-docdok/src/domain/messaging/selectors/primaryConSelector'
import { messagingActions } from 'common-docdok/src/domain/messaging/actions'
import path from 'ramda/src/path'
import { healthrelationActions, healthrelationActionTypes } from 'common-docdok/src/domain/healthrelation/actions'
import { asyncAction } from 'common-docdok/src/utils/sagaUtils'

import { infoPanelActions } from '../../../../../common/InfoPanel/actions'


export function formToPatientDto(formData: Object) {
  const {
    salutation,
    firstName,
    lastName,
    gender,
    birthdate,
    createNew,
    isDependent,
    proxyEmail,
    proxyMobileNumber,
    proxySalutation,
    proxyFirstName,
    proxyLastName,
    proxyGender
  } = formData

  let { email, langKey, mobileNumber } = formData

  const type = isDependent ? 'PATIENTPROXIED' : 'PATIENTSELF'
  const userRef = path(['proxy', 0, 'userRef'], formData)

  let proxy

  if (isDependent) {
    if (createNew) {
      proxy = {
        salutation: proxySalutation,
        firstName: proxyFirstName,
        lastName: proxyLastName,
        gender: proxyGender
      }
      email = proxyEmail
      mobileNumber = proxyMobileNumber
    } else {
      // existing person
      email = path(['proxy', 0, 'email'], formData)
      mobileNumber = path(['proxy', 0, 'mobileNumber'], formData)
      langKey = path(['proxy', 0, 'langKey'], formData)
    }
  }

  const patient = {
    firstName,
    lastName,
    birthdate,
    email,
    mobileNumber,
    salutation,
    type,
    userRef,
    gender,
    langKey,
    proxy
  }

  return patient
}

export function* selectPatGen({ payload }: ActionType): GenType {
  const uuid = payload
  if (uuid) {
    const winner = yield asyncAction(healthrelationActions.loadPatientRequested(uuid))

    if (winner.succeeded) {
      const conversationId = yield select((state: StoreType) => primaryConversationSelector(state, uuid))
      yield put(messagingActions.selectConversation(Number(conversationId)))
    }
  }
}

export function* deletePatientSucceeded(
  action: ExtractReturn<typeof healthrelationActions.deletePatientSucceeded>
): GenType {
  const { payload } = action
  const name = toString.person(payload)
  yield put(
    infoPanelActions.showMessage('patients.actions.deletePatient.success', {
      name
    })
  )
}

export default function patientsSaga(): Array<GenType> {
  return [takeEvery(healthrelationActionTypes.DELETE_PATIENT_SUCCEEDED, deletePatientSucceeded)]
}
