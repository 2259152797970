// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'

import PersonAdd from '@material-ui/icons/PersonAdd'
import { fields } from 'common-docdok/src/domain/healthrelation/types/healthcareProfessionalDto'
import { connect } from 'react-redux'

import { professionalsActions } from '../actions'

import DynamicFormDialog from '../../../../../common/DynamicFormDialog'
import Action from '../../../../../common/Security/containers/action'
import { CREATE_PHYSICIAN } from '../../../../../common/Security/actionNames'
import { RaisedButton } from '../../../../../common/Mui'

type PropsType = {
  toggleCreatePhysicianDialog: typeof professionalsActions.toggleCreatePhysicianDialog,
  showCreatePhysicianDialog: boolean
}

function createPhysicianButton(props: PropsType) {
  const { toggleCreatePhysicianDialog, showCreatePhysicianDialog } = props
  return (
    <Action name={CREATE_PHYSICIAN}>
      <RaisedButton
        label={<Translate value='professionals.createPhysician.title' />}
        onClick={toggleCreatePhysicianDialog}
        icon={<PersonAdd />}
        style={{ marginLeft: 5 }}
      />
      <DynamicFormDialog
        open={showCreatePhysicianDialog}
        handleClose={toggleCreatePhysicianDialog}
        titleKey='professionals.createPhysician.title'
        form='createPhysician'
        fields={[
          {
            salutation: fields.salutation,
            firstName: fields.firstName
          },
          {
            lastName: fields.lastName,
            gender: fields.gender
          },
          {
            mobileNumber: {
              ...fields.mobileNumber,
              minLength: 5,
              mandatory: true
            },
            email: {
              ...fields.email,
              mandatory: true
            }
          },
          {
            langKey: fields.langKey,
            clinicId: {
              specName: 'clinic',
              type: 'select',
              single: true,
              mandatory: true
            }
          }
          // {
          //   allowHycare: {
          //     type: 'boolean',
          //     style: 'toggle'
          //   }
          // }
        ]}
      />
    </Action>
  )
}

const mapStateToProps = (state: StoreType) => ({
  showCreatePhysicianDialog: state.professionals.showCreatePhysicianDialog
})

export default connect(
  mapStateToProps,
  { toggleCreatePhysicianDialog: professionalsActions.toggleCreatePhysicianDialog }
)(createPhysicianButton)
