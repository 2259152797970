export default {
  en: {
    fields: {
      id: 'Id',
      orgName: 'Organization Name',
      orgId: 'OrganizationId',
      externalId: 'External Identificaiton'
    }
  },
  de: {
    fields: {
      id: 'Id',
      orgName: 'Name der Organisation',
      orgId: 'Organisations ID',
      externalId: 'Externe ID'
    }
  }
}
