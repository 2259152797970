// @flow
import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { Translate } from 'react-redux-i18n'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import styled from 'styled-components'
import palette from '../../../../../styles/palette'
import { DialogHeader } from '../../../../../common/Mui'
import { Container } from '../../../../../common/Static'
import { surveysActions } from '../actions'

const SurveyRow = styled.div`
  display: flex;
  background-color: ${props => (props.lineNo % 2 === 0 ? palette.greyLight : 'white')};
`

const QuestionCell = styled.div`
  width: 70%;
  padding: 0.5rem;
`
const AnswerCell = styled.div`
  width: 30%;
  padding: 0.5rem;
`

type PropsType = {
  showSurveyResponsesDialog: boolean,
  participationId: number,
  responses: Object,
  toggleSurveyResponsesDialog: typeof surveysActions.toggleSurveyResponsesDialog,

  fullScreen: boolean
}

function surveyAnswerToHtml(answer: Object) {
  if (Array.isArray(answer)) {
    const content = answer.map(it => <li>{it}</li>)
    return <ul>{content}</ul>
  }
  return String(answer)
}

function surveyResponsesDialog(props: PropsType) {
  const { toggleSurveyResponsesDialog, showSurveyResponsesDialog, responses, fullScreen } = props
  if (!showSurveyResponsesDialog) {
    return null
  }
  const rows = Object.keys(responses).map(q => ({
    question: q,
    answer: responses[q]
  }))

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth='md'
      scroll='paper'
      open={showSurveyResponsesDialog}
      onEscapeKeyDown={() => toggleSurveyResponsesDialog()}
    >
      <DialogHeader name='survey-respones' onClose={() => toggleSurveyResponsesDialog()}>
        <Container noWrap>
          <Translate value='surveys.viewResponses.title' />
        </Container>
      </DialogHeader>
      <DialogContent>
        {rows.map((r, i) => (
          <SurveyRow lineNo={i} key={`${i}-${r.question}`}>
            <QuestionCell>
              <Typography>{r.question}</Typography>
            </QuestionCell>
            <AnswerCell>
              <Typography>
                <div>{surveyAnswerToHtml(r.answer)}</div>
              </Typography>
            </AnswerCell>
          </SurveyRow>
        ))}
      </DialogContent>
    </Dialog>
  )
}

export default withMobileDialog()(surveyResponsesDialog)
