// @flow
import { securityActionTypes, securityActions } from 'common-docdok/src/common/Security/actions'
import type { ActionType, ExtractReturn } from 'common-docdok/src/types'

export type RightType = {
  route: string,
  actions: Array<string>
}

export type SecurityStateType = {
  rights: ?Array<RightType>
}

const initialState: SecurityStateType = {
  rights: undefined
}

function getRights(state: SecurityStateType, action: ExtractReturn<typeof securityActions.getRightsSucceeded>) {
  const rights = []

  const allRoute: RightType = action.payload.find(it => it.route === 'ALL') || { route: 'ALL', actions: [] }

  action.payload.forEach((it) => {
    const origActions = it.actions ? it.actions : []
    const actions = [...allRoute.actions, ...origActions]
    const res = { ...it, actions }
    rights.push(res)
  })

  return {
    ...state,
    rights
  }
}

const ACTION_HANDLERS = {
  [securityActionTypes.GET_RIGHTS_SUCCEEDED]: getRights
}

export default function securityReducer(
  state: SecurityStateType = initialState,
  action: ActionType
): SecurityStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
