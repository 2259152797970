// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'

import { checkRoute } from '../../../../common/Security/utils'
import ClinicTeam from './components/ClinicTeam'
import PhysicianPatients from './components/PhysicianPatients'

export default (store: any) => ({
  path: 'clinics',
  name: (
    <span id='breadcrumb1'>
      <Translate value='menu.clinics' />
    </span>
  ),
  /*  Async getComponent is only invoked when route matches   */
  getComponent(nextState: any, cb: Function) {
    /*  Webpack - use 'require.ensure' to create a split point
        and embed an async module loader (jsonp) when bundling   */
    (require: any).ensure(
      [],
      (require) => {
        /*  Webpack - use require callback to define
          dependencies for bundling   */
        const container = require('./components/clinics').default

        /*  Return getComponent   */
        cb(null, container)

        /* Webpack named bundle   */
      },
      'clinics'
    )
  },
  onEnter: (state: any, replace: Function) => {
    checkRoute(store.getState(), state.location.pathname, replace)
  },
  childRoutes: [
    {
      name: (
        <span id='breadcrumb2'>
          <Translate value='menu.team' />
        </span>
      ),
      path: ':clinicId/team',
      component: ClinicTeam,
      onEnter: (state: any, replace: Function) => {
        checkRoute(store.getState(), state.location.pathname, replace)
      },
      childRoutes: [
        {
          name: (
            <span id='breadcrumb3'>
              <Translate value='menu.patients' />
            </span>
          ),
          path: ':uuid/patients',
          component: PhysicianPatients,
          onEnter: (state: any, replace: Function) => {
            checkRoute(store.getState(), state.location.pathname, replace)
          }
        }
      ]
    }
  ]
})
