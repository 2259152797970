// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'
import { checkRoute } from '../../../../common/Security/utils'

export default (store: any) => ({
  path: 'professionals',
  /*  Async getComponent is only invoked when route matches   */

  getComponent(nextState: any, cb: Function) {
    /*  Webpack - use 'require.ensure' to create a split point
        and embed an async module loader (jsonp) when bundling   */
    (require: any).ensure(
      [],
      (require) => {
        /*  Webpack - use require callback to define
          dependencies for bundling   */
        const container = require('./components/professionals').default

        /*  Add the reducer to the store on key 'counter'  */

        /*  Return getComponent   */
        cb(null, container)

        /* Webpack named bundle   */
      },
      'professionals'
    )
  },
  childRoutes: [
    {
      onEnter: (state: any, replace: Function) => {
        checkRoute(store.getState(), state.location.pathname, replace)
      },
      path: 'team',
      name: (
        <span id='breadcrumb1'>
          <Translate value='menu.team' />
        </span>
      ),
      getComponent(nextState: any, cb: Function) {
        (require: any).ensure(
          [],
          (require) => {
            const container = require('./containers/team').default
            cb(null, container)
          },
          'team'
        )
      }
    }
  ]
})
