// @flow
import type { PreferencesType } from './preferences'
import { type UserDtoType, fieldNames as uFieldNames, fields as uFields } from './userDto'

export const roles = {
  admin: 'ROLE_ADMIN',
  physician: 'ROLE_PHYSICIAN',
  studyCoordinator: 'ROLE_STUDY_COORDINATOR',
  mpa: 'ROLE_MPA',
  patient: 'ROLE_PATIENT',
  supportAdmin: 'ROLE_SUPPORT_ADMIN'
}

export const fieldNames = {
  ...uFieldNames,
  preferences: 'preferences'
}

export const fields = {
  ...uFields,
  preferences: { type: 'Object' }
}

export type MeDtoType = {
  preferences: PreferencesType // this field is added with a second request. see fetchProfile saga
} & UserDtoType
