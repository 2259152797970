// @flow
import type { ActionType, ExtractReturn } from 'common-docdok/src/types'
import { userActionTypes } from 'common-docdok/src/domain/user/actions'
import { introActionTypes, introActions } from '../actions'

type TourType = {
  tourId: string,
  force: boolean
}

// State Definition
export type IntroStateType = {
  completedTours: { [key: string]: boolean },
  tourQueue: Array<TourType>,
  visibleTours: Set<string>,
  ready: boolean
}

const initialState: IntroStateType = {
  completedTours: {},
  tourQueue: [],
  visibleTours: new Set([]),
  ready: false
}

function resetTours(state: IntroStateType) {
  return { ...state, completedTours: {} }
}

function tourFinished(
  state: IntroStateType,
  action: ExtractReturn<typeof introActions.tourFinsihed>
) {
  const tourId = action.payload

  return {
    ...state,
    completedTours: {
      ...state.completedTours,
      [tourId]: true
    }
  }
}

function registerTour(
  state: IntroStateType,
  action: ExtractReturn<typeof introActions.registerTour>
) {
  const tourId = action.payload
  const visibleTours = new Set([...state.visibleTours, tourId])

  return {
    ...state,
    visibleTours
  }
}

function unregisterTour(
  state: IntroStateType,
  action: ExtractReturn<typeof introActions.unregisterTour>
) {
  const tourId = action.payload
  const visibleTours = new Set([...state.visibleTours].filter(id => id !== tourId))

  return {
    ...state,
    visibleTours
  }
}

function tutorialsReady(state: IntroStateType) {
  return {
    ...state,
    ready: true
  }
}

const ACTION_HANDLERS = {
  [introActionTypes.RESET_TOURS]: resetTours,
  [introActionTypes.TOUR_FINISHED]: tourFinished,
  [introActionTypes.REGISTER_TOUR]: registerTour,
  [introActionTypes.UNREGISTER_TOUR]: unregisterTour,
  [userActionTypes.PROFILE_FETCH_SUCCEEDED]: tutorialsReady
}

export default function infoPanelReducer(state: IntroStateType = initialState, action: ActionType) {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
