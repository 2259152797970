// @flow

export const fieldNames = {
  addedBy: 'addedBy',
  createdDate: 'createdDate',
  id: 'id',
  participantId: 'participantId',
  participantSubId: 'participantSubId',
  patientUuid: 'patientUuid',
  studyId: 'studyId',
  userRef: 'userRef'
}

export const fields = {
  addedBy: { type: 'string' },
  createdDate: { type: 'Date' },
  id: {
    type: 'number',
    format: 'int64'
  },
  participantId: { type: 'string' },
  participantSubId: { type: 'string' },
  patientUuid: { type: 'string' },
  studyId: {
    type: 'number',
    format: 'int64'
  },
  userRef: { type: 'string' }
}

export type StudyParticipantDtoType = {
  addedBy: string,
  createdDate?: Date,
  id?: number,
  participantId: string,
  participantSubId: string,
  patientUuid: string,
  studyId: number,
  userRef: string
}
