// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'
import Typography from '@material-ui/core/Typography'
import type { ReduxFormCompontenType } from '../../DynamicForm/components/fields/formField'

type PropsType = ReduxFormCompontenType

class ClinicalData extends React.Component<PropsType> {
  render() {
    return (
      <div>
        <div>
          <Typography variant='subtitle1'>
            <Translate value='cases.newCaseForm.clinicalData' />
          </Typography>
        </div>
        {this.props.children}
      </div>
    )
  }
}

export default ClinicalData
