// @flow
import React from 'react'
import type { StudySurveyDtoType } from 'common-docdok/src/domain/survey/types/studySurveyDto'
import { connect } from 'react-redux'

import DynamicFormDialog from '../../DynamicFormDialog'
import { scheduleModelToForm } from '../utils/converter'
import { scheduleSurveyFields } from '../utils/fields'
import { scheduleSurveyRenderer } from '../utils/renderer'
import { scheduleSurveyValidator } from '../utils/validator'
import { scheduleSurveyDialogActions } from '../actions/index'


type PropsType = {
  scheduleSurvey: ?StudySurveyDtoType,
  setStudySurveySchedule: typeof scheduleSurveyDialogActions.setStudySurveySchedule
}

function scheduleSurveyDialog(props: PropsType) {
  const { scheduleSurvey, setStudySurveySchedule } = props
  if (!scheduleSurvey) return null

  const defaults = {
    ...scheduleModelToForm(scheduleSurvey.schedule)
  }

  return (
    <DynamicFormDialog
      open={Boolean(scheduleSurvey)}
      handleClose={() => setStudySurveySchedule()}
      titleKey='forms.scheduleStudySurvey.title'
      form='scheduleStudySurvey'
      initialValues={{
        scheduleSurvey,
        pattern: 'noRecurrence',
        ...defaults
      }}
      fields={scheduleSurveyFields}
      render={scheduleSurveyRenderer}
      validator={scheduleSurveyValidator}
    />
  )
}

const mapStateToProps = (state: StoreType) => ({
  scheduleSurvey: state.scheduleSurveyDialog.scheduleSurvey
})

export default connect(
  mapStateToProps,
  scheduleSurveyDialogActions
)(scheduleSurveyDialog)
