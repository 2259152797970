export default {
  de: {
    name: 'FitBit',
    fields: {
      steps: 'Anzahl der Schritte',
      dateTime: 'Datum',
      day: 'Tag der Studie',
      value: 'Anzahl der Schritte',
      week: 'Woche',
      avgWeek: 'Durschnitt'
    }
  },
  en: {
    name: 'FitBit',
    fields: {
      dateTime: 'Date',
      day: 'Day of Study',
      value: 'Number of Steps',
      steps: 'Number of Steps',
      week: 'Week',
      avgWeek: 'Average Steps Per Day '
    }
  }
}
