// @flow
import type { ActionType } from 'common-docdok/src/types'
import { exportStudyDialogActionTypes } from '../actions'

// State Definition
export type ExportStudyDialogStateType = {
  showExportStudyDialog: boolean
}

// Default State
const initialState: ExportStudyDialogStateType = {
  showExportStudyDialog: false
}

// Action Handlers

function toggleExportStudyDtataDialog(state: ExportStudyDialogStateType) {
  return {
    ...state,
    showExportStudyDialog: !state.showExportStudyDialog
  }
}

const ACTION_HANDLERS = {
  [exportStudyDialogActionTypes.TOGGLE_EXPORT_STUDY_DIALOG]: toggleExportStudyDtataDialog
}

export default function surveysReducer(
  state: ExportStudyDialogStateType = initialState,
  action: ActionType
): ExportStudyDialogStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
