export const CREATE_USER = 'createUser'
export const CREATE_PHYSICIAN = 'createPhysician'
export const CREATE_PATIENT = 'createPatient'
export const SEND_SURVEY = 'sendSurvey'
export const DOWNLOAD_SURVEY = 'downloadSurvey'
export const ADD_INTEGRATION = 'addIntegration'
export const LAUNCH_SURVEY = 'launchSurvey'
export const SEND_MESSAGE = 'sendMessage'
export const ACTIVATE_SURVEY = 'activateSurvey'
export const IMPORT_SURVEY = 'importSurvey'
export const CHANGE_AVATAR = 'changeAvatar'
export const CREATE_CONVERSATION = 'createConversation'
export const LEAVE_CONVERSATION = 'leaveConversation'
export const EDIT_CONVERSATION = 'editConversation'
export const VIEW_SURVEY_RESPONSES = 'viewSurveyResponses'
export const CREATE_MPA = 'createMPA'
export const SEND_ATTACHMENT = 'sendAttachment'
export const EXPORT_STUDY_DATA = 'exportStudyData'
export const REQUEST_ATTENTION = 'requestAttention'
export const BILLING = 'billing'
export const BILLING_EXPORT = 'billingExport'
export const INTEGRATION = 'integration'
export const SHOW_EVENTS = 'showEvents'
export const CREATE_STUDY = 'createStudy'
export const REMOVE_STUDY_SURVEY = 'removeStudySurvey'
export const ADD_STUDY_PARTICIPANT = 'addStudyParticipant'
export const ADD_STUDY_DEVICE = 'addStudyDevice'
export const ADD_STUDY_PHYSICIAN = 'addStudyPhysicians'
export const ADD_STUDY_SURVEY = 'addStudySurvey'
export const ADD_STUDY_COORDINATOR = 'addStudyCoordinator'
export const SCHEDULE_STUDY_SURVEY = 'scheduleStudySurvey'
export const REMOVE_STUDY_PHYSICIAN = 'removeStudyPhysician'
export const REMOVE_STUDY_DEVICE = 'removeStudyDevice'
export const RENEW_INVITATION = 'renewInvitation'
export const SET_AVAILABILITY = 'setAvailability'
export const SET_STUDY_STATUS = 'changeStudyStatus'
export const DISPLAY_TERMS = 'displayTerms'
export const CHANGE_EMAIL_NOTIFICATION = 'changeEmailNotification'
export const ASSIGNE_TO_ME = 'assigneToMe'
export const FILTER_ATTACHMENTS = 'filterAttachments'
export const EXPORT_UOB_DATA = 'exportUOBData'
export const DELETE_PATIENT = 'deletePatient'
export const CREATE_CLINIC = 'createClinic'
export const UPDATE_USER = 'updateUser'
export const UPDATE_PATIENT = 'updatePatient'
export const UPDATE_CONTENT_DEFINITION = 'updateContentDefinition'
export const SEND_CONTENT = 'sendContent'
export const MUTE_CONVERSATION = 'muteConversation'
export const MASS_MSG_SENDING = 'massMsgSending'
export const SHOW_MESSAGE_READ_TICK = 'showTicks'
export const DELETE_SURVEY = 'deleteSurvey'
export const DISABLE_USER = 'disableUser'
export const UPDATE_SURVEY = 'updateSurvey'
export const DELETE_STUDY = 'deleteStudy'
export const DOWNLOAD_STUDY_ANSWERS = 'downloadSurveyAnswers'
export const VIDEO_CONFERENCE = 'useVideoConference'
export const SHOW_AMBULANCE = 'showAmbulance'
export const CASESSTREAMS = 'caseStreams'
export const SHOW_APPOINTMENTS = 'caseStreams' // TODO update to showAppointments and edit svc-user profiles
export const CALENDAR = 'calendar'
export const ADD_EVENT = 'addEvent'
export const PROGRESS = 'progress'
export const DELETE_USER = 'deleteUser'
