// @flow
import React from 'react'

import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

const Container = styled.div`
  display: flex;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  margin-top: -60px;
  padding: 5px;
`

const TextContainer = styled.div`
  flex-grow: 2;
`

const ButtonContainer = styled.div``

type PropsType = {
  title: any,
  subtitle: any,
  button: any
}

const MediaTitle = (props: PropsType) => {
  const { title, subtitle, button } = props
  return (
    <Container>
      <TextContainer>
        <Typography color='inherit' variant='subtitle1'>
          {title}
        </Typography>
        <Typography color='inherit' variant='body2'>
          {subtitle}
        </Typography>
      </TextContainer>
      <ButtonContainer>{button}</ButtonContainer>
    </Container>
  )
}

export default MediaTitle
