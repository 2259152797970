// @flow
import React from 'react'
import TablePagination from '@material-ui/core/TablePagination'
import { GreyBar } from '../../Static'

type PropsType = {
  page: number,
  pages: number,
  onPageChange: (page: number) => void,
  showPageSizeOptions: boolean,
  pageSizeOptions: Array<number>,
  pageSize: number,
  canPrevious: boolean,
  canNext: boolean,
  onPageSizeChange: (size: number) => void,
  total: number,
  previousText: string,
  ofText: string,
  pageText: string,
  nextText: string,
  rowsText: string
}

type StateType = {}

export default class Pagination extends React.Component<PropsType, StateType> {
  changePage(newPage: number) {
    const { page, onPageChange } = this.props
    if (page !== newPage) {
      onPageChange(newPage)
    }
  }

  render() {
    const {
      page,
      total,
      pageSizeOptions,
      pageSize,
      onPageSizeChange,
      previousText,
      ofText,
      nextText,
      rowsText,
      showPageSizeOptions
    } = this.props

    return (
      <TablePagination
        labelDisplayedRows={paginationState => (
          <span>
            {paginationState.from}
            {' '}
-
            {paginationState.to}
            {' '}
            {ofText}
            {' '}
            {paginationState.count}
          </span>
        )}
        labelRowsPerPage={(
          <span>
            {rowsText}
:
          </span>
)}
        rowsPerPageOptions={showPageSizeOptions ? pageSizeOptions : []}
        component={GreyBar}
        count={total}
        rowsPerPage={pageSize}
        page={page}
        backIconButtonProps={{
          'aria-label': previousText
        }}
        nextIconButtonProps={{
          'aria-label': nextText
        }}
        onChangePage={(event, newPage) => this.changePage(newPage)}
        onChangeRowsPerPage={event => onPageSizeChange(event.target.value)}
      />
    )
  }
}
