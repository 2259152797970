export default {
  en: {
    name: 'Study',
    fields: {
      id: 'Id',
      coordinatorRef: 'Coordinator',
      name: 'Name',
      status: 'Status',
      statusOpts: {
        ACTIVE: 'Active',
        FINISHED: 'Finished',
        PAUSED: 'Paused'
      },
      targetParticipantNumber: 'Target Patients Count',
      targetPhysicianNumber: 'Target Physician Count',
      createdDate: 'Created date',
      currentParticipantNumber: 'Patients',
      currentPhysicianNumber: 'Physicians',
      nbSurveys: 'Surveys'
    }
  },
  de: {
    name: 'Studie',
    fields: {
      id: 'Id',
      coordinatorRef: 'Koordinator',
      name: 'Name',
      status: 'Status',
      statusOpts: {
        ACTIVE: 'Aktive',
        FINISHED: 'Abgeschlossen',
        PAUSED: 'Pausiert'
      },
      targetParticipantNumber: 'Ziel Patientenanzahl',
      targetPhysicianNumber: 'Ziel Doktoranzahl',
      createdDate: 'Erstellungs Datum',
      currentParticipantNumber: 'Patienten',
      currentPhysicianNumber: 'Doktoren',
      nbSurveys: 'Umfragen'
    }
  }
}
