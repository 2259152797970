// @flow
import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import { Translate } from 'react-redux-i18n'
import { browserHistory } from 'react-router'
import { keycloakActions } from 'common-docdok/src/common/Keycloak/actions'
import Tooltip from '@material-ui/core/Tooltip'
import { Menu } from '../../Mui'

type PropsType = {
  profile: ?{ firstName: string, avatarPicture: string },
  logout: typeof keycloakActions.logout
}

type StateType = {
  showDefaultAvatar: boolean,
  anchorEl: ?any
}

export default class HeaderAvatar extends React.Component<PropsType, StateType> {
  state = {
    showDefaultAvatar: false,
    anchorEl: undefined
  }

  componentWillReceiveProps(nextProps: PropsType) {
    if (
      nextProps.profile
      && this.props.profile
      && nextProps.profile.avatarPicture !== this.props.profile.avatarPicture
    ) {
      this.setState({ showDefaultAvatar: false })
    }
  }

  handleMenu = (event: any) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { profile, logout } = this.props

    if (!profile) {
      return null
    }

    const { anchorEl, showDefaultAvatar } = this.state
    const open = Boolean(anchorEl)
    const { firstName, avatarPicture } = profile

    const avatarSize = 35
    let avatar = (
      <Avatar
        src={avatarPicture}
        size={avatarSize}
        onError={() => {
          this.setState({ showDefaultAvatar: true })
        }}
        onLoad={() => {
          this.setState({ showDefaultAvatar: false })
        }}
      />
    )

    if (showDefaultAvatar) {
      avatar = <Avatar size={avatarSize}>{firstName[0]}</Avatar>
    }

    return (
      <div>
        <Tooltip title={<Translate value='header.profile' />}>
          <IconButton id='avatar-button' onClick={this.handleMenu} style={{ marginTop: -3 }}>
            {avatar}
          </IconButton>
        </Tooltip>
        <Menu
          id='menu-appbar'
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={open}
          onClose={this.handleClose}
        >
          <div>
            <MenuItem id='logout-button' onClick={logout}>
              <Translate value='header.logout' />
            </MenuItem>
            <MenuItem
              id='profile-button'
              onClick={() => {
                browserHistory.push('/private/app/profile')
              }}
            >
              <Translate value='header.profile' />
            </MenuItem>
          </div>
        </Menu>
      </div>
    )
  }
}
