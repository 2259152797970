// @flow
import { put, takeEvery } from 'redux-saga/effects'
import type { ExtractReturn, GenType } from 'common-docdok/src/types'
import { asyncAction } from 'common-docdok/src/utils/sagaUtils'

import { messagingActions, messagingActionTypes } from 'common-docdok/src/domain/messaging/actions'
import constants from 'common-docdok/src/utils/constants'
import { healthrelationActions } from 'common-docdok/src/domain/healthrelation/actions'
import { socketActionTypes, socketActions } from '../actions'
import { decryptAction } from '../messaging'
import { isPathAccessable } from '../../common/Security/containers/path'

export function* loadSubAndNotifiyFromMsg(
  action: ExtractReturn<typeof socketActions.loadSubAndNotifyFromMsg>
): GenType {
  const { userRef } = action.payload
  if (constants.UNKNOWN_USER_REF !== userRef) {
    const loadSubscriptions = yield asyncAction(messagingActions.getSubscriptionsRequested(false, userRef))
    if (loadSubscriptions.succeeded) {
      const [sub] = loadSubscriptions.succeeded.payload
      const {
        conversation: { secret }
      } = sub
      yield put(decryptAction(action, secret))
    }
  }
}

export function* maybeReloadAppointments(action: ExtractReturn<typeof messagingActions.postMessageSucceeded>): GenType {
  if (isPathAccessable('/appointment')) {
    const { meta } = action.payload
    if (meta && meta.notificationtype === 'APPOINTMENT') {
      yield asyncAction(healthrelationActions.upcomingAppointmentsRequested())
    }
  }
}

export default function notificationsSaga(): Array<GenType> {
  return [
    takeEvery(socketActionTypes.LOAD_SUB_AND_NOTIFY_FROM_MSG, loadSubAndNotifiyFromMsg),
    takeEvery(messagingActionTypes.POST_MESSAGE_SUCCEEDED, maybeReloadAppointments)
  ]
}
