// @flow
import type { ActionType, ExtractReturn } from 'common-docdok/src/types'
import { createUserDialogActionTypes, createUserDialogActions } from '../actions'

export type CreateUserDialogStateType = {
  showCreateUserDialog: boolean
}

const initialState: CreateUserDialogStateType = {
  showCreateUserDialog: false
}

function setCreateUserDialog(
  state: CreateUserDialogStateType,
  action: ExtractReturn<typeof createUserDialogActions.setCreateUserDialog>
): CreateUserDialogStateType {
  return {
    ...state,
    showCreateUserDialog: action.payload
  }
}

const ACTION_HANDLERS = {
  [createUserDialogActionTypes.SET_CREATE_USER_DIALOG]: setCreateUserDialog
}

export default function createUserDialogReducer(
  state: CreateUserDialogStateType = initialState,
  action: ActionType
): CreateUserDialogStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
