// @flow
import React from 'react'
import type { UserDtoType } from 'common-docdok/src/domain/user/types/userDto'
import type { PatientDtoType } from 'common-docdok/src/domain/healthrelation/types/patientDto'

import toString from 'common-docdok/src/utils/toString'
import { TextLine } from '../../Static'

type PropsType = {
  entity: ?UserDtoType | ?PatientDtoType,
  id?: string,
  className?: string,
  maxLenght?: number,
  style?: Object
}

export default class UserName extends React.Component<PropsType> {
  static defaultProps = {
    id: undefined,
    className: undefined,
    maxLenght: undefined,
    style: undefined
  }

  render() {
    const { entity } = this.props
    if (!entity) return null

    const name = toString.person(entity)
    return <TextLine {...this.props}>{name}</TextLine>
  }
}
