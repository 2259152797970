// @flow
import type { SurveyScheduleSpecType } from './surveyScheduleSpec'

export const fieldNames = {
  alertAfterHours: 'alertAfterHours',
  id: 'id',
  remindAfterHours: 'remindAfterHours',
  schedule: 'schedule',
  studyId: 'studyId',
  surveyId: 'surveyId',
  surveyName: 'surveyName',
  writeAccess: 'writeAccess',
  score: 'score',
  differenceDays: 'differenceDays'
}

export const fields = {
  alertAfterHours: {
    type: 'number',
    format: 'int64'
  },
  id: {
    type: 'number',
    format: 'int64'
  },
  remindAfterHours: {
    type: 'number',
    format: 'int64'
  },
  schedule: { type: 'SurveyScheduleSpec' },
  studyId: {
    type: 'number',
    format: 'int64'
  },
  surveyId: {
    type: 'number',
    format: 'int64'
  },
  surveyName: { type: 'string' },
  writeAccess: {
    type: 'string',
    enum: ['PATIENT_AND_HCP', 'PATIENT_ONLY', 'HCP_ONLY']
  },
  score: {
    type: 'Array<string>',
    clientSideSort: true
  },
  differenceDays: {
    type: 'number',
    format: 'int64',
    orderBy: 'creationDate'
  }
}

export type StudySurveyDtoType = {
  alertAfterHours: number,
  id: number,
  remindAfterHours: number,
  schedule: SurveyScheduleSpecType,
  studyId: number,
  surveyId: number,
  surveyName: string,
  writeAccess: string,
  score: Array<string>,
  differenceDays: number
}
