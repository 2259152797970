// @flow

const SIZE_1KB = 1000
const SIZE_1MB = 1000000

function bytes(size: number): string {
  let result
  if (size < SIZE_1KB) {
    result = `${size} Bytes`
  } else if (size < SIZE_1MB) {
    result = `${Math.round(size * 0.001)} KB`
  } else {
    result = `${Math.round(size * 0.000001)} MB`
  }
  return result
}

export function person(entity?: Object, empty?: string = '') {
  if (entity) {
    const salutation = entity.salutation ? `${entity.salutation} ` : ''
    const firstName = entity.firstName ? `${entity.firstName} ` : ''
    const lastName = entity.lastName ? `${entity.lastName}` : ''
    return `${salutation}${firstName}${lastName}`
  }
  return empty
}

export default {
  bytes,
  person
}
