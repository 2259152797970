export default {
  en: {
    name: 'Theme',
    fields: {
      id: 'Id',
      name: 'Name',
      createdDate: 'Upload date',
      lastModifiedDate: 'Last update date'
    }
  },
  de: {
    name: 'Themenbereiche',
    fields: {
      id: 'Id',
      name: 'Name',
      createdDate: 'Upload Datum',
      lastModifiedDate: 'Datum der letzten Aktualisierung'
    }
  }
}
