// @flow
import React from 'react'
import FormHelperText from '@material-ui/core/FormHelperText'
import { withStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import { Translate, I18n } from 'react-redux-i18n'
import { sortOptionsByTranslation } from '../utils'
import type { FieldComponentPropsType } from './formField'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    minWidth: 256,
    maxWidth: 256
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing.unit / 4
  }
})

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const TOTAL_HEIGHT = ITEM_HEIGHT * 4.5
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: TOTAL_HEIGHT + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

type StateType = {
  selection: Array<string>
}

type PropsType = FieldComponentPropsType & {
  classes: Object
}

class MultiSelectField extends React.Component<PropsType, StateType> {
  handleChange = (event) => {
    this.props.input.onChange(event.target.value)
  }

  render() {
    const {
      input,
      disabled,
      meta: { touched, error, form },
      propertyDefinition: { options },
      classes
    } = this.props
    const { name, value } = input
    const currentValue = value || []
    /*    const index = 0 // TODO: pass index */
    const opts = options || []
    const dataSource = opts.sort(sortOptionsByTranslation(form, name)).map(option => ({
      text: `forms.${form}.${name}Opts.${option.key}`,
      value: option.value
    }))

    return (
      <FormControl className={classes.formControl} margin='normal'>
        <InputLabel htmlFor={`forms.${form}.${name}`}>
          <Translate value={`forms.${form}.${name}`} />
        </InputLabel>
        <Select
          multiple
          disabled={disabled}
          value={currentValue}
          error={Boolean(touched && error)}
          onChange={this.handleChange}
          input={<Input id={`${form}-${name}`} data-test={`${form}-${name}`} />}
          renderValue={selected => (
            <div className={classes.chips}>
              {selected.map(item => I18n.t(`forms.${form}.${name}Opts.${item}`)).join(', ')}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {dataSource.map(data => (
            <MenuItem key={data.value} value={data.value}>
              <Checkbox checked={value.indexOf(data.value) > -1} />
              <ListItemText primary={<Translate value={data.text} />} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error={Boolean(touched && error)}>{touched && error}</FormHelperText>
      </FormControl>
    )
  }
}

export default withStyles(styles, { withTheme: true })(MultiSelectField)
