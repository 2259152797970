// @flow
import type { MessageDtoType } from 'common-docdok/src/domain/messaging/types/messageDto'

export const socketActionTypes = {
  LOAD_SUB_AND_NOTIFY_FROM_MSG: 'LOAD_SUB_AND_NOTIFY_FROM_MSG'
}
export type SocketActionType = $Keys<typeof socketActionTypes>
export const socketActions = {
  loadSubAndNotifyFromMsg(payload: MessageDtoType) {
    return {
      type: socketActionTypes.LOAD_SUB_AND_NOTIFY_FROM_MSG,
      payload
    }
  }
}
