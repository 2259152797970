// @flow
import { roles } from 'common-docdok/src/domain/user/types/userDto'

export const canLaunchSurvey = (userAuthorities: Array<string>) => (writeAccess: ?string) => {
  const mapping = {
    PATIENT_AND_HCP: [roles.patient, roles.physician, roles.studyCoordinator, roles.mpa],
    PATIENT_ONLY: [roles.patient],
    HCP_ONLY: [roles.physician, roles.studyCoordinator, roles.mpa]
  }

  const supportedRoles = (writeAccess && mapping[writeAccess]) || []

  return userAuthorities.reduce((currentVal, role) => supportedRoles.includes(role) || currentVal, false)
}
