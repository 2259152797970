// @flow
import React from 'react'
import styled from 'styled-components'

const SurroundedDiv = styled.div`
  display: flex;
  justify-content: center;
`

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  line-height: normal;
`
const TextLineDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
`

const QuestionLineDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const BorderDiv = styled.div`
  text-align: center;
  padding: 5px;
`

const RateDescriptionDiv = styled.div`
  max-width: 30vw;
  margin-bottom: 5px;

  @media (min-width: 768px) {
    max-width: 20vw;
  }
`

const StyledLabel = styled.label`
  width: ${props => `${props.labelWidth}em`};
  @media (min-width: 768px) {
    width: 6em;
  }
`

type PropsType = {
  question: Object,
  onRatingChange: Function
}

class TwoLineRating extends React.Component<PropsType> {
  render() {
    const { question } = this.props
    const { cssClasses } = question
    const values = []

    for (let i = 0; i < question.visibleRateValues.length; i += 1) {
      values.push(this.renderItem(`value${i}`, question.visibleRateValues[i], cssClasses))
    }

    return (
      <div>
        <SurroundedDiv>
          <ContainerDiv>
            <TextLineDiv>
              <RateDescriptionDiv>{question.minRateDescription}</RateDescriptionDiv>
              <RateDescriptionDiv style={{ textAlign: 'right' }}>{question.maxRateDescription}</RateDescriptionDiv>
            </TextLineDiv>
            <QuestionLineDiv>{values}</QuestionLineDiv>
          </ContainerDiv>
        </SurroundedDiv>
      </div>
    )
  }

  renderItem(key: string, item: any, cssClasses: any) {
    const { question } = this.props

    const isChecked = Number(question.value) === Number(item.value)
    let className = cssClasses.item
    if (isChecked) className += ` ${cssClasses.selected}`
    let itemText = item.itemValue
    if (itemText === undefined && item.propertyHash) {
      itemText = item.propertyHash.value
    }
    const labelWidth = question.visibleRateValues.length > 5 ? 2.0 : 3.5
    return (
      <BorderDiv key={key}>
        <StyledLabel labelWidth={labelWidth} className={className}>
          <input
            type='radio'
            style={{ display: 'none' }}
            name={question.name}
            value={item.value}
            checked={isChecked}
            onChange={this.handleOnChange}
            aria-label={item.locText.text}
          />
          <span>{itemText}</span>
        </StyledLabel>
      </BorderDiv>
    )
  }

  handleOnChange = (event: any) => {
    const { onRatingChange } = this.props
    onRatingChange(event.target.value)
  }
}

export default TwoLineRating
