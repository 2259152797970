// @flow
import { takeEvery, put } from 'redux-saga/effects'
import { surveyActions } from 'common-docdok/src/domain/survey/actions'
import { asyncAction } from 'common-docdok/src/utils/sagaUtils'
import type { GenType } from 'common-docdok/src/types'
import { stopSubmit } from 'redux-form'

import { dynamicFormActionTypes } from '../../DynamicForm/actions'
import { infoPanelActions } from '../../InfoPanel/actions'
import { formToScheduleModel } from '../../ScheduleSurveyDialog/utils/converter'


export function* handleEvent(payload: any): GenType {
  if (payload.name === 'editEvent' || payload.name === 'addEvent') {
    yield 1
  }
}

export function* uploadSurvey({ payload }: any): GenType {
  if (payload.name === 'uploadSurvey' || payload.name === 'updateSurvey') {
    const updatedSurveyDefinitionSummary = payload.data
    const {
      name,
      file,
      alertAfterHours,
      remindAfterHours,
      allowedClinicIds,
      restrictedAccess,
      id
    } = updatedSurveyDefinitionSummary
    const isUpload = payload.name === 'uploadSurvey'

    let surveyDef
    if (isUpload) {
      const upload = yield asyncAction(surveyActions.uploadLSSFileRequested(name, file.text))
      if (upload.failed) {
        yield put(stopSubmit(payload.name, upload.failed.payload.error))
        return
      }
      // use new created surveyDef as base
      surveyDef = upload.succeeded.payload.surveyDefinition
    } else {
      const surveyDefinitionsRequest = yield asyncAction(surveyActions.getSurveyDefinitionsByIdRequested([id]))
      if (surveyDefinitionsRequest.failed) {
        yield put(stopSubmit(payload.name, surveyDefinitionsRequest.failed.payload.error))
        return
      }
      // use existing surveyDef as base
      [surveyDef] = surveyDefinitionsRequest.succeeded.payload
    }

    const defaultScheduling = formToScheduleModel(updatedSurveyDefinitionSummary)
    const updatedSurveyDefintionDto = {
      ...surveyDef,
      ...updatedSurveyDefinitionSummary,
      alertAfterHours,
      remindAfterHours,
      allowedClinicIds,
      restrictedAccess,
      defaultScheduling
    }

    const update = yield asyncAction(surveyActions.updateSurveyDefinitionRequested(updatedSurveyDefintionDto))
    if (update.failed) {
      yield put(stopSubmit(payload.name, update.failed.payload.error))
      return
    }
    yield put(infoPanelActions.showMessage('surveys.actions.uploadSurvey.success'))
    yield put(stopSubmit(payload.name))
  }
}

export default function eventSaga(): Array<GenType> {
  return [takeEvery(dynamicFormActionTypes.SUBMIT_FORM, handleEvent)]
}
