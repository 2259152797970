// @flow
import React from 'react'
import { connect } from 'react-redux'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Translate } from 'react-redux-i18n'
import {
  findCurrentUser,
  isPatient
} from 'common-docdok/src/domain/user/selectors/findCurrentUser'
import UserAvatar from '../../UserAvatar'
import type { MessagePropsType } from './Message'
import { launchSurveyActions } from '../../LaunchSurvey/actions'

type PropsType = {
  launchSurvey: typeof launchSurveyActions.launchSurvey,
  isAPatient: boolean
} & MessagePropsType

function surveyInvitation(props: PropsType) {
  let result = null
  const {
    user,
    message: {
      meta: { surveyName, id }
    },
    closeRequest,
    launchSurvey,
    isAPatient
  } = props

  const startSurvey = () => {
    launchSurvey(Number(id))
    if (closeRequest) closeRequest()
  }

  const clickFn = startSurvey


  if (user) {
    result = (
      <ListItem
        button={isAPatient || true}
        id='survey-invitation-notification'
        onClick={clickFn}
      >
        <UserAvatar
          size={34}
          src={user ? user.avatarPicture : undefined}
          name={user ? user.firstName : ''}
        />
        <ListItemText
          primary={(
            <Translate
              value='surveys.notifications.surveyInvitation'
              user={user ? `${user.firstName} ${user.lastName}` : ''}
              survey={surveyName}
            />
)}
        />
      </ListItem>
    )
  }
  return result
}

const mapStateToProps = (state: StoreType) => {
  const currentUser = findCurrentUser(state)
  const isAPatient = !(currentUser.isNothing || !isPatient(currentUser.get()))

  return {
    isAPatient
  }
}

export default connect(mapStateToProps, {
  launchSurvey: launchSurveyActions.launchSurvey
})(surveyInvitation)
