export default {
  en: {
    name: 'Survey',
    fields: {
      id: 'Id',
      lsId: 'Limesurvey-Id',
      name: 'Name',
      surveyName: 'Name',
      nbCompleted: 'Completed',
      nbSent: 'Sent',
      ownerRef: 'Owner',
      schedule: 'Scheduled',
      status: 'Status',
      statusOpts: {
        RUNNING: 'Running',
        NOT_ACTIVE: 'Not Active'
      },
      url: 'Url',
      writeAccess: 'Respondent',
      writeAccessOpts: {
        PATIENT_AND_HCP: 'Patient or Professional',
        PATIENT_ONLY: 'Patient',
        HCP_ONLY: 'Professional'
      }
    }
  },
  de: {
    name: 'Umfrage',
    fields: {
      id: 'Id',
      lsId: 'Limesurvey-Id',
      name: 'Name',
      surveyName: 'Name',
      schedule: 'Wiederkehrend',
      nbCompleted: 'Abgeschlossen',
      nbSent: 'Versendet',
      ownerRef: 'Besitzer',
      status: 'Status',
      statusOpts: {
        RUNNING: 'Im Gange',
        NOT_ACTIVE: 'Nicht Aktiv'
      },
      url: 'Url',
      writeAccess: 'Befragter',
      writeAccessOpts: {
        PATIENT_AND_HCP: 'Patient oder Fachkraft',
        PATIENT_ONLY: 'Patient',
        HCP_ONLY: 'Fachkraft'
      }
    }
  }
}
