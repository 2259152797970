// @flow
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export type ShowNotificationType = (title: string, options: Object) => void;
export function showNotification(title: string, options: Object, url: string) {
  return {
    type: SHOW_NOTIFICATION,
    payload:{
      title,
      options,
      url
    }
  }
}
