// @flow
import React from 'react'

import FormFooter from './footer/formFooter'

import GeneratedFormLayout from './GeneratedFormLayout'
import { DialogContent } from '../../Mui'
import type { DynamicFormPropsType } from '../containers/DynamicForm'

export default function generatedForm(props: DynamicFormPropsType) {
  const {
    form,
    fields,
    render,
    data,
    addionalButtons,
    serverErrors,
    showReset,
    reset,
    formSubmitting,
    saveLabelKey,
    dirty,
    indentForm
  } = props

  const formComp = render ? render({ form, fields }, { ...data }) : <GeneratedFormLayout fields={fields} form={form} />

  return (
    <React.Fragment>
      {indentForm ? <DialogContent>{formComp}</DialogContent> : formComp}
      <FormFooter
        serverErrors={serverErrors}
        showReset={showReset}
        reset={reset}
        formSubmitting={formSubmitting}
        saveLabelKey={saveLabelKey}
        dirty={dirty}
        form={form}
      >
        {addionalButtons}
      </FormFooter>
    </React.Fragment>
  )
}

generatedForm.defaulProps = {
  addionalButtons: null
}
