// @flow

export const fieldNames = {
  conversationPicture: 'conversationPicture',
  id: 'id',
  meta: 'meta',
  newestMessageId: 'newestMessageId',
  secret: 'secret',
  title: 'title',
  type: 'type'
}

export const fields = {
  conversationPicture: { type: 'string' },
  id: {
    type: 'number',
    format: 'int64'
  },
  meta: { type: 'Object' },
  newestMessageId: {
    type: 'number',
    format: 'int64'
  },
  secret: { type: 'string' },
  title: { type: 'string' },
  type: {
    type: 'string',
    enum: ['CHAT', 'NOTIFICATION']
  }
}

export type ConversationDtoType = {
  conversationPicture: string,
  id: number,
  meta: Object,
  newestMessageId: number,
  secret: string,
  title: string,
  type: string
}
