// @flow
import React from 'react'
import type { UserDtoType } from 'common-docdok/src/domain/user/types/userDto'
import { roles } from 'common-docdok/src/domain/user/types/userDto'

import { studiesActions } from '../actions'
import DynamicFormDialog from '../../../../../common/DynamicFormDialog'
import CreatePhysicianButton from '../../Professionals/containers/createPhysicianButton'

type PropsType = {
  showAddPhysicianDialog: boolean,
  toggleAddPhysicianDialog: typeof studiesActions.toggleAddPhysicianDialog,
  studyId: number,
  physicians: Array<UserDtoType>
}

export default function addPhysicianDialg(props: PropsType) {
  const { showAddPhysicianDialog, toggleAddPhysicianDialog, studyId, physicians } = props
  return (
    <DynamicFormDialog
      open={showAddPhysicianDialog}
      handleClose={() => {
        toggleAddPhysicianDialog()
      }}
      addionalButtons={[<CreatePhysicianButton />]}
      titleKey='studies.actions.addStudyPhysicians.title'
      form='addStudyPhysicians'
      saveLabelKey='actions.add'
      initialValues={{ studyId, physicians, currentPhysicians: physicians }}
      fields={{
        physicians: {
          specName: 'userByRole',
          type: 'search',
          mandatory: true,
          multiSelectable: true,
          params: [roles.physician]
        }
      }}
    />
  )
}
