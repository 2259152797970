// @flow
import compose from 'ramda/src/compose'
import { connect } from 'react-redux'

import withMobileDialog from '@material-ui/core/withMobileDialog'
import EntityTable, { type EntityTablePropsType } from '../components/EntityTable'
import entityLoader from '../../EntityLoader'
import { entityTableActions } from '../actions'

// do not merge. entity Table is used in a unconnected way aswell

const mapStateToProps = (state: StoreType, ownProps: EntityTablePropsType) => {
  const { id } = ownProps
  const defaultPageSize = state.entityTable[id] && state.entityTable[id].pageSize
  return { defaultPageSize: defaultPageSize || ownProps.defaultPageSize }
}

export default compose(
  withMobileDialog(),
  connect(
    mapStateToProps,
    entityTableActions
  ),
  entityLoader
)(EntityTable)
