export default {
  en: {
    team: 'Team',
    createPhysician: {
      title: 'Create Physician',
      success: 'Physician was successfully created'
    },
    createMpa: {
      title: "Create Doctor's Assistant",
      success: "Doctor's Assistant was successfully created"
    }
  },
  de: {
    team: 'Team',
    createPhysician: {
      title: 'Doktor erstellen',
      success: 'Doktor wurde erfolgreich erstellt'
    },
    createMpa: {
      title: 'Medizinische Praxis Assistent/in erstellen',
      success: 'Medizinische Praxis Assistent/in wurde erfolgreich erstellt'
    }
  }
}
