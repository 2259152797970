export default {
  en: {
    // -- APPEND ENTRY HERE (EN) --
    catalog: 'Catalog',
    home: 'Home',
    profile: 'Profile',
    users: 'Users',
    surveys: 'Surveys',
    surveysParticipants: 'Participations',
    surveysParticipantsCompleted: 'Completed',
    patients: 'Patients',
    team: 'Team',
    messages: 'Messages',
    notifications: 'Notifications',
    exportUOBData: 'Export UOB Data',
    help: 'Customer Support',
    billing: 'Billing',
    clinics: 'Clinics',
    studies: 'Studies',
    appointments: 'Appointments',
    overview: 'Overview',
    diga1: 'DiGA1'
  },
  de: {
    // -- APPEND ENTRY HERE (DE) --
    catalog: 'Katalog',
    home: 'Startseite',
    profile: 'Profil',
    users: 'Benutzer',
    surveys: 'Umfragen',
    surveysParticipants: 'Teilnehmer',
    surveysParticipantsCompleted: 'Abgeschlossen',
    patients: 'Patienten',
    team: 'Team',
    messages: 'Nachrichten',
    notifications: 'Benachrichtigungen',
    exportUOBData: 'UOB Daten Exportieren',
    help: 'Kundendienst',
    billing: 'Abrechnung',
    clinics: 'Kliniken',
    studies: 'Studien',
    appointments: 'Anstehende Termine',
    overview: 'Überblick',
    diga1: 'DiGA1'
  }
}
