// @flow
import { connect } from 'react-redux'


import GlobalLoadingBar from '../components/globalLoadingBar'

const mapStateToProps = (state: StoreType) => ({
  isLoading: state.loadingStack.isLoading
})

export default connect(mapStateToProps)(GlobalLoadingBar)
