/**
 * @flow
 */

export const clinicsActionTypes = {
  SET_CREATE_CLINIC_DIALOG: 'SET_CREATE_CLINIC_DIALOG',
  SET_UPDATE_CLINIC_DIALOG: 'SET_UPDATE_CLINIC_DIALOG'
}

export type ClinicsActionType = $Keys<typeof clinicsActionTypes>

export const clinicsActions = {
  setCreateClinicDialog(payload: boolean) {
    return {
      type: clinicsActionTypes.SET_CREATE_CLINIC_DIALOG,
      payload
    }
  },
  setUpdateClinicDialog(payload: boolean) {
    return {
      type: clinicsActionTypes.SET_UPDATE_CLINIC_DIALOG,
      payload
    }
  }
}
