// @flow
import React from 'react'
import { connect } from 'react-redux'
import 'moment/locale/de' // this is the important bit, you have to import the locale your'e trying to use.
import moment from 'moment'
import { Translate } from 'react-redux-i18n'

import type { FieldComponentPropsType } from './formField'

type PropsType = {
  locale: string,
  shouldDisableDate: ?Function,
} & FieldComponentPropsType

class DatePickerField extends React.Component<PropsType> {
  datepicker: any

  openDatePicker() {
    if (this.datepicker) this.datepicker.openDialog()
  }

  render() {
    const {
      disabled,
      input: { name, value, onChange },
      meta: { touched, error, form }
    } = this.props

    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */}
        <label
          style={{
            fontSize: 12,
            color: '#5f5f5f',
            marginTop: 8
          }}
          htmlFor="time-picker"
        >
          <span>
            <Translate value={`forms.${form}.${name}`} />
          </span>
        </label>
        <input
          id="time-picker"
          style={{ width: '80%', padding: 8 }}
          name={name}
          value={moment(value).format('HH:mm')}
          defaultValue={moment(value).format('HH:mm')}
          type="time"
          placeholder={<Translate value={`forms.${form}.${name}`} />}
          onChange={(e) => {
            const date = value ? moment(value) : moment()
            date.set({
              hours: String(e.target.value).split(':')[0],
              minutes: String(e.target.value).split(':')[1]
            })
            onChange(moment(date).toDate())
          }}
          data-test={`${form}-${name}`}
          disabled={disabled}
          pattern="[0-9]{2}:[0-9]{2}"
        />
        {touched && error ? (
          <span style={{ color: '#f44336' }} htmlFor="time-picker">{error}</span>
        ) : null}
      </>
    )
  }
}

export default connect((state: StoreType) => ({ locale: state.i18n.locale }))(
  DatePickerField
)
