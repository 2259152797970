/**
 * @flow
 */

export const introActionTypes = {
  START_TOUR: 'START_TOUR',
  RESET_TOURS: 'RESET_TOURS',
  TOUR_FINISHED: 'TOUR_FINISHED',
  REGISTER_TOUR: 'REGISTER_TOUR',
  UNREGISTER_TOUR: 'UNREGISTER_TOUR',
  START_VISIBLE_TOURS: 'START_VISIBLE_TOURS'
}

export type IntroActionType = $Keys<typeof introActionTypes>

export const introActions = {
  startVisibleTours() {
    return {
      type: introActionTypes.START_VISIBLE_TOURS
    }
  },
  registerTour(tourId: string) {
    return {
      type: introActionTypes.REGISTER_TOUR,
      payload: tourId
    }
  },
  unregisterTour(tourId: string) {
    return {
      type: introActionTypes.UNREGISTER_TOUR,
      payload: tourId
    }
  },
  startTour(tourId: string, force: boolean = false) {
    return {
      type: introActionTypes.START_TOUR,
      payload: { tourId, force }
    }
  },

  resetTours() {
    return {
      type: introActionTypes.RESET_TOURS
    }
  },
  tourFinsihed(tourId: string) {
    return {
      type: introActionTypes.TOUR_FINISHED,
      payload: tourId
    }
  }
}
