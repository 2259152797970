// @flow
import React from 'react'
import styled from 'styled-components'
import palette from '../../styles/palette'
import { SublinedTypography } from '../Mui'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: ${props => (props.noWrap ? 'nowrap' : 'wrap')};
  ${props => props.vertical && 'flex-direction: column;'} > * {
    /*   flex: 1; */
    &:not(:first-child) {
      ${props => (props.vertical ? 'margin-top' : 'margin-left')}: 1%;
    }
  }
  @media screen and (orientation: portrait) {
    flex-wrap: ${props => (props.noWrap ? 'nowrap' : 'wrap')};
    ${props => props.vertical && 'flex-direction: column;'} > * {
      /*   flex: 1; */
      &:not(:first-child) {
        ${props => (props.vertical ? 'margin-top' : 'margin-left')}: 0;
      }
    }
  }
`

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  min-height: 56px;
  padding: 0em 0.5em 0em 0.5em;
`

export const ToolbarGroup = styled.div`
  display: flex;
  align-items: flex-end;
  &:first-child {
    flex-grow: 2;
  }
`

export const Buttons = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
`

export const Item = styled.div``

export const Spacer = styled.div`
  height: ${({ height }) => height}px;
`

export const Clickable = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin: 0;
  text-align: inherit;
`

export const PageTitle = ({ children, ...rest }: Object) => (
  <SublinedTypography variant='h4' {...rest}>
    {children}
  </SublinedTypography>
)

export const TextLine = styled.div`
  overflow: hidden;
  width: 100%;
  display: block;
  text-overflow: ellipsis;
`
export const GreyBar = styled.div`
  background-color: ${palette.greyLight};
`
export const Link = ({ children, onClick, ...rest }: Object) => (
  <a
    href='#'
    {...rest}
    onClick={(event) => {
      onClick(event)
      event.preventDefault()
    }}
  >
    {children}
  </a>
)

export const Bubble = styled.div`
  border-radius: 16px;
  padding: 5px;
`
export const Table = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3em 0;
  padding: 0;
  > * {
    width: ${props => 100 / props.cols}%;
  }
`
export const Cell = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  width: 50%; // Default to full width
  padding: 0.8em 1.2em;
  overflow: hidden; // Or flex might break
  list-style: none;
  border: solid @bw white;
  background: fade(slategrey, 20%);
`

export const Centered = styled.div`
  margin: auto;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`

export const AlignMiddle = styled.div`
  display: flex;
  justify-content: center
`
