/**
 * @flow
 */
export const messagesActionTypes = {
  SET_SHOW_SUBSCRIPTIONS: 'SET_SHOW_SUBSCRIPTIONS',
  SET_SHOW_CREATE_CONVERSATION: 'SET_SHOW_CREATE_CONVERSATION',
  SET_SHOW_MASS_DIALOG: 'SET_SHOW_MASS_DIALOG',
  SET_MESSAGE_READ: 'SET_MESSAGE_READ'
}

export type MessagesActionType = $Keys<typeof messagesActionTypes>

export const messagesActions = {
  setShowSubscriptions(show: boolean) {
    return {
      type: messagesActionTypes.SET_SHOW_SUBSCRIPTIONS,
      payload: show
    }
  },

  setShowCreateConversation(show: boolean) {
    return {
      type: messagesActionTypes.SET_SHOW_CREATE_CONVERSATION,
      payload: show
    }
  },
  setShowMassSendingDialog(show: boolean) {
    return {
      type: messagesActionTypes.SET_SHOW_MASS_DIALOG,
      payload: show
    }
  }
}
