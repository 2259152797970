// @flow

export const entityTableActionTypes = {
  SET_PAGE_SIZE: 'SET_PAGE_SIZE'
}

export type EntityTableActionType = $Keys<typeof entityTableActionTypes>

export const entityTableActions = {
  setPageSize(id: string, pageSize: number) {
    return {
      type: entityTableActionTypes.SET_PAGE_SIZE,
      payload: { id, pageSize }
    }
  }
}
