export const fieldNames = {
  id: 'id',
  orgName: 'orgName',
  orgId: 'orgId',
  externalId: 'externalId'
}

export const fields = {
  id: {
    type: 'number',
    format: 'int64'
  },
  orgName: { type: 'string' },
  orgId: { type: 'string' },
  externalId: { type: 'string' }
}

export type OrganizationDtoType = {
  id: number,
  orgName: string,
  orgId: string,
  externalId: string
}
