// @flow
import { put, call, takeEvery, select } from 'redux-saga/effects'
import api from 'common-docdok/src/api'
import type { ExtractReturn, GenType } from 'common-docdok/src/types'
import { messagingActions } from 'common-docdok/src/domain/messaging/actions'
import { asyncAction } from 'common-docdok/src/utils/sagaUtils'
import { browserHistory } from 'react-router'
import { findCurrentUser, isPatient } from 'common-docdok/src/domain/user/selectors/findCurrentUser'
import primaryConversationSelector from '../selectors/primaryConSelector'
import { chatActions } from '../../Chat/actions'
import { ambulanceQueueItemsActions, ambulanceQueueItemsActionTypes } from '../actions'
import { infoPanelActions } from '../../InfoPanel/actions'

// -- APPEND GENERATORS HERE --
function* getAmbulanceWaitingPositionRequested(): GenType {
  try {
    const result = yield call(api.getWaitingPositionInAmbulanceQueue)
    yield put(ambulanceQueueItemsActions.getAmbulanceWaitingPositionSucceeded(result.data))
  } catch (error) {
    yield put(ambulanceQueueItemsActions.getAmbulanceWaitingPositionFailed(error))
  }
}

function* getAmbulanceQueueItems(): GenType {
  try {
    const result = yield call(api.getAmbulanceQueueItem)
    yield put(ambulanceQueueItemsActions.getAmbulanceQueueItemsSucceeded(result.data))
  } catch (error) {
    yield put(ambulanceQueueItemsActions.getAmbulanceQueueItemsFailed(error))
  }
}

function* queueUpdated(): GenType {
  const currentUser = yield select(findCurrentUser)
  if (currentUser.isJust) {
    if (!isPatient(currentUser.get())) {
      try {
        const result = yield call(api.getAmbulanceQueueItem)
        yield put(ambulanceQueueItemsActions.getAmbulanceQueueItemsSucceeded(result.data))
      } catch (error) {
        yield put(ambulanceQueueItemsActions.getAmbulanceQueueItemsFailed(error))
      }
    } else {
      try {
        const result = yield call(api.getWaitingPositionInAmbulanceQueue)
        yield put(ambulanceQueueItemsActions.getAmbulanceWaitingPositionSucceeded(result.data))
      } catch (error) {
        yield put(ambulanceQueueItemsActions.getAmbulanceWaitingPositionFailed(error))
      }
    }
  }
}

function*
setItemIntoProgress(action: ExtractReturn<typeof ambulanceQueueItemsActions.setItemIntoProgressRequested>): GenType {
  try {
    const result = yield call(api.setAmbulanceQueueItemIntoProgress, action.payload.patientUserRef)
    yield put(ambulanceQueueItemsActions.setItemIntoProgressSucceeded(result.data))
  } catch (error) {
    yield put(ambulanceQueueItemsActions.setItemIntoProgressFailed(error))
  }
}


function* setItemToFinished(
  action: ExtractReturn<typeof ambulanceQueueItemsActions.setItemToFinishedRequested>): GenType {
  try {
    const result = yield call(api.setAmbulanceQueueItemFinished, action.payload.patientUserRef)
    yield put(ambulanceQueueItemsActions.setItemToFinishedSucceeded(result.data))
  } catch (error) {
    yield put(ambulanceQueueItemsActions.setItemToFinishedFailed(error))
  }
}

function* setItemToError(action: ExtractReturn<typeof ambulanceQueueItemsActions.setItemToErrorRequested>): GenType {
  try {
    const result = yield call(api.setAmbulanceQueueItemToError, action.payload.patientUserRef)
    yield put(ambulanceQueueItemsActions.setItemToErrorSucceeded(result.data))
  } catch (error) {
    yield put(ambulanceQueueItemsActions.setItemToErrorFailed(error))
  }
}

function* navigateToSelectedQueueItem(
  action: ExtractReturn<typeof ambulanceQueueItemsActions.navigateToConversationOfItem>): GenType {
  try {
    const { item: { userRef, patientUuid }, invite } = action.payload

    yield asyncAction(messagingActions.joinPrimaryConversationRequested(userRef, patientUuid))

    const winner = yield asyncAction(messagingActions.getSubscriptionsRequested(false, userRef))
    if (winner.succeeded) {
      const cid = winner.succeeded.payload[0].conversationId
      if (invite) {
        yield put(chatActions.invite2VideoConferenceRequested(cid))
      }
      browserHistory.push(`/private/app/messages/conversation/${cid}`)
    }
  } catch (error) {
    yield put(ambulanceQueueItemsActions.setItemToErrorFailed(error))
  }
}

function* navigateToChat() {
  const currentUser = yield select(findCurrentUser)
  if (currentUser.isJust) {
    const conversationId = yield select((state: StoreType) => primaryConversationSelector(state,
      currentUser.get().uid))
    browserHistory.push(`/private/app/messages/conversation/${conversationId}`)
  }
}

function* setMySelf2Waiting(): GenType {
  try {
    yield call(api.setMyselfToWaiting)
    yield put(ambulanceQueueItemsActions.setMySelfToWaitingSucceeded())
    yield navigateToChat()
    yield put(infoPanelActions.showMessage('ambulance.messageSuccess'))
  } catch (error) {
    yield put(ambulanceQueueItemsActions.setMySelfToWaitingFailed(error))
  }
}


function* setMySelf2NotWaiting(): GenType {
  try {
    yield call(api.setMyselfToNotWaiting)
    yield put(ambulanceQueueItemsActions.setMySelfToNotWaitingSucceeded())
    yield put(infoPanelActions.showMessage('ambulance.messageRemoved'))
  } catch (error) {
    yield put(ambulanceQueueItemsActions.setMySelfToNotWaitingFailed(error))
  }
}

export default function ambulanceQueueSaga(): Array<GenType> {
  return [
    // -- APPEND TAKES HERE --
    takeEvery(ambulanceQueueItemsActionTypes.GET_AMBULANCE_QUEUE_ITEMS_REQUESTED, getAmbulanceQueueItems),
    takeEvery(ambulanceQueueItemsActionTypes.SET_IN_PROGRESS_AMBULANCE_QUEUE_ITEM_REQUESTED, setItemIntoProgress),
    takeEvery(ambulanceQueueItemsActionTypes.SET_TO_FINISHED_AMBULANCE_QUEUE_ITEM_REQUESTED, setItemToFinished),
    takeEvery(ambulanceQueueItemsActionTypes.SET_TO_ERROR_AMBULANCE_QUEUE_ITEM_REQUESTED, setItemToError),
    takeEvery(ambulanceQueueItemsActionTypes.QUEUE_UPDATED, queueUpdated),
    takeEvery(ambulanceQueueItemsActionTypes.GET_AMBULANCE_QUEUE_ITEMS_REQUESTED, getAmbulanceQueueItems),
    takeEvery(ambulanceQueueItemsActionTypes.NAVIGATE_TO_CONVERSATION_OF_ITEM, navigateToSelectedQueueItem),
    takeEvery(ambulanceQueueItemsActionTypes.SET_MYSELF_2_WAITING_REQUESTED, setMySelf2Waiting),
    takeEvery(ambulanceQueueItemsActionTypes.SET_MYSELF_2_NOT_WAITING_REQUESTED, setMySelf2NotWaiting),
    takeEvery(ambulanceQueueItemsActionTypes.GET_AMBULANCE_WAITING_POSITION_REQUESTED,
      getAmbulanceWaitingPositionRequested)
  ]
}
