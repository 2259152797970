import type { MessageDtoType } from '../types/messageDto'

export const pageSize = 20
export function getPagesToLoad(messages: Array<MessageDtoType>): Array<number> {
  const noMessagesLoaded = messages.length
  const highestPage = Math.floor(noMessagesLoaded / pageSize)
  const pagesToLoad = []
  if (messages[0].sequenceNo > 0) {
    pagesToLoad.push(highestPage)
  }
  return pagesToLoad
}
