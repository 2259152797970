// @flow
import { emailRgx, mobileRgx } from '../../../utils/rgx'

export const fieldNames = {
  activated: 'activated',
  avatarPicture: 'avatarPicture',
  class: 'class',
  clinicId: 'clinicId',
  clinicPublicUuid: 'clinicPublicUuid',
  email: 'email',
  firstName: 'firstName',
  firstname: 'firstname',
  gender: 'gender',
  langKey: 'langKey',
  lastName: 'lastName',
  lastname: 'lastname',
  mobileNumber: 'mobileNumber',
  origin: 'origin',
  publicUuid: 'publicUuid',
  salutation: 'salutation',
  userRef: 'userRef',
  uuid: 'uuid'
}

export const fields = {
  activated: { type: 'boolean' },
  avatarPicture: {
    type: 'string'
  },
  class: {
    type: 'string',
    enum: ['PHYSICIAN', 'MPA']
  },
  clinicId: {
    type: 'number',
    format: 'int64'
  },
  clinicPublicUuid: { type: 'string' },
  email: {
    type: 'string',
    mandatory: true,
    minLength: 5,
    maxLength: 100,
    pattern: emailRgx,
    patternNice: 'me@domain.com'
  },
  firstname: {
    type: 'string',
    maxLength: 50,
    mandatory: true,
    primary: true
  },
  firstName: {
    type: 'string',
    maxLength: 50,
    mandatory: true,
    primary: true
  },
  gender: {
    type: 'string',
    mandatory: true,
    enum: ['MALE', 'FEMALE', 'DIVERS']
  },
  langKey: {
    type: 'select',
    enum: ['en', 'fr', 'de', 'it'],
    mandatory: true
  },
  lastname: {
    type: 'string',
    maxLength: 50,
    mandatory: true,
    primary: true
  },
  lastName: {
    type: 'string',
    maxLength: 50,
    mandatory: true,
    primary: true
  },
  mobileNumber: {
    type: 'string',
    minLength: 0,
    maxLength: 20,
    pattern: mobileRgx,
    patternNice: '+11223344',
    normalize: (nbr: string) => nbr.replace(/\s/g, '')
  },
  origin: {
    type: 'string',
    enum: ['PIS', 'IMEDONE', 'DOCDOK']
  },
  publicUuid: { type: 'string' },

  salutation: {
    type: 'autoComplete',
    maxLength: 100,
    mandatory: true,
    primary: true,
    options: [
      { value: 'Mr', key: 'Mr' },
      { value: 'Ms', key: 'Ms' },
      { value: 'Mrs', key: 'Mrs' },
      { value: 'Prof', key: 'Prof' },
      { value: 'Dr', key: 'Dr' },
      { value: 'PractMed', key: 'PractMed' }
    ]
  },
  userRef: { type: 'string' },
  uuid: { type: 'string' }
}

export type HealthcareProfessionalDtoType = {
  activated: boolean,
  avatarPicture: string,
  class: string,
  clinicId: number,
  clinicPublicUuid: string,
  email: string,
  firstName: string,
  firstname: string,
  gender: string,
  langKey: string,
  lastName: string,
  lastname: string,
  mobileNumber: string,
  origin: string,
  publicUuid: string,
  salutation: string,
  userRef: string,
  uuid: string
};
