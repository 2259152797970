// @flow
import { takeEvery, put, call } from 'redux-saga/effects'
import { stopSubmit } from 'redux-form'
import type { GenType } from 'common-docdok/src/types'
import api from 'common-docdok/src/api'
import { I18n } from 'react-redux-i18n'

import { dynamicFormActionTypes, type SubmitFormType } from '../../DynamicForm/actions'
import { downloadSVC } from '../../utils'
import { exportStudyDialogActions } from '../actions'

export function* exportStudyReportGen({ payload }: SubmitFormType): GenType {
  if (payload.name === 'studyReport') {
    try {
      const { studyId, studyReportDefinitionId } = payload.data
      const result = yield call(api.getStudyReport, studyId, studyReportDefinitionId)

      downloadSVC(`${I18n.t('surveys.studyReport.fileName')}.csv`, result.data)
      yield put(stopSubmit(payload.name))
      yield put(exportStudyDialogActions.toggleExportStudyDialog())
    } catch (e) {
      yield put(stopSubmit(payload.name, e))
    }
  }
}

export default function studiesSaga(): Array<GenType> {
  return [takeEvery(dynamicFormActionTypes.SUBMIT_FORM, exportStudyReportGen)]
}
