// @flow

import { takeEvery, put } from 'redux-saga/effects'
import { stopSubmit } from 'redux-form'
import { asyncAction } from 'common-docdok/src/utils/sagaUtils'
import type { GenType } from 'common-docdok/src/types'

import { healthrelationActions } from 'common-docdok/src/domain/healthrelation/actions'
import type { SubmitFormType } from '../../../../../common/DynamicForm/actions'
import { infoPanelActions } from '../../../../../common/InfoPanel/actions'
import { professionalsActions } from '../actions'
import { dynamicFormActionTypes } from '../../../../../common/DynamicForm/actions'

function* createPhysician({ payload }: SubmitFormType): GenType {
  if (payload.name === 'createPhysician') {
    const physician = payload.data
    const winner = yield asyncAction(healthrelationActions.createPhysicianRequested(physician))

    if (winner.succeeded) {
      yield put(stopSubmit(payload.name))
      yield put(professionalsActions.toggleCreatePhysicianDialog())
      yield put(infoPanelActions.showMessage('professionals.createPhysician.success'))
    } else {
      yield put(stopSubmit(payload.name, winner.failed.payload.error))
    }
  }
}

function* createMpa({ payload }: SubmitFormType): GenType {
  if (payload.name === 'createMpa') {
    const mpa = payload.data
    const winner = yield asyncAction(healthrelationActions.createMpaRequested(mpa))

    if (winner.succeeded) {
      yield put(stopSubmit(payload.name))
      yield put(professionalsActions.toggleCreateMpaDialog())
      yield put(infoPanelActions.showMessage('professionals.createMpa.success'))
    } else {
      yield put(stopSubmit(payload.name, winner.failed.payload.error))
    }
  }
}

export default function professionalsSaga(): Array<GenType> {
  return [
    takeEvery(dynamicFormActionTypes.SUBMIT_FORM, createPhysician),
    takeEvery(dynamicFormActionTypes.SUBMIT_FORM, createMpa)
  ]
}
