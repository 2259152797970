// @flow

export const addToStudyDialogActionTypes = {
  TOGGLE_ADD_TO_STUDY_DIALOG: 'TOGGLE_ADD_TO_STUDY_DIALOG'
}
export type AddToStudyDialogActionType = $Keys<typeof addToStudyDialogActionTypes>
export const addToStudyDialogActions = {
  toggleAddToStudyDialog(userRef?: string, patientUuid?: string) {
    return {
      type: addToStudyDialogActionTypes.TOGGLE_ADD_TO_STUDY_DIALOG,
      payload: {
        userRef,
        patientUuid
      }
    }
  }
}
