// @flow
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import styled from 'styled-components'
import { userActions } from 'common-docdok/src/domain/user/actions'
import React from 'react'
import { Translate } from 'react-redux-i18n'

import { headerActions } from '../actions'
import palette from '../../../styles/palette'
import { DialogHeader } from '../../Mui'

type PropsType = {
  showTerms: ?string,
  declineTerms: typeof headerActions.declineTerms,
  locale: string,
  acceptTerms: typeof userActions.updateTermsVersionRequested,
  acceptedTermsVersion: ?string,
  hideTerms: typeof headerActions.hideTerms,
  fullScreen: boolean
}

type StateType = {
  srcollToPrivacy: ?number,
  checked: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Footer = styled.p`
  color: black;
  margin-top: 10;
`

const Terms = styled.iframe`
  width: 100%;
  flex-grow: 2;
  height: 50vh;
`

class TermsDialog extends React.Component<PropsType, StateType> {
  state = {
    srcollToPrivacy: undefined,
    checked: false
  }

  render() {
    const { srcollToPrivacy, checked } = this.state
    const { locale, showTerms, declineTerms, acceptTerms, acceptedTermsVersion, hideTerms, fullScreen } = this.props

    if (!showTerms) return null

    const showButtons = showTerms !== acceptedTermsVersion
    const isWebDe = window ? String(window.location.host).split('.').reverse()[0] === 'de' : false

    return (
      <Dialog
        id='dialog-terms'
        fullScreen={fullScreen}
        maxWidth='md'
        scroll='paper'
        onEscapeKeyDown={() => (showButtons ? declineTerms() : hideTerms())}
        open={Boolean(showTerms)}
      >
        <DialogHeader name='terms' onClose={() => (showButtons ? declineTerms() : hideTerms())}>
          <Translate value='header.terms.title' />
        </DialogHeader>

        <DialogContent>
          {acceptedTermsVersion != null
            && acceptedTermsVersion !== showTerms && (
              <div style={{ color: palette.pink, marginTop: 10 }}>
                <Translate value='header.terms.update' />
              </div>
          )}
          <Container>
            <Terms
              frameBorder='0'
              title='terms-and-conditions'
              src={`/termsAndConditions/terms.${showTerms}.${locale}.html${
                srcollToPrivacy ? `?id=${srcollToPrivacy}#privacy-policy` : ''
              }`}
            />
            <hr />
            <Footer>
              <b>
                {isWebDe ? (
                  <>
                    <Translate value="header.terms.consent" />
                    <a
                      href="#"
                      onClick={() => {
                        this.setState({ srcollToPrivacy: Date.now() })
                      }}
                    >
                      <Translate value="header.terms.consentLink" />
                    </a>
                  </>
                ) : null}
                <br />
                <FormControlLabel
                  disabled={!showButtons}
                  control={(
                    <Checkbox
                      id='terms-checkbox'
                      checked={checked || !showButtons}
                      onChange={() => this.setState({ checked: !checked })}
                      color='primary'
                    />
)}
                  label={(
                    <strong>
                      <Translate value='header.terms.checkboxLabel' />
                    </strong>
)}
                />
              </b>
            </Footer>
          </Container>
        </DialogContent>
        {showButtons && (
          <DialogActions>
            <Button id='terms-decline-button' onClick={() => declineTerms()}>
              <Translate value='actions.decline' />
            </Button>
            <Button
              disabled={!this.state.checked}
              id='terms-accept-button'
              color='primary'
              variant='contained'
              onClick={() => acceptTerms(showTerms)}
            >
              <Translate value='actions.confirm' />
            </Button>
          </DialogActions>
        )}
      </Dialog>
    )
  }
}

export default withMobileDialog()(TermsDialog)
