// @flow
import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import { getKeycloakClient } from 'common-docdok/src/common/Keycloak/keycloakProvider'
import { Translate } from 'react-redux-i18n'

type PropsType = {
  fullScreen: boolean
}

type StateType = {
  open: boolean,
  timer: any
}

class LoginDialog extends React.Component<PropsType, StateType> {
  state = {
    open: false,
    timer: setInterval(() => {
      this.tick()
    }, 1000)
  }

  componentWillUnmount() {
    window.clearInterval(this.state.timer)
  }

  tick() {
    if (getKeycloakClient().isTokenExpired() && !this.state.open) {
      window.clearInterval(this.state.timer)
      this.handleOpen()
    }
  }

  handleOpen() {
    this.setState({ open: true })
  }

  handleClose() {
    getKeycloakClient().logout()
    this.setState({ open: false })
  }

  render() {
    return (
      <Dialog
        fullScreen={this.props.fullScreen}
        maxWidth='md'
        scroll='paper'
        onEscapeKeyDown={() => this.handleClose()}
        modal
        open={this.state.open}
      >
        <DialogTitle>
          <Translate value='infos.sessionTimeout' />
        </DialogTitle>
        <DialogActions>
          <Button color='primary' variant='contained' onClick={() => this.handleClose()}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
export default withMobileDialog()(LoginDialog)
