export default {
  en: {
    table: {
      title: 'Users'
    },
    createUser: {
      title: 'Create User',
      userCreated: 'The user was successfully created.'
    },
    updateUser: {
      title: 'Update User',
      success: 'The user was successfully updated.'
    },
    renewInvitation: {
      title: 'Renew invitation',
      success: 'Invitation Email was sent again.'
    },
    disableUser: {
      title: 'Disable User',
      success: 'The user was sucessfully disabled'
    },
    deleteUser: {
      title: 'Delete User',
      success: 'User Deleted'
    },
    resendInitialSms: {
      title: 'Resend initial password',
      success: 'Sms sucessfully sent',
      confirmText: `Make sure that the users email address is valid.
      Otherwise docdok might not work properly`
    }
  },
  de: {
    table: {
      title: 'Benutzer'
    },
    createUser: {
      title: 'Benutzer erstellen',
      userCreated: 'Der Benutzer wurde erfolgreich erstellt.'
    },
    updateUser: {
      title: 'Benutzer aktualisieren',
      success: 'Der Benutzer wurde erfolgreich aktualisiert.'
    },
    renewInvitation: {
      title: 'Einladung erneuern',
      success: 'Einladungs Email wurden erneut verschickt.'
    },
    disableUser: {
      title: 'Benutzer deaktivieren',
      success: 'Der Benutzer wurde erfolgreich deaktiviert'
    },
    deleteUser: {
      title: 'Delete User',
      success: 'User Deleted'
    },
    resendInitialSms: {
      title: 'Passwort verschicken',
      success: 'Sms wurde erfolgreich verschickt',
      confirmText: `Stellen sie vorher sicher, dass die Email Adresse des Benutzers gültig ist. 
        Ansonsten funktioniert Docdok nicht einwandfrei`
    }
  }
}
