export default {
  en: {
    fields: {
      caseStreamId: 'Id',
      caseStreamName: 'Name',
      patientPublicUuid: 'Patient',
      organizations: 'Clinics',
      participants: 'Health Professionals',
      status: 'Status'
    }
  },
  de: {
    fields: {
      caseStreamId: 'Identifikation',
      caseStreamName: 'Name',
      patientPublicUuid: 'Patient DE',
      organizations: 'Kliniken',
      participants: 'Gesundheitsfachkräfte',
      status: 'Status'
    }
  }
}
