// @flow

export const profileActionTypes = {
  SET_UPLOAD_USER_AVATAR_DIALOG: 'SET_UPLOAD_USER_AVATAR_DIALOG'
}
export type ProfileActionType = $Keys<typeof profileActionTypes>
export const profileActions = {
  setUploadUserAvatarDialog(open: boolean) {
    return {
      type: profileActionTypes.SET_UPLOAD_USER_AVATAR_DIALOG,
      payload: open
    }
  }
}
