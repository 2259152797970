// @flow

export const fieldNames = {
  appointmentDescription: 'appointmentDescription',
  appointmentEndTime: 'appointmentEndTime',
  appointmentTime: 'appointmentTime',
  appointmentType: 'appointmentType',
  appointmentURL: 'appointmentURL',
  caseStreamCaseStreamId: 'caseStreamCaseStreamId',
  caseStreamId: 'caseStreamId',
  clinicAddress: 'clinicAddress',
  clinicContact: 'clinicContact',
  clinicContactMail: 'clinicContactMail',
  clinicLocation: 'clinicLocation',
  clinicName: 'clinicName',
  clinicStreet: 'clinicStreet',
  createdAt: 'createdAt',
  email: 'email',
  externalId: 'externalId',
  groupAppointmentId: 'groupAppointmentId',
  healthSubjectUuid: 'healthSubjectUuid',
  id: 'id',
  message: 'message',
  mobileNumber: 'mobileNumber',
  organizationAddress: 'organizationAddress',
  organizationLocation: 'organizationLocation',
  organizationName: 'organizationName',
  patientName: 'patientName',
  physician: 'physician',
  physicianUserRef: 'physicianUserRef',
  seriesId: 'seriesId',
  userRef: 'userRef'
}

export const fields = {
  appointmentDescription: { type: 'string' },
  appointmentEndTime: { type: 'Date' },
  appointmentTime: { type: 'Date' },
  appointmentType: { type: 'string' },
  appointmentURL: { type: 'string' },
  caseStreamCaseStreamId: { type: 'string' },
  caseStreamId: {
    type: 'number',
    format: 'int64'
  },
  clinicAddress: { type: 'string' },
  clinicContact: {
    type: 'string',
    minLength: 0,
    maxLength: 20
  },
  clinicContactMail: { type: 'string' },
  clinicLocation: {
    type: 'string',
    minLength: 0,
    maxLength: 266
  },
  clinicName: {
    type: 'string',
    minLength: 0,
    maxLength: 255
  },
  clinicStreet: {
    type: 'string',
    minLength: 0,
    maxLength: 266
  },
  createdAt: { type: 'Date' },
  email: { type: 'string' },
  externalId: { type: 'string' },
  groupAppointmentId: { type: 'string' },
  healthSubjectUuid: {
    type: 'string',
    minLength: 0,
    maxLength: 40
  },
  id: {
    type: 'number',
    format: 'int64'
  },
  message: {
    type: 'string',
    minLength: 0,
    maxLength: 1024
  },
  mobileNumber: { type: 'string' },
  organizationAddress: { type: 'string' },
  organizationLocation: { type: 'string' },
  organizationName: { type: 'string' },
  patientName: {
    type: 'string',
    minLength: 0,
    maxLength: 255
  },
  physician: {
    type: 'string',
    minLength: 0,
    maxLength: 255
  },
  physicianUserRef: {
    type: 'string',
    minLength: 0,
    maxLength: 36
  },
  seriesId: { type: 'string' },
  userRef: {
    type: 'string',
    minLength: 0,
    maxLength: 36
  }
}

export type AppointmentDtoType = {
  appointmentDescription: string,
  appointmentEndTime: Date,
  appointmentTime: Date,
  appointmentType: string,
  appointmentURL: string,
  caseStreamCaseStreamId: string,
  caseStreamId: number,
  clinicAddress: string,
  clinicContact: string,
  clinicContactMail: string,
  clinicLocation: string,
  clinicName: string,
  clinicStreet: string,
  createdAt: Date,
  email: string,
  externalId: string,
  groupAppointmentId: string,
  healthSubjectUuid: string,
  id: number,
  message: string,
  mobileNumber: string,
  organizationAddress: string,
  organizationLocation: string,
  organizationName: string,
  patientName: string,
  physician: string,
  physicianUserRef: string,
  seriesId: string,
  userRef: string
}
