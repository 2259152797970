// @flow
import React from 'react'
import { Translate } from 'react-redux-i18n'

import study from './containers/study'
import studyPhysicians from './containers/studyPhysicians'
import studyParticipants from './containers/studyParticipants'
import studySurveysToSend from './containers/studySurveysToSend'

import { checkRoute } from '../../../../common/Security/utils'
import participants from '../Surveys/containers/Participants'

function getStudyName(store: any, param: Object) {
  const { studyId } = param
  const studyDto = store.getState().survey.studies[studyId]
  const name = studyDto ? studyDto.name : '...'
  return name
}

export default (store: any) => ({
  path: 'studies',
  name: (
    <span id='breadcrumb1'>
      <Translate value='menu.studies' />
    </span>
  ),
  /*  Async getComponent is only invoked when route matches   */
  onEnter: (state: any, replace: Function) => {
    checkRoute(store.getState(), state.location.pathname, replace)
  },
  getComponent(nextState: any, cb: Function) {
    /*  Webpack - use 'require.ensure' to create a split point
        and embed an async module loader (jsonp) when bundling   */
    (require: any).ensure(
      [],
      (require) => {
        /*  Webpack - use require callback to define
          dependencies for bundling   */
        const container = require('./containers/studies').default

        /*  Return getComponent   */
        cb(null, container)

        /* Webpack named bundle   */
      },
      'studies'
    )
  },
  childRoutes: [
    {
      getDisplayName(param: Object) {
        return <span id='breadcrumb2'>{getStudyName((store: any), (param: Object))}</span>
      },

      path: ':studyId',
      component: study,
      onEnter: (state: any, replace: Function) => {
        checkRoute(store.getState(), state.location.pathname, replace)
      }
    },
    {
      getDisplayName(param: Object) {
        return (
          <span id='breadcrumb2'>
            {getStudyName((store: any), (param: Object))}
            {' '}
            <Translate value='studies.physicians.title' />
          </span>
        )
      },
      path: ':studyId/physicians',
      component: studyPhysicians,
      onEnter: (state: any, replace: Function) => {
        checkRoute(store.getState(), state.location.pathname, replace)
      }
    },
    {
      getDisplayName(param: Object) {
        return (
          <span id='breadcrumb2'>
            {getStudyName((store: any), (param: Object))}
            {' '}
            <Translate value='studies.participants.title' />
          </span>
        )
      },
      path: ':studyId/participants',
      component: studyParticipants,
      onEnter: (state: any, replace: Function) => {
        checkRoute(store.getState(), state.location.pathname, replace)
      }
    },
    {
      getDisplayName(param: Object) {
        return (
          <span id='breadcrumb2'>
            {getStudyName((store: any), (param: Object))}
            {' '}
            <Translate value='studies.surveys.title' />
          </span>
        )
      },
      path: ':studyId/surveys',
      component: studySurveysToSend,
      onEnter: (state: any, replace: Function) => {
        checkRoute(store.getState(), state.location.pathname, replace)
      },
      childRoutes: [
        {
          name: (
            <span id='breadcrumb3'>
              <Translate value='menu.surveysParticipants' />
            </span>
          ),
          path: ':surveyId/participants',
          component: participants,
          onEnter: (state: any, replace: Function) => {
            checkRoute(store.getState(), state.location.pathname, replace)
          },
          childRoutes: [
            {
              path: ':filter',
              getDisplayName() {
                return (
                  <span id='breadcrumb4'>
                    <Translate value='menu.surveysParticipantsCompleted' />
                  </span>
                )
              },
              component: participants,
              onEnter: (state: any, replace: Function) => {
                checkRoute(store.getState(), state.location.pathname, replace)
              }
            }
          ]
        }
      ]
    }
  ]
})
