// @flow
import api from 'common-docdok/src/api'
import { fieldNames as userFields, fields as userFieldsDef } from 'common-docdok/src/domain/user/types/userDto'
import {
  fieldNames as catalogFields,
  fields as catalogFieldsDef
} from 'common-docdok/src/domain/messaging/types/contentDefinitionVm'
import {
  fieldNames as patientFields,
  fields as patientFieldsDef
} from 'common-docdok/src/domain/healthrelation/types/patientDto'
import {
  fieldNames as professionalFields,
  fields as professionalFieldsDef
} from 'common-docdok/src/domain/healthrelation/types/healthcareProfessionalDto'
import {
  fieldNames as clincFields,
  fields as clinicFieldsDef
} from 'common-docdok/src/domain/healthrelation/types/clinicDto'
import { fieldNames as studyFields, fields as studyFieldsDef } from 'common-docdok/src/domain/survey/types/studyDto'
import {
  fieldNames as surveyFields,
  fields as surveyFieldsDef
} from 'common-docdok/src/domain/survey/types/surveyDefinitionSummary'
import {
  fieldNames as studyReportDefFields,
  fields as studyReportDefFieldsDef
} from 'common-docdok/src/domain/survey/types/studyReportDefinitionDto'
import {
  fieldNames as deviceDefFields,
  fields as deviceDefFieldsDef
} from 'common-docdok/src/domain/survey/types/deviceDefinitionDto'
import {
  fieldNames as appointmentFields,
  fields as appointmentFieldsDef
} from 'common-docdok/src/domain/healthrelation/types/appointmentDto'
import {
  fieldNames as ambulanceQueueItemsFields,
  fields as ambulanceQueueItemsFieldsDef
} from 'common-docdok/src/domain/healthrelation/types/ambulanceQueueItemDto'
import {
  fieldNames as casestreamFields,
  fields as casestreamFieldsDef
} from 'common-docdok/src/domain/healthrelation/types/caseStreamDto'
import {
  fieldNames as organizationFields,
  fields as organizationFieldsDef
} from 'common-docdok/src/domain/healthrelation/types/organizationDto'
import {
  fieldNames as conversationFields,
  fields as conversationFieldsDef
} from 'common-docdok/src/domain/messaging/types/conversationDto'
import {
  fieldNames as patientActivitiesFields,
  fields as patientActivitiesFieldsDef
} from 'common-docdok/src/domain/healthrelation/types/patientActivitiesDto'
import {
  fieldNames as patientMeassurementsFields,
  fields as patientMeassurementsFieldsDef
} from 'common-docdok/src/domain/healthrelation/types/patientMeassurements'

export type EntitySpecType = {
  entity: string,
  api: Function,
  identifier: string,
  fields: {
    [name: string]: Object
  },
  avatarField?: string
}

const specs = {
  patient: {
    entity: 'patient',
    api: api.getPatients,
    identifier: patientFields.uuid,
    fields: {
      firstName: patientFieldsDef.firstName,
      lastName: patientFieldsDef.lastName
    },
    avatarField: userFields.avatarPicture
  },
  clinic: {
    entity: 'clinic',
    api: api.getClinics,
    identifier: clincFields.id,
    fields: { name: clinicFieldsDef.name }
  },
  study: {
    entity: 'study',
    api: api.getAllStudies,
    identifier: studyFields.id,
    fields: { name: studyFieldsDef.name }
  },
  reportDefinition: {
    entity: 'reportDefinition',
    api: api.getAllStudies,
    identifier: studyFields.id,
    fields: { name: 'name' }
  },
  survey: {
    entity: 'survey',
    api: api.getSurveyDefinitions,
    identifier: surveyFields.id,
    fields: { name: surveyFieldsDef.name }
  },
  user: {
    entity: 'user',
    api: api.getUsers,
    identifier: userFields.uid,
    fields: {
      firstName: userFieldsDef.firstName,
      lastName: userFieldsDef.lastName
    },
    avatarField: userFields.avatarPicture
  },
  userByRole: {
    entity: 'user',
    api: api.getUsersByRole,
    identifier: userFields.uid,
    fields: {
      firstName: userFieldsDef.firstName,
      lastName: userFieldsDef.lastName
    },
    avatarField: userFields.avatarPicture
  },

  professional: {
    entity: 'professional',
    api: api.getTeam,
    identifier: professionalFields.userRef,
    fields: {
      firstName: professionalFieldsDef.firstName,
      lastName: professionalFieldsDef.lastName
    },
    avatarField: professionalFields.avatarPicture
  },
  otherProfessionalUsers: {
    entity: 'professional',
    api: api.getCoworkers,
    identifier: professionalFields.userRef,
    fields: {
      firstName: professionalFieldsDef.firstName,
      lastName: professionalFieldsDef.lastName
    },
    avatarField: professionalFields.avatarPicture
  },

  studyReportDefinition: {
    entity: 'studyReportDefinition',
    api: api.getReportDefintions,
    identifier: studyReportDefFields.id,
    fields: {
      name: studyReportDefFieldsDef.name
    }
  },

  availableStudySurveys: {
    entity: 'survey',
    api: api.getAvailableStudySurveys,
    identifier: surveyFields.id,
    fields: { name: surveyFieldsDef.name }
  },
  availableStudyDevices: {
    entity: 'deviceDefinition',
    api: api.getAvailableStudyDevices,
    identifier: deviceDefFields.id,
    fields: { name: deviceDefFieldsDef.name }
  },
  studyParticipants: {
    entity: 'studyParticipantPatient',
    api: api.getStudyParticipants,
    identifier: patientFields.uuid,
    fields: { firstName: patientFieldsDef.firstName, lastName: patientFieldsDef.lastName }
  },
  contentDefinition: {
    entity: 'contentDefinition',
    api: api.getContentDefinition,
    identifier: catalogFields.contentDefinitionId,
    fields: { title: catalogFieldsDef.title }
  },
  appointments: {
    entity: 'appointments',
    api: api.getAllAppointments,
    identifier: appointmentFields.id,
    fields: { ...appointmentFieldsDef }
  },
  ambulanceQueueItems: {
    entity: 'ambulanceQueueItems',
    api: api.getAmbulanceQueueItem,
    identifier: ambulanceQueueItemsFields.id,
    fields: { ...ambulanceQueueItemsFieldsDef }
  },
  casestream: {
    entity: 'casestream',
    api: api.getCasesStream,
    identifier: casestreamFields.id,
    fields: { ...casestreamFieldsDef }
  },
  organization: {
    entity: 'organizations',
    identifier: organizationFields.id,
    fields: { ...organizationFieldsDef }
  },
  conversation: {
    entity: 'conversation',
    identifier: conversationFields.id,
    fields: { ...conversationFieldsDef }
  },
  patientactivities: {
    entity: 'patientactivities',
    api: api.getPatientActivities,
    identifier: patientActivitiesFields.id,
    fields: { ...patientActivitiesFieldsDef }
  },
  patientmeasurements: {
    entity: 'patientmeasurements',
    api: api.getPatientMeasurements,
    identifier: patientMeassurementsFields.id,
    fields: { ...patientMeassurementsFieldsDef }
  },
  icdCodes: {
    entity: 'icdCodes',
    api: api.getICDCodes,
    identifier: 'id',
    fields: {
      // code: {
      //   type: 'string',
      //   maxLength: 50,
      //   mandatory: true
      // },
      description: {
        type: 'string'
      }
    }
  },
  themes: {
    entity: 'themes',
    api: api.getSurveyThemes,
    identifier: 'id',
    fields: {
      name: {
        type: 'string'
      }
    }
  },
  surveyDefinitions: {
    entity: 'surveyDefinitions',
    api: api.getSurveyDefinition,
    identifier: 'id',
    fields: {
      name: {
        type: 'string'
      }
    }
  }
}

export default (specName: ?string): EntitySpecType => {
  if (!specName) {
    throw new Error('please provide spec name')
  }
  const spec = specs[specName]
  if (!spec) {
    throw new Error(`no spec found for the name ${specName}`)
  }
  return spec
}

// appointmentType: 'appointmentType',
//   appointmentTime: 'appointmentTime',
//   clinicContact: 'clinicContact',
//   clinicLocation: 'clinicLocation',
//   clinicName: 'clinicName',
//   clinicStreet: 'clinicStreet',
//   createdAt: 'createdAt',
//   healthSubjectUuid: 'healthSubjectUuid',
//   id: 'id',
//   message: 'message',
//   patientName: 'patientName',
//   physician: 'physician',
//   physicianUserRef: 'physicianUserRef',
//   userRef: 'userRef'
