// @flow

export const coreLayoutActionTypes = {
  SET_MENU_VISIBILITY: 'SET_MENU_VISIBILITY'
}
export type CoreLayoutActionType = $Keys<typeof coreLayoutActionTypes>
export const coreLayoutActions = {
  setMenuVisibility(visible: boolean) {
    return {
      type: coreLayoutActionTypes.SET_MENU_VISIBILITY,
      payload: visible
    }
  }
}
