import invariant from 'invariant'
import type { $AxiosXHR, AxiosPromise } from 'axios'
import moment from 'moment'

import type { HealthcareProfessionalDtoType } from '../domain/healthrelation/types/healthcareProfessionalDto'
import type {
  CreatePatientDtoType,
  PatientDtoType
} from '../domain/healthrelation/types/patientDto'
import type { ClinicDtoType } from '../domain/healthrelation/types/clinicDto'
import type { AppointmentDtoType } from '../domain/healthrelation/types/appointmentDto'
import type { AvailabilityDtoType } from '../domain/healthrelation/types/availabilityDto'
import type { UserDtoType } from '../types'
import type { MassMessagingVmType } from '../domain/healthrelation/types/massMessagingVm'
import type { CaseStreamDtoType } from '../domain/healthrelation/types/caseStreamDto'
import type {
  PatientActivitiesDtoType,
  PatientActivitiesCalendarType,
  CreatePatientActivitiesDtoType
} from '../domain/healthrelation/types/patientActivitiesDto'
import type { PatientMeassurmentsDtoType } from '../domain/healthrelation/types/patientMeassurements'
import { getUser } from './user'
import buildPaginingUrl, {
  setAvatarPictureVersion,
  setAvatarPictureVersions
} from './util'
import { getAxiosInstance } from './'

const BASE_PATH = '/rest/healthrelation/api/'

export function getPatients(
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: string,
  orderBy?: string
): AxiosPromise<Array<PatientDtoType>> {
  const path = buildPaginingUrl(
    `${BASE_PATH}patients`,
    pageSize,
    page,
    searchTerm,
    orderBy
  )
  return getAxiosInstance()
    .get(path)
    .then(setAvatarPictureVersions)
}

export function getPatientUuidByPublicId(
  publicUuid: string
): AxiosPromise<string> {
  return getAxiosInstance().get(
    `${BASE_PATH}patients/byPublicUuid/${publicUuid}/uuid`
  )
}

export function getPatient(uuid: string): AxiosPromise<PatientDtoType> {
  return getAxiosInstance()
    .get(`${BASE_PATH}patients/${uuid}`)
    .then(setAvatarPictureVersion)
}

export function createPatient(
  patient: CreatePatientDtoType
): AxiosPromise<PatientDtoType> {
  invariant(patient, 'createPatient: patient is undefined')
  return getAxiosInstance().post(`${BASE_PATH}patients`, {
    class: patient.proxy ? 'PATIENT_PROXY' : 'PATIENT',
    ...patient
  })
}

export function getClinics(
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: string,
  orderBy?: string
): AxiosPromise<Array<ClinicDtoType>> {
  const path = buildPaginingUrl(
    `${BASE_PATH}clinics`,
    pageSize,
    page,
    searchTerm,
    orderBy
  )
  return getAxiosInstance().get(path)
}

export function createClinic(clinic: ClinicDtoType): AxiosPromise<*> {
  invariant(clinic, 'createClinic: clinic is undefined')
  return getAxiosInstance().post(`${BASE_PATH}clinics`, clinic)
}

export function getClinic(id: string): AxiosPromise<ClinicDtoType> {
  return getAxiosInstance().get(`${BASE_PATH}clinics/${id}`)
}

export function getClinicMine(): AxiosPromise<ClinicDtoType> {
  return getAxiosInstance().get(`${BASE_PATH}clinics/mine`)
}

export function updateClinic(
  clinic: ClinicDtoType
): AxiosPromise<ClinicDtoType> {
  return getAxiosInstance().put(`${BASE_PATH}clinics`, clinic)
}

export function getTeam(
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: string,
  orderBy?: string
): AxiosPromise<Array<HealthcareProfessionalDtoType>> {
  const path = buildPaginingUrl(
    `${BASE_PATH}healthcareprofessionals/team`,
    pageSize,
    page,
    searchTerm,
    orderBy
  )
  return getAxiosInstance()
    .get(path)
    .then(setAvatarPictureVersions)
}

export function getHealthcareProfessional(
  uuid: string
): AxiosPromise<Array<HealthcareProfessionalDtoType>> {
  invariant(uuid, 'healthcareProf uuid not defined')
  return getAxiosInstance()
    .get(`${BASE_PATH}healthcareprofessionals/${uuid}`)
    .then(setAvatarPictureVersion)
}

export function getHealthcareProfessionalByPublicUuid(
  publicUuid: string
): AxiosPromise<Array<HealthcareProfessionalDtoType>> {
  invariant(publicUuid, 'healthcareProf publicUuid not defined')
  return getAxiosInstance()
    .get(`${BASE_PATH}healthcareprofessionals/byPublicUuid/${publicUuid}`)
    .then(setAvatarPictureVersion)
}

export function getClinicTeam(
  teamId: number,
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: string,
  orderBy?: string
): AxiosPromise<Array<HealthcareProfessionalDtoType>> {
  const path = buildPaginingUrl(
    `${BASE_PATH}clinic/${teamId}/healthcareprofessionals`,
    pageSize,
    page,
    searchTerm,
    orderBy
  )
  return getAxiosInstance()
    .get(path)
    .then(setAvatarPictureVersions)
}

export function getPhysicianPatients(
  uuid: number,
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: string,
  orderBy?: string
): AxiosPromise<Array<HealthcareProfessionalDtoType>> {
  const path = buildPaginingUrl(
    `${BASE_PATH}healthcareprofessionals/${uuid}/patients`,
    pageSize,
    page,
    searchTerm,
    orderBy
  )
  return getAxiosInstance()
    .get(path)
    .then(setAvatarPictureVersions)
}

type HpUserType = HealthcareProfessionalDtoType & UserDtoType

export function getTeamUsers(
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: string,
  orderBy?: string
): AxiosPromise<Array<HpUserType>> {
  const path = buildPaginingUrl(
    `${BASE_PATH}healthcareprofessionals/team`,
    pageSize,
    page,
    searchTerm,
    orderBy
  )
  return getAxiosInstance()
    .get(path)
    .then((teamData: any) => {
      const professioanlsUserRefs = teamData.data.map(p => p.userRef)
      const professioanlsDict = teamData.data.reduce(
        (dict, p) => ({ ...dict, [p.userRef]: p }),
        {}
      )
      const requests = professioanlsUserRefs.map(ref => getUser(ref))
      return Promise.all(requests).then((responses) => {
        const users = responses.map((r) => {
          const user = r.data
          const patient = professioanlsDict[user.uid]
          return { ...user, ...patient }
        })
        return {
          ...teamData,
          data: users
        }
      })
    })
}

export function getCoworkers(
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: string,
  orderBy?: string
): Promise<$AxiosXHR<Array<HealthcareProfessionalDtoType>>> {
  const path = buildPaginingUrl(
    `${BASE_PATH}healthcareprofessionals/coworkers`,
    pageSize,
    page,
    searchTerm,
    orderBy
  )
  return getAxiosInstance()
    .get(path)
    .then(setAvatarPictureVersions)
}

export function createProfessional(
  professional: HealthcareProfessionalDtoType
): AxiosPromise<HealthcareProfessionalDtoType> {
  invariant(professional, 'createProfessional: professional is undefined')
  return getAxiosInstance().post(
    `${BASE_PATH}healthcareprofessionals`,
    professional
  )
}

/**
 * Get all appointments for authentified user.
 * @return {AxiosPromise<Array<AppointmentDtoType>>}
 */
export function getAllAppointments(): AxiosPromise<Array<AppointmentDtoType>> {
  return getAxiosInstance().get(`${BASE_PATH}appointments`)
}

export function getAllAppointmentsByUserRef(
  userRef: string
): AxiosPromise<Array<AppointmentDtoType>> {
  return getAxiosInstance().get(`${BASE_PATH}appointments/byUserRef/${userRef}`)
}

export function getCaseStreamAppointments(
  caseStreamId: string
): AxiosPromise<Array<AppointmentDtoType>> {
  return getAxiosInstance().get(
    `${BASE_PATH}public/appointments?caseStreamId=${caseStreamId}`
  )
}

/**
 * Get appointment detail for authentified user.
 * @param {number} id of appointment
 * @return {AxiosPromise<AppointmentDtoType>}
 */
export function getAppointment(id: number): AxiosPromise<AppointmentDtoType> {
  invariant(id, 'getAppointment: id is undefined')
  return getAxiosInstance().get(`${BASE_PATH}appointments/${id}`)
}

export function requestAttention(
  patientUuid: string,
  toUuid: string,
  message: string
): AxiosPromise<*> {
  invariant(patientUuid, 'patientUuid: id is undefined')
  invariant(toUuid, 'toUuid: id is undefined')
  return getAxiosInstance().post(
    `${BASE_PATH}healthcareprofessionals/requestattention`,
    {
      patientUuid,
      toUuid,
      message
    }
  )
}

export function getPhysicianTypes(): AxiosPromise<*> {
  return getAxiosInstance().get(`${BASE_PATH}billing/physician-types`)
}

export function getBillingPositions(patientUuid: string): AxiosPromise<*> {
  invariant(patientUuid, 'patientUuid: id is undefined')
  return getAxiosInstance().get(
    `${BASE_PATH}patients/${patientUuid}/billing-positions`
  )
}

export function billPatient(
  patientUuid: string,
  code: string
): AxiosPromise<*> {
  invariant(patientUuid, 'patientUuid: id is undefined')
  invariant(code, 'billing code is undefined')
  return getAxiosInstance().post(`${BASE_PATH}billing/${patientUuid}/event`, {
    code
  })
}

export function downloadBilling(): AxiosPromise<*> {
  return getAxiosInstance().get(`${BASE_PATH}billing/revenue/csv`)
}

/**
 * Get upload avatar url.
 * @return {AxiosPromise<*>}
 */
export function getPatientUploadAvatarUrl(
  patientUuid: string
): AxiosPromise<*> {
  invariant(patientUuid, 'patientUuid is undefined')
  return getAxiosInstance().get(
    `${BASE_PATH}patients/${patientUuid}/uploadAvatarUrl`
  )
}

export function getAvailabilities(): AxiosPromise<Array<AvailabilityDtoType>> {
  return getAxiosInstance().get(`${BASE_PATH}clinics/availabilities`)
}

export function setAvailability(
  type: string,
  message: string
): AxiosPromise<AvailabilityDtoType> {
  invariant(type, 'type is undefined')
  invariant(message, 'message is undefined')
  invariant(['clinic', 'physician'].includes(type), `unknown type ${type}`)
  return getAxiosInstance().post(`${BASE_PATH}clinics/availability`, {
    type,
    message
  })
}

export function deletePatient(uuid: string): AxiosPromise<*> {
  invariant(uuid, 'patient uuid is undefined')
  return getAxiosInstance().delete(`${BASE_PATH}patients/${uuid}`)
}

export function updatePatient(
  patient: PatientDtoType
): AxiosPromise<PatientDtoType> {
  invariant(patient, 'patient is undefined')

  let pat = patient
  if (
    patient.firstName !== patient.firstname ||
    patient.lastName !== patient.lastname
  ) {
    // eslint-disable-next-line
    console.warn(
      `firstname and firstName or lastName and lastName are not consistant.
      overriding values with lastName and firstName`,
      patient
    )
    pat = {
      ...patient,
      firstname: patient.firstName,
      lastname: patient.lastName
    }
  }

  return getAxiosInstance().put(`${BASE_PATH}patients`, pat)
}

export function massSending(
  message: string,
  activated: ?boolean,
  simulation: boolean = true
): AxiosPromise<MassMessagingVmType> {
  const payload = { message, patientFilter: { activated } }
  return getAxiosInstance().post(
    `${BASE_PATH}patients/mass-sending?simulation=${String(simulation)}`,
    payload
  )
}

export function deleteClinic(clinicId: number): AxiosPromise<*> {
  invariant(clinicId, 'clinic id is undefined')
  return getAxiosInstance().delete(`${BASE_PATH}clinics/${clinicId}`)
}

export function getAmbulanceQueueItem(): AxiosPromise<*> {
  return getAxiosInstance().get(`${BASE_PATH}ambulance/queue`)
}

export function setAmbulanceQueueItemIntoProgress(
  patientUserRef: string
): AxiosPromise<*> {
  return getAxiosInstance().post(
    `${BASE_PATH}ambulance/queue/${patientUserRef}/progress`
  )
}

export function setAmbulanceQueueItemFinished(
  patientUserRef: string
): AxiosPromise<*> {
  return getAxiosInstance().post(
    `${BASE_PATH}ambulance/queue/${patientUserRef}/finished`
  )
}

export function setAmbulanceQueueItemToError(
  patientUserRef: string
): AxiosPromise<*> {
  return getAxiosInstance().post(
    `${BASE_PATH}ambulance/queue/${patientUserRef}/error`
  )
}

export function getWaitingPositionInAmbulanceQueue(): AxiosPromise<Number> {
  return getAxiosInstance().get(`${BASE_PATH}ambulance/queue/waitposition`)
}

export function setMyselfToWaiting(): AxiosPromise<*> {
  return getAxiosInstance().post(`${BASE_PATH}ambulance/queue/waiting`)
}

export function setMyselfToNotWaiting(): AxiosPromise<*> {
  return getAxiosInstance().post(`${BASE_PATH}ambulance/queue/notwaiting`)
}

export function getCasesStream(
  patientPublicUuid: string,
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: string,
  orderBy?: string
): AxiosPromise<Array<CaseStreamDtoType>> {
  const path = buildPaginingUrl(
    `${BASE_PATH}case-streams`,
    pageSize,
    page,
    searchTerm,
    orderBy
  )
  return getAxiosInstance().get(
    `${path}&patientPublicUuid=${patientPublicUuid}`
  )
}

export function getPatientActivities(
  patientCalendar: PatientActivitiesCalendarType
): AxiosPromise<Array<PatientActivitiesDtoType>> {
  const queryParams = Object.keys(patientCalendar)
    .map(k => `${k}=${patientCalendar[k] !== null ? patientCalendar[k] : ''}`)
    .join('&')
  return getAxiosInstance().get(
    `${BASE_PATH}patient-activities-calendar?${queryParams}`
  )
}

export function getPatientMeasurements(
  patientPublicUuid: string
): AxiosPromise<Array<PatientMeassurmentsDtoType>> {
  return getAxiosInstance().get(
    `${BASE_PATH}patient-measurements/${patientPublicUuid}`
  )
}

export function updatePatientActivities(
  patientActivity: any
): AxiosPromise<any> {
  let pattern = null
  if (patientActivity.recurrence === 'schedule') {
    if (patientActivity.pattern) {
      pattern = String(patientActivity.pattern).toLowerCase()
    }
  }
  const reqPayload: CreatePatientActivitiesDtoType = {
    patientUuid: patientActivity.patientUuid,
    physicianUuid: patientActivity.physicianUuid,
    name: String(patientActivity.name).trim(''),
    description: patientActivity.description,
    startTime: moment(
      `${moment(patientActivity.startdate).format('YYYY-MM-DD')} ${moment(
        patientActivity.startTime
      ).format('HH:mm:ss')}`
    ).format('YYYY-MM-DDTHH:mm:ssZ'),
    endTime: moment(
      `${moment(patientActivity.startdate).format('YYYY-MM-DD')} ${moment(
        patientActivity.endTime
      ).format('HH:mm:ss')}`
    ).format('YYYY-MM-DDTHH:mm:ssZ'),
    type: patientActivity.type,
    location: patientActivity.location,
    pattern,
    status: patientActivity.status,
    url: patientActivity.url,
    notes: patientActivity.notes,
    toUpdateSeries: patientActivity.toUpdateSeries || false,
    seriesId:
      patientActivity.seriesId !== null &&
      patientActivity.seriesId !== undefined
        ? patientActivity.seriesId
        : null
  }
  // endDate

  if (patientActivity.recurrence === 'schedule') {
    reqPayload.seriesEndDate = moment(patientActivity.endDate).endOf('date').format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
  }
  if (patientActivity.id) {
    reqPayload.id = patientActivity.id
    return getAxiosInstance().put(`${BASE_PATH}patient-activities`, reqPayload)
  }
  return getAxiosInstance().post(`${BASE_PATH}patient-activities`, reqPayload)
}

export function deletePatientActivities(
  id: number,
  isSeries: boolean
): AxiosPromise<any> {
  return getAxiosInstance().put(`${BASE_PATH}patient-activities-status-change`, {
    id,
    isSeries: isSeries !== undefined ? isSeries : true,
    newPatientActivityStatus: 'CANCELED'
  })
}

export function uploadRequestAttachment(fileName: string, patientActivityId: number) {
  invariant(fileName, 'fileName undefined')
  invariant(patientActivityId, 'patientActivityId undefined')
  return getAxiosInstance().post(`${BASE_PATH}patient-activities-create-signed-url`, {
    fileName,
    patientActivityId
  })
}

export async function getMediaResource(id) {
  try {
    const mediaUrl = `${BASE_PATH}patient-activities-get-signed-url?patientActivityId=${id}`
    return getAxiosInstance().get(mediaUrl)
  } catch (error) {
    return Promise.reject(error)
  }
}

export function getPatientProgress(
  params: any
): AxiosPromise<Array<any>> {
  return getAxiosInstance().post(
    `${BASE_PATH}patient-measurements-query`,
    params
  )
}

export function getPatientProgressCounts(
  params: any
): AxiosPromise<Array<any>> {
  return getAxiosInstance().get(
    `${BASE_PATH}patient-activities-count-query?patientUuid=${params.patientUuid}&filterType=${params.filterType}`
  )
}

export function getClinicOfPatient(params): AxiosPromise<ClinicDtoType> {
  return getAxiosInstance().get(`${BASE_PATH}clinics/by-user-ref/${params.userRef}`)
}

export function getICDCodes(language): AxiosPromise<Array<PatientActivitiesDtoType>> {
  const DIGA_CODE = 0
  return getAxiosInstance().get(
    `${BASE_PATH}icd-code-list/${DIGA_CODE}?${language ? `language=${language}` : ''}`
  )
}

export function createDiga1Patient(data: any): AxiosPromise<Array<PatientActivitiesDtoType>> {
  return getAxiosInstance().post(
    `${BASE_PATH}create-diga1-patient`,
    data
  )
}

export function getPatientTarget(id: string): AxiosPromise<ClinicDtoType> {
  return getAxiosInstance().get(`${BASE_PATH}medical-health-targets/${id}`)
}

export function setPatientTarget(payload: any): AxiosPromise<ClinicDtoType> {
  return getAxiosInstance().post(`${BASE_PATH}medical-health-targets`, payload)
}

export function getPatientTargetDigaDetails(payload: any): AxiosPromise<ClinicDtoType> {
  return getAxiosInstance().post(`${BASE_PATH}medical-measurement`, payload)
}
