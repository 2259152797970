export default {
  en: {
    fields: {
      conversationTitle: 'Title',
      clinicName: 'Clinic'
    }
  },
  de: {
    fields: {
      conversationTitle: 'Titel',
      clinicName: 'Klinik'
    }
  }
}
