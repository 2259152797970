export default {
  en: {
    title: 'Patients',
    actions: {
      showProfile: {
        title: 'Show Profile'
      },
      createPatient: {
        title: 'Create Patient',
        success: 'New patient successfuly created'
      },
      addToStudy: {
        title: 'Add to Study'
      },
      deletePatient: {
        title: 'Delete Patient',
        success: 'Patient deleted successfully'
      },
      confirmation: {
        title: 'Are you sure? Clicking "OK" will permanently delete the patient and all associated data'
      }
    },

    createPatient: {
      title: 'Create Patient',
      patientTitle: 'Patient Infos',
      studyTitle: 'Studyparticipation',
      parentInfos: 'Parent Information',
      siblingInfo: `If the new patient's parent has another child in docdok already,
        search for the sibling here to add them both to the parent.?`
    },
    publicIdNotFound: 'Could not resolve public-Id %{publicId} to a patient'
  },
  de: {
    title: 'Patienten',
    actions: {
      showProfile: {
        title: 'Profil anzeigen'
      },
      createPatient: {
        title: 'Patient erstellen',
        success: 'Neuer Patient wurde erfolgreich angelegt'
      },
      addToStudy: {
        title: 'Zu Studie hinzufügen'
      },
      deletePatient: {
        title: 'Patient löschen',
        success: 'Patient erfolgreich gelöscht'
      },
      confirmation: {
        title:
          'Sind Sie sicher? Wenn Sie auf "OK" klicken, wird der Patient und alle zugehörigen Daten endgültig gelöscht'
      }
    },
    createPatient: {
      title: 'Patient erstellen',
      patientTitle: 'Patienten Infos',
      studyTitle: 'Studienteilnahme',
      parentInfos: 'Eltern Informationen',
      siblingInfo: `Wenn die Eltern des Patienten bereits ein anderes Kind in docdok haben,
        suchen Sie dieses hier um beide mit dem Elternteil zu verknüpfen.`
    },
    publicIdNotFound: 'Die public-Id %{publicId} konnte nicht zu einem Patient aufgelöst werden'
  }
}
