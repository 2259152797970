// @flow
import React from 'react'
import { connect } from 'react-redux'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Translate, I18n } from 'react-redux-i18n'
import { browserHistory } from 'react-router'
import moment from 'moment'

import type { MessageDtoType } from 'common-docdok/src/domain/messaging/types/messageDto'
import UserAvatar from '../../UserAvatar'


const mapStateToProps = (state: StoreType, ownProps: { message: MessageDtoType }) => {
  const user = state.userCache.users[ownProps.message.userRef]
  return { user }
}

export default connect(mapStateToProps)((props) => {
  const {
    user,
    message: {
      meta: { surveyId, studyId, creationDate, surveyName },
      closeRequest
    }
  } = props
  if (!user) return null

  const formattedDate = I18n.l(moment(creationDate).toDate(), {
    dateFormat: 'date.short'
  })

  return (
    <ListItem
      button
      id='survey-reminder-notification'
      onClick={() => {
        if (studyId !== undefined) {
          browserHistory.push(`/private/app/studies/${studyId}/surveys`)
        } else {
          browserHistory.push(`/private/app/surveys/${surveyId}/participants`)
        }
        if (closeRequest) closeRequest()
      }}
    >
      <UserAvatar
        size={34}
        src={user ? user.avatarPicture : undefined}
        name={user ? user.firstName : ''}
      />
      <ListItemText
        primary={(
          <Translate
            value='surveys.notifications.surveyReminder'
            user={user ? `${user.firstName} ${user.lastName}` : ''}
            survey={surveyName}
            creationDate={formattedDate}
          />
)}
      />
    </ListItem>
  )
})
