// @flow
import React from 'react'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import FormField from './fields/formField'
import { flatFields } from './utils'
import { Container } from '../../Static'
import type { FieldsType } from '../containers/DynamicForm'

type PropsType = {
  form: string,
  fields: FieldsType,
  fullScreen: boolean
}

function generatedFormLayout(props: PropsType) {
  const { form, fields, fullScreen } = props

  let fieldElements

  if (fields instanceof Array) {
    const flattedFields = flatFields(fields)
    const totalNumberFields = fields.reduce((count, row) => count + Object.keys(row).length, 0)

    let index = 0
    fieldElements = (
      <div>
        {fields.map((row, key) => (
          <Container key={key} noWrap={!fullScreen}>
            {Object.keys(row).map((name: string) => {
              const isLast = index === totalNumberFields
              index += 1
              return (
                <FormField
                  key={name}
                  form={form}
                  isLast={isLast}
                  fields={flattedFields}
                  name={name}
                />
              )
            })}
          </Container>
        ))}
      </div>
    )
  } else {
    const totalNumberFields = Object.keys(fields).length - 1
    fieldElements = Object.keys(fields).map((name: string, index: number) => {
      const isLast = index === totalNumberFields
      return <FormField key={index} form={form} isLast={isLast} fields={fields} name={name} />
    })
  }
  return <div id='fields-container'>{fieldElements}</div>
}

export default withMobileDialog()(generatedFormLayout)
