// @flow
import type { StudyReportPartDefinitionDtoType } from './studyReportPartDefinitionDto'

export const fieldNames = {
  id: 'id',
  name: 'name',
  partDefinitions: 'partDefinitions',
  studyId: 'studyId'
}

export const fields = {
  id: {
    type: 'number',
    format: 'int64'
  },
  name: { type: 'string' },
  partDefinitions: { type: 'Array<StudyReportPartDefinitionDTO>' },
  studyId: {
    type: 'number',
    format: 'int64'
  }
}

export type StudyReportDefinitionDtoType = {
  id: number,
  name: string,
  partDefinitions: Array<StudyReportPartDefinitionDtoType>,
  studyId: number
}
