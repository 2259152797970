// @flow
import isArray from 'lodash/isArray'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import moment from 'moment'

const toDate = (val: any) => {
  let date

  if (val === null || val === undefined) {
    date = val
  } else if (isString(val)) {
    date = moment(val).toDate()
  } else if (isArray(val)) {
    date = new Date(val[0], val[1] - 1, val[2])
  } else if (isNumber(val)) {
    date = new Date(0)
    date.setUTCSeconds(val)
  } else {
    date = new Date(val)
  }

  return date
}

export default {
  toDate,

  getDiffDays(d1: any, d2: any): number {
    const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
    const firstDate = toDate(d1)
    const secondDate = toDate(d2)
    if (!firstDate || !secondDate) throw new Error('one of the argument could not be converted to Date')
    const diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay))
    return diffDays
  }
}
