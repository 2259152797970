import path from 'ramda/src/path'
import pipe from 'ramda/src/pipe'
import propOr from 'ramda/src/propOr'
import find from 'ramda/src/find'
import { eqUserRef } from './subscription'

/**
 * return the subscriptions of the conversation
 * @param {ConversationDtoType} conversation
 * @return {Array<SubscriptinDtoType>} subscriptions
 */
export const getSubscriptions = propOr([], 'subscriptions')

/**
 * return the subject in the meta of the conversation
 * @param {ConversationDtoType} conversation
 * @return {string} userRef
 */
export const getSubjectUserRef = path(['meta', 'userRef'])

/**
 * takes the subscription with the meta.userRef and returns a find-function
 * (used for subscriptions)
 * @param {ConversationDtoType} conversation
 * @return a function which takes a {Array<SubscriptinDtoType>}
 */
export const findByConvUserRef = pipe(getSubjectUserRef, eqUserRef, find)

/**
 * takes the conversation and returns the sub of the subject
 * @param {ConversationDtoType} conversation
 * @return {SubscriptinDtoType} the subscription of the subject
 */
export const getSubjectSub = conversation =>
  pipe(getSubscriptions, findByConvUserRef(conversation))(conversation)
